import styled from 'styled-components';
import { useState } from "react";
import Menu from './Menu';
import { CollapseMenu, DisplayStart } from '../../Helpers/Global/StyledComponents';
import { BsChevronDoubleLeft, BsChevronDoubleRight } from "react-icons/bs";
import { Sidebar as ReactProSidebar } from 'react-pro-sidebar';
import { useSelector } from 'react-redux';
import LogoMenu from './logoMenu';
import { useEffect } from 'react';

const Sidebarbg = styled.div`
    height:100%;
    width:100%;
    position:relative;
`
const SideBarLogo = styled.img`
width:150px;
height:auto;
object-fit:contain;
margin-left:15px;
margin-bottom:15px;
cursor:pointer;
`

const Sidebar = (props) => {
    const { isCollapse, setIsCollapse } = props;
    const [showLogoMenu, setShowLogoMenu] = useState(false);
    const themeColor = useSelector(state => state.app.theme.colors.bg);
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 991) {
                setIsCollapse(true);
            } else {
                setIsCollapse(false);
            }
        };

        // Set initial value based on the current screen size
        handleResize();

        // Listen for window resize events
        window.addEventListener('resize', handleResize);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [setIsCollapse]);

    return (
        <Sidebarbg className='sideBarMenu'>

            <DisplayStart>
                {!isCollapse ?
                    <SideBarLogo onClick={() => setShowLogoMenu(!showLogoMenu)} src="/assets/WhiteLogo.png" />
                    :
                    <SideBarLogo onClick={() => setShowLogoMenu(!showLogoMenu)} style={{ height: '33px', width: 'auto' }} src="/assets/WhiteFavicon.png" />
                }
            </DisplayStart>
            {showLogoMenu && <LogoMenu isCollapse={isCollapse} />}

            <ReactProSidebar width="200px" collapsedWidth="65px" backgroundColor={themeColor} collapsed={isCollapse}>
                <Menu />
            </ReactProSidebar>
        </Sidebarbg>
    );
}

export default Sidebar;