import { FieldArray, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { FaListUl, FaPlus, FaTrash } from "react-icons/fa";
import styled from "styled-components";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { BsToggleBtn, Gear, Remove } from "../../Components/ActionBtns";
import HeaderButton from "../../Components/HeaderButton";
import { AxiosLite, CallAxios } from "../../Helpers";
import { TableWrapper } from "../../Helpers/Global/StyledComponents";
import { useTranslation } from "react-i18next";
import DeleteConfirmationModal from "../../Components/DeleteConfirmationModal";


function DataProfileTab(props) {
    const { t: ast } = useTranslation('accountSetting');
    const { t: ct } = useTranslation('common');


    const [NewDatasetModal, setNewDatasetModal] = useState(false);
    const [Records, setRecords] = useState([]);
    const [RecordIdForEdit, setRecordIdForEdit] = useState(null);
    const [ShowDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

    useEffect(() => {
        getData()
    }, []);

    const getData = async () => {
        var response = await AxiosLite(
            "/api/ProfileData/GetRecordList|get"
        );
        if (!!response && response.status === 200) {
            setRecords(response.data)
        }
    }
    const SetFavouriteRecord = async (objid) => {
        var response = await CallAxios(
            "/api/ProfileData/SetFavouriteRecord|post", { id: objid }
        );
        if (!!response && response.status === 200) {
            getData()
        }
    }
    const SetActiveRecord = async (objid) => {
        var response = await CallAxios(
            "/api/ProfileData/SetActiveRecord|post", { id: objid }
        );
        if (!!response && response.status === 200) {
            getData()
        }
    }
    const DeleteRecord = async (objid) => {
        var response = await CallAxios(
            "/api/ProfileData/DeleteRecord|post", { id: objid }
        );
        if (!!response && response.status === 200) {
            getData()
            setShowDeleteConfirmationModal(false)

        }
    }

    return (
        <>
            {
                ShowDeleteConfirmationModal &&
                <DeleteConfirmationModal show={ShowDeleteConfirmationModal} onHide={setShowDeleteConfirmationModal}
                    title={ast('profileTab.deleteMdl.title')} message={ast('profileTab.deleteMdl.desc')} deletefn={DeleteRecord} objId={RecordIdForEdit} />
            }
            <Row>
                <Col md={12}>
                    <div className="TabFormContainerSection">
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <div className="Heading Heading h5 fw-bold  d-flex align-items-center"> {ast('profileTab.dataProfiles')}</div>
                            <HeaderButton title={ast('profileTab.dataProfiles')} onClick={() => { setRecordIdForEdit(""); setNewDatasetModal(true) }} icon={<FaPlus color='#fff' size={18} />} />
                        </div>
                        <TableWrapper>
                            <Table striped bordered responsive className="DataGridTable overflowVisible">
                                <thead>
                                    <tr className="firsthead">
                                        <th>{ct('title')}</th>
                                        <th>{ast('profileTab.fields')}</th>
                                        <th>{ct('action')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Records.length > 0 ? Records.map((value, key) => {
                                            return <tr>
                                                <td>
                                                    <div className="dataTextCell">{value.title}</div>
                                                </td>
                                                <td>
                                                    <div className="dataTextCell">{value.fields}</div>
                                                </td>
                                                <td>
                                                    <a className={value.isFavourite ? 'btnTblAction bgYellow' : 'btnTblAction'} onClick={() => this.SetFavouriteRecord(value.id)} ><i className="fa fa-star"></i></a>
                                                    {
                                                        value.isdefault === false ?
                                                            <>
                                                                <Gear onClick={(e) => { setRecordIdForEdit(value.id); setNewDatasetModal(true) }} />
                                                                <BsToggleBtn color={value.isActive ? '#2ecc71' : 'gray'} onClick={() => SetActiveRecord(value.id)} />
                                                                {/* <a className={value.isActive ? 'btnTblAction bgGreen' : 'btnTblAction'} onClick={() => SetActiveRecord(value.id)} ><i className={value.isActive ? 'fa fa-toggle-on' : 'fa fa-toggle-off'}></i></a> */}
                                                                <Remove onClick={(e) => { setRecordIdForEdit(value.id); setShowDeleteConfirmationModal(true) }} />
                                                            </>
                                                            : null
                                                    }
                                                </td>
                                            </tr>
                                        })
                                            : null
                                    }
                                </tbody>
                            </Table>
                        </TableWrapper>
                    </div>
                </Col>
            </Row>
            {
                NewDatasetModal === true ?
                    <AddNewDataSetModal show={NewDatasetModal} onHide={setNewDatasetModal} RecordIdForEdit={RecordIdForEdit} getData={getData} />
                    : null
            }
        </>
    );
}

export default DataProfileTab;

function AddNewDataSetModal(props) {
    const { t: ast } = useTranslation('accountSetting');
    const { t: ct } = useTranslation('common');


    useEffect(() => {
        if (!!props.RecordIdForEdit) {
            getData();
        }
    }, [props.RecordIdForEdit]);
    const getData = async () => {
        var response = await CallAxios(
            "/api/ProfileData/GetProfileForm|post", { id: props.RecordIdForEdit }
        );
        if (!!response && response.status === 200) {
            formik.setFieldValue('formid', response.data.formid)
            formik.setFieldValue('title', response.data.title)
            formik.setFieldValue('dataSet', response.data.fields)
        }
    }

    const formik = useFormik({
        initialValues: {
            formid: undefined,
            title: "",
            // regexCheck:false,
            dataSet: [{
                fieldid: undefined,
                title: "",
                type: "string",
                regex: "",
                isMultiSelect: false,
                option: [
                    {
                        fieldOptionId: undefined,
                        fieldOptionvalue: ""
                    }
                ]
            }]
        },
        validationSchema: Yup.object().shape({
            title: Yup.string().required(ast('profileTab.profileMdl.errorMsg.title')),
            // dataSet: addressSchema
        }),
        onSubmit: values => {
            var isAllFieldsValid = true;
            var fieldList = [];
            values.dataSet.map((v, k) => {
                var obj = {
                    fieldid: v.fieldid,
                    type: v.type,
                    title: v.title,
                    regex: v.regex,
                    IsMultiSelect: v.isMultiSelect,
                    option: v.option,
                }
                fieldList.push(obj);
                if (v.title === "") {
                    isAllFieldsValid = false;
                }
            })
            if (!!values.title && isAllFieldsValid === true) {
                var data = {
                    formid: values.formid,
                    title: values.title,
                    Fields: fieldList
                }
                sumitdata(data)
            } else {
                Swal.fire({
                    icon: "error",
                    text: ast('profileTab.profileMdl.errorMsg.name')
                })
            }
        }
    });
    const sumitdata = async (data) => {
        var response = await CallAxios(
            "/api/ProfileData/CreateForm|post", data
        );
        if (!!response && response.data === true) {
            props.getData();
            props.onHide(false)
        }
    }
    return (

        <Modal show={props.show} onHide={() => props.onHide(false)}>
            <Modal.Header closeButton >
                <Modal.Title>{!!props.RecordIdForEdit ? <>{ct('edit')}</> : <>{ct('new')}</>} {ast('profileTab.dataProfiles')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col md={12} >
                            <Form.Group controlId="AddNewDatasetForm.txtTitle">
                                <Form.Label> {ct('title')}</Form.Label>
                                <Form.Control name="title" maxLength={150} onChange={formik.handleChange} value={formik.values.title} type="text" placeholder={ast('profileTab.profileMdl.egAddress')} />
                                {formik.touched.title && formik.errors.title ? (
                                    <span className="error text-danger">{formik.errors.title}</span>
                                ) : null}
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <DocumentsFieldSet formik={formik} />
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btnPrimaryGreen" onClick={formik.handleSubmit}> {ct('finished')}</Button>
            </Modal.Footer>
        </Modal>
    );
}

function DocumentsFieldSet({ formik }) {
    const { t: ast } = useTranslation('accountSetting');
    const { t: sdt } = useTranslation('selectDropdown');


    return (
        <>
            <FormikProvider value={formik}>

                <FieldArray
                    name="dataSet"
                    render={arrayHelpers => {
                        const dataSet = formik.values.dataSet;
                        return (
                            <div>
                                {dataSet && dataSet.length > 0
                                    ? dataSet.map((x, i) => (
                                        <>
                                            <div className="pt-2" key={i}>
                                                <Row>
                                                    <Col md={5}>
                                                        <Form.Group className="mb-2" controlId="DatasetForm.txtSurname">
                                                            <Form.Label> {ast('profileTab.profileMdl.name')}</Form.Label>
                                                            <Form.Control name={`dataSet.${i}.title`} type="text" value={x?.title} onChange={formik.handleChange} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3} className="pl-0">
                                                        <Form.Group className="mb-2" controlId="DatasetForm.SelectType">
                                                            <Form.Label> {sdt('profileTab.labelTitle')}</Form.Label>
                                                            <Form.Control name={`dataSet.${i}.type`} value={x?.type} onChange={formik.handleChange} as="select" className="SelectbgGrey" custom>
                                                                <option value="string">{sdt('profileTab.option1')}</option>
                                                                <option value="float">{sdt('profileTab.option2')}</option>
                                                                <option value="boolean">{sdt('profileTab.option3')}</option>
                                                                <option value="date">{sdt('profileTab.option4')}</option>
                                                                <option value="time">{sdt('profileTab.option5')}</option>
                                                                <option value="country_select">{sdt('profileTab.option6')}</option>
                                                                <option value="dropdown">{sdt('profileTab.option7')}</option>
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={2}>
                                                        <Form.Group className="mb-2" controlId="DatasetForm.txtSurname">
                                                            <Form.Label> {ast('profileTab.profileMdl.regex')}</Form.Label>
                                                            <Form.Control name={`dataSet.${i}.regex`} type="text" value={x?.regex} onChange={formik.handleChange} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={2} className="px-0">
                                                        {x?.type === "dropdown" && <a className="btninlineFormAction bg-info me-2" onClick={() => formik.setFieldValue(`dataSet.${i}.showOptions`, !x?.showOptions)} ><FaListUl /></a>}
                                                        <a className="btninlineFormAction bgRed" onClick={() => arrayHelpers.remove(i)} ><FaTrash /></a>
                                                    </Col>
                                                </Row>

                                                {x?.type === "dropdown" && <MultipleOptions index={i} dataSet={`dataSet.${i}.isMultiSelect`} Parentindex={i} field={`dataSet.${i}.option`} xref={x} formik={formik} />}
                                            </div>

                                        </>
                                    )) : null
                                }
                                <div className="py-3"><a className="AddComponentLink" onClick={() => arrayHelpers.push({ title: "", type: "string", regex: "", isMultiSelect: false, option: [] })}> {ast('profileTab.profileMdl.addField')}</a></div>
                            </div>
                        );
                    }}
                />
            </FormikProvider>
        </>
    );
}
const MultipleWrapper = styled.div`
padding: 20px 50px;
border: 2px solid #BDC3C7;
margin: 15px 0;
`

function MultipleOptions({ formik, field, index, dataSet, Parentindex, xref }) {
    const { t: ast } = useTranslation('accountSetting');

    return (
        <>
            <MultipleWrapper>
                <Row>
                    <Col md={12}>
                        <div className=''>
                            <Form.Group controlId={`formBasicCheckbox_${Parentindex}`}>
                                <Form.Check type="checkbox" onChange={(e) => formik.setFieldValue(dataSet, e.target.checked)} checked={xref?.isMultiSelect} label={ast('profileTab.profileMdl.multipleOptions')} />
                            </Form.Group>
                        </div>
                    </Col>
                    <Col md={12}>
                        <FieldArray
                            name={field}
                            render={arrayHelpers => {
                                const selections = formik?.values?.dataSet[index].option;
                                return (
                                    <div>
                                        {selections && selections.length > 0
                                            ? selections.map((x, i) => {
                                                return <>
                                                    <div className="pt-2" key={i}>
                                                        <Row>
                                                            <Col md={10}>
                                                                <Form.Group className="mb-2" >
                                                                    <Form.Label>&nbsp;</Form.Label>
                                                                    <Form.Control name={`${field}.${i}`} type="text" value={x?.fieldOptionvalue} onChange={(e) => formik.setFieldValue(`${field}.${i}`, { fieldOptionvalue: e.target.value, fieldOptionId: x?.fieldOptionId })} />
                                                                    {/* <Form.Control name={`${field}.${i}.option`} type="text" value={x?.option} onChange={formik.handleChange} /> */}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={2}>
                                                                <a className="btninlineFormAction bgRed" onClick={() => arrayHelpers.remove(i)} ><FaTrash /></a>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </>
                                            }) : null
                                        }
                                        <div className="py-3"><a className="AddComponentLink" onClick={() => arrayHelpers.push({
                                            fieldOptionId: undefined,
                                            fieldOptionvalue: ""
                                        })}> {ast('profileTab.profileMdl.addOption')} </a></div>
                                    </div>
                                );
                            }}
                        />
                    </Col>
                </Row>
            </MultipleWrapper>

        </>
    );
}