import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { CalendarBtn, CheckCircle, Clock, Edit, ExclaimationCircle, Invite, Mail, Remove, RemoveCircle } from '../../Components/ActionBtns';
import DataGrid from '../../Components/DataGrid';
import MessageModal from '../../Components/MessageModal';
import MultiSelect from '../../Components/MultiSelect';
import AddEditVocationQuotaModal from '../../Components/VocationQuotaModal';
import { CallAxios } from '../../Helpers';
import { Constants } from '../../Helpers/Contents';
import { BSelectWrapper } from '../../Helpers/Global/StyledComponents';
import { SignalrHelper } from '../../Helpers/SignalrHelper';
import { useTranslation } from "react-i18next";


function EmployeeGrid(props) {
  const { t: ept } = useTranslation('employeePage');
  const { t: ct } = useTranslation('common');
  const { t: cnt } = useTranslation('content');

  var user = useSelector(x => x.app.user);
  var appSettings = useSelector(x => x.app.appSettings);
  var selectedTags = useSelector(x => x.app.selectedTags);
  var selectedWorkAreas = useSelector(x => x.app.selectedWorkAreas);
  // var RoleHubConnection = SignalrHelper.RoleHubConnection;

  const [showVocationQuotaModal, setShowVocationQuotaModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showInviteModel, setShowInviteModel] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const ChangesRoles = async (selected, id) => {
    var data = {
      roleid: selected,
      empid: parseInt(id)
    }
    var response = await CallAxios("/api/Employee/updateEmprole|post", data)
    if (response.status === 200) {
      if (response.data === true) {
        // await RoleHubConnection.send('SendMessage', { empid: parseInt(id) });
        props.getEmployeeList();
      }
    }
  }
  const ChangesArea = async (selected, id) => {
    var data = {
      empid: parseInt(id),
      areaId: selected
    }
    var response = await CallAxios("/api/Employee/updateEmpArea|post", data)
    if (response.status === 200) {
      props.getEmployeeList();
    }
  }
  const updateTimeTrack = async (values, id) => {

    var data = {
      empid: parseInt(id),
      treckVia: values.value
    }
    var res = await CallAxios("/api/Employee/updateEmpTimeTracking|post", data);
    if (!!res && res.status === 200) {
      props.getEmployeeList()
    }
  }
  const UpdateSelfAssignment = async (id) => {
    var data = {
      id: id.toString()
    }
    var response = await CallAxios('/api/Employee/UpdateSelfAssignShiftRight|post', data)
    if (!!response && response.data === true) {
      props.getEmployeeList()
    }
  }
  const UpdateOwnTimeRecords = async (id) => {
    var data = {
      id: id.toString()
    }
    var response = await CallAxios('/api/Employee/UpdateOwnTimeRecordsRight|post', data)
    if (!!response && response.data === true) {
      props.getEmployeeList()
    }
  }

  const columns = [
    { key: 'id', name: '#.' },
    { key: 'surname', name: ept('tbl.surname'), sortable: true, searchable: true, disableSearch: false },
    { key: 'role', name: ct('role'), sortable: true, searchable: true, disableSearch: true },
    { key: 'areas', name: ept('tbl.areas'), sortable: true, searchable: true, disableSearch: true },
    { key: 'vacation', name: ept('tbl.vacation'), sortable: true, searchable: true, disableSearch: true },
    { key: 'time_tracking', name: ct('timeTracking'), sortable: true, searchable: true, disableSearch: true },
    { key: 'right', name: ept('tbl.right'), sortable: true },
    { key: 'action', name: ct('action') },

  ];

  return (
    <>
      {
        showDeleteModal === true ?
          <DeleteModal show={showDeleteModal} onHide={setShowDeleteModal} getEmployeeList={props.getEmployeeList} />
          : null
      }
      {
        showVocationQuotaModal === true ?
          <AddEditVocationQuotaModal show={showVocationQuotaModal} onHide={setShowVocationQuotaModal} getEmployeeList={props.getEmployeeList} />
          : null
      }
      {
        showMessageModal === true ?
          <MessageModal show={showMessageModal} onHide={setShowMessageModal} EmpList={props.employeeList.filter(x => x.email !== null).map((val, key) => ({ label: val.surname, value: val.empID.toString() }))} />
          : null
      }
      {
        showInviteModel === true ?
          <InviteModel show={showInviteModel} onHide={setShowInviteModel} />
          : null
      }

      <DataGrid
        columns={columns}
        rows={props.employeeList.filter(x => x.empAreas.length === 0 || x.empAreas.find(v => selectedWorkAreas.some(c => c.value === v)) || x.tagids.find(v => selectedTags.some(c => c.value === v)))}
        itemsPerPage={10}
        customBody={(rows) =>
          rows?.map((value, i) => {
            return <tr key={i}>
              <td>{i + 1}</td>
              <td>
                <div className='d-flex align-items-center justify-content-between'>
                  {
                    value.isActive ?
                      <EditableLabel getEmployeeList={props.getEmployeeList} timeTrackingMode={value.timeTrackingMode} value={value.surname} empid={value.empID} tooltipText={ept('syncedWithAppTooltip')} link={`/companies/${user.LocationId}/users/${value.empID}/master_data`} />
                      : <>{ct('inActive')} <a onClick={() => localStorage.setItem("selectedEmpId", value.empID)} href={`/companies/${user.LocationId}/users/${value.empID}/master_data`}>{value.surname}</a></>
                  }
                </div>
              </td>
              <td>
                {
                  value.isActive && user.roles.some(x => x === Constants.roles.AccountAdmin) ?
                    <MultiSelect selectDeselectLabel={ct('selectDeselectAll')} optionKey="value" selected={value.empRoles} handleOnChange={(selected) => ChangesRoles(selected, value.empID)} options={Constants.rolesDropDown.map(x => ({ ...x, label: x.label === "Admin" ? cnt("Area Admin") : cnt(x.label) }))} placeholder={ct('employee')} buttonClass="max275px breakSpaceClass" name={`EmpRole${i}`} />
                    :
                    <MultiSelect selectDeselectLabel={ct('selectDeselectAll')} optionKey="value" selected={value.empRoles} handleOnChange={(selected) => ChangesRoles(selected, value.empID)} options={Constants.rolesDropDown.map(x => ({ ...x, label: x.label === "Admin" ? cnt("Area Admin") : cnt(x.label) }))} placeholder={ct('employee')} name={`EmpRole${i}`} buttonClass="btnDDList smWidth max275px disabled breakSpaceClass" />
                }
              </td>
              <td>
                <EditableDropdown
                  onchange={values => ChangesArea(values, value.empID)}
                  empid={value.empID}
                  tooltipText={appSettings.WorkSpaces.filter(x => value.empAreas.some(c => x.id === c)).map((val) => val.workSpace).join(",")}
                  value={value.empAreas} //selected value
                  listValues={appSettings.WorkSpaces} //listDropdown Values
                  defaultListText="Area"
                  DDName={"EmpArea_" + i}
                  optionLabel="workSpace"
                  optionKey="id"
                  selectDeselectLabel={ct('selectDeselectAll')}
                  isdisabled={value.isActive && user.roles.some(x => x === Constants.roles.AccountAdmin || x.label === Constants.roles.WorkspaceAdmin) ? false : true}
                />

              </td>
              <td>
                {
                  !!value.vocationQuota ?
                    // <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip {...props}>}</Tooltip>}>
                    <div className="d-flex" data-tooltip-id="my-tooltip" data-tooltip-content={ept('untilTheTooltip') + ' ' + `${moment(value.vocationQuota.vocationQuotaDate).format('DD/MM/YYYY')}`}>

                      {value.consumeVocations !== 0 ? value.consumeVocations : "0.0"} / {!!value.vocationQuota ? value.vocationQuota.totalVocation : "0.0"} {ept('days')} <ExclaimationCircle color='#a4a4a4' />
                    </div>
                    // </OverlayTrigger>
                    :
                    user.roles.some(x => x === Constants.roles.AccountAdmin || x.label === Constants.roles.WorkspaceAdmin) ?
                      <div className="dataTextCell">
                        <a href='javascript:;' onClick={(e) => { localStorage.setItem("selectedEmpId", value.empID); setShowVocationQuotaModal(true) }}>{ct('add')}</a>
                      </div>
                      : null
                }
                {/* <div className='d-flex align-items-center justify-content-between'>{value.vacation} <BsExclamationCircle color='#a4a4a4' /></div> */}
              </td>
              <td>
                <Select
                  isDisabled={user.roles.some(x => x === Constants.roles.AccountAdmin || x.label === Constants.roles.WorkspaceAdmin) && value.isActive === true ? false : true}
                  isSearchable={false}
                  classNamePrefix="ReactSelectCustomPrefix"
                  className="ReactSelectCustom disabled"
                  defaultValue={{ label: value.timeTrackingMode, value: value.timeTrackingMode }}
                  onChange={values => updateTimeTrack(values, value.empID)}
                  options={Constants.timeTrackingTypesDropDown.map((x, i) => ({ value: x.value, label: cnt(x.label) }))}
                />
              </td>
              <td>
                <div className='d-flex'>
                  {
                    value.isActive ?
                      value.isAccountAdmin === true ?
                        Constants.roles.AccountAdmin
                        :
                        <>
                          <CheckCircle data-tooltip-id="my-tooltip" data-tooltip-content={ept('selfAssignmentTooltip')} fs={"22px"} val={value.stampFromAnywhere} onClick={() => UpdateSelfAssignment(value.empID)} color={value.stampFromAnywhere ? '#e39541' : "#a4a4a4"} />
                          <CalendarBtn data-tooltip-id="my-tooltip" data-tooltip-content={ept('ownWorkingTimeTooltip')} fs={"18px"} val={value.createOwnTimeRecords} onClick={() => UpdateOwnTimeRecords(value.empID)} empid={value.empID} color={value.createOwnTimeRecords ? '#536afb' : "#a4a4a4"} />
                        </>
                      :
                      <>
                        <CheckCircle className="disabled" data-tooltip-id="my-tooltip" data-tooltip-content={ept('selfAssignmentTooltip')} fs={"22px"} color='#e39541' />
                        <CalendarBtn className="disabled" data-tooltip-id="my-tooltip" data-tooltip-content={ept('ownWorkingTimeTooltip')} fs={"18px"} color='#536afb' />
                      </>
                  }
                </div>
              </td>
              <td>
                <div className='d-flex'>
                  {
                    value.isActive ?
                      <Mail data-tooltip-id="my-tooltip" data-tooltip-content={ept('sendMessageTooltip')} color='#e1b237' fs={"22px"} onClick={() => setShowMessageModal(true)} />
                      :
                      <Mail color='#e1b237' fs={"22px"} />
                  }
                  {
                    value.isAccountAdmin === true ? null
                      : <>
                        {
                          value.isActive ?
                            value.isAccountCreated === false ?
                              !!value.email ?

                                <Invite data-tooltip-id="my-tooltip" data-tooltip-content={ept('inviteTooltip')} fs={"22px"} color='#1b9175' className="btnTblAction bgBlue" onClick={() => { setShowInviteModel(true); localStorage.setItem("selectedEmpId", value.empID); localStorage.setItem("SelectedEmpName", value.surname) }} />
                                :
                                <Invite data-tooltip-id="my-tooltip" data-tooltip-content={ept('inviteTooltip')} fs={"22px"} color='#1b9175' onClick={() =>
                                  toast.warning(ept("errorMsg.noEmail"))
                                } />

                              : null
                            :
                            value.isAccountCreated === false ?
                              <Invite fs={"22px"} color='#1b9175' />
                              : null
                        }
                        <div>
                          {
                            user.roles.some(x => x === Constants.roles.AccountAdmin || x.label === Constants.roles.WorkspaceAdmin) ?
                              <Remove data-tooltip-id="my-tooltip" data-tooltip-content={ct('delete')} fs={"22px"} color='#f25656' onClick={() => { localStorage.setItem("selectedEmpId", value.empID); localStorage.setItem("SelectedEmpName", value.surname); setShowDeleteModal(true) }} />
                              : null
                          }
                        </div>
                      </>
                  }
                </div>
              </td>
            </tr>
          }
          )
        } />
    </>
  );
}

export default EmployeeGrid;

function DeleteModal(props) {
  const { t: ept } = useTranslation('employeePage');
  const { t: ct } = useTranslation('common');


  const DeleteRecord = async () => {

    var data = {
      id: localStorage.getItem("selectedEmpId")
    };

    var response = await CallAxios("/api/Employee/DeleteEmployee|post", data)
    if (!!response && response.status === 200) {
      props.getEmployeeList()
      props.onHide(false)
    }
  }
  return (
    <>
      <Modal show={props.show} onHide={() => props.onHide(false)} size="md">
        <Modal.Header closeButton>
          <Modal.Title><h3> {ept('deleteEmployeeMdl.title')} : {localStorage.getItem("SelectedEmpName")} </h3></Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body lead'>
          <Row>
            <Col md={12}>
              <p>{ept('deleteEmployeeMdl.para')}</p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className='mb-2'>
          <Button variant='secondary' className="" onClick={() => props.onHide(false)}>{ept('deleteEmployeeMdl.interrupt')}</Button>
          <Button variant="danger" className="" onClick={() => DeleteRecord()}>{ct('yesDeleteNow')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

function InviteModel(props) {
  const { t: ept } = useTranslation('employeePage');


  const [inviteLink, setInviteLink] = useState("");
  useEffect(() => {
    getData()
  }, []);
  const getData = async () => {
    var data = {
      id: localStorage.getItem("selectedEmpId"),
    }
    var response = await CallAxios("/api/Employee/GetInviteCode|post", data)
    if (!!response && response.status === 200) {
      setInviteLink(window.location.origin + '/Invites?token=' + response.data)
    }
  }
  const InviteEmployee = async () => {
    var data = {
      id: localStorage.getItem("selectedEmpId"),
    }
    var response = await CallAxios("/api/Employee/InviteEmployee|post", data)

    if (!!response && response.status === 200) {
      toast.success(ept("errorMsg.invitationMailSent"))
      props.onHide(false)
    }
  }
  return (
    <>
      <Modal show={props.show} onHide={() => props.onHide(false)} size="md">
        <Modal.Header closeButton>
          <Modal.Title> {ept('inviteTooltip')} {localStorage.getItem("SelectedEmpName")} </Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body lead'>
          <Row>
            <Col md={12}>
              <p> {ept('inviteMdl.para1')}:</p>

              <p style={{ color: '#27ae60', overflowWrap: 'break-word' }}>{inviteLink}</p>

              <p>{ept('inviteMdl.para2')}</p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className='mb-2'>
          <Button variant='btn btn-primary send_invite_email' className="" onClick={() => InviteEmployee()}> {ept('inviteMdl.inviteEmployeesNow')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
function EditableDropdown(props) {
  const [DropdownState, showDropdown] = useState(false);
  const themeColor = useSelector(state => state.app.theme.colors.bg);
  return <>
    <div className='d-flex align-items-center justify-content-between'>
      {
        !DropdownState &&
        // <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip></Tooltip>}>
        <div data-tooltip-id="my-tooltip" data-tooltip-content={!!props.value && props.value.length > 0 ? props.listValues.filter(i => props.value.some(x => x === i.value)).map(x => x.label).join(",") : null} className="dataTextCell largTextMaxwidth me-3">{
          !!props.value && props.value.length > 0 ? props.listValues.filter(i => props.value.some(x => x === i.value)).map(x => x.label).join(", ") : null
        }
        </div>
        // </OverlayTrigger>
      }
      {
        !DropdownState &&
        <Edit color={themeColor} className="tblTextIcon" onClick={() => showDropdown(true)} />
      }
      {
        DropdownState &&
        <div className="TblDDList Multiselect fullwidthElement">
          <MultiSelect optionKey="value" selected={props.value} selectDeselectLabel={props.selectDeselectLabel} handleOnChange={(s) => props.onchange(s, props.empid)} options={props.listValues} name={props.DDName} buttonClass={props.isdisabled ? "disabled btnDDList lgWidth" : "btnDDList lgWidth"} />
        </div>
      }
    </div>
  </>
};
function BSelect(props) {
  const { options, selected } = props;
  return (
    <BSelectWrapper>
      <Form.Select size='sm' className='CustomBSELECT' aria-label="Default select example">
        {options?.map(x =>
          <option value={x}>{x}</option>
        )}

      </Form.Select>
    </BSelectWrapper>
  );
}
function EditableLabel(props) {
  const [TextboxState, showTextbox] = useState(false);
  const [surName, setSurname] = useState("");
  const themeColor = useSelector(state => state.app.theme.colors.buttons);

  const CloseEdit = async () => {
    var data = {
      empid: parseInt(props.empid),
      name: surName
    }
    var res = await CallAxios("/api/Employee/updateEmpName|post", data)
    if (res.status === 200) {
      props.getEmployeeList();
    }
  }
  return <>
    {!TextboxState && <Edit color={themeColor} onClick={() => { setSurname(props.value); showTextbox(true) }} />}
    {!TextboxState && <Link className="DetailLink" onClick={() => { return localStorage.setItem("selectedEmpId", props.empid) }
    } to={!!props.link ? props.link : "#"} state={{ currentView: 'Admin' }}>{props.value}</Link>}

    {/* onClose={CloseEdit} setSurname={setsurName} */}
    <span className='ps-3'>
      {
        !TextboxState && props.timeTrackingMode === Constants.timeTrackingModes.StampApp ?
          // <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip></Tooltip>}>
          <Clock data-tooltip-id="my-tooltip" data-tooltip-content={props.tooltipText} color='#a4a4a4' />
          // </OverlayTrigger>
          : null
      }
    </span>
    {
      TextboxState &&
      <div className="position-relative d-flex align-items-center">
        <Form.Control onChange={(e) => setSurname(e.target.value)} onKeyPress={(e) => e.which === 13 ? (CloseEdit(), showTextbox(false)) : null} defaultValue={props.value} id={props.empid} type="text" className="EditableTextbox" />
        <RemoveCircle color='#f25656' className="FormControlClose ms-2" onClick={() => { showTextbox(false) }} />
      </div>
    }
  </>
};
