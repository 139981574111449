import interactionPlugin from "@fullcalendar/interaction"; // needed
import FullCalendar from '@fullcalendar/react';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import { useFormik } from 'formik';
import moment from "moment";
import { createRef, useEffect, useState } from "react";
import { ButtonGroup, Table } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import DatePicker from 'react-datepicker';
import { BsChevronLeft, BsChevronRight, BsExclamationCircle } from "react-icons/bs";
import { FaRegCalendarAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { Gear, Remove } from "../../Components/ActionBtns";
import { AxiosLite, CallAxios } from "../../Helpers";
import { Constants } from "../../Helpers/Contents";
import { TableWrapper } from "../../Helpers/Global/StyledComponents";
import { useTranslation } from "react-i18next";
import DeleteConfirmationModal from "../../Components/DeleteConfirmationModal";

function AvailabilitiesTab() {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');


    const { userid } = useParams();
    const user = useSelector(x => x.app.user)
    var MycalendarRef = createRef();

    const [weekNum, setWeekNum] = useState("");
    const [AvailbilityInfoObjIDforEdit, setAvailbilityInfoObjIDforEdit] = useState("");
    const [AbsenceDetailModal, setAbsenceDetailModal] = useState(false);
    const [ShowdeleteAvailabilitiesModel, setShowdeleteAvailabilitiesModel] = useState(false);
    const [events, setEvents] = useState([]);
    const [WorkingtimeModelsList, setWorkingtimeModelsList] = useState([]);
    const [chooseDateModal, setchooseDateModal] = useState(false);
    const [show, setshow] = useState(false);
    const [editmode, seteditmode] = useState(false);
    const [empWorkingModelIdForEdit, setEmpWorkingModelIdForEdit] = useState("");
    const [EmployeeWorkingModelList, setEmployeeWorkingModelList] = useState([]);
    // const [ShowDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState();

    useEffect(() => {
        getEvents()
        GetWorkingtimeModelsList()
        getWeekNum(new Date())
    }, []);

    const getEvents = async () => {
        var data = {
            empId: userid.toString(),
            startdate: moment(MycalendarRef.current.calendar.currentDataManager.data.dateProfile.activeRange.start).startOf('day'),
            enddate: moment(MycalendarRef.current.calendar.currentDataManager.data.dateProfile.activeRange.end).startOf('day'),
        }

        var response = await CallAxios('/api/Calendar/GetEmployeeAvailabilities|post', data)
        if (!!response && response.status === 200) {
            setEvents(response.data)
        }
    }
    const getChooseDate = (date) => {
        getWeekNum(date);
        setchooseDateModal(false)
        MycalendarRef.current.getApi().gotoDate(!!date ? new Date(date) : new Date()); // call a method on the Calendar object
        getEvents()
    }
    const nextMonths = () => {
        let calendar = MycalendarRef.current;
        if (!!calendar) {
            calendar.getApi().next();
            var date = calendar.getApi().getDate();
            getWeekNum(date)
            getEvents()
        }
    }
    const PrevMonths = () => {
        let calendar = MycalendarRef.current;
        if (!!calendar) {
            calendar.getApi().prev();
            var date = calendar.getApi().getDate();
            getWeekNum(date)
            getEvents()
        }
    }
    const deleteWorkingModel = async (id) => {
        var response = await CallAxios("/api/WorkingTimeModel/DeleteEmployeeWorkingModel|post", { id: id });
        if (!!response && response.data === true) {
            GetEmployeeWorkingtimeModelsList()
            getEvents()
        }
    }
    const GetWorkingtimeModelsList = async () => {
        var response = await AxiosLite("/api/WorkingTimeModel/workingModelList|get");
        if (!!response && response.status === 200) {
            setWorkingtimeModelsList(response.data)
            GetEmployeeWorkingtimeModelsList()
        }
    }
    const GetEmployeeWorkingtimeModelsList = async () => {
        var response = await CallAxios("/api/WorkingTimeModel/GetEmployeeWorkingModelList|post", { id: userid });
        if (!!response && response.status === 200) {
            setEmployeeWorkingModelList(response.data)
        }
    }
    const createDragEvent = async (info) => {
        if (!!MycalendarRef.current) {
            var data = {
                empId: userid.toString(),
                StartDate: info.start,
                EndDate: info.end,
                UpdateDDValue: 'single',
            }
            var response = await CallAxios('/api/Calendar/AddEditEmployeeAvailability|post', data)
            if (!!response && response.data !== true) {
                toast.error(response.data)
            }
            getEvents()
        }
    }
    const getWeekNum = (date) => {
        let now = date;
        let onejan = new Date(now.getFullYear(), 0, 1);
        let week = Math.ceil((((now.getTime() - onejan.getTime()) / 86400000) + onejan.getDay() + 1) / 7);
        setWeekNum(week)
    }
    const HandleEventClick = (info) => {
        setAvailbilityInfoObjIDforEdit(info.event._def.extendedProps.avalibilityIds)
        setAbsenceDetailModal(true)
    }
    const deleteAllAvailabilitiesModel = async () => {
        var response = await CallAxios('/api/Calendar/RemoveAllEmployeeAvailability|post', { id: userid.toString() })
        if (!!response && response.status === 200) {
            getEvents()
            setShowdeleteAvailabilitiesModel(false)
        }
    }


    return <>
        {/* {
            ShowDeleteConfirmationModal &&
            <DeleteConfirmationModal show={ShowDeleteConfirmationModal} onHide={setShowDeleteConfirmationModal}
                // title="Delete all availibilities" message="Are you shure you want to clear all availibilities?" deletefn={removechartRow} objId={ReportIdForEdit} />
                title="Delete all availibilities" message="Are you shure you want to clear all availibilities?" />
        } */}
        {
            !!WorkingtimeModelsList && WorkingtimeModelsList.length > 0 ?
                <>
                    <div className='mb-5 d-flex justify-content-between align-items-center'>
                        <h4 className='h5 fw-bold mb-0'> {t('workSchedules')}</h4>
                        {
                            user.roles.some(x => x === Constants.roles.Admin || x === Constants.roles.WorkspaceAdmin || x === Constants.roles.AccountAdmin) ?
                                <button className='btn btn-primary' onClick={(e) => { setEmpWorkingModelIdForEdit(""); seteditmode(false); setshow(true) }}> {t('availbltyTab.addWorkSchedule')}</button>
                                : null
                        }
                    </div>
                    <TableWrapper>
                        <Table striped bordered className="DataGridTable">
                            <thead>
                                <tr className='firsthead'>
                                    <th>{t('availbltyTab.tbl.title')}</th>
                                    <th>{t('availbltyTab.tbl.startDate')}</th>
                                    <th>{t('availbltyTab.tbl.startWithDay')}</th>
                                    {
                                        user.roles.some(x => x === Constants.roles.Admin || x === Constants.roles.WorkspaceAdmin || x === Constants.roles.AccountAdmin) ?
                                            <th>{t('availbltyTab.tbl.action')}</th>
                                            : null
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !!EmployeeWorkingModelList && EmployeeWorkingModelList.length > 0 ? EmployeeWorkingModelList.map((value, key) => {
                                        return <tr>
                                            <td>{value.title}</td>
                                            <td>{value.startDate}</td>
                                            <td>{value.startDay}</td>
                                            {
                                                user.roles.some(x => x === Constants.roles.Admin || x === Constants.roles.WorkspaceAdmin || x === Constants.roles.AccountAdmin) ?
                                                    <td>
                                                        <Gear className='btnTblAction bgBlue' onClick={(e) => { setEmpWorkingModelIdForEdit(value.empWorkingModelId); seteditmode(true); setshow(true) }} />
                                                        <Remove className='btnTblAction bgRed' onClick={(e) => deleteWorkingModel(value.empWorkingModelId)} />
                                                    </td>
                                                    : null
                                            }
                                        </tr>

                                    })
                                        :
                                        null
                                }
                            </tbody>
                        </Table>
                    </TableWrapper>
                </>
                :
                <>
                    <div className='mb-5 d-flex justify-content-between align-items-center'>
                        <h4 className='fw-light'> {t('availbltyTab.workingTimeModels')}</h4>
                    </div>
                    <div class="well text-center">
                        <a href={`/account/${user.CompanyId}/accountsettings/work_schedules`}> {t('availbltyTab.workingTimeModelsPara')}.</a>
                    </div>
                </>
        }
        <div className='mt-3 d-flex justify-content-between align-items-center'>
            <h4 className='fw-bold h5 mb-0'>{t('availabilities')}  <a className='btnTblAction bgRed' onClick={(e) => setShowdeleteAvailabilitiesModel(true)}><Remove /></a></h4>
            <div className="ToolMonthChange ">
                <div>
                    <a><BsChevronLeft onClick={(e) => PrevMonths()} className="btnPrev" /></a>
                </div>
                <div>
                    <div className="MonthChangeContainer" onClick={() => setchooseDateModal(true)}>
                        <FaRegCalendarAlt className="CalIcon" />
                        <span className="MonthTitle">{t('availbltyTab.week')} {weekNum}</span>
                    </div>
                </div>
                <div>
                    <a><BsChevronRight onClick={(e) => nextMonths()} className="btnNext" /></a>
                </div>
            </div>
        </div>



        <FullCalendar
            ref={node => {
                // this.props.refCalander.current = node
                // this.calendarComponentRef.current = node
                MycalendarRef.current = node
            }}
            headerToolbar={{
                left: '',
                center: '',
                right: ''
            }}
            firstDay='1'
            allDaySlot={false}
            // plugins={[resourceTimeGridPlugin, interactionPlugin  ]}
            plugins={[resourceTimeGridPlugin, interactionPlugin]}
            initialView="timeGridWeek"
            slotMinTime='00:00'
            slotMaxTime='23:00'
            slotDuration='01:00' // 1 hours
            // views= {{
            //     resourceTimeGridThreeMonth: {
            //     type: 'resourceTimeGrid',
            //      duration: { week: 1 },
            //     }}
            // }
            editable={true}
            eventStartEditable={true}
            eventResizableFromStart={true}
            eventDurationEditable={true}
            eventResourceEditable={true}
            // { id: 'r1', title: 'April 2021' },,{ id: 'r3', title: 'June 2021' }
            // resources ={[{ id: '"Y8pD9YJCKexw/wBf+f8nuw=="', title: '' }]}

            events={events}
            selectable={true}
            dateClick={() => {
                // alert(2)

                // setAddAbsenceModal(true)
            }}
            eventClick={(i) => {
                HandleEventClick(i)
            }}
            eventDrop={async (info) => {

                var data = {
                    id: info.event._def.extendedProps.avalibilityIds,
                    empId: userid.toString(),
                    StartDate: info.event.start,
                    EndDate: info.event.end,
                    UpdateDDValue: 'single',
                }

                var response = await CallAxios('/api/Calendar/AddEditEmployeeAvailability|post', data)
                getEvents();
                if (!!response && response.data !== true) {
                    toast.error(response.data)
                }
            }}
            eventResize={async (info) => {
                var data = {
                    id: info.event._def.extendedProps.avalibilityIds,
                    empId: userid.toString(),
                    StartDate: info.event.start,
                    EndDate: info.event.end,
                    UpdateDDValue: 'single',
                }
                var response = await CallAxios('/api/Calendar/AddEditEmployeeAvailability|post', data)
                getEvents()
                if (!!response && response.data !== true) {
                    toast.error(response.data)
                }
            }}
            select={(info) => {
                createDragEvent(info)
            }}
        />
        {
            chooseDateModal === true ?
                <ChooseDateModal chose={getChooseDate} show={chooseDateModal} setshow={setchooseDateModal} />
                : null
        }
        {
            show === true ?
                <CreateNewWorkingModal getEvents={getEvents} editmode={editmode} seteditmode={seteditmode} empWorkingModelIdForEdit={empWorkingModelIdForEdit} reloadtable={GetEmployeeWorkingtimeModelsList} show={show} setshow={setshow} />
                : null
        }
        {
            ShowdeleteAvailabilitiesModel ?
                <DeleteAvailabilitiesModel show={ShowdeleteAvailabilitiesModel} onHide={setShowdeleteAvailabilitiesModel} deleteAllAvailabilitiesModel={deleteAllAvailabilitiesModel} />
                : false
        }
        {AbsenceDetailModal === true ?
            <EmployeeAvailabilityDetailModal userid={userid.toString()} getEvents={getEvents} show={AbsenceDetailModal} onHide={setAbsenceDetailModal} AvailbilityInfoObjIDforEdit={AvailbilityInfoObjIDforEdit} />
            : null
        }

    </>
}

export default AvailabilitiesTab;
export function ChooseDateModal(props) {//used in Avalibility calender also
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');

    const [startDate, setStartDate] = useState(null);

    return (
        <Modal show={props.show} onHide={() => props.setshow(false)} size="sm">
            <Modal.Header closeButton >
                <Modal.Title>{ct("chooseDate")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={12} className="m-auto InlineDatePickerContainer">
                        <DatePicker ClassName="form-control" showWeekNumbers selected={startDate} onChange={date => { props.chose(date); setStartDate(date) }} inline />
                        {/* <ChooseDate getDate={props.chose} /> */}
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}
function CreateNewWorkingModal(props) {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');

    const { userid } = useParams();
    const [WorkingtimeModelsList, setWorkingtimeModelsList] = useState([]);
    const [WorkingModelWorkDays, setWorkingModelWorkDays] = useState([]);

    useEffect(() => {
        getData();
    }, [props.empWorkingModelIdForEdit]);
    const getData = async () => {
        var WorkSchedulelList = await AxiosLite("/api/WorkingTimeModel/GetWorkSchedulelList|get")
        if (!!WorkSchedulelList && WorkSchedulelList.status === 200) {
            setWorkingtimeModelsList(WorkSchedulelList.data)
            if (WorkSchedulelList.data.length > 0) {
                formik.setFieldValue("working_time_model", WorkSchedulelList.data[0].value)
                getWorkingScheduleDetail(WorkSchedulelList.data[0].value)
            }
        }
        if (!!props.empWorkingModelIdForEdit && props.editmode === true) {
            var response = await CallAxios("/api/WorkingTimeModel/GetEmployeeWorkingModel|post", { id: props.empWorkingModelIdForEdit })
            if (!!response && response.status === 200) {
                formik.setFieldValue("working_time_model", response.data.workingModelId)
                formik.setFieldValue("startDate", new Date(response.data.startDate))
                formik.setFieldValue("startWithDay", response.data.workingTimeWorkDayId)
            }
        }
    }
    const formik = useFormik({
        initialValues: {
            working_time_model: "",
            startDate: new Date(),
            startWithDay: ""

        },
        onSubmit: values => {
            submit(values)
        },
    });
    const submit = async (values) => {
        var data = {
            empId: 0,
            WorkingModelId: values.working_time_model,
            StartDate: moment(values.startDate).startOf("day"),
            WorkingTimeWorkDayId: values.startWithDay,
            empWorkingModelId: null,
        }
        if (!!props.empWorkingModelIdForEdit && props.editmode === true) {
            data.empWorkingModelId = props.empWorkingModelIdForEdit
        }
        else {
            data.empId = parseInt(userid);
        }
        var response = await CallAxios("/api/WorkingTimeModel/AddEditEmployeeWorkingModel|post", data)
        if (!!response && response.data === true) {
            props.reloadtable()
            props.getEvents()
            props.seteditmode(false);
            props.setshow(false)
        }
    }
    const getWorkingScheduleDetail = async (WorkScheduleId) => {
        var WorkSchedulelWorkDayList = await CallAxios("/api/WorkingTimeModel/GetWorkingSchedulaWorkDaysList|post", { id: WorkScheduleId })
        if (!!WorkSchedulelWorkDayList && WorkSchedulelWorkDayList.status === 200) {
            setWorkingModelWorkDays(WorkSchedulelWorkDayList.data)
            if (WorkSchedulelWorkDayList.data.length > 0) {
                formik.setFieldValue("startWithDay", WorkSchedulelWorkDayList.data[0].value)
            }
        }
    }
    useEffect(() => {
        if (!!formik.values.working_time_model) {
            getWorkingScheduleDetail(formik.values.working_time_model)
        }
    }, [formik.values.working_time_model]);
    return (
        <Modal show={props.show} onHide={() => { props.seteditmode(false); props.setshow(false) }}>
            <Modal.Header closeButton >
                <Modal.Title>{!!props.empWorkingModelIdForEdit && props.editmode === true ? <>{ct('edit')}</> : <>{ct('new')}</>} {t('availbltyTab.newWorkScheduleMdl.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={12} >
                        <Form.Group controlId="NewShiftForm.txtDay">
                            <Form.Label> {t('workSchedules')} </Form.Label>
                            <select className="form-control" name="working_time_model" onChange={formik.handleChange} value={formik.values.working_time_model}>
                                {
                                    !!WorkingtimeModelsList && WorkingtimeModelsList.length > 0 ?
                                        WorkingtimeModelsList.map((value, key) => {
                                            return <option value={value.value}>{value.text}</option>
                                        })
                                        :
                                        null
                                }
                            </select>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="NewPeriodForm.txtBeginning">
                            <Form.Label> {t('availbltyTab.newWorkScheduleMdl.startDate')}</Form.Label>
                            <DatePicker className="form-control" name="startDate" onChange={(date) => formik.setFieldValue("startDate", date)} selected={formik.values.startDate} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="NewPeriodForm.txtend">
                            <Form.Label> {t('availbltyTab.newWorkScheduleMdl.startDay')}</Form.Label>
                            <select className="form-control" name="startWithDay" onChange={formik.handleChange} value={formik.values.startWithDay}>
                                {
                                    !!WorkingModelWorkDays && WorkingModelWorkDays.length > 0 ?
                                        WorkingModelWorkDays.map((value, key) => {
                                            return <option value={value.value}>{key + 1}: {value.text}</option>
                                        })
                                        :
                                        null
                                }
                            </select>
                        </Form.Group>
                    </Col>

                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btnPrimaryGreen" onClick={formik.handleSubmit}> {ct('finished')}</Button>
            </Modal.Footer>
        </Modal>

    );
}

export function EmployeeAvailabilityDetailModal(props) { //used in Avalibility calender also
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');

    const [starttimehour, setstarttimehour] = useState("");
    const [startdateMin, setStartdateMin] = useState("");
    const [EndHour, setEndHour] = useState("");
    const [EndMin, setEndMin] = useState("");
    const [StartDate, setDate] = useState(null);
    const [EndDate, setEndDate] = useState(null);
    const [mode, setMode] = useState("");

    const [DEleteOrUpdateSelectionModel, SetDEleteOrUpdateSelectionModel] = useState(false);
    const [TypeOfDEleteOrUpdateSelectionModel, setTypeOfDEleteOrUpdateSelectionModel] = useState("");


    const [ShowRepetationModel, setShowRepetationModel] = useState(false);


    const [RulesDropdown, setRulesDropdown] = useState("weekly");
    const [monthlyRhythm, setmonthlyRhythm] = useState("weekday");
    const [interval, setinterval] = useState(1);
    const [days, setdays] = useState("MON,TUE,WED,THU,FRI");
    const [summary, setsummary] = useState("Weekly every Monday, Tuesday, Wednesday, Thursday, Friday");
    const [isNeverEnds, setisNeverEnds] = useState(true);
    const [AfterNoofAppointments, setAfterNoofAppointments] = useState(null);
    const [AtTheDate, setAtTheDate] = useState(null);
    const [IsAfterNoofAppointments, setIsAfterNoofAppointments] = useState(false);
    const [IsAtTheDate, setIsAtTheDate] = useState(false);
    const [datasetforrepetitionModal, setdatasetforrepetitionModal] = useState({});
    const [Schedularid, setSchedularid] = useState(null);

    const [nextSummary, setNextSummary] = useState("");

    useEffect(() => {
        if (!!props.AvailbilityInfoObjIDforEdit) {
            getdata()
        }
    }, [props.AvailbilityInfoObjIDforEdit]);
    const getdata = async () => {
        var response = await CallAxios('/api/Calendar/GetEmployeeAvailability|post', { id: props.AvailbilityInfoObjIDforEdit })
        if (!!response && response.status === 200) {
            setstarttimehour(response.data.startTimeHour)
            setStartdateMin(response.data.startTimeMin)
            setEndHour(response.data.endTimeHour)
            setEndMin(response.data.endTimeMin)
            setDate(new Date(response.data.startDate))
            setEndDate(new Date(response.data.endDate))

            // RepetitionModal props
            setSchedularid(response.data.schedularid)
            setRulesDropdown(response.data.rule)
            setmonthlyRhythm(response.data.monthlyRhythm)
            setDate(response.data.startDate)
            setinterval(response.data.interval)
            setdays(response.data.daysOfWeek)
            setisNeverEnds(response.data.isNeverEnds)
            setAfterNoofAppointments(response.data.afterNoofAppointments)
            setAtTheDate(response.data.atTheDate)
            setIsAfterNoofAppointments(response.data.isAfterNoofAppointments)
            setIsAtTheDate(response.data.isAtTheDate)

            setdatasetforrepetitionModal({
                RulesDropdown: response.data.rule,
                monthlyRhythm: response.data.monthlyRhythm,
                startDate: new Date(response.data.dayDate),
                EndDate: new Date(response.data.dayDateEnd),
                interval: response.data.interval,
                days: response.data.daysOfWeek,
                isNeverEnds: response.data.isNeverEnds,
                AfterNoofAppointments: response.data.afterNoofAppointments,
                AtTheDate: response.data.atTheDate,
                IsAfterNoofAppointments: response.data.isAfterNoofAppointments,
                IsAtTheDate: response.data.isAtTheDate
            })
            getValuesofRules(response.data.rule, response.data.daysOfWeek, response.data.startDate)
            getNEXTSummary(response.data)
            if (!!response.data.schedularid) {
                setMode("normal")
            } else {
                setMode("dayclick")
            }
        }
    }
    const getNEXTSummary = (data) => {
        let IsAfterNoofAppointmentsOrAtTheDate = data.isAfterNoofAppointments === true ? "afterNapppoinment" : data.isAtTheDate === true ? "Atthedate" : false;
        setNextSummary(data.isNeverEnds === false ? IsAfterNoofAppointmentsOrAtTheDate === "Atthedate" ? !!data.atTheDate ? ", till " + moment(data.atTheDate).format("DD/MM/YYYY") : "" : !!data.afterNoofAppointments ? (", " + data.afterNoofAppointments + " times") : "" : "");
    }
    const getValuesofRules = (e, days) => {

        switch (e) {
            case "daily":
                setsummary(e)
                break;
            case "yearly":
                setsummary(e)
                break;
            case "monthly":
                setMonthlySummary(StartDate, "Weekday");
                break;
            case "weekly":
                setWeeklySummary(days);
                break;
            default:
                break;
        }
    }
    const setWeeklySummary = (days) => {

        if (!!days) {
            let getdays = getDays(days);
            const filteredByValue = Object.fromEntries(Object.entries(getdays).filter(([key, value]) => value === true));

            if (isEmpty(filteredByValue)) {
                setsummary("Weekly")

            } else {

                setsummary("Weekly on " + Object.keys(filteredByValue).map((valu) => " " + valu + " "))
            }
        }
    }
    const setMonthlySummary = (date, type) => {

        if (type === 'DayofMonth') {
            setmonthlyRhythm("DayofMonth");
            let day = moment(date).format("w");
            setsummary("Monthly on the " + moment(date).format("Do [day of the month]"));
            setDate(date)
        }
        else if (type === 'Weekday') {
            setWeekdaySummary(StartDate)
        }
    }
    const setWeekdaySummary = (date) => {
        let dayStr = moment(date).format('YYYY-MM-DD');
        setsummary(test(moment(dayStr)))

    }
    function test(mJsDate) {

        let convection = "";
        switch (Math.ceil(mJsDate.date() / 7)) {
            case 1:
                convection = "st";
                break;
            case 2:
                convection = "nd";
                break;
            case 3:
                convection = "rd";
                break;
            default:
                convection = "th";
                break;
        }


        var str = "Monthly on the " + Math.ceil(mJsDate.date() / 7) + convection + " " + moment(mJsDate.toLocaleString()).format("dddd")

        return str;
    }
    const isEmpty = (obj) => {
        return Object.keys(obj).length === 0;
    }
    const getDays = (days) => {

        let activeButtons = { Monday: true, Tuesday: true, Wednesday: true, Thursday: true, Friday: true, Saturday: false, Sunday: false };
        if (!!days && days.length > 0) {
            let newobj = {};
            for (let i = 0; i < Object.keys(activeButtons).length; i++) {
                const gett = Object.keys(activeButtons)[i];
                if (days.split(",").some((item) => item.includes(gett.toUpperCase().substring(0, 3)))) {

                    newobj = { ...newobj, [gett]: true }

                } else {
                    newobj = { ...newobj, [gett]: false }
                }
            }
            return newobj;
        }
    }
    const Remove = async (val) => {
        var data = {
            AvailabilityId: props.AvailbilityInfoObjIDforEdit,
            deleteValue: !!val ? val : "single",
            deletefrom: StartDate
        }
        var response = await CallAxios('/api/Calendar/RemoveEmployeeAvailability|post', data)
        if (!!response && response.status === 200) {
            props.getEvents();
            props.onHide(false)
        }
    }
    const Save = async (val) => {
        var startDateTime = moment(StartDate).startOf('day')
            .add(parseInt(starttimehour), 'hours')
            .add(parseInt(startdateMin), 'minutes');

        var endDateTime = moment(EndDate).startOf('day')
            .add(parseInt(EndHour), 'hours')
            .add(parseInt(EndMin), 'minutes');

        var data = {
            Id: props.AvailbilityInfoObjIDforEdit,
            empId: props.userid,
            StartDate: startDateTime,
            EndDate: endDateTime,
            UpdateDDValue: !!val ? val : "single",
            Schedularid: Schedularid,
            Rule: RulesDropdown,
            interval: interval,
            monthlyRhythm: monthlyRhythm,
            isNeverEnds: isNeverEnds,
            daysOfWeek: days,
            IsAtTheDate: IsAtTheDate,
            AtTheDate: AtTheDate,
            IsAfterNoofAppointments: IsAfterNoofAppointments,
            AfterNoofAppointments: AfterNoofAppointments
        }
        var response = await CallAxios('/api/Calendar/AddEditEmployeeAvailability|post', data)
        if (!!response && response.status === 200) {
            if (response.data === true) {
                props.getEvents();
                props.onHide(false)
            } else {
                toast.error(response.data)
            }
        }
    }
    const ClickSubmit = (val) => {
        if (TypeOfDEleteOrUpdateSelectionModel === "Submit") {
            Save(val)
        } else {
            Remove(val)
        }
    }
    const handleDeleteUpdateModal = (val) => {
        if (Schedularid === null) {
            if (val === "Submit") {
                Save()
            } else {
                Remove()
            }
        }
        else {
            SetDEleteOrUpdateSelectionModel(true)
        }
    }
    return (
        <>
            {
                ShowRepetationModel === true ?
                    <RepetitionModal
                        ParentModel={props.onHide}
                        userid={props.userid}
                        getEvents={props.getEvents}
                        DayDate={StartDate}
                        StartHours={starttimehour}
                        StartMin={startdateMin}
                        EndHours={EndHour}
                        EndMin={EndMin}
                        AvailbilityInfoObjIDforEdit={props.AvailbilityInfoObjIDforEdit}
                        dataset={datasetforrepetitionModal}
                        OccupancyModal={props.OccupancyModal}
                        handleHideEditShiftModal={props.handleHideEditShiftModal}
                        calenderDate={props.calenderDate}
                        Schedularid={Schedularid}
                        show={ShowRepetationModel}
                        setIsAtTheDate={setIsAtTheDate}
                        setIsAfterNoofAppointments={setIsAfterNoofAppointments}
                        setAfterNoofAppointments={setAfterNoofAppointments}
                        setAtTheDate={setAtTheDate}
                        setisNeverEnds={setisNeverEnds}
                        fnsetsummary={setsummary}
                        setdays={setdays}
                        setinterval={setinterval}
                        setstartDate={setDate}
                        setmonthlyRhythm={setmonthlyRhythm}
                        setRulesDropdown={setRulesDropdown}
                        setShowRepetationModel={setShowRepetationModel}
                        handleModalShowHide={props.handleModalShowHide}
                    />
                    : null
            }
            {
                DEleteOrUpdateSelectionModel === true ?
                    <Modal show={DEleteOrUpdateSelectionModel} onHide={() => SetDEleteOrUpdateSelectionModel(false)} >
                        <Modal.Header className="RelativeModalHeader" closeButton >
                            <Modal.Title className="text-break">
                                {TypeOfDEleteOrUpdateSelectionModel === "Submit" ? <>{t('availbltyTab.deleteAvailbltyMdl.editAvailbltyTitle')}</> : <>{t('availbltyTab.deleteAvailbltyMdl.deleteAvailbltyTitle')}</>}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <Button className="btn btnPrimaryGreen" onClick={() => ClickSubmit("single")}> {t('availbltyTab.deleteAvailbltyMdl.onlyAvailability')}</Button>
                                    </div>
                                    <div className='col-md-8'>
                                        {t('availbltyTab.deleteAvailbltyMdl.onlyAvailabilityPara')}.
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <Button className="btn btnPrimaryGreen" onClick={() => ClickSubmit("future")}>  {t('availbltyTab.deleteAvailbltyMdl.futureAvailabilities')} </Button>
                                    </div>
                                    <div className='col-md-8'>
                                        {t('availbltyTab.deleteAvailbltyMdl.futureAvailabilitiesPara')}.
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <Button className="btn btnPrimaryGreen" onClick={() => ClickSubmit("all")}> {t('availbltyTab.deleteAvailbltyMdl.allAvailabilities')}</Button>
                                    </div>
                                    <div className='col-md-8'>
                                        {t('availbltyTab.deleteAvailbltyMdl.allAvailabilitiesPara')}.
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    : null
            }
            <Modal show={props.show} onHide={() => props.onHide(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> {t('availbltyTab.editAvailbltyMdl.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12} >
                            <Row>
                                <Col md={6}>

                                    <Form.Group controlId="AddAbsenceForm.txtStartTime">
                                        <Form.Label> {t('availbltyTab.editAvailbltyMdl.starts')}</Form.Label>
                                        <div className="flexInputGroup d-flex align-items-center">
                                            <Form.Control as="select" className="Noarrow bgPrimaryGrey" value={starttimehour} onChange={(e) => setstarttimehour(e.target.value)} custom>
                                                {
                                                    Constants.hoursDropDown.map((val, key) => {
                                                        return <option key={"startTimeHour" + key} value={val.value}>{val.text}</option>
                                                    })
                                                }
                                            </Form.Control>
                                            <span className="seperator mx-2"> : </span>
                                            <Form.Control as="select" className="Noarrow bgPrimaryGrey" value={startdateMin} onChange={(e) => setStartdateMin(e.target.value)} custom>
                                                {
                                                    Constants.minutesDropDown.map((val, key) => {
                                                        return <option key={"startdateMin" + key} value={val.value}>{val.text}</option>
                                                    })
                                                }
                                            </Form.Control>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="NewShiftForm.txtEndTime">
                                        <Form.Label> {t('availbltyTab.editAvailbltyMdl.endsAt')}</Form.Label>
                                        <div className="flexInputGroup d-flex align-items-center">
                                            <Form.Control as="select" className="Noarrow bgPrimaryGrey" value={EndHour} onChange={(e) => setEndHour(e.target.value)} custom>
                                                {
                                                    Constants.hoursDropDown.map((val, key) => {
                                                        return <option key={"EndHour" + key} value={val.value}>{val.text}</option>
                                                    })
                                                }
                                            </Form.Control>
                                            <span className="seperator mx-2"> :  </span>
                                            <Form.Control as="select" className="Noarrow bgPrimaryGrey" value={EndMin} onChange={(e) => setEndMin(e.target.value)} custom>
                                                {
                                                    Constants.minutesDropDown.map((val, key) => {
                                                        return <option key={"EndMin" + key} value={val.value}>{val.text}</option>
                                                    })
                                                }
                                            </Form.Control>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12}>
                            <div className="form-group">
                                <label htmlFor="" className="d-block fw-bold"> {t('availbltyTab.editAvailbltyMdl.recurrence')}</label>
                                <div className="flexInputGroup" >
                                    <span onClick={() => setShowRepetationModel(true)}>
                                        {
                                            mode === "normal" ? <div className="ActionText">{summary} {nextSummary} </div> : <div className="ActionText"> {t('availbltyTab.editAvailbltyMdl.createRepetition')}</div>
                                        }
                                    </span>
                                </div>
                                {/* <a href="javascript:;" onClick={() => setShowRepetationModel(true)} className=' fw-bold colorGreenPrimary text-decoration-none'>No recurrence</a> */}
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='justify-content-between'>
                    <Button className="btn btn-sm m-0 " onClick={() => { setTypeOfDEleteOrUpdateSelectionModel("Remove"); handleDeleteUpdateModal("Remove") }}> {t('availbltyTab.editAvailbltyMdl.removeAvailability')} </Button>

                    <div className='m-0'>
                        <Button className="btn btn-sm  me-2" onClick={() => props.onHide(false)}> {ct('close')} </Button>
                        <Button className="btn btn-sm btnPrimaryGreen" onClick={() => { setTypeOfDEleteOrUpdateSelectionModel("Submit"); handleDeleteUpdateModal("Submit"); }}> {ct('submit')}</Button>
                    </div>

                </Modal.Footer>
            </Modal>
        </>
    )
}
function DeleteAvailabilitiesModel(props) {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');


    return (
        <Modal show={props.show} className="" onHide={() => props.onHide(false)}>
            <Modal.Header closeButton className="">
                <Modal.Title> {t('availbltyTab.deleteAllAvailbltyMdl.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>  {t('availbltyTab.deleteAllAvailbltyMdl.clearPara')}?</p>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btnPrimaryGreen text-white" onClick={(e) => props.onHide(false)}>{ct('cancel')}</button>
                <button className="btn btnPrimaryGreen text-white" onClick={() => props.deleteAllAvailabilitiesModel()}> {ct('yesDeleteNow')}</button>
            </Modal.Footer>
        </Modal>
    );
}

function RepetitionModal(props) { //used in Avalibility calender also
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');
    const { t: sdt } = useTranslation('selectDropdown');

    const intervalText = {
        daily: <span className="seperator">{ct('day')}(s)</span>,
        weekly: <span className="seperator">{ct('week')}(s)</span>,
        monthly: <span className="seperator">{ct('month')}(s)</span>,
        yearly: <span className="seperator">{ct('year')}(s)</span>,
    }
    const [RulesDropdown, setRulesDropdown] = useState("weekly");
    const [monthlyRhythm, setmonthlyRhythm] = useState("Weekday");
    const [startDate, setstartDate] = useState(props.startDate);
    const [interval, setinterval] = useState(1);
    const [activeButtons, setactiveButtons] = useState({ Monday: true, Tuesday: true, Wednesday: true, Thursday: true, Friday: true, Saturday: false, Sunday: false });
    const [summary, setsummary] = useState("Daily");
    const [isNeverEnds, setisNeverEnds] = useState(false);
    const [AfterNoofAppointments, setAfterNoofAppointments] = useState(null);
    const [AtTheDate, setAtTheDate] = useState(null);
    const [IsAfterNoofAppointmentsOrAtTheDate, setIsAfterNoofAppointmentsOrAtTheDate] = useState("");
    const [deleteShiftModal, setdeleteShiftModal] = useState(false);
    const [Updatedropdownvalue, setUpdatedropdownvalue] = useState("future");
    const [IsAtTheDate, setIsAtTheDate] = useState(false);
    useEffect(() => {
        if (!!props.dataset && Object.keys(props.dataset).length > 0) {
            let data = props.dataset;
            if (!!props.Schedularid) {
                setmonthlyRhythm(data.monthlyRhythm);
                setRulesDropdown(data.RulesDropdown);
                setinterval(data.interval);
                setisNeverEnds(data.isNeverEnds);
                setstartDate(data.startDate);
                setIsAtTheDate(data.IsAtTheDate);
            } else {
                setinterval(1);
                setisNeverEnds(true);
            }
            setAfterNoofAppointments(!!data.AfterNoofAppointments ? data.AfterNoofAppointments : null);
            if (!!data.AtTheDate) {
                setAtTheDate(new Date(data.AtTheDate));
            }
            setIsAfterNoofAppointmentsOrAtTheDate(data.IsAfterNoofAppointments === true ? "afterNapppoinment" : data.IsAtTheDate === true ? "Atthedate" : false);

            if (!!data.days && data.days.length > 0) {
                let newobj = {};
                for (let i = 0; i < Object.keys(activeButtons).length; i++) {
                    const gett = Object.keys(activeButtons)[i];
                    if (data.days.split(",").some((item) => item.includes(gett.toUpperCase().substring(0, 3)))) {

                        newobj = { ...newobj, [gett]: true }

                    } else {
                        newobj = { ...newobj, [gett]: false }
                    }
                }
                setactiveButtons(newobj)
            }
        }
    }, [props.dataset]);
    useEffect(() => {
        setUpdatedropdownvalue("future")
    }, []);
    const UpdateShiftbtn = async () => {
        var startDateTime = moment(props.DayDate).startOf('day')
            .add(parseInt(props.StartHours), 'hours')
            .add(parseInt(props.StartMin), 'minutes');

        var endDateTime = moment(props.DayDate).startOf('day')
            .add(parseInt(props.EndHours), 'hours')
            .add(parseInt(props.EndMin), 'minutes');

        var data = {
            empId: props.userid,
            Schedularid: props.Schedularid,
            Id: props.AvailbilityInfoObjIDforEdit,
            StartDate: startDateTime,
            EndDate: endDateTime,
            Rule: RulesDropdown,
            interval: parseInt(interval),
            monthlyRhythm: monthlyRhythm,
            isNeverEnds: isNeverEnds,
            daysOfWeek: [],
            IsAtTheDate: IsAtTheDate,
            AtTheDate: !!AtTheDate ? AtTheDate : null,
            IsAfterNoofAppointments: IsAfterNoofAppointmentsOrAtTheDate,
            AfterNoofAppointments: !!AfterNoofAppointments ? parseInt(AfterNoofAppointments) : null,
            UpdateDDValue: Updatedropdownvalue,
        }
        let arr = [];
        Object.entries(activeButtons).map(([key, value]) => value === true ? arr.push(key.toUpperCase().substring(0, 3)) : console.log(key, value));
        data.daysOfWeek = (arr.join(','));

        if (IsAfterNoofAppointmentsOrAtTheDate === "afterNapppoinment") {
            data.IsAfterNoofAppointments = true;
            data.IsAtTheDate = false;
        }
        if (IsAfterNoofAppointmentsOrAtTheDate === "Atthedate") {
            data.IsAfterNoofAppointments = false;
            data.IsAtTheDate = true;
        }
        if (IsAfterNoofAppointmentsOrAtTheDate === "") {
            data.IsAfterNoofAppointments = false;
            data.IsAtTheDate = false;
        }
        var response = await CallAxios('/api/Calendar/UpdateAvailabilitySchedular|post', data);
        if (!!response && response.status === 200) {
            props.getEvents()
            props.ParentModel(false)
        }
    }
    const saveDataToBaseModel = () => {
        if (!!props.Schedularid) {
            setdeleteShiftModal(true)
        } else {
            UpdateShiftbtn()
        }
    }
    useEffect(() => {
        setWeeklySummary();
    }, [activeButtons]);
    const changingButtonState = (e) => {
        props.setdays()
        setactiveButtons({
            ...activeButtons,
            [e.target.name]: !activeButtons[e.target.name]
        })

    }
    const getValuesofRules = (e) => {
        setRulesDropdown(e.target.value);
        switch (e.target.value) {
            case "daily":
                setsummary(e.target.value)
                break;
            case "yearly":
                setsummary(e.target.value)
                break;
            case "monthly":
                setMonthlySummary(startDate, "Weekday");
                break;
            case "weekly":
                setWeeklySummary();
                break;
            default:
                break;
        }
    }
    const setWeeklySummary = () => {
        const filteredByValue = Object.fromEntries(
            Object.entries(activeButtons).filter(([key, value]) => value === true));
        if (isEmpty(filteredByValue)) {
            setsummary("Weekly")
        } else {
            setsummary("Weekly on " + Object.keys(filteredByValue).map((valu) => " " + valu + " "))
        }
    }
    const isEmpty = (obj) => {
        return Object.keys(obj).length === 0;
    }
    const setMonthlySummary = (date, type) => {
        if (type === 'DayofMonth') {
            setmonthlyRhythm("DayofMonth");
            let day = moment(date).format("w");
            setsummary("Monthly on the " + moment(date).format("Do [day of the month]"));
            setstartDate(date)
        }
        else if (type === 'Weekday') {
            setWeekdaySummary(startDate)
        }
    }
    const setWeekdaySummary = (date) => {
        let dayStr = moment(date).format('YYYY-MM-DD');
        setsummary(test(moment(dayStr)))
    }
    function test(mJsDate) {
        let convection = "";
        switch (Math.ceil(mJsDate.date() / 7)) {
            case 1:
                convection = "st";
                break;
            case 2:
                convection = "nd";
                break;
            case 3:
                convection = "rd";
                break;
            default:
                convection = "th";
                break;
        }
        var str = "Monthly on the " + Math.ceil(mJsDate.date() / 7) + convection + " " + moment(mJsDate.toLocaleString()).format("dddd")
        return str;
    }
    const updateMonthlyrythm = (date, type) => {
        if (type === 'DayofMonth') {
            if (!!date) {
                date = startDate
            }
            setmonthlyRhythm("DayofMonth");
            let day = moment(date).format("w");
            setsummary("Monthly on the " + moment(date).format("Do [day of the month]"));
            setstartDate(date)
        }
        else {
            setWeekdaySummary(startDate)
        }
        if (!!type) {
            setmonthlyRhythm(type)
        } else {
            setmonthlyRhythm(monthlyRhythm)
        }
    }
    const getNEXTSummary = () => {
        return isNeverEnds === false ? IsAfterNoofAppointmentsOrAtTheDate === "Atthedate" ? !!AtTheDate ? ", till " + moment(AtTheDate).format("DD/MM/YYYY") : "" : !!AfterNoofAppointments ? (", " + AfterNoofAppointments + " times") : "" : ""
    }

    const AlertValue = {
        single: t('availbltyTab.deleteShiftMdl.alertSingle').toString(),
        future: t('availbltyTab.deleteShiftMdl.alertFuture').toString(),
        all: t('availbltyTab.deleteShiftMdl.alertAll').toString(),
    }


    return (
        <>
            {
                deleteShiftModal === true ?
                    <Modal show={deleteShiftModal} onHide={() => setdeleteShiftModal(false)} >
                        <Modal.Header className="RelativeModalHeader text-break" closeButton >
                            <Modal.Title> {t('availbltyTab.deleteShiftMdl.title')}?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <label className='fw-bolder mb-1'> {sdt('availbltyTab.iWantUpdate.labelTitle')}</label>
                                <select className='Noarrow bgPrimaryGrey text-center form-select custom-select mb-3' value={Updatedropdownvalue} onChange={(e) => setUpdatedropdownvalue(e.target.value)}>
                                    <option value="future"> {sdt('availbltyTab.iWantUpdate.option1')}</option>
                                    <option value="all"> {sdt('availbltyTab.iWantUpdate.option2')}</option>
                                </select>
                                <div className="alert alert-primary px-3" role="alert">
                                    <div className='row'>
                                        <div className='col-md-1'>
                                            <BsExclamationCircle style={{ fontSize: "26px" }} />
                                        </div>
                                        <div className='col-md-11'>
                                            {AlertValue[Updatedropdownvalue]}

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <a className='text-danger me-3' onClick={() => setdeleteShiftModal(false)}> {ct('cancel')} </a>
                            <Button className="btn btnPrimaryGreen" onClick={() => UpdateShiftbtn()}> {ct('update')} </Button>
                        </Modal.Footer>
                    </Modal>
                    : null
            }
            <Modal show={props.show} onHide={() => { props.setShowRepetationModel(false) }}>
                <Modal.Header closeButton >
                    <Modal.Title> {t('availbltyTab.editRepeatMdl.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={12} >
                                <Form.Group controlId="RepetitionShiftForm.SelectRule">
                                    <Form.Label>  {sdt('availbltyTab.rule.labelTitle')}</Form.Label>
                                    <Form.Control as="select" value={RulesDropdown} onChange={(e) => getValuesofRules(e)} custom>
                                        <option value="daily"> {sdt('availbltyTab.rule.option1')}</option>
                                        <option value="weekly"> {sdt('availbltyTab.rule.option2')}</option>
                                        <option value="monthly"> {sdt('availbltyTab.rule.option3')}</option>
                                        <option value="yearly"> {sdt('availbltyTab.rule.option4')}</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={12} >
                                <Form.Group controlId="RepetitionShiftForm.txtInterval">
                                    <Form.Label> {t('availbltyTab.editRepeatMdl.interval')}</Form.Label>
                                    <div className="flexInputGroup">
                                        <Form.Control as="select" onChange={(e) => setinterval(e.target.value)} style={{ width: "80px" }} value={interval} custom>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                            <option value="13">13</option>
                                            <option value="14">14</option>
                                            <option value="15">15</option>
                                            <option value="16">16</option>
                                            <option value="17">17</option>
                                            <option value="18">18</option>
                                            <option value="19">19</option>
                                            <option value="20">20</option>
                                            <option value="21">21</option>
                                            <option value="22">22</option>
                                            <option value="23">23</option>
                                            <option value="24">24</option>
                                            <option value="25">25</option>
                                            <option value="26">26</option>
                                            <option value="27">27</option>
                                            <option value="28">28</option>
                                            <option value="29">29</option>
                                            <option value="30">30</option>
                                            <option value="31">31</option>
                                        </Form.Control>
                                        {/* <Form.Control type="number" onChange={(e) => setinterval(e.target.value)} value={interval} className="smallNumFormControl"/> */}
                                        {intervalText[RulesDropdown]}
                                    </div>

                                </Form.Group>
                            </Col>
                            {/* <Col md={12} >
                            <Form.Group controlId="RepetitionShiftForm.txtStartOn">
                                <Form.Label>Starts on</Form.Label>
                                <div><DatePicker selected={startDate} disabled onChange={(selected) => { RulesDropdown === "monthly" ? setMonthlySummary(selected) : setstartDate(selected) }} className="w-100" /></div>
                            </Form.Group>
                        </Col> */}
                            {RulesDropdown === "monthly" ?
                                <Col md={12} >
                                    <label className="form-label"> {sdt('availbltyTab.monthly.labelTitle')}</label>
                                    <Form.Control as="select" value={monthlyRhythm} onChange={(e) => updateMonthlyrythm(startDate, e.target.value)} custom>
                                        <option value="Weekday"> {sdt('availbltyTab.monthly.option1')}</option>
                                        <option value="DayofMonth"> {sdt('availbltyTab.monthly.option2')}</option>
                                    </Form.Control>
                                </Col>
                                : null}
                            {RulesDropdown === "weekly" ?
                                <Col md={12}>
                                    <label className="form-label"> {sdt('availbltyTab.daysOfWeek.labelTitle')}</label>
                                    <div>
                                        <ButtonGroup onClick={(e) => changingButtonState(e)} aria-label="Basic example" size="sm" className="btnSelectGroup">
                                            <Button name='Monday' variant="secondary" className={`${activeButtons.Monday === true ? " btnPrimaryGreen" : ""} makeitselect`} > {sdt('availbltyTab.daysOfWeek.option1')}</Button>
                                            <Button name='Tuesday' variant="secondary" className={`${activeButtons.Tuesday === true ? " btnPrimaryGreen" : ""} makeitselect`}> {sdt('availbltyTab.daysOfWeek.option2')}</Button>
                                            <Button name='Wednesday' variant="secondary" className={`${activeButtons.Wednesday === true ? " btnPrimaryGreen" : ""} makeitselect`}> {sdt('availbltyTab.daysOfWeek.option3')}</Button>
                                            <Button name='Thursday' variant="secondary" className={`${activeButtons.Thursday === true ? " btnPrimaryGreen" : ""} makeitselect`}> {sdt('availbltyTab.daysOfWeek.option4')}</Button>
                                            <Button name='Friday' variant="secondary" className={`${activeButtons.Friday === true ? " btnPrimaryGreen" : ""} makeitselect`}> {sdt('availbltyTab.daysOfWeek.option5')}</Button>
                                            <Button name='Saturday' variant="secondary" className={`${activeButtons.Saturday === true ? " btnPrimaryGreen" : ""} makeitselect`}> {sdt('availbltyTab.daysOfWeek.option6')}</Button>
                                            <Button name='Sunday' variant="secondary" className={`${activeButtons.Sunday === true ? " btnPrimaryGreen" : ""} makeitselect`}> {sdt('availbltyTab.daysOfWeek.option7')}</Button>
                                        </ButtonGroup>
                                    </div>
                                </Col>
                                : null}
                            <Col md={12} className="pt-3">
                                <Form.Group controlId="formBasicCheckbox">
                                    <Form.Label> {t('availbltyTab.editRepeatMdl.ends')}</Form.Label>
                                    <Form.Check type="checkbox" label={t('availbltyTab.editRepeatMdl.neverLabel')} checked={isNeverEnds} onClick={() => setisNeverEnds(!isNeverEnds)} />
                                </Form.Group>
                            </Col>
                            {isNeverEnds === false ?
                                <Col md={12}>
                                    <div className="check">
                                        <div className="d-flex align-items-center mb-2">
                                            <Form.Check
                                                inline
                                                label={t('availbltyTab.editRepeatMdl.afterLabel')}
                                                type={"radio"}
                                                name="apppoinmenttime"
                                                checked={IsAfterNoofAppointmentsOrAtTheDate === "afterNapppoinment" ? true : false}
                                                id={`inline-radio-3`}
                                                value="afterNapppoinment"

                                                onChange={(e) => setIsAfterNoofAppointmentsOrAtTheDate(e.target.value)}

                                            />
                                            <Form.Control style={{ minHeight: "32px" }} type="text" onClick={() => setIsAfterNoofAppointmentsOrAtTheDate("afterNapppoinment")} onChange={(e) => setAfterNoofAppointments(e.target.value)} value={AfterNoofAppointments} className="smallNumFormControlss me-2" /> {t('availbltyTab.editRepeatMdl.appointments')}

                                        </div>
                                        <Form.Group controlId="RepetitionShiftForm">
                                            <div className="d-flex align-items-center">
                                                <Form.Check
                                                    inline
                                                    label={t('availbltyTab.editRepeatMdl.atThe')}
                                                    name="apppoinmenttime"
                                                    checked={IsAfterNoofAppointmentsOrAtTheDate === "Atthedate" ? true : false}
                                                    type={"radio"}
                                                    id={`inline-radio-2`}
                                                    value="Atthedate"
                                                    onChange={(e) => setIsAfterNoofAppointmentsOrAtTheDate(e.target.value)}
                                                />
                                                <div>
                                                    <DatePicker selected={AtTheDate} onFocus={() => setIsAfterNoofAppointmentsOrAtTheDate("Atthedate")} onChange={(selected) => { setAtTheDate(selected) }} className="form-control" /></div>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </Col>
                                : null}
                            <Col md={12} className="mt-2">
                                <div className="SummaryText"> {ct('summary')}: {summary} {isNeverEnds === false ? IsAfterNoofAppointmentsOrAtTheDate === "Atthedate" ? !!AtTheDate ? ", till " + moment(AtTheDate).format("DD/MM/YYYY") : null : !!AfterNoofAppointments ? (", " + AfterNoofAppointments + " times") : "" : null}</div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.setShowRepetationModel(false)}> {t('availbltyTab.editRepeatMdl.toShutdown')}</Button>
                    <Button className="btn btnPrimaryGreen" onClick={() => saveDataToBaseModel()}> {ct('toSave')}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
