import { useFormik } from 'formik';
import moment from "moment";
import React, { useEffect, useRef, useState } from 'react';
import { Col, Collapse, Form, Modal, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { BsChevronDown, BsChevronRight } from 'react-icons/bs';
import { FaDownload, FaExclamationTriangle, FaFilter, FaRegStar, FaStar, FaSync, FaTrash } from 'react-icons/fa';
import { HiOutlineClock } from 'react-icons/hi';
import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { AxiosLite, CallAxios, CallAxiosBlob } from '../../Helpers';
import { ActionItem, ActionsBox, ContentBox, ContentSubHeader, SubHeadTitle, SubHeaderTitleBox, SubHeaderWrapper } from '../../Helpers/Global/StyledComponents';
import BarHighChart from './BarHighChart';
import LineHighChart from './LineHighChart';
import { PieHighChart } from './PieHighChart';
import { Remove } from "../../Components/ActionBtns"
import Dropdown from 'react-bootstrap/Dropdown';
import { CircleBtnWithIcon } from '../../Helpers/Global/StyledComponents';
import { FcFilledFilter } from "react-icons/fc";
import { saveAs } from 'file-saver';
import ReactDatePicker from 'react-datepicker';
import DeleteConfirmationModal from '../../Components/DeleteConfirmationModal';
import { useTranslation } from "react-i18next";


const CustomToggleDropdo = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </a>
));



function Reports() {
    const { t: st } = useTranslation('simpleData');
    const { t: ct } = useTranslation('common');


    const user = useSelector(x => x.app.user)
    const [show, setshow] = useState(false);
    const [ReportIdForEdit, setReportIdForEdit] = useState("");
    const [ChartsDiv, setChartsDiv] = useState([]);
    const themeColorBtn = useSelector(state => state.app.theme.colors.buttons);
    const [Filters, setFilters] = useState([]);
    const [ShowDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState();
    // const [ChartsDiv, setChartsDiv] = useState([
    //     {title:"BarChat",chartType:"bar",expanded:false,favorite:false},
    //     {title:"LineChart",chartType:"line",expanded:false,favorite:true},
    //     {title:"PieChart",chartType:"pie",expanded:false,favorite:false}
    // ]);
    useEffect(() => {
        GetReports()
    }, []);
    const GetReports = async () => {
        var response = await AxiosLite(
            "/api/Reports/GetReportList|get"
        );
        if (!!response && response.status === 200) {
            setChartsDiv(response.data)
        }
    }
    const UpdateArrayOfChartDiv = (name, index, value) => {

        const NewArrayToUpdate = [...ChartsDiv];
        let newobj = {
            ...ChartsDiv[index],
            [name]: value
        }
        NewArrayToUpdate[index] = newobj;
        setChartsDiv(NewArrayToUpdate)
    }
    const UpdateseInShiftPlan = async (id) => {
        var response = await CallAxios(
            "/api/Reports/UpdateUseInshiftPlan|post", { id: id }
        );
        if (!!response && response.status === 200) {
            GetReports()
        }
    }
    const removechartRow = async (id) => {
        var response = await CallAxios(
            "/api/Reports/DeleteReport|post", { id: id }
        );
        if (!!response && response.status === 200) {
            GetReports()
            setShowDeleteConfirmationModal(false)
        }
    }
    const DownloadReport = async (id, type) => {
        let filterobj = Filters.find(x => x.ReportId === id);
        filterobj = {
            ...filterobj,
            startDate: !!filterobj.startDate ? moment(parseFloat(filterobj.startDate)).startOf("day") : null,
            endDate: !!filterobj.endDate ? moment(parseFloat(filterobj.endDate)).endOf("day") : null
        }
        filterobj.chartType = type;
        var response = await CallAxiosBlob("/api/Reports/DownloadReport|post", filterobj);
        if (!!response && response.status === 200) {
            if (type === 'CSV') {
                saveAs(response.data, "Report.csv")
            }
            else if (type === 'Excel') {
                saveAs(response.data, "Report.xlsx")
            }
        }
    }

    return (
        <>

            {show === true ? <AddEditNewChart ReportIdForEdit={ReportIdForEdit} GetReports={GetReports} setReportIdForEdit={setReportIdForEdit} show={show} setshow={setshow} /> : null}
            {
                ShowDeleteConfirmationModal &&
                <DeleteConfirmationModal show={ShowDeleteConfirmationModal} onHide={setShowDeleteConfirmationModal}
                    title={st('reportPage.deleteReportMdl.title')} message={st('reportPage.deleteReportMdl.desc')} deletefn={removechartRow} objId={ReportIdForEdit} />
            }
            <ContentSubHeader>
                <SubHeaderWrapper>
                    <SubHeaderTitleBox>
                        <HiOutlineClock color={themeColorBtn} size={22} />
                        <SubHeadTitle>{st('reportPage.reports')} {user.LocationName}</SubHeadTitle>
                    </SubHeaderTitleBox>
                    <ActionsBox>
                        <ActionItem>
                            <button className='btn btn-primary' onClick={(e) => setshow(true)}> {st('reportPage.newReport')}</button>
                        </ActionItem>
                    </ActionsBox>
                </SubHeaderWrapper>
            </ContentSubHeader>
            <ContentBox>
                <Row>
                    {!!ChartsDiv && ChartsDiv.length > 0 ?
                        ChartsDiv.map((val, index) => {
                            return <>
                                <Col md={12} className="pt-4 ">
                                    <Row className="row py-2 border-top">
                                        <Col md={8} className="text-left align-self-center TopHeaderClick" onClick={() => UpdateArrayOfChartDiv("expanded", index, !val.expanded)} aria-controls="example-collapse-text" aria-expanded={val.expanded}>
                                            <div className="d-flex align-items-center">
                                                {val.expanded ? <BsChevronDown className="me-4" /> : <BsChevronRight className="me-4" />}
                                                <p className="mb-0">
                                                    {val.title}
                                                </p>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className='text-right d-flex align-items-center'>
                                                {val.isUseInShiftPlan ? <FaStar onClick={() => UpdateseInShiftPlan(val.id)} className='me-2' style={{ fontSize: "26px", color: "#f1c40f" }} /> : <FaRegStar onClick={() => UpdateseInShiftPlan(val.id)} className='me-2' style={{ fontSize: "26px" }} />}

                                                <Dropdown className="d-inline-block">
                                                    <Dropdown.Toggle as={CustomToggleDropdo} variant="success" id="dropdown-basic">
                                                        <CircleBtnWithIcon className='Btn me-0' color='#83beec'><FaDownload /></CircleBtnWithIcon>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu >
                                                        <Dropdown.Item href="javascript:;" onClick={() => DownloadReport(val.id, "Excel")}>Excel</Dropdown.Item>
                                                        <Dropdown.Item href="javascript:;" onClick={() => DownloadReport(val.id, "CSV")}>CSV</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <CircleBtnWithIcon className='Btn mx-2' color='#000' onClick={(e) => { setReportIdForEdit(val.id); setshow(true); }}><FcFilledFilter /></CircleBtnWithIcon>
                                                <Remove onClick={(e) => { setReportIdForEdit(val.id); setShowDeleteConfirmationModal(true) }}></Remove>
                                            </div>
                                        </Col>
                                        <Col md={12} >
                                            <Collapse in={val.expanded} >
                                                <div id="example-collapse-text"  >
                                                    <div className="py-3 bg-white mt-3 border-top">
                                                        {
                                                            // val.expanded ?
                                                            val.chartType === "bar" ?
                                                                <BarHighChart Filters={Filters} setFilters={setFilters} chartid={val.id} expanded={val.expanded} />
                                                                :
                                                                val.chartType === "line" ?
                                                                    <LineHighChart Filters={Filters} setFilters={setFilters} chartid={val.id} expanded={val.expanded} />
                                                                    :
                                                                    <PieHighChart Filters={Filters} setFilters={setFilters} chartid={val.id} expanded={val.expanded} />
                                                            // :
                                                            // false
                                                        }
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </Col>
                                    </Row>
                                </Col>
                            </>
                        })
                        : null
                    }
                </Row>
            </ContentBox>
        </>
    );
}

export default Reports;


function AddEditNewChart(props) {
    const { t: st } = useTranslation('simpleData');
    const { t: ct } = useTranslation('common');


    const [text, setText] = React.useState("");
    // const [TagsDD, setTagsDD] = useState([]);
    const [WorkSpaceDD, setWorkSpaceDD] = useState([]);
    const [AbsenceDD, setAbsenceDD] = useState([]);
    const [EmployeeDD, setEmployeeDD] = useState([]);

    useEffect(() => {
        if (!!props.ReportIdForEdit) {
            GetReports()
        }

    }, [props.ReportIdForEdit])
    const GetReports = async () => {
        var response = await CallAxios(
            "/api/Reports/GetReportById|post", { id: props.ReportIdForEdit }
        );
        if (!!response && response.status === 200) {
            formik.setFieldValue("title", response.data.title)
            formik.setFieldValue("metricCheckBox", response.data.metricCheckBox)
            formik.setFieldValue("areas", response.data.areas)
            formik.setFieldValue("employees", response.data.employees)
            // formik.setFieldValue("tags" , response.data.tags)   
            formik.setFieldValue("absenceType", response.data.absenceType)
            formik.setFieldValue("lastPeriod", response.data.lastPeriod)
            formik.setFieldValue("startDate", !!response.data.startDate ? new Date(response.data.startDate) : new Date())
            formik.setFieldValue("endDate", !!response.data.endDate ? new Date(response.data.endDate) : new Date())
            formik.setFieldValue("groupingType", response.data.groupingType)
            formik.setFieldValue("graphType", response.data.graphType)
            formik.setFieldValue("interval", response.data.interval)
            formik.setFieldValue("budget", response.data.budget)
        }
    }
    useEffect(() => {
        getworkspaces()
        GetEmployeeDD()
        GetAbsenceDD()
    }, []);
    const GetAbsenceDD = async () => {
        var response = await AxiosLite(
            "/api/LocationSetting/getAbsenceTypeDD|get"
        );
        if (!!response && response.status === 200) {
            if (response.data.length > 0) {
                formik.setFieldValue("absenceType", response.data.map(x => x.key))
            }
            setAbsenceDD(response.data)
        }
    }
    const GetEmployeeDD = async () => {
        var response = await AxiosLite("/api/Reports/GetEmployeeDD|get")
        if (!!response && response.status === 200) {
            formik.setFieldValue("employees", response.data.map(x => x.key))
            setEmployeeDD(response.data)
        }
    }
    const getworkspaces = async () => {
        var response = await AxiosLite(
            "/api/Reports/GetWorkSpaceDD|get"
        );
        if (!!response && response.status === 200) {
            if (response.data.length > 0) {
                formik.setFieldValue("areas", response.data.map(x => x.key))
            }
            setWorkSpaceDD(response.data);
        }
    }

    const formik = useFormik({
        initialValues: {
            title: "",
            metricCheckBox: [],
            areas: [],
            employees: [],
            // tags: [],
            absenceType: [],
            lastPeriod: "last_month",
            startDate: new Date(),
            endDate: new Date(),
            groupingType: "location",
            graphType: "bar",
            interval: "day",
            budget: "0.0",
        },

        onSubmit: values => {
            submit(values)
            // props.editmode === false ? props.AddNewChartRow(values) : props.updateExistingChartDataFromModal(values);
        },
    });
    const submit = async (values) => {

        var data = {
            Id: props.ReportIdForEdit,
            title: values.title,
            isTarget: values.metricCheckBox.some(x => x === "Target"),
            isPlan: values.metricCheckBox.some(x => x === "plan"),
            isActual: values.metricCheckBox.some(x => x === "Actual"),
            isAbsent: values.metricCheckBox.some(x => x === "absent"),
            isDemand: values.metricCheckBox.some(x => x === "Demand"),
            isShiftAssignments: values.metricCheckBox.some(x => x === "Shift_Assignments"),
            isSimpleLaborCosts: values.metricCheckBox.some(x => x === "simple_Labor_costs"),
            isCumulated: values.metricCheckBox.some(x => x === "cumulative"),
            DefaultPeriodSelection: values.lastPeriod,
            DefaultStartDate: values.lastPeriod === "user_defined" ? values.startDate : null,
            DefaultEndDate: values.lastPeriod === "user_defined" ? values.endDate : null,
            DefaultGrouping: values.groupingType,
            ChartType: values.graphType,
            ChartXaxis: values.interval,
            Budget: parseFloat(values.budget),
            ReportWorkingAreaids: values.areas,
            ReportEmployeesids: values.employees,
            // ReportTagsids:values.tags,
            ReportAbsenceTypesids: values.absenceType
        }
        if (!!data.title && (data.isTarget || data.isPlan || data.isActual || data.isAbsent || data.isDemand || data.isShiftAssignments || data.isSimpleLaborCosts || data.isCumulated)) {
            var response = await CallAxios(
                "/api/Reports/CreateReport|post", data
            );
            if (!!response && response.status === 200) {
                props.GetReports()
                props.setshow(false)
            }
        } else {
            toast.error((!!data.title ? "" : ct('titleRequire') + '. ') + ((data.isTarget || data.isPlan || data.isActual || data.isAbsent || data.isDemand || data.isShiftAssignments || data.isSimpleLaborCosts || data.isCumulated) === true ? "" : st('reportPage.newReportMdl.errorTitleToster')))
        }
    }
    const ChangePeriod = (val) => {
        var data = {
            "last_day": "Hour",
            "last_7_days": "Hour",
            "this_week": "Hour",
            "user_defined": "Hour",
            "last_30_days": "day",
            "this_month": "day",
            "last_month": "day",
            "this_year": "month",
        }
        formik.setFieldValue("interval", data[val])
    }


    return (
        <Modal show={props.show} className="" onHide={() => { props.setshow(false); props.setReportIdForEdit("") }}>
            <Modal.Header closeButton className="">
                <Modal.Title>{props.ReportIdForEdit === "" ? <>{st('reportPage.newReportMdl.createNewReport')}</> : <>{st('reportPage.newReportMdl.editReport')}</>}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={12} className="border-bottom pb-3">
                        <Form.Group>
                            <h4 className="fw-light"> {ct('title')}</h4>
                            <Form.Control type="text" onChange={formik.handleChange} value={formik.values.title} name="title" className="ThickBorder my-0 pr40" />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="py-3" onChange={formik.handleChange}>
                            <h4 className="fw-light"> {st('reportPage.metric')} </h4>
                            <Form.Check checked={formik.values.metricCheckBox.some(x => x === "Target") ? true : false} type="checkbox" value="Target" label={st('reportPage.metricSelect.option1')} name="metricCheckBox" id="Target" className="ThickBorder my-0 pr40" />
                            <Form.Check checked={formik.values.metricCheckBox.some(x => x === "plan") ? true : false} type="checkbox" value="plan" label={st('reportPage.metricSelect.option2')} name="metricCheckBox" id="plan" className="ThickBorder my-0 pr40" />
                            <Form.Check checked={formik.values.metricCheckBox.some(x => x === "Actual") ? true : false} type="checkbox" value="Actual" label={st('reportPage.metricSelect.option3')} name="metricCheckBox" id="Actual" className="ThickBorder my-0 pr40" />
                            <Form.Check checked={formik.values.metricCheckBox.some(x => x === "absent") ? true : false} type="checkbox" value="absent" label={st('reportPage.metricSelect.option4')} name="metricCheckBox" id="absent" className="ThickBorder my-0 pr40" />
                            <Form.Check checked={formik.values.metricCheckBox.some(x => x === "Demand") ? true : false} type="checkbox" value="Demand" label={st('reportPage.metricSelect.option5')} name="metricCheckBox" id="Demand" className="ThickBorder my-0 pr40" />
                            <Form.Check checked={formik.values.metricCheckBox.some(x => x === "Shift_Assignments") ? true : false} type="checkbox" value="Shift_Assignments" label={st('reportPage.metricSelect.option6')} name="metricCheckBox" id="Shift_Assignments" className="ThickBorder my-0 pr40" />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="py-3" onChange={formik.handleChange}>
                            <h4>&nbsp;</h4>
                            <Form.Check type="checkbox" checked={formik.values.metricCheckBox.some(x => x === "simple_Labor_costs") ? true : false} name="metricCheckBox" value="simple_Labor_costs" label={st('reportPage.metricSelect.option7')} id="simple_Labor_costs" className="ThickBorder my-0 pr40" />
                            <Form.Check type="checkbox" checked={formik.values.metricCheckBox.some(x => x === "cumulative") ? true : false} name="metricCheckBox" value="cumulative" label={st('reportPage.newReportMdl.cumulated')} id="cumulative" className="ThickBorder my-0 pr40" />
                        </Form.Group>
                    </Col>
                    <Col md={12} className="border-top py-3">
                        <Form.Group>
                            <h4 className="fw-light">{st('reportPage.newReportMdl.data')}</h4>
                            <Row>
                                <Col md={6}>
                                    <div className="TblDDList Multiselect HideSelectAll">
                                        {WorkSpaceDD.length > 0 &&
                                            <DropdownMultiselect name="areas" selected={formik.values.areas} handleOnChange={(selected) => formik.setFieldValue("areas", selected)} options={WorkSpaceDD} placeholder={st('reportPage.newReportMdl.workingAreas')} buttonClass="btnDDList " />
                                        }
                                        {WorkSpaceDD.length === 0 &&
                                            <DropdownMultiselect name="areas" handleOnChange={(selected) => formik.setFieldValue("areas", selected)} options={[]} placeholder={st('reportPage.newReportMdl.workingAreas')} buttonClass="btnDDList " />
                                        }
                                    </div>

                                </Col>
                                <Col md={6}>
                                    <div className="TblDDList Multiselect HideSelectAll">
                                        {
                                            EmployeeDD.length > 0 &&
                                            <DropdownMultiselect name="employees" selected={formik.values.employees} handleOnChange={(selected) => formik.setFieldValue("employees", selected)} options={EmployeeDD} placeholder={ct('employee')} buttonClass="btnDDList " />
                                        }
                                        {
                                            EmployeeDD.length === 0 &&
                                            <DropdownMultiselect name="employees" handleOnChange={(selected) => formik.setFieldValue("employees", selected)} options={[]} placeholder={ct('employee')} buttonClass="btnDDList " />
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    formik.values.metricCheckBox.some(x => x === "absent") ?
                                        <Col md={6}>
                                            <div className="TblDDList Multiselect HideSelectAll">
                                                {
                                                    AbsenceDD.length > 0 &&
                                                    <DropdownMultiselect name="absenceType" selected={formik.values.absenceType} handleOnChange={(selected) => formik.setFieldValue("absenceType", selected)} options={AbsenceDD} placeholder={st('reportPage.newReportMdl.absenceTypes')} buttonClass="btnDDList " />
                                                }
                                                {
                                                    AbsenceDD.length === 0 &&
                                                    <DropdownMultiselect name="absenceType" options={[]} placeholder={st('reportPage.newReportMdl.absenceTypes')} buttonClass="btnDDList " />
                                                }
                                            </div>
                                        </Col>
                                        : null
                                }
                            </Row>
                        </Form.Group>
                    </Col>
                    <Col md={12} className="border-top py-3">
                        <Form.Group>
                            <h4 className="fw-light"> {st('reportPage.newReportMdl.period')}</h4>
                            <Row>
                                <Col md={6}>
                                    <label>&nbsp;</label>
                                    {/* <select className="SelectbgGrey custom-select" value={formik.values.lastPeriod} onChange={(e)=>this.ChangePeriod(e.target.value)} name="lastPeriod" id=""> */}
                                    <select className="SelectbgGrey custom-select" value={formik.values.lastPeriod} onChange={(e) => { formik.handleChange(e); ChangePeriod(e.target.value) }} name="lastPeriod" id="">
                                        <option value="last_day">{st('reportPage.filterSelect.option1')}</option>
                                        <option value="last_7_days">{st('reportPage.filterSelect.option2')}</option>
                                        <option value="this_week">{st('reportPage.filterSelect.option3')}</option>
                                        <option value="last_30_days">{st('reportPage.filterSelect.option4')}</option>
                                        <option value="this_month">{st('reportPage.filterSelect.option5')}</option>
                                        <option value="last_month">{st('reportPage.filterSelect.option6')}</option>
                                        <option value="this_year">{st('reportPage.filterSelect.option7')}</option>
                                        <option value="user_defined">{st('reportPage.filterSelect.option8')}</option>
                                    </select>
                                </Col>
                                <Col md={6}>
                                    <Form.Group>
                                        <label> {ct('startDate')}</label>
                                        <DatePicker className="form-control" disabled={formik.values.lastPeriod === "user_defined" ? false : true} selected={formik.values.startDate} onChange={(s) => formik.setFieldValue("startDate", s)} />
                                    </Form.Group>
                                    <Form.Group>
                                        <label> {ct('endDate')}</label>
                                        <DatePicker className="form-control" disabled={formik.values.lastPeriod === "user_defined" ? false : true} selected={formik.values.endDate} onChange={(s) => formik.setFieldValue("endDate", s)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                    <Col md={12} className="border-top py-3">
                        <Form.Group onChange={formik.handleChange} checked={formik.values.groupingType}>
                            <h4 className="fw-light">{st('reportPage.grouping')}</h4>
                            <Form.Check className='mb-2' type="radio" value="location" checked={formik.values.groupingType === "location" ? true : false} label={st('reportPage.grouping1.option1')} name="groupingType" id="formExportPeriodWeek" />
                            <Form.Check className='mb-2' type="radio" value="Workspace" checked={formik.values.groupingType === "Workspace" ? true : false} label={st('reportPage.grouping1.option2')} name="groupingType" id="formExportPeriodWeek2" />
                            {/* <Form.Check className='mb-2' type="radio" value="tag" checked={formik.values.groupingType === "tag" ? true : false} label="Tag" name="groupingType" id="formExportPeriodWeek4" /> */}
                            <Form.Check className='mb-2' type="radio" value="employees" checked={formik.values.groupingType === "employees" ? true : false} label={st('reportPage.grouping1.option3')} name="groupingType" id="formExportPeriodWeek3" />

                        </Form.Group>
                    </Col>
                    <Col md={12} className="border-top py-3">
                        <Row>
                            <Col md={6}>
                                <Form.Group onChange={formik.handleChange}>
                                    <h4 className="fw-light"> {st('reportPage.newReportMdl.displayInterval')}</h4>
                                    <Form.Check className='mb-2' type="radio" value="bar" checked={formik.values.graphType === "bar" ? true : false} label={st('reportPage.newReportMdl.columnChart')} name="graphType" id="Graph1" />
                                    <Form.Check className='mb-2' type="radio" value="line" checked={formik.values.graphType === "line" ? true : false} label={st('reportPage.newReportMdl.lineChart')} name="graphType" id="Graph2" />
                                    <Form.Check className='mb-2' type="radio" value="pie" checked={formik.values.graphType === "pie" ? true : false} label={st('reportPage.newReportMdl.pieChart')} name="graphType" id="Graph3" />

                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group onChange={formik.handleChange} >
                                    <h4>&nbsp;</h4>
                                    <Form.Check className='mb-2' disabled={formik.values.lastPeriod === "user_defined" || formik.values.lastPeriod === "last_day" ? false : true} type="radio" checked={formik.values.interval === "15m" ? true : false} value="15m" label="15m" name="interval" id="Interval1" />
                                    <Form.Check className='mb-2' disabled={formik.values.lastPeriod === "user_defined" || formik.values.lastPeriod === "last_day" ? false : true} type="radio" checked={formik.values.interval === "30m" ? true : false} value="30m" label="30m" name="interval" id="Interval2" />
                                    <Form.Check className='mb-2' disabled={formik.values.lastPeriod === "user_defined" || formik.values.lastPeriod === "last_day" || formik.values.lastPeriod === "last_7_days" || formik.values.lastPeriod === "this_week" ? false : true} type="radio" checked={formik.values.interval === "Hour" ? true : false} value="Hour" label={st('reportPage.grouping2.option1')} name="interval" id="Interval3" />
                                    <Form.Check className='mb-2' disabled={formik.values.lastPeriod !== "this_year" ? false : true} type="radio" checked={formik.values.interval === "day" ? true : false} value="day" label={st('reportPage.grouping2.option2')} name="interval" id="Interval4" />
                                    <Form.Check className='mb-2' disabled={formik.values.lastPeriod === "user_defined" || formik.values.lastPeriod === "last_day" || formik.values.lastPeriod === "this_year" ? true : false} type="radio" checked={formik.values.interval === "week" ? true : false} value="week" label={st('reportPage.grouping2.option3')} name="interval" id="Interval5" />
                                    <Form.Check className='mb-2' disabled={formik.values.lastPeriod === "user_defined" || formik.values.lastPeriod === "last_day" ? true : false} type="radio" checked={formik.values.interval === "month" ? true : false} value="month" label={st('reportPage.grouping2.option4')} name="interval" id="Interval6" />

                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={12} className="border-top py-3">
                        <Form.Group>
                            <h4 className="fw-light">{st('reportPage.newReportMdl.budget')}</h4>
                            <Form.Control type="number" onChange={formik.handleChange} value={formik.values.budget} name="budget" className="ThickBorder my-0 pr40" />
                        </Form.Group>
                    </Col>
                </Row>


            </Modal.Body>
            <Modal.Footer>
                <button className="btn btnPrimaryGreen text-white" onClick={formik.handleSubmit}>{ct('ready')}</button>

            </Modal.Footer>
        </Modal>
    );
}

export function FiltersDiv(props) {
    const { t: st } = useTranslation('simpleData');
    const { t: ct } = useTranslation('common');


    moment.updateLocale('en', {
        week: {
            dow: 1, // Monday is the first day of the week.
        }
    });
    const Groupoptions = [
        { value: 'location', label: st('reportPage.grouping1.option1') },
        { value: 'Workspace', label: st('reportPage.grouping1.option2') },
        { value: 'employees', label: st('reportPage.grouping1.option3') },
    ];
    const Groupoptions2 = [
        { value: '15m', label: '15m' },
        { value: '30m', label: '30m' },
        { value: 'Hour', label: st('reportPage.grouping2.option1') },
        { value: 'day', label: st('reportPage.grouping2.option2') },
        { value: 'week', label: st('reportPage.grouping2.option3') },
        { value: 'month', label: st('reportPage.grouping2.option4') },
    ];
    const MetricsOptions = [
        { key: 'Target', label: st('reportPage.metricSelect.option1') },
        { key: 'plan', label: st('reportPage.metricSelect.option2') },
        { key: 'Actual', label: st('reportPage.metricSelect.option3') },
        { key: 'absent', label: st('reportPage.metricSelect.option4') },
        { key: 'Demand', label: st('reportPage.metricSelect.option5') },
        { key: 'Shift_Assignments', label: st('reportPage.metricSelect.option6') },
        { key: 'simple_Labor_costs', label: st('reportPage.metricSelect.option7') },
        { key: 'cumulative', label: st('reportPage.metricSelect.option8') },
    ];
    const [WorkSpaceDD, setWorkSpaceDD] = useState([]);
    const [ShowFilter, setShowFilter] = useState(false);
    const [IsInitialLoadComplete, setIsInitialLoadComplete] = useState(false);
    const [Filters, setFilters] = useState(
        {
            title: "",
            metricCheckBox: "",
            areas: "",
            employees: "",
            tags: "",
            absenceType: "",
            lastPeriod: "",
            groupingType: "",
            graphType: "",
            interval: "",
            budget: "",
            startDate: null,
            endDate: null
        }
    );

    useEffect(() => {
        if (props.onChange) {
            props.onChange(Filters)
        }
    }, [Filters]);
    useEffect(() => {
        onload()
    }, []);
    const onload = async () => {
        await getworkspaces();
        await GetReports();
    }
    const getworkspaces = async () => {
        var response = await AxiosLite(
            "/api/Reports/GetWorkSpaceDD|get"
        );
        if (!!response && response.data.length > 0) {
            setWorkSpaceDD(response.data);
        }
    }

    const GetReports = async () => {
        var response = await CallAxios(
            "/api/Reports/GetReportById|post", { id: props.chartid }
        );
        if (!!response && response.status === 200) {
            setFilters((prevState) => {
                const newState = { ...prevState }
                return Object.assign(newState, {
                    title: response.data.title,
                    metricCheckBox: response.data.metricCheckBox,
                    areas: WorkSpaceDD.filter(x => response.data.areas.some(c => c === x.key)),
                    employees: response.data.employees,
                    // tags :response.data.tags,
                    absenceType: response.data.absenceType,
                    lastPeriod: response.data.lastPeriod,
                    groupingType: response.data.groupingType,
                    graphType: response.data.graphType,
                    interval: response.data.interval,
                    budget: response.data.budget,
                })
            })
            setShowFilter(true)
        }
    }
    const lastPeriodSelection = useRef();
    useEffect(() => {
        if (!!lastPeriodSelection.current) {
            setFilters((prevState) => {
                const newState = { ...prevState }
                return Object.assign(newState, {
                    startDate: lastPeriodSelection.current.options[lastPeriodSelection.current.selectedIndex].attributes.startdate.value,
                    endDate: lastPeriodSelection.current.options[lastPeriodSelection.current.selectedIndex].attributes.endDate.value,
                })
            })
            setIsInitialLoadComplete(true)
        }
    }, [lastPeriodSelection.current]);
    useEffect(() => {
        if (IsInitialLoadComplete) {
            props.setIsInitialLoadComplete(true)
        }

    }, [IsInitialLoadComplete]);
    return (
        <div className='FilterDiv'>
            {
                ShowFilter === true ?
                    <div className='d-flex mx-3 mb-3 justify-content-between'>
                        <div className='me-3 w-25'>
                            <div className='d-flex align-items-center '>
                                <label htmlFor="" className='fw-bold font12 mb-0 me-3'> {st('reportPage.metrics')}</label>
                                <div className='TblDDList Multiselect HideSelectAll  flex-grow-1 w-100'>
                                    <DropdownMultiselect handleOnChange={(selected) => setFilters({ ...Filters, metricCheckBox: selected })} selected={Filters.metricCheckBox} options={MetricsOptions} selectDeselectLabel="Select all" placeholder={st('reportPage.metric')} buttonClass="btnDDList lgWidth" />
                                </div>
                            </div>
                        </div>
                        <div className='me-3'>
                            <div className='d-flex align-items-center'>
                                <label htmlFor="" className='fw-bold font12 mb-0 me-3'>{st('reportPage.grouping')}</label>
                                <div className='TblDDList Multiselect HideSelectAll flex-grow-1'>
                                    <div className='d-flex addFlexGrowToDropdowns'>
                                        <div className='w-120px me-2'>
                                            <Select
                                                isSearchable={false}
                                                value={Groupoptions.find(x => x.value === Filters.groupingType)}
                                                classNamePrefix="ReactSelectCustomPrefix"
                                                className="ReactSelectCustom disabled"
                                                onChange={values =>
                                                    setFilters({ ...Filters, groupingType: values.value })
                                                    // setFilters({...Filters,grouping:{...Filters.grouping,dataType:values.value}})
                                                }
                                                options={Groupoptions}
                                            />
                                        </div>
                                        <div className='w-120px'>
                                            <Select
                                                isSearchable={false}
                                                value={Groupoptions2.find(x => x.value === Filters.interval)}
                                                classNamePrefix="ReactSelectCustomPrefix"
                                                className="ReactSelectCustom disabled"
                                                onChange={values =>
                                                    setFilters({ ...Filters, interval: values.value })
                                                }
                                                options={Groupoptions2}
                                            />
                                        </div>
                                        {/* <DropdownMultiselect   handleOnChange={(selected) =>   console.log(selected) }       options={["Location","Workspace","Day" , "employees"]} selectDeselectLabel="Select all"   placeholder={"metric"} buttonClass="btnDDList me-2 flex-grow-1 fixwidthDoNotMove w-100"  /> */}
                                        {/* <DropdownMultiselect   handleOnChange={(selected) =>   console.log(selected) }       options={["15m","30m","hour" , "Day" , "week" , "month"]} selectDeselectLabel="Select all"   placeholder={"metric"} buttonClass="btnDDList flex-grow-1 fixwidthDoNotMove w-100"  /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='me-3'>
                            <div className='d-flex align-items-center'>
                                <label htmlFor="" className='fw-bold font12 mb-0 me-3'> {st('reportPage.filter')}</label>
                                <div className='TblDDList Multiselect HideSelectAll flex-grow-1'>
                                    <div className='d-flex addFlexGrowToDropdowns'>
                                        {
                                            WorkSpaceDD.length > 0 &&
                                            <DropdownMultiselect handleOnChange={(selected) => setFilters({ ...Filters, areas: selected })} selected={Filters.areas} options={WorkSpaceDD} selectDeselectLabel="Select all" placeholder={"metric"} buttonClass="btnDDList fixwidthDoNotMove flex-grow-1 w-100" />
                                        }
                                        {
                                            WorkSpaceDD.length === 0 &&
                                            <DropdownMultiselect name="areas" options={[]} placeholder="Working areas" buttonClass="btnDDList " />
                                        }
                                        <div className='w-120px ms-3'>
                                            <select ref={lastPeriodSelection} className="SelectbgGrey form-select form-select-sm custom-select" value={Filters.lastPeriod}
                                                onChange={(e) => {
                                                    setFilters({
                                                        ...Filters, lastPeriod: e.target.value, startDate: e.currentTarget.options[e.currentTarget.selectedIndex].attributes.StartDate.value,
                                                        endDate: e.currentTarget.options[e.currentTarget.selectedIndex].attributes.EndDate.value
                                                    })
                                                }} name="lastPeriod" id="">
                                                <option StartDate={moment().subtract(1, 'days')} EndDate={moment().subtract(1, 'days')} value="last_day"> {st('reportPage.filterSelect.option1')}</option>
                                                <option StartDate={moment().subtract(6, 'days')} EndDate={moment()} value="last_7_days"> {st('reportPage.filterSelect.option2')}</option>
                                                <option StartDate={moment().startOf('week')} EndDate={moment().endOf("week")} value="this_week"> {st('reportPage.filterSelect.option3')}</option>
                                                <option StartDate={moment().subtract(29, 'days')} EndDate={moment()} value="last_30_days"> {st('reportPage.filterSelect.option4')}</option>
                                                <option StartDate={moment().startOf('month')} EndDate={moment().endOf("month")} value="this_month"> {st('reportPage.filterSelect.option5')}</option>
                                                <option StartDate={moment().subtract(1, 'months').date(1)} EndDate={moment().subtract(1, 'months').endOf('month')} value="last_month">{st('reportPage.filterSelect.option6')}</option>
                                                <option StartDate={moment().startOf('year')} EndDate={moment().endOf("year")} value="this_year">{st('reportPage.filterSelect.option7')}</option>
                                                <option StartDate={moment()} EndDate={moment()} value="user_defined">{st('reportPage.filterSelect.option8')}</option>
                                            </select>
                                        </div>
                                        {
                                            Filters.lastPeriod === "user_defined" &&
                                            <div className='w-100'>
                                                <ReactDatePicker selected={new Date(moment(parseFloat(Filters.startDate)))} onChange={(date) => setFilters({ ...Filters, startDate: moment(date).valueOf() })} placeholderText={ct('startDate')} className="form-control" />
                                                <ReactDatePicker selected={new Date(moment(parseFloat(Filters.endDate)))} onChange={(date) => setFilters({ ...Filters, endDate: moment(date).valueOf() })} placeholderText={ct('endDate')} className=" form-control" />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </div>

    )
}

export function RefreshButton(props) {
    const { t: st } = useTranslation('simpleData');

    return (
        <div onClick={() => props.getChartData()} className='d-flex align-items-center justify-content-center mt-4'>

            {
                props.IsFilterChange === true &&
                <>
                    <FaExclamationTriangle style={{ "color": "red" }} />
                    <span className='mx-1' style={{ "color": "red" }}> {st('reportPage.pleaseRefresh')} </span>
                </>
            }

            <FaSync />
            <span className='mx-1'>{st('reportPage.refresh')}</span>
            <small className='text-muted'>
                ({moment(props.refreshDate).format('DD/MM/YYYY HH:mm')})
            </small>
        </div>
    )
}