import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { CallAxios } from "../../Helpers";
import lockImg from '../../assets/Images/lock.png';



function Invites() {
    const [searchParams] = useSearchParams();
    const iviteToken = searchParams.get("token")
    const [EmpName, setEmpName] = useState("");
    const [EmpId, setEmpId] = useState(0);
    const [email, setEmail] = useState("");
    const [password, setpassword] = useState("");
    const [confirmpassword, setconfirmpassword] = useState("");
    const [LocationName, setLocationName] = useState("");
    const navigate = useNavigate()
    useEffect(() => {
        getData()
    }, []);

    const getData = async () => {
        if (!!iviteToken) {
            var response = await CallAxios("/api/Account/GetInviteEmployeeInfo|post", { id: iviteToken })
            if (!!response && response.status === 200) {
                setEmpName(response.data.empName)
                setEmail(response.data.empEmail)
                setEmpId(response.data.empId)
                setLocationName(response.data.locationName)
            }
            else {
                toast.error('something went wronge')
            }
        }
    }
    const submit = async () => {
        if (password === confirmpassword) {
            if (!!email && !!password) {
                var data = {
                    EmpId: EmpId,
                    empName: EmpName,
                    empEmail: email,
                    Password: password
                }

                var response = await CallAxios("/api/Account/SignupFromInviteEmployee|post", data);
                if (!!response && response.data === true) {
                    navigate("/")
                } else {
                    toast.error('something went wronge')
                }
            } else {
                if (email === '' || email === undefined) {
                    Swal.fire({
                        icon: 'error',
                        title: 'please enter email',
                    })
                }
                if (password === '' || password === undefined) {
                    Swal.fire({
                        icon: 'error',
                        title: 'please enter password',
                    })
                }
            }
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'password and confirm password not match',
            })
        }
    }

    const mystyle = {
        paddingBottom: '0px',
        color: '#ffffff',
        backgroundColor: '#20b99a',
        top: '0',
        left: '0',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    };

    return (
        <>
            <div class="row-fluid" style={mystyle}>
                <div class="login">
                    <div class="row-fluid text-center text-left greeting-text" style={{ paddingTop: '60px', }}>
                        <h1>Hello {EmpName}</h1>
                        <p>You have been added and invited to the location <b>"{LocationName}"</b> Please finalise your registration here:</p>
                    </div>
                    <div class="row login-screen text-center" style={{ margin:'0 auto' , width:"570px", paddingTop: '50px' }}>
                        <div class="col-md-3">
                            <img alt="Willkommen bei Bastashift" src={lockImg} />
                            <h4>
                                <small class="text-center color-white">
                                    Registration
                                </small>
                            </h4>
                        </div>
                        <div class="col-md-9">
                            <div class="login-form-invite inviteform-padding" id="edit_user_form">
                                <form class="edit_user" id="new-password-form" action="" accept-charset="UTF-8" method="post" style={{ display: 'inline-block', }}>
                                    <div id="form-message" class="text-center" style={{ color: '#c0392b', fontWeight: 'bold', }}>
                                        <div class="form-group" style={{ height: '41px !important', width: '310px' }}>
                                            <div class="field_with_errors">
                                                <input type="text" onChange={(e) => setEmpName(e.target.value)} className="form-control login-field email_field invite-input-fields" value={EmpName} placeholder="Name" />
                                            </div><br />
                                            <div class="field_with_errors">
                                                <input type="text" onChange={(e) => setEmail(e.target.value)} className="form-control login-field email_field invite-input-fields" value={email} placeholder="Email" />
                                            </div><br />
                                            <div class="field_with_errors">
                                                <input type="password" onChange={(e) => setpassword(e.target.value)} className="form-control login-field invite-input-fields" placeholder="Password" autoFocus="autofocus" />
                                            </div><br />
                                            <div class="field_with_errors">
                                                <input type="password" onChange={(e) => setconfirmpassword(e.target.value)} className="form-control login-field invite-input-fields" placeholder="Confirm Password" />
                                            </div><br />
                                            <div class="btn btn-primary btn-lg btn-block invite-input-fields" onClick={() => submit()}>Submit</div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p class="user-invite-bottom row-fluid">
                            When completing the registration you accept our <u><a href="#!" target="_blank" style={{ color: '#fff' }}>terms and conditions</a></u> as well as our
                            <u><a href="#!" target="_blank" style={{ color: '#fff' }}>data privacy regulations</a></u> and
                            <u><a href="#!" target="_blank" style={{ color: '#fff' }}>data security policy</a></u>.<br />
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Invites;