import { Field, FieldArray, FormikProvider } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import { FaTimes } from "react-icons/fa";
import { Remove, RemoveCircle } from "./ActionBtns";
import { Constants } from "../Helpers/Contents";
import { useTranslation } from "react-i18next";

function TimeTrackingBreaksRepeter(props) {
    const { t: ct } = useTranslation('common');

    return (
        <FormikProvider value={props.formik}>
            <FieldArray
                name="timeTrackingBreaks"
                render={arrayHelpers => {
                    const timeTrackingBreaks = props.formik.values.timeTrackingBreaks;
                    return (
                        <div>
                            {timeTrackingBreaks && timeTrackingBreaks.length > 0
                                ? timeTrackingBreaks.map((user, index) => (
                                    <div className="pt-2" key={index}>
                                        <Row>
                                            <Col md={3}>
                                                <Form.Group controlId="AddTimeTrackingForm.SelectDay">
                                                    <Form.Label> {ct('day')}</Form.Label>
                                                    <DatePicker className="form-control" name={`timeTrackingBreaks.${index}.day`} selected={!!user.day ? new Date(user.day) : user.day} onChange={date => props.formik.setFieldValue(`timeTrackingBreaks.${index}.day`, date)} PlaceholderText="" />
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId="AddTimeTrackingForm.txtBegin">
                                                    <Form.Label>{ct('begin')}</Form.Label>
                                                    <div className="flexInputGroup d-flex align-items-center">
                                                        <Field
                                                            name={`timeTrackingBreaks.${index}.startTimeHour`}
                                                            label={ct("free")}
                                                            className="Noarrow bgPrimaryGrey custom-select form-control text-center"
                                                            value={props.formik.values.timeTrackingBreaks[index].startTimeHour}
                                                            as="select" >
                                                            {
                                                                Constants.hoursDropDown.map((val, key) => {
                                                                    return <option key={"Begin" + key} value={val.value}>{val.text}</option>
                                                                })
                                                            }
                                                        </Field>
                                                        <span className="seperator mx-2">:</span>
                                                        <Field
                                                            name={`timeTrackingBreaks.${index}.startTimeMin`}
                                                            label={ct("free")}
                                                            className="Noarrow bgPrimaryGrey custom-select form-control text-center"
                                                            as="select"
                                                            value={props.formik.values.timeTrackingBreaks[index].startTimeMin}

                                                        >
                                                            {
                                                                Constants.minutesDropDown.map((val, key) => {
                                                                    return <option key={"StartMin" + key} value={val.value}>{val.text}</option>
                                                                })
                                                            }
                                                        </Field>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group controlId="AddTimeTrackingForm.txtEndTime">
                                                    <Form.Label> {ct('end')}</Form.Label>
                                                    <div className="flexInputGroup d-flex align-items-center">
                                                        <Field

                                                            name={`timeTrackingBreaks.${index}.endTimeHour`}
                                                            label={ct("free")}
                                                            className="Noarrow bgPrimaryGrey custom-select form-control text-center"
                                                            as="select"
                                                            value={props.formik.values.timeTrackingBreaks[index].endTimeHour}

                                                        >
                                                            {
                                                                Constants.hoursDropDown.map((val, key) => {
                                                                    return <option key={"endTimeHour" + key} value={val.value}>{val.text}</option>
                                                                })
                                                            }

                                                        </Field>
                                                        <span className="seperator mx-2">:</span>
                                                        <Field
                                                            name={`timeTrackingBreaks.${index}.endTimeMin`}
                                                            label={ct("free")}
                                                            className="Noarrow bgPrimaryGrey custom-select form-control text-center"
                                                            as="select"
                                                            value={props.formik.values.timeTrackingBreaks[index].endTimeMin}
                                                        >
                                                            {
                                                                Constants.minutesDropDown.map((val, key) => {
                                                                    return <option key={"endTimeMin" + key} value={val.value}>{val.text}</option>
                                                                })
                                                            }

                                                        </Field>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col md={1} className="px-0">
                                                <Form.Group>
                                                    <Form.Label className="d-block">&nbsp;</Form.Label>
                                                    <RemoveCircle color='#f25656' className="nobgIcon" onClick={() => arrayHelpers.remove(index)} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </div>
                                ))
                                : null}

                            <div className="py-3"><a className="AddComponentLink" onClick={() => {
                                arrayHelpers.push({ id: props.formik.values.id, day: new Date(props.DayDate), startTimeHour: props.StartHours, startTimeMin: props.StartMin, endTimeHour: props.EndHours, endTimeMin: props.EndMin })
                            }}> {ct('addABreak')}</a></div>
                        </div>
                    );
                }}
            />
        </FormikProvider>
    );

}

export default TimeTrackingBreaksRepeter;