import React from 'react';
import { ActionItem, ActionsBox, CircleBtnWithIcon, ContentBox, ContentSubHeader, SubHeadTitle, SubHeaderTitleBox, SubHeaderWrapper, TableWrapper } from '../../../Helpers/Global/StyledComponents';
import { CallAxios } from '../../../Helpers';
import { useState } from 'react';
import { Button, Col, Container, Form, Modal, Nav, OverlayTrigger, Row, Tab, Table, Tabs, Tooltip } from 'react-bootstrap';
import { BsGear } from 'react-icons/bs';
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import Highlighter from 'react-highlight-words';
import { FaDownload, FaUpload } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import EmployeeExportAbsences from './EmployeeExportAbsences';
import EmployeeExportEmpTab from './EmployeeExportEmpTab';
import EmployeeExportTargetHours from './EmployeeExportTargetHours';
import EmployeeExportTimeRecords from './EmployeeExportTimeRecords';
import EmployeeExportVacation from './EmployeeExportVacation';
import EmployeeExportAvailable from './EmployeeExportAvailable';
import { HiUserGroup } from 'react-icons/hi';
import { MdImportExport } from 'react-icons/md';
import { useTranslation } from "react-i18next";

function ExpotImportIndex() {
    const { t: ct } = useTranslation('common');
    const { t: eit } = useTranslation('exportsImports');
    const { t: sdt } = useTranslation('selectDropdown');


    var user = useSelector(x => x.app.user)
    const [ExportView, setExportView] = useState(true);
    const [ImportView, setImportView] = useState(false);
    const [ButtonImportModal, setButtonImportModal] = useState(false);
    const themeColorBtn = useSelector(state => state.app.theme.colors.buttons);
    const themeColorActive = useSelector(state => state.app.theme.colors.active);

    const handleExportImportView = (ExportImportView) => {
        if (ExportImportView === "ExportFormView") {
            if (ExportView === true) {
                setExportView(false)
            }
            else {
                setImportView(false)
                setExportView(true)
            }
        }

        if (ExportImportView === "ImportFormView") {
            if (ImportView === true) {
                setImportView(false)
            }
            else {
                setExportView(false)
                setImportView(true)
            }
        }
    }

    return (
        <>


            <ContentBox className='ex-imp-font-fix'>
                <Container fluid>
                    <Row>
                        <Col md={4}>
                            <div className="my-3">
                                <SubHeaderTitleBox>
                                    <MdImportExport color={themeColorBtn} size={22} />
                                    <SubHeadTitle>{eit('exportImport')}</SubHeadTitle>
                                </SubHeaderTitleBox>
                            </div>
                        </Col>
                        <Col md={8}>
                            <div className="my-3">
                                <Nav className="justify-content-end" activeKey={`/companies/${user.LocationId}`}>
                                    <Nav.Item>
                                        {/* <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={<Tooltip>Export</Tooltip>}> */}
                                        <Nav.Link data-tooltip-id="my-tooltip" data-tooltip-content="Export" data-tooltip-place="bottom" href="#" className={ExportView ? 'active' : null} onClick={() => handleExportImportView("ExportFormView")}>
                                            <CircleBtnWithIcon color={ExportView === true ? themeColorActive : "#000"} className='Btn'> <FaDownload className="HeaderActionIcon" /></CircleBtnWithIcon>
                                        </Nav.Link>
                                        {/* </OverlayTrigger> */}
                                    </Nav.Item>

                                    <Nav.Item>
                                        {/* <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={<Tooltip>Import</Tooltip>}> */}
                                        <Nav.Link data-tooltip-id="my-tooltip" data-tooltip-content={eit('importTitle')} data-tooltip-place="bottom" href="#" className={ImportView ? 'active' : null} onClick={() => handleExportImportView("ImportFormView")}>
                                            <CircleBtnWithIcon color={ExportView !== true ? themeColorActive : "#000"} className='Btn'> <FaUpload className="HeaderActionIcon" /></CircleBtnWithIcon>
                                        </Nav.Link>
                                        {/* </OverlayTrigger> */}
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </Col>
                    </Row>
                    <div className='bg-white p-2 border'>


                        {
                            ExportView === true ?
                                <Row className="mtMainContent">
                                    <Col md={12} className="lgcol12">
                                        <Row>
                                            <Col md={12} >
                                                <div className="classicTab custom-tabs-font">
                                                    <Tabs className='nav-underline' defaultActiveKey="Absences" transition={false} id="noanim-tab-example">
                                                        <Tab eventKey="Absences" title={eit('absencesTab.tabTitle')}>
                                                            <EmployeeExportAbsences />
                                                        </Tab>
                                                        <Tab eventKey="Employee" title={eit('employeeTab.tabTitle')}>
                                                            <EmployeeExportEmpTab />
                                                        </Tab>
                                                        <Tab eventKey="Targethours" title={eit('targetHoursTab.tabTitle')}>
                                                            <EmployeeExportTargetHours />
                                                        </Tab>
                                                        <Tab eventKey="Timerecords" title={eit('timeRecordsTab.tabTitle')}>
                                                            <EmployeeExportTimeRecords />
                                                        </Tab>
                                                        <Tab eventKey="Vactionquotas" title={eit('vationQuotasTab.tabTitle')}>
                                                            <EmployeeExportVacation />
                                                        </Tab>
                                                        <Tab eventKey="Availabilities" title={eit('availabilitiesTab.tabTitle')}>
                                                            <EmployeeExportAvailable />
                                                        </Tab>
                                                    </Tabs>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                : null
                        }
                        {
                            ImportView === true ?
                                <Row>
                                    <Col md={12}>
                                        <Row>
                                            <Col md={12} >
                                                <div className="col-md-12">
                                                    <div className='mb-2 text-end'>
                                                        <Button variant="primary" onClick={() => setButtonImportModal(true)}>{ct('new') + ' ' + eit('importTitle')}</Button>
                                                    </div>
                                                    <TableWrapper>
                                                        <Table responsive striped bordered>
                                                            <thead>
                                                                <tr className='firsthead'>
                                                                    <th>{ct('type')}</th>
                                                                    <th>{ct('fileName')}</th>
                                                                    <th>{ct('creator')}</th>
                                                                    <th>{ct('date')}</th>
                                                                    <th>{ct('information')}</th>
                                                                    <th>{ct('status')}</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                        </Table>
                                                    </TableWrapper>
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <span className="d-flex justify-content-center">{eit('import.emptyTableText')}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                : null
                        }
                    </div>
                </Container>

                {
                    ButtonImportModal === true ?
                        <Modal show={ButtonImportModal} onHide={() => setButtonImportModal(false)}>
                            <Modal.Header closeButton >
                                <Modal.Title>{ct('new') + ' ' + eit('importTitle')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Row>
                                        <Col md={12}>
                                            <Container>
                                                <Row>
                                                    <Col md={12}>
                                                        <Form.Group className='mb-3' controlId="AddAbsenceForm.SelectEmployee">
                                                            <Form.Label>{ct("type")}</Form.Label>
                                                            <Form.Control as="select" className="SelectbgGrey" custom>
                                                                <option>{sdt("newImports.option1")}</option>
                                                                <option>{sdt("newImports.option2")}</option>
                                                                <option>{sdt("newImports.option3")}</option>
                                                                <option>{sdt("newImports.option4")}</option>
                                                                <option>{sdt("newImports.option5")}</option>
                                                                <option>{sdt("newImports.option6")}</option>
                                                                <option>{sdt("newImports.option7")}</option>
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md={6}>
                                                        <Form.Group>
                                                            <input type='file' id="toUploadFileinImport" />
                                                            {/* <Form.File id="toUploadFileinImport" /> */}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <span className="d-flex justify-content-end p-2">{eit("import.newImportMdl.example")} &nbsp;<FaDownload /></span>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Col>
                                    </Row>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="success">{eit('importTitle')}</Button>
                            </Modal.Footer>

                        </Modal>
                        : null
                }
            </ContentBox >

        </>
    );
}

export default ExpotImportIndex;