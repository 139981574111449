import axios from "axios";
import moment from "moment/moment";
import { config } from "../Config";

const tokenKey = 'token';
const generatedKey = 'generated';
const expiryKey = 'expiry';

export class SignInManager {
    options = {
        UserName: '',
        password: ''
    };
    async Login(userName, password) {
        this.options.UserName = userName || '';
        this.options.password = password || '';

        const response = await axios.post(config.TOKEN_URL, this.options);
        return response;
    }
    get IsAuthenticated() {
        const t = this.GetToken();
        const rt = this.GetRefreshToken();
        const e = Number(new Date(localStorage.getItem(expiryKey)));
        const g = localStorage.getItem(generatedKey);
        if (t && e && g && rt) {
            const expiry = e;
            const generated = new Date(g);
            generated.setSeconds(generated.getSeconds() + expiry);
            return generated > new Date();
        } else {
            return false;
        }
    }
    get UserRole() {
        return this.ParseJwt.Role;
    }
    // get UserModules() {
    //     return this.ParseJwt.modules;
    // }
    SetToken(token) {
        localStorage.setItem(tokenKey, JSON.stringify(token));
        localStorage.setItem(expiryKey, moment().add(1, 'hour').toISOString());
        localStorage.setItem(generatedKey, new Date().toISOString());
    }
    GetToken() {
        var token = localStorage.getItem(tokenKey);
        if (!!token) {
            return JSON.parse(token)
        } else {
            return token
        }
    }
    GetRefreshToken() {
        var token = localStorage.getItem("refresh_Token");
        if (!!token) {
            return token
        } else {
            return token
        }
    }

    get AccessToken() {
        const token = this.GetToken();
        if (token) {
            return token;
            // return token.access_token;
        }
        else {
            return '.';
        }
    }

    RemoveToken() {
        localStorage.removeItem(tokenKey);
        localStorage.removeItem(expiryKey);
        localStorage.removeItem(generatedKey);
        localStorage.removeItem("InitialLogin")

        // localStorage.clear();
    }
    Logout(navigate) {
        this.RemoveToken();
        if (navigate)
            navigate('/login', { replace: true })
    }
    get ParseJwt() {
        const token = this.AccessToken || '.';
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload || '{}') || {};
    }

}