import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { BsEnvelope } from 'react-icons/bs';
import { FiMessageSquare } from 'react-icons/fi';
import WorkAreaCheckSelectFilter from '../../Components/WorkAreaCheckSelectFilter';
import { ActionItem, ActionsBox, SubHeadTitle, SubHeaderTitleBox, SubHeaderWrapper } from '../../Helpers/Global/StyledComponents';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

const SelectCheckData = [
    {
        optionText: 'Option 1',
        isCheck: false
    },
    {
        optionText: 'Option 2',
        isCheck: false
    },
    {
        optionText: 'Option 3',
        isCheck: false
    },
    {
        optionText: 'Option 4',
        isCheck: false
    }
]

function MessagesHeader(props) {
    const { t: st } = useTranslation('simpleData');
    const { t: ct } = useTranslation('common');


    const themeColorBtn = useSelector(state => state.app.theme.colors.buttons);
    return (
        <>
            <SubHeaderWrapper>
                <SubHeaderTitleBox>
                    <FiMessageSquare color={themeColorBtn} size={22} />
                    <SubHeadTitle> {st('messagePage.messagesTitle')}</SubHeadTitle>
                </SubHeaderTitleBox>
                <ActionsBox>
                    <ActionItem>
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic" className="HeaderLinkText">
                                {ct('actions')}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={() => props.setMessageEmployeeModal(true)}><BsEnvelope className="me-2" /> {st('messagePage.toEmployees')}</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={() => props.setSendMessageWorAreaModal(true)}><BsEnvelope className="me-2" /> {st('messagePage.atWorkArea')}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </ActionItem>
                    <ActionItem>
                        <WorkAreaCheckSelectFilter />
                    </ActionItem>
                </ActionsBox>
            </SubHeaderWrapper>

        </>
    );
}

export default MessagesHeader;

