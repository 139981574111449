import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { GoPrimitiveDot } from 'react-icons/go';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { CheckCircle, Edit, ExclaimationCircle, Gear, Note, PaperCLip } from '../../Components/ActionBtns';
import NotesModal from '../../Components/NoteModal';
import ServerSideDataGrid from '../../Components/ServerSideDataGrid';
import { CallAxios } from '../../Helpers';
import { config } from '../../Helpers/Config';
import { Constants } from '../../Helpers/Contents';
import { StatusBox } from '../../Helpers/Global/StyledComponents';
import { UpdateTimeTrackBadge } from '../../Helpers/MasterData';
import { useTranslation } from "react-i18next";


function TimeTrackingGrid(props) {
  const { t: tpt } = useTranslation('timeTrackingPage');
  const { t: ct } = useTranslation('common');

  const cols = [
    { key: '', name: '', sortable: false, searchable: false },
    { key: 'workSpaceName', name: tpt('tbl.areasTags').toString(), sortable: true, searchable: true },
    { key: 'date', name: ct('day').toString(), sortable: true, searchable: true },
    { key: 'time', name: ct('time').toString(), sortable: true, searchable: true },
    { key: 'duration', name: ct('duration'), sortable: true, searchable: true },
    { key: 'breaks', name: ct('break'), sortable: true, searchable: true },
    { key: 'net', name: ct('net'), sortable: true, searchable: true },
    { key: 'regiserdBy', name: tpt('tbl.recordedBy'), sortable: true, searchable: true },
    { key: 'status', name: ct('status') },
    { key: 'action', name: ct('action') },

  ];

  const currentView = useSelector(x => x.app.currentView)
  const user = useSelector(x => x.app.user);
  const appSettings = useSelector(x => x.app.appSettings);
  var selectedTags = useSelector(x => x.app.selectedTags);
  var selectedWorkAreas = useSelector(x => x.app.selectedWorkAreas);
  const [filterTimeTrackingList, setFilterTimeTrackingList] = useState([]);
  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const [timeTrackIdForEdit, setTimeTrackIdForEdit] = useState("");
  const [columns, setcolumns] = useState(cols);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [NoteDetail, setNoteDetail] = useState(null);
  const [isOpen, setIsOpen] = useState(false)

  const themeColor = useSelector(state => state.app.theme.colors.bg);

  // useEffect(() => {
  //   ApplyDateFilter()
  // }, [props.FilterStartDate, props.FilterEndDate]);

  // const ApplyDateFilter = () => {
  //   if (!!props.FilterStartDate && !!props.FilterEndDate) {
  //     setFilterTimeTrackingList(props.timeTrackingList.filter(c => moment(c.dateobj).isSameOrAfter(props.FilterStartDate) && moment(c.dateobj).isSameOrBefore(props.FilterEndDate)))
  //   }
  //   else if (!!props.FilterStartDate) {
  //     setFilterTimeTrackingList(props.timeTrackingList.filter(c => moment(c.dateobj).isSameOrAfter(props.FilterStartDate)))
  //   }
  //   else if (!!props.FilterEndDate) {
  //     setFilterTimeTrackingList(props.timeTrackingList.filter(c => moment(c.dateobj).isSameOrBefore(props.FilterEndDate)))
  //   }
  //   else {
  //     setFilterTimeTrackingList(props.timeTrackingList)
  //   }
  // }


  useEffect(() => {
    if (props.checkRadio === "open") {
      setFilterTimeTrackingList(props.timeTrackingList.filter(x => x.status === false && (x.runningstatus === null || x.runningstatus === 'Complete')))
    } else if (props.checkRadio === "confirm") {
      setFilterTimeTrackingList(props.timeTrackingList.filter(x => x.status === true && (x.runningstatus === null || x.runningstatus === 'Complete')))
    }
    else {
      setFilterTimeTrackingList(props.timeTrackingList)
    }
  }, [props.checkRadio]);

  useEffect(() => {
    setFilterTimeTrackingList(props.timeTrackingList);
  }, [props.timeTrackingList]);
  const UpdateStatus = async (id) => {
    var response = await CallAxios("/api/TimeTracking/UpdateTimeTracking|post", { id: id });
    if (!!response && response.status === 200) {
      // UpdateTimeTrackBadge(dispatch);
      props.getTimeTrackList()
    }
  }

  useEffect(() => {
    if (currentView !== Constants.layoutType.Employee) {
      let colList = [...cols];
      colList.splice(1, 0, { key: 'employeeName', name: ct('employee'), sortable: true, searchable: true })
      setcolumns(colList)
    }
  }, []);
  const getNoteDetail = async (id) => {
    if (isOpen === false) {

      var response = await CallAxios("/api/Notes/GetLatestNote|post", { ObjectId: id.toString(), noteEntity: "TimeTrack" });
      if (!!response && response.status === 200) {
        setNoteDetail(response.data)
        setIsOpen(true);

      }
    } else {
      setIsOpen(false);
    }
  }



  return (
    <>
      {
        showSignatureModal === true ?
          <SignatureModel show={showSignatureModal} onHide={setShowSignatureModal} timeTrackIdForEdit={timeTrackIdForEdit} />
          : null
      }
      {
        showNoteModal === true ?
          <TimeTrackNoteModel show={showNoteModal} onHide={setShowNoteModal} timeTrackIdForEdit={timeTrackIdForEdit} />
          : null
      }

      <ServerSideDataGrid
        columns={columns}
        rows={filterTimeTrackingList.filter(x => (selectedWorkAreas.some(c => c.value === x.workSpaceId || !!x.workSpaceId) || x.tagids.find(v => selectedTags.some(c => c.value === v))))}
        loadData={(p) => props.getTimeTrackList(p)}
        itemsPerPage={10}
        getPaginateInfo={(p) => props.getPaginateInfo(p)}
        customBody={(rows) =>
          rows?.map((value, i) =>
            <tr>
              <td>
                {
                  !!value.runningstatus && value.runningstatus === "Start"
                    ?
                    <GoPrimitiveDot style={{ color: "#2ecc71", fontSize: "25px" }} />
                    :
                    !!value.runningstatus && value.runningstatus === "Stop"
                      ?
                      <GoPrimitiveDot style={{ color: "yellow", fontSize: "25px" }} />
                      : null
                }
              </td>
              {
                currentView !== Constants.layoutType.Employee &&
                <td>
                  <Link className="DetailLink" onClick={() => localStorage.setItem("selectedEmpId", value.empId)} state={{ currentView: 'Admin' }} to={`/companies/${user.LocationId}/users/${value.empId}/master_data`}> {value.employeeName}</Link>
                </td>
              }
              <td>
                {value.workSpaceName} | {!!appSettings.tags && appSettings.tags.filter(x => value.tagids.some(d => d === x.value)).map(x => x.text).join(",")}
              </td>
              <td>{value.date}</td>
              <td>{value.time}</td>
              <td>{value.duration} h</td>
              <td>
                <span>
                  {value.breaks} h
                  {
                    value.breaksDetail.length > 0 ?
                      // <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Popover id="popover-basic">
                      //   <Popover.Title as="h3"> Breaks</Popover.Title>
                      //   <Popover.Content>
                      //     {value.breaksDetail.map((vv, ii) => {

                      //       return <div>{vv}</div>
                      //     }
                      //     )}
                      //   </Popover.Content>
                      // </Popover>}>
                      //   <span className=''>
                      //     <FaInfoCircle className="ml-1" />
                      //   </span>
                      // </OverlayTrigger>
                      <span className='' data-tooltip-id="my-tooltip" data-tooltip-html={
                        value.breaksDetail.map((vv, ii) => {

                          return `<div>${vv}</div>`
                        }
                        )
                      }>
                        <ExclaimationCircle color='#a4a4a4' className="ml-1" />
                      </span>
                      : null
                  }
                </span>
              </td>
              <td>{value.net} h</td>
              <td><Button className='me-2' status={"rejected"}>{value.regiserdBy}</Button> </td>
              <td>
                <div className='d-flex align-items-center'>
                  <StatusBox className='me-2' status={value.status === true ? "confirmed" : "rejected"}>{value.status === true ? <>{ct("confirmed")}</> : <>{ct("unconfirmed")}</>}</StatusBox>
                  {
                    value.haveNote ?
                      <Note onClick={() => { getNoteDetail(value.id); }} data-tooltip-id="my-tooltip-api" data-tooltip-html={
                        ` <div className='minwidth150px' >
                      <div className='header bg-light p-3'>
                        <h6 className='mb-0 fw-bold' style={{ fontSize: "12px" }}>${NoteDetail?.notecreatedByName}</h6>
                        <span style={{ fontSize: "10px" }}>${NoteDetail?.notecreatedDate}</span>
                      </div>
                      <div className='popoverBody p-3'>
                        <span style={{ fontSize: "12px" }}> ${NoteDetail?.noteText}  </span>
                      </div>
                    </div>`
                      } />
                      : null
                  }
                  {
                    value.attachment ?
                      <a target='_blank' href={config.S3BucketURL + value.attachment}> <PaperCLip data={value.attachment} /></a>
                      : null
                  }

                </div>
              </td>
              <td>
                <div className='d-flex'>
                  {
                    value.runningstatus === null || value.runningstatus === 'Complete' ?
                      <Gear data-tooltip-id="my-tooltip" data-tooltip-content={ct('toEdit')} className="btnTblAction bgBlue" onClick={() => { props.setTimeTrackIdForEdit(value.id); props.setShowAddTimeTrackingModal(true) }} />
                      : null
                  }
                  <Note data-tooltip-id="my-tooltip" data-tooltip-content={ct('attachedNote')} className="btnTblAction bgYellow" onClick={() => { setTimeTrackIdForEdit(value.id); setShowNoteModal(true) }} />
                  {
                    (value.runningstatus === null || value.runningstatus === 'Complete') && value.regiserdBy === 'Station-App' && value.haveSignature === true ?
                      <Edit color={themeColor} className="btnTblAction" onClick={() => { setTimeTrackIdForEdit(value.id); setShowSignatureModal(true) }} />
                      : null
                  }
                  {
                    currentView === Constants.layoutType.Admin && value.runningstatus === null || value.runningstatus === 'Complete' ?
                      value.status === false && user.roles.some(x => x === Constants.roles.AccountAdmin || x === Constants.roles.WorkspaceAdmin || x === Constants.roles.Admin) ?
                        <CheckCircle color='#8dc572' data-tooltip-id="my-tooltip" data-tooltip-content={ct('toConfirm')} className="btnTblAction bgGreen" onClick={() => UpdateStatus(value.id)} />
                        : null
                      : null
                  }
                </div>
              </td>
            </tr>
          )}

        customFooter={
          <tr className='text-left'>
            <th style={{ width: "30px" }} className="NumberDataCell">{ct('total')}</th>
            {
              currentView === Constants.layoutType.Employee ?
                null :
                <th></th>
            }
            <th></th>
            <th>{filterTimeTrackingList.length}</th>
            <th></th>
            <th>{filterTimeTrackingList.reduce((a, v) => a = parseFloat(a) + parseFloat(v.duration.replace(":", ".")), 0).toFixed(2)}h</th>
            <th>{filterTimeTrackingList.reduce((a, v) => a = parseFloat(a) + parseFloat(v.breaks.replace(":", ".")), 0).toFixed(2)}h</th>
            <th>{filterTimeTrackingList.reduce((a, v) => a = parseFloat(a) + parseFloat(v.net.replace(":", ".")), 0).toFixed(2)}h</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        }
      />
      <Tooltip
        id="my-tooltip-api"
        content="Hello world!"
        isOpen={isOpen}
      />
    </>
  );
}

export default TimeTrackingGrid;

export function TimeTrackNoteModel(props) {
  const { t: ct } = useTranslation('common');


  return (
    <Modal show={props.show} onHide={() => props.onHide(false)}>
      <Modal.Header closeButton >
        <Modal.Title> {ct('note')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col md={12}>
              <Container fluid>
                <NotesModal objIdforNote={props.timeTrackIdForEdit} noteEntity="TimeTrack" />
              </Container>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-secondary" onClick={() => props.onHide(false)}> {ct('shutdown')}</Button>
      </Modal.Footer>
    </Modal>
  )
}

export function SignatureModel(props) {
  const { t: ct } = useTranslation('common');
  const { t: tpt } = useTranslation('timeTrackingPage');

  const [SignatursList, setSignatursList] = useState([]);

  useEffect(() => {
    getData()
  }, []);
  const getData = async () => {
    var data = {
      id: props.timeTrackIdForEdit.toString()
    }
    var response = await CallAxios('/api/TimeTracking/GetTimeTrackSignatures|post', data)
    if (!!response && response.data.length > 0) {
      setSignatursList(response.data)
    }
  }
  return (
    <Modal show={props.show} onHide={() => props.onHide(false)}>
      <Modal.Header closeButton >
        <Modal.Title> {tpt('signatureMdl.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col md={12}>
              <Container fluid>
                <Row>
                  {
                    !!SignatursList && SignatursList.length > 0 ? SignatursList.map((value, key) => {
                      return <div className='border-bottom p-2 text-center'>

                        {
                          key === 0 ?
                            <>
                              <p>{ct('timeTracking')} {value.text}</p>

                            </>
                            :
                            key === SignatursList.length - 1 && value.text === 'Complete' ?
                              <p>{ct('timeTracking')} {value.text}</p>
                              :
                              <p>
                                {value.text === 'Stop' ?
                                  <>{tpt('pauseStart')}</>
                                  :
                                  <>{tpt('pauseStop')}</>
                                }
                              </p>
                        }
                        <img src={config.S3BucketURL + value.value} className='img-fluid' />
                      </div>
                    })
                      : null
                  }
                </Row>
              </Container>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-secondary" onClick={() => props.onHide(false)}> {ct('shutdown')} </Button>
      </Modal.Footer>
    </Modal>
  )
}