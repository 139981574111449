import Highcharts from 'highcharts';
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { CallAxios } from "../../Helpers";
import { FiltersDiv, RefreshButton } from "./ReportsIndex";


export function LineHighChart(props) {
    const chartdiv = useRef();
    const [getFilters, setGetFilters] = useState(undefined);

    const [refreshDate, setrefreshDate] = useState(new Date());
    const [IsFilterChange, setIsFilterChange] = useState(false);
    const [IsInitialLoadComplete, setIsInitialLoadComplete] = useState(false);
    const [ChartData, setChartData] = useState([]);
    const [tickInterval, setTickInterval] = useState(1000 * 15 * 24);
    const [BudgetData, setBudgetData] = useState([]);

    useEffect(() => {
        if (!!ChartData) {
            Highcharts.chart(chartdiv.current, {
                legend: {
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'middle'
                },
                title: {
                    text: ''
                },

                subtitle: {
                    text: ''
                },

                yAxis: {
                    min: 0,
                    title: {
                        text: 'hours'
                    }
                },
                // chart: {
                //   marginBottom: 100
                // },

                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    layout: 'horizontal',
                    floating: false,
                },
                xAxis: {
                    type: 'datetime',
                    accessibility: {
                        rangeDescription: ''
                    },
                    tickInterval: tickInterval,

                },
                tooltip: {
                    useHTML: true,
                    shared: true,
                    borderRadius: 15,
                    backgroundColor: "#fff",
                    borderColor: "#ccc",
                    shadow: false,

                    formatter: function () {
                        return `<div class=" text-center">${moment(this.x).format("DD/MM/YYYY HH:mm")}</div> 
                            <div class=" text-center" style="color:${this?.points[0].color}"> Target: ${this?.y}</div> 
                            `
                    }
                },
                plotOptions: {
                    series: {
                        label: {
                            connectorAllowed: false
                        },
                        // pointStart: Date.now(2010, 0, 1),
                        // pointInterval:7* 24 * 3600 * 1000              
                    }
                },

                series: ChartData,
                //   series: [
                //     {
                //       name: 'Target',
                //       data: [{x:Date.UTC(new Date().getFullYear(),0,1),y:0},{x:Date.UTC(new Date().getFullYear(),0,new Date().getDay()),y:5}],

                //   },
                //   {
                //       name: 'Plan',
                //       data: [{x:Date.UTC(new Date().getFullYear(),0,1),y:0},{x:Date.UTC(new Date().getFullYear(),0,new Date().getDay()),y:8}],

                //   },
                //   {
                //       name: 'Actual',
                //       data: [{x:Date.UTC(new Date().getFullYear(),0,1),y:0},{x:Date.UTC(new Date().getFullYear(),0,new Date().getDay()),y:6}],

                //   },
                // ],

                responsive: {
                    rules: [
                        {
                            condition: {
                                maxWidth: 500
                            },

                        }
                    ]
                }

            });
        }
    }, [ChartData]);

    useEffect(() => {
        if (IsInitialLoadComplete) {
            if (props.Filters.length > 0 && props.Filters?.some(x => x.ReportId === props.chartid)) {
                let prevstate = [...props.Filters];
                let findObj = prevstate.find(x => x.ReportId === props.chartid);
                findObj.title = getFilters.title;
                findObj.metricCheckBox = getFilters.metricCheckBox;
                findObj.areas = getFilters.areas;
                findObj.employees = getFilters.employees;
                findObj.tags = getFilters.tags;
                findObj.absenceType = getFilters.absenceType;
                findObj.lastPeriod = getFilters.lastPeriod;
                findObj.groupingType = getFilters.groupingType;
                findObj.graphType = getFilters.graphType;
                findObj.interval = getFilters.interval;
                findObj.budget = getFilters.budget;
                findObj.startDate = getFilters.startDate;
                findObj.endDate = getFilters.endDate;

                let index = prevstate.findIndex(x => x.ReportId === props.chartid);
                prevstate[index] = findObj;
                props.setFilters(prevstate);

            } else {
                getFilters.ReportId = props.chartid
                props.setFilters([...props.Filters, getFilters]);
            }
            setIsFilterChange(true)
        }
    }, [getFilters]);

    useEffect(() => {
        if (IsInitialLoadComplete) {
            getChartData();
        }
    }, [IsInitialLoadComplete]);

    const getChartData = async () => {

        getFilters.interval === "15m" && setTickInterval(1000 * 15 * 24)
        getFilters.interval === "30m" && setTickInterval(1000 * 30 * 24)
        getFilters.interval === "Hour" && setTickInterval(1000 * 60 * 24)
        getFilters.interval === "day" && setTickInterval(1000 * 3600 * 24)
        getFilters.interval === "week" && setTickInterval(1000 * 25200 * 24)
        getFilters.interval === "month" && setTickInterval(1000 * 100000 * 24)
        let data = {};
        data = {
            ...getFilters,
            startDate: !!getFilters.startDate ? moment(parseFloat(getFilters.startDate)).startOf("day") : null,
            endDate: !!getFilters.endDate ? moment(parseFloat(getFilters.endDate)).endOf("day") : null
        }
        setIsFilterChange(false)

        var response = await CallAxios(
            "/api/Reports/GetChartData|post", data
        );
        if (!!response && response.status === 200) {

            if (response.data.targetHoursList.length > 0) {
                if (!!getFilters && getFilters.budget > 0) {
                    var CostObject =
                    {
                        "name": "Simple Labor Costs",
                        "data": []
                    }
                    for (var d = new Date(data.startDate); d <= data.endDate; d.setDate(d.getDate() + 1)) {
                        CostObject.data.push({ x: new Date(d).getTime(), y: getFilters.budget });
                    }
                    response.data.targetHoursList.push(CostObject);
                }
                response.data.targetHoursList.map((v, k) => {
                    var targetlist = []
                    v.data.map((val, key) => {
                        targetlist.push({ x: new Date(val.x).getTime(), y: val.y })
                    })
                    v.data = targetlist;
                })
            }

            setChartData(response.data.targetHoursList)
            setrefreshDate(new Date)
        }
    }

    return (
        <>
            <FiltersDiv chartid={props.chartid} setIsInitialLoadComplete={setIsInitialLoadComplete} onChange={selected => setGetFilters(selected)} />
            <div ref={chartdiv} style={{ height: "400px" }}></div>
            <RefreshButton IsFilterChange={IsFilterChange} refreshDate={refreshDate} getChartData={getChartData} />
        </>
    );
}
export default LineHighChart;