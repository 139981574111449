import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import moment from "moment";
import { createRef, useEffect, useState } from "react";
import { Col, Nav, Row } from "react-bootstrap";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { FaRegCalendarAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import Swal from 'sweetalert2';
import { CallAxios } from "../../Helpers";
import { ChooseDateModal, EmployeeAvailabilityDetailModal } from '../Profile/AvailabilitiesTab';
import { useTranslation } from "react-i18next";


function AvailibilityView() {
    const { t: cl } = useTranslation('calendarPage');
    const { t: ct } = useTranslation('common');

    const user = useSelector(x => x.app.user)
    const MycalendarRef = createRef();

    const currentView = useSelector(x => x.app.currentView)
    const [chooseDateModal, setChooseDateModal] = useState(false);
    const [weekNum, setWeekNum] = useState("");
    const [events, setEvents] = useState([]);
    const [ShowAbsenceDetailModal, setShowAvailibilityModal] = useState(false);
    const [AvailbilityInfoObjIDforEdit, setAvailbilityInfoObjIDforEdit] = useState("");
    const [TargetHour, setTargetHour] = useState(0);
    const [AssignHours, setAssignHours] = useState(0);
    useEffect(() => {
        Promise.all([
            getEvents(),
            GetEmployeeTargetAndAssignHours()
        ])
        getWeekNum(new Date());
    }, []);
    const getEvents = async () => {
        var data = {
            empId: user.LoginUserId.toString(),
            startdate: moment(MycalendarRef.current.calendar.currentData.dateProfile.activeRange.start).startOf("day"),
            enddate: moment(MycalendarRef.current.calendar.currentData.dateProfile.activeRange.end).startOf("day"),
        }
        var response = await CallAxios('/api/Calendar/GetEmployeeAvailabilities|post', data)
        if (!!response && response.status === 200) {
            setEvents(response.data)
        }
    }
    const GetEmployeeTargetAndAssignHours = async () => {
        var data = {
            empId: user.LoginUserId.toString(),
            startdate: moment(MycalendarRef.current.calendar.currentData.dateProfile.activeRange.start).startOf("day"),
            enddate: moment(MycalendarRef.current.calendar.currentData.dateProfile.activeRange.end).startOf("day"),
        }
        var response = await CallAxios('/api/Calendar/GetEmployeeTargetAndAssignHours|post', data)
        if (!!response && response.status === 200) {
            setTargetHour(response.data.weeklytargetHours)
            setAssignHours(response.data.weeklyassignHours)
        }
    }
    const getChooseDate = (date) => {
        getWeekNum(date);
        setChooseDateModal(false)
    }
    const getWeekNum = (date) => {
        let now = date;
        let onejan = new Date(now.getFullYear(), 0, 1);
        let week = Math.ceil((((now.getTime() - onejan.getTime()) / 86400000) + onejan.getDay() + 1) / 7);
        setWeekNum(week)
    }
    const reFreshData = (date) => {
        Promise.all([
            getEvents(),
            GetEmployeeTargetAndAssignHours()
        ])
    }
    const createDragEvent = async (info) => {
        if (!!MycalendarRef.current) {
            var data = {
                StartDate: info.start,
                EndDate: info.end,
                empId: user.LoginUserId.toString(),
                UpdateDDValue: 'single',
            }

            var response = await CallAxios('/api/Calendar/AddEditEmployeeAvailability|post', data)
            reFreshData()
            if (!!response && response.data !== true) {
                Swal.fire({
                    icon: 'error',
                    title: response.data,
                })
            }
        }
    }
    const nextMonths = () => {
        let calendar = MycalendarRef.current;
        if (!!calendar) {
            calendar.getApi().next();
            var date = calendar.getApi().getDate();
            getWeekNum(date)
            reFreshData()
        }
    }
    const PrevMonths = () => {
        let calendar = MycalendarRef.current;
        if (!!calendar) {
            calendar.getApi().prev();
            var date = calendar.getApi().getDate();
            getWeekNum(date)
            reFreshData()
        }
    }

    return (
        <>
            {
                chooseDateModal === true ?
                    <ChooseDateModal show={chooseDateModal} setshow={setChooseDateModal} chose={getChooseDate} />
                    : null
            }
            <div>
                <Row className='mt-4'>
                    <Col md={4} className="text-left align-self-end">
                        <span> {cl("availibilityView.weekTarget")}: <b>{TargetHour}h</b>     | {cl("availibilityView.currentWeek")}: <b>{AssignHours}h</b></span>
                    </Col>
                    <Col md={8} className="">
                        <Nav className="justify-content-end" activeKey="/employee/employeecalender">
                            <Nav.Item>
                                <div className="ToolMonthChange">
                                    <div>
                                        <a><BsChevronLeft onClick={() => PrevMonths()} className="btnPrev" /></a>
                                    </div>
                                    <div>
                                        <div className="MonthChangeContainer" onClick={() => setChooseDateModal(true)}>
                                            <FaRegCalendarAlt className="CalIcon" />
                                            <span className="MonthTitle"> {cl("week")} {weekNum}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <a><BsChevronRight onClick={() => nextMonths()} className="btnNext" /></a>
                                    </div>
                                </div>
                            </Nav.Item>
                        </Nav>
                    </Col>
                </Row>
            </div>
            <FullCalendar
                ref={MycalendarRef}
                headerToolbar={{
                    left: '',
                    center: '',
                    right: ''
                }}
                firstDay='1'
                allDaySlot={false}
                plugins={[resourceTimeGridPlugin, interactionPlugin]}
                initialView="timeGridWeek"
                slotMinTime='00:00'
                slotMaxTime='24:00'
                slotLabelFormat={[
                    {
                        hour: "2-digit",
                        minute: "2-digit",
                        meridiem: true,
                    },
                ]}
                locale={"en-GB"}
                slotDuration='01:00' // 1 hours
                editable={true}
                eventStartEditable={true}
                eventResizableFromStart={true}
                eventDurationEditable={true}
                eventResourceEditable={true}
                events={events}
                selectable={true}
                dateClick={() => {
                    // this.handleModalShowHide("AddAbsenceModal")
                }}
                eventClick={(i) => {
                    setAvailbilityInfoObjIDforEdit(i.event._def.extendedProps.avalibilityIds)
                    setShowAvailibilityModal(true)
                }}
                eventDrop={async (info) => {
                    var data = {
                        id: info.event._def.extendedProps.avalibilityIds,
                        StartDate: info.event.start,
                        EndDate: info.event.end,
                        UpdateDDValue: 'single',

                    }

                    var response = await CallAxios('/api/Calendar/AddEditEmployeeAvailability|post', data)
                    if (!!response && response.data === true) {
                        reFreshData()
                    }
                    if (!!response && response.data !== true) {
                        Swal.fire({
                            icon: 'error',
                            title: response.data,
                        })
                    }
                }}
                eventResizeStart={(info) =>
                    console.log("resize calling", info.event.end)
                }
                eventResize={async (info) => {
                    var data = {
                        id: info.event._def.extendedProps.avalibilityIds,
                        StartDate: info.event.start,
                        EndDate: info.event.end,
                        UpdateDDValue: 'single',
                    }


                    var response = await CallAxios('/api/Calendar/AddEditEmployeeAvailability|post', data)
                    if (!!response && response.data === true) {
                        reFreshData()
                    }
                    if (!!response && response.data !== true) {
                        Swal.fire({
                            icon: 'error',
                            title: response.data,
                        })
                    }
                }}
                select={(info) => {
                    createDragEvent(info)
                }}
            />
            {
                ShowAbsenceDetailModal === true ?
                    <EmployeeAvailabilityDetailModal userid={user.LoginUserId.toString()} getEvents={reFreshData} show={ShowAbsenceDetailModal} AvailbilityInfoObjIDforEdit={AvailbilityInfoObjIDforEdit} onHide={setShowAvailibilityModal} />
                    : null
            }

        </>
    );
}

export default AvailibilityView;

