
import { useState } from "react";
import { Container } from "react-bootstrap";
import { BsChevronDoubleLeft, BsChevronDoubleRight } from "react-icons/bs";
import { Outlet } from "react-router-dom";
import { CollapseMenu, ContentArea, GLobalcss, HeaderLeftLabel, MainWrapper } from "../Helpers/Global/StyledComponents";
import AccountSidebar from "./Sidebar/AccountSidebar";

export const Accountlayout = () => {
    const [isCollapse, setIsCollapse] = useState(false);

    return (
        <MainWrapper className="mainWrapperOfLayout">
            <GLobalcss />
            <div className="d-flex layoutDflex">
                <div md={2} className="pe-0 col-sidebar" >
                    <AccountSidebar isCollapse={isCollapse} setIsCollapse={setIsCollapse} />
                </div>
                <div md={10} className="ps-0 col-main-content flex-grow-1">
                    <ContentArea className="contentAreaGrey">
                        <HeaderLeftLabel className="ps-4 pt-3 ">
                            <CollapseMenu className='me-3' onClick={() => setIsCollapse(!isCollapse)}>
                                {isCollapse ?
                                    <BsChevronDoubleRight size={20} />
                                    :
                                    <BsChevronDoubleLeft size={20} />
                                }

                            </CollapseMenu>

                        </HeaderLeftLabel>
                        {/* <ContentHeader>
                            <MainHeader />
                        </ContentHeader> */}
                        <Container fluid className="px-0">
                            <Outlet isCollapse={isCollapse} setIsCollapse={setIsCollapse} />
                        </Container>
                    </ContentArea>
                </div>
            </div>
        </MainWrapper>
    );
}


