import { useRef, useState } from "react";
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import * as Yup from 'yup';
import { FaUpload } from "react-icons/fa";
import FileBase64 from 'react-file-base64';
import MultiSelect from "./MultiSelect";
import { CallAxios } from "../Helpers";
import { useTranslation } from "react-i18next";

export default function MessageModal(props) {
  const { t: st } = useTranslation('simpleData');
  const { t: ct } = useTranslation('common');


  const fileInput = useRef();
  const [File, setFile] = useState("");
  const [FileName, setFileName] = useState("");
  const formik = useFormik({
    initialValues: {
      message: "",
      EmpIds: []
    },
    validationSchema: Yup.object().shape({
      message: Yup.string().required(st('messagePage.msgEmployeeMdl.errorMsg')),
      EmpIds: Yup.array().min(1, st('messagePage.msgEmployeeMdl.errorEmpId')).required("required")
    }),
    onSubmit: values => {
      Submit(values)
    },
  });

  const Submit = async (values) => {
    var data = {
      EmpIds: values.EmpIds,
      Message: values.message,
      file: File,
    }
    // if (messageEmpList.length > 0) {
    // var form_data = new FormData();
    // for (var key in data) {
    //   if (typeof (data[key]) === "object" && !!data[key] && data[key].length > 0) {
    //     for (var i = 0; i < data[key].length; i++) {
    //       form_data.append(key + [], data[key][i]);
    //     }
    //   } else {
    //     form_data.append(key, data[key]);

    //   }
    // }
    var form_data = new FormData();
    Object.keys(data).forEach((key, index) => {
      if (typeof (data[key]) === "object" && !!data[key] && data[key].length > 0) {
        for (var i = 0; i < data[key].length; i++) {
          form_data.append(key + [], data[key][i]);
        }
      } else if (data[key]) {
        form_data.append(key, data[key]);
      }
    })

    var response = await CallAxios("/api/Employee/sendmessage|post", form_data);
    if (!!response && response.status === 200) {
      if (!!props.getData) {
        props.getData()
      }
      props.onHide(false)
    }

    // toast.warning("Please select atleast one employee and write a message.")

  }
  const getFiles = (files) => {
    setFileName(files.name);
    setFile(files.file)
  }
  return (
    <>
      <Modal show={props.show} onHide={() => props.onHide(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{st('messagePage.msgEmployeeMdl.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12} className="mb-3">
              <MultiSelect name="EmpIds" optionKey="value" handleOnChange={(selected) => formik.setFieldValue("EmpIds", selected)} options={props.EmpList} placeholder={st('messagePage.msgEmployeeMdl.employee')} />
              {formik.touched.EmpIds && formik.errors.EmpIds ? (
                <span className="error text-danger text-left">{formik.errors.EmpIds}</span>
              ) : null}
            </Col>
            <Col md={12} className="mb-3">
              <Form.Group controlId="SendempMsgForm.txtmessage">
                <Form.Control as="textarea" name="message" onChange={(e) => formik.handleChange(e)} rows={2} />
                {formik.touched.message && formik.errors.message ? (
                  <span className="error text-danger text-left">{formik.errors.message}</span>
                ) : null}
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <a className="btnUpload position-relative">
                  <FaUpload className="icon" /> <span className="mx-2">{!!FileName ? FileName : <>{st('messagePage.msgEmployeeMdl.addAttachment')}</>}</span>
                  <FileBase64
                    hidden
                    id='Uploadattachment'
                    ref={fileInput}
                    multiple={false}
                    onDone={getFiles}
                  />
                </a>

                {/* <Form.File id="Uploadattachment" onChange={(e) => {
                    this.setState({ MessageFile: e.target.files[0] })
                    this.showResult(e.target.files[0])
                  }
                  } label="Attachment" /> */}
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btnPrimaryGreen" onClick={(e) => formik.handleSubmit(e)}>{ct('finished')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}