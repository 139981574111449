import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { CallAxios } from "../../Helpers";
import { SignInManager } from "../../Helpers/Authentication/SignInManager";
import { Constants } from "../../Helpers/Contents";
import { setCurrentView, setJwt, setUser } from "../../Store/slices/AppSlice";

function AdminLogin() {
    const [UrlToken, setUrlToken] = useState(null);
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const dispatch = useDispatch();

    useEffect(() => {
        new SignInManager().RemoveToken()
        setUrlToken(decodeURIComponent(params.get("code")))
    }, []);
    useEffect(() => {
        if (!!UrlToken) {
            onLoad();
        }
    }, [UrlToken]);
    const onLoad = async () => {
        var res = await CallAxios('/api/account/LoginAsCompanyAdmin|post', { id: UrlToken });
        if (!!res && res.status === 200) {
            new SignInManager().SetToken(res.data.token)
            dispatch(setJwt({ token: res.data.token }));
            dispatch(
                setUser({
                    CompanyId: res.data.compnayId,
                    LocationId: res.data.locationId,
                    roles: res.data.userRoles.split(","),
                    LoginUserId: res.data.employeeId,
                })
            );
            localStorage.setItem("InitialLogin", true) //use this flag to set all workareas and tags seleted by default in redux
            localStorage.setItem("refresh_Token", res.data.refreshToken)

            if (res.data.userRoles.split(",").some(x => x === Constants.roles.AccountAdmin || x === Constants.roles.WorkspaceAdmin)) {
                dispatch(setCurrentView(Constants.layoutType.Admin));
            } else {
                dispatch(setCurrentView(Constants.layoutType.Employee));
            }
            navigate(`/companies/${res.data.compnayId}/users/${res.data.employeeId}/master_data`)
        }
    }
    return (
        <>
            <h1>Please wait for authentication...</h1>
        </>
    )
}

export default AdminLogin;