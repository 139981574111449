import { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from 'react-toastify';
import { AxiosLite, CallAxios } from "../../Helpers";
import { config } from '../../Helpers/Config';
import { useTranslation } from "react-i18next";


function CalendarSynchronization() {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');


    const { userid } = useParams();
    const user = useSelector(x => x.app.user)

    const [isOutlookAuthenticated, setIsOutlookAuthenticated] = useState(false);
    const [isGoogleAuthenticated, setIsGoogleAuthenticated] = useState(false);
    const [Syncshifts, setSyncshifts] = useState(true);
    const [SyncAbsences, setSyncAbsences] = useState(true);

    useEffect(() => {
        getData()
    }, []);
    const getData = async () => {
        var response = await CallAxios("/api/Employee/GetCalanderSyncInfo|post", { id: userid });
        if (!!response && response.status === 200) {
            setIsOutlookAuthenticated(response.data.isOutlookCalenderSync)
            setIsGoogleAuthenticated(response.data.isGoogleCalenderSync)
        }
    }
    const GoogleSignin = async () => {
        window.location.href = config.API_URL + "/api/GoogleCalendar/LogintoGoogle?StateURL=" + encodeURIComponent(window.location.href + "||" + user.EncLoginUserId)
    }
    const AouthOutlookUser = () => {
        window.location.href = config.API_URL + "/api/OutlookCalendar/LogintoOutlook?StateURL=" + encodeURIComponent(window.location.href + "||" + user.EncLoginUserId)
    }
    const LogoutFromOutlook = async () => {
        var response = await CallAxios("/api/Employee/SignoutOutlookAouth|post", { id: user.EncLoginUserId })
        if (!!response && response.status === 200) {
            getData()
        }
    }
    const LogoutFromGoogle = async () => {
        var response = await CallAxios("/api/Employee/SignoutGoogleAouth|post", { id: user.EncLoginUserId })
        if (!!response && response.status === 200) {
            getData()
        }
    }
    const UpdateCalander = async () => {
        var data = {
            id: user.EncLoginUserId,
            SyncAbsences: SyncAbsences,
            Syncshifts: Syncshifts
        }
        var response = await CallAxios("/api/Employee/SyncCalander|post", data)
        if (!!response && response.status === 200) {
            if (response.data === true) {
                toast.success('Synced Successfully...')
            }
        }
    }

    return <>
        <div className="DetailInfoWrapper text-left">
            <div className='mb-3'>
                <h4 className='fw-light'> {t('calendarSynchronization')}</h4>
                <p> {t('calendarSyncTab.para')}.</p>
                <div>
                    <Form.Group className="mb-3">
                        <Row>
                            <Col md={4}>
                                <Form.Check className="mb-2" name="notifications5" onChange={(e) => setSyncshifts(e.target.checked)} checked={Syncshifts} label={t('calendarSyncTab.syncShiftsLabel')} type="checkbox" />
                            </Col>
                            <Col md={4}>
                                <Form.Check className="mb-2" name="notifications5" onChange={(e) => setSyncAbsences(e.target.checked)} checked={SyncAbsences} label={t('calendarSyncTab.syncAbsencesLabel')} type="checkbox" />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Row>
                        <Col md={3}>

                            <div>
                                <div className="mb-2 fw-bold"> {t('calendarSyncTab.googleCalendar')}</div>
                                {
                                    isGoogleAuthenticated ?
                                        <Button name='Friday' variant="primary" onClick={() => LogoutFromGoogle()} > {ct('signout')}</Button>
                                        :
                                        <Button name='Friday' variant="primary" onClick={() => GoogleSignin()}> {t('calendarSyncTab.signinGoogle')}</Button>
                                }
                            </div>
                        </Col>
                        <Col md={3}>
                            <div>
                                <div className="mb-2 fw-bold"> {t('calendarSyncTab.microsoftOutlook')}</div>
                                {
                                    isOutlookAuthenticated ?
                                        <Button name='Friday' variant="primary" onClick={() => LogoutFromOutlook()}> {ct('signout')}</Button>
                                        :
                                        <Button name='Friday' variant="primary" onClick={() => AouthOutlookUser()} > {t('calendarSyncTab.signinOutlook')}</Button>
                                }
                            </div>
                        </Col>
                        <Col md={6}>
                            {
                                isGoogleAuthenticated || isOutlookAuthenticated ?
                                    <div>
                                        <div className="mb-2 fw-bold">{t('calendarSyncTab.authenticatedText')}.</div>
                                        <Button name='Friday' variant="primary" onClick={() => UpdateCalander()} > {t('calendarSyncTab.updateCalendar')}</Button>
                                    </div>
                                    :
                                    <div>
                                        <div className="mb-2 fw-bold"> {t('calendarSyncTab.authenticatedText')}.</div>
                                        <Button name='Friday' variant="primary" > {t('calendarSyncTab.updateCalendar')}</Button>
                                    </div>
                            }
                        </Col>
                    </Row>

                </div>
            </div>
        </div>
    </>
}

export default CalendarSynchronization;
