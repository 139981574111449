import React from 'react';
import { SubHeadTitle, SubHeaderTitleBox, SubHeaderWrapper } from '../../Helpers/Global/StyledComponents';
import { useTranslation } from "react-i18next";

const SelectCheckData = [
    {
        optionText: 'Option 1',
        isCheck: false
    },
    {
        optionText: 'Option 2',
        isCheck: false
    },
    {
        optionText: 'Option 3',
        isCheck: false
    },
    {
        optionText: 'Option 4',
        isCheck: false
    }
]

function AccountSettingHeader(props) {
    const { t: ast } = useTranslation('accountSetting');


    return (
        <>
            <SubHeaderWrapper>
                <SubHeaderTitleBox>
                    <SubHeadTitle> {ast('accountSettings')}</SubHeadTitle>
                </SubHeaderTitleBox>

            </SubHeaderWrapper>
        </>
    );
}

export default AccountSettingHeader;

