import { useRef, useState } from "react";
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import * as Yup from 'yup';
import { FaUpload } from "react-icons/fa";
import FileBase64 from 'react-file-base64';
import MultiSelect from "./MultiSelect";
import { AxiosLite, CallAxios } from "../Helpers";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";



export default function MessageToWorAreaModal(props) {
    const { t: st } = useTranslation('simpleData');
    const { t: ct } = useTranslation('common');

    const [WorkspaceList, setWorkspaceList] = useState([]);
    const [selectedArea, setSelectedArea] = useState([]);
    const [message, setMessage] = useState("");
    const [MessageFile, setMessageFile] = useState(null);
    const [filetype, setFiletype] = useState("");

    useEffect(() => {
        getData()
    }, []);
    const getData = async () => {
        var response = await AxiosLite("/api/BaseLoginData/getLocationWorkAreas|get");
        if (!!response && response.status === 200) {
            setWorkspaceList(response.data);
        }
    }
    const finished = async () => {
        var data = {
            starDate: new Date().toUTCString(),
            endDate: new Date().toUTCString(),
            WorkAreas: selectedArea,
            Message: message,
            file: MessageFile,
            fileType: filetype

        }
        var form_data = new FormData();
        for (var key in data) {
            if (typeof (data[key]) === "object" && !!data[key] && data[key].length > 0) {
                for (var i = 0; i < data[key].length; i++) {
                    form_data.append(key + [], data[key][i]);
                }
            } else {
                form_data.append(key, data[key]);
            }
        }
        var response = await CallAxios("/api/Calendar/SendMessageToWorkAreaAllEmployee|post", form_data)
        if (!!response && response.status === 200) {
            if (!!props.getData) {
                props.getData()
            }
            props.setshow(false)
        }
    }
    const showResult = (file) => {
        setFiletype(file.name)
        fileDataURL(file)
    }
    const fileDataURL = file => new Promise((resolve, reject) => {
        let fr = new FileReader();
        fr.onload = () => resolve(fr.result);
        fr.onerror = reject;
        fr.readAsDataURL(file)
    });
    const handelWorkareaChange = (val) => {
        setSelectedArea(val)
    }
    return (
        <Modal show={props.show} onHide={() => props.setshow(false)}>
            <Modal.Header closeButton>
                <Modal.Title> {st('messagePage.workAreaMdl.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={12} className="mb-3">
                        {
                            WorkspaceList.length > 0 &&
                            <MultiSelect name="areaIds" optionKey="value" handleOnChange={(selected) => handelWorkareaChange(selected)} options={WorkspaceList} placeholder={st('messagePage.workAreaMdl.group')} />
                        }
                        {/* <GroupsMultiselectModal defaultselect={selectedArea} data={WorkspaceList} getValues={setSelectedArea} /> */}
                    </Col>
                    <Col md={12} className="mb-3">
                        <Form.Group controlId="SendPlanForm.txtmessage">
                            <Form.Control as="textarea" rows={2} onChange={(e) => setMessage(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col md={12} className="mt-3">
                        <Form.Group>
                            <input type='file' onChange={(e) => {
                                setMessageFile(e.target.files[0])
                                showResult(e.target.files[0])
                            }
                            } label={st('messagePage.attachment')} />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btnPrimaryGreen" onClick={() => finished()}> {ct('finished')}</Button>
            </Modal.Footer>
        </Modal>
    )
}