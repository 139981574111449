import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import Swal from "sweetalert2";
import { AxiosLite, CallAxios } from "../../../Helpers";
import { sortableContainer, sortableElement, arrayMove, sortableHandle } from 'react-sortable-hoc';
import { TblSwitchToogleButtonDragable } from "../../../Components/TblSwitchToogleButtonDragable";
import { useTranslation } from "react-i18next";


export default function OccupyAutomaticallySettingModal(props) {
    const { t: cl } = useTranslation('calendarPage');
    const { t: ct } = useTranslation('common');



    const [TABLELIST, setTABLELIST] = useState([]);
    const [TablesortList, setTablesortList] = useState([]);
    const [IsStatutoryrestperiods, setIsStatutoryrestperiods] = useState(false);
    const [Statutoryrestperiods, setStatutoryrestperiods] = useState("");
    const [Alwaysconsiderapplications, setAlwaysconsiderapplications] = useState(false);
    const [assignAutomaticallyIgnoreTargetHourLimit, setAssignAutomaticallyIgnoreTargetHourLimit] = useState(false);
    const [ConfirmationModel, setConfirmationModel] = useState(false);
    useEffect(() => {
        setTablesortList(TABLELIST)
    }, [TABLELIST]);

    useEffect(() => {
        getDate()
    }, []);
    const getDate = async () => {
        var response = await AxiosLite("/api/LocationSetting/getPlanningSortandOtherSetting|get");
        if (!!response && response.status === 200) {
            setTABLELIST(response.data.listPlanningSortSetting)
            setTablesortList(response.data.listPlanningSortSetting)
            setAlwaysconsiderapplications(response.data.alwaysConsiderApplication)
            setAssignAutomaticallyIgnoreTargetHourLimit(response.data.assignAutomatically_IgnoreTargetHourLimit)
            setIsStatutoryrestperiods(response.data.statutoryRestPeriods)
            setStatutoryrestperiods(response.data.statutoryRestPeriodsHours)
        }
    }
    const DragHandle = sortableHandle((value) => <td className='py-1'><div className="dataTextCell"><FaBars /> {value.value}</div></td>);
    const SortableItem = sortableElement(({ value, value2, className }) => {
        return <tr className={className}><DragHandle value={value} />{value2}</tr>
    });
    const SortableContainer = sortableContainer(({ children }) => {
        return <tbody>{children}</tbody>;
    });
    const disableRow = async (check, index) => {
        setTablesortList(TablesortList.map((item, ind) => item.id === index ? { ...item, isActive: check } : item));
        var data = {
            Id: index,
            IsActive: check === true ? true : false
        }
        var response = await CallAxios(
            "/api/LocationSetting/updateStatusPlanningSortSetting|post", data
        );
        if (!!response && !!response.data) {
            setTABLELIST(response.data)
        }
    }
    const onSortEnd = async ({ oldIndex, newIndex }) => {
        var odresrlist = reorder(TABLELIST, oldIndex, newIndex)

        if (TablesortList[newIndex].isActive === true) {
            let arr = arrayMove(TablesortList, oldIndex, newIndex);
            setTablesortList(arr)
        } else {
        }

    }
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };
    const submit = async () => {
        if (IsStatutoryrestperiods === true) {
            if (parseInt(Statutoryrestperiods) > -1) {

            } else {
                Swal.fire({
                    icon: 'An error occurred.',
                    title: cl("restPeriod"),
                })
                return
            }
        }
        var data = {
            ListPlanningSortSetting: TablesortList,
            AlwaysConsiderApplication: Alwaysconsiderapplications,
            AssignAutomatically_IgnoreTargetHourLimit: assignAutomaticallyIgnoreTargetHourLimit,
            StatutoryRestPeriods: IsStatutoryrestperiods,
            StatutoryRestPeriodsHours: parseFloat(Statutoryrestperiods)
        }
        var response = await CallAxios("/api/LocationSetting/SavePlanningSortandOtherSetting|post", data);
        if (!!response && response.status === 200) {
            props.onHide(false)
        }
    }
    const changeCancel = (node) => {
        return
    }
    const handlechange = (e) => {
        if (e.currentTarget.name === 'alwaysConsiderApplication') {
            setAlwaysconsiderapplications(!Alwaysconsiderapplications)
        } else if (e.currentTarget.name === 'assignAutomaticallyIgnoreTargetHourLimit') {
            setAssignAutomaticallyIgnoreTargetHourLimit(!assignAutomaticallyIgnoreTargetHourLimit)
        } else if (e.currentTarget.name === 'IsstatutoryRestPeriods') {
            setIsStatutoryrestperiods(!IsStatutoryrestperiods)
        }
    }
    const AssignAutomatically = async () => {
        if (IsStatutoryrestperiods === true) {
            if (parseInt(Statutoryrestperiods) > -1) {

            } else {
                Swal.fire({
                    icon: 'An error occurred.',
                    title: cl("restPeriod"),
                })
                return
            }
        }
        var obj = {
            ListPlanningSortSetting: TablesortList,
            AlwaysConsiderApplication: Alwaysconsiderapplications,
            AssignAutomatically_IgnoreTargetHourLimit: assignAutomaticallyIgnoreTargetHourLimit,
            StatutoryRestPeriods: IsStatutoryrestperiods,
            StatutoryRestPeriodsHours: parseFloat(Statutoryrestperiods)
        }
        var objresponse = await CallAxios("/api/LocationSetting/SavePlanningSortandOtherSetting|post", obj);
        if (!!objresponse && objresponse.status === 200) {
            var data = {
                startDate: props.calendar.current.activeRange.start,
                EndDate: props.calendar.current.activeRange.end
            }
            var response = await CallAxios("/api/Calendar/AssignShiftAutomaticallyHoleWeek|post", data);
            if (!!response && !!response.data) {
                props.GetEmployeeList()
                props.UpdateShiftChildTrigger()
                props.onHide(false)
            }
        }
    }
    return (
        <>
            {
                ConfirmationModel === true ?
                    <Modal show={ConfirmationModel} onHide={(e) => setConfirmationModel(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>{cl("assignAutomatically")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{cl("thisProcedure")}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="d-flex justify-content-between align-items-center w-100">
                                <Button className="btn btnPrimaryGreen" onClick={(e) => { AssignAutomatically(); setConfirmationModel(false) }}>Ok</Button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                    : null
            }
            <Modal show={props.show} onHide={() => props.onHide(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{cl("assignAutomatically")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12} >
                            <div className="ModalSectionHeading">{cl("sortingSettings")}</div>
                            <div className="InfoText">{cl("defineHowPara")}</div>
                        </Col>
                        <Col md={12}>
                            <Table hover responsive className="DataGridTable">
                                <thead>
                                    <tr>
                                        <th>
                                            {ct("name")}
                                        </th>
                                        <th>{ct("actions")}</th>
                                    </tr>
                                </thead>
                                <SortableContainer updateBeforeSortStart={changeCancel} onSortEnd={onSortEnd} useDragHandle>
                                    {TablesortList.map((value, index) =>
                                        <SortableItem disabled={!value.isActive} key={`item-${index}`} index={index} value2={<td className='py-1'><TblSwitchToogleButtonDragable active={value.isActive} checkactive={(e) => disableRow(e, value.id)} /></td>} value={value.label} />
                                    )}
                                </SortableContainer>
                            </Table>
                        </Col>
                        <Col md={12} className="py-3 border-top">
                            <h4>{cl("otherSettings")}</h4>
                            <div className="form-check text-left">
                                <input className="form-check-input " type="checkbox" checked={IsStatutoryrestperiods} onChange={(e) => handlechange(e)} name="IsstatutoryRestPeriods" value="" id="statutoryRestPeriods" />

                                <Form.Group controlId="formBasicCheckbox">
                                    {cl("considerPeriods")}
                                    <input type="text" onChange={(e) => setStatutoryrestperiods(e.target.value)} name="consider_hours_between_bookings" value={Statutoryrestperiods} className="form-control form-control-sm d-inline-block text-center mx-2" style={{ width: "10%", minHeight: "15px" }} disabled={!IsStatutoryrestperiods} />
                                    {cl("hoursShift")}
                                </Form.Group>
                            </div>

                            <Form.Group controlId="formBasicCheckbox">
                                <Form.Check className="text-left" onChange={(e) => handlechange(e)} name={"alwaysConsiderApplication"} checked={Alwaysconsiderapplications}
                                    type="checkbox" label={cl("considerApplications")} />
                            </Form.Group>
                            <Form.Group controlId="formBasicCheckbox">
                                <Form.Check className="text-left" onChange={(e) => handlechange(e)} name={"assignAutomaticallyIgnoreTargetHourLimit"}
                                    type="checkbox" label={cl("assignAutomaticallyIgnoreTarget")} checked={assignAutomaticallyIgnoreTargetHourLimit} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <a href="javascript:;" onClick={(e) => { submit() }} className="ModalFooterLink">{cl("saveAssignment")}</a>
                        <Button className="btn btnPrimaryGreen" onClick={(e) => { setConfirmationModel(true); }}>{cl("assignAutomatically")}</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}