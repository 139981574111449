import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Col, Dropdown, Form, Modal, Row, Table } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import { BsFillGearFill } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import { AxiosLite, CallAxios } from "../../../Helpers";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import DeleteConfirmationModal from "../../../Components/DeleteConfirmationModal";



export default function SandBox(props) {
    const { t: cl } = useTranslation('calendarPage');
    const { t: ct } = useTranslation('common');



    moment.updateLocale('en', {
        week: {
            dow: 1, // Monday is the first day of the week.
        }
    });
    const [selectedOption, setSelectedOption] = useState();
    const appSettings = useSelector(s => s.app.appSettings);
    const [AddNewPeriod, setAddNewPeriod] = useState();
    const [startDate, setStartDate] = useState(moment().startOf('week'));
    const [EndDate, setEndDate] = useState(moment().endOf('week'));
    const [ExpiryDate, setExpiryDate] = useState();
    const [workspaceDD, setWorkspaceDD] = useState([]);
    const [SandBoxList, setSandBoxList] = useState([]);
    const [SelectedWorkspace, setSelectedWorkspace] = useState([]);
    const [SandBoxId, setSandBoxId] = useState("");
    const [ShowDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

    useEffect(() => {
        setStartDate(props.calendar.current.activeRange.start)
        setEndDate(props.calendar.current.activeRange.end)
        onLoad()
    }, []);

    const onLoad = async () => {
        var response = await AxiosLite("/api/LocationSetting/getWorkSpaceDD|get");
        if (!!response && response.data.length > 0) {
            setWorkspaceDD(response.data)
        }
        getdata();
    }

    const getdata = async () => {
        var response = await AxiosLite("/api/Calendar/GetSandBoxList|get");
        if (!!response && response.status === 200) {
            setSandBoxList(response.data)
        }
    }
    const OnchageValue = (e) => {
        setSelectedWorkspace(e.target.value)
    }
    const Delete = async (id) => {
        var response = await CallAxios("/api/Calendar/DeleteSandBox|post", { id: id });
        if (!!response && response.status === 200) {
            setSandBoxId("")
            props.GetEmployeeList()
            props.UpdateShiftChildTrigger()
            getdata();
            setShowDeleteConfirmationModal(false);
        }
    }
    const edit = (obj) => {
        setSandBoxId(obj.id)
        setExpiryDate(!!obj.expireDate ? new Date(obj.expireDate) : null)
        setStartDate(new Date(obj.startDay))
        setEndDate(new Date(obj.endDate))
        setSelectedWorkspace(obj.workAreaids)
        setAddNewPeriod(true)
    }
    const Abortform = () => {
        setSandBoxId("");
        setExpiryDate()
        setStartDate(moment().startOf('week'))
        setEndDate(moment().endOf('week'))
        setSelectedWorkspace([])
        setAddNewPeriod(false)
    }
    const Submit = async () => {
        if (!!ExpiryDate) {
            if (moment(EndDate).isAfter(ExpiryDate)) {
                alert(cl('errorMsg.dateOverlap'))
                return;
            }
        }
        if (moment(EndDate).isBefore(startDate)) {
            Swal.fire({
                icon: "error",
                title: cl("errorMsg.startEndDateDesc"),
            })
            return;
        }
        var data = {
            ID: SandBoxId,
            StartDay: startDate,
            EndDate: EndDate,
            ExpireDate: ExpiryDate,
            WorkAreaids: SelectedWorkspace.length === 0 ? workspaceDD.map(x => x.key) : SelectedWorkspace,
        }
        var response = await CallAxios("/api/Calendar/AddEditSandBox|post", data);
        if (!!response && response.status === 200) {
            if (response.data === "OverLap") {
                toast.error(cl('errorMsg.dateOverlap'))
            } else {
                props.GetEmployeeList()
                props.UpdateShiftChildTrigger()
                getdata();
                setAddNewPeriod(false)
            }
        }
    }

    return (
        <>
            {
                ShowDeleteConfirmationModal &&
                <DeleteConfirmationModal show={ShowDeleteConfirmationModal} onHide={setShowDeleteConfirmationModal}
                    title={"Delete sandbox period"} message={"Are you sure you want to remove this sandbox period?"} deletefn={Delete} objId={SandBoxId} />
            }
            <Modal show={props.show} onHide={() => props.onHide(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{cl("sandboxMode")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12} >
                            <div className="InfoText">{cl("sandboxModePara")}</div>
                        </Col>
                        <Col md={12}>
                            <div className="d-flex justify-content-end py-3">
                                <Button className="btn btnPrimaryGreen" onClick={() => setAddNewPeriod(!AddNewPeriod)}>{cl("addNewPeriod")}</Button>
                            </div>
                        </Col>
                        <Col md={12}>
                            <Table hover responsive className="DataGridTable">
                                <thead>
                                    <tr>
                                        <th>{ct("beginning")}</th>
                                        <th>{ct("end")}</th>
                                        <th>{cl("expiresOn")}</th>
                                        <th>{ct("workingArea")}</th>
                                        <th>{ct("actions")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !!SandBoxList && SandBoxList.length > 0 ?
                                            SandBoxList.map((val, key) => {
                                                return <tr>
                                                    <td>{moment(val.startDay).format("DD/MM/YYYY")}</td>
                                                    <td>{moment(val.endDate).format("DD/MM/YYYY")}</td>
                                                    <td>{!!val.expireDate ? moment(val.expireDate).format("DD/MM/YYYY") : ""}</td>
                                                    <td>{val.workArea.map(item => item).join(', ')}</td>
                                                    <td>
                                                        <a
                                                            className="btnTblAction bgBlue"
                                                            onClick={(e) => edit(val)}>
                                                            <BsFillGearFill />
                                                        </a>
                                                        <a
                                                            className="btnTblAction bgRed"
                                                            onClick={(e) => { setSandBoxId(val.id); setShowDeleteConfirmationModal(true); }}
                                                        >
                                                            <FaTrash />
                                                        </a>
                                                    </td>
                                                </tr>
                                            })
                                            :
                                            <tr style={{ "textAlign": "center" }}><td colSpan={5}>{cl("noTimePeriod")}</td></tr>
                                    }
                                </tbody>
                            </Table>
                        </Col>
                        {
                            AddNewPeriod &&
                            <Col md={12}>
                                <div className="ModalSectionHeading pb-3 border-bottom">{cl("newTimePeriod")}</div>
                                <container fluid>
                                    <Row>
                                        <Col md={4} className="py-3">
                                            <Dropdown >
                                                <Dropdown.Toggle className="GreyBgDropdown" id="dropdown-basic">
                                                    {cl("entireLocation")}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {(appSettings.WorkSpaces || []).map((item, index) =>
                                                        <>
                                                            {
                                                                <Dropdown.Item key={index}>
                                                                    {item.label}
                                                                </Dropdown.Item>
                                                            }
                                                        </>
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <Form.Group controlId="NewPeriodForm.txtBeginning">
                                                <Form.Label>{ct("beginning")}</Form.Label>
                                                <div className="DatePickerContainer"><DatePicker selected={new Date(startDate)} onChange={(date) => setStartDate(date)} className="form-control w-100" /></div>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group controlId="NewPeriodForm.txtend">
                                                <Form.Label>{ct("end")}</Form.Label>
                                                <div className="DatePickerContainer"><DatePicker selected={new Date(EndDate)} onChange={(date) => setEndDate(date)} className="form-control w-100" /></div>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group controlId="NewPeriodForm.txtExpiresOn">
                                                <Form.Label>{cl("expiresOn")}</Form.Label>
                                                <div className="DatePickerContainer"><DatePicker selected={ExpiryDate} onChange={(date) => setExpiryDate(date)} className="form-control w-100" /></div>
                                            </Form.Group>
                                        </Col>

                                        <Col md={12}>
                                            <div className="d-flex justify-content-end">
                                                <Button className="btn btn-secondary m-2" onClick={() => { Abortform() }}>{ct("abort")}</Button>
                                                <Button className="btn btnPrimaryGreen m-2" onClick={() => Submit()}>{ct("finished")}</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </container>
                            </Col>
                        }
                    </Row>
                </Modal.Body>
            </Modal >
        </>
    )
}