import React, { useState } from 'react';
import { Button, Col, Container, Form, Nav, Row, Table } from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import { BsGear } from 'react-icons/bs';
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { CallAxios } from '../../../Helpers';
import { ContentBox, ContentSubHeader } from '../../../Helpers/Global/StyledComponents';
import { useTranslation } from "react-i18next";


function SearchIndex() {
    const { t: st } = useTranslation('simpleData');
    const { t: ct } = useTranslation('common');

    const [SearchFields, setSearchFields] = useState(["Surname", "Location", "WorkingArea"]);
    const [FilterRecords, setFilterRecords] = useState([]);
    const [Keyworld, setKeyworld] = useState("");

    const ChangeSelection = (selected) => {
        setSearchFields(selected);
    }
    const submit = async () => {
        if (!!Keyworld) {
            var response = await CallAxios(
                "/api/Employee/SearchEmployee|post", { keyword: Keyworld }
            );
            if (!!response && response.data) {
                setFilterRecords(response.data)
            }
        }
    }


    const AreaArray = [
        { key: "Surname", label: st('searchPage.tbl.surName') },
        { key: "Location", label: st('searchPage.tbl.location') },
        { key: "Abbreviation", label: st('searchPage.tbl.abbreviation') },
        { key: "staffNumber", label: st('searchPage.tbl.staffNumber') },
        { key: "external", label: st('searchPage.tbl.external') },
        { key: "WorkingArea", label: st('searchPage.tbl.workingAreas') },
        { key: "Filters", label: st('searchPage.tbl.filters') },
        { key: "Email", label: ct('email') },
        { key: "Phonenumber", label: ct('phoneNumber') },
        { key: "note", label: ct('note') },
        { key: "birthday", label: ct('birthday') },
        { key: "startofwork", label: st('searchPage.tbl.commencementWork') },
        { key: "Endofcontract", label: st('searchPage.tbl.endOfContract') },
        { key: "Endofprobationaryperoid", label: st('searchPage.tbl.endProbation') },
    ];


    return (
        <>
            <ContentSubHeader>
            </ContentSubHeader>
            <ContentBox>
                <Container fluid className="mt-2">
                    <Col md={12}>
                        <Row>
                            <Col md={12} className="mb-3 textDesgin">
                                <span className="textdesginMain"> {ct('search')}</span> <br />
                                <span> {st('searchPage.subTitle')}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={2} className="pr-0" >
                                <Form.Control type="text" placeholder={st('searchPage.keyword')} className="textBoxDesgin mb-2" onKeyUp={(e) => { e.keyCode == 13 ? submit() : setKeyworld(e.target.value) }} />
                            </Col>

                            <Col md={2} className="pl-1">
                                <Button variant="primary" className="btnColor d-flex justify-content-start " onClick={() => submit()}> {ct('search')}</Button>
                            </Col>

                            <Col md={8} className="">
                                <Nav className="d-flex justify-content-end btnCustDesgin">
                                    <Nav.Item className="align-self-center">
                                        <div>
                                            <BsGear className="btnCustDesgin" />
                                        </div>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <div className="Multiselect HideSelectAll TblDDList max250px">
                                            <DropdownMultiselect className="Desginbox btnDDList smWidth max250px" handleOnChange={ChangeSelection} selected={["Surname", "Location", "WorkingArea"]} options={AreaArray} buttonClass="btnDDList smWidth " placeholder={st('searchPage.seachColumns')} />
                                        </div>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                        </Row>
                        {
                            !!FilterRecords && FilterRecords.length > 0 ?

                                <Table hover responsive className="DataGridTable">
                                    <thead className='text-left'>

                                        <tr>
                                            {
                                                SearchFields.some(x => x === "Surname") === true ?
                                                    <th>{ct('name')}</th>
                                                    : null
                                            }
                                            {
                                                SearchFields.some(x => x === "Location") === true ?
                                                    <th>{st('searchPage.tbl.location')}</th>
                                                    : null
                                            }
                                            {
                                                SearchFields.some(x => x === "Abbreviation") === true ?
                                                    <th>{st('searchPage.tbl.abbreviation')}</th>
                                                    : null
                                            }
                                            {
                                                SearchFields.some(x => x === "staffNumber") === true ?
                                                    <th>{st('searchPage.tbl.staffNumber')}</th>
                                                    : null
                                            }
                                            {
                                                SearchFields.some(x => x === "external") === true ?
                                                    <th>{st('searchPage.tbl.external')}</th>
                                                    : null
                                            }
                                            {
                                                SearchFields.some(x => x === "WorkingArea") === true ?
                                                    <th>{st('searchPage.tbl.workingAreas')}</th>
                                                    : null
                                            }
                                            {
                                                SearchFields.some(x => x === "Filters") === true ?
                                                    <th>{st('searchPage.tbl.filters')}</th>
                                                    : null
                                            }
                                            {
                                                SearchFields.some(x => x === "Email") === true ?
                                                    <th>{ct('email')}</th>
                                                    : null
                                            }
                                            {
                                                SearchFields.some(x => x === "Phonenumber") === true ?
                                                    <th>{ct('phoneNumber')}</th>
                                                    : null
                                            }
                                            {
                                                SearchFields.some(x => x === "note") === true ?
                                                    <th>{ct('note')}</th>
                                                    : null
                                            }
                                            {
                                                SearchFields.some(x => x === "birthday") === true ?
                                                    <th>{ct('birthday')}</th>
                                                    : null
                                            }
                                            {
                                                SearchFields.some(x => x === "startofwork") === true ?
                                                    <th>{st('searchPage.tbl.commencementWork')}</th>
                                                    : null
                                            }
                                            {
                                                SearchFields.some(x => x === "Endofcontract") === true ?
                                                    <th>{st('searchPage.tbl.endOfContract')}</th>
                                                    : null
                                            }
                                            {
                                                SearchFields.some(x => x === "Endofprobationaryperoid") === true ?
                                                    <th>{st('searchPage.tbl.endProbation')}</th>
                                                    : null
                                            }

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            FilterRecords.map((value, key) => {
                                                return <tr className='text-left'>
                                                    {
                                                        SearchFields.some(x => x === "Surname") === true ?
                                                            <td>
                                                                <Highlighter
                                                                    highlightClassName="yellowmark"
                                                                    searchWords={[Keyworld]}
                                                                    autoEscape={true}
                                                                    textToHighlight={value.surname}
                                                                />
                                                            </td>
                                                            : null
                                                    }
                                                    {
                                                        SearchFields.some(x => x === "Location") === true ?
                                                            <th className='text-nowrap'>
                                                                <Highlighter
                                                                    highlightClassName="yellowmark"
                                                                    searchWords={[Keyworld]}
                                                                    autoEscape={true}
                                                                    textToHighlight={value.location}
                                                                />
                                                            </th>
                                                            : null
                                                    }
                                                    {
                                                        SearchFields.some(x => x === "Abbreviation") === true ?
                                                            <th>
                                                                <Highlighter
                                                                    highlightClassName="yellowmark"
                                                                    searchWords={[Keyworld]}
                                                                    autoEscape={true}
                                                                    textToHighlight={value.abbreviation}
                                                                />
                                                            </th>
                                                            : null
                                                    }
                                                    {
                                                        SearchFields.some(x => x === "staffNumber") === true ?
                                                            <th>
                                                                <Highlighter
                                                                    highlightClassName="yellowmark"
                                                                    searchWords={[Keyworld]}
                                                                    autoEscape={true}
                                                                    textToHighlight={value.staffNumber}
                                                                />
                                                            </th>
                                                            : null
                                                    }
                                                    {
                                                        SearchFields.some(x => x === "external") === true ?
                                                            <th>
                                                                <Highlighter
                                                                    highlightClassName="yellowmark"
                                                                    searchWords={[Keyworld]}
                                                                    autoEscape={true}
                                                                    textToHighlight={value.external}
                                                                />
                                                            </th>
                                                            : null
                                                    }
                                                    {
                                                        SearchFields.some(x => x === "WorkingArea") === true ?
                                                            <th>
                                                                <Highlighter
                                                                    highlightClassName="yellowmark"
                                                                    searchWords={[Keyworld]}
                                                                    autoEscape={true}
                                                                    textToHighlight={value.workingArea}
                                                                />
                                                            </th>
                                                            : null
                                                    }
                                                    {
                                                        SearchFields.some(x => x === "Filters") === true ?
                                                            <th>
                                                                <Highlighter
                                                                    highlightClassName="yellowmark"
                                                                    searchWords={[Keyworld]}
                                                                    autoEscape={true}
                                                                    textToHighlight={value.filters}
                                                                />
                                                            </th>
                                                            : null
                                                    }
                                                    {
                                                        SearchFields.some(x => x === "Email") === true ?
                                                            <th className='text-nowrap'>
                                                                <Highlighter
                                                                    highlightClassName="yellowmark"
                                                                    searchWords={[Keyworld]}
                                                                    autoEscape={true}
                                                                    textToHighlight={value.email}
                                                                />
                                                            </th>
                                                            : null
                                                    }
                                                    {
                                                        SearchFields.some(x => x === "Phonenumber") === true ?
                                                            <th>
                                                                <Highlighter
                                                                    highlightClassName="yellowmark"
                                                                    searchWords={[Keyworld]}
                                                                    autoEscape={true}
                                                                    textToHighlight={value.phonenumber}
                                                                />
                                                            </th>
                                                            : null
                                                    }
                                                    {
                                                        SearchFields.some(x => x === "note") === true ?
                                                            <th>
                                                                <Highlighter
                                                                    highlightClassName="yellowmark"
                                                                    searchWords={[Keyworld]}
                                                                    autoEscape={true}
                                                                    textToHighlight={value.note}
                                                                />
                                                            </th>
                                                            : null
                                                    }
                                                    {
                                                        SearchFields.some(x => x === "birthday") === true ?
                                                            <th>
                                                                <Highlighter
                                                                    highlightClassName="yellowmark"
                                                                    searchWords={[Keyworld]}
                                                                    autoEscape={true}
                                                                    textToHighlight={value.birthday}
                                                                />
                                                            </th>
                                                            : null
                                                    }
                                                    {
                                                        SearchFields.some(x => x === "startofwork") === true ?
                                                            <th>
                                                                <Highlighter
                                                                    highlightClassName="yellowmark"
                                                                    searchWords={[Keyworld]}
                                                                    autoEscape={true}
                                                                    textToHighlight={value.startofwork}
                                                                />
                                                            </th>
                                                            : null
                                                    }
                                                    {
                                                        SearchFields.some(x => x === "Endofcontract") === true ?
                                                            <th>
                                                                <Highlighter
                                                                    highlightClassName="yellowmark"
                                                                    searchWords={[Keyworld]}
                                                                    autoEscape={true}
                                                                    textToHighlight={value.endofcontract}
                                                                />
                                                            </th>
                                                            : null
                                                    }
                                                    {
                                                        SearchFields.some(x => x === "Endofprobationaryperoid") === true ?
                                                            <th>
                                                                <Highlighter
                                                                    highlightClassName="yellowmark"
                                                                    searchWords={[Keyworld]}
                                                                    autoEscape={true}
                                                                    textToHighlight={value.endofprobationaryperoid}
                                                                />
                                                            </th>
                                                            : null
                                                    }
                                                </tr>
                                            })
                                        }
                                    </tbody>

                                </Table>
                                : null
                        }
                    </Col>
                </Container>
            </ContentBox>

        </>
    );
}

export default SearchIndex;