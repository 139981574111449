import { useFormik } from "formik";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import DatePicker from 'react-datepicker';
import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap';
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { Gear, Remove } from "../../../Components/ActionBtns";
import CollapseableDiv from "../../../Components/CollapseCard";
import { AxiosLite, CallAxios } from "../../../Helpers";
import { TableWrapper } from "../../../Helpers/Global/StyledComponents";
import { useTranslation } from "react-i18next";
import DeleteConfirmationModal from "../../../Components/DeleteConfirmationModal";


function HolidayBan() {
    const { t: Lst } = useTranslation('locationSetting');
    const { t: ct } = useTranslation('common');


    const [BanModal, setBanModal] = useState(false);
    const [VacationIdForEdit, setVacationIdForEdit] = useState("");
    const [VacationList, setVacationList] = useState([]);
    const [ShowDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

    useEffect(() => {
        getData()
    }, []);

    const getData = async () => {
        var response = await AxiosLite(
            "/api/LocationSetting/HolidayBansList|get"
        );
        if (!!response && response.status === 200) {
            setVacationList(response.data)
        }

    }
    const onDeleteSubmit = async (id) => {
        var response = await CallAxios("/api/LocationSetting/deletevocationBan|post", { id: id })
        if (!!response && response.status === 200) {
            setVacationList(response.data)
            setShowDeleteConfirmationModal(false)
        }
    }
    const EditModel = (id) => {
        setVacationIdForEdit(id)
        setBanModal(true)
    }


    return (
        <>
            {
                ShowDeleteConfirmationModal &&
                <DeleteConfirmationModal show={ShowDeleteConfirmationModal} onHide={setShowDeleteConfirmationModal}
                    title={Lst('absenceTab.holidayBanAccor.deleteMdl.title')} message={Lst('absenceTab.holidayBanAccor.deleteMdl.desc')} deletefn={onDeleteSubmit} objId={VacationIdForEdit} />
            }
            <CollapseableDiv title={Lst('absenceTab.holidayBanAccor.holidayBans')}>
                {
                    BanModal === true ?
                        <VacationBanModal show={BanModal} setshow={setBanModal} setVacationList={setVacationList} VacationIdForEdit={VacationIdForEdit} />
                        : null
                }
                <Row>
                    <Col md={12}>
                        <div className="TabFormContainerSection">
                            <div className='d-flex mb-4 justify-content-between align-items-center'>
                                <p className="text-left w-75">
                                    {Lst('absenceTab.holidayBanAccor.desc')}.
                                </p>
                                <button type="button" className="btn btnPrimaryGreen btn-primary" onClick={(e) => { setVacationIdForEdit(""); setBanModal(true) }}> {Lst('absenceTab.holidayBanAccor.newVacationBan')}</button>
                            </div>
                            <TableWrapper>


                                <Table striped bordered responsive className="">
                                    <thead>
                                        <tr className='firsthead'>
                                            <th> {ct('title')}</th>
                                            <th> {Lst('absenceTab.holidayBanAccor.tbl.startDate')}</th>
                                            <th> {Lst('absenceTab.holidayBanAccor.tbl.endDate')}</th>
                                            <th> {Lst('absenceTab.holidayBanAccor.tbl.maxNumberUsers')}</th>
                                            <th> {Lst('absenceTab.holidayBanAccor.tbl.workingArea')}</th>
                                            <th> {Lst('absenceTab.holidayBanAccor.tbl.absenceTypes')}</th>
                                            <th style={{ width: "110px" }}> {ct('actions')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !!VacationList && VacationList.length > 0 ? VacationList.map((value, key) => {
                                                return <tr>
                                                    <td><div className="white-space-nowrap">{value.surname}</div></td>
                                                    <td>{moment(value.beginning).format('MM/DD/YYYY')}</td>
                                                    <td>{moment(value.end).format('MM/DD/YYYY')}</td>
                                                    <td>{value.maxAbsence}</td>
                                                    <td>
                                                        <div className="white-space-nowrap">
                                                            {value.workspaces.length > 0 ? value.workspaces.map((val, key) => {
                                                                return val + ", "
                                                            }) : null}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {value.absence_types.length > 0 ? value.absence_types.map((val, key) => {
                                                            return val + ", "
                                                        }) : null}
                                                    </td>
                                                    <td>
                                                        <Gear onClick={(e) => EditModel(value.id)} />
                                                        <Remove onClick={(e) => { setVacationIdForEdit(value.id); setShowDeleteConfirmationModal(true); }} />

                                                    </td>
                                                </tr>
                                            }) :
                                                <tr>
                                                    <td colSpan={8} className="text-center"> {Lst('absenceTab.holidayBanAccor.noTblRecord')}.</td>
                                                </tr>
                                        }
                                    </tbody>
                                </Table>
                            </TableWrapper>

                        </div>
                    </Col>
                </Row>
            </CollapseableDiv>
        </>
    );
}

export default HolidayBan;

function VacationBanModal(props) {
    const { t: Lst } = useTranslation('locationSetting');
    const { t: ct } = useTranslation('common');


    const [workspaceDD, setWorkspaceDD] = useState([]);
    const [AbsenceTypeDD, setAbsenceTypeDD] = useState([]);
    const [SelectedWorkSpaceids, setSelectedWorkSpaceids] = useState([]);
    const [SelectedAbsenceTypeIds, setSelectedAbsenceTypeIds] = useState([]);
    const appsettings = useSelector(x => x.app.appSettings);
    const formik = useFormik({
        initialValues: {
            surname: "",
            beginning: new Date(),
            end: new Date(),
            MaxAbsence: 0,
        },
        validationSchema: Yup.object().shape({
            surname: Yup.string().required(Lst('absenceTab.holidayBanAccor.newVacationMdl.errorMsg.name')),
            beginning: Yup.date().typeError(Lst('absenceTab.holidayBanAccor.newVacationMdl.errorMsg.noValidDate')).required(Lst('absenceTab.holidayBanAccor.newVacationMdl.errorMsg.beginNotBlank')),
            end: Yup.date().required(Lst('absenceTab.holidayBanAccor.newVacationMdl.errorMsg.beginNotBlank')).typeError(Lst('absenceTab.holidayBanAccor.newVacationMdl.errorMsg.noValidDate')).test('end', Lst('absenceTab.holidayBanAccor.newVacationMdl.errorMsg.end'), function (value) {
                if (moment(formik.values.beginning).startOf("day").isSameOrBefore(moment(value).startOf("day"))) {
                    return true;
                } else {
                    return false;
                }
            }),
            MaxAbsence: Yup.number().required(Lst('absenceTab.holidayBanAccor.newVacationMdl.errorMsg.max')).min(0, Lst('absenceTab.holidayBanAccor.newVacationMdl.errorMsg.maxGreater'))
        }),
        onSubmit: values => {
            submit(values)
        },
    });

    const editdata = async () => {

        var response = await CallAxios(
            "/api/LocationSetting/getHolidayBans|post", { id: props.VacationIdForEdit }
        );
        if (!!response && !!response.data) {
            formik.setFieldValue("beginning", new Date(response.data.beginning))
            formik.setFieldValue("end", new Date(response.data.end))
            formik.setFieldValue("surname", response.data.surname)
            formik.setFieldValue("MaxAbsence", response.data.maxAbsence)

            setSelectedWorkSpaceids(response.data.workspaces)
            setSelectedAbsenceTypeIds(response.data.absence_types)
        }
    }

    useEffect(() => {
        onLoad()
        if (!!props.VacationIdForEdit) {
            editdata()
        }
    }, []);

    const onLoad = async () => {
        setWorkspaceDD(appsettings.WorkSpaces)

        var res = await AxiosLite(
            "/api/LocationSetting/getAbsenceTypeDD|get"
        );

        if (!!res && res.status === 200) {
            setAbsenceTypeDD(res.data)
        }
    }

    const submit = async (values) => {
        var data = {
            Id: props.VacationIdForEdit,
            beginning: values.beginning,
            end: values.end,
            surname: values.surname,
            MaxAbsence: parseInt(values.MaxAbsence),
            workspaces: SelectedWorkSpaceids,
            absence_types: SelectedAbsenceTypeIds
        };
        var response = await CallAxios(
            "/api/LocationSetting/AddeditHolidayBans|post", data
        );
        if (!!response && response.status === 200) {
            props.setVacationList(response.data)
            props.setshow(false)
        }
    };

    return (
        <Modal show={props.show} className="" onHide={() => props.setshow(false)}>
            <Modal.Header closeButton className="">
                <Modal.Title>{!!props.VacationIdForEdit ? <>{ct('edit')}</> : <>{ct('new')}</>} {Lst('absenceTab.holidayBanAccor.newVacationMdl.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Row>
                    <Col md={4}>
                        <Form.Group className="mb-3">
                            <label className="fw-bold mb-2"> {ct('name')}</label>
                            <Form.Control type="text" onChange={formik.handleChange} maxLength={150} value={formik.values.surname} name="surname" className="ThickBorder my-0 pr40" />
                            {formik.touched.surname && formik.errors.surname ? (
                                <span className="error text-danger">{formik.errors.surname}</span>
                            ) : null}
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-3">
                            <label className="fw-bold mb-2"> {ct('beginning')}</label>
                            <DatePicker className="ThickBorder form-control my-0 pr40 w-100" selected={formik.values.beginning} name="beginning" onChange={(date) => formik.setFieldValue("beginning", date)} />
                            {formik.touched.beginning && formik.errors.beginning ? (
                                <span className="error text-danger">{formik.errors.beginning}</span>
                            ) : null}
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-3">
                            <label className="fw-bold mb-2"> {ct('end')}</label>
                            <DatePicker className="ThickBorder form-control my-0 pr40 w-100" selected={formik.values.end} name="end" onChange={(date) => formik.setFieldValue("end", date)} />
                            {formik.touched.end && formik.errors.end ? (
                                <span className="error text-danger">{formik.errors.end}</span>
                            ) : null}
                        </Form.Group>
                    </Col>
                    <Col md={8}>
                        <Form.Group className="mb-3">
                            <label className="fw-bold mb-2"> {Lst('absenceTab.holidayBanAccor.newVacationMdl.maximumLabel')}</label>
                            <Form.Control type="number" value={formik.values.MaxAbsence} name="MaxAbsence" className="ThickBorder my-0 pr40" onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                if (value >= 0 || e.target.value === "") {
                                    formik.handleChange(e);
                                }
                            }}
                            />
                            {formik.touched.MaxAbsence && formik.errors.MaxAbsence ? (
                                <span className="error text-danger">{formik.errors.MaxAbsence}</span>
                            ) : null}
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <label className="fw-bold mb-2"> {Lst('absenceTab.holidayBanAccor.newVacationMdl.scopeVacationLabel')}</label>
                            {!!workspaceDD && workspaceDD.length > 0 ?
                                <DropdownMultiselect selected={SelectedWorkSpaceids} handleOnChange={(selected) => setSelectedWorkSpaceids(selected)} selectDeselectLabel="" optionKey="value" options={workspaceDD} placeholder={Lst('absenceTab.holidayBanAccor.newVacationMdl.workspacesPlace')} name='workspaceDD' /> : null}

                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <label className="fw-bold mb-2"> {Lst('absenceTab.holidayBanAccor.tbl.absenceTypes')} </label>
                            {!!AbsenceTypeDD && AbsenceTypeDD.length > 0 ?
                                <DropdownMultiselect selected={SelectedAbsenceTypeIds} handleOnChange={(selected) => setSelectedAbsenceTypeIds(selected)} selectDeselectLabel="" options={AbsenceTypeDD} placeholder={Lst('absenceTab.holidayBanAccor.newVacationMdl.absencePlace')} name='AbsenceTypeDD' /> : null}

                        </Form.Group>
                    </Col>

                </Row>


            </Modal.Body>
            <Modal.Footer>
                <button className="btn btnPrimaryGreen text-white" onClick={formik.handleSubmit}>{ct('import')}</button>

            </Modal.Footer>
        </Modal>
    );
}
