import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import FileBase64 from 'react-file-base64';
import { FaUpload } from "react-icons/fa";
import Swal from 'sweetalert2';
import TreeViewProfile from '../../Components/TreeViewProfile';
import { AxiosLite, CallAxios } from "../../Helpers";
import { config } from '../../Helpers/Config';
import { useTranslation } from "react-i18next";


export default function Documents() {
    const { t } = useTranslation('myProfile');


    const [data, setData] = useState([]);
    const [selectedNode, setSelectedNode] = useState(null);
    const [ShowUploadFileModal, setShowUploadFileModal] = useState(false);
    const [ShowEditFileNameModal, setShowEditFileNameModal] = useState(false);

    useEffect(() => {
        getData()
    }, []);

    const getData = async () => {
        setData([]);
        var response = await AxiosLite("/api/Documents/GetEmployeeFolders|get")
        if (!!response) {
            setData(response.data);
        }
    }

    const handleTreeActions = async (e, NodeData) => {
        if (NodeData.action === "Upload") {
            setSelectedNode(NodeData)
            setShowUploadFileModal(true)
        }
        else if (NodeData.action === "Edit") {
            var data = {
                DocumentId: 0,
                FolderId: 0,
                name: 0
            }
            setSelectedNode(NodeData)
            setShowEditFileNameModal(true)
        }
        else if (NodeData.action === "View") {
            window.open(config.S3BucketURL + NodeData.Node.bucketKey)
        }
        else if (NodeData.action === "Delete") {
            var data = {
                DocumentId: NodeData.Node.id,
                FolderId: NodeData.Node.parentId,
            }
            var response = await CallAxios("/api/Documents/DeleteDocument|post", data)
            if (!!response) {
                getData();
            }
        }
        else if (NodeData.action === "Movetoarchive") {
            var parentNode = NodeData.list.find(x => x.label === NodeData.Node.parentFolderName)
            if (parentNode.children.length > 0) {
                var obj = {
                    DocumentId: NodeData.Node.id,
                    FolderId: parentNode.children[0].id,
                }
                var response = await CallAxios("/api/Documents/MoveFile|post", obj)
                if (!!response) {
                    getData();
                }
            }
        }
        else if (NodeData.action === "MoveBackFromArchive") {
            var obj = {
                DocumentId: NodeData.Node.id,
                FolderId: undefined,
            }
            var parentNode = NodeData.list.map((val, i) => {
                if (val.children.length > 0 && val.children.find(c => c.id === NodeData.Node.parentId)) {
                    obj.FolderId = val.children.find(c => c.id === NodeData.Node.parentId).parentId
                }
            })
            if (obj.FolderId) {
                var response = await CallAxios("/api/Documents/MoveFile|post", obj)
                if (!!response) {
                    getData();
                }
            }
        }
    }

    return <>
        <div className="DetailInfoWrapper">
            <div className='mb-3 d-flex align-items-center justify-content-between'>
                <h4 className='h5 fw-bold mb-0'> {t('documents')}</h4>
            </div>
            {
                data.length > 0 &&
                <TreeViewProfile jsonData={data} handleTreeActions={handleTreeActions} />
            }
        </div>

        {
            ShowUploadFileModal === true ?
                <UploadDocumentModal show={ShowUploadFileModal} onHide={setShowUploadFileModal} getData={getData} selectedNode={selectedNode} setSelectedNode={setSelectedNode} />
                : null
        }
        {
            ShowEditFileNameModal === true ?
                <EditDocumentModal show={ShowEditFileNameModal} onHide={setShowEditFileNameModal} getData={getData} selectedNode={selectedNode} setSelectedNode={setSelectedNode} />
                : null
        }

    </>
}

function EditDocumentModal(props) {
    const [FileName, setFileName] = useState("");
    useEffect(() => {
        if (!!props.selectedNode && props.selectedNode.action === "Edit") {
            setFileName(props.selectedNode.Node.label)
        }
    }, []);

    const Save = async () => {
        if (!!FileName) {
            var obj = {
                DocumentId: props.selectedNode.Node.id,
                FolderId: props.selectedNode.Node.parentId,
                name: FileName,
            }
            var response = await CallAxios("/api/Documents/EditDocumentName|post", obj)
            if (response.data === true) {
                props.getData();
                props.setSelectedNode(null);
                props.onHide(false)
            }
            else {
                Swal.fire({
                    icon: 'info',
                    text: response.data
                })
            }
        }
    }
    return (
        <Modal show={props.show} onHide={() => { props.setSelectedNode(null); props.onHide(false) }}>
            <Modal.Header closeButton>
                <Modal.Title>Edit File Name</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="">
                    <Form.Label>File Name</Form.Label>
                    <Form.Control type="text" value={FileName} onChange={(e) => setFileName(e.target.value)} />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { props.setSelectedNode(null); props.onHide(false) }}>
                    Close
                </Button>
                <Button variant="primary" onClick={() => Save()}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

function UploadDocumentModal(props) {
    const fileInput = useRef();
    const [FileName, setFileName] = useState("");
    const [File, setFile] = useState("");

    const getFiles = (files) => {
        setFileName(files.name);
        setFile(files.file)
    }
    const Save = async () => {
        var data = {
            FolderId: props.selectedNode.Node.id,
            file: File
        }
        var form_data = new FormData();
        Object.keys(data).forEach((key, index) => {
            if (typeof (data[key]) === "object" && !!data[key] && data[key].length > 0) {
                for (var i = 0; i < data[key].length; i++) {
                    form_data.append(key + [], data[key][i]);
                }
            } else if (data[key]) {
                form_data.append(key, data[key]);
            }
        })

        var response = await CallAxios("/api/Documents/UploadDocument|post", form_data)
        if (!!response && response.status === 200) {
            if (response.data === true) {
                props.getData();
                props.setSelectedNode(null);
                props.onHide(false)
            }
            else {
                Swal.fire({
                    icon: 'info',
                    text: response.data
                })
            }
        }
    }
    return (
        <>
            <Modal show={props.show} onHide={() => { props.setSelectedNode(null); props.onHide(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <a className="btnUpload position-relative ">
                                <FaUpload className="icon" /> <span className="mx-2">{!!FileName ? FileName : "Upload Document"}</span>
                                <FileBase64
                                    hidden
                                    id='uploadNoteFile'
                                    ref={fileInput}
                                    multiple={false}
                                    onDone={getFiles}
                                />
                            </a>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { props.setSelectedNode(null); props.onHide(false) }}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => Save()}>
                        Done
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}