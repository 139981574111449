import { useFormik } from 'formik';
import moment from 'moment';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';
import * as Yup from "yup";
import { CallAxios } from '../Helpers';
import { useTranslation } from "react-i18next";

export default function AddEditVocationQuotaModal(props) {
    const { t: ept } = useTranslation('employeePage');
    const { t: ct } = useTranslation('common');

    const formik = useFormik({
        initialValues: {
            name: "",
            beginning: moment().startOf('year')._d,
            end: moment().endOf('year')._d,
            expires_after_expiry: true,
            holiday: 0.0,
            transfer_remaining_entitlement_to_new_quota: false,
            expireDate: moment().startOf('year').endOf('month').add(2, 'months').add(1, 'years')._d
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(ept('vocationQuotaMdl.errorMsg.title')),
            holiday: Yup.number().required(ept('vocationQuotaMdl.errorMsg.holiday')).moreThan(0, ept('vocationQuotaMdl.errorMsg.holiday'))
        }),
        onSubmit: values => {
            Submit(values)
        },
    });
    const Submit = async (values) => {
        var data = {
            Id: props.id,
            EmployeeId: localStorage.getItem("selectedEmpId"),
            Name: values.name,
            BeginingTime: !!values.beginning ? values.beginning : null,
            EndTime: !!values.end ? values.end : null,
            IsExpires: values.expires_after_expiry,
            Holidays: parseFloat(values.holiday),
            IsTransfer: values.transfer_remaining_entitlement_to_new_quota,
            Transfer_Expiration_Date: !!values.expireDate ? values.expireDate : null,
        }
        var response = await CallAxios("/api/VacationQuota/AddEditVacationQuota|post", data);
        if (!!response && response.status === 200) {
            props.getEmployeeList()
            props.onHide(false)
        }
    }
    return (
        <>
            <Modal size='lg' show={props.show} onHide={() => props.onHide(false)}>
                <Modal.Header closeVariant='red' closeButton>
                    <Modal.Title>{!!props.vacationQuotaId ? <>{ct('edit')}</> : <>{ct('new')}</>} {ept('vocationQuotaMdl.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12} >
                            <Form.Group controlId="NewShiftForm.txtDay">
                                <Form.Label>{ct('title')}</Form.Label>
                                <Form.Control type="text" name="name" onChange={formik.handleChange} value={formik.values.name} placeholder={ept('vocationQuotaMdl.egAnnualVacation')} />
                                {formik.touched.name && formik.errors.name ? (
                                    <span className="error text-danger text-left">{formik.errors.name}</span>
                                ) : null}                            </Form.Group>
                        </Col>
                        <Col md={6} className="mt-1">
                            <Form.Group controlId="NewPeriodForm.txtBeginning">
                                <Form.Label>{ct('beginning')}</Form.Label>
                                <DatePicker className="form-control" name="beginning" onChange={(date) => formik.setFieldValue("beginning", date)} selected={!!formik.values.beginning ? formik.values.beginning : new Date()} />
                            </Form.Group>
                        </Col>
                        <Col md={6} className="mt-1">
                            <Form.Group controlId="NewPeriodForm.txtend">
                                <Form.Label>{ct('end')}</Form.Label>
                                <DatePicker className="form-control" name="end" onChange={(date) => formik.setFieldValue("end", date)} selected={!!formik.values.end ? formik.values.end : new Date()} />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group controlId="NewVacationForm.txtExpiry">
                                <Form.Check inline name="expires_after_expiry" onChange={() => formik.setFieldValue("expires_after_expiry", !formik.values.expires_after_expiry)} checked={formik.values.expires_after_expiry} label={ept('vocationQuotaMdl.expiresLabel')} className="boldlabeltext" type="checkbox" id="CheckExpiry" />
                            </Form.Group>
                        </Col>
                        <Col md={12} >
                            <Form.Group controlId="NewShiftForm.txtDay">
                                <Form.Label>{ept('vocationQuotaMdl.holiday')}</Form.Label>
                                <Form.Control type="number" name="holiday" onChange={formik.handleChange} value={formik.values.holiday} />
                                {formik.touched.holiday && formik.errors.holiday ? (
                                    <span className="error text-danger text-left">{formik.errors.holiday}</span>
                                ) : null}
                            </Form.Group>
                        </Col>
                        {formik.values.expires_after_expiry === true ?
                            <>
                                <Col md={12} className='mt-2'>
                                    <Form.Group controlId="NewVacationForm.txtTransferremaining">

                                        <Form.Check name="transfer_remaining_entitlement_to_new_quota" onChange={() => formik.setFieldValue("transfer_remaining_entitlement_to_new_quota", !formik.values.transfer_remaining_entitlement_to_new_quota)} checked={formik.values.transfer_remaining_entitlement_to_new_quota} inline label={ept('vocationQuotaMdl.transferNewQuotaLabel')} type="checkbox" id="CheckTransferremaining" />
                                    </Form.Group>
                                </Col>
                                <Col md={6} className='mt-1'>
                                    <Form.Group controlId="NewShiftForm.txtCarryoverExpirationDate">
                                        <Form.Label> {ept('vocationQuotaMdl.transferExpireDate')}</Form.Label>
                                        <DatePicker className="form-control" name="expireDate" onChange={(date) => formik.setFieldValue("expireDate", date)} selected={formik.values.expireDate} disabled={!formik.values.transfer_remaining_entitlement_to_new_quota} />

                                        {/* <Form.Control type="text" value={formik.values.expireDate} disabled={epireVacation}  /> */}
                                    </Form.Group>
                                </Col>
                            </>
                            : null}
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={(e) => formik.handleSubmit()}>{ct('done')}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
