





export class SignalrHelper {
    static _roleHubConnection = undefined;

    static get RoleHubConnection() {
        return this._roleHubConnection;
    }
    static set RoleHubConnection(value) {
        this._roleHubConnection = value;
    }

}
