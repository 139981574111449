import React from 'react';
import { TfiBarChart } from "react-icons/tfi";
import { HiOutlineCog } from "react-icons/hi";
import { BsChevronDoubleLeft, BsChevronDoubleRight, BsClock, BsEnvelope } from "react-icons/bs";
import { FaRegBell, FaRegCalendarTimes } from "react-icons/fa";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { AvatarBox, Badge, CollapseMenu, DisplayBetween, HeaderLeftLabel, HeaderMenu, HeaderMenuItem, IconWrapper, RotateIcon } from '../../Helpers/Global/StyledComponents';
import { NavLink, useNavigate } from 'react-router-dom';
import { SignInManager } from '../../Helpers/Authentication/SignInManager';
import { CallAxios } from '../../Helpers';
import { Dropdown, DropdownButton, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useState } from 'react';
import { useEffect } from 'react';
import { Constants } from '../../Helpers/Contents';
import { setCurrentView } from '../../Store/slices/AppSlice';
import { SwitchLocation } from '../../Helpers/MasterData';
import { useTranslation } from "react-i18next";




function MainHeader(props) {
    const { t: ht } = useTranslation('header');


    const { isCollapse, setIsCollapse } = props;

    const user = useSelector(s => s.app.user);
    const currentView = useSelector(s => s.app.currentView);
    const appSettings = useSelector(s => s.app.appSettings);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [locationList, setlocationList] = useState([]);
    const [currentlocation, setcurrentlocation] = useState("");
    useEffect(() => {
        if (!!user.LoginUserId) {
            getData()
        }
    }, [user.LoginUserId]);
    const getData = async () => {
        var response = await CallAxios('/api/Locations/GetEmplocationDD|post', { id: user.LoginUserId.toString() });
        if (response) {
            setlocationList(response?.data)
            setcurrentlocation(response?.data?.find(x => x.value == user.LocationId))
        }
    }

    const SwithView = () => {
        var view = currentView;
        dispatch(
            setCurrentView(currentView === Constants.layoutType.Admin ? Constants.layoutType.Employee : Constants.layoutType.Admin)
        );

        if (view === Constants.layoutType.Employee) {
            window.location.href = `/companies/${user.CompanyId}/calendar`
        } else {
            if (appSettings.employeeAccess.canSeeCalender === true) {
                window.location.href = `/companies/${user.CompanyId}/calendar`
            }
            else if (appSettings.employeeAccess.canSeetimetracking === true) {
                window.location.href = `/companies/${user.CompanyId}/timetracking`
            }
            else if (appSettings.employeeAccess.canSeeabsences === true) {
                window.location.href = `/companies/${user.CompanyId}/absences`
            }
            else {
                window.location.href = `/companies/${user.CompanyId}/users/${user.LoginUserId}/master_data`
            }
        }
    }
    return (
        <>
            <DisplayBetween>
                <HeaderLeftLabel>
                    <CollapseMenu className='me-3' onClick={() => setIsCollapse(!isCollapse)}>
                        {isCollapse ?
                            <BsChevronDoubleRight size={20} />
                            :
                            <BsChevronDoubleLeft size={20} />
                        }

                    </CollapseMenu>

                    {ht('mainHeader.headerTitle')}
                </HeaderLeftLabel>
                <HeaderMenu>
                    {
                        currentView === Constants.layoutType.Employee &&
                        <>
                            <HeaderMenuItem>
                                <NavLink to={`/companies/${user.CompanyId}/users/${user.LoginUserId}/master_data`}>
                                    <span className="creatingBox"><BsClock className="iconsAlign" /> {user.CurrentBalance}  h</span>
                                </NavLink>
                            </HeaderMenuItem>
                            <HeaderMenuItem>
                                <NavLink to={`/companies/${user.CompanyId}/absences`}>
                                    <span className="creatingBox"><FaRegCalendarTimes />&nbsp;{user.CurrentVocationBalance}  {ht('mainHeader.days')}</span>
                                </NavLink>
                            </HeaderMenuItem>
                        </>
                    }
                    {
                        user.roles.some(x => x === Constants.roles.AccountAdmin || x === Constants.roles.WorkspaceAdmin) ?
                            <HeaderMenuItem>
                                {/* <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={<Tooltip></Tooltip>}> */}
                                <NavLink data-tooltip-id="my-tooltip" data-tooltip-place="left" data-tooltip-content={currentView === Constants.layoutType.Admin ? ht('mainHeader.employeeViewTooltip') : ht('mainHeader.adminViewTooltip')} to="#" className="btnSwitch btnEmployeeSwitch"
                                    onClick={() => SwithView()}>
                                    {currentView === Constants.layoutType.Admin ? <>{ht('mainHeader.admin')}</> : <>{ht('mainHeader.employee')}</>}
                                </NavLink>
                                {/* </OverlayTrigger> */}
                            </HeaderMenuItem>
                            : null
                    }
                    {
                        currentView === Constants.layoutType.Admin &&
                        <HeaderMenuItem>
                            <NavLink to={`/companies/${user.CompanyId}/reports`}>
                                <TfiBarChart size={25} color='#9fa8b0' />
                            </NavLink>
                        </HeaderMenuItem>
                    }
                    {
                        ((currentView === Constants.layoutType.Employee && appSettings.employeeAccess.canSeeMessages === true) || currentView === Constants.layoutType.Admin) &&
                        <HeaderMenuItem>
                            <NavLink to={`/companies/${user.CompanyId}/messages`}>
                                <IconBadge Message={true} badgeCount={user.messageCounter} />
                            </NavLink>
                        </HeaderMenuItem>
                    }
                    <HeaderMenuItem>
                        <NavDropdown
                            id="nav-dropdown-dark-example"
                            title={<UserAvatar Image="" Username={user.empName} />}
                        >
                            <NavDropdown.Item className='dropdown-item disabled text-center'>{user.LocationName}</NavDropdown.Item>
                            {
                                locationList.length > 1 &&
                                <>
                                    <LocationDropDown locationList={locationList} currentlocation={currentlocation} />
                                </>
                            }
                            <NavDropdown.Divider />
                            <NavLink className='dropdown-item' to={`/companies/${user.CompanyId}/users/${user.LoginUserId}/master_data`}> {ht('mainHeader.myProfile')}</NavLink>
                            {/* <NavLink className='dropdown-item' to={`/companies/${user.CompanyId}/users/${user.LoginUserId}/tickets`}>My Tickets</NavLink> */}
                            <NavDropdown.Divider />
                            {
                                currentView === Constants.layoutType.Admin &&
                                <NavLink className='dropdown-item' to="locationsettings/master_data"> {ht('mainHeader.locationSettings')}</NavLink>
                            }
                            {
                                user.roles.some(x => x === Constants.roles.AccountAdmin) &&
                                <NavLink className='dropdown-item' to={`/account/${user.CompanyId}/accountsettings/master_data`}> {ht('mainHeader.accountSettings')}</NavLink>
                            }
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="" onClick={(e) => { e.preventDefault(); new SignInManager().Logout(navigate) }}>
                                {ht('mainHeader.logout')}
                            </NavDropdown.Item>
                        </NavDropdown>
                    </HeaderMenuItem>
                </HeaderMenu>
            </DisplayBetween>
        </>
    );
}

export default MainHeader;

const IconBadge = (props) => {
    const { Message, Notification, badgeCount } = props;

    return (
        <>
            <IconWrapper>
                {
                    badgeCount > 0 &&
                    <Badge NotifBadge={Notification}>{badgeCount}</Badge>
                }
                {Message ?
                    <BsEnvelope size={25} color='#9fa8b0' />
                    : null
                }
                {Notification ?
                    <RotateIcon>
                        <FaRegBell size={25} color='#9fa8b0' />
                    </RotateIcon>
                    : null
                }
            </IconWrapper>
        </>
    )
}
const UserAvatar = (props) => {
    const { Image, Username } = props;
    const UsernameShort = Username.split(' ').map(word => word.charAt(0).toUpperCase())
    return (
        <>
            <AvatarBox bgImage={Image}>{!!Image ? null : UsernameShort}</AvatarBox> {Username}
        </>
    )
}
function LocationDropDown(props) {
    const appSettings = useSelector(x => x.app.appSettings)
    // const SwitchLocation = async (id) => {
    //     var response = await CallAxios("/api/Account/SwitchLocation|post", { id: id + "" })
    //     if (!!response && response.status === 200) {
    //         localStorage.setItem("InitialLogin", true)
    //         new SignInManager().SetToken(response.data.token)
    //         var tokenData = new SignInManager().ParseJwt

    //         if (tokenData.Role.split(",").some(x => x === Constants.roles.AccountAdmin || x === Constants.roles.WorkspaceAdmin)) {
    //             window.location.href = `/companies/${id}/calendar`
    //         } else {
    //             if (appSettings.employeeAccess.canSeeCalender === true) {
    //                 window.location.href = `/companies/${id}/calendar`
    //             }
    //             else if (appSettings.employeeAccess.canSeetimetracking === true) {
    //                 window.location.href = `/companies/${id}/timetracking`
    //             }
    //             else if (appSettings.employeeAccess.canSeeabsences === true) {
    //                 window.location.href = `/companies/${id}/absences`
    //             }
    //             else {
    //                 window.location.href = `/companies/${id}/users/${tokenData.EmpID}/master_data`
    //             }
    //         }
    //     }
    // }

    return (
        <DropdownButton id="dropdown-basic-button" className="Companydropdown text-center" title={props.currentlocation?.text}>
            {
                !!props.locationList && props.locationList.length > 0 ? props.locationList.map((value, key) => {
                    return <Dropdown.Item href="#" onClick={() => { SwitchLocation(value.value, appSettings) }}>{value.text}</Dropdown.Item>
                    // return <Dropdown.Item href={`/companies/${value.value}`} onClick={()=> {setCookieValue("LocationName",value.text,3);setcurrentView("admin");}}>{value.text}</Dropdown.Item>
                })
                    : null
            }
        </DropdownButton>
    );
}