



export class Constants {
    static get roles() {
        return {
            AccountAdmin: "Account admin",
            Employee: "Employee",
            Admin: "Admin",
            WorkspaceAdmin: "Workspace admin"
        }
    }

    static get roleIds() {
        return {
            Employee: 1,
            Admin: 2,
            WorkspaceAdmin: 3,
            AccountAdmin: 4
        }
    }

    static get timeTrackingModes() {
        return {
            StampApp: "Stamp-App",
            BrowserStamp: "Browser-Stamp",
            roster: "roster",
        }
    }

    static get absenceStatus() {
        return {
            Confirmed: "confirmed",
            Pending: "pending",
            Rejected: "rejected",
        }
    }

    static get rolesDropDown() {
        return [
            { value: 'Y8pD9YJCKexw/wBf+f8nuw==', label: this.roles.Employee, text: this.roles.Employee },//1
            { value: 'VaGFaLByaSFiTpRPWnZUxg==', label: this.roles.Admin, text: this.roles.Admin },//2
            { value: 'bfAmWGqmtASVCOOyJl/M5g==', label: this.roles.WorkspaceAdmin, text: this.roles.WorkspaceAdmin },//3
            { value: 'oOCtuL7SDG9icRuY5zx+dA==', label: this.roles.AccountAdmin, text: this.roles.AccountAdmin },//4
        ];
    }

    static get timeTrackingTypesDropDown() {
        return [
            { value: this.timeTrackingModes.StampApp, label: "Via App", text: "Via App" },
            { value: this.timeTrackingModes.roster, label: "Via roster", text: "Via roster" },
            { value: this.timeTrackingModes.BrowserStamp, label: "Via Browser-time Clock", text: "Via Browser-time Clock" },
        ];
    }

    static get layoutType() {
        return {
            Employee: 'employee',
            Admin: "admin",
        };
    }

    static get SubscriptionStatus() {
        return {
            Expire: 0,
            Paid: 1
        };
    }
    static get PaymentType() {
        return {
            Card: 0,
            Paypal: 1
        };
    }
    static get SubscriptionPeriod() {
        return {
            Trial: 0,
            Monthly: 1,
            Yearly: 2
        };
    }
    static get folderActions() {
        return {
            Read: 1,
            Write: 2,
            Modify: 3,
            Delete: 4
        };
    }
    static get SubscriptionModulesConstants() {
        return {
            Absences: "Absences",
            TimeTracking: "Time tracking",
            WorkingTimeAccount: "Working-Time Account",
            Qualifications: "Qualifications",
            Archive: "Archive",
            ShiftPlanner: "Shift planner",
            FlextimeQageRecord: "Flextime wage record",
            Uploads: "Uploads",
            RolesAndRights: "Roles and Rights",
            BudgetPlanning: "Budget planning",
            PersonalSuccessManager: "Personal success manager",
            SupportForEmployees: "Support for employees",
            ServiceLevelAgreements: "Service Level Agreements",
            EmployeeTemplates: "Employee templates",
            Imports: "Imports",
            AccountExports: "Account exports",
            GuestAccess: "Guest access",
            AutoAssignments: "Auto Assignments"

        };
    }

    static get companyNumberOfEmployeesDropDown() {
        return [
            {
                value: "Choose number of employees",
                text: "Choose number of employees"
            },
            {
                value: "1-5 employees",
                text: "1-5 employees"
            },
            {
                value: "6-20 employees",
                text: "6-20 employees"
            },
            {
                value: "21-35 employees",
                text: "21-35 employees"
            },
            {
                value: "36-50 employees",
                text: "36-50 employees"
            },
            {
                value: "51-80 employees",
                text: "51-80 employees"
            },
            {
                value: "81-150 employees",
                text: "81-150 employees"
            },
            {
                value: "151-500 employees",
                text: "151-500 employees"
            },
            {
                value: "mehr als 500 employees",
                text: "more than 500 employees"
            }
        ];
    }

    static get industryOptions() {
        return [
            {
                value: "Plain",
                text: "Branche wählen"
            },
            {
                value: "Apotheke",
                text: "Apotheke"
            },
            {
                value: "Arztpraxis ",
                text: "Arztpraxis"
            },
            {
                value: "Arztpraxis mit Bereich je Arzt",
                text: "Arztpraxis mit Bereich je Arzt"
            },
            {
                value: "Arztpraxis mit MRT",
                text: "Arztpraxis mit MRT"
            },
            {
                value: "Ausbildung",
                text: "Ausbildung"
            },
            {
                value: "Autohaus mit Verkauf und Werkstatt",
                text: "Autohaus mit Verkauf und Werkstatt"
            },
            {
                value: "Bäckerei mit Bezirksplanung",
                text: "Bäckerei mit Bezirksplanung"
            },
            {
                value: "Bankfiliale",
                text: "Bankfiliale"
            },
            {
                value: "Bauunternehmen",
                text: "Bauunternehmen"
            },
            {
                value: "Bildung",
                text: "Bildung"
            },
            {
                value: "E-Commerce",
                text: "E-Commerce"
            },
            {
                value: "Einzelhandel Supermarkt",
                text: "Einzelhandel Supermarkt"
            },
            {
                value: "Eventveranstalter",
                text: "Eventveranstalter"
            },
            {
                value: "Fitness",
                text: "Fitness"
            },
            {
                value: "Freizeit",
                text: "Freizeit"
            },
            {
                value: "Gastronomie",
                text: "Gastronomie"
            },
            {
                value: "Hotel",
                text: "Hotel"
            },
            {
                value: "Kino",
                text: "Kino"
            },
            {
                value: "Kita",
                text: "Kita"
            },
            {
                value: "Kleines Filialgeschäft",
                text: "Kleines Filialgeschäft"
            },
            {
                value: "Kurierdienst",
                text: "Kurierdienst"
            },
            {
                value: "Leerer Standort",
                text: "Leerer Standort"
            },
            {
                value: "Limousinenservice/Taxi",
                text: "Limousinenservice/Taxi"
            },
            {
                value: "Logistik/ Transportunternehmen",
                text: "Logistik/ Transportunternehmen"
            },
            {
                value: "Marketing",
                text: "Marketing"
            },
            {
                value: "Museum Tour Guides",
                text: "Museum Tour Guides"
            },
            {
                value: "Personaldienstleistung",
                text: "Personaldienstleistung"
            },
            {
                value: "Pflegedienst",
                text: "Pflegedienst"
            },
            {
                value: "Planung Kundeneinsätze",
                text: "Planung Kundeneinsätze"
            },
            {
                value: "Produktion: 3 Schicht mit Schichführer",
                text: "Produktion: 3 Schicht mit Schichführer"
            },
            {
                value: "Produktion: mit Arbeitsschritten",
                text: "Produktion: mit Arbeitsschritten"
            },
            {
                value: "Produktion: ohne Schichtbetrieb",
                text: "Produktion: ohne Schichtbetrieb"
            },
            {
                value: "Produzierendes Gewerbe",
                text: "Produzierendes Gewerbe"
            },
            {
                value: "Promotion mit freien Mitarbeitern",
                text: "Promotion mit freien Mitarbeitern"
            },
            {
                value: "Redaktion (Zeitung/ Radio)",
                text: "Redaktion (Zeitung/ Radio)"
            },
            {
                value: "Reisebüro",
                text: "Reisebüro"
            },
            {
                value: "Rettungsdienst",
                text: "Rettungsdienst"
            },
            {
                value: "Rundfunk",
                text: "Rundfunk"
            },
            {
                value: "Schwimmbad",
                text: "Schwimmbad"
            },
            {
                value: "Service Center",
                text: "Service Center"
            },
            {
                value: "Servicecenter/ Callcenter",
                text: "Servicecenter/ Callcenter"
            },
            {
                value: "Servicecenter (einfach)",
                text: "Servicecenter (einfach)"
            },
            {
                value: "Sicherheitsdienst",
                text: "Sicherheitsdienst"
            },
            {
                value: "Skigebiet",
                text: "Skigebiet"
            },
            {
                value: "Sozialeinrichtung/ Jugendhilfe",
                text: "Sozialeinrichtung/ Jugendhilfe"
            },
            {
                value: "Theater",
                text: "Theater"
            },
            {
                value: "Tourguides",
                text: "Tourguides"
            },
            {
                value: "Verein",
                text: "Verein"
            },
            {
                value: "Wohngruppen",
                text: "Wohngruppen"
            }
        ];
    }
    static get hoursDropDown() {
        return Array(24).fill(0).map((x, i) => ({ value: i.toString(), text: i.toLocaleString({}, { minimumIntegerDigits: 2 }) }));
    }

    static get minutesDropDown() {
        return Array(60).fill(0).map((x, i) => ({ value: i.toString(), text: i.toLocaleString({}, { minimumIntegerDigits: 2 }) }))
    }
    static get noOfEmployeeDropDown() {
        return Array(201).fill(0).map((x, i) => ({ value: (i + 2).toString(), text: (i + 2).toLocaleString({}, { minimumIntegerDigits: 1 }) }))
    }
}

