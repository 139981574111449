import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Form, Modal, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { FaCheck, FaRegCheckCircle, FaTimes, FaUndo } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import NotesModal from '../../Components/NoteModal';
import { AxiosLite, CallAxios } from '../../Helpers';
import { Constants } from '../../Helpers/Contents';
import { ContentBox, ContentSubHeader, StatusBox } from '../../Helpers/Global/StyledComponents';
import { GetAbsenceTypeByEmpid, GetEmployeeDDForAbsence } from '../../Helpers/MasterData';
import AbsencesGrid from './AbsencesGrid';
import AbsencesHeader from './AbsencesHeader';
import usePagination from '../../Helpers/usePagination';
import { useTranslation } from "react-i18next";


// Main Absences Page
function AbsencesList() {
    var user = useSelector(x => x.app.user);

    const [showAddEditAbsenceModal, setShowAddEditAbsenceModal] = useState(false);
    const [absenceList, setAbsenceList] = useState([]);
    const [absenceIdForEdit, setAbsenceIdForEdit] = useState("");
    const [absenceModalType, setAbsenceModalType] = useState("add");
    const [FilterStartDate, setFilterStartDate] = useState();
    const [FilterEndDate, setFilterEndDate] = useState();
    const [checkRadio, setCheckRadio] = useState("home");
    const [PaginateInfo, setPaginateInfo] = useState(null);
    const getPaginateInfo = (prop) => {
        setPaginateInfo(prop)
    }
    const handelDateFilter = (btnType, startDate, endDate) => {
        if (btnType === "reset") {
            setFilterStartDate()
            setFilterEndDate()
            GetAbsenceList(null, startDate, endDate)
        }
        if (btnType === "filter") {
            setFilterStartDate(startDate)
            setFilterEndDate(endDate)
            GetAbsenceList(null, startDate, endDate)
        }
    }

    // useEffect(() => {
    //     if (!!user.EncLoginUserId) {
    //         GetAbsenceList()
    //     }
    // }, [user.EncLoginUserId]);
    const GetAbsenceList = async (prop, startDate, endDate) => {
        if (!prop && !!PaginateInfo) {
            prop = PaginateInfo
        }
        var data = {
            EmployeeId: user.EncLoginUserId,
            Skip: prop.skip,
            Take: prop.take,
            StartDate: !!startDate ? moment(startDate).startOf('day') : null,
            EndDate: !!endDate ? moment(endDate).endOf('day') : null
        }
        var res = await CallAxios("/api/Absence/absenceListEmployee|post", data)
        if (!!res && res.status === 200) {
            setAbsenceList(res.data.list)
            prop.updateCount(res.data.count)
        }
    }

    return (
        <>
            <ContentSubHeader>
                <AbsencesHeader checkRadio={checkRadio} setCheckRadio={setCheckRadio} setShowAddEditAbsenceModal={setShowAddEditAbsenceModal} setAbsenceIdForEdit={setAbsenceIdForEdit} setAbsenceModalType={setAbsenceModalType}
                    handelDateFilter={handelDateFilter}
                // FilterStartDate={FilterStartDate} setFilterStartDate={setFilterStartDate}
                // FilterEndDate={FilterEndDate} setFilterEndDate={setFilterEndDate} 
                />
            </ContentSubHeader>
            <ContentBox>
                <AbsencesGrid getPaginateInfo={getPaginateInfo} checkRadio={checkRadio} absenceList={absenceList} GetAbsenceList={GetAbsenceList} setAbsenceModalType={setAbsenceModalType} setAbsenceIdForEdit={setAbsenceIdForEdit}
                    setShowAddEditAbsenceModal={setShowAddEditAbsenceModal} FilterStartDate={FilterStartDate} FilterEndDate={FilterEndDate} />
            </ContentBox>
            {
                showAddEditAbsenceModal === true ?
                    <AddEditAbsenceModal modalType={absenceModalType} show={showAddEditAbsenceModal} onHide={setShowAddEditAbsenceModal} GetAbsenceList={GetAbsenceList} absenceIdForEdit={absenceIdForEdit} setAbsenceIdForEdit={setAbsenceIdForEdit} />
                    // <EmployeeAbsenceModal show={showAddAbsenceModal} onHide={setShowAddAbsenceModal} GetAbsenceList={GetAbsenceList} />
                    : null
            }
        </>
    );
}

export default AbsencesList;

export function AddEditAbsenceModal(props) {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');
    const { t: apt } = useTranslation('absencePage');

    const currentView = useSelector(x => x.app.currentView);
    const user = useSelector(x => x.app.user);
    const { userid } = useParams();

    const [EmployeeDDList, setEmployeeDDList] = useState([]);
    const [AbsenceTypeDDList, setAbsenceTypeDDList] = useState([]);
    const [OverLappingList, setOverLappingList] = useState([]);
    const [StartTimeHour, setStartTimeHour] = useState(8);
    const [StartTimeMin, setStartTimeMin] = useState(0);
    const [EndTimeHour, setEndTimeHour] = useState(16);
    const [EndTimeMin, setEndTimeMin] = useState(0);
    const [OccupiedShifts, setOccupiedShifts] = useState("0");
    const [applications, setApplications] = useState("0");
    const [ShowOverLappingModel, setShowOverLappingModel] = useState(false);
    const [suggestedCorrectedhours, setSuggestedCorrectedhours] = useState(0);
    const [suggestedHolidays, setSuggestedHolidays] = useState(0);
    //absence type seting
    const [Takepublicholidaysintoaccount, setTakepublicholidaysintoaccount] = useState(false);
    //Edit form States
    const [showSaveButton, setShowSaveButton] = useState(false); //update record or confirm record
    const [empName, setempName] = useState("");
    const [absenceType, setabsenceType] = useState("");
    const [AllowDelation, setAllowDelation] = useState(false);
    const [ShowNoQuotaOrConsumedModal, setShowNoQuotaOrConsumedModal] = useState(false);
    const [vocationConsume, setVocationConsume] = useState(0);
    const [vocationAvailible, setVocationAvailible] = useState(0);
    const [isEmployeesResetAbsences, setIsEmployeesResetAbsences] = useState(false);
    const [isinitilize, setisinitilize] = useState(false);

    const [HaveAccessToConfirm, setHaveAccessToConfirm] = useState(false);
    const formik = useFormik({
        initialValues: {
            employeeID: '',
            absenceID: '',
            absenceTypeId: '',
            startDate: new Date(),
            endDate: new Date(),
            isallday: true,
            isEnforceoffsetting: false,
            correctedhours: 0.0,
            holidays: 0.0,
        },
        validationSchema: Yup.object().shape({
            employeeID: Yup.string().typeError("Please select employee").required(apt('errorMsg.employeeId')),
            absenceTypeId: Yup.string().typeError("Please select absence type").required(apt('errorMsg.absenceTypeId')),
            startDate: Yup.date().typeError("Invalid Date").required(apt('errorMsg.invalidData')),
            endDate: Yup.date().typeError("Invalid Date").required(apt('errorMsg.invalidData')),
            correctedhours: Yup.number().typeError("Corrected hours must be a number").required(apt('errorMsg.correctedhours')),
            holidays: Yup.number().typeError("Vacation days must be a number").required(apt('errorMsg.holiday'))
        }),
        onSubmit: values => {
            CheckOverLapping()
        },
    })
    useEffect(() => {
        if (!!props.absenceIdForEdit) {
            GetAbsence();
        } else {
            OnPageLoad();
        }
    }, []);
    useEffect(() => {
        if (!!props.DateTobESelected) {
            formik.setFieldValue("startDate", new Date(props.DateTobESelected))
            formik.setFieldValue("endDate", new Date(props.DateTobESelected))
        }
    }, [props.DateTobESelected]);
    useEffect(() => {
        if (!!formik.values.absenceTypeId) {
            getAbsenceTypeSetting(formik.values.absenceTypeId);
        }
    }, [formik.values.absenceTypeId]);
    useEffect(() => {
        if ((props.modalType === Constants.absenceStatus.Pending && isinitilize) || props.modalType !== Constants.absenceStatus.Pending) {
            if (!!formik.values.startDate && !!formik.values.endDate && !!formik.values.employeeID && !!formik.values.absenceTypeId) {
                const startDate = moment(formik.values.startDate);
                const endDate = moment(formik.values.endDate);
                if (startDate.isAfter(endDate)) {
                    formik.setFieldValue("endDate", new Date(startDate))
                }
                if (startDate.isSame(endDate)) {
                    if (parseInt(EndTimeHour) < parseInt(StartTimeHour)) {
                        setEndTimeHour(StartTimeHour)
                        toast.error(apt('errorMsg.startEndTimeHour'))
                        return;
                    }
                }
                if (formik.values.isEnforceoffsetting === false) {
                    findDays()
                    getDatesCount(formik.values.startDate, formik.values.endDate, formik.values.employeeID, Takepublicholidaysintoaccount);
                }
                getOccupiedshifts(formik.values.startDate, formik.values.endDate, formik.values.employeeID)
            }
        }
    }, [StartTimeHour, StartTimeMin, EndTimeHour, EndTimeMin, formik.values.isallday, formik.values.startDate, formik.values.endDate, formik.values.isEnforceoffsetting, formik.values.employeeID, formik.values.absenceTypeId]);
    const getAbsenceTypeSetting = async (absenceTypeId) => {
        var response = await CallAxios("/api/LocationSetting/getAbsenceType|post", { id: absenceTypeId })
        if (!!response && response.data) {
            setTakepublicholidaysintoaccount(response.data.takepublicholidaysintoaccount)
            setIsEmployeesResetAbsences(response.data.isEmployeesResetAbsences)
        }
    }
    async function findDays() {
        if (!!formik.values.employeeID && !!formik.values.absenceTypeId) {
            var startDateTime = moment(formik.values.startDate).startOf('day')
                .add(parseInt(StartTimeHour), 'hours')
                .add(parseInt(StartTimeMin), 'minutes');

            var endDateTime = moment(formik.values.endDate).startOf('day')
                .add(parseInt(EndTimeHour), 'hours')
                .add(parseInt(EndTimeMin), 'minutes');
            var data = {
                startDate: startDateTime,
                endDate: endDateTime,
                empID: formik.values.employeeID,
                absenceTypeId: formik.values.absenceTypeId,
                isallday: formik.values.isallday,
            }
            var response = await CallAxios("/api/Absence/getcalculateDays|post", data)
            if (!!response && response.status === 200) {
                formik.setFieldValue("holidays", response.data)
                setSuggestedHolidays(response.data)
            }
        }
    }
    const onsubmit = async () => {

        const startDate = moment(formik.values.startDate);
        const endDate = moment(formik.values.endDate);

        if (startDate.isSame(endDate)) {
            if (parseInt(StartTimeHour) === parseInt(EndTimeHour) && parseInt(StartTimeMin) >= parseInt(EndTimeMin)) {
                toast.error(apt('errorMsg.absenceStartEndDate'))
                return
            }
            if (parseInt(EndTimeHour) < parseInt(StartTimeHour)) {
                toast.error(apt('errorMsg.startEndTimeHour'))
                return
            }
        }
        var startDateTime = null;
        var endDateTime = null;
        if (formik.values.isallday === true) {
            startDateTime = moment(formik.values.startDate).startOf('day')
                .add(0, 'hours')
                .add(0, 'minutes');

            endDateTime = moment(formik.values.endDate).startOf('day')
                .add(23, 'hours')
                .add(59, 'minutes');
        } else {
            startDateTime = moment(formik.values.startDate).startOf('day')
                .add(parseInt(StartTimeHour), 'hours')
                .add(parseInt(StartTimeMin), 'minutes');

            endDateTime = moment(formik.values.endDate).startOf('day')
                .add(parseInt(EndTimeHour), 'hours')
                .add(parseInt(EndTimeMin), 'minutes');
        }
        if (formik.values.isEnforceoffsetting) {
            if (parseFloat(formik.values.correctedhours) < 0) {
                toast.error("Hours are not valid.")
                return
            }
            if (parseFloat(formik.values.holidays) < 0) {
                toast.error("Holidays are not valid.")
                return
            }
        }

        if (!!props.absenceIdForEdit) {
            var absence = {
                EmployeeID: formik.values.employeeID,
                AbsenceID: formik.values.absenceID,
                AbsenceTypeId: formik.values.absenceTypeId,
                AbsenceType: absenceType,
                StartDate: startDateTime,
                EndDate: endDateTime,
                isallday: formik.values.isallday,
                isEnforceoffsetting: formik.values.isEnforceoffsetting,
                correctedhours: parseFloat(formik.values.correctedhours),
                holidays: parseFloat(formik.values.holidays),
                saveonly: showSaveButton
            };
            var response = await CallAxios("/api/Absence/editAbsenceAD|post", absence)
            if (!!response && response.status === 200) {
                props.GetAbsenceList()
                if (showSaveButton === true) {
                    props.onHide(false)
                    return;
                }
                if (response.data.toString() === "true") {
                    props.onHide(false)
                    // if (userRoles.filter(x => x.label.toLowerCase().includes("admin")).length > 0 && getEveryThing.absenceSetting.confirmAbsencesCreatedAdministrators === true) {
                    //     setTimeout(() => {
                    //         props.handleModalShowHide("AbsenceInfoModal")
                    //     }, 2000);
                    // }
                    // else if (userRoles.filter(x => x.label.toLowerCase().includes("employee")).length > 0 && getEveryThing.absenceSetting.confirmAbsencesCreatedEmployee === true) {
                    //     setTimeout(() => {
                    //         props.handleModalShowHide("AbsenceInfoModal")
                    //     }, 2000);
                    // }
                }
                else if (response.data === "No Quota Or Consumed") {
                    toast.error(apt('errorMsg.noQuotaConsumed'))
                }
                else if (response.data === "Ban") {
                    toast.error(apt('errorMsg.changeAbsenceBan'))
                }
            }
        } else {
            var absence = {
                EmployeeID: formik.values.employeeID,
                AbsenceTypeId: formik.values.absenceTypeId,
                StartDate: startDateTime,
                EndDate: endDateTime,
                isallday: formik.values.isallday,
                isEnforceoffsetting: formik.values.isEnforceoffsetting,
                correctedhours: parseFloat(formik.values.correctedhours),
                holidays: parseFloat(formik.values.holidays),
            };

            var response = await CallAxios("/api/Absence/absenseCreateAD|post", absence)
            if (!!response && response.status === 200) {
                props.GetAbsenceList()
                props.onHide(false)
            }
        }
    }
    const GetAbsence = async () => {
        var absenceObj = await CallAxios("/api/Absence/getAbsenceAD|post", { id: props.absenceIdForEdit });
        if (!!absenceObj) {
            // setEmpdata(absenceObj.data)
            formik.setFieldValue("employeeID", absenceObj.data.employeeID)
            formik.setFieldValue("absenceID", absenceObj.data.absenceID)
            formik.setFieldValue("absenceTypeId", absenceObj.data.absenceTypeId)
            formik.setFieldValue("startDate", new Date(absenceObj.data.startDate))
            formik.setFieldValue("endDate", new Date(absenceObj.data.endDate))
            formik.setFieldValue("isallday", absenceObj.data.isallday)
            formik.setFieldValue("isEnforceoffsetting", (!!absenceObj.data.isEnforceoffsetting ? absenceObj.data.isEnforceoffsetting : false))
            formik.setFieldValue("correctedhours", absenceObj.data.correctedhours)
            formik.setFieldValue("holidays", absenceObj.data.holidays)
            setAllowDelation(absenceObj.data.isAllowDeletionAbsences)
            setabsenceType(absenceObj.data.absenceType)
            setempName(absenceObj.data.employeeName)
            setStartTimeHour(absenceObj.data.startTimehour)
            setStartTimeMin(absenceObj.data.startTimeMin)
            setEndTimeHour(absenceObj.data.endTimeHour)
            setEndTimeMin(absenceObj.data.endTimeMin)
            setVocationConsume(absenceObj.data.vocationConsume)
            setVocationAvailible(absenceObj.data.vocationAvailiblae)
            setHaveAccessToConfirm(absenceObj.data.isHaveAccessToconfirm)
        }

        await GetAbsenceTypeByEmpid(setAbsenceTypeDDList, { id: absenceObj.data.employeeID }); //  resp = dropdown first option value
        // await getOccupiedshifts(absenceObj.data.startDate, absenceObj.data.endDate, absenceObj.data.employeeID)
        setisinitilize(true)
    }
    const OnPageLoad = async () => {

        if (currentView === Constants.layoutType.Admin && user.roles.length > 0 && user.roles.some(x => x === Constants.roles.AccountAdmin || x === Constants.roles.WorkspaceAdmin)) {
            if (!!userid) {
                funsetempID(userid)
            } else if (!!props.SelectedEmployeeID) {
                funsetempID(props.SelectedEmployeeID)
            } else {
                var resp = await GetEmployeeDDForAbsence(setEmployeeDDList)
                funsetempID(resp)
            }

        } else {
            var response = await AxiosLite("/api/Employee/GetEmployeeAccess|get")
            if (!!response && response.status === 200) {
                if (response.data.createAbsenceForAccountAdmin === true || response.data.createAbsenceForAreaAdmin === true || response.data.createAbsenceForEmployee === true || response.data.createAbsenceForWorkspaceAdmin === true) {
                    var resp = await GetEmployeeDDForAbsence(setEmployeeDDList)
                    funsetempID(resp)

                } else {
                    funsetempID(user.EncLoginUserId)
                }
            }
        }
    }
    const funsetempID = async (id) => {
        formik.setFieldValue("employeeID", id)
        // formik.setFieldValue("absenceTypeId", "")
        // setting
        // setDeduct_from_remaining_vacation_days(false)
        var resp = await GetAbsenceTypeByEmpid(setAbsenceTypeDDList, { id: id }); //  resp = dropdown first option value
        formik.setFieldValue("absenceTypeId", resp)

        // getDatesCount(formik.values.startDate, formik.values.endDate, id, Takepublicholidaysintoaccount, resp)
        // await getOccupiedshifts(formik.values.startDate, formik.values.endDate, id)
    }
    async function getOccupiedshifts(StartDate, EndDate, empID) {
        if (!!formik.values.employeeID) {
            var startDateTime = null;
            var endDateTime = null;
            if (formik.values.isallday === true) {
                startDateTime = moment(StartDate).startOf('day').add(0, 'hours').add(0, 'minutes');
                endDateTime = moment(EndDate).startOf('day').add(23, 'hours').add(59, 'minutes');
            } else {
                startDateTime = moment(StartDate).startOf('day')
                    .add(parseInt(StartTimeHour), 'hours')
                    .add(parseInt(StartTimeMin), 'minutes');

                endDateTime = moment(EndDate).startOf('day')
                    .add(parseInt(EndTimeHour), 'hours')
                    .add(parseInt(EndTimeMin), 'minutes');
            }

            var data = {
                startDate: startDateTime,
                endDate: endDateTime,
                empID: empID,
                isallday: formik.values.isallday,
            }
            var response = await CallAxios("/api/Absence/GetOccupiedshifts|post", data)
            if (!!response && response.status === 200) {
                setOccupiedShifts(response.data)
            }
        }
    }
    async function getDatesCount(startDate, endDate, id, takepublicholidaysintoaccountValue, absenceTypeID) {
        formik.setFieldValue("correctedhours", 0.0)

        if (!!id && !!formik.values.startDate && !!startDate && !!endDate) {
            var startDateTime = null;
            var endDateTime = null;
            if (formik.values.isallday === true) {
                startDateTime = moment(startDate).startOf('day')
                    .add(0, 'hours')
                    .add(0, 'minutes')
                    .add(59, 'seconds');

                endDateTime = moment(endDate).startOf('day')
                    .add(23, 'hours')
                    .add(59, 'minutes')
                    .add(59, 'seconds');

            } else {
                startDateTime = moment(startDate).startOf('day')
                    .add(parseInt(StartTimeHour), 'hours')
                    .add(parseInt(StartTimeMin), 'minutes');

                endDateTime = moment(endDate).startOf('day')
                    .add(parseInt(EndTimeHour), 'hours')
                    .add(parseInt(EndTimeMin), 'minutes');
            }

            var data = {
                startDate: startDateTime,
                endDate: endDateTime,
                empID: id,
                isallday: formik.values.isallday,
                takepublicholidaysintoaccountSetting: takepublicholidaysintoaccountValue,
                absencetypeID: !!absenceTypeID ? absenceTypeID : formik.values.absenceTypeId
            }
            var response = await CallAxios("/api/Absence/getcalculateHours|post", data)
            formik.setFieldValue("correctedhours", response.data.toString())
            setSuggestedCorrectedhours(response.data.toString())
            var Applicationresponse = await CallAxios("/api/Absence/GetShiftApplicationInPeriod|post", data)
            setApplications(Applicationresponse.data)
        }
    }
    const CheckOverLapping = async () => {

        var startDateTime = null;
        var endDateTime = null;
        if (formik.values.isallday === true) {
            startDateTime = moment(formik.values.startDate).startOf('day')
                .add(0, 'hours')
                .add(0, 'minutes');

            endDateTime = moment(formik.values.endDate).startOf('day')
                .add(23, 'hours')
                .add(59, 'minutes');
        } else {
            startDateTime = moment(formik.values.startDate).startOf('day')
                .add(parseInt(StartTimeHour), 'hours')
                .add(parseInt(StartTimeMin), 'minutes');

            endDateTime = moment(formik.values.endDate).startOf('day')
                .add(parseInt(EndTimeHour), 'hours')
                .add(parseInt(EndTimeMin), 'minutes');
        }
        var absence = {
            AbsenceID: props.absenceIdForEdit,
            EmployeeID: formik.values.employeeID,
            AbsenceType: formik.values.absenceTypeId,
            StartDate: startDateTime,
            EndDate: endDateTime,
            isallday: formik.values.isallday,
            isEnforceoffsetting: formik.values.isEnforceoffsetting,
            correctedhours: parseFloat(formik.values.correctedhours),
            holidays: parseFloat(formik.values.holidays),
        };

        var response = await CallAxios("/api/Absence/CheckAbsenceOverLaping|post", absence)
        if (!!response && response.status === 200 && response.data.length > 0) {
            setOverLappingList(response.data)
            setShowOverLappingModel(true)

        } else if (!!response.data) {
            onsubmit()
        }
    }
    //Edit form functionx
    const changestatus = async (status) => {
        var data = {
            id: formik.values.absenceID,
            status: status
        }
        var response = await CallAxios("/api/Absence/changeAbsenceStatusAdmin|post", data)
        if (!!response && response.status === 200) {
            if (response.data.toString() === "true") {
                props.GetAbsenceList()
                props.onHide(false)
            }
            else if (response.data === "No Quota Or Consumed") {
                setShowNoQuotaOrConsumedModal(true)
            }
            else if (response.data === "Ban") {
                toast.error(apt('errorMsg.changeAbsenceBan'))
            }
        }
    }
    const deleteAbsence = async () => {
        var data = {
            id: formik.values.absenceID
        }
        var response = await CallAxios("/api/Absence/DeleteAbsenceAD|post", data)
        if (!!response && response.status === 200) {
            props.GetAbsenceList()
            props.onHide(false)
        }
    }
    const OnCancle = async () => {
        await changestatus('R')
        props.onHide(false)
    }
    return (
        <>
            {
                ShowOverLappingModel === true ?
                    <OverLappingAbseneceModel show={ShowOverLappingModel} setShow={setShowOverLappingModel} dataList={OverLappingList} onsubmit={onsubmit} />
                    : null
            }
            {
                ShowNoQuotaOrConsumedModal === true ?
                    <NoQuotaOrConsumedModal show={ShowNoQuotaOrConsumedModal} tryToconfirmAbsenceDate={formik.values.startDate} setShow={setShowNoQuotaOrConsumedModal} SelectedEmployeeId={formik.values.employeeID} />
                    : null
            }
            <Modal size='lg' show={props.show} className="largeModalDialog" onHide={() => { props.onHide(false); props.setAbsenceIdForEdit("") }}>
                <Modal.Header closeButton className="position-relative">
                    {
                        !!props.absenceIdForEdit ?
                            <>
                                <Modal.Title>
                                    <div className='d-flex align-items-center'><span className='me-4'>{absenceType} - {empName} </span>
                                        {
                                            props.modalType === Constants.absenceStatus.Pending ?
                                                <StatusBox status={props.modalType} className="me-2"> {t('absenceTab.newAbsenMdl.outstanding')}</StatusBox>
                                                :
                                                <StatusBox status={props.modalType} className="me-2">{props.modalType}</StatusBox>
                                        }
                                    </div>
                                    <div className='d-flex align-items-center mt-2'>
                                        <div className="font12 text-black">{new Date(formik.values.startDate).toLocaleDateString()} - {new Date(formik.values.startDate).toLocaleDateString()}</div>
                                        <div className="font12 text-black mx-4">{t('absenceTab.newAbsenMdl.hours')}: {formik.values.correctedhours} | {t('absenceTab.newAbsenMdl.days')}: {formik.values.holidays}</div>
                                        <div className="font12 text-black">{t('absenceTab.newAbsenMdl.vacationAvailable')} {vocationConsume} / {vocationAvailible}</div>

                                    </div>
                                </Modal.Title>
                                {/* <Col md={10}>
                                    <Container fluid>
                                        <Row>
                                            <Col md={4} className="pl-0">
                                                <div className="HeaderInfoText">{new Date(formik.values.startDate).toLocaleDateString()} - {new Date(formik.values.startDate).toLocaleDateString()}</div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="HeaderInfoText">Hours: {formik.values.correctedhours} | Days: {formik.values.holidays}</div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="HeaderInfoText">Vacation available {vocationConsume} / {vocationAvailible}</div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col> */}
                            </>
                            :
                            <Modal.Title> {t('absenceTab.newAbsenMdl.addAbsence')}</Modal.Title>
                    }
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={6}>
                                <Container fluid>
                                    <Row>
                                        {
                                            !props.absenceIdForEdit && !!EmployeeDDList && EmployeeDDList.length > 0 && !userid && !props.SelectedEmployeeID ?
                                                <Col md={6} className="border-bottom ps-0">
                                                    <Form.Group controlId="AddAbsenceForm.SelectEmployee">
                                                        <Form.Label>{ct('employee')}</Form.Label>
                                                        <Form.Control as="select" className="SelectbgGrey" value={formik.values.employeeID} name="employeeID" onChange={(e) => funsetempID(e.target.value)} custom>
                                                            {
                                                                EmployeeDDList.map((value, key) => {
                                                                    return <option value={value.value}>{value.text}</option>
                                                                })
                                                            }
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                : null
                                        }
                                        {
                                            props.modalType === "add" || props.modalType === Constants.absenceStatus.Pending ?
                                                <Col md={6} className="border-bottom pe-0">
                                                    <Form.Group controlId="AddAbsenceForm.SelectEmployee">
                                                        <Form.Label> {t('absenceTab.newAbsenMdl.absenceType')}</Form.Label>
                                                        <Form.Control as="select" className="SelectbgGrey" value={formik.values.absenceTypeId} name="absenceTypeId" onChange={(e) => { formik.handleChange(e); !!props.absenceIdForEdit && setShowSaveButton(true) }} custom>
                                                            {
                                                                !!AbsenceTypeDDList && AbsenceTypeDDList.length > 0 ?
                                                                    AbsenceTypeDDList.map((value, key) => {
                                                                        return <option value={value.value}>{value.text}</option>
                                                                    }) : null
                                                            }
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                : null
                                        }
                                        <Col md={12} className="px-0 py-3">
                                            <Card>
                                                <Card.Header> {t('absenceTab.newAbsenMdl.period')}</Card.Header>
                                                {
                                                    props.modalType === "add" || props.modalType === Constants.absenceStatus.Pending ?
                                                        <>
                                                            <Card.Body>
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <Form.Group controlId="AddAbsenceForm.txtStartOn" className="mb-2">
                                                                            <Form.Label> {t('absenceTab.newAbsenMdl.startsOn')}</Form.Label>
                                                                            <div><DatePicker className="form-control" selected={new Date(formik.values.startDate)} name="startDate" onChange={(date) => { formik.setFieldValue("startDate", !!date ? date : new Date()); setShowSaveButton(true) }} /></div>
                                                                        </Form.Group>
                                                                        {formik.values.isallday === false ?
                                                                            <Form.Group controlId="AddAbsenceForm.txtStartTime">
                                                                                <div className="flexInputGroup d-flex align-items-center ">
                                                                                    <Form.Control as="select" className="Noarrow bgPrimaryGrey form-select" value={StartTimeHour} onChange={(e) => { setStartTimeHour(e.target.value); setShowSaveButton(true) }} custom>
                                                                                        {
                                                                                            Constants.hoursDropDown.map((val, key) => {
                                                                                                return <option key={"StartTimeHour" + key} value={val.value}>{val.text}</option>
                                                                                            })
                                                                                        }
                                                                                    </Form.Control>
                                                                                    <span className="seperator mx-2">:</span>
                                                                                    <Form.Control as="select" className="Noarrow bgPrimaryGrey form-select" value={StartTimeMin} onChange={(e) => { setStartTimeMin(e.target.value); setShowSaveButton(true) }} custom>
                                                                                        {
                                                                                            Constants.minutesDropDown.map((val, key) => {
                                                                                                return <option key={"StartTimeMin" + key} value={val.value}>{val.text}</option>
                                                                                            })
                                                                                        }
                                                                                    </Form.Control>
                                                                                </div>
                                                                            </Form.Group> : null}

                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <Form.Group controlId="AddAbsenceForm.txtEndsat" className="mb-2">
                                                                            <Form.Label> {t('absenceTab.newAbsenMdl.endsAt')}</Form.Label>
                                                                            <div><DatePicker className="form-control" selected={new Date(formik.values.endDate)} name="endDate" onChange={(date) => { formik.setFieldValue("endDate", !!date ? date : new Date()); setShowSaveButton(true) }} /></div>
                                                                            {/* <div><DatePicker className="w-100" selected={new Date(formik.values.endDate)} name="endDate" value={new Date(formik.values.endDate)} onChange={(date) => formik.setFieldValue("endDate", date)} /></div> */}
                                                                        </Form.Group>
                                                                        {formik.values.isallday === false ?
                                                                            <Form.Group controlId="NewShiftForm.txtEndTime">
                                                                                <div className="flexInputGroup d-flex align-items-center">
                                                                                    <Form.Control as="select" className="Noarrow bgPrimaryGrey form-select" value={EndTimeHour} onChange={(e) => { setEndTimeHour(e.target.value); setShowSaveButton(true) }} custom>
                                                                                        {
                                                                                            Constants.hoursDropDown.map((val, key) => {
                                                                                                return <option key={"EndTimeHour" + key} value={val.value}>{val.text}</option>
                                                                                            })
                                                                                        }
                                                                                    </Form.Control>
                                                                                    <span className="seperator mx-2">:</span>
                                                                                    <Form.Control as="select" className="Noarrow bgPrimaryGrey form-select" value={EndTimeMin} onChange={(e) => { setEndTimeMin(e.target.value); setShowSaveButton(true) }} custom>
                                                                                        {
                                                                                            Constants.minutesDropDown.map((val, key) => {
                                                                                                return <option key={"EndTimeMin" + key} value={val.value}>{val.text}</option>
                                                                                            })
                                                                                        }
                                                                                    </Form.Control>
                                                                                </div>

                                                                            </Form.Group>
                                                                            : null}
                                                                    </Col>
                                                                </Row>
                                                            </Card.Body>
                                                            <Card.Footer>
                                                                <Form.Group className="mb-0">
                                                                    <Form.Check className="smallTextControl" inline label={t('absenceTab.newAbsenMdl.allDayLabel')} type="checkbox" id="AnsenceAllDay" name="isallday" checked={formik.values.isallday} onChange={() => { formik.setFieldValue("isallday", !formik.values.isallday); setShowSaveButton(true) }} />
                                                                </Form.Group>
                                                            </Card.Footer>
                                                        </>
                                                        :
                                                        <>
                                                            <Card.Body>
                                                                <Row>
                                                                    <Col md={12}>
                                                                        {new Date(formik.values.startDate).toLocaleDateString()} - {new Date(formik.values.endDate).toLocaleDateString()}
                                                                    </Col>
                                                                </Row>
                                                            </Card.Body>
                                                        </>
                                                }
                                            </Card>
                                        </Col>
                                        <Col md={12} className="px-0 pb-3">
                                            <Card>
                                                <Card.Header> {t('absenceTab.newAbsenMdl.billing')}</Card.Header>
                                                <Card.Body>
                                                    <Row>
                                                        <Col md={6}>
                                                            {
                                                                formik.values.isEnforceoffsetting && (props.modalType === "add" || props.modalType === Constants.absenceStatus.Pending) ?
                                                                    <Form.Group className="mb-0" controlId="AddAbsenceForm.txtCorrected hours">
                                                                        <Form.Label> {t('absenceTab.newAbsenMdl.correctedHour')}</Form.Label>
                                                                        <Form.Control type="number" value={formik.values.correctedhours} name="correctedhours" onChange={(e) => {
                                                                            const value = parseFloat(e.target.value);
                                                                            if (value >= 0 || e.target.value === "") {
                                                                                formik.handleChange(e);
                                                                                setShowSaveButton(true);
                                                                            }
                                                                        }} />
                                                                    </Form.Group>
                                                                    :
                                                                    <Form.Group className="mb-0" controlId="AddAbsenceForm.txtStartOn">
                                                                        <Form.Label> {t('absenceTab.newAbsenMdl.correctedHour')}</Form.Label>
                                                                        <div className="CounterText">{formik.values.correctedhours}</div>
                                                                    </Form.Group>
                                                            }
                                                        </Col>
                                                        <Col md={6}>
                                                            {
                                                                formik.values.isEnforceoffsetting && (props.modalType === "add" || props.modalType === Constants.absenceStatus.Pending) ?
                                                                    <Form.Group className="mb-0" controlId="AddAbsenceForm.txtHoliday">
                                                                        <Form.Label> {t('absenceTab.newAbsenMdl.holiday')}</Form.Label>
                                                                        <Form.Control type="number" value={formik.values.holidays} name="holidays" onChange={(e) => {
                                                                            const value = parseFloat(e.target.value);
                                                                            if (value >= 0 || e.target.value === "") {
                                                                                formik.handleChange(e);
                                                                                setShowSaveButton(true);
                                                                            }
                                                                        }} />
                                                                    </Form.Group>
                                                                    :
                                                                    <Form.Group className="mb-0" controlId="AddAbsenceForm.txtStartOn">
                                                                        <Form.Label> {t('absenceTab.newAbsenMdl.holiday')}</Form.Label>
                                                                        <div className="CounterText">{formik.values.holidays}</div>
                                                                    </Form.Group>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                                {
                                                    props.modalType === "add" || props.modalType === Constants.absenceStatus.Pending ?
                                                        <Card.Footer>
                                                            <Form.Group className="mb-0">
                                                                <Form.Check className="smallTextControl text-danger" inline label={t('absenceTab.newAbsenMdl.smallerTextForceLabel')} type="checkbox" id="AnsenceAllDay" checked={formik.values.isEnforceoffsetting} onChange={() => { formik.setFieldValue("isEnforceoffsetting", !formik.values.isEnforceoffsetting); setShowSaveButton(true) }} />
                                                            </Form.Group>
                                                        </Card.Footer>
                                                        : null
                                                }
                                            </Card>
                                        </Col>
                                        {
                                            props.modalType === "add" || props.modalType === Constants.absenceStatus.Pending ?
                                                <Col md={12} className="px-0 pb-3">
                                                    <Card border="warning">
                                                        <Card.Header className="WarningHeader"> {t('absenceTab.newAbsenMdl.additionalInformation')}</Card.Header>
                                                        <Card.Body>
                                                            <Row>
                                                                <Col md={12}>
                                                                    <span className="AdditionText borderRightThick"> {t('absenceTab.newAbsenMdl.suggestion')}:
                                                                        {suggestedCorrectedhours} {t('absenceTab.newAbsenMdl.hours')} <FaRegCheckCircle className="Icon" />
                                                                    </span>
                                                                    <span className="AdditionText">
                                                                        {suggestedHolidays} {t('absenceTab.newAbsenMdl.days')} <FaRegCheckCircle className="Icon" />
                                                                    </span>
                                                                </Col>
                                                                <Col md={12}>
                                                                    <span className="AdditionText"> {t('absenceTab.newAbsenMdl.occupiedShifts')}: {OccupiedShifts},</span>
                                                                    <span className="AdditionText"> {t('absenceTab.newAbsenMdl.applications')}: {applications}</span>
                                                                </Col>

                                                            </Row>
                                                        </Card.Body>

                                                    </Card>
                                                </Col>
                                                : null
                                        }
                                    </Row>
                                </Container>

                            </Col>
                            <Col md={6} className="position-relative">

                                <div className="AbsenceInfoSection">
                                    {
                                        !!props.absenceIdForEdit ?
                                            <NotesModal objIdforNote={props.absenceIdForEdit} noteEntity="Absence" />
                                            :
                                            <div className="d-flex w-100 justify-content-center ptSetControl40 liftIndexUp text-wrap"> {t('absenceTab.newAbsenMdl.absenceInfoSectionPara')}.</div>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {
                        !!props.absenceIdForEdit && props.modalType === Constants.absenceStatus.Pending ?
                            <>
                                <div>
                                    {
                                        // AllowDelation === true && currentView === Constants.layoutType.Admin ?
                                        AllowDelation === true &&
                                        <Button onClick={() => deleteAbsence()}>
                                            {t('absenceTab.newAbsenMdl.deleteAbsence')}
                                        </Button>
                                    }
                                </div>
                                <div>
                                    {
                                        showSaveButton === true ?
                                            <Button className="btn btnPrimaryGreen mx-2" onClick={() => CheckOverLapping()} ><FaCheck className="me-2" />{ct('save')}</Button>
                                            :
                                            HaveAccessToConfirm &&
                                            <Button className="btn btnPrimaryGreen mx-2" onClick={() => CheckOverLapping()} ><FaCheck className="me-2" />{ct('toConfirm')}</Button>
                                    }
                                    {showSaveButton === false && currentView === Constants.layoutType.Admin ?
                                        <Button className="btn btn-danger mx-2" onClick={() => OnCancle()} ><FaTimes className="me-2" /> {ct('cancel')}</Button>
                                        : null
                                    }
                                </div>
                            </>
                            :
                            !!props.absenceIdForEdit && (props.modalType === Constants.absenceStatus.Rejected || props.modalType === Constants.absenceStatus.Confirmed) ?
                                user.roles.some(x => x === Constants.roles.AccountAdmin || x === Constants.roles.WorkspaceAdmin || x === Constants.roles.Admin)
                                    || (isEmployeesResetAbsences === true) ?
                                    <Button className="btn btn-secondary" onClick={(e) => changestatus("P")}><FaUndo className="me-2" /> {t('absenceTab.undoTooltip')}</Button>
                                    : null
                                :
                                <Button className="btn btnPrimaryGreen" onClick={formik.handleSubmit}> {ct('finished')}</Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}
export function OverLappingAbseneceModel(props) {
    const { t: apt } = useTranslation('absencePage');
    const { t: ct } = useTranslation('common');

    return (
        <>
            <Modal show={props.show} className="largeModalDialog" onHide={() => props.setShow(false)}>
                <Modal.Header closeButton className="position-relative">
                    <Modal.Title className="text-break"> {apt('overLapAbseneceMdl.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table hover responsive className="DataGridTable">
                        <thead>
                            <tr>
                                <th></th>
                                <th>{ct('employee')}</th>
                                <th>{apt('overLapAbseneceMdl.timePeriod')}</th>
                                <th>{apt('overLapAbseneceMdl.description')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.dataList.length > 0 ?
                                    props.dataList.map((value, key) => {

                                        return <tr className='text-left'>
                                            <td>{value.status}</td>
                                            <td>{value.employeeName}</td>
                                            <td>{value.timeperiod}</td>
                                            <td>{value.description}</td>
                                        </tr>

                                    }) : null
                            }
                        </tbody>

                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btnPrimaryGreen" onClick={() => props.setShow(false)}> {ct('cancel')}</Button>
                    <Button className="btn btnPrimaryGreen" onClick={() => props.onsubmit()}>Ok</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
function NoQuotaOrConsumedModal(props) {
    const { t: apt } = useTranslation('absencePage');


    const navigate = useNavigate();
    const user = useSelector(x => x.app.user);
    const navigateToprofile = () => {
        navigate('/companies/' + user.CompanyId + '/users/' + props.SelectedEmployeeId + '/vacations')
    }
    return (
        <>
            <Modal show={props.show} className="largeModalDialog" onHide={() => props.setShow()}>
                <Modal.Header closeButton className="">
                    <Modal.Title>{apt('noQuotaMdl.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6> {apt('noQuotaMdl.desc')}:</h6>
                    <p>
                        {
                            new Date(props.tryToconfirmAbsenceDate).toLocaleDateString()
                        }
                    </p>
                    <p>
                        <a href="#" onClick={() => navigateToprofile()}> {apt('noQuotaMdl.setupEntitlementLink')}</a>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-success mx-2" onClick={() => props.setShow()} >Ok</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}