import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useDispatch } from 'react-redux';
import { ChooseAll, OptionBox, OptionText, ShowItem, CheckBox } from '../Helpers/Global/StyledComponents';
import { setSelectedTags, setSelectedWorkAreas } from '../Store/slices/AppSlice';
import { useSelector } from 'react-redux';
import { FaRegEdit, FaTrashAlt } from 'react-icons/fa';
import { useEffect } from 'react';
import { CallAxios } from '../Helpers';
import { toast } from 'react-toastify';
import { UpdateReduxStates } from '../Helpers/MasterData';
import { SignInManager } from '../Helpers/Authentication/SignInManager';
import { Form, Modal } from 'react-bootstrap';
import { Constants } from '../Helpers/Contents';
import { useTranslation } from "react-i18next";


function WorkAreaCheckSelectFilter(props) {
    const { t: ct } = useTranslation('common');


    const selectedOption = useSelector(s => s.app.selectedWorkAreas);
    const appSettings = useSelector(s => s.app.appSettings);
    const listoptions = appSettings.WorkSpaces;
    const user = useSelector(x => x.app.user)
    const dispatch = useDispatch();

    const [WorkAreaList, setWorkAreaList] = useState([]);
    const [workspace, setworkspace] = useState("");
    const [deleteModal, setDeleteModal] = useState(false);
    const [WorkSpaceIdforDel, setWorkSpaceIdforDel] = useState("");
    const [Newarea, setNewarea] = useState("");
    const [workspaceEditname, setWorkspaceEditname] = useState("");
    useEffect(() => {
        var list = listoptions.map(x => {
            return { ...x, isEditable: false }
        })
        setWorkAreaList(list)
    }, [listoptions]);
    function handleItemClick(val) {
        const findobj = selectedOption.find(x => x.value === val.value);
        const index = selectedOption.indexOf(findobj);
        if (index === -1) {
            dispatch(
                setSelectedWorkAreas([...selectedOption, val])
            );
        } else {
            dispatch(
                setSelectedWorkAreas(selectedOption.filter(x => x !== findobj))
            );
        }
    }
    const handleChooseAllClick = () => {
        if (listoptions.length > 0) {
            if (selectedOption.length === listoptions.length) {
                dispatch(
                    setSelectedWorkAreas([])
                );
            } else {
                var tempOptions = []
                listoptions.map((val, key) => {
                    const index = selectedOption.indexOf(val);
                    if (index === -1) {
                        tempOptions.push(val);
                    }
                })
                dispatch(
                    setSelectedWorkAreas([...selectedOption, ...tempOptions])
                );
            }
        }
    }
    const showEditableArea = (index, e) => {
        e.preventDefault();
        e.stopPropagation();
        let prevState = [...WorkAreaList];
        let newarr = prevState.map(x => {
            return { ...x, isEditable: false }
        })
        newarr[index].isEditable = true;
        setWorkAreaList(newarr);
    }
    const updateList = (key, val) => {

        var prev = [...WorkAreaList];
        prev[key].label = val;
        setWorkAreaList(prev)
        setworkspace(val)
    }
    const removeEditMode = async (workingAreaId) => {
        if (!!workingAreaId && !!workspace) {
            var data = {
                id: workingAreaId,
                workspaceName: workspace
            }
            var response = await CallAxios("/api/LocationSetting/addeditWorkSpaceFromFilter|post", data)

            if (!!response && response.status === 200) {
                if (response.data !== true) {
                    toast.error('Title has already been taken')
                } else {
                    await UpdateReduxStates(new SignInManager().ParseJwt, dispatch)
                }
            }
        } else {
            let prevState = [...WorkAreaList];
            let newarr = prevState.map(x => {
                if (x.isEditable === false) {
                    return { ...x }
                } else {
                    return { ...x, label: workspaceEditname, isEditable: false }
                }
            })
            setWorkAreaList(newarr);
        }

    }
    const AddNewArea = async (e) => {
        if (e.keyCode == 13 && !!Newarea) {
            var data = {
                workspaceName: Newarea
            }
            var response = await CallAxios("/api/LocationSetting/addeditWorkSpaceFromFilter|post", data)

            if (!!response && response.status === 200) {
                if (response.data !== true) {
                    toast.error('Title has already been taken')
                } else {
                    setNewarea("")
                    await UpdateReduxStates(new SignInManager().ParseJwt, dispatch)
                }
            }
        }
        if (e.keyCode == 32) {
            setworkspace(Newarea + " ")
        }
    }
    const toggleDropdown = async (e) => {
        if (!e) {
            removeEditMode()
        }
    }
    return (
        <>
            {
                deleteModal &&
                <DeleteWorkingAreaConfirmationModal WorkSpaceIdforDel={WorkSpaceIdforDel} show={deleteModal} handleClose={setDeleteModal} />
            }

            <DropdownButton onToggle={(e) => toggleDropdown(e)} autoClose="outside" className={props.autoWidth ? 'check-select autoWidth' : 'check-select'} title={selectedOption.length > 0 ? selectedOption.map((item) => item.label).join(",") : <>{ct('workingAreas')}</>}>
                <Dropdown.Item className="choose-all">
                    <ChooseAll onClick={handleChooseAllClick}>
                        {selectedOption.length === listoptions.length ? <>{ct('selectNone')}</> : <>{ct('selectAll')}</>}
                    </ChooseAll>
                </Dropdown.Item>
                {(WorkAreaList || []).map((item, index) =>
                    <>
                        {
                            // !!item.label ?
                            item.isEditable === true ?
                                <Dropdown.Item>
                                    <div className="d-flex justify-content-between align-items-center text-left" key={index}>
                                        <Form.Control value={item.label} onChange={(e) => updateList(index, e.target.value)} onKeyUp={(e) => e.keyCode === 13 ? removeEditMode(item.value) : null} type="text" />
                                        <div className="px-2">
                                            <FaTrashAlt onClick={(e) => { setDeleteModal(true); setWorkSpaceIdforDel(item.value) }} />
                                        </div>
                                    </div>
                                </Dropdown.Item>
                                :
                                <>
                                    <Dropdown.Item onClick={() => handleItemClick(item)} key={index}>
                                        <OptionBox>
                                            <CheckBox type="checkbox" disablePointerEvent={true} checked={(selectedOption.some(x => x.value === item.value))} size="sm" />
                                            <OptionText>{item.label}</OptionText>
                                            <FaRegEdit style={{ "marginLeft": "10px" }} onClick={(e) => { showEditableArea(index, e); setworkspace(item.label); setWorkspaceEditname(item.label) }} className="IconHoverShow" />
                                        </OptionBox>
                                    </Dropdown.Item>
                                </>
                            // : null
                        }
                    </>
                )}
                {
                    user.roles.some(x => x === Constants.roles.WorkspaceAdmin || x === Constants.roles.AccountAdmin) &&
                    <Dropdown.Item>
                        <div className="my-2">
                            <Form.Control placeholder={ct('workAreaSelectFilter.newArea')} value={Newarea} onChange={(e) => setNewarea(e.target.value)} onKeyUp={(e) => { AddNewArea(e) }} type="text" />
                        </div>
                    </Dropdown.Item>
                }
            </DropdownButton>
        </>
    );
}
export default WorkAreaCheckSelectFilter;


function DeleteWorkingAreaConfirmationModal(props) {
    const { t: ct } = useTranslation('common');

    const dispatch = useDispatch();

    const deleteworkspace = async () => {
        var data = {
            id: props.WorkSpaceIdforDel
        }
        var response = await CallAxios("/api/LocationSetting/DeleteSpaceStatus|post", data)

        if (!!response && response.status === 200) {
            await UpdateReduxStates(new SignInManager().ParseJwt, dispatch)
            props.handleClose(false)
        }
    }

    return (
        <>
            <Modal show={props.show} onHide={() => props.handleClose(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> {ct('workAreaSelectFilter.deleteWorkTitle')}</Modal.Title>
                </Modal.Header>
                <Modal.Body> {ct('workAreaSelectFilter.deleteWorkPara')}</Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={() => props.handleClose(false)}>
                        {ct('cancel')}
                    </button>
                    <button className="btn btn-danger" onClick={() => deleteworkspace()}>
                        {ct('yesDeleteNow')}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )

}

