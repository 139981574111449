import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { AxiosLite, CallAxios } from "../../../Helpers";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { BsCaretDownFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";

export default function SendRosterPlanModal(props) {
    const { t: cl } = useTranslation('calendarPage');
    const { t: ct } = useTranslation('common');



    const [WorkspaceList, setWorkspaceList] = useState();
    const [selectedArea, setSelectedArea] = useState([]);
    const [message, setMessage] = useState("");
    const [MessageFile, setMessageFile] = useState("");
    const [filetype, setFiletype] = useState("");
    useEffect(() => {
        onLoad()
    }, []);
    const onLoad = async () => {
        var response = await AxiosLite("/api/LocationSetting/getWorkSpaceDD|get");
        if (!!response && response.status === 200) {
            setWorkspaceList(response.data);
        }
    }
    const finished = async () => {
        if (selectedArea.length > 0) {
            var data = {
                starDate: props.calendar.current.activeRange.start.toUTCString(),
                endDate: props.calendar.current.activeRange.end.toUTCString(),
                WorkAreas: selectedArea,
                Message: message,
                file: MessageFile,
                fileType: filetype
            }
            var form_data = new FormData();
            for (var key in data) {
                if (typeof (data[key]) === "object" && !!data[key] && data[key].length > 0) {
                    for (var i = 0; i < data[key].length; i++) {
                        form_data.append(key + [], data[key][i]);
                    }
                } else {
                    form_data.append(key, data[key]);
                }
            }
            var response = await CallAxios("/api/Calendar/SendShiftsPlanToWorkAreaAllEmployee|post", form_data)
            if (!!response && response.status === 200) {
                props.onHide(false)
            }
        }
    }
    const showResult = (file) => {
        setFiletype(file.name)
        fileDataURL(file)
    }
    const fileDataURL = file => new Promise((resolve, reject) => {
        let fr = new FileReader();
        fr.onload = () => resolve(fr.result);
        fr.onerror = reject;
        fr.readAsDataURL(file)
    });
    return (
        <Modal show={props.show} onHide={() => props.onHide(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{cl("messageWorkArea")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={12} >
                        <div className="CustomMultiselectDropdownWrapper">
                            <div className="w-1/2 h-16  text-black-100 flex items-center justify-center text-xl">
                                <SearchBar data={WorkspaceList} selected={selectedArea} setval={setSelectedArea} />
                            </div>
                        </div>
                    </Col>
                    <Col md={12}>
                        <Form.Group controlId="SendPlanForm.txtmessage">
                            <Form.Control as="textarea" rows={2} onChange={(e) => setMessage(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <Form.Group>
                            <input type='file' onChange={(e) => {
                                setMessageFile(e.target.files[0])
                                showResult(e.target.files[0])
                            }
                            } label={ct("attachment")} />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btnPrimaryGreen" onClick={() => finished()}>{ct("finished")}</Button>
            </Modal.Footer>
        </Modal>
    )
}
const SearchBar = (props) => {
    const { t: ct } = useTranslation('common');



    const wrapperRef = useRef(null);
    const [expanded, setExpanded] = useState(false);
    const [selections, setSelections] = useState([]);

    const toggleExpanded = () => {
        if (!expanded) {
            setExpanded(true);
        } else {
            setExpanded(false);
        }
    };


    // below is the same as componentDidMount and componentDidUnmount
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);

    const handleClickOutside = event => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setExpanded(false);
        }
    };

    const handleChange = event => {
        if (event.target.checked) {
            props.setval([...props.selected, event.target.value])
            return setSelections([...selections, event.target.value]);
        } else {
            const filtered = selections.filter(name => name !== event.target.value);
            props.setval(filtered)
            return setSelections(filtered);
        }
    };

    const handleSubmit = e => {
        e.preventDefault();
        toggleExpanded();
    };

    return (
        <form onSubmit={handleSubmit} className="w-full">
            <div className="ModalMultiselectDropdownContainer" ref={wrapperRef}>
                <div className="CustomMultiselectDropdown" onClick={toggleExpanded}>
                    <div>{ct("group")}</div>
                    <BsCaretDownFill className="ArrowIcon" />
                </div>
                {expanded && (
                    <div className="ModalMultiselectDDMenu">
                        {
                            props.data.map((workingAreaItem, i) => (
                                <label className="CustomCheckbox" key={i}>
                                    <input
                                        type="checkbox"
                                        name={workingAreaItem.label}
                                        value={workingAreaItem.key}
                                        onChange={handleChange}
                                        className="m-3 cursor-pointer"
                                        checked={props.selected.includes(workingAreaItem.key) ? true : false}

                                    />
                                    {workingAreaItem.label}

                                    <span className="checkmark"></span>

                                </label>
                            ))
                        }
                    </div>
                )}
            </div>

        </form>
    );
};