import React, { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { BiPurchaseTag } from 'react-icons/bi';
import { BsSearch } from 'react-icons/bs';
import { FaFileInvoiceDollar, FaRegCalendarAlt, FaRegRegistered } from "react-icons/fa";
import { FiUserX } from "react-icons/fi";
import { HiOutlineClock, HiOutlineLogout, HiOutlineUserGroup } from "react-icons/hi";
import { IoMdSettings } from 'react-icons/io';
import { MdImportExport, MdLocationOn } from 'react-icons/md';
import { Menu as ReactProMenu, MenuItem as ReactProMenuItem, menuClasses } from 'react-pro-sidebar';
import { useSelector } from 'react-redux';
import { NavLink, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { SignInManager } from '../../Helpers/Authentication/SignInManager';
import { Constants } from '../../Helpers/Contents';
import { useTranslation } from "react-i18next";


const Menubox = styled(ReactProMenu)`
margin-top: 20px;
`;
const Menuitem = styled(NavLink)`
    display: block;
    text-decoration: none;
    color: #eee;
    cursor: pointer;
    text-align:left;
    // border-bottom:1px solid #8dc8bb;
    &:hover{
        color:#fff !important;
    }
    &.active .menu-item-box{
        background-color: ${props => props.active};
        border-radius: 12px 0px 0px 12px;
        color: #fff !important;
    }
`;

const Menuitemicon = styled.span`
display: inline-block;
    margin-right: 10px;
`;

const Menu = () => {
    const { t: ht } = useTranslation('header');

    const user = useSelector(x => x.app.user)
    const appSettings = useSelector(x => x.app.appSettings)
    const currentView = useSelector(s => s.app.currentView);

    const [menuList, setMenuList] = useState([]);
    useEffect(() => {
        if (!!currentView && appSettings.Modules.length > 0) {
            var List = [
                // {
                //     link: '/dashboard',
                //     icon: <RxDashboard size={17} />,
                //     linkTex: 'Dashboard'
                // }
            ]
            if (appSettings.Modules?.split(",").some(x => x === Constants.SubscriptionModulesConstants.ShiftPlanner) && ((currentView === Constants.layoutType.Employee && appSettings.employeeAccess.canSeeCalender === true) || currentView === Constants.layoutType.Admin)) {
                List.push({
                    link: `/companies/${user.CompanyId}/calendar`,
                    icon: <FaRegCalendarAlt size={17} />,
                    linkTex: 'calendar'
                })
            }
            if (currentView === Constants.layoutType.Admin) {
                List.push({
                    link: `/companies/${user.CompanyId}/users`,
                    icon: <HiOutlineUserGroup size={17} />,
                    linkTex: 'employees'
                })
            }
            if (appSettings.Modules?.split(",").some(x => x === Constants.SubscriptionModulesConstants.Absences) && ((currentView === Constants.layoutType.Employee && appSettings.employeeAccess.canSeeabsences === true) || currentView === Constants.layoutType.Admin)) {
                List.push({
                    link: `/companies/${user.CompanyId}/absences`,
                    icon: <FiUserX size={17} />,
                    linkTex: 'absences'
                })
            }
            if (appSettings.Modules?.split(",").some(x => x === Constants.SubscriptionModulesConstants.TimeTracking) && ((currentView === Constants.layoutType.Employee && appSettings.employeeAccess.canSeetimetracking === true) || currentView === Constants.layoutType.Admin)) {
                List.push({
                    link: `/companies/${user.CompanyId}/timetracking`,
                    icon: <HiOutlineClock size={17} />,
                    linkTex: 'timeTrackings'
                })
            }
            if (currentView === Constants.layoutType.Admin) {
                List.push({
                    link: `/companies/${user.CompanyId}/recurrences`,
                    icon: <FaRegRegistered size={17} />,
                    linkTex: 'recurrences'
                })
            }
            setMenuList(List)
        }
    }, [currentView, appSettings]);
    const absenceCounterBadge = useSelector(x => x.app.absenceCounterBadge)
    const pendingTimetrackCount = useSelector(x => x.app.pendingTimetrackCount)
    const runningTimetrackCount = useSelector(x => x.app.runningTimetrackCount)
    const themeColorActive = useSelector(state => state.app.theme.colors.active);
    const handleMenuItemClick = (index) => {
        var tempList = [...menuList];
        for (var i = 0; i < tempList.length; i++) {
            tempList[i].isActive = false;
        }
        tempList[index].isActive = true;
        setMenuList(tempList)
    }


    return (
        <>
            <Menubox
                menuItemStyles={{
                    button: {
                        // the active class will be added automatically by react router
                        // so we can use it to style the active menu item
                        [`&.active`]: {
                            backgroundColor: themeColorActive,
                            color: '#fff',

                        },
                    },
                }}
            >
                {menuList.map((item, index) => {
                    return <>
                        <ReactProMenuItem
                            rootStyles={{
                                ['.' + menuClasses.button]: {
                                    backgroundColor: 'transparent',
                                    color: '#fff !important',
                                    borderTopLeftRadius: '15px',
                                    borderBottomLeftRadius: '15px',
                                    marginTop: "10px",
                                    '&:hover': {
                                        backgroundColor: `${themeColorActive} !important`,
                                    },
                                },
                            }}
                            icon={
                                <Menuitemicon className={`menu-item-icon ${item.linkTex === "timeTrackings" ? "time-tracking" : ""}`}>

                                    {
                                        (item.linkTex === "timeTrackings" && runningTimetrackCount > 0) &&
                                        <Badge style={{ "marginLeft": "10px" }} bg="success">{runningTimetrackCount}</Badge>
                                    }
                                    {item.icon}
                                    {
                                        (item.linkTex === "timeTrackings" && pendingTimetrackCount > 0) &&
                                        <Badge style={{ "marginLeft": "10px" }} bg="danger">{pendingTimetrackCount}</Badge>
                                    }

                                </Menuitemicon>
                            }
                            component={<Menuitem active={themeColorActive} to={item.link} onClick={() => handleMenuItemClick(index)} />}


                        >
                            {ht(item.linkTex)}
                            {
                                (item.linkTex === "absences" && absenceCounterBadge > 0) &&
                                <Badge style={{ "marginLeft": "10px" }} bg="danger">{absenceCounterBadge}</Badge>
                            }

                        </ReactProMenuItem>
                    </>
                })}
            </Menubox>
        </>
    );
}

export const AccountMenu = () => {
    const { t: ht } = useTranslation('header');

    const user = useSelector(x => x.app.user)
    const appSettings = useSelector(x => x.app.appSettings)
    const [menuList, setMenuList] = useState([]);
    useEffect(() => {
        setMenuList(
            [
                {
                    link: `account/${user.CompanyId}/accountsettings/master_data`,
                    icon: <IoMdSettings size={19} />,
                    linkTex: 'settings',
                    isVisible: user.roles.some(x => x === Constants.roles.AccountAdmin),
                    hasAccess: appSettings.SubscriptionStatus == Constants.SubscriptionStatus.Paid
                },
                {
                    link: `account/${user.CompanyId}/search`,
                    icon: <BsSearch size={17} />,
                    linkTex: 'search',
                    isVisible: user.roles.some(x => x === Constants.roles.AccountAdmin),
                    hasAccess: appSettings.SubscriptionStatus == Constants.SubscriptionStatus.Paid
                },
                {
                    link: `account/${user.CompanyId}/exchange`,
                    icon: <MdImportExport size={17} />,
                    linkTex: 'exportsImports',
                    isVisible: user.roles.some(x => x === Constants.roles.AccountAdmin),
                    hasAccess: appSettings.SubscriptionStatus == Constants.SubscriptionStatus.Paid
                },
                {
                    link: `account/${user.CompanyId}/locationoverview`,
                    icon: <MdLocationOn size={19} />,
                    linkTex: 'locations',
                    isVisible: user.roles.some(x => x === Constants.roles.AccountAdmin),
                    hasAccess: appSettings.SubscriptionStatus == Constants.SubscriptionStatus.Paid
                },
                {
                    link: `account/${user.CompanyId}/invoices`,
                    icon: <FaFileInvoiceDollar size={19} />,
                    linkTex: 'invoices',
                    isVisible: user.roles.some(x => x === Constants.roles.AccountAdmin),
                    hasAccess: true
                },
                {
                    link: `account/${user.CompanyId}/subscriptions`,
                    icon: <BiPurchaseTag size={19} />,
                    linkTex: 'subscription',
                    isVisible: true,
                    hasAccess: true
                },
                // {
                //     link: `account/${user.CompanyId}/subscriptions`,
                //     icon: <MdLocationOn size={19} />,
                //     linkTex: 'Logout'
                // },
            ]
        )
    }, [appSettings]);
    const absenceCounterBadge = useSelector(x => x.app.absenceCounterBadge)
    const themeColorActive = useSelector(state => state.app.theme.colors.active);

    const navigate = useNavigate();
    const handleMenuItemClick = (index) => {
        var tempList = [...menuList];
        for (var i = 0; i < tempList.length; i++) {
            tempList[i].isActive = false;
        }
        tempList[index].isActive = true;
        setMenuList(tempList)
    }


    return (
        <>
            <Menubox
                menuItemStyles={{
                    button: {
                        // the active class will be added automatically by react router
                        // so we can use it to style the active menu item
                        [`&.active`]: {
                            backgroundColor: themeColorActive,
                            color: '#fff',

                        },
                    },
                }}
            >
                {menuList.map((item, index) => {
                    return <>
                        {
                            (item.hasAccess && item.isVisible) &&
                            <ReactProMenuItem
                                key={index}
                                rootStyles={{
                                    ['.' + menuClasses.button]: {
                                        backgroundColor: 'transparent',
                                        color: '#fff !important',
                                        borderTopLeftRadius: '15px',
                                        borderBottomLeftRadius: '15px',
                                        marginTop: "10px",
                                        '&:hover': {
                                            backgroundColor: `${themeColorActive} !important`,
                                        },
                                    },
                                }}
                                icon={
                                    <Menuitemicon className='menu-item-icon'>
                                        {item.icon}
                                    </Menuitemicon>
                                }
                                component={<Menuitem active={themeColorActive} to={item.link} onClick={() => handleMenuItemClick(index)} />}


                            >
                                {ht(item.linkTex)}


                            </ReactProMenuItem>
                        }
                    </>
                })}
                <ReactProMenuItem

                    rootStyles={{
                        ['.' + menuClasses.button]: {
                            backgroundColor: 'transparent',
                            color: '#fff !important',
                            borderTopLeftRadius: '15px',
                            borderBottomLeftRadius: '15px',
                            marginTop: "10px",
                            '&:hover': {
                                backgroundColor: `${themeColorActive} !important`,
                            },
                        },
                    }}
                    icon={
                        <Menuitemicon className='menu-item-icon'>
                            <HiOutlineLogout size={19} />
                        </Menuitemicon>
                    }
                    component={<Menuitem active={themeColorActive} to="" onClick={(e) => { e.preventDefault(); new SignInManager().Logout(navigate) }} />}


                >
                    {ht('logout')}


                </ReactProMenuItem>
            </Menubox>
            {/* <Menubox>
                {menuList.map((item, index) => {
                    return <>
                        <Menuitem active={themeColorActive} to={item.link} onClick={() => handleMenuItemClick(index)}>
                            <MenuItemBox className='menu-item-box'>
                                <Menuitemicon className='menu-item-icon'>
                                    {item.icon}
                                </Menuitemicon>
                                <MenuitemText>
                                    {item.linkTex}
                                </MenuitemText>
                                {
                                    item.linkTex === "Absence" ?
                                        <Badge style={{ "marginLeft": "10px" }} bg="danger">{absenceCounterBadge}</Badge>
                                        : null
                                }
                            </MenuItemBox>
                        </Menuitem>
                    </>
                })}
                <Menuitem to="" onClick={(e) => { e.preventDefault(); new SignInManager().Logout(navigate) }}>
                    <MenuItemBox className='menu-item-box'>
                        <Menuitemicon className='menu-item-icon'>
                            <MdLocationOn size={19} />
                        </Menuitemicon>
                        <MenuitemText>
                            Logout
                        </MenuitemText>
                    </MenuItemBox>
                </Menuitem>
            </Menubox> */}
        </>
    );
}
export default Menu;


