export const TimeZoneList = [
    {

        value: "Dateline Standard Time",
        label:
            "(UTC-12:00) Internationale Datumsgrenze (Westen)"

    },
    {

        value: "UTC-11",
        label:
            "(UTC-11:00) Koordinierte Weltzeit-11"

    },
    {

        value: "Aleutian Standard Time",
        label:
            "(UTC-10:00) Aleuten-Inseln"

    },
    {

        value: "Hawaiian Standard Time",
        label:
            "(UTC-10:00) Hawaii"

    },
    {

        value: "Marquesas Standard Time",
        label:
            "(UTC-09:30) Marquesas-Inseln"

    },
    {

        value: "Alaskan Standard Time",
        label:
            "(UTC-09:00) Alaska"

    },
    {

        value: "UTC-09",
        label:
            "(UTC-09:00) Koordinierte Weltzeit-09"

    },
    {

        value: "UTC-08",
        label:
            "(UTC-08:00) Koordinierte Weltzeit-08"

    },
    {

        value: "Pacific Standard Time (Mexico)",
        label:
            "(UTC-08:00) Niederkalifornien"

    },
    {

        value: "Pacific Standard Time",
        label:
            "(UTC-08:00) Pacific Zeit (USA &amp; Kanada)"

    },
    {

        value: "US Mountain Standard Time",
        label:
            "(UTC-07:00) Arizona"

    },
    {

        value: "Mountain Standard Time (Mexico)",
        label:
            "(UTC-07:00) La Paz, Mazatlan"

    },
    {

        value: "Mountain Standard Time",
        label:
            "(UTC-07:00) Mountain Zeit (USA &amp; Kanada)"

    },
    {

        value: "Yukon Standard Time",
        label:
            "(UTC-07:00) Yukon"

    },
    {

        value: "Central Standard Time",
        label:
            "(UTC-06:00) Central Zeit (USA &amp; Kanada)"

    },
    {

        value: "Central Standard Time (Mexico)",
        label:
            "(UTC-06:00) Guadalajara, Mexiko-Stadt, Monterrey"

    },
    {

        value: "Central America Standard Time",
        label:
            "(UTC-06:00) Mittelamerika"

    },
    {

        value: "Easter Island Standard Time",
        label:
            "(UTC-06:00) Osterinsel"

    },
    {

        value: "Canada Central Standard Time",
        label:
            "(UTC-06:00) Saskatchewan"

    },
    {

        value: "SA Pacific Standard Time",
        label:
            "(UTC-05:00) Bogotá, Lima, Quito, Rio Branco"

    },
    {

        value: "Eastern Standard Time (Mexico)",
        label:
            "(UTC-05:00) Chetumal"

    },
    {

        value: "Eastern Standard Time",
        label:
            "(UTC-05:00) Eastern Zeit (USA &amp; Kanada)"

    },
    {

        value: "Haiti Standard Time",
        label:
            "(UTC-05:00) Haiti"

    },
    {

        value: "Cuba Standard Time",
        label:
            "(UTC-05:00) Havanna"

    },
    {

        value: "US Eastern Standard Time",
        label:
            "(UTC-05:00) Indiana (Ost)"

    },
    {

        value: "Turks And Caicos Standard Time",
        label:
            "(UTC-05:00) Turks- und Caicosinseln"

    },
    {

        value: "Paraguay Standard Time",
        label:
            "(UTC-04:00) Asuncion"

    },
    {

        value: "Atlantic Standard Time",
        label:
            "(UTC-04:00) Atlantic (Kanada)"

    },
    {

        value: "Venezuela Standard Time",
        label:
            "(UTC-04:00) Caracas"

    },
    {

        value: "Central Brazilian Standard Time",
        label:
            "(UTC-04:00) Cuiaba"

    },
    {

        value: "SA Western Standard Time",
        label:
            "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan"

    },
    {

        value: "Pacific SA Standard Time",
        label:
            "(UTC-04:00) Santiago"

    },
    {

        value: "Newfoundland Standard Time",
        label:
            "(UTC-03:30) Neufundland"

    },
    {

        value: "Tocantins Standard Time",
        label:
            "(UTC-03:00) Araguaína"

    },
    {

        value: "E. South America Standard Time",
        label:
            "(UTC-03:00) Brasilia"

    },
    {

        value: "Argentina Standard Time",
        label:
            "(UTC-03:00) Buenos Aires"

    },
    {

        value: "SA Eastern Standard Time",
        label:
            "(UTC-03:00) Cayenne, Fortaleza"

    },
    {

        value: "Greenland Standard Time",
        label:
            "(UTC-03:00) Grönland"

    },
    {

        value: "Montevideo Standard Time",
        label:
            "(UTC-03:00) Montevideo"

    },
    {

        value: "Magallanes Standard Time",
        label:
            "(UTC-03:00) Punta Arenas"

    },
    {

        value: "Bahia Standard Time",
        label:
            "(UTC-03:00) Salvador"

    },
    {

        value: "Saint Pierre Standard Time",
        label:
            "(UTC-03:00) St. Pierre und Miquelon"

    },
    {

        value: "UTC-02",
        label:
            "(UTC-02:00) Koordinierte Weltzeit-02"

    },
    {

        value: "Mid-Atlantic Standard Time",
        label:
            "(UTC-02:00) Mittelatlantik - Alt"

    },
    {

        value: "Azores Standard Time",
        label:
            "(UTC-01:00) Azoren"

    },
    {

        value: "Cape Verde Standard Time",
        label:
            "(UTC-01:00) Cabo Verde"

    },
    {

        value: "UTC",
        label:
            "(UTC) Coordinated Universal Time"

    },
    {

        value: "GMT Standard Time",
        label:
            "(UTC+00:00) Dublin, Edinburgh, Lissabon, London"

    },
    {

        value: "Greenwich Standard Time",
        label:
            "(UTC+00:00) Monrovia, Reykjavik"

    },
    {

        value: "Sao Tome Standard Time",
        label:
            "(UTC+00:00) São Tomé"

    },
    {

        value: "Morocco Standard Time",
        label:
            "(UTC+01:00) Casablanca"

    },
    {

        value: "W. Europe Standard Time",
        label:
            "(UTC+01:00) Amsterdam, Berlin, Bern, Rom, Stockholm, Wien"

    },
    {

        value: "Central Europe Standard Time",
        label:
            "(UTC+01:00) Belgrad, Bratislava (Pressburg), Budapest, Ljubljana, Prag (Praha)"

    },
    {

        value: "Romance Standard Time",
        label:
            "(UTC+01:00) Brüssel, Kopenhagen, Madrid, Paris"

    },
    {

        value: "Central European Standard Time",
        label:
            "(UTC+01:00) Sarajevo, Skopje, Warschau, Zagreb"

    },
    {

        value: "W. Central Africa Standard Time",
        label:
            "(UTC+01:00) West-Zentralafrika"

    },
    {

        value: "GTB Standard Time",
        label:
            "(UTC+02:00) Athen, Bukarest"

    },
    {

        value: "Middle East Standard Time",
        label:
            "(UTC+02:00) Beirut"

    },
    {

        value: "E. Europe Standard Time",
        label:
            "(UTC+02:00) Chisinau"

    },
    {

        value: "Syria Standard Time",
        label:
            "(UTC+02:00) Damaskus"

    },
    {

        value: "West Bank Standard Time",
        label:
            "(UTC+02:00) Gaza, Hebron"

    },
    {

        value: "South Africa Standard Time",
        label:
            "(UTC+02:00) Harare, Pretoria"

    },
    {

        value: "FLE Standard Time",
        label:
            "(UTC+02:00) Helsinki, Kiew, Riga, Sofia, Tallinn, Wilna"

    },
    {

        value: "Israel Standard Time",
        label:
            "(UTC+02:00) Jerusalem"

    },
    {

        value: "South Sudan Standard Time",
        label:
            "(UTC+02:00) Juba"

    },
    {

        value: "Egypt Standard Time",
        label:
            "(UTC+02:00) Kairo"

    },
    {

        value: "Kaliningrad Standard Time",
        label:
            "(UTC+02:00) Kaliningrad"

    },
    {

        value: "Sudan Standard Time",
        label:
            "(UTC+02:00) Khartum"

    },
    {

        value: "Libya Standard Time",
        label:
            "(UTC+02:00) Tripolis"

    },
    {

        value: "Namibia Standard Time",
        label:
            "(UTC+02:00) Windhuk"

    },
    {

        value: "Jordan Standard Time",
        label:
            "(UTC+03:00) Amman"

    },
    {

        value: "Arabic Standard Time",
        label:
            "(UTC+03:00) Bagdad"

    },
    {

        value: "Turkey Standard Time",
        label:
            "(UTC+03:00) Istanbul"

    },
    {

        value: "Arab Standard Time",
        label:
            "(UTC+03:00) Kuwait, Riad"

    },
    {

        value: "Belarus Standard Time",
        label:
            "(UTC+03:00) Minsk"

    },
    {

        value: "Russian Standard Time",
        label:
            "(UTC+03:00) Moskau, St. Petersburg"

    },
    {

        value: "E. Africa Standard Time",
        label:
            "(UTC+03:00) Nairobi"

    },
    {

        value: "Volgograd Standard Time",
        label:
            "(UTC+03:00) Wolgograd"

    },
    {

        value: "Iran Standard Time",
        label:
            "(UTC+03:30) Teheran"

    },
    {

        value: "Arabian Standard Time",
        label:
            "(UTC+04:00) Abu Dhabi, Muskat"

    },
    {

        value: "Astrakhan Standard Time",
        label:
            "(UTC+04:00) Astrachan, Uljanowsk"

    },
    {

        value: "Azerbaijan Standard Time",
        label:
            "(UTC+04:00) Baku"

    },
    {

        value: "Caucasus Standard Time",
        label:
            "(UTC+04:00) Eriwan"

    },
    {

        value: "Russia Time Zone 3",
        label:
            "(UTC+04:00) Ischewsk, Samara"

    },
    {

        value: "Mauritius Standard Time",
        label:
            "(UTC+04:00) Port Louis"

    },
    {

        value: "Saratov Standard Time",
        label:
            "(UTC+04:00) Saratov"

    },
    {

        value: "Georgian Standard Time",
        label:
            "(UTC+04:00) Tiflis"

    },
    {

        value: "Afghanistan Standard Time",
        label:
            "(UTC+04:30) Kabul"

    },
    {

        value: "West Asia Standard Time",
        label:
            "(UTC+05:00) Aschgabat, Taschkent"

    },
    {

        value: "Pakistan Standard Time",
        label:
            "(UTC+05:00) Islamabad, Karatschi"

    },
    {

        value: "Ekaterinburg Standard Time",
        label:
            "(UTC+05:00) Jekaterinburg"

    },
    {

        value: "Qyzylorda Standard Time",
        label:
            "(UTC+05:00) Qysylorda"

    },
    {

        value: "India Standard Time",
        label:
            "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi"

    },
    {

        value: "Sri Lanka Standard Time",
        label:
            "(UTC+05:30) Sri Jayawardenepura"

    },
    {

        value: "Nepal Standard Time",
        label:
            "(UTC+05:45) Katmandu"

    },
    {

        value: "Central Asia Standard Time",
        label:
            "(UTC+06:00) Astana"

    },
    {

        value: "Bangladesh Standard Time",
        label:
            "(UTC+06:00) Dakka"

    },
    {

        value: "Omsk Standard Time",
        label:
            "(UTC+06:00) Omsk"

    },
    {

        value: "Myanmar Standard Time",
        label:
            "(UTC+06:30) Yangon (Rangun)"

    },
    {

        value: "SE Asia Standard Time",
        label:
            "(UTC+07:00) Bangkok, Hanoi, Jakarta"

    },
    {

        value: "Altai Standard Time",
        label:
            "(UTC+07:00) Barnaul, Gorno-Altaisk"

    },
    {

        value: "W. Mongolia Standard Time",
        label:
            "(UTC+07:00) Hovd"

    },
    {

        value: "North Asia Standard Time",
        label:
            "(UTC+07:00) Krasnojarsk"

    },
    {

        value: "N. Central Asia Standard Time",
        label:
            "(UTC+07:00) Nowosibirsk"

    },
    {

        value: "Tomsk Standard Time",
        label:
            "(UTC+07:00) Tomsk"

    },
    {

        value: "North Asia East Standard Time",
        label:
            "(UTC+08:00) Irkutsk"

    },
    {

        value: "Singapore Standard Time",
        label:
            "(UTC+08:00) Kuala Lumpur, Singapur"

    },
    {

        value: "China Standard Time",
        label:
            "(UTC+08:00) Peking, Chongqing, Hongkong (SAR), Urumchi"

    },
    {

        value: "W. Australia Standard Time",
        label:
            "(UTC+08:00) Perth"

    },
    {

        value: "Taipei Standard Time",
        label:
            "(UTC+08:00) Taipeh"

    },
    {

        value: "Ulaanbaatar Standard Time",
        label:
            "(UTC+08:00) Ulan-Bator"

    },
    {

        value: "Aus Central W. Standard Time",
        label:
            "(UTC+08:45) Eucla"

    },
    {

        value: "Transbaikal Standard Time",
        label:
            "(UTC+09:00) Chita"

    },
    {

        value: "Yakutsk Standard Time",
        label:
            "(UTC+09:00) Jakutsk"

    },
    {

        value: "Tokyo Standard Time",
        label:
            "(UTC+09:00) Osaka, Sapporo, Tokio"

    },
    {

        value: "North Korea Standard Time",
        label:
            "(UTC+09:00) Pjöngjang"

    },
    {

        value: "Korea Standard Time",
        label:
            "(UTC+09:00) Seoul"

    },
    {

        value: "Cen. Australia Standard Time",
        label:
            "(UTC+09:30) Adelaide"

    },
    {

        value: "AUS Central Standard Time",
        label:
            "(UTC+09:30) Darwin"

    },
    {

        value: "E. Australia Standard Time",
        label:
            "(UTC+10:00) Brisbane"

    },
    {

        value: "AUS Eastern Standard Time",
        label:
            "(UTC+10:00) Canberra, Melbourne, Sydney"

    },
    {

        value: "West Pacific Standard Time",
        label:
            "(UTC+10:00) Guam, Port Moresby"

    },
    {

        value: "Tasmania Standard Time",
        label:
            "(UTC+10:00) Hobart"

    },
    {

        value: "Vladivostok Standard Time",
        label:
            "(UTC+10:00) Wladiwostok"

    },
    {

        value: "Lord Howe Standard Time",
        label:
            "(UTC+10:30) Lord-Howe-Insel"

    },
    {

        value: "Bougainville Standard Time",
        label:
            "(UTC+11:00) Bougainville-Insel"

    },
    {

        value: "Magadan Standard Time",
        label:
            "(UTC+11:00) Magadan"

    },
    {

        value: "Norfolk Standard Time",
        label:
            "(UTC+11:00) Norfolkinsel"

    },
    {

        value: "Sakhalin Standard Time",
        label:
            "(UTC+11:00) Sachalin"

    },
    {

        value: "Central Pacific Standard Time",
        label:
            "(UTC+11:00) Salomonen, Neu-Kaledonien"

    },
    {

        value: "Russia Time Zone 10",
        label:
            "(UTC+11:00) Tschokurdach"

    },
    {

        value: "Russia Time Zone 11",
        label:
            "(UTC+12:00) Anadyr, Petropawlowsk-Kamtschatski"

    },
    {

        value: "New Zealand Standard Time",
        label:
            "(UTC+12:00) Auckland, Wellington"

    },
    {

        value: "Fiji Standard Time",
        label:
            "(UTC+12:00) Fidschi"

    },
    {

        value: "UTC+12",
        label:
            "(UTC+12:00) Koordinierte Weltzeit+12"

    },
    {

        value: "Kamchatka Standard Time",
        label:
            "(UTC+12:00) Petropawlowsk-Kamtschatski - veraltet"

    },
    {

        value: "Chatham Islands Standard Time",
        label:
            "(UTC+12:45) Chatham-Inseln"

    },
    {

        value: "UTC+13",
        label:
            "(UTC+13:00) Koordinierte Weltzeit+13"

    },
    {

        value: "Tonga Standard Time",
        label:
            "(UTC+13:00) Nuku'alofa"

    },
    {

        value: "Samoa Standard Time",
        label:
            "(UTC+13:00) Samoa"

    },
    {

        value: "Line Islands Standard Time",
        label:
            "(UTC+14:00) Insel Kiritimati"

    }
];

export const EditWorkspaceColor = [
    {
        class: "color",
        title: "default",
        style: "background-color: #fff;",
        dataColor: "#fff",
        role: "button",
        tabindex: "0"
    },
    {
        class: "color",
        title: "aqua_island",
        style: "background-color: #A2DED0;",
        dataColor: "#A2DED0",
        role: "button",
        tabindex: "0"
    },
    {
        class: "color",
        title: "Turquoise",
        style: "background-color: #1abc9c;",
        dataColor: "#1abc9c",
        role: "button",
        tabindex: "0"
    },
    {
        class: "color",
        title: "green_sea",
        style: "background-color: #16a085;",
        dataColor: "#16a085",
        role: "button",
        tabindex: "0"
    },
    {
        class: "color",
        title: "emerald",
        style: "background-color: #2ecc71;",
        dataColor: "#2ecc71",
        role: "button",
        tabindex: "0"
    },
    {
        class: "color",
        title: "nephritis",
        style: "background-color: #27ae60;",
        dataColor: "#27ae60",
        role: "button",
        tabindex: "0"
    },
    {
        class: "color",
        title: "peter_river",
        style: "background-color: #3498db;",
        dataColor: "#3498db",
        role: "button",
        tabindex: "0"
    },
    {
        class: "color",
        title: "belize_hole",
        style: "background-color: #2980b9;",
        dataColor: "#2980b9",
        role: "button",
        tabindex: "0"
    },
    {
        class: "color",
        title: "chambray",
        style: "background-color: #3A539B;",
        dataColor: "#3A539B",
        role: "button",
        tabindex: "0"
    },
    {
        class: "color",
        title: "amethyst",
        style: "background-color: #9b59b6;",
        dataColor: "#9b59b6",
        role: "button",
        tabindex: "0"
    },
    {
        class: "color",
        title: "wisteria",
        style: "background-color: #8e44ad;",
        dataColor: "#8e44ad",
        role: "button",
        tabindex: "0"
    },
    {
        class: "color",
        title: "plum",
        style: "background-color: #913D88;",
        dataColor: "#913D88",
        role: "button",
        tabindex: "0"
    },
    {
        class: "color",
        title: "wet_asphalt",
        style: "background-color: #34495e;",
        dataColor: "#34495e",
        role: "button",
        tabindex: "0"
    },
    {
        class: "color",
        title: "midnight_blue",
        style: "background-color: #2c3e50;",
        dataColor: "#2c3e50",
        role: "button",
        tabindex: "0"
    },
    {
        class: "color",
        title: "cream_can",
        style: "background-color: #F5D76E;",
        dataColor: "#F5D76E",
        role: "button",
        tabindex: "0"
    },
    {
        class: "color",
        title: "sun_flower",
        style: "background-color: #f1c40f;",
        dataColor: "#f1c40f",
        role: "button",
        tabindex: "0"
    },
    {
        class: "color",
        title: "orange",
        style: "background-color: #f39c12;",
        dataColor: "#f39c12",
        role: "button",
        tabindex: "0"
    },
    {
        class: "color",
        title: "carrot",
        style: "background-color: #e67e22;",
        dataColor: "#e67e22",
        role: "button",
        tabindex: "0"
    },
    {
        class: "color",
        title: "pumpkin",
        style: "background-color: #d35400;",
        dataColor: "#d35400",
        role: "button",
        tabindex: "0"
    },
    {
        class: "color",
        title: "alizarin",
        style: "background-color: #e74c3c;",
        dataColor: "#e74c3c",
        role: "button",
        tabindex: "0"
    },
    {
        class: "color",
        title: "pomegranates",
        style: "background-color: #c0392b;",
        dataColor: "#c0392b",
        role: "button",
        tabindex: "0"
    },
    {
        class: "color",
        title: "old_brick",
        style: "background-color: #96281B;",
        dataColor: "#96281B",
        role: "button",
        tabindex: "0"
    },
    {
        class: "color",
        title: "clouds",
        style: "background-color: #ecf0f1;",
        dataColor: "#ecf0f1",
        role: "button",
        tabindex: "0"
    },
    {
        class: "color",
        title: "silver",
        style: "background-color: #bdc3c7;",
        dataColor: "#bdc3c7",
        role: "button",
        tabindex: "0"
    },
    {
        class: "color",
        title: "concrete",
        style: "background-color: #95a5a6;",
        dataColor: "#95a5a6",
        role: "button",
        tabindex: "0"
    },
    {
        class: "color",
        title: "asbestos",
        style: "background-color: #7f8c8d;",
        dataColor: "#7f8c8d",
        role: "button",
        tabindex: "0"
    },
    {
        class: "color",
        title: "lynching",
        style: "background-color: #6C7A89;",
        dataColor: "#6C7A89",
        role: "button",
        tabindex: "0"
    }
];

export const LanguageList = [
    {

        value: "en",
        label: 'English'

    },
    {

        value: "de",
        label: 'Deutsch'

    },
    // {

    //     value: "en_uk",
    //     label: 'English UK'

    // },
    // {

    //     value: "nl",
    //     label: 'Dutch'

    // },
    // {

    //     value: "es",
    //     label: 'Spanish'

    // },
];

export const ApplicationsAndAssignmentsList = [
    {
        label: "Employees can apply for full shifts",
        checked: false
    },
    {
        label: "Employees are allowed to work full shifts",
        checked: false
    },
    {
        label: "Prohibit applications",
        checked: false
    },
    {
        label: "Employees are allowed to see applications of their colleagues",
        checked: false
    },
    {
        label: "Hide all bookings in the calendar for employees",
        checked: false
    },
    {
        label: "Prohibit shift swaps",
        checked: false
    },
    {
        label: "View applications in resource view",
        checked: false
    },
    {
        label: "Statutory rest periods from",
        checked: false,
    },
    {
        label: "Always consider applications",
        checked: false
    },
    {
        label: "Assign automatically: Ignore target hour limit",
        checked: false
    },

];

export const ProfileTabsList = [
    {
        label: "General",
        isActive: true
    },
    {
        label: "Target hours",
        isActive: false
    },
    {
        label: "Assignments",
        isActive: false
    },
    {
        label: "Time tracking",
        isActive: false
    },
    {
        label: "Records",
        isActive: false
    },
    {
        label: "Availabilities",
        isActive: false
    },
    {
        label: "Absences",
        isActive: false
    },
    {
        label: "Vacation",
        isActive: false
    },
    {
        label: "Notifications",
        isActive: false
    },
    {
        label: "Calendar synchronization",
        isActive: false
    },
];