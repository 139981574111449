import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { CallAxios } from '../../Helpers';
import { Constants } from '../../Helpers/Contents';
import { DetailsGreyBox, GreenUserName, TabsItems } from '../../Helpers/Global/StyledComponents';
import ProfileImage from './ProfileImage';
import TabContentForProfile from './TabContent';
import { NavLink, useLocation, Outlet } from 'react-router-dom';
import { useTranslation } from "react-i18next";


function Profile() {
    const { t } = useTranslation('myProfile');

    const location = useLocation();
    const [tabsList, setTabsList] = useState([{
        label: "General",
        isActive: location.pathname.indexOf("master_data") > -1,
        sortInde: 1,
        url: "master_data"
    },
    {
        label: "Target hours",
        isActive: location.pathname.indexOf("target_hours") > -1,
        sortInde: 2,
        url: "target_hours"
    },
    {
        label: "Records",
        isActive: location.pathname.indexOf("evaluations") > -1,
        sortInde: 5,
        url: "evaluations"
    },
    {
        label: "Notifications",
        isActive: location.pathname.indexOf("notifications") > -1,
        sortInde: 9,
        url: "notifications"
    }]);
    // const [activeTab, setActiveTab] = useState('');
    const { userid } = useParams();
    const [ProfileData, setProfileData] = useState({});
    const user = useSelector(x => x.app.user)
    const appSettings = useSelector(x => x.app.appSettings)
    const currentView = useSelector(s => s.app.currentView);
    // useEffect(() => {
    //     if (tabsList.length > 0) {
    //         setActiveTab(tabsList.find(x => x.isActive === true).label);
    //     }
    // }, [tabsList]);
    const changeTab = (index) => {
        let prevState = [...tabsList];
        prevState.map(x => x.isActive = false);
        prevState[index].isActive = true;
        setTabsList(prevState)

    }

    useEffect(() => {
        getProfileData();
    }, []);
    useEffect(() => {
        getProfileData();
    }, [location]);
    useEffect(() => {
        var List = [{
            label: "general",
            isActive: location.pathname.indexOf("master_data") > -1,
            sortInde: 1,
            url: "master_data"
        },
        {
            label: "targetHours",
            isActive: location.pathname.indexOf("target_hours") > -1,
            sortInde: 2,
            url: "target_hours"
        },
        {
            label: "records",
            isActive: location.pathname.indexOf("evaluations") > -1,
            sortInde: 5,
            url: "evaluations"
        },
        {
            label: "notifications",
            isActive: location.pathname.indexOf("notifications") > -1,
            sortInde: 9,
            url: "notifications"
        }]
        if ((currentView === Constants.layoutType.Employee && appSettings.employeeAccess.canSeeCalender === true) || currentView === Constants.layoutType.Admin) {
            List.push({
                label: "assignments",
                isActive: location.pathname.indexOf("events") > -1,
                sortInde: 3,
                url: "events"
            })
        }
        if ((currentView === Constants.layoutType.Employee && appSettings.employeeAccess.canSeetimetracking === true) || currentView === Constants.layoutType.Admin) {
            List.push({
                label: "timeTracking",
                isActive: location.pathname.indexOf("time_trackings") > -1,
                sortInde: 4,
                url: "time_trackings"
            })
        }
        if ((currentView === Constants.layoutType.Employee && appSettings.employeeAccess.canSeeavailabilities === true) || currentView === Constants.layoutType.Admin) {
            List.push({
                label: "availabilities",
                isActive: location.pathname.indexOf("availabilities") > -1,
                sortInde: 6,
                url: "availabilities"
            })
        }
        if ((currentView === Constants.layoutType.Employee && appSettings.employeeAccess.canSeeabsences === true) || currentView === Constants.layoutType.Admin) {
            List.push({
                label: "absences",
                isActive: location.pathname.indexOf("absences") > -1,
                sortInde: 7,
                url: "absences"
            })
            List.push({
                label: "vacation",
                isActive: location.pathname.indexOf("vacations") > -1,
                sortInde: 8,
                url: "vacations"
            })
        }
        if (user.LoginUserId === userid) {
            List.push({
                label: "documents",
                isActive: location.pathname.indexOf("documents") > -1,
                sortInde: 10,
                url: "documents"
            })
        }
        if (user.LoginUserId === userid) {
            List.push({
                label: "calendarSynchronization",
                isActive: location.pathname.indexOf("authentications") > -1,
                sortInde: 11,
                url: "authentications"
            })
        }

        setTabsList(List)
    }, [user]);
    const getProfileData = async () => {
        var res = await CallAxios("/api/Employee/getEmployeebyurlID|post", { id: userid });
        if (!!res && res.status === 200) {
            setProfileData(res.data)
        }
    }
    return (
        <div className='p-3 py-5'>
            <Row>
                <Col md={2}>
                    <ProfileImage ProfileData={ProfileData} getProfileData={getProfileData} />
                </Col>
                <Col md={10}>
                    <UserDetails ProfileData={ProfileData} />
                </Col>
                <Col md={2}>
                    <div className='TabsWrapper my-3'>
                        {tabsList.length > 0 && tabsList.sort((a, b) => a.sortInde > b.sortInde ? 1 : -1).map((x, i) =>
                            <TabsItems as={NavLink} to={location.pathname.substring(0, location.pathname.lastIndexOf("/")) + "/" + x.url} key={x.label + i} onClick={() => changeTab(i)} className={` pb-1 border-bottom `}>
                                <div className={`p-2 labelTExt ${x.isActive === true && "active"}`}>
                                    {t(x.label)}
                                </div>
                            </TabsItems>
                        )}
                    </div>
                </Col>
                <Col md={10}>
                    <div className='pt-3'>
                        <Card>
                            <Card.Body>
                                {/* <TabContentForProfile active={activeTab} /> */}
                                <Outlet />
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default Profile;


function UserDetails(props) {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');


    return (
        <>
            <GreenUserName>
                {!!props.ProfileData ? props.ProfileData.surname : null}
            </GreenUserName>
            <p>
                {ct('workingAreas')} :
                {
                    !!props.ProfileData && !!props.ProfileData.empAreas && props.ProfileData.empAreas.length > 0 ? props.ProfileData.empAreas.map((value, key) => {
                        return value
                    }).join(", ") :
                        null
                }
            </p>
            <p>
                {ct('tags')}:
                {
                    !!props.ProfileData && props.ProfileData.tags
                }
            </p>
            <DetailsGreyBox>
                {ct('birthday')} :{!!props.ProfileData && !!props.ProfileData.birthday ? new Date(props.ProfileData.birthday).toLocaleDateString() : ""}
            </DetailsGreyBox>
            <DetailsGreyBox>
                {t('generalTab.startWork')} :{!!props.ProfileData && !!props.ProfileData.startOfWork ? new Date(props.ProfileData.startOfWork).toLocaleDateString() : ""}
            </DetailsGreyBox>
            <DetailsGreyBox>
                {t('generalTab.endProbationaryPeriod')} : {!!props.ProfileData && !!props.ProfileData.endProbationaryPeriod ? new Date(props.ProfileData.endProbationaryPeriod).toLocaleDateString() : ""}
            </DetailsGreyBox>
            <DetailsGreyBox>
                {t('generalTab.endContract')} : {!!props.ProfileData && !!props.ProfileData.endOfContract ? new Date(props.ProfileData.endOfContract).toLocaleDateString() : ""}
            </DetailsGreyBox>
            <DetailsGreyBox>
                {ct('role')} :
                {
                    !!props.ProfileData && !!props.ProfileData.empRoles && props.ProfileData.empRoles.length > 0 ? props.ProfileData.empRoles.map((value, key) => {
                        return value
                    }).join(", ") :
                        null
                }
            </DetailsGreyBox>
        </>
    );
}

