import { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { CallAxios } from "../../Helpers";
import { Constants } from "../../Helpers/Contents";
import { useTranslation } from "react-i18next";


function NotificationTab() {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');


    const { userid } = useParams();
    const user = useSelector(x => x.app.user)

    const [employeeNotificationId, setEmployeeNotificationId] = useState("");
    const [DeliveryMailsActive, setDeliveryMailsActive] = useState(null);
    const [IncompleteSchedulerWeekly, setIncompleteSchedulerWeekly] = useState(null);
    const [ApplicationsShifts, setApplicationsShifts] = useState(null);
    const [reachedLimitTargetHours, setReachedLimitTargetHours] = useState(null);
    const [ReceiveFollowerMails, setReceiveFollowerMails] = useState(null);
    const [NewOrEditedAbsences, setNewOrEditedAbsences] = useState(null);
    const [NewMessages, setNewMessages] = useState(null);
    const [ShiftSwap, setShiftSwap] = useState(null);
    const [WorkHours, setWorkHours] = useState(null);

    useEffect(() => {
        getdata()
    }, []);

    const getdata = async () => {
        var responses = await CallAxios(
            "/api/EmployeeNotifications/getnotificationSetting|post", { id: userid }
        );
        setEmployeeNotificationId(responses.data.employeeNotificationId);
        setDeliveryMailsActive(responses.data.deliveryMailsActive);
        setIncompleteSchedulerWeekly(responses.data.incompleteSchedulerWeekly);
        setApplicationsShifts(responses.data.applicationsShifts);
        setReachedLimitTargetHours(responses.data.reachedLimitTargetHours);
        setReceiveFollowerMails(responses.data.receiveFollowerMails);
        setNewOrEditedAbsences(responses.data.newOrEditedAbsences);
        setNewMessages(responses.data.newMessages);
        setShiftSwap(responses.data.shiftSwap);
        setWorkHours(responses.data.workHours);
    }

    const Submit = async () => {
        var data = {
            EmployeeNotificationId: employeeNotificationId,
            EmployeeId: parseInt(userid),
            DeliveryMailsActive: DeliveryMailsActive,
            IncompleteSchedulerWeekly: IncompleteSchedulerWeekly,
            ApplicationsShifts: ApplicationsShifts,
            reachedLimitTargetHours: reachedLimitTargetHours,
            ReceiveFollowerMails: ReceiveFollowerMails,
            NewOrEditedAbsences: NewOrEditedAbsences,
            NewMessages: NewMessages,
            ShiftSwap: ShiftSwap,
            WorkHours: WorkHours,
        }
        await CallAxios(
            "/api/EmployeeNotifications/updatenotifications|post", data
        );
    }

    return <>
        <div className="DetailInfoWrapper text-left">
            <div className='mb-3'>
                <h4 className='fw-light'> {t('notifications')}</h4>
                <p> {t('notificationsTab.notifyPara')}:</p>
                <div>
                    <Form.Group className="mb-0">
                        {
                            user.roles.some(x => x === Constants.roles.AccountAdmin) ?
                                <>
                                    <Form.Check className="mb-2" name="notifications1" onChange={(e) => setDeliveryMailsActive(e.target.checked)} checked={DeliveryMailsActive} label={t('notificationsTab.mailActiveLabel')} type="checkbox" />
                                    <Form.Check className="mb-2" name="notifications2" onChange={(e) => setIncompleteSchedulerWeekly(e.target.checked)} checked={IncompleteSchedulerWeekly} label={t('notificationsTab.incompleteLabel')} type="checkbox" />
                                    <Form.Check className="mb-2" name="notifications3" onChange={(e) => setApplicationsShifts(e.target.checked)} checked={ApplicationsShifts} label={t('notificationsTab.appShiftsLabel')} type="checkbox" />
                                    <Form.Check className="mb-2" name="notifications4" onChange={(e) => setReachedLimitTargetHours(e.target.checked)} checked={reachedLimitTargetHours} label={t('notificationsTab.limitTargetHourLabel')} type="checkbox" />
                                </>
                                : null
                        }
                        <Form.Check className="mb-2" name="notifications5" onChange={(e) => setReceiveFollowerMails(e.target.checked)} checked={ReceiveFollowerMails} label={t('notificationsTab.receiveMailLabel')} type="checkbox" />
                        <Form.Check className="mb-2" name="notifications5" onChange={(e) => setNewOrEditedAbsences(e.target.checked)} checked={NewOrEditedAbsences} label={t('notificationsTab.newAbsencesLabel')} type="checkbox" />
                        <Form.Check className="mb-2" name="notifications5" onChange={(e) => setNewMessages(e.target.checked)} checked={NewMessages} label={t('notificationsTab.newMessageLabel')} type="checkbox" />
                        <Form.Check className="mb-2" name="notifications5" onChange={(e) => setShiftSwap(e.target.checked)} checked={ShiftSwap} label={t('notificationsTab.shiftSwapLabel')} type="checkbox" />
                        <Form.Check className="mb-2" name="notifications5" onChange={(e) => setWorkHours(e.target.checked)} checked={WorkHours} label={t('notificationsTab.workHourLabel')} type="checkbox" />

                    </Form.Group>
                    <button className='btn btn-primary my-2' type='button' onClick={() => Submit()}> {ct('done')}</button>
                </div>
            </div>


        </div>
    </>
}

export default NotificationTab;
