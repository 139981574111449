import React, { useEffect, useState } from 'react';
import { AxiosLite, CallAxios } from '../../Helpers';
import { ContentBox, ContentSubHeader } from '../../Helpers/Global/StyledComponents';
import EmployeeGrid from './EmployeeGrid';
import EmployeeHeader from './EmployeeHeader';
import InputImoji from '../../Components/inputImoji';
import { Form } from 'react-bootstrap';
import { BsPlusCircle } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Constants } from '../../Helpers/Contents';
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";



// Main Employee Page
function EmployeeList() {
    const { t: ept } = useTranslation('employeePage');

    var user = useSelector(x => x.app.user);
    var appSettings = useSelector(x => x.app.appSettings);
    const [employeeList, setEmployeeList] = useState([]);
    const [text, setText] = useState("");

    useEffect(() => {
        getEmployeeList();
    }, []);

    const getEmployeeList = async () => {
        var response = await AxiosLite("/api/Employee/employeelist|get")
        if (!!response && response.status === 200) {
            setEmployeeList(response.data)
        }
    }
    const CreateEmployeeWithoutEmail = async (e) => {
        e.preventDefault();
        if (!!text) {
            var response = await CallAxios('/api/Employee/CreateEmp|post', { name: text })
            if (!!response && response.status === 200) {
                if (response.data === true) {
                    setText("")
                    getEmployeeList();
                } else if (response.data === "LimitReach") {
                    toast.error(ept('errorMsg.limitReach'))
                }
            }
        } else {
            Swal.fire({
                icon: "info",
                text: ept('errorMsg.name')
            })
        }
    }

    return (
        <>
            <ContentSubHeader>
                <EmployeeHeader employeeList={employeeList} getEmployeeList={getEmployeeList} />
            </ContentSubHeader>
            <ContentBox>
                <div className="position-relative w-25">
                    {
                        appSettings.locationsettings.Employees_only_created_by_name === true ?
                            user.roles.length > 0 && user.roles.some(x => x === Constants.roles.AccountAdmin || x === Constants.roles.WorkspaceAdmin) ?
                                <div className='d-flex align-items-center mb-3'>
                                    <form onSubmit={CreateEmployeeWithoutEmail}>
                                        <Form.Group className="position-relative flex-grow-1">
                                            <InputImoji placeholder={ept('newColleague')} value={text} onChange={v => setText(v)} />
                                        </Form.Group>
                                    </form>
                                    <span className="btnPlusCircle ms-2" onClick={() => CreateEmployeeWithoutEmail()}><BsPlusCircle /></span>
                                </div>
                                : null
                            : null
                    }
                </div>
                <EmployeeGrid employeeList={employeeList} getEmployeeList={getEmployeeList} />
            </ContentBox>
        </>
    );
}

export default EmployeeList;