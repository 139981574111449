import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { AxiosLite, CallAxios } from "../../Helpers";
import { useTranslation } from "react-i18next";


function MoreSettingTab(props) {
    const { t: ast } = useTranslation('accountSetting');
    const { t: ct } = useTranslation('common');


    useEffect(() => {
        getbaseData()
    }, []);
    const getbaseData = async () => {
        var response = await AxiosLite("/api/AccountSetting/basedata|get");
        if (!!response && response.status === 200) {

            setbaseDataobj((prevState) => {
                const newState = { ...prevState }
                return Object.assign(newState, {
                    IsAllowDeletionAbsences: response.data.isAllowDeletionAbsences,
                    IsBlockAccessPapershiftSupportStaff: response.data.isBlockAccessPapershiftSupportStaff,
                    IsemployeeNumberAsExternalID: response.data.isemployeeNumberAsExternalID,

                })
            })
        }
    }

    const [baseDataobj, setbaseDataobj] = useState(
        {
            IsAllowDeletionAbsences: false,
            IsBlockAccessPapershiftSupportStaff: false,
            IsemployeeNumberAsExternalID: false,
        }
    );
    const OnchageValue = (e) => {
        setbaseDataobj((prevState) => {
            const newState = { ...prevState }
            return Object.assign(newState, {
                [e.target.name]: e.target.checked,
            })
        })
    }

    const onSubmit = async () => {
        var data = {
            IsAllowDeletionAbsences: baseDataobj.IsAllowDeletionAbsences,
            IsBlockAccessPapershiftSupportStaff: baseDataobj.IsBlockAccessPapershiftSupportStaff,
            IsemployeeNumberAsExternalID: baseDataobj.IsemployeeNumberAsExternalID,
        }
        await CallAxios("/api/AccountSetting/updateMoreSetting|post", data);
    }

    return (
        <>
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <div className="TabFormContainerSection">
                            <div className="Heading h5 fw-bold mb-3"> {ast('moreSettings')}</div>
                            <Form>
                                <Form.Group id="MoreSettingForm.allowAbcenceDeletion">
                                    <Form.Check className="fw-bold" type="checkbox" name="IsAllowDeletionAbsences" checked={baseDataobj.IsAllowDeletionAbsences} onChange={(e) => OnchageValue(e)} label={ast('moreSettingTab.labelList1')} />
                                </Form.Group>
                                <Form.Group id="MoreSettingForm.UseNumAsID">
                                    <Form.Check className="fw-bold" type="checkbox" name="IsemployeeNumberAsExternalID" checked={baseDataobj.IsemployeeNumberAsExternalID} onChange={(e) => OnchageValue(e)} label={ast('moreSettingTab.labelList2')} />
                                </Form.Group>
                                <Form.Group id="MoreSettingForm.BlockStaffAccess">
                                    <Form.Check className="fw-bold" type="checkbox" name="IsBlockAccessPapershiftSupportStaff" checked={baseDataobj.IsBlockAccessPapershiftSupportStaff} onChange={(e) => OnchageValue(e)} label={ast('moreSettingTab.labelList3')} />
                                </Form.Group>

                                <Col md={12} className="px-0 mt-4">
                                    <Button className="btn btnPrimaryGreen" onClick={(e) => onSubmit()}> {ct('finished')}</Button>
                                </Col>

                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default MoreSettingTab;