import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { AxiosLite, CallAxios } from '../../../Helpers';
import { SignInManager } from '../../../Helpers/Authentication/SignInManager';
import { UpdateReduxStates } from '../../../Helpers/MasterData';
import { setLocationTheme } from '../../../Store/slices/AppSlice';
import { DOT } from '../../../Helpers/Global/StyledComponents';
import { useTranslation } from "react-i18next";



function ThemeSetting() {
    const { t: ast } = useTranslation('accountSetting');
    const { t: ct } = useTranslation('common');


    var dispatch = useDispatch();
    useEffect(() => {
        // dispatch(
        //     setLocationTheme({
        //         colors: {
        //             bg: "#1b2226",
        //             buttons: "#01d222",
        //             headerText: "#109222",
        //             active: "#0022a9"
        //         }
        //     })
        // )

    }, []);
    const applayTheme = async (theme) => {
        await CallAxios("/api/AccountSetting/UpdateTheme/|post", { id: JSON.stringify(theme) });
        dispatch(
            setLocationTheme(theme)
        )
    }
    return (
        <>
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <div className="TabFormContainerSection">
                            <h5 className='text-left fw-bold mb-3'> {ast('themeTab.selectTheme')}</h5>
                            <Form>
                                <Row>
                                    <Col md={4} className='mb-2'>
                                        <div className='d-flex align-items-center'>
                                            <label className='me-3'>{ast('themeTab.default')}: </label>
                                            <DOT color="#1b9176" />
                                            <DOT color="#01dba9" />
                                            <DOT color="#109a7a" />
                                            <DOT color="#00dba9" />
                                            <Button className='ps-3' onClick={() => applayTheme({
                                                colors: {
                                                    bg: "#1b9176",
                                                    buttons: "#01dba9",
                                                    headerText: "#109a7a",
                                                    active: "#00dba9"
                                                }
                                            })}> {ast('themeTab.apply')}</Button>
                                        </div>
                                    </Col>
                                    <Col md={4} className='mb-2'>
                                        <div className='d-flex align-items-center'>
                                            <label className='me-3'> {ast('themeTab.light')}:</label>
                                            <DOT color="#dbe1ef" />
                                            <DOT color="#3f74fe" />
                                            <DOT color="#8b95ae" />
                                            <DOT color="#8dacff" />
                                            <Button className='ps-3' onClick={() => applayTheme({
                                                colors: {
                                                    bg: "#3f74fe",
                                                    buttons: "#3f74fe",
                                                    headerText: "#8b95ae",
                                                    active: "#dbe1ef"
                                                }
                                            })}> {ast('themeTab.apply')}</Button>
                                        </div>
                                    </Col>
                                    <Col md={4} className='mb-2'>
                                        <div className='d-flex align-items-center'>
                                            <label className='me-3'>Kubrick:</label>
                                            <DOT color="#fe612e" />
                                            <DOT color="#ffa082" />
                                            <DOT color="#dbe1ef" />
                                            <DOT color="#11141d" />
                                            <Button className='ps-3' onClick={() => applayTheme({
                                                colors: {
                                                    bg: "#fe612e",
                                                    buttons: "#fe612e",
                                                    headerText: "#11141d",
                                                    active: "#ffa082"
                                                }
                                            })}> {ast('themeTab.apply')}</Button>
                                        </div>
                                    </Col>
                                    <Col md={4} className='mb-2'>
                                        <div className='d-flex align-items-center'>
                                            <label className='me-3'>Capella:</label>
                                            <DOT color="#ffb902" />
                                            <DOT color="#feda6a" />
                                            <DOT color="#dbe1ef" />
                                            <DOT color="#000" />
                                            <Button className='ps-3' onClick={() => applayTheme({
                                                colors: {
                                                    bg: "#ffb902",
                                                    buttons: "#ffb902",
                                                    headerText: "#8b95ae",
                                                    active: "#feda6a"
                                                }
                                            })}> {ast('themeTab.apply')}</Button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4} className="text-left mt-3">
                                        {/* <button type="button" onClick={(e) => formik.handleSubmit()} className="btn btnPrimaryGreen btn-primary" >Ready</button> */}
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ThemeSetting;


