import { useState } from "react";
import { CallAxios } from "../../../Helpers";
import Swal from "sweetalert2";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

function DirectAssignPastShiftConfirmationModel(props) {
    const { t: cl } = useTranslation('calendarPage');
    const { t: ct } = useTranslation('common');


    const [ShowExceedLimitModel, setShowExceedLimitModel] = useState(false);
    const [forceShiftId, setForceShiftId] = useState("");
    const [CosenType, setCosenType] = useState("");
    const Assignmentonly = async () => {
        var data = {
            id: props.shiftid,
            empid: props.SelectedEmployeeId.toString(),
            checkWorkarea: props.checkWorkarea,
            assignForcefully: false
        }
        if (props.isShiftalreadyassigned) {
            var response = await CallAxios("/api/Calendar/RemoveFromShiftDirectly|post", data)
        }
        else {
            var response = await CallAxios("/api/Calendar/assignShiftDirectly|post", data)
            if (response.data === "reached limit") {
                setForceShiftId(props.shiftid)
                setShowExceedLimitModel(true);
                return;
            }
        }
        if (!!response && response.data.success === true) {
            if (response.data === true) {
                props.getShifts();
                props.GetEmployeeList(props.calendarComponentRef.current.activeRange.start);
                props.handleModal()
                if (!!props.getunoccupiedList) {
                    props.getunoccupiedList();
                }
                // props.GetEmployeeList(calendarComponentRef.current._calendarApi.currentDataManager.data.dateProfile.activeRange.start);
            } else if (response.data !== false) {
                Swal.fire({
                    icon: 'error',
                    title: response.data,
                })
            }
        } else {
            toast.error(response.data.message)
        }
    }
    const AlsoTimeTracking = async () => {
        var data = {
            id: props.shiftid,
            empid: props.SelectedEmployeeId.toString(),
            checkWorkarea: props.checkWorkarea
        }
        if (props.isShiftalreadyassigned) {
            var response = await CallAxios("/api/Calendar/RemoveFromShiftAndTimetracking|post", data)
        }
        else {
            var response = await CallAxios("/api/Calendar/AssignShiftAndTimetracking|post", data)
            if (response.data === "reached limit") {
                setForceShiftId(props.shiftid)
                setShowExceedLimitModel(true);
                return;
            }
        }
        if (!!response && response.status === 200) {
            if (response.data === true) {
                props.getShifts();
                props.GetEmployeeList(props.calendarComponentRef.current.activeRange.start);
                if (!!props.getunoccupiedList) {
                    props.getunoccupiedList();
                }
                props.handleModal()
            } else if (response.data !== false) {
                Swal.fire({
                    icon: 'error',
                    title: response.data,
                })
                if (response.data === "Employee is already assigned to a shift in the same range.") {
                    props.getShifts();
                }
            }
        }
    }
    const ForceassignShiftDirectly = async () => {
        var data = {
            id: forceShiftId,
            empid: props.SelectedEmployeeId.toString(),
            checkWorkarea: props.checkWorkarea,
            assignForcefully: true
        }
        var URL = "";
        if (CosenType === "Also time tracking") {
            URL = "/api/Calendar/AssignShiftAndTimetracking|post";
        } else {
            URL = "/api/Calendar/assignShiftDirectly|post";
        }
        var response = await CallAxios(URL, data);
        if (!!response && response.status === 200) {
            if (response.data === true) {
                props.getShifts();
                props.GetEmployeeList(props.calendarComponentRef.current.activeRange.start);
                if (!!props.getunoccupiedList) {
                    props.getunoccupiedList();
                }
                props.handleModal()
            }
            else if (response.data !== false) {
                Swal.fire({
                    icon: 'error',
                    title: response.data,
                })
            }
        }
    }
    return (
        <>
            {
                ShowExceedLimitModel === true ?
                    <Modal show={ShowExceedLimitModel} onHide={() => setShowExceedLimitModel(false)} >
                        <Modal.Header closeButton >
                            <Modal.Title>Information</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <label className='fw-bolder'> {cl("pastShiftConfirmMdl.informationDesc")} </label>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <a className='text-danger me-3' onClick={() => setShowExceedLimitModel(false)}> {ct("cancel")}</a>
                            <Button className="btn btnPrimaryGreen" onClick={() => ForceassignShiftDirectly()}> {ct("forceAssignment")} </Button>
                        </Modal.Footer>
                    </Modal>
                    : null
            }
            <Modal show={props.show} onHide={() => props.handleModal()} >
                <Modal.Header className="" closeButton >
                    <Modal.Title> {ct("warning")} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p> {cl("pastShiftConfirmMdl.warningDesc")}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btnPrimaryGreen me-3" onClick={() => { setCosenType("Assignment only"); Assignmentonly() }}> {cl("pastShiftConfirmMdl.assignmentOnly")} </Button>
                    <Button className="btn btnPrimaryGreen" onClick={() => { setCosenType("Also time tracking"); AlsoTimeTracking() }}> {cl("pastShiftConfirmMdl.alsoTimeTracking")} </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default DirectAssignPastShiftConfirmationModel;
