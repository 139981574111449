import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CallAxios } from "../../Helpers";
import { SignInManager } from "../../Helpers/Authentication/SignInManager";
import { UpdateReduxStates } from "../../Helpers/MasterData";

function Verifyemail() {
    let navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const isAuthenticated = new SignInManager().IsAuthenticated;
    const ParseJwt = new SignInManager().ParseJwt;
    const dispatch = useDispatch();

    useEffect(() => {
        onLoad()
    }, []);
    const onLoad = async () => {
        if (!!token) {
            var response = await CallAxios("/api/Account/verifyemail|post", { id: token })
            if (!!response && response.data === true) {
                toast.success('Your Email is verified.')
                if (isAuthenticated) {
                    UpdateReduxStates(ParseJwt, dispatch)
                    navigate(`/companies/${ParseJwt.CompanyId}/users/${ParseJwt.EmpID}/master_data`)
                } else {
                    navigate("/login")
                }
            } else {
                toast.error('Something went wronge')
            }
        }
    }

    return (
        <>
        </>
    )
}
export default Verifyemail;