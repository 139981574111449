import { saveAs } from 'file-saver';
import { useFormik } from 'formik';
import moment from "moment";
import { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import DatePicker from 'react-datepicker';
import { BsCloudDownload } from "react-icons/bs";
import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import * as Yup from 'yup';
import { Edit } from "../../Components/ActionBtns";
import InputImoji from "../../Components/inputImoji";
import { CallAxios, CallAxiosBlob, CallAxiosNoloader } from "../../Helpers";
import { SignInManager } from "../../Helpers/Authentication/SignInManager";
import { Constants } from "../../Helpers/Contents";
import { toast } from 'react-toastify';
import { LanguageList } from '../../CommonJSON';
import { UpdateReduxStates } from '../../Helpers/MasterData';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { CheckBox, ChooseAll, OptionBox, OptionText } from '../../Helpers/Global/StyledComponents';
import CreatableSelect from 'react-select/creatable';

const BodyWrapper = styled.div`
padding:0 8px;

& .UiInfoFlex{
    display:flex;
    align-items:center; 
    margin-bottom:15px;
}
& .UiInfoFlex .InfoLabel{
    margin-right:5px;
    font-weight:600;
}
& .smallLabel .form-check-label{
    font-size:14px;
    line-height:1.4;
}
`

function GeneralTab() {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');

    const { userid } = useParams();
    const location = useLocation();
    const currentView = useSelector(x => x.app.currentView)
    const user = useSelector(x => x.app.user)
    const navigate = useNavigate();
    const [ProfileData, setProfileData] = useState({});
    const [showBaseDataModal, setBaseDataModal] = useState(false);
    const [ContactModal, setContactModal] = useState(false);
    const [showMiscellaneousModal, setMiscellaneousModal] = useState(false);
    const [PlaceTimeRightModal, setPlaceTimeRightModal] = useState(false);
    const [DeactivateEmployee, setDeactivateEmployee] = useState(false);
    const [openDynamicForm, setOpenDynamicForm] = useState(false);
    const [DynamicFormObject, setDynamicFormObject] = useState({});
    const [dynamicFormData, setdynamicFormData] = useState();
    const themeColor = useSelector(state => state.app.theme.colors.buttons);


    useEffect(() => {
        getProfileData();
        getProfileDataForm();
    }, []);
    useEffect(() => {
        getProfileData();
        getProfileDataForm();
    }, [location]);
    const getProfileData = async () => {
        var res = await CallAxiosNoloader("/api/Employee/getEmployeebyurlID|post", { id: userid });
        if (!!res && res.status === 200) {
            setProfileData(res.data)
        }
    }
    const ActivedeactiveEmployee = async () => {
        var data = {
            id: userid.toString()
        }
        var response = await CallAxios('/api/Employee/ActiveDeactiveEmployee|post', data)
        if (!!response && response.data === true) {
            // this.setState({
            //     DeactivateEmployee: false
            // })
            getProfileData()
        }
    }
    const ExportData = async () => {
        var data = {
            id: userid.toString()
        }
        var response = await CallAxiosBlob('/api/Employee/ExportEmployee|post', data)
        if (!!response && response.status === 200) {
            saveAs(response.data, "profile.pdf")
        }
    }
    const getProfileDataForm = async () => {
        var response = await CallAxios('/api/ProfileData/GetProfileDataList|post', { id: userid })
        if (!!response && response.status === 200) {
            setdynamicFormData(response.data)
        }
    }
    const ResetPassword = () => {
        var logout = new SignInManager().Logout(navigate);
    }
    return <>
        <BodyWrapper>
            <Row>
                <Col md={5}>
                    <div className="SectionInfoWrapper">
                        <div className="SectionInfoHeading d-flex align-items-center justify-content-between mb-2">
                            <span className="h5 fw-bold "> {t('general')}</span>
                            <Edit color={themeColor} className="ActionIcon" onClick={() => setBaseDataModal(true)} />
                        </div>
                        <div className="pb-3">
                            <div className="UiInfoFlex">
                                <div className="InfoLabel"> {t('generalTab.userName')}:</div>
                                <div className="InfoValue">{ProfileData.surname}</div>
                            </div>
                            <div className="UiInfoFlex">
                                <div className="InfoLabel">{t('generalTab.abbreviation')}:</div>
                                <div className="InfoValue">{ProfileData.abbreviation}</div>
                            </div>
                            <div className="UiInfoFlex">
                                <div className="InfoLabel"> {t('generalTab.staffNumber')}:</div>
                                <div className="InfoValue">{ProfileData.personnelnumber}</div>
                            </div>
                            <div className="UiInfoFlex">
                                <div className="InfoLabel"> {ct('birthday')}:</div>
                                <div className="InfoValue">{!!ProfileData.birthday ? new Date(ProfileData.birthday).toLocaleDateString() : ""}</div>
                            </div>
                            <div className="UiInfoFlex">
                                <div className="InfoLabel"> {t('generalTab.mobilePin')}:</div>
                                <div className="InfoValue">{ProfileData.mobilePIN}</div>
                            </div>
                        </div>
                    </div>

                    <div className="SectionInfoWrapper">
                        <div className="SectionInfoHeading d-flex align-items-center justify-content-between mb-2">
                            <span className="h5 fw-bold "> {t('generalTab.placeTimeRights')}</span>
                            <Edit color={themeColor} className="ActionIcon" onClick={() => setPlaceTimeRightModal(true)} />
                        </div>
                        <div className="pb-3">

                            <div className="UiInfoFlex mb-3">
                                <div className="InfoLabel "> {ct('workspace')}:</div>
                                <div className="InfoValue">{ProfileData.empAreas?.toString()}</div>
                            </div>
                            <div className="UiInfoFlex mb-3">
                                <div className="InfoLabel "> {ct('tags')}:</div>
                                <div className="InfoValue">{ProfileData.tags}</div>
                            </div>
                            <div className="UiInfoFlex mb-3">
                                <div className="InfoLabel "> {ct('timeZone')}:</div>
                                <div className="InfoValue">{ProfileData.timeZone}</div>
                            </div>
                            <div className="UiInfoFlex mb-3">
                                <div className="InfoLabel "> {ct('language')}:</div>
                                <div className="InfoValue">{!!ProfileData.language ? LanguageList.find(x => x.value === ProfileData.language).label : ""}</div>
                            </div>
                            <div className="UiInfoFlex mb-3  d-block">
                                <div className="InfoLabel  d-block mb-2"> {t('generalTab.homeOffice')}</div>
                                <div className="InfoValue">
                                    <Form.Group controlId="EmpCanStamp" className="mb-0 smallLabel">
                                        <Form.Check type="checkbox" disabled label={t('generalTab.homeOfficeLabel')} checked={ProfileData.stampFromAnywhere} />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="UiInfoFlex  d-block">
                                <div className="InfoLabel  d-block mb-2"> {t('generalTab.timeRecords')}</div>
                                <div className="InfoValue">
                                    <Form.Group controlId="EmpCancreateRecord" className="mb-0 smallLabel">
                                        <Form.Check type="checkbox" disabled label={t('generalTab.timeRecordsLabel')} checked={ProfileData.createOwnTimeRecords} />
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={5}>
                    {
                        !!dynamicFormData && dynamicFormData.length > 0 ?
                            dynamicFormData.map((val, key) => {
                                return <div className="SectionInfoWrapper">
                                    <div className="SectionInfoHeading d-flex align-items-center justify-content-between mb-2">
                                        <span className="h5 fw-bold ">{val.title}</span>
                                        <Edit color={themeColor} className="ActionIcon" onClick={() => { setDynamicFormObject(val); setOpenDynamicForm(true) }} />
                                    </div>
                                    <div className="pb-3">
                                        {
                                            val.fields.length > 0 ?
                                                val.fields.map((v, k) => {
                                                    return <div className="UiInfoFlex">
                                                        <div className="InfoLabel">{v.title}:</div>
                                                        <div className="InfoValue">
                                                            {
                                                                v.dataType === "date" && !!v.employeevalue ? new Date(v.employeevalue).toDateString() :
                                                                    v.dataType === "dropdown" && !!v.employeevalue ? val.fields.find(x => x.fieldId === v.fieldId).options.filter(x => v.employeevalue.split(",").some(c => c === x.id.toString())).map(x => x.option + ", ")
                                                                        : v.employeevalue}</div>
                                                    </div>
                                                })
                                                : null
                                        }
                                    </div>
                                </div>
                            })
                            : null
                    }
                    <div className="SectionInfoWrapper">
                        <div className="SectionInfoHeading  d-flex align-items-center justify-content-between mb-2">
                            <span className="h5 fw-bold "> {ct('contact')}</span>
                            <Edit color={themeColor} className="ActionIcon" onClick={() => setContactModal(true)} />
                        </div>
                        <div className="pb-3">
                            <div className="UiInfoFlex">
                                <div className="InfoLabel"> {ct('phoneNumber')}:</div>
                                <div className="InfoValue">{ProfileData.phonenumber}</div>
                            </div>
                            <div className="UiInfoFlex">
                                <div className="InfoLabel"> {ct('email')}:</div>
                                <div className="InfoValue">{ProfileData.email}</div>
                            </div>
                        </div>
                    </div>

                    <div className="SectionInfoWrapper">
                        <div className="SectionInfoHeading d-flex align-items-center justify-content-between mb-2">
                            <span className="h5 fw-bold "> {t('generalTab.miscellaneous')}</span>
                            <Edit color={themeColor} className="ActionIcon" onClick={() => setMiscellaneousModal(true)} />
                        </div>
                        <div className="pb-3">
                            <div className="UiInfoFlex">
                                <div className="InfoLabel">{t('generalTab.startWork')} :</div>
                                <div className="InfoValue">{!!ProfileData.startOfWork ? new Date(ProfileData.startOfWork).toLocaleDateString() : ""}</div>
                            </div>
                            <div className="UiInfoFlex">
                                <div className="InfoLabel">{t('generalTab.endProbationaryPeriod')} :</div>
                                <div className="InfoValue">{!!ProfileData.endProbationaryPeriod ? new Date(ProfileData.endProbationaryPeriod).toLocaleDateString() : ""}</div>
                            </div>
                            <div className="UiInfoFlex">
                                <div className="InfoLabel">{t('generalTab.endContract')} :</div>
                                <div className="InfoValue">{!!ProfileData.endOfContract ? new Date(ProfileData.endOfContract).toLocaleDateString() : ""}</div>
                            </div>
                            <div className="UiInfoFlex">
                                <div className="InfoLabel">{t('generalTab.note')} :</div>
                                <div className="InfoValue">{ProfileData.note}</div>
                            </div>
                            <div className="UiInfoFlex">
                                <div className="InfoLabel">{t('generalTab.costCenter')} :</div>
                                <div className="InfoValue">{ProfileData.costCentre}</div>
                            </div>
                            <div className="UiInfoFlex">
                                <div className="InfoLabel">{t('generalTab.costObject')} :</div>
                                <div className="InfoValue">{ProfileData.payers}</div>
                            </div>

                        </div>
                    </div>
                </Col>
                <Col md={2}>
                    <div className="text-end">
                        <BsCloudDownload fontSize={35} style={{ "cursor": "pointer" }} className="exportIcon" onClick={() => ExportData()} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="py-3 border-top">


                        <div className="d-flex align-items-center justify-content-between">
                            {
                                currentView === Constants.layoutType.Admin && user.roles.length > 0 && user.roles.some(x => x === Constants.roles.WorkspaceAdmin || x === Constants.roles.AccountAdmin || x === Constants.roles.Admin) ?
                                    <Link className="linkButton" to={`/account/${user.CompanyId}/accountsettings/data_profiles`}>  {t('generalTab.createFurtherData')}</Link>
                                    : null
                            }
                            <div className="d-flex align-items-center justify-content-between">
                                {user.roles.length > 0 && user.roles.find(x => x === Constants.roles.WorkspaceAdmin || x === Constants.roles.AccountAdmin)
                                    && ProfileData.empID !== user.LoginUserId ?
                                    ProfileData.isActive === true ?
                                        <div className="UiInfoFlex mb-0">
                                            <Button variant="primary" onClick={() => setDeactivateEmployee(true)} size="sm"> {t('generalTab.deactivateEmployee')}</Button>
                                        </div>
                                        : <div className="UiInfoFlex mb-0">
                                            <Button variant="primary" onClick={() => ActivedeactiveEmployee()} size="sm"> {t('generalTab.activateEmployee')} </Button>
                                        </div>
                                    :
                                    null
                                }
                                {
                                    user.LoginUserId === userid ?
                                        <div className="UiInfoFlex ms-2 mb-0">
                                            <Button variant="light" size="sm" onClick={() => {
                                                ResetPassword();
                                            }}> {t('generalTab.resetPassword')} </Button>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </BodyWrapper>
        {
            DeactivateEmployee === true ?
                <DeactivateEmployeeMOdal show={DeactivateEmployee} onHide={setDeactivateEmployee} getProfileData={getProfileData} />
                : null
        }
        {/*BaseData Modal popups start*/}
        {showBaseDataModal === true ?
            <BaseDataModal show={showBaseDataModal} empdata={ProfileData} onHide={setBaseDataModal} getProfileData={getProfileData} />
            : null}
        {/*Contact Modal popups start*/}
        {ContactModal === true ?
            <EmployeeContactModal show={ContactModal} empdata={ProfileData} onHide={setContactModal} getProfileData={getProfileData} />
            : null}
        {/*Miscellaneous Modal popups start*/}

        {showMiscellaneousModal === true ?
            <MiscellaneousModal show={showMiscellaneousModal} empdata={ProfileData} onHide={setMiscellaneousModal} getProfileData={getProfileData} />
            : null}
        {/*Place, time and rights Modal popups start*/}
        {PlaceTimeRightModal === true ?
            <PlaceTimeAndRightsModal show={PlaceTimeRightModal} empdata={ProfileData} onHide={setPlaceTimeRightModal} getProfileData={getProfileData} />
            : null
        }
        {
            openDynamicForm === true ?
                <DynamicFormModal show={openDynamicForm} onHide={setOpenDynamicForm} form={DynamicFormObject} getProfileDataForm={getProfileDataForm} getProfileData={getProfileData} />
                : null
        }
    </>
}

export default GeneralTab;
function DynamicFormModal(props) {
    const { t: ct } = useTranslation('common');

    const { userid } = useParams();
    const [fields, setFields] = useState();
    const [newFields, setNewFields] = useState();
    const [ShowErrorModal, setShowErrorModal] = useState(false);
    const [ErrorRegex, setErrorRegex] = useState("");
    const [ErrorValue, setErrorValue] = useState("");
    const { getData } = require('country-list');
    useEffect(() => {
        if (!!props.form) {
            var list = [];
            props.form.fields.map((val, key) => {
                var obj = {};
                obj.fieldid = val.id;
                obj.value = val.employeevalue;
                obj.datatype = val.dataType;
                obj.IsMultipal = val.isMultipal;
                list.push(obj);
            });
            setFields(list)

            var list2 = [];
            props.form.fields.map((item, index) => {
                var obj = {};
                obj.dataType = item.dataType;
                obj.employeevalue = item.employeevalue;
                obj.fieldId = item.fieldId;
                obj.id = item.id;
                obj.isMultipal = item.isMultipal;
                obj.options = item.options;
                obj.regex = obj.regex;
                obj.title = item.title;
                list2.push(obj);
            });
            setNewFields(list2);
        }
    }, [props.form]);
    const setValue = (e, id, datatype, Fieldvalue) => {
        if (datatype === "date") {
            fields.find(x => x.fieldid === id).value = Fieldvalue
        } else {
            fields.find(x => x.fieldid === id).value = Fieldvalue.toString()
        }
    }

    const SaveForm = async () => {
        // setErrorMessage([]);
        var IsAllRegexValid = true;
        fields.map((val, key) => {
            var CurrentParentField = props.form.fields.find(x => x.id === val.fieldid)
            if (!!CurrentParentField.regex) {
                if (!!val.value && val.value.match(CurrentParentField.regex) === null) {
                    IsAllRegexValid = false;
                    setShowErrorModal(true)
                    setErrorRegex(CurrentParentField.regex)
                    setErrorValue(val.value)
                    // setErrorMessage([...ErrorMessage,CurrentParentField.title+" is not valid."])
                }
            }
        })
        if (IsAllRegexValid) {
            var data = {
                EmpId: userid,
                ProfileData: fields
            }
            var response = await CallAxios('/api/ProfileData/SaveProfileData|post', data)
            if (!!response && response.status === 200) {
                props.getProfileDataForm()
                props.onHide(false)
            }
        }
    }
    const SetDate = (date, id, index) => {
        // 
        // console.log(newFields+"index is ="+index);
        let newState = [...newFields];
        newState[index].employeevalue = date;
        setNewFields(newState);
        // console.log(newFields);
    }

    return (
        <>
            {
                ShowErrorModal === true ?
                    <Modal show={ShowErrorModal} onHide={() => { setErrorRegex(""); setShowErrorModal(false) }}>
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body >
                            <Form>
                                <Row>
                                    <Col md={12} >
                                        <p>
                                            Error: Input format doesn't match the requirement. Custom field value {ErrorValue} should match regex: {ErrorRegex}
                                        </p>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal.Body>
                    </Modal>
                    : null
            }
            <Modal show={props.show} onHide={() => props.onHide(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.form.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Form>
                        <Row>
                            {
                                !!newFields ?
                                    newFields.map((val, key) => {
                                        return <>
                                            {


                                                val.dataType === "string" ?
                                                    <Col md={12} >
                                                        <Form.Label className="setLabelPosition">{val.title}</Form.Label>
                                                        <div className="position-relative">
                                                            <Form.Group>
                                                                <Form.Control type="text" defaultValue={val.employeevalue} pattern={val.regex} onChange={(e) => { setValue(e, val.id, val.dataType, e.target.value) }} className="ThickBorder my-3 pr40" />
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    : val.dataType === "float" ?
                                                        <Col md={12} >
                                                            <Form.Label className="setLabelPosition">{val.title}</Form.Label>
                                                            <div className="position-relative">
                                                                <Form.Group>
                                                                    <Form.Control type="number" defaultValue={val.employeevalue} pattern={val.regex} onChange={(e) => setValue(e, val.id, val.dataType, e.target.value)} className="ThickBorder my-3 pe-0" />
                                                                </Form.Group>
                                                            </div>
                                                        </Col>
                                                        : val.dataType === "boolean" ?
                                                            <Col md={12}>
                                                                <div className="UiInfoFlex  d-flex align-items-center">
                                                                    <div className="InfoLabel  d-block">{val.title}</div>
                                                                    <div className="InfoValue">
                                                                        <Form.Group className="mb-0">
                                                                            <Form.Check type="checkbox" className="d-flex align-items-center" pattern={val.regex} onChange={(e) => setValue(e, val.id, val.dataType, e.target.checked)} checked={val.employeevalue} />
                                                                        </Form.Group>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            : val.dataType === "date" ?
                                                                <Col md={12} >
                                                                    <Form.Group controlId="MiscellaneousForm.txtStartofwork">
                                                                        <Form.Label>{val.title}</Form.Label>
                                                                        <div className="w-100"><DatePicker className="form-control" regex={val.regex} onChange={(e) => { SetDate(e, val.id, key); setValue(e, val.id, val.dataType, e) }} selected={new Date()} showYearDropdown /></div>
                                                                    </Form.Group>
                                                                </Col>
                                                                : val.dataType === "time" ?
                                                                    <Col md={12} >
                                                                        <Form.Group controlId="MiscellaneousForm.txtStartofwork">
                                                                            <Form.Label>{val.title}</Form.Label>
                                                                            <div className="w-100"><Form.Control type="time" defaultValue={val.employeevalue} pattern={val.regex} onChange={(e) => setValue(e, val.id, val.dataType, e.target.value)} className="ThickBorder my-3" /></div>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    : val.dataType === "country_select" ?
                                                                        <Col md={12}>
                                                                            <Form.Group controlId="PlaceTimeRightForm.SelectTimeZone" custom>
                                                                                <Form.Label>{val.title}</Form.Label>
                                                                                <Form.Control as="select" className="SelectbgGrey" pattern={val.regex} onChange={(e) => setValue(e, val.id, val.dataType, e.target.value)} defaultValue={val.employeevalue} custom>
                                                                                    <option value="">Please select</option>
                                                                                    {
                                                                                        getData().map((v, k) => {
                                                                                            return <option value={v.name}>{v.code}</option>
                                                                                        })
                                                                                    }
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                        : val.dataType === "dropdown" ?
                                                                            <Col md={12} >

                                                                                {
                                                                                    val.isMultipal === false ?
                                                                                        <Form.Group controlId="PlaceTimeRightForm.SelectTimeZone" custom>
                                                                                            <Form.Label>{val.title}</Form.Label>
                                                                                            <Form.Control as="select" className="SelectbgGrey" pattern={val.regex} onChange={(e) => setValue(e, val.id, val.dataType, e.target.value)} defaultValue={val.employeevalue} custom>
                                                                                                <option value="">Please select</option>
                                                                                                {
                                                                                                    val.options.length > 0 ?
                                                                                                        val.options.map((v, k) => {
                                                                                                            return <option value={v.id}>{v.option}</option>
                                                                                                        })
                                                                                                        : null
                                                                                                }
                                                                                            </Form.Control>
                                                                                        </Form.Group>
                                                                                        :
                                                                                        <DropdownMultiselect selected={!!val.employeevalue ? val.employeevalue.split(',') : []} handleOnChange={(selected) => setValue(selected, val.id, val.dataType, selected)} optionKey="id" optionLabel="option" options={val.options} placeholder="Please select" buttonClass="btnDDList smWidth gray-dropdown" />
                                                                                }

                                                                            </Col>
                                                                            : null
                                            }
                                        </>
                                    })
                                    : null
                            }

                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btnPrimaryGreen" onClick={(e) => SaveForm()}>{ct('finished')}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

function PlaceTimeAndRightsModal(props) {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');

    const { userid } = useParams();
    const user = useSelector(x => x.app.user)
    const appSettings = useSelector(s => s.app.appSettings);
    const listWorkSpaces = appSettings.WorkSpaces;
    const [TimeZone, setTimeZone] = useState("");
    const [Language, setLanguage] = useState("");
    const [IsSelfAssignShift, setIsSelfAssignShift] = useState("");
    const [createOwnTimeRecords, setcreateOwnTimeRecords] = useState("");
    const dispatch = useDispatch();
    const [EmpworkspaceDD, setEmpWorkspaceDD] = useState([]);
    const [tagsDD, settagsDD] = useState([]);


    useEffect(() => {
        getEmployeeworkspacesDD();
        getTagsDD();
        setTimeZone(!!props.empdata ? props.empdata.timeZone : "")
        setLanguage(!!props.empdata && !!props.empdata.language ? props.empdata.language : "de")
        setIsSelfAssignShift(!!props.empdata ? props.empdata.stampFromAnywhere : false)
        setcreateOwnTimeRecords(!!props.empdata ? props.empdata.createOwnTimeRecords : false)

    }, [props.empdata]);
    const getEmployeeworkspacesDD = async () => {
        var response = await CallAxios("/api/Employee/GetEmplyeeWorkSpaceDD|post", { id: userid });
        if (!!response && response.data.length > 0) {
            setEmpWorkspaceDD(response.data)
        }
    }
    const DropdownIndicator = () => null;
    const PlaceTimeAndRights = async () => {

        var employee = {
            Id: userid,
            TimeZone: TimeZone,
            Language: Language,
            IsSelfAssignShift: IsSelfAssignShift,
            createOwnTimeRecords: createOwnTimeRecords,
            WorkAreas: EmpworkspaceDD
        };
        var res = await CallAxios("/api/Employee/updatePlaceandRights|post", employee)
        if (!!res && res.status === 200) {
            await UpdateReduxStates(new SignInManager().ParseJwt, dispatch)
            props.getProfileData()
            props.onHide(false)
        }
    }

    const handleChooseAllClick = () => {
        if (listWorkSpaces.length > 0) {
            if (EmpworkspaceDD.length === listWorkSpaces.length) {
                dispatch(
                    setEmpWorkspaceDD([])
                );
            } else {
                dispatch(
                    setEmpWorkspaceDD(listWorkSpaces)
                );
            }
        }
    }
    function handleItemClick(val) {
        const findobj = EmpworkspaceDD.find(x => x.value === val.value);
        const index = EmpworkspaceDD.indexOf(findobj);
        if (index === -1) {
            dispatch(
                setEmpWorkspaceDD([...EmpworkspaceDD, val])
            );
        } else {
            dispatch(
                setEmpWorkspaceDD(EmpworkspaceDD.filter(x => x !== findobj))
            );
        }
    }
    const getTagsDD = async () => {
        var responseTags = await CallAxios("/api/Employee/EmployeeTags|post", { id: userid });
        if (!!responseTags && responseTags.status === 200) {
            settagsDD(responseTags.data)
        }
    }
    return (
        <Modal show={props.show} onHide={() => props.onHide(false)}>
            <Modal.Header closeButton>
                <Modal.Title> {t('generalTab.placeTimeRights')}</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Form>
                    <Row>
                        <Col md={12} className='mb-1'>
                            <DropdownButton autoClose="outside" className='check-select' autoWidth title={ct('workingArea')}>
                                <Dropdown.Item className="choose-all">
                                    <ChooseAll onClick={handleChooseAllClick}>
                                        {EmpworkspaceDD.length === listWorkSpaces.length ? <>{ct('selectNone')}</> : <>{ct('selectAll')}</>}
                                    </ChooseAll>
                                </Dropdown.Item>
                                {(listWorkSpaces || []).map((item, index) =>
                                    <>
                                        {
                                            <Dropdown.Item onClick={() => handleItemClick(item)} key={index}>
                                                <OptionBox>
                                                    <CheckBox type="checkbox" disablePointerEvent={true} checked={(EmpworkspaceDD.some(x => x.value === item.value))} size="sm" />
                                                    <OptionText>{item.label}</OptionText>
                                                </OptionBox>
                                            </Dropdown.Item>
                                        }
                                    </>
                                )}
                            </DropdownButton>
                        </Col>
                        <Col md={12} className='mb-1'>
                            <Form.Label> {ct('tags')}</Form.Label>
                            <CreatableSelect
                                classNamePrefix="MyReactSelect"
                                isMulti
                                isClearable={false}
                                components={{ DropdownIndicator }}
                                options={tagsDD}
                                value={tagsDD}
                                getOptionValue={(option) => option.value}
                                styles={{
                                    indicatorSeparator: base => ({
                                        ...base,
                                        display: 'none'
                                    })
                                }}
                                onChange={async (value, action) => {
                                    var newtag = value.find(x => x.__isNew__ === true);
                                    if (!!newtag) {
                                        var data = {
                                            id: userid,
                                            tagname: newtag.label
                                        }
                                        var response = await CallAxiosNoloader("/api/LocationSetting/AddEditEmployeeTags|post", data)
                                        if (!!response && response.status === 200) {
                                            if (response.data.success === true) {
                                                var newobj = { value: response.data.data.id, label: newtag.label }
                                                value.splice(value.findIndex(x => x.__isNew__ === true), 1)
                                                value.push(newobj)
                                            } else {
                                                toast.error(response.data.message)
                                            }
                                        }
                                    } else {
                                        var data = {
                                            id: action.removedValue.value,
                                        }
                                        var response = await CallAxiosNoloader("/api/LocationSetting/DeleteTags|post", data)
                                        getTagsDD()
                                    }
                                    props.getProfileData()
                                    // let stringarr = [];
                                    // for (let i = 0; i < value.length; i++) {
                                    //     stringarr.push(value[i].value)
                                    // }
                                    // setTags(stringarr)
                                }}

                            />
                        </Col>
                        <Col md={12} className='mb-1'>
                            <Form.Group className='mb-3' controlId="PlaceTimeRightForm.SelectTimeZone" value={TimeZone} onChange={(e) => setTimeZone(e.target.value)}>
                                <Form.Label> {ct('timeZone')}</Form.Label>
                                <Form.Control as="select" className="SelectbgGrey form-select" disabled={user.roles.length === 1 && user.roles.some(x => x === Constants.roles.Employee) ? true : false} custom>
                                    <option>{ct('timeZone')}</option>
                                    <option>(GMT-12: 00) International Date Line West</option>
                                    <option>(GMT-11: 00) American Samoa</option>
                                    <option>(GMT-11: 00) Midway Island</option>
                                    <option>(GMT-10: 00) Hawaii</option>
                                    <option>(GMT-09: 00) Alaska</option>
                                    <option>(GMT-08: 00) Pacific Time (US & Canada)</option>
                                    <option>(GMT-08: 00) Tijuana</option>
                                    <option>(GMT-07: 00) Arizona</option>
                                    <option>(GMT-07: 00) Chihuahua</option>
                                    <option>(GMT-07: 00) Mazatlan</option>
                                    <option>(GMT-07:00) Mountain Time (US & Canada)</option>
                                    <option>(GMT-06:00) Central America</option>
                                    <option>(GMT-06:00) Central Time (US & Canada)</option>
                                    <option>(GMT-06:00) Guadalajara</option>
                                    <option>(GMT-06:00) Mexico City</option>
                                    <option>(GMT-06:00) Monterrey</option>
                                    <option>(GMT-06:00) Saskatchewan</option>
                                    <option>(GMT-05:00) Bogota</option>
                                    <option>(GMT-05:00) Eastern Time (US & Canada)</option>
                                    <option>(GMT-05:00) Indiana (East)</option>
                                    <option>(GMT-05:00) Lima</option>
                                    <option>(GMT-05:00) Quito</option>
                                    <option>(GMT-04:00) Atlantic Time (Canada)</option>
                                    <option>(GMT-04:00) Caracas</option>
                                    <option>(GMT-04:00) Georgetown</option>
                                    <option>(GMT-04:00) La Paz</option>
                                    <option>(GMT-04:00) Puerto Rico</option>
                                    <option>(GMT-04:00) Santiago</option>
                                    <option>(GMT-03:30) Newfoundland</option>
                                    <option>(GMT-03:00) Brasilia</option>
                                    <option>(GMT-03:00) Buenos Aires</option>
                                    <option>(GMT-03:00) Greenland</option>
                                    <option>(GMT-03:00) Montevideo</option>
                                    <option>(GMT-02:00) Mid-Atlantic</option>
                                    <option>(GMT-01:00) Azores</option>
                                    <option>(GMT-01:00) Cape Verde Is.</option>
                                    <option>(GMT+00:00) Dublin</option>
                                    <option>(GMT+00:00) Edinburgh</option>
                                    <option>(GMT+00:00) Lisbon</option>
                                    <option>(GMT+00:00) London</option>
                                    <option>(GMT+00:00) Monrovia</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={12} className='mb-1'>
                            <Form.Group className='mb-3' controlId="PlaceTimeRightForm.SelectLanguage">
                                <Form.Label> {ct('language')}</Form.Label>
                                <Form.Control as="select" className="SelectbgGrey" value={Language} onChange={(e) => setLanguage(e.target.value)} custom>
                                    {/* <option value=""></option> */}
                                    {/* <option>Deutsch</option>
                                    <option>English</option> */}
                                    {LanguageList?.map(x =>
                                        <option value={x.value}>{x.label}</option>
                                    )}
                                    {/* <option>German (Switzerland)</option> */}
                                    {/* <option>English UK</option> */}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <div className="UiInfoFlex mb-3 d-block">
                                <div className="InfoLabel fw-bold  d-block"> {t('generalTab.homeOffice')}</div>
                                <div className="InfoValue">
                                    <Form.Group className="mb-0 d-flex align-items-start">
                                        <Form.Check type="checkbox" className="d-flex align-items-center" disabled={user.roles.length === 1 && user.roles.some(x => x === Constants.roles.Employee) ? true : false} label="" checked={IsSelfAssignShift} onChange={(e) => setIsSelfAssignShift(e.target.checked)} />
                                        <span className="text-break">{t('generalTab.homeOfficeLabel')} {ct('timeTracking')}</span>
                                    </Form.Group>

                                </div>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="UiInfoFlex mb-3 d-block">
                                <div className="InfoLabel fw-bold  d-block"> {t('generalTab.timeRecords')}</div>
                                <div className="InfoValue">
                                    <Form.Group className="mb-0 d-flex align-items-start">
                                        <Form.Check type="checkbox" className="d-flex align-items-center text-break" disabled={user.roles.length === 1 && user.roles.some(x => x === Constants.roles.Employee) ? true : false} label="" checked={createOwnTimeRecords} onChange={(e) => setcreateOwnTimeRecords(e.target.checked)} />
                                        <span className="text-break">{t('generalTab.timeRecordsLabel')}</span>
                                    </Form.Group>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btnPrimaryGreen" onClick={(e) => PlaceTimeAndRights()}>{ct('save')}</Button>
            </Modal.Footer>
        </Modal>
    )
}
function MiscellaneousModal(props) {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');

    const { userid } = useParams();
    const [StartOfWork, setStartOfWork] = useState("");
    const [EndProbationaryPeriod, setEndProbationaryPeriod] = useState("");
    const [EndOfContract, setEndOfContract] = useState("");
    const [Note, setNote] = useState("");
    const [CostCentre, setCostCentre] = useState("");
    const [Payers, setPayers] = useState("");



    useEffect(() => {
        setPayers(!!props.empdata ? props.empdata.payers : "")
        setCostCentre(!!props.empdata ? props.empdata.costCentre : "")
        setNote(!!props.empdata ? props.empdata.note : "")
        setEndOfContract(!!props.empdata?.endOfContract ? new Date(props.empdata.endOfContract) : new Date())
        setEndProbationaryPeriod(!!props.empdata?.endProbationaryPeriod ? new Date(props.empdata.endProbationaryPeriod) : new Date())
        setStartOfWork(!!props.empdata?.startOfWork ? new Date(props.empdata.startOfWork) : new Date())

    }, [props.empdata]);

    const MiscellaneousUpdate = async () => {
        var employee = {
            Id: userid,
            StartOfWork: new Date(StartOfWork),
            EndProbationaryPeriod: new Date(EndProbationaryPeriod),
            EndOfContract: new Date(EndOfContract),
            Note: Note,
            CostCentre: CostCentre,
            Payers: Payers,

        };
        var res = await CallAxios("/api/Employee/updateOtherInfo|post", employee)
        if (!!res && res.status === 200) {
            props.getProfileData()
            props.onHide(false)
        }

    }
    return (
        <Modal show={props.show} onHide={() => props.onHide(false)}>
            <Modal.Header closeButton>
                <Modal.Title> {t('generalTab.miscellaneous')}</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Form>
                    <Row>
                        <Col md={12} >
                            <Form.Group controlId="MiscellaneousForm.txtStartofwork">
                                <Form.Label> {t('generalTab.startWork')}</Form.Label>
                                <div className="w-100"><DatePicker className="form-control" selected={StartOfWork} onChange={(date) => setStartOfWork(date)} showYearDropdown /></div>
                            </Form.Group>
                        </Col>

                        <Col md={12} >
                            <Form.Group controlId="MiscellaneousForm.txtEndOfProbationaryPeriod">
                                <Form.Label> {t('generalTab.endProbationaryPeriod')}</Form.Label>
                                <div className="w-100"><DatePicker className="form-control" selected={EndProbationaryPeriod} onChange={(date) => setEndProbationaryPeriod(date)} showYearDropdown /></div>
                            </Form.Group>
                        </Col>
                        <Col md={12} >
                            <Form.Group controlId="MiscellaneousForm.txtEndofcontract">
                                <Form.Label> {t('generalTab.endContract')}</Form.Label>
                                <div className="w-100"><DatePicker className="form-control" selected={EndOfContract} onChange={(date) => setEndOfContract(date)} showYearDropdown /></div>
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group controlId="ContactForm.txtNote">
                                <Form.Label> {t('generalTab.note')}</Form.Label>
                                <Form.Control type="text" value={Note} onChange={(e) => setNote(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group controlId="ContactForm.txtCostCentre">
                                <Form.Label> {t('generalTab.costCenter')}</Form.Label>
                                <Form.Control type="text" value={CostCentre} maxLength={150} onChange={(e) => setCostCentre(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group controlId="ContactForm.txtPayers">
                                <Form.Label> {t('generalTab.costObject')}</Form.Label>
                                <Form.Control type="text" maxLength={150} value={Payers} onChange={(e) => setPayers(e.target.value)} />
                            </Form.Group>
                        </Col>

                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btnPrimaryGreen" onClick={(e) => MiscellaneousUpdate()}>{ct('save')}</Button>
            </Modal.Footer>
        </Modal>
    )
}
function EmployeeContactModal(props) {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');

    const { userid } = useParams();

    const formik = useFormik({
        initialValues: {
            email: '',
            phonenumber: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .email(t('generalTab.errorMsg.email'))
                .required(t('generalTab.errorMsg.email').toString()),
            phonenumber: Yup.string().test('phonenumber', t('generalTab.errorMsg.phoneNumber').toString(), (value) => {
                if (!!value) {
                    return isValidPhoneNumber(value)
                } else {
                    return true;
                }
            })
        }),
        onSubmit: values => {
            updateContectDetail(values)
        },
    });

    useEffect(() => {
        formik.setFieldValue("email", props.empdata.email)
        formik.setFieldValue("phonenumber", props.empdata.phonenumber)
    }, [props.empdata]);

    const updateContectDetail = async (values) => {
        var employee = {
            Id: userid,
            Phonenumber: values.phonenumber,
            Email: values.email,
        };

        var res = await CallAxios("/api/Employee/updateContact|post", employee)
        if (!!res && res.status === 200) {
            if (res.data === true) {
                props.getProfileData()
                props.onHide(false)
            }
            if (res.data === "Exist") {
                toast.error(t('generalTab.errorMsg.emailExist').toString())
            }
        }
    }
    return (
        <Modal show={props.show} onHide={() => props.onHide(false)}>
            <Modal.Header closeButton>
                <Modal.Title> {ct('contact')}</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Form>
                    <Row>
                        <Col md={12}>
                            <Form.Group controlId="ContactForm.txtPhoneNumber">
                                <Form.Label> {ct('phoneNumber')}</Form.Label>
                                <Form.Control type="text" placeholder={ct('eg') + " +49 1234567890"} name='phonenumber' value={formik.values.phonenumber} onChange={formik.handleChange} />
                                {formik.touched.phonenumber && formik.errors.phonenumber ? (
                                    <span className="error text-danger text-left">{formik.errors.phonenumber}</span>
                                ) : null}
                            </Form.Group>

                        </Col>
                        <Col md={12}>
                            <Form.Group controlId="ContactForm.txtEmail">
                                <Form.Label> {ct('email')}</Form.Label>
                                <Form.Control type="email" placeholder={ct('eg') + "+49 1234567890"} maxLength={150} name='email' value={formik.values.email} onChange={formik.handleChange} />
                                {formik.touched.email && formik.errors.email ? (
                                    <span className="error text-danger text-left">{formik.errors.email}</span>
                                ) : null}
                            </Form.Group>

                        </Col>

                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btnPrimaryGreen" onClick={(e) => formik.handleSubmit()}> {ct('finished')} </Button>
            </Modal.Footer>
        </Modal>
    )
}
function BaseDataModal(props) {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');

    const { userid } = useParams();
    const user = useSelector(x => x.app.user);
    const [Surname, setSurname] = useState("");
    const [abbrivation, setabbrivation] = useState("");
    const [PersonalNO, setPersonalNO] = useState("");
    const [Birthday, setBirthday] = useState(new Date());
    // const [MobilePin, setMobilePin] = useState("");
    useEffect(() => {
        setSurname(props.empdata.surname === null ? "" : props.empdata.surname)
        setabbrivation(props.empdata.abbreviation === null ? "" : props.empdata.abbreviation)
        setPersonalNO(props.empdata.personnelnumber === null ? "" : props.empdata.personnelnumber)
        setBirthday(props.empdata.birthday === null ? "" : new Date(props.empdata.birthday))

    }, [props.empdata]);

    const updateBaseData = async () => {
        if (!Surname) {
            alert(t('generalTab.errorMsg.alertName').toString())
            return;
        }
        var employee = {
            Id: userid,
            Surname: Surname,
            Abbreviation: abbrivation,
            Personnelnumber: PersonalNO,
            Birthday: new Date(Birthday),

        };

        // if (moment().isAfter(moment(Birthday), "day")) {
        //     var res = await CallAxios("/api/Employee/updatebasedata|post", employee)
        //     if (!!res && res.status === 200) {
        //         props.getProfileData()
        //         props.onHide(false)
        //     }
        // } else {
        //     alert(t('generalTab.errorMsg.alertDate').toString())
        // }
        if (Birthday && moment().isAfter(moment(Birthday), "day")) {
            var res = await CallAxios("/api/Employee/updatebasedata|post", employee);
            if (!!res && res.status === 200) {
                props.getProfileData();
                props.onHide(false);
            }
        } else if (Birthday) {
            // If Birthday is entered but the current date is not after it, show an alert
            alert(t('generalTab.errorMsg.alertDate').toString());
        } else {
            // If Birthday is not entered, proceed without checking it
            var res = await CallAxios("/api/Employee/updatebasedata|post", employee);
            if (!!res && res.status === 200) {
                props.getProfileData();
                props.onHide(false);
            }
        }

    }
    return (
        <Modal show={props.show} onHide={() => props.onHide(false)}>
            <Modal.Header closeButton>
                <Modal.Title> {t('generalTab.baseData')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <Form>
                    <Row>
                        <Col md={12} >
                            <Form.Label className="setLabelPosition">{t('generalTab.userName')}</Form.Label>
                            <div className="position-relative">
                                <Form.Group controlId="BaseDataForm.txtSurname">
                                    <InputImoji placeholder={t('generalTab.newColleague')} value={Surname} onChange={v => setSurname(v)} />

                                    {/* <Form.Control type="text" placeholder="New colleague" onChange={(e) => setSurname(e.target.value)} value={Surname} className="ThickBorder my-3 pr40" />
                                    <span className="btnShowEmoji" onClick={() => handleEmojipicker()}><FiSmile /></span> */}
                                </Form.Group>
                                {/* {!props.onHide ?
                                    <div>
                                        <Picker disableSearchBar="true" onEmojiClick={props.onEmojiClick} />
                                    </div> : null
                                } */}
                            </div>
                        </Col>
                        <Col md={12}>
                            <Form.Group controlId="BaseDataForm.txtAbbreviation">
                                <Form.Label> {t('generalTab.abbreviation')} </Form.Label>
                                <Form.Control type="text" placeholder={ct('eg') + " MM"} maxLength={50} disabled={user.roles.length === 1 && user.roles.some(x => x === Constants.roles.Employee) ? true : false} onChange={(e) => setabbrivation(e.target.value)} value={abbrivation} />
                                <Form.Text className="text-muted">
                                    {t('generalTab.abbreviationLabelDes')}.
                                </Form.Text>
                            </Form.Group>

                        </Col>
                        <Col md={12}>
                            <Form.Group controlId="BaseDataForm.txtPersonalNumber">
                                <Form.Label> {t('generalTab.staffNumber')}</Form.Label>
                                <Form.Control type="text" placeholder={ct('eg') + " 0006167"} maxLength={50} disabled={user.roles.length === 1 && user.roles.some(x => x === Constants.roles.Employee) ? true : false} onChange={(e) => setPersonalNO(e.target.value)} value={PersonalNO} />
                            </Form.Group>

                        </Col>
                        <Col md={12} >
                            <Form.Group controlId="BaseDataForm.txtBirthday">
                                <Form.Label> {ct('birthday')}</Form.Label>
                                <div className="w-100">
                                    <DatePicker className="form-control" onChange={(date) => setBirthday(date)} selected={Birthday} showYearDropdown dropdownMode="select" />
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btnPrimaryGreen" onClick={(e) => updateBaseData()}> {ct('finished')} </Button>
            </Modal.Footer>
        </Modal>
    )
}
function DeactivateEmployeeMOdal(props) {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');

    const { userid } = useParams();

    const ActivedeactiveEmployee = async () => {
        var response = await CallAxios('/api/Employee/ActiveDeactiveEmployee|post', { id: userid.toString() })
        if (!!response && response.data === true) {
            // this.setState({
            //     DeactivateEmployee: false
            // })
            props.getProfileData()
            props.onHide(false)
        }
    }
    return (
        <Modal show={props.show} onHide={() => props.onHide(false)}>
            <Modal.Header closeButton>
                <Modal.Title> {t('generalTab.deactivateUserMdl.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Form>
                    <Row>
                        <Col md={12}>
                            {t('generalTab.deactivateUserMdl.desc')}
                        </Col>

                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btnPrimaryGreen" onClick={(e) => props.onHide(false)}>{ct('cancel')}</Button>
                <Button className="btn btnPrimaryGreen" onClick={(e) => ActivedeactiveEmployee()}>  {t('generalTab.deactivateUserMdl.yesDeactivate')} </Button>
            </Modal.Footer>
        </Modal>
    )
}