import { useEffect } from "react";
import { useState } from "react";
import { AxiosLite, CallAxios } from "../../../Helpers";
import { Col, Row, Table } from "react-bootstrap";
import { sortableElement, arrayMove, sortableHandle, sortableContainer } from 'react-sortable-hoc';
import { TblSwitchToogleButtonDragable } from "../../../Components/TblSwitchToogleButtonDragable";
import { FaBars } from "react-icons/fa";
import CollapseableDiv from "../../../Components/CollapseCard";
import { useTranslation } from "react-i18next";

const DragHandle = sortableHandle((value) => <td className='py-1'><div className="dataTextCell"><FaBars /> {value.value}</div></td>);

const SortableItem = sortableElement(({ value, value2, className }) => {
    return <tr className={className}><DragHandle value={value} />{value2}</tr>
});


const SortableContainer = sortableContainer(({ children }) => {
    return <tbody>{children}</tbody>;
});



function SortSetting() {
    const { t: Lst } = useTranslation('locationSetting');
    const { t: ct } = useTranslation('common');


    const [TABLELIST, setTABLELIST] = useState([]);
    const [TablesortList, setTablesortList] = useState([]);

    useEffect(() => {
        setTablesortList(TABLELIST)
    }, [TABLELIST]);

    useEffect(() => {
        getData()
    }, []);
    const getData = async () => {
        var response = await AxiosLite("/api/LocationSetting/getPlanningSortSetting|get");
        if (!!response && !!response.data) {
            setTABLELIST(response.data)
        }
    }
    const onSortEnd = async ({ oldIndex, newIndex }) => {
        if (TablesortList[newIndex].isActive === true) {
            let arr = arrayMove(TablesortList, oldIndex, newIndex);
            setTablesortList(arr)
            await CallAxios("/api/LocationSetting/updateorderPlanningSortSetting|post", arr);
        } else {
            console.log("wrong index ")
        }
    }

    const disableRow = async (check, index) => {
        setTablesortList(TablesortList.map((item, ind) => item.id === index ? { ...item, isActive: check } : item));
        var data = {
            Id: index,
            IsActive: check === true ? true : false
        }
        var response = await CallAxios("/api/LocationSetting/updateStatusPlanningSortSetting|post", data);
        if (!!response && !!response.data) {
            setTABLELIST(response.data)
        }
    }
    const changeCancel = (node) => {
        return
    }

    return (
        <>
            <CollapseableDiv title={Lst('planTab.sortSetAccor.sortSetting')}>
                <Row>
                    <Col md={12}>
                        <div className="TabFormContainerSection mt-2">
                            {/* <h5 className='text-left font-weight-bold'> {Lst('planTab.sortSetAccor.sortSetting')} </h5> */}
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='text-left'>
                                    {Lst('planTab.sortSetAccor.Desc')}.</p>
                            </div>
                            <Table hover responsive className="DataGridTable text-left overflowVisible">
                                <thead>
                                    <tr>
                                        <th> {ct('name')}</th>
                                        <th width="25"> {ct('actions')}</th>
                                    </tr>
                                </thead>
                                <SortableContainer updateBeforeSortStart={changeCancel} onSortEnd={onSortEnd} useDragHandle>
                                    {/* {TablesortList.sort((a,b) => a.order > b.order ? 1 : -1).map((value, index) => */}
                                    {TablesortList.map((value, index) =>
                                        <SortableItem disabled={!value.isActive} key={`item-${index}`} index={index} value2={<td className='py-1'><TblSwitchToogleButtonDragable active={value.isActive} checkactive={(e) => disableRow(e, value.id)} /></td>} value={value.label} />
                                    )}
                                </SortableContainer>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </CollapseableDiv>
        </>
    );
}

export default SortSetting;