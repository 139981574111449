import Picker from 'emoji-picker-react';
import { saveAs } from 'file-saver';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Button, Col, Form, FormGroup, Modal, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { BiExport } from "react-icons/bi";
import { FiSmile } from "react-icons/fi";
import { HiPlus, HiUserGroup } from "react-icons/hi";
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import HeaderButton from '../../Components/HeaderButton';
import TagsCheckSelectFilter from '../../Components/TagsCheckSelectFilter';
import WorkAreaCheckSelectFilter from '../../Components/WorkAreaCheckSelectFilter';
import { AxiosLite, CallAxios, CallAxiosBlob } from '../../Helpers';
import { ActionItem, ActionsBox, DisplayFlex, InputField, InputIcon, InviteCount, InviteText, SubHeadTitle, SubHeaderTitleBox, SubHeaderWrapper } from '../../Helpers/Global/StyledComponents';
import { useTranslation } from "react-i18next";

// const SelectCheckData = [
//     {
//         optionText: 'Option 1',
//         isCheck: false
//     },
//     {
//         optionText: 'Option 2',
//         isCheck: false
//     },
//     {
//         optionText: 'Option 3',
//         isCheck: false
//     },
//     {
//         optionText: 'Option 4',
//         isCheck: false
//     }
// ]

function EmployeeHeader(props) {
    const { t: ct } = useTranslation('common');
    const { t: ept } = useTranslation('employeePage');
    const { t: ht } = useTranslation('header');

    const [showAddEmployeeModal, setShowAddEmployeeModal] = useState(false);
    const [showInviteEmployeeModal, setshowInviteEmployeeModal] = useState(false);
    const [exportEmployeeModal, setExportEmployeeModal] = useState(false);
    const themeColorBtn = useSelector(state => state.app.theme.colors.buttons);
    return (
        <>
            <SubHeaderWrapper>
                <SubHeaderTitleBox>
                    <HiUserGroup color={themeColorBtn} size={22} />
                    <SubHeadTitle> {ht('employees')}</SubHeadTitle>
                </SubHeaderTitleBox>
                <ActionsBox>
                    <ActionItem>
                        <DisplayFlex onClick={(e) => setshowInviteEmployeeModal(true)} >
                            <InviteText> {ept('inviteEmployees')}</InviteText>
                            <InviteCount>{props.employeeList.filter(x => !!x.email && x.isAccountCreated === false).length}</InviteCount>
                        </DisplayFlex>
                    </ActionItem>
                    <ActionItem>
                        <HeaderButton title={ct('add')} onClick={(e) => setShowAddEmployeeModal(true)} icon={<HiPlus color='#fff' size={18} />} />
                    </ActionItem>
                    <ActionItem>
                        <HeaderButton title={ct('export')} onClick={(e) => setExportEmployeeModal(true)} icon={<BiExport color='#fff' size={18} className='rotate90deg' />} />
                    </ActionItem>
                    <ActionItem>
                        <TagsCheckSelectFilter />
                    </ActionItem>
                    <ActionItem>
                        <WorkAreaCheckSelectFilter />
                    </ActionItem>
                    {/* <ActionItem>
                                <CheckSelectFilter listoptions={SelectCheckData} placeholder={<BsThreeDots size={30} />} autoWidth={true} />
                            </ActionItem> */}
                </ActionsBox>
            </SubHeaderWrapper>
            {
                showAddEmployeeModal === true ?
                    <AddEmployeeModal show={showAddEmployeeModal} onHide={setShowAddEmployeeModal} getEmployeeList={props.getEmployeeList} />
                    : null
            }
            {
                showInviteEmployeeModal === true ?
                    <InviteEmployeeModal show={showInviteEmployeeModal} onHide={setshowInviteEmployeeModal} employeeList={props.employeeList} />
                    : null
            }
            {
                exportEmployeeModal === true ?
                    <ExportEmployeeModal show={exportEmployeeModal} onHide={setExportEmployeeModal} employeeList={props.employeeList} />
                    : null
            }
        </>
    );
}

export default EmployeeHeader;

function ExportEmployeeModal(props) {
    const { t: ept } = useTranslation('employeePage');
    const { t: ct } = useTranslation('common');

    const [Name, setName] = useState(true);
    const [Abbreviation, setAbbreviation] = useState(true);
    const [StaffNumber, setStaffNumber] = useState(true);
    const [Email, setEmail] = useState(true);
    const [Birthday, setBirthday] = useState(true);
    const [Status, setStatus] = useState(true);
    const [HourlySalary, setHourlySalary] = useState(true);
    const [TargetHours, setTargetHours] = useState(true);
    const [WorkingAreas, setWorkingAreas] = useState(true);
    const [Role, setRole] = useState(true);
    const [TimeTrackingMode, setTimeTrackingMode] = useState(true);
    const [RemainingVacation, setRemainingVacation] = useState(true);
    const [RemainingVacationDate, setRemainingVacationDate] = useState();
    const [CurrentBalanceUntil, setCurrentBalanceUntil] = useState(true);
    const [CurrentBalanceUntilDate, setCurrentBalanceUntilDate] = useState();
    const [RemainingDays, setRemainingDays] = useState(true);
    const [PhoneNumber, setPhoneNumber] = useState(true);
    const [CommencementOfWork, setCommencementOfWork] = useState(true);
    const [EndOfProbation, setEndOfProbation] = useState(true);
    const [EndOfcontract, setEndOfcontract] = useState(true);
    const [Note, setNote] = useState(true);
    const [CostCenter, setCostCenter] = useState(true);
    const [CostObject, setCostObject] = useState(true);

    const ExportTimeTracking = async (format) => {

        var data = {
            Name: Name,
            Abbreviation: Abbreviation,
            StaffNumber: StaffNumber,
            Email: Email,
            Birthday: Birthday,
            Status: Status,
            HourlySalary: HourlySalary,
            TargetHours: TargetHours,
            WorkingAreas: WorkingAreas,
            Role: Role,
            TimeTrackingMode: TimeTrackingMode,
            RemainingVacation: RemainingVacation,
            RemainingVacationDate: RemainingVacationDate,
            CurrentBalanceUntil: CurrentBalanceUntil,
            CurrentBalanceUntilDate: CurrentBalanceUntilDate,
            RemainingDays: RemainingDays,
            PhoneNumber: PhoneNumber,
            CommencementOfWork: CommencementOfWork,
            EndOfProbation: EndOfProbation,
            EndOfcontract: EndOfcontract,
            Note: Note,
            CostCenter: CostCenter,
            CostObject: CostObject,
            format: format
        }

        var response = await CallAxiosBlob("/api/Employee/EmployeeDataExport|post", data);

        if (!!response && response.status === 200) {
            if (format === 'CSV') {
                saveAs(response.data, "Employees.csv")
            }
            else if (format === 'XLSX') {
                saveAs(response.data, "Employees.xlsx")
            }
            props.onHide(false)
        }
    }
    const handleCheckbox = (e) => {

        if (e.target.name === "CoreData") {
            setName(e.target.checked);
            setAbbreviation(e.target.checked);
            setStaffNumber(e.target.checked);
            setEmail(e.target.checked);
            setBirthday(e.target.checked);
            setStatus(e.target.checked);
            setHourlySalary(e.target.checked);
            setTargetHours(e.target.checked);
        }
        if (e.target.name === "COMPANYRELATED") {
            setWorkingAreas(e.target.checked);
            setRole(e.target.checked);
            setTimeTrackingMode(e.target.checked);
        }
        if (e.target.name === "FURTHERATTRIBUTES") {
            setRemainingVacation(e.target.checked);
            setCurrentBalanceUntil(e.target.checked);
            setRemainingDays(e.target.checked);
        }
        if (e.target.name === "USERPROFILES") {
            setPhoneNumber(e.target.checked);
            setCommencementOfWork(e.target.checked);
            setEndOfProbation(e.target.checked);
            setEndOfcontract(e.target.checked);
            setNote(e.target.checked);
            setCostCenter(e.target.checked);
            setCostObject(e.target.checked);
        }
    }
    return (
        <Modal size='lg' show={props.show} onHide={() => props.onHide(false)}>
            <Modal.Header closeButton>
                <Modal.Title> {ept('exportMdl.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={12} >
                        <Form.Group className="mb-3" controlId="Schule">
                            <label className='fw-bold'> {ept('exportMdl.desc')}:</label>
                            <Row>
                                <Col md={6} className='mb-1'>
                                    <Row>
                                        <Col md={12} className='mb-1'>
                                            {ept('exportMdl.coreData')}
                                        </Col>
                                        <Col md={12} className='mb-1'>
                                            <Form.Check type="checkbox" id="0" defaultChecked={true} onChange={(e) => handleCheckbox(e)} name="CoreData" label={ct('selectAll')} />
                                        </Col>
                                        <Col md={12} className='mb-1'>
                                            <Form.Check type="checkbox" id="1" checked={Name} onChange={(e) => setName(!Name)} label={ct('name')} />
                                        </Col>
                                        <Col md={12} className='mb-1'>
                                            <Form.Check type="checkbox" id="2" checked={Abbreviation} onChange={(e) => setAbbreviation(!Abbreviation)} label={ept('abbreviation')} />
                                        </Col>
                                        <Col md={12} className='mb-1'>
                                            <Form.Check type="checkbox" id="3" checked={StaffNumber} onChange={(e) => setStaffNumber(!StaffNumber)} label={ept('exportMdl.staffNumber')} />
                                        </Col>
                                        <Col md={12} className='mb-1'>
                                            <Form.Check type="checkbox" id="4" checked={Email} onChange={(e) => setEmail(!Email)} label={ct('email')} />
                                        </Col>
                                        <Col md={12} className='mb-1'>
                                            <Form.Check type="checkbox" id="5" checked={Birthday} onChange={(e) => setBirthday(!Birthday)} label={ct('birthday')} />
                                        </Col>
                                        <Col md={12} className='mb-1'>
                                            <Form.Check type="checkbox" id="6" checked={Status} onChange={(e) => setStatus(!Status)} label={ct('status')} />
                                        </Col>
                                        <Col md={12} className='mb-1'>
                                            <Form.Check type="checkbox" id="7" checked={HourlySalary} onChange={(e) => setHourlySalary(!HourlySalary)} label={ept('exportMdl.hourlySalary')} />
                                        </Col>
                                        <Col md={12} className='mb-1'>
                                            <Form.Check type="checkbox" id="8" checked={TargetHours} onChange={(e) => setTargetHours(!TargetHours)} label={ept('exportMdl.targetHours')} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={6} className='mb-1'>
                                    <Row>
                                        <Col md={12} className='mb-1'>
                                            {ept('exportMdl.companyRelated')}
                                        </Col>
                                        <Col md={12} className='mb-1'>
                                            <Form.Check type="checkbox" id="9" defaultChecked={true} onChange={(e) => handleCheckbox(e)} name="COMPANYRELATED" label={ct('selectAll')} />
                                        </Col>
                                        <Col md={12} className='mb-1'>
                                            <Form.Check type="checkbox" id="10" checked={WorkingAreas} onChange={(e) => setWorkingAreas(!WorkingAreas)} label={ct('workingAreas')} />
                                        </Col>
                                        <Col md={12} className='mb-1'>
                                            <Form.Check type="checkbox" id="11" checked={Role} onChange={(e) => setRole(!Role)} label={ct('role')} />
                                        </Col>
                                        <Col md={12} className='mb-1'>
                                            <Form.Check type="checkbox" id="12" checked={TimeTrackingMode} onChange={(e) => setTimeTrackingMode(!TimeTrackingMode)} label={ept('exportMdl.timeTrackingMode')} />
                                        </Col>
                                        <Col md={12} className='mb-1'>
                                            {ept('exportMdl.furtherAttributes')}
                                        </Col>
                                        <Col md={12} className='mb-1'>
                                            <Form.Check type="checkbox" id="13" defaultChecked={true} onChange={(e) => handleCheckbox(e)} name="FURTHERATTRIBUTES" label={ct('selectAll')} />
                                        </Col>
                                        <Col md={12} className='mb-1'>
                                            <Form.Check type="checkbox" id="14" onChange={(e) => setRemainingVacation(!RemainingVacation)} checked={RemainingVacation} label={ept('exportMdl.remainingVacation')} />
                                            <DatePicker className="form-control" selected={RemainingVacationDate} onChange={(date) => setRemainingVacationDate(date)} placeholder="until date" />
                                        </Col>
                                        <Col md={12} className='mb-1'>
                                            <Form.Check type="checkbox" id="15" onChange={(e) => setCurrentBalanceUntil(!CurrentBalanceUntil)} checked={CurrentBalanceUntil} label={ept('exportMdl.currentBalance')} />
                                            <DatePicker className="form-control" selected={CurrentBalanceUntilDate} onChange={(date) => setCurrentBalanceUntilDate(date)} placeholder="yesterday" />
                                        </Col>
                                        <Col md={12} className='mb-1'>
                                            <Form.Check type="checkbox" id="16" onChange={(e) => setRemainingDays(!RemainingDays)} checked={RemainingDays} label={ept('exportMdl.remainingDays')} />
                                        </Col>
                                        <Col md={12} className='mb-1'>
                                            {ept('exportMdl.userProfiles')}
                                        </Col>
                                        <Col md={12} className='mb-1'>
                                            <Form.Check type="checkbox" id="17" defaultChecked={true} onChange={(e) => handleCheckbox(e)} name="USERPROFILES" label={ct('selectAll')} />
                                        </Col>
                                        <Col md={12} className='mb-1'>
                                            <Form.Check type="checkbox" id="18" onChange={(e) => setPhoneNumber(!PhoneNumber)} checked={PhoneNumber} label={ct('phoneNumber')} />
                                        </Col>
                                        <Col md={12} className='mb-1'>
                                            <Form.Check type="checkbox" id="19" onChange={(e) => setCommencementOfWork(!CommencementOfWork)} checked={CommencementOfWork} label="Commencement of work" />
                                        </Col>
                                        <Col md={12} className='mb-1'>
                                            <Form.Check type="checkbox" id="20" onChange={(e) => setEndOfProbation(!EndOfProbation)} checked={EndOfProbation} label={ept('exportMdl.endProbation')} />
                                        </Col>
                                        <Col md={12} className='mb-1'>
                                            <Form.Check type="checkbox" id="21" onChange={(e) => setEndOfcontract(!EndOfcontract)} checked={EndOfcontract} label={ept('exportMdl.endContract')} />
                                        </Col>
                                        <Col md={12} className='mb-1'>
                                            <Form.Check type="checkbox" id="22" onChange={(e) => setNote(!Note)} checked={Note} label={ct('note')} />
                                        </Col>
                                        <Col md={12} className='mb-1'>
                                            <Form.Check type="checkbox" id="23" onChange={(e) => setCostCenter(!CostCenter)} checked={CostCenter} label={ept('exportMdl.costCenter')} />
                                        </Col>
                                        <Col md={12} className='mb-1'>
                                            <Form.Check type="checkbox" id="24" onChange={(e) => setCostObject(!CostObject)} checked={CostObject} label={ept('exportMdl.costObject')} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
                <Button variant="primary" onClick={(e) => ExportTimeTracking("XLSX")}>Excel</Button>
                <Button variant="primary" onClick={(e) => ExportTimeTracking("CSV")}>CSV</Button>
            </Modal.Footer>
        </Modal>
    )
}
function InviteEmployeeModal(props) {
    const { t: ept } = useTranslation('employeePage');

    const InviteAllavailibleEmployees = async () => {
        var response = await AxiosLite("/api/Employee/InviteAllEmployee|get")
        if (!!response && response.status === 200) {
            toast.success(ept('errorMsg.inviteSent'))
            props.onHide(false)
        }
    }
    return (
        <Modal size='lg' show={props.show} onHide={() => props.onHide(false)}>
            <Modal.Header closeButton>
                <Modal.Title > {ept('inviteEmployeesMdl.title')} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    {
                        props.employeeList.filter(x => !!x.email && x.isAccountCreated === false).length > 0 ?
                            <>
                                <Col md={12}>
                                    <div>{ept('inviteEmployeesMdl.inviteEmailPara')}:</div>
                                </Col>
                                <Col md={12}>
                                    <ul className='py-2'>
                                        {props.employeeList.filter(x => !!x.email && x.isAccountCreated === false).map((value, index) =>
                                            <li style={{ fontSize: "14px" }}>{value.surname} ({value.email})</li>
                                        )
                                        }
                                    </ul>
                                </Col>
                                <Col md={12} className="text-right">
                                    <Button href="#" onClick={() => InviteAllavailibleEmployees()}> {ept('inviteEmployeesMdl.sendInviteEmail')} </Button>
                                </Col>
                            </>
                            : null
                    }
                    {
                        props.employeeList.filter(x => x.email === null || x.email === "" && x.isAccountCreated === false).length > 0 ?
                            <>
                                <Col md={12}>
                                    <div>{ept('inviteEmployeesMdl.noEmailPara')}:</div>
                                </Col>
                                <Col md={12} >
                                    <ul className='py-2'>
                                        {props.employeeList.filter(x => x.email === null || x.email === "" && x.isAccountCreated === false).map((value, index) =>
                                            <li style={{ fontSize: "14px" }}>{value.surname}</li>
                                        )
                                        }
                                    </ul>
                                </Col>
                            </>
                            : null
                    }
                    <Col md={12} className='mt-2'>
                        <div>{ept('inviteEmployeesMdl.inviteLinkPara')}:</div>
                        <div>
                            <a className="highlitedLink" href="#">https://app.bastashift.com/register/1a90c831-58d9-447f-9fe1-c60c1a63ba91/employees?lang=de</a>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

function AddEmployeeModal(props) {
    const { t: ept } = useTranslation('employeePage');
    const { t: ct } = useTranslation('common');

    const [hideShowEmojipicker, sethideShowEmojipicker] = React.useState(false);
    const appSettings = useSelector(s => s.app.appSettings);

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            abbreviation: '',
            WorkSpaces: [],
        },
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .email(ept('addEmployeeMdl.emailError'))
                .nullable()
                .optional(),
            name: Yup.string().required(ct('required'))
        }),
        onSubmit: values => {
            createEmployee(values)
        },
    });
    const createEmployee = async (data) => {
        var response = await CallAxios("/api/Employee/addemployee|post", data)
        if (!!response && response.status === 200) {
            if (response.data === "EmailExist") {
                toast.error(ept('errorMsg.emailExist'))
            } else if (response.data === "LimitReach") {
                toast.error(ept('errorMsg.limitReach'))
            } else {
                props.getEmployeeList();
                props.onHide(false)
            }
        }
    }
    const handleCheckbox = (e) => {
        if (e.target.checked === true) {
            formik.setFieldValue("WorkSpaces", [...formik.values.WorkSpaces, e.target.id])
        } else {
            formik.setFieldValue("WorkSpaces", formik.values.WorkSpaces.filter(s => s !== e.target.id))
        }
    }
    return (
        <Modal size='lg' show={props.show} onHide={() => props.onHide(false)}>
            <Modal.Header closeVariant='red' closeButton>
                <Modal.Title>{ct('add') + ' ' + ct('employee')} </Modal.Title>
            </Modal.Header>
            {/* <div className="modal-header d-block">
                <button type="button" className="close" style={{ "float": "right" }} data-dismiss="modal" onClick={() => { props.onHide(false) }}>×</button>
                <h2 className='modal-title'>Add Employee</h2>
            </div> */}
            <Modal.Body>
                <p> {ept('addEmployeeMdl.desc')} </p>

                <Row>
                    <Col md={8}>
                        <FormGroup className="position-relative mb-3">
                            <Form.Label className='fw-bold'> {ept('addEmployeeMdl.username')} </Form.Label>
                            <InputField border="2" type="text" placeholder={ept('addEmployeeMdl.createEmployeePlace')} maxLength={150} name="name" value={formik.values.name} onChange={(e) => formik.setFieldValue("name", e.target.value)} />
                            <InputIcon style={{ top: "42px" }} className='input-icon'>
                                <FiSmile onClick={(e) => sethideShowEmojipicker(!hideShowEmojipicker)} size={20} color={"#cbcbcb"} />
                            </InputIcon>
                            {formik.touched.name && formik.errors.name ? (
                                <span className="error text-danger">{formik.errors.name}</span>
                            ) : null}
                            {hideShowEmojipicker ?
                                <div style={{ position: "absolute", zIndex: 99 }}>
                                    <Picker disableSearchBar="true" onEmojiClick={(n) => formik.setFieldValue("name", formik.values.name + n.emoji)} />
                                </div> : null
                            }
                        </FormGroup>

                    </Col>
                    <Col md={4}>
                        <FormGroup className="position-relative">
                            <Form.Label className='fw-bold'> {ept('abbreviation')} </Form.Label>
                            <InputField border="2" type="text" placeholder={ct('eg') + ' ' + 'MM'} maxLength={50} name="abbreviation" value={formik.values.abbreviation} onChange={formik.handleChange} className="ThickBorder my-0 " />
                        </FormGroup>
                    </Col>
                </Row>


                <FormGroup className="position-relative mb-3">
                    <Form.Label className='fw-bold'> {ept('addEmployeeMdl.employeeInvited')}</Form.Label>
                    <InputField border="2" type="text" name="email" maxLength={150} onChange={formik.handleChange} placeholder={ct('eg') + ' ' + 'max@mustermann.com'} value={formik.values.email} className="ThickBorder my-0 " />
                    {formik.touched.email && formik.errors.email ? (
                        <span className="error text-danger">{formik.errors.email}</span>
                    ) : null}
                </FormGroup>
                <FormGroup className="mb-3" controlId="Schule">
                    <Form.Label className='fw-bold'>{ct('workingAreas')}</Form.Label>
                    <Row>
                        {
                            !!appSettings.WorkSpaces && appSettings.WorkSpaces.length > 0 ? appSettings.WorkSpaces.map((value, key) => {
                                return <Col md={3} className='mb-1'>
                                    <Form.Check type="checkbox" id={value.value} onChange={(e) => handleCheckbox(e)} label={value.label} />
                                </Col>
                            }) : null
                        }
                    </Row>
                </FormGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={(e) => formik.handleSubmit()}> {ct('done')}</Button>
            </Modal.Footer>
        </Modal>
    )
}