import { useEffect } from "react";
import { useState } from "react";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";

export function TblSwitchToogleButtonDragable(props) {
    const [btnActiveState, setActive] = useState(false);


    useEffect(() => {
        if (!!props.active) {

            setActive(props.active)
        }
    }, [props.active]);
    return <>
        <a className={props.active ? 'btnTblAction bgGreen active' : 'btnTblAction'} onClick={(e) => { setActive(!btnActiveState); props.checkactive(!btnActiveState) }}>
            {props.active ? <BsToggleOn /> : <BsToggleOff />}
        </a>
    </>
};