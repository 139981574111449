import React, { useEffect, useState } from 'react';
import { AxiosLite } from '../../../Helpers';
import { InputField } from '../../../Helpers/Global/StyledComponents';
import ApplicationsAndAssignments from './ApplicationsAndAssignments';
import Availabilities from './Availabilities';
import CalendarSetting from './CalendarSetting';
import MoreSettings from './MoreSettings';
import Tags from './Tags';
import WorkingAreaGrid from './WorkingAreaGrid';
import SortSetting from './SortSetting';



function PlanningTab() {
    const [WorkAreaList, setWorkAreaList] = useState([]);
    const [AvailibilitySetting, setAvailibilitySetting] = useState({});
    const [ApplicationsandAssignments, setApplicationsandAssignments] = useState({});
    const [LocationCalendarSettings, setLocationCalendarSettings] = useState({});
    const [MoreSetting, setMoreSetting] = useState({});
    const [TagsList, setTagsList] = useState([]);

    useEffect(() => {
        getplanningData();
    }, []);
    const getplanningData = async () => {
        var response = await AxiosLite("/api/LocationSetting/getplanningData|get");
        if (!!response && response.data) {
            setWorkAreaList(response.data.workspaceList);
            setAvailibilitySetting(response.data.availibilitySettingViewModel);
            setApplicationsandAssignments(response.data.applicationsandAssignmentsViewModel);
            setLocationCalendarSettings(response.data.locationCalendarSettings);
            setMoreSetting(response.data.moreSettingViewModel);
            setTagsList(response.data.tagsListViewModel);
        }
    };
    return (
        <>

            <WorkingAreaGrid WorkAreaList={WorkAreaList} getplanningData={getplanningData} />
            <Availabilities AvailibilitySetting={AvailibilitySetting} />
            <ApplicationsAndAssignments ApplicationsandAssignments={ApplicationsandAssignments} />
            <CalendarSetting LocationCalendarSettings={LocationCalendarSettings} />
            <MoreSettings MoreSetting={MoreSetting} />
            <Tags TagsList={TagsList} setTagsList={setTagsList} />
            <SortSetting />

        </>
    );
}

export default PlanningTab;

const HoursApplication = (props) => {

    return (
        <>
            {props.label}
            <InputField type="text" disabled={!props.checked} className='d-inline-block form-control-sm mx-2 text-center p-1' style={{ width: "60px" }} />
            Consider hours between bookings
        </>
    )
}

