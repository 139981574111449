import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { AxiosLite, CallAxios } from '../../../Helpers';
import { SignInManager } from '../../../Helpers/Authentication/SignInManager';
import { UpdateReduxStates } from '../../../Helpers/MasterData';
import { useTranslation } from "react-i18next";



function EmployeeTab() {
    const { t: Lst } = useTranslation('locationSetting');
    const { t: ct } = useTranslation('common');


    var dispatch = useDispatch();
    const CHECKBOXLIST = [
        { name: "calendar", label: Lst('employeeTab.calendar') },
        { name: "absences", label: Lst('employeeTab.absences') },
        { name: "availabilities", label: Lst('employeeTab.availabilities') },
        { name: "timetracking", label: Lst('employeeTab.timetracking') },
        { name: "Messages", label: Lst('employeeTab.messages') }]


    const formik = useFormik({
        initialValues: {
            calendar: false,
            absences: false,
            availabilities: false,
            timetracking: false,
            Messages: false,

        },
        onSubmit: values => {
            submit(values)

        },
    });
    useEffect(() => {
        getData()
    }, []);
    const getData = async () => {
        var response = await AxiosLite(
            "/api/LocationSetting/getEmployeeSetting|get"
        );
        if (!!response) {
            formik.setFieldValue("calendar", response.data.calendar)
            formik.setFieldValue("absences", response.data.absences)
            formik.setFieldValue("availabilities", response.data.availabilities)
            formik.setFieldValue("timetracking", response.data.timetracking)
            formik.setFieldValue("Messages", response.data.messages)
        }
    }
    const submit = async (values) => {
        var data = {
            calendar: values.calendar,
            absences: values.absences,
            availabilities: values.availabilities,
            timetracking: values.timetracking,
            Messages: values.Messages,
        };
        await CallAxios(
            "/api/LocationSetting/editEmployeeSetting|post", data
        );
        await UpdateReduxStates(new SignInManager().ParseJwt, dispatch)

    };
    return (
        <>
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <div className="TabFormContainerSection">
                            <h5 className='text-left fw-bold'> {Lst('employeeTab.title')}</h5>
                            <Form>
                                <Row>
                                    <Col md={4}>
                                        {CHECKBOXLIST.map((val, index) =>
                                            <Form.Group className="mb-1" key={"formBasicCheckbox" + index} controlId={"formBasicCheckbox" + index}>
                                                <Form.Check className="text-left" checked={formik.values[val.name]} onChange={formik.handleChange} name={val.name}
                                                    type="checkbox" label={val.label} />
                                            </Form.Group>
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4} className="text-left mt-3">
                                        <button type="button" onClick={(e) => formik.handleSubmit()} className="btn btnPrimaryGreen btn-primary" > {ct('ready')}</button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default EmployeeTab;


