import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, Col, Form, Modal, Row } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import FileBase64 from 'react-file-base64';
import { BsChevronDown, BsExclamationCircle } from "react-icons/bs";
import { FaDownload, FaUpload } from "react-icons/fa";
import { RiDeleteBin7Line, RiEditLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import CreatableSelect from 'react-select/creatable';
import Swal from "sweetalert2";
import { AxiosLite, CallAxios } from "../../../Helpers";
import { config } from "../../../Helpers/Config";
import { Constants } from "../../../Helpers/Contents";
import TimeTrackingBreaksRepeter from "../../../Components/TimeTrackingBreaksRepeter";
import RepetitionModal from "../../../Components/RepetitionModal";
import { Remove } from "../../../Components/ActionBtns";
import { CircleBtnWithIcon } from "../../../Helpers/Global/StyledComponents";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";



function EditShiftModal(props) {
    const { t: cl } = useTranslation('calendarPage');
    const { t: ct } = useTranslation('common');



    const AlertValue = {
        single: cl("alertValue.single"),
        future: cl("alertValue.future"),
        all: cl("alertValue.all"),
    }




    const appSettings = useSelector(x => x.app.appSettings)

    const [InitialStartHousr, setInitialStartHousr] = useState("0");
    const [InitialStartMin, setInitialStartMin] = useState("0");
    const [InitialEndHousr, setInitialEndHousr] = useState("0");
    const [InitialEndMin, setInitialEndMin] = useState("0");

    const [workSpaceValue, setWorkSpaceValue] = useState("");
    const [ShowRepetationModel, setShowRepetationModel] = useState(false);
    const [deleteShiftModal, setdeleteShiftModal] = useState(false);
    const [deletedropdownvalue, setDeletedropdownvalue] = useState("single");
    const [minimumEmployee, setMinimumEmployee] = useState("");
    const [DayDate, setDayDate] = useState(new Date());
    const [StartHousr, setStartHousr] = useState("9");
    const [StartMin, setStartMin] = useState("0");
    const [EndHousr, setEndHousr] = useState("14");
    const [EndMin, setEndMin] = useState("0");
    const [Tags, setTags] = useState([]);
    const [Note, setNote] = useState("");
    const [workspaceList, setWorkspaceList] = useState([]);
    const [AllowToaddBreak, setAllowToaddBreak] = useState(false);
    const [Attachment, setAttachment] = useState([]);

    // const { setIsLoading } = useContext(ApiContext);
    const [tagsDD, settagsDD] = useState([]);
    const [mode, setMode] = useState("");


    const [RulesDropdown, setRulesDropdown] = useState("weekly");
    const [monthlyRhythm, setmonthlyRhythm] = useState("DayofMonth");
    const [startDate, setstartDate] = useState(new Date());
    const [interval, setinterval] = useState(1);
    const [activeButtons, setactiveButtons] = useState("MON,TUE,WED,THU,FRI");
    const [summary, setsummary] = useState("Weekly every Monday, Tuesday, Wednesday, Thursday, Friday");
    const [isNeverEnds, setisNeverEnds] = useState(true);
    const [AfterNoofAppointments, setAfterNoofAppointments] = useState(null);
    const [AtTheDate, setAtTheDate] = useState(null);
    const [IsAfterNoofAppointmentsOrAtTheDate, setIsAfterNoofAppointmentsOrAtTheDate] = useState("");
    const [Schedularid, setSchedularid] = useState(null);

    const [notify, setNotify] = useState(false);
    const [nextSummary, setNextSummary] = useState("");
    const [summaryStart, setSummaryStart] = useState("");
    const [totalbreackMinutes, setTotalbreackMinutes] = useState(0);
    const [Showdetail, setShowdetail] = useState(false);

    const [FileName, setFileName] = useState("");
    const [File, setFile] = useState("");
    const [ShowFormControl, setShowFormControl] = useState(false);
    const fileInput = useRef();
    const getFiles = (files) => {
        setFileName(files.name);
        setFile(files.file)
    }
    useEffect(() => {
        if (!!props.shiftidforedit) {
            getdata();
        }
    }, [props.shiftidforedit]);
    useEffect(() => {
        setstartDate(DayDate)
        props.setDateForFutureDelete(DayDate)
    }, [DayDate]);
    useEffect(() => {
        onLoad();
    }, []);
    const onLoad = async () => {
        // props.setShowFormControl(false)
        Promise.all([
            getworkspaces(),
            getTagsDD()
        ])
    }
    // const removerepetation = () => {
    //     setMode("dayclick")
    // }
    const formik = useFormik({
        initialValues: {
            timeTrackingBreaks: []
        },
    });
    // const [TimeTrackObj, setTimeTrackObj] = useState({
    //     id: null,
    //     day: DayDate,
    //     startTimeHour: StartHousr,
    //     startTimeMin: StartMin,
    //     endTimeHour: EndHousr,
    //     endTimrMin: EndMin,
    // });
    const getdata = async () => {
        var responseCalendarSetting = await AxiosLite("/api/Calendar/GetCalendarSetting|get");
        if (!!responseCalendarSetting && responseCalendarSetting.status === 200) {
            setAllowToaddBreak(responseCalendarSetting.data.activate_shift_related_breaks_in_duty_roster)
        }
        var response = await CallAxios('/api/Calendar/GetShiftByid|post', { id: props.shiftidforedit });
        if (!!response) {
            let data = response.data;
            setWorkSpaceValue(data.workSpaceId);
            setMinimumEmployee(data.minimumEmployee);
            setDayDate(new Date(data.dayDate));
            setStartHousr(data.startHousr)
            setStartMin(data.startMin)
            setEndHousr(data.endHousr)
            setEndMin(data.endMin)
            setAttachment(data.attachment)

            setInitialStartHousr(data.startHousr)
            setInitialStartMin(data.startMin)
            setInitialEndHousr(data.endHousr)
            setInitialEndMin(data.endMin)

            setTags(data.tags)
            setNote(data.note)
            // RepetitionModal props
            setSchedularid(data.schedularid)

            if (!!data.schedularid) {
                setRulesDropdown(data.rule)
                setmonthlyRhythm(data.monthlyRhythm)
                setstartDate(data.startDate)
                setinterval(data.interval)
                if (!!data.daysOfWeek) {
                    setactiveButtons(data.daysOfWeek)
                }
                setisNeverEnds(data.isNeverEnds)
                setAfterNoofAppointments(data.afterNoofAppointments)
                setAtTheDate(data.atTheDate)
                setIsAfterNoofAppointmentsOrAtTheDate(data.isAfterNoofAppointments === true ? "afterNapppoinment" : data.atTheDate === true ? "Atthedate" : "")
            }

            // setIsAtTheDate(data.isAtTheDate)
            // setdatasetforrepetitionModal({
            //     RulesDropdown: data.rule,
            //     monthlyRhythm: data.monthlyRhythm,
            //     startDate: new Date(data.dayDate),
            //     EndDate: new Date(data.dayDateEnd),
            //     interval: data.interval,
            //     days: data.daysOfWeek,
            //     isNeverEnds: data.isNeverEnds,
            //     AfterNoofAppointments: data.afterNoofAppointments,
            //     AtTheDate: data.atTheDate,
            //     IsAfterNoofAppointments: data.isAfterNoofAppointments,
            //     IsAtTheDate: data.isAtTheDate
            // })
            var breaks = [];
            setTotalbreackMinutes(response.data.totalbreackMinutes);

            response?.data?.shiftBreaks?.map((v, i) => {
                v.day = new Date(v.day);
                v.startTimeHour = new Date(v.startTime).getHours();
                v.startTimeMin = new Date(v.startTime).getMinutes();
                v.endTimeHour = new Date(v.endTime).getHours();
                v.endTimeMin = new Date(v.endTime).getMinutes();
                v.id = v.id;
                breaks.push(v);
            })
            formik.setFieldValue("timeTrackingBreaks", breaks)

            getValuesofRules(data.rule, data.daysOfWeek, data.startDate, data.monthlyRhythm)
            getNEXTSummary(data)
            if (!!data.schedularid) {
                setMode("normal")
            } else {
                setMode("dayclick")
            }
        }
    }
    const DropdownIndicator = () => null;
    const getTagsDD = async () => {
        settagsDD(appSettings.tags.filter(x => x.value !== "Withouttags"))
        // var responseTags = await AxiosLite("/api/LocationSetting/getTagsDD|get");
        // if (!!responseTags && responseTags.data.length > 0) {
        //     settagsDD(responseTags.data)
        // }
    }
    const getworkspaces = () => {
        setWorkspaceList(appSettings.WorkSpaces);
        // var response = await dynamicGetApi(
        //     "/api/LocationSetting/getWorkSpaceDD"
        // );
        // if (!!response && response.data.length > 0) {
        //     setWorkspaceList(response.data);
        // }
    }
    const UpdateShiftbtn = async (notifyVal) => {
        if (DayDate === null) {
            Swal.fire({
                icon: 'info',
                title: cl("errorMsg.time"),
            })
            return;
        }
        if (minimumEmployee === "" || parseInt(minimumEmployee) < 0) {
            Swal.fire({
                icon: 'info',
                title: cl("errorMsg.numberOfEmployees"),
            })
            return;
        }
        var startDateTime = moment(DayDate).startOf('day')
            .add(parseInt(StartHousr), 'hours')
            .add(parseInt(StartMin), 'minutes');

        var endDateTime = moment(DayDate).startOf('day')
            .add(parseInt(EndHousr), 'hours')
            .add(parseInt(EndMin), 'minutes');
        if (moment(endDateTime).isSameOrBefore(moment(startDateTime))) {
            toast.error(cl("errorMsg.endTimeGreaterThenStartTime"))
            return;
        }
        var BreaksList = [];
        var TotalBreakTime = 0;
        var startTime = (parseInt(StartHousr) * 60) + parseInt(StartMin);
        var endTime = (parseInt(EndHousr) * 60) + parseInt(EndMin);
        var TotalWorkingTime = (endTime - startTime);
        if (!!formik.values.timeTrackingBreaks && formik.values.timeTrackingBreaks.length > 0) {
            if (formik.values.timeTrackingBreaks.find(x => x.day.setHours(0, 0, 0, 0) !== DayDate.setHours(0, 0, 0, 0)) === undefined) {

                formik.values.timeTrackingBreaks.map((val, key) => {
                    var Breakstart = (parseInt(val.startTimeHour) * 60) + parseInt(val.startTimeMin);
                    var Breakend = (parseInt(val.endTimeHour) * 60) + parseInt(val.endTimeMin);
                    TotalBreakTime = TotalBreakTime + (Breakend - Breakstart);
                    if (Breakstart < startTime || Breakstart > endTime || Breakend < startTime || Breakend > endTime) {
                        Swal.fire({
                            icon: 'error',
                            title: cl("errorMsg.pauseTime"),
                        })
                        throw ("error", cl("errorMsg.pauseTime"))
                        return
                    }
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: cl("errorMsg.pauseTime"),
                })
                return
            }
            if (TotalWorkingTime < TotalBreakTime) {
                Swal.fire({
                    icon: 'info',
                    title: cl("errorMsg.PauseTimeTrackDuration"),
                })
                return
            }
            formik.values.timeTrackingBreaks.map((val, key) => {
                var breakobj = {
                    id: val.id,
                    day: moment(val.day).startOf('day'),
                    startTime: moment(val.day).startOf('day')
                        .add(parseInt(val.startTimeHour), 'hours')
                        .add(parseInt(val.startTimeMin), 'minutes'),
                    endTime: moment(val.day).startOf('day')
                        .add(parseInt(val.endTimeHour), 'hours')
                        .add(parseInt(val.endTimeMin), 'minutes')
                }
                BreaksList.push(breakobj)
            })
        }
        var data = {
            id: props.shiftidforedit,
            workSpaceId: workSpaceValue,
            Schedularid: Schedularid,
            minimumEmployee: minimumEmployee,
            DayDate: startDateTime,
            DayDateEnd: endDateTime,
            Attachment: null,

            StartHourDiff: parseInt(StartHousr) - parseInt(InitialStartHousr),
            StartMinDiff: parseInt(StartMin) - parseInt(InitialStartMin),
            EndHourDiff: parseInt(EndHousr) - parseInt(InitialEndHousr),
            EndMinDiff: parseInt(EndMin) - parseInt(InitialEndMin),
            Tags: Tags,
            Note: Note,
            UpdateDDValue: deletedropdownvalue,
            mode: mode,
            notify: notifyVal,
            ShiftBreaks: BreaksList,

        }
        if (!!File) {
            var obj = {
                OldAttachmentID: !!Attachment ? Attachment : "",
                Attachment: File
            }
            var form_data = new FormData();
            for (var key in obj) {
                if (typeof (obj[key]) === "object" && !!obj[key] && obj[key].length > 0) {
                    for (var i = 0; i < obj[key].length; i++) {
                        form_data.append(key + [], obj[key][i]);
                    }
                } else {
                    form_data.append(key, obj[key]);
                }
            }
            var Attachmentresponse = await CallAxios("/api/Shift/SaveAttachment|post", form_data);
            if (!!Attachmentresponse && Attachmentresponse.status === 200) {
                data.Attachment = {
                    AttachmentName: File.name,
                    AttachmentKey: Attachmentresponse.data
                };
            }
        }
        var response = await CallAxios('/api/Shift/UpdateShift|post', data);
        if (!!response && response.status === 200) {
            if (!!props.calenderDate) {
                props.GetEmployeeList(props.calenderDate)
                props.getShifts?.()
            } else {
                // for month view 
                props.getShifts?.(new Date(moment().startOf('month')), new Date(moment().endOf('month')))

            }
            setdeleteShiftModal?.(false);
            props.onHide?.(false)
            // props.handleHideEditShiftModal();
            if (props.OccupancyModal) {
                props.handleOccupancyModal?.();
            }
        }
    }
    const SaveConfirmationModal = (notifyVal) => {
        setNotify(notifyVal)
        if (mode === "normal") {
            setdeleteShiftModal(true)
        }
        else {
            UpdateShiftbtn(notifyVal);
        }
    }
    const getValuesofRules = (e, days, dateFromApi, monthlyRhythm) => {
        switch (e) {
            case "daily":
                setsummary("")
                break;
            case "yearly":
                setsummary("")
                break;
            case "monthly":
                setMonthlySummary(dateFromApi, monthlyRhythm);
                break;
            case "weekly":
                setWeeklySummary(days);
                break;
            default:
                break;
        }
    }
    useEffect(() => {
        if (interval) {
            setInterValSummary(interval)
        }
    }, [interval, RulesDropdown]);
    const setInterValSummary = (num) => {
        if (Number(num) !== 1) {
            setSummaryStart(`Every ${getNumberWithOrdinal(interval)} ${getdayConventions(num)}`)
        } else {
            setSummaryStart(getdayConventions(num))
        }
    }
    const getdayConventions = (num) => {
        const obj = {
            "daily": "day",
            "weekly": "week",
            "monthly": "month",
            "yearly": "year"
        }
        if (num > 1) {
            return obj[RulesDropdown]
        } else {
            return capitalizeFirstLetter(RulesDropdown)
        }
    }
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const getNumberWithOrdinal = (n) => {
        var s = ["th", "st", "nd", "rd"],
            v = n % 100;
        return n + (s[(v - 20) % 10] || s[v] || s[0]);
    }
    const setWeeklySummary = (days) => {
        if (!!days) {
            let getdays = getDays(days);
            const filteredByValue = Object.fromEntries(Object.entries(getdays).filter(([key, value]) => value === true));
            if (isEmpty(filteredByValue)) {
                setsummary("")
            } else {
                setsummary(" on " + Object.keys(filteredByValue).map((valu) => " " + valu + " "))
            }
        } else {
            setsummary("")
        }
    }
    const getDays = (days) => {
        let activeButtons = { Monday: true, Tuesday: true, Wednesday: true, Thursday: true, Friday: true, Saturday: false, Sunday: false };
        if (!!days && days.length > 0) {
            let newobj = {};
            for (let i = 0; i < Object.keys(activeButtons).length; i++) {
                const gett = Object.keys(activeButtons)[i];
                if (days.split(",").some((item) => item.includes(gett.toUpperCase().substring(0, 3)))) {
                    newobj = { ...newobj, [gett]: true }
                } else {
                    newobj = { ...newobj, [gett]: false }
                }
            }
            return newobj;
        }
    }
    const isEmpty = (obj) => {
        return Object.keys(obj).length === 0;
    }
    const setMonthlySummary = (date, type) => {
        if (type === 'DayofMonth') {
            setmonthlyRhythm("DayofMonth");
            setsummary(" on the " + moment(date).format("Do [day of the month]"));
            setstartDate(date)
        }
        else if (type === 'Weekday') {
            setWeekdaySummary(date)
        }
    }
    const setWeekdaySummary = (date) => {
        let dayStr = moment(date).format('YYYY-MM-DD');
        setsummary(test(moment(dayStr)))
    }
    function test(mJsDate) {
        let convection = "";
        switch (Math.ceil(mJsDate.date() / 7)) {
            case 1:
                convection = "st";
                break;
            case 2:
                convection = "nd";
                break;
            case 3:
                convection = "rd";
                break;
            default:
                convection = "th";
                break;
        }
        var str = " on the " + Math.ceil(mJsDate.date() / 7) + convection + " " + moment(mJsDate.toLocaleString()).format("dddd")
        return str;
    }
    const getNEXTSummary = (data) => {
        let IsAfterNoofAppointmentsOrAtTheDate = data.isAfterNoofAppointments === true ? "afterNapppoinment" : data.isAtTheDate === true ? "Atthedate" : false;
        setNextSummary(data.isNeverEnds === false ? IsAfterNoofAppointmentsOrAtTheDate === "Atthedate" ? !!data.atTheDate ? ", till " + moment(data.atTheDate).format("DD/MM/YYYY") : "" : !!data.afterNoofAppointments ? (", " + data.afterNoofAppointments + " times") : "" : "");
    }
    const DeleteAttachment = async (id) => {
        var Attachmentresponse = await CallAxios("/api/Shift/DeleteAttachment|post", { id: id });
        if (!!Attachmentresponse && Attachmentresponse.data === true) {
            setAttachment(Attachment.filter(x => x.id !== id))
        }
    }
    const RefreshData = () => {
        if (!!props.calenderDate) {
            props.GetEmployeeList(props.calenderDate)
        }
        props.getShifts?.()
        if (props.OccupancyModal) {
            props.handleOccupancyModal();
        }
        props.onHide?.(false);
    }
    const DeleteShiftbtn = async () => {
        var data = {
            deleteValue: "single",
            shiftId: props.shiftidforedit,
            deletefrom: DayDate
        }
        var response = await CallAxios("/api/Calendar/DeleteShifts|post", data);
        if (!!response && response.data === true) {
            RefreshData();
        }
    }
    const [isHovered, setIsHovered] = useState(false);
    return (
        <>
            {
                ShowRepetationModel === true ?
                    <>
                        <RepetitionModal
                            show={ShowRepetationModel}
                            onHide={setShowRepetationModel}
                            File={File}
                            RulesDropdown={RulesDropdown}
                            setRulesDropdown={setRulesDropdown}
                            monthlyRhythm={monthlyRhythm}
                            setmonthlyRhythm={setmonthlyRhythm}
                            startDate={startDate}
                            setstartDate={setstartDate}
                            StartHours={StartHousr}
                            StartMin={StartMin}
                            EndHours={EndHousr}
                            EndMin={EndMin}
                            interval={interval}
                            setinterval={setinterval}
                            activeButtons={activeButtons}
                            setactiveButtons={setactiveButtons}
                            summary={summary}
                            setsummary={setsummary}
                            isNeverEnds={isNeverEnds}
                            setisNeverEnds={setisNeverEnds}
                            AfterNoofAppointments={AfterNoofAppointments}
                            setAfterNoofAppointments={setAfterNoofAppointments}
                            AtTheDate={AtTheDate}
                            setAtTheDate={setAtTheDate}
                            IsAfterNoofAppointmentsOrAtTheDate={IsAfterNoofAppointmentsOrAtTheDate}
                            setIsAfterNoofAppointmentsOrAtTheDate={setIsAfterNoofAppointmentsOrAtTheDate}
                            Schedularid={Schedularid}
                            shiftidforedit={props.shiftidforedit}
                            RefreshData={RefreshData}


                        // DayDate={DayDate}
                        // dataset={datasetforrepetitionModal}
                        // setdeleteShiftModal={setdeleteShiftModal}
                        // setdays={setdays}
                        />
                    </>
                    : null
            }
            {
                deleteShiftModal === true ?
                    <Modal show={deleteShiftModal} onHide={() => setdeleteShiftModal(false)} >
                        <Modal.Header className="RelativeModalHeader" closeButton >
                            <Modal.Title className='text-break'>{cl("editSingleEvent")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <label className='fw-bolder form-label'>{cl("iWantUpdate")}</label>
                                <select className='Noarrow bgPrimaryGrey form-select text-center custom-select mb-3' value={deletedropdownvalue} onChange={(e) => setDeletedropdownvalue(e.target.value)}>
                                    <option value="single">{cl("onlyThisEvent")}</option>
                                    <option value="future">{cl("allFutureEvents")}</option>
                                    <option value="all">{cl("allevents")}</option>
                                </select>
                                <div className="alert alert-primary px-3" role="alert">
                                    <div className='row'>
                                        <div className='col-md-1'>
                                            <BsExclamationCircle style={{ fontSize: "26px" }} />
                                        </div>
                                        <div className='col-md-11'>
                                            {AlertValue[deletedropdownvalue]}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <a className='text-danger me-3' onClick={() => setdeleteShiftModal(false)}>{ct("cancel")}</a>
                            <Button className="btn btnPrimaryGreen" onClick={() => UpdateShiftbtn(notify)}>{ct("update")}</Button>
                        </Modal.Footer>
                    </Modal>
                    : null
            }
            <Modal show={props.show} onHide={() => props.onHide(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{cl("editShift")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={7}>
                                <Form.Group className="mb-3" controlId="NewShiftForm.SelectEditWorkspace">
                                    <Form.Label>{cl("workspace")}</Form.Label>
                                    <Form.Control as="select" value={workSpaceValue} onChange={(e) => setWorkSpaceValue(e.target.value)} className="bgPrimaryGreen" custom>
                                        {
                                            !!workspaceList && workspaceList.length > 0 ?
                                                workspaceList.map((value, key) => {
                                                    return <option value={value.value}>{value.label}</option>
                                                }) : null
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={5}>
                                <Form.Group className="mb-3" controlId="NewShiftForm.txtEditMinNumEmployee">
                                    <Form.Label>{cl("minimumEmployees")}</Form.Label>
                                    <Form.Control type="number" value={minimumEmployee} onChange={(e) => setMinimumEmployee(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="NewShiftForm.txtEditDay">
                                    <Form.Label>{ct("day")}</Form.Label>
                                    <DatePicker className="form-control" selected={DayDate} onChange={(date) => setDayDate(date)} />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="NewShiftForm.txtEditStartTime">
                                    <Form.Label>{ct("startTime")}</Form.Label>
                                    <div className="flexInputGroup d-flex align-items-center ">
                                        <Form.Control as="select" value={StartHousr} onChange={(e) => setStartHousr(e.target.value)} className="Noarrow bgPrimaryGrey" custom>
                                            {
                                                Constants.hoursDropDown.map((val, key) => {
                                                    return <option key={"StartHousr" + key} value={val.value}>{val.text}</option>
                                                })
                                            }
                                        </Form.Control>
                                        <span className="seperator mx-3">:</span>
                                        <Form.Control as="select" className="Noarrow bgPrimaryGrey" value={StartMin} onChange={(e) => setStartMin(e.target.value)} custom>
                                            {
                                                Constants.minutesDropDown.map((val, key) => {
                                                    return <option key={"StartMin" + key} value={val.value}>{val.text}</option>
                                                })
                                            }
                                        </Form.Control>
                                    </div>

                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="NewShiftForm.txtEditEndTime">
                                    <Form.Label>{ct("endTime")}</Form.Label>
                                    <div className="flexInputGroup d-flex align-items-center">
                                        <Form.Control as="select" className="Noarrow bgPrimaryGrey" value={EndHousr} onChange={(e) => setEndHousr(e.target.value)} custom>
                                            {
                                                Constants.hoursDropDown.map((val, key) => {
                                                    return <option key={"EndHousr" + key} value={val.value}>{val.text}</option>
                                                })
                                            }
                                        </Form.Control>
                                        <span className="seperator mx-3">:</span>
                                        <Form.Control as="select" className="Noarrow bgPrimaryGrey" value={EndMin} onChange={(e) => setEndMin(e.target.value)} custom>
                                            {
                                                Constants.minutesDropDown.map((val, key) => {
                                                    return <option key={"EndMin" + key} value={val.value}>{val.text}</option>
                                                })
                                            }
                                        </Form.Control>
                                    </div>

                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <div className="pb-4 border-bottom">
                                    <div>
                                        <label className="form-label">{cl("repetition")}</label>
                                        <div className="flexInputGroup">
                                            <span onClick={() => setShowRepetationModel(true)}>
                                                {
                                                    mode === "normal" ?
                                                        <div className="ActionText"
                                                            onMouseEnter={() => setIsHovered(true)}
                                                            onMouseLeave={() => setIsHovered(false)}
                                                            style={{ color: '#1b9176', cursor: 'pointer', textDecoration: isHovered ? 'underline' : 'none' }}
                                                        >
                                                            <span style={{ paddingRight: '7px' }}>{summaryStart} {summary} {nextSummary}</span>
                                                            <RiEditLine fontSize={20} color="#1b9176" />
                                                        </div>
                                                        :
                                                        <a href="javascript:;" className="ActionText custom-link-style">{cl("createRepetition")}</a>
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            {
                                !Showdetail ?
                                    <Col md={12} className="border-bottom py-3">
                                        <div>{cl("eventBreak")}<span className="fw-bold"> {totalbreackMinutes} </span>{cl("minutesLong")}</div>
                                        <div><a href="javascript:;" className="highlitedLink text-primary" onClick={() => setShowdetail(true)}>{ct('showDetail')}</a></div>
                                    </Col>
                                    : null
                            }
                            {
                                Showdetail && AllowToaddBreak === true ?
                                    <Col md={12} className="border-bottom">
                                        {
                                            appSettings.TimetrackSettings.Resulting_pause_at_least_as_long_as_automatic_pause === true ?
                                                <Form.Group className="mb-0 mt-3">
                                                    <Form.Label>{ct("break")}</Form.Label>
                                                </Form.Group>
                                                : null
                                        }
                                        <TimeTrackingBreaksRepeter formik={formik} DayDate={DayDate} StartHours={StartHousr} StartMin={StartMin} EndHours={EndHousr} EndMin={EndMin} />
                                    </Col>
                                    : null
                            }
                            {/* {
                                    AllowToaddBreak===true?
                                        <Col md={12}>
                                            <div className=" border-bottom">
                                                <div>
                                                    <TTAddBreak formik={formik} obj={TimeTrackObj} Day={DayDate} />
                                                </div>
                                            </div>
                                        </Col>
                                    :null
                                } */}
                            {
                                !ShowFormControl ?
                                    <Col md={12}>
                                        <div className="py-4 " onClick={() => setShowFormControl(!ShowFormControl)}>
                                            <div>
                                                <div className="flexInputGroup" >
                                                    <div className="ActionGreyText">{cl("showFields")}  <BsChevronDown className="CloseActionText" /></div>

                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    : null
                            }
                            {
                                ShowFormControl ?
                                    <Col md={12}>
                                        <div className="py-4 border-bottom">
                                            <Form.Group controlId="NewShiftForm.txtEditTags">
                                                <Form.Label>{cl("tags")}</Form.Label>
                                                <CreatableSelect
                                                    classNamePrefix="MyReactSelect"
                                                    placeholder={ct("select")}
                                                    isMulti
                                                    isClearable={false}
                                                    components={{ DropdownIndicator }}
                                                    options={tagsDD}
                                                    defaultValue={tagsDD.filter(x => Tags.some(v => x.value.includes(v)))}
                                                    getOptionValue={(option) => option.value}
                                                    styles={{
                                                        indicatorSeparator: base => ({
                                                            ...base,
                                                            display: 'none'
                                                        })
                                                    }}
                                                    onChange={async (value, action) => {
                                                        var newtag = value.find(x => x.__isNew__ === true);
                                                        if (!!newtag) {
                                                            var data = {
                                                                tagname: newtag.label
                                                            }
                                                            var response = await CallAxios("/api/LocationSetting/addeditTagFromFilter|post", data)
                                                            if (!!response && response.status === 200) {
                                                                var newobj = { value: response.data, label: newtag.label }
                                                                value.splice(value.findIndex(x => x.__isNew__ === true), 1)
                                                                value.push(newobj)
                                                            }
                                                        }
                                                        let stringarr = [];
                                                        for (let i = 0; i < value.length; i++) {
                                                            stringarr.push(value[i].value)
                                                        }
                                                        setTags(stringarr)
                                                    }}

                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="py-4">
                                            <Form.Group controlId="NewShiftForm.txtEditNote">
                                                <Form.Label>{ct("note")}</Form.Label>
                                                <Form.Control as="textarea" rows={2} value={Note} onChange={(e) => setNote(e.target.value)} />
                                            </Form.Group>
                                        </div>
                                        <div className="py-4">
                                            <a className="btnUpload position-relative">
                                                <FaUpload className="icon me-2" /> <span className="me-2">{!!FileName ? FileName : cl("addAttachment")}</span>
                                                <FileBase64
                                                    hidden
                                                    id='uploadNoteFile'
                                                    ref={fileInput}
                                                    multiple={false}
                                                    onDone={getFiles}
                                                />
                                            </a>
                                        </div>
                                        {
                                            !!Attachment && Attachment.length > 0 ?
                                                Attachment.map((val, key) => {
                                                    return <>
                                                        <div className="d-flex align-items-center">
                                                            <span className="me-2">{val.attachmentName}</span>
                                                            <a target='_blank' href={config.S3BucketURL + val.attachmentKey}><CircleBtnWithIcon className="Btn " color=""><FaDownload /></CircleBtnWithIcon></a>
                                                            <Remove onClick={() => DeleteAttachment(val.id)} />
                                                        </div>
                                                    </>
                                                })
                                                : null
                                        }
                                    </Col>
                                    : null
                            }
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='justify-content-between'>
                    <Button href="javascript:;" onClick={() => { !!Schedularid ? props.setDeleteShiftModal(true) : DeleteShiftbtn() }}>{cl("deleteShift")}</Button>
                    <div>
                        <Button href="javascript:;" className=' me-3' onClick={() => SaveConfirmationModal(true)}>{cl("doneAndNotify")}</Button>
                        <Button className="btn btnPrimaryGreen" onClick={() => SaveConfirmationModal(false)}>{ct("finished")}</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default EditShiftModal;

