import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function DeleteConfirmationModal(props) {
    const { t: ct } = useTranslation('common');

    return (
        <Modal show={props.show} onHide={() => { props.onHide(false) }}>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={12} >
                        {props.message}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btnPrimaryGreen" onClick={() => props.onHide(false)}>{ct('cancel')}</Button>
                <Button className="btn btnPrimaryDanger" onClick={() => props.deletefn(props.objId)}>{ct('yesRemoveNow')}</Button>
            </Modal.Footer>
        </Modal>

    );
}