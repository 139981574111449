import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useDispatch } from 'react-redux';
import { ChooseAll, OptionBox, OptionText, ShowItem, CheckBox } from '../Helpers/Global/StyledComponents';
import { setSelectedTags } from '../Store/slices/AppSlice';
import { useSelector } from 'react-redux';
import { SignInManager } from '../Helpers/Authentication/SignInManager';
import { UpdateReduxStates } from '../Helpers/MasterData';
import { CallAxios } from '../Helpers';
import { Form, Modal } from 'react-bootstrap';
import { FaRegEdit, FaTrashAlt } from 'react-icons/fa';
import { Constants } from '../Helpers/Contents';
import { useTranslation } from "react-i18next";


function TagsCheckSelectFilter(props) {
    const { t: ct } = useTranslation('common');

    const selectedOption = useSelector(s => s.app.selectedTags);
    const appSettings = useSelector(s => s.app.appSettings);
    const user = useSelector(x => x.app.user)
    const listoptions = appSettings.tags;
    const dispatch = useDispatch();

    const [TagsList, setTagsList] = useState([]);
    const [Tag, setTag] = useState("");
    const [deleteModal, setDeleteModal] = useState(false);
    const [TagIdForDel, setTagIdForDel] = useState("");
    const [Newtag, setNewtag] = useState("");
    const [tagEditName, setTagEditName] = useState("");
    useEffect(() => {
        var list = listoptions.map(x => {
            return { ...x, isEditable: false }
        })
        setTagsList(list)
    }, [listoptions]);

    function handleItemClick(val) {
        const findobj = selectedOption.find(x => x.value === val.value);
        const index = selectedOption.indexOf(findobj);
        if (index === -1) {
            dispatch(
                setSelectedTags([...selectedOption, val])
            );
        } else {
            dispatch(
                setSelectedTags(selectedOption.filter(x => x !== findobj))
            );
        }
    }
    const handleChooseAllClick = () => {
        if (listoptions.length > 0) {
            if (selectedOption.length === listoptions.length) {
                dispatch(
                    setSelectedTags([])
                );
            } else {
                var tempOptions = []
                listoptions.map((val, key) => {
                    const index = selectedOption.indexOf(val);
                    if (index === -1) {
                        tempOptions.push(val);
                    }
                })
                dispatch(
                    setSelectedTags([...selectedOption, ...tempOptions])
                );
            }
        }
    }
    const showEditableArea = (index, e) => {
        e.preventDefault();
        e.stopPropagation();
        let prevState = [...TagsList];
        let newarr = prevState.map(x => {
            return { ...x, isEditable: false }
        })
        newarr[index].isEditable = true;
        setTagsList(newarr);
    }
    const updateList = (key, val) => {

        var prev = [...TagsList];
        prev[key].label = val;
        setTagsList(prev)
        setTag(val)
    }
    const removeEditMode = async (tagid) => {
        if (!!tagid && !!Tag) {
            var data = {
                id: tagid,
                tagname: Tag
            }
            var response = await CallAxios("/api/LocationSetting/addeditTagFromFilter|post", data)

            if (!!response && response.status === 200) {
                await UpdateReduxStates(new SignInManager().ParseJwt, dispatch)
            }
        } else {
            let prevState = [...TagsList];
            let newarr = prevState.map(x => {
                if (x.isEditable === false) {
                    return { ...x }
                }
                else {
                    return { ...x, label: tagEditName, isEditable: false }
                }
            })
            setTagsList(newarr);
        }

    }
    const AddNewTag = async (e) => {
        if (e.keyCode == 13 && !!Newtag) {
            var data = {
                tagname: Newtag
            }
            var response = await CallAxios("/api/LocationSetting/addeditTagFromFilter|post", data)
            if (!!response && response.status === 200) {
                setNewtag("")
                await UpdateReduxStates(new SignInManager().ParseJwt, dispatch)
            }
        }
        if (e.keyCode == 32) {
            setNewtag(Newtag + " ")
        }
    }
    const toggleDropdown = async (e) => {
        if (!e) {
            removeEditMode()
        }
    }
    return (
        <>
            {
                deleteModal &&
                <DeleteWorkingAreaConfirmationModal tagIdforDel={TagIdForDel} show={deleteModal} handleClose={setDeleteModal} />
            }

            <DropdownButton onToggle={(e) => toggleDropdown(e)} autoClose="outside" className={props.autoWidth ? 'check-select autoWidth' : 'check-select'} title={selectedOption.length > 0 ? selectedOption.map((item) => item.label).join(",") : "Tags"}>
                <Dropdown.Item className="choose-all">
                    <ChooseAll onClick={handleChooseAllClick}>
                        {
                            selectedOption.length === listoptions.length ? <>{ct('selectNone')}</> : <>{ct('selectAll')}</>
                        }
                    </ChooseAll>
                </Dropdown.Item>
                {(TagsList || []).map((item, index) =>
                    <>
                        {
                            // !!item.label ?
                            item.isEditable === true ?
                                <Dropdown.Item>
                                    <div className="d-flex justify-content-between align-items-center text-left" key={index}>
                                        <Form.Control value={item.label} onChange={(e) => updateList(index, e.target.value)} onKeyUp={(e) => e.keyCode === 13 ? removeEditMode(item.value) : null} type="text" />
                                        <div className="px-2">
                                            <FaTrashAlt onClick={(e) => { setDeleteModal(true); setTagIdForDel(item.value) }} />
                                        </div>
                                    </div>
                                </Dropdown.Item>
                                :
                                <>
                                    <Dropdown.Item onClick={() => handleItemClick(item)} key={item.value}>
                                        <OptionBox>
                                            <CheckBox type="checkbox" disablePointerEvent={true} checked={(selectedOption.some(x => x.value === item.value))} size="sm" />
                                            <OptionText>{item.label}</OptionText>
                                            {
                                                index !== 0 &&
                                                <FaRegEdit style={{ "marginLeft": "10px" }} onClick={(e) => { showEditableArea(index, e); setTagEditName(item.label); setTag(item.label) }} className="IconHoverShow" />
                                            }
                                        </OptionBox>
                                    </Dropdown.Item>
                                </>
                            // : null
                        }
                    </>
                )}
                {
                    user.roles.some(x => x === Constants.roles.WorkspaceAdmin || x === Constants.roles.AccountAdmin || x === Constants.roles.Admin) &&
                    <Dropdown.Item>
                        <div className="my-2">
                            <Form.Control placeholder={ct('tagsSelectFilter.newTag')} value={Newtag} onChange={(e) => setNewtag(e.target.value)} onKeyUp={(e) => { AddNewTag(e) }} type="text" />
                        </div>
                    </Dropdown.Item>
                }
            </DropdownButton>
        </>
    );
}
export default TagsCheckSelectFilter;

function DeleteWorkingAreaConfirmationModal(props) {
    const { t: ct } = useTranslation('common');

    const dispatch = useDispatch();

    const deleteTag = async () => {
        var response = await CallAxios("/api/LocationSetting/DeleteTags|post", { id: props.tagIdforDel })
        if (!!response && response.status === 200) {
            await UpdateReduxStates(new SignInManager().ParseJwt, dispatch)
            props.handleClose(false)
        }
    }
    return (
        <>
            <Modal show={props.show} onHide={() => props.handleClose(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> {ct('tagsSelectFilter.deleteTagTitle')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{ct('tagsSelectFilter.deleteTagPara')}</Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={() => props.handleClose(false)}>
                        {ct('cancel')}
                    </button>
                    <button className="btn btn-danger" onClick={() => deleteTag()}>
                        {ct('yesDeleteNow')}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )

}
