import React from 'react';
import { ContentBox, ContentSubHeader } from '../../../Helpers/Global/StyledComponents';
import { CallAxios, CallAxiosBlob } from '../../../Helpers';
import { useState } from 'react';
import { Button, Col, Container, Form, Modal, Nav, OverlayTrigger, Row, Tab, Table, Tabs, Tooltip } from 'react-bootstrap';
import { BsGear } from 'react-icons/bs';
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import Highlighter from 'react-highlight-words';
import { FaDownload, FaUpload } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import { useTranslation } from "react-i18next";


function EmployeeExportEmpTab() {
    const { t: ct } = useTranslation('common');
    const { t: eit } = useTranslation('exportsImports');


    const [StatusType, setStatusType] = useState("All");
    const [Format, setFormat] = useState("XLSX");

    const submit = async () => {

        var data = {
            Format: Format,
            StatusType: StatusType,
        }
        var response = await CallAxiosBlob("/api/Employee/ExportAccountEmployee|post", data)

        if (!!response && response.status === 200) {
            if (Format === 'CSV') {
                saveAs(response.data, "Absence.csv")
            }
            else if (Format === 'XLSX') {
                saveAs(response.data, "Absence.xlsx")
            }
        }
    }
    return (
        <>
            <Container fluid className='ex-imp-tab-font-fix'>
                <Row className=" desginBox">
                    <Col md={6}>
                        <Form>
                            <Row className="mt-3">

                                <Col md={12} >
                                    <Form.Group onChange={(e) => setStatusType(e.target.value)}>
                                        <Form.Label className="textDesign fs-15px">{ct('status')}</Form.Label>
                                        <div className='d-flex align-items-center '>
                                            <Form.Check className='me-2' type="radio" value="All" checked={StatusType === "All" ? true : false} label={ct('all')} name="SelectExportStatus" />
                                            <Form.Check className='me-2' type="radio" value="Inactive" checked={StatusType === "Inactive" ? true : false} label={ct('inActive')} name="SelectExportStatus" />
                                        </div>
                                    </Form.Group>
                                </Col>

                                <Col md={12} className="mt-3">
                                    <Form.Group onChange={(e) => setFormat(e.target.value)}>
                                        <Form.Label className="textDesign fs-15px">{eit('fileFormat')}</Form.Label>
                                        <div className='d-flex align-items-center '>
                                            <Form.Check className='me-2' type="radio" value="XLSX" checked={Format === "XLSX" ? true : false} label="XLSX" name="SelectFileExporttype" />
                                            <Form.Check className='me-2' type="radio" value="CSV" checked={Format === "CSV" ? true : false} label="CSV" name="SelectFileExporttype" />
                                        </div>
                                    </Form.Group>
                                </Col>

                                <Col md={12} className="mt-5 mb-4">
                                    <Button className="btn btnPrimaryGreen fs-15px" onClick={() => submit()}> {eit('createExport')}</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    {/* Form here the hints section start */}
                    <Col md={6}>
                        <Form>
                            <Row className="block-example border-left border-dark mt-5 p-3">
                                <Col md={12}>
                                    <span muted>
                                        <span className="textdesginHead h5 fw-bold">{eit('hintText')}</span>
                                    </span>
                                </Col>

                                <Col md={12} className="mt-3">
                                    <span muted className='text-break'>
                                        {eit('employeeTab.hints.hint1')}
                                    </span>
                                </Col>

                                <Col md={12} className="mt-3">
                                    <span muted className='text-break'>
                                        {eit('employeeTab.hints.hint2')}
                                        {/* {eit('employeeTab.hints.hint2').split('`/n`').map(line => <span>{line}<br /></span>)} */}
                                    </span>
                                </Col>

                                <Col md={12} className="mt-3">
                                    <span muted className='text-break'>
                                        {eit('employeeTab.hints.hint3')}
                                    </span>
                                </Col>

                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>

        </>
    );
}

export default EmployeeExportEmpTab;