import { useEffect } from "react";
import { useState } from "react";
import SelectInput from "react-select";
import { GetEmployeeDD } from "../Helpers/MasterData";
const DropdownIndicator = () => null;
const FollowersReactSelect = (props) => {
    const [employeeListForadmin, setEmployeeListForadmin] = useState([]);

    useEffect(() => {
        onLoad()
    }, [])
    const onLoad = async () => {
        await GetEmployeeDD(setEmployeeListForadmin)
    }

    return (
        <SelectInput
            classNamePrefix="MyReactSelect"
            isMulti
            isClearable={false}
            components={{ DropdownIndicator }}
            options={employeeListForadmin}
            value={props.setDefault}
            styles={{
                indicatorSeparator: (base) => ({
                    ...base,
                    display: "none",
                }),
            }}
            onChange={(value, action) => {
                props.UpdateFollowers(value);
            }}
        />
    );
}

export default FollowersReactSelect;