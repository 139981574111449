import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import CollapseableDiv from '../../../Components/CollapseCard';
import { AxiosLite, CallAxios } from '../../../Helpers';
import { SignInManager } from '../../../Helpers/Authentication/SignInManager';
import { UpdateReduxStates } from '../../../Helpers/MasterData';
import { useTranslation } from "react-i18next";



function Settings() {
    const { t: Lst } = useTranslation('locationSetting');
    const { t: ct } = useTranslation('common');

    const IdeasCheckboxes = {
        label: [
            // { label: Lst('timeTab.settingAccor.settingLabel1'), name: "summarize_time_records" },
            { label: Lst('timeTab.settingAccor.settingLabel2'), name: "resulting_pause_at_least_as_long_as_automatic_pause" },
            { label: Lst('timeTab.settingAccor.settingLabel3'), name: "time_recordings_automatically_confirmed" },
            { label: Lst('timeTab.settingAccor.settingLabel4'), name: "employees_allowed_to_tag_time_records" },
            { label: Lst('timeTab.settingAccor.settingLabel5'), name: "employees_allowed_edit_working_area_of_time_recordings" },
            { label: Lst('timeTab.settingAccor.settingLabel6'), name: "automatic_break_deduction_after_net_time" },
            { label: Lst('timeTab.settingAccor.settingLabel7'), name: "employee_app" },
            { label: Lst('timeTab.settingAccor.settingLabel8'), name: "copy_tags_from_roster" },
            { label: Lst('timeTab.settingAccor.settingLabel9'), name: "employees_can_create_own_tags" }
        ],
        p: [
            // Lst('timeTab.settingAccor.settingLabelPara1'),
            Lst('timeTab.settingAccor.settingLabelPara2'),
            Lst('timeTab.settingAccor.settingLabelPara3'),
            Lst('timeTab.settingAccor.settingLabelPara4'),
            Lst('timeTab.settingAccor.settingLabelPara5'),
            Lst('timeTab.settingAccor.settingLabelPara6'),
            Lst('timeTab.settingAccor.settingLabelPara7'),
            Lst('timeTab.settingAccor.settingLabelPara8'),
            Lst('timeTab.settingAccor.settingLabelPara9'),
            "Browser time clock: At the end of the break, the window for editing the time recording opens automatically.",
            "Browser time clock: When you clock out, the window for editing the time recording opens automatically.",

        ]
    }

    const initialValues = IdeasCheckboxes;
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            ...initialValues,
            browserClock: "",
            ipclock: "",
        },
        onSubmit: values => {
            submit(values)
        },
    });

    const submit = async (values) => {
        var data = {
            Summarize_time_records: values.summarize_time_records,
            Resulting_pause_at_least_as_long_as_automatic_pause: values.resulting_pause_at_least_as_long_as_automatic_pause,
            Time_recordings_automatically_confirmed: values.time_recordings_automatically_confirmed,
            Employees_allowed_to_tag_time_records: values.employees_allowed_to_tag_time_records,
            Employees_allowed_edit_working_area_of_time_recordings: values.employees_allowed_edit_working_area_of_time_recordings,
            Automatic_break_deduction_after_net_time: values.automatic_break_deduction_after_net_time,
            Employee_app: values.employee_app,
            Copy_tags_from_roster: values.copy_tags_from_roster,
            Employees_can_create_own_tags: values.employees_can_create_own_tags,
        };
        await CallAxios(
            "/api/LocationSetting/editLocationTimeTrackSetting|post", data
        );
        await UpdateReduxStates(new SignInManager().ParseJwt, dispatch)

    };

    useEffect(() => {
        getData()
    }, []);
    const getData = async () => {
        var response = await AxiosLite(
            "/api/LocationSetting/getLocationTimeTrackSetting|get"
        );

        if (!!response && !!response.data) {
            formik.setFieldValue("summarize_time_records", response.data.summarize_time_records)
            formik.setFieldValue("resulting_pause_at_least_as_long_as_automatic_pause", response.data.resulting_pause_at_least_as_long_as_automatic_pause)
            formik.setFieldValue("time_recordings_automatically_confirmed", response.data.time_recordings_automatically_confirmed)
            formik.setFieldValue("employees_allowed_to_tag_time_records", response.data.employees_allowed_to_tag_time_records)
            formik.setFieldValue("employees_allowed_edit_working_area_of_time_recordings", response.data.employees_allowed_edit_working_area_of_time_recordings)
            formik.setFieldValue("automatic_break_deduction_after_net_time", response.data.automatic_break_deduction_after_net_time)
            formik.setFieldValue("employee_app", response.data.employee_app)
            formik.setFieldValue("copy_tags_from_roster", response.data.copy_tags_from_roster)
            formik.setFieldValue("employees_can_create_own_tags", response.data.employees_can_create_own_tags)
        }

    }
    return (
        <>
            <CollapseableDiv title={Lst('timeTab.settingAccor.settings')}>
                <Row>
                    <Col md={12}>
                        <div className="TabFormContainerSection">
                            <div className='checkboxlist'>
                                {IdeasCheckboxes.label.map((val, index) => {
                                    return <Form.Group className="" key={val + index} controlId={"ideasCheckboxes_" + index}>

                                        <Form.Check className="text-left fw-bold"
                                            type="checkbox" name={val.name} onChange={formik.handleChange} checked={formik.values[val.name]} label={val.label} />

                                        <p className="text-info text-left ms-3 ps-1">
                                            {IdeasCheckboxes.p[index]}
                                        </p>
                                    </Form.Group>
                                })}
                            </div>
                            <div className='text-left mt-4'>
                                <button type="button" className="btn btnPrimaryGreen btn-primary" onClick={formik.handleSubmit}> {ct('ready')}</button>
                            </div>

                        </div>
                    </Col>
                </Row>
            </CollapseableDiv>

        </>
    );
}

export default Settings;


