import moment from "moment";
import { createRef, useEffect, useState } from "react";
import { Button, Col, Dropdown, Form, Nav, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { BsChevronLeft, BsChevronRight, BsGear, BsPlusCircle } from "react-icons/bs";
import { FaRegCheckCircle, FaRegCircle, FaRegTimesCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import InputImoji from "../../Components/inputImoji";
import { CallAxios } from "../../Helpers";
import { Constants } from "../../Helpers/Contents";
import AbsenceCalendar from "./AbsenceCalendar";
import { CircleBtnWithIcon } from "../../Helpers/Global/StyledComponents";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function AbsencesView() {
    const { t: ept } = useTranslation('employeePage');
    const { t: ct } = useTranslation('common');

    var absencCalendarRef = createRef();
    const appSettings = useSelector(x => x.app.appSettings)
    const currentView = useSelector(x => x.app.currentView)
    const user = useSelector(x => x.app.user)
    const [calendarAbsenceDetail, setCalendarAbsenceDetail] = useState([]);
    const [calendarAbsenceDetailFilter, setCalendarAbsenceDetailFilter] = useState([]);
    const [MonthDuration, setMonthDuration] = useState(3);
    const [ViewTitle, setViewTitle] = useState("");
    const [text, setText] = useState("");
    const [AbsenceEmployeeListWithBadge, setAbsenceEmployeeListWithBadge] = useState([]);
    const [CompleteAbsenceCalenderData, setCompleteAbsenceCalenderData] = useState([]);
    const [Absenceemployeelist, setAbsenceemployeelist] = useState([]);
    const [absenceShowWeekly, setAbsenceShowWeekly] = useState(true);
    const [HolidayBans, setHolidayBans] = useState([]);

    // const [EmployeeList, setEmployeeList] = useState([]);
    // const [filterEmployeeList, setFilterEmployeeList] = useState([]);
    // const [weekNum, setWeekNum] = useState("");
    // const [showMonth, setShowMonth] = useState(new Date());
    // const [chooseWeekDate, setchooseWeekDate] = useState(new Date());
    useEffect(() => {
        // setWeekNum(parseInt(moment(new Date()).format('W')))
        Promise.all([
            // GetEmployeeList(new Date()),
            GetAbsenceEmployeeList()
        ])
    }, []);

    const onSelectMonthDuration = (event) => {
        let calendar = absencCalendarRef.current;
        setMonthDuration(parseInt(event))

        if (!!calendar && event === 12) {
            let Date = moment().startOf("year");
            calendar.getApi().gotoDate(Date.format());
            setTimeout(function () {
                setViewTitle(calendar.getApi().view.getCurrentData().viewTitle)
            }, 500)
        } else {
            let Date = moment();
            calendar.getApi().gotoDate(Date.format());
            setTimeout(function () {
                setViewTitle(calendar.getApi().view.getCurrentData().viewTitle)
            }, 500)
        }
    }
    const nextMonths = () => {
        let calendar = absencCalendarRef.current;
        if (!!calendar) {
            calendar.getApi().next();
            setViewTitle(calendar.getApi().view.getCurrentData().viewTitle)
        }
    }
    const PrevMonths = () => {
        let calendar = absencCalendarRef.current;
        if (!!calendar) {
            calendar.getApi().prev();
            setViewTitle(calendar.getApi().view.getCurrentData().viewTitle)
        }
    }
    const GetAbsenceEmployeeList = async () => {
        var data = {
            date: new Date(),
            weekno: 0,
            viewType: currentView
        }
        var response = await CallAxios('/api/Calendar/getAllEmployeeAbsence|post', data)
        var AbsenceBanresponse = await CallAxios('/api/Calendar/getAbsenceBan|post', data)

        if (!!response && response.data.length > 0) {
            let arr = [];
            let arrEmployeeAbsence = [];
            response.data.map((value, key) =>
                arr.push({
                    id: value.id,
                    employee: value.employee,
                    // badge: appSettings.locationsettings.Hide_employee_hours_in_calendar === false ? <Button variant="primary" size="sm" className="btnActionChage float-right"><span>{value.weeklyassignHours}/{value.weeklytargetHours}</span></Button> : "",
                    weeklyassignHours: value.weeklyassignHours,
                    weeklytargetHours: value.weeklytargetHours,
                    empid: value.empid //employee have this not badge
                }))
            arr.push({ id: 0, employee: ept('vocationBan'), empid: 0 })
            response.data.map((value, key) =>
                value.calendarAbsenceDetail.map((v, k) =>
                    arrEmployeeAbsence.push({
                        resourceId: v.id,
                        title: v.absenceTypeName,
                        start: v.startDate,
                        end: v.endDate,
                        backgroundColor: v.colour,
                        textColor: 'black',
                        absenceID: v.absenceID,
                        startDate: v.startDate,
                        status: v.status //Admin extra
                    })
                )
            )
            setHolidayBans(AbsenceBanresponse.data.publicHolidays)
            AbsenceBanresponse.data.calendarAbsenceDetail.map((v, k) => arrEmployeeAbsence.push({
                resourceId: 0,
                title: v.title,
                start: v.startDate,
                end: v.endDate,
                backgroundColor: v.colour,
                textColor: 'black',
                absenceID: 0,
                limit: v.limit,
                startDate: v.startDate,
                workArea: v.workAreas,
                absenceType: v.absenceType,
                status: "confirmed"  //Employee extra
            }))
            setAbsenceEmployeeListWithBadge(arr)
            setCalendarAbsenceDetail(arrEmployeeAbsence)
            setCalendarAbsenceDetailFilter(arrEmployeeAbsence)
            setAbsenceemployeelist(arr)
            setCompleteAbsenceCalenderData(response.data)
        }
    }
    const SetabsenceShowWeekly = () => {
        setAbsenceShowWeekly(!absenceShowWeekly)
        // this.state.absenceShowWeekly = !this.state.absenceShowWeekly
        let arr = [];
        arr.push({ id: 0, employee: ept('vocationBan'), empid: 0 })
        if (!absenceShowWeekly) {
            CompleteAbsenceCalenderData.map((value, key) => arr.push({ id: value.id, employee: value.employee, empid: value.empid, weeklyassignHours: value.weeklyassignHours, weeklytargetHours: value.weeklytargetHours }))
            setAbsenceEmployeeListWithBadge(arr)
        } else {
            CompleteAbsenceCalenderData.map((value, key) => arr.push({ id: value.id, employee: value.employee, empid: value.empid, weeklyassignHours: value.monthlyassignHours, weeklytargetHours: value.monthlytargetHours }))
            setAbsenceEmployeeListWithBadge(arr)
        }
    }
    const CreateEmployeeWithoutEmail = async (e) => {
        e.preventDefault();
        if (!!text) {
            var response = await CallAxios('/api/Employee/CreateEmp|post', { name: text })
            if (!!response && response.status === 200) {
                if (response.data === true) {
                    setText("")
                    // self.GetAbsenceEmployeeList()
                    GetAbsenceEmployeeList()
                } else if (response.data === "LimitReach") {
                    toast.error(ept('errorMsg.limitReach'))
                }
            }
        } else {
            alert(ept('errorMsg.name'))
        }
    }
    // const GetEmployeeList = async (date) => {
    //     if (!!date) {
    //         var dateobj = new Date(date)
    //         var d = dateobj.setHours(new Date().getHours(), new Date().getMinutes());
    //         dateobj.setTime(d);
    //         date = dateobj;
    //     }
    //     if (date === undefined) {
    //         let calendar = absencCalendarRef.current;
    //         if (!!calendar) {
    //             date = calendar.getDate();
    //         }
    //     }
    //     let week = parseInt(moment(date).format('W'));
    //     var data = {
    //         date: date,
    //         weekno: week
    //     }
    //     var response = await CallAxios('/api/Calendar/getEmployeeList|post', data)
    //     if (!!response && response.data.length > 0) {
    //         setEmployeeList(response.data)
    //         setFilterEmployeeList(response.data)
    //     }
    // }

    return (
        <>
            <Row>

                <Col md={12} className="lgcol9">
                    <Row>
                        {
                            currentView === Constants.layoutType.Admin &&
                            <FilterAbsenseBtns filterFrom={calendarAbsenceDetailFilter} changeData={(data) => setCalendarAbsenceDetail(data)} />
                        }
                        <Col md={9} className="mb-3">
                            <Nav className="justify-content-end" activeKey="/home">
                                <Nav.Item>
                                    {/* <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip>Actions</Tooltip>}> */}
                                    <Nav.Link data-tooltip-id="my-tooltip" data-tooltip-content={ct("actions")} href="javascript:;" className="p-0">
                                        <Dropdown className="ActionDDList" onSelect={(eventkey) => onSelectMonthDuration(eventkey)}>
                                            <Dropdown.Toggle id="dropdown-basic" className="btnDDList" menuAlign="right">
                                                <BsGear className="ActionToolIcon" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item eventKey="3">
                                                    3 {ept("monthView")}
                                                </Dropdown.Item>
                                                <Dropdown.Item eventKey="6">
                                                    6 {ept("monthView")}
                                                </Dropdown.Item>
                                                <Dropdown.Item eventKey="12">
                                                    12 {ept("monthView")}
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Nav.Link>
                                    {/* </OverlayTrigger> */}
                                </Nav.Item>
                                {/* {
                                    currentView === Constants.layoutType.Employee && */}
                                <Nav.Item>
                                    <div className="ToolMonthChange">
                                        <div>
                                            <a><BsChevronLeft onClick={(e) => PrevMonths()} className="btnPrev" /></a>
                                        </div>
                                        <div>
                                            <div className="MonthChangeContainer">
                                                <span className="MonthTitle">{ViewTitle}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <a><BsChevronRight onClick={(e) => nextMonths()} className="btnNext" /></a>
                                        </div>
                                    </div>
                                </Nav.Item>
                                {/* } */}
                            </Nav>
                        </Col>

                    </Row>
                </Col>
                <Col md={12}>
                    <div className="FullCalendarContainer AbsenceCalWrapper NoToolBar">
                        <AbsenceCalendar
                            // GetEmployeeList={GetEmployeeList}
                            AbsenceEmployeeListWithBadge={AbsenceEmployeeListWithBadge}
                            SetabsenceShowWeekly={SetabsenceShowWeekly}
                            GetAbsenceEmployeeList={GetAbsenceEmployeeList} //GetEmployeeAbsenceList
                            calendarAbsenceDetail={calendarAbsenceDetail} //EmployeeAbsenceList
                            absenceemployeelist={Absenceemployeelist} //employeelist
                            setViewTitle={setViewTitle}
                            calendarRef={absencCalendarRef}
                            HolidayBans={HolidayBans}
                            month={MonthDuration} />

                    </div>
                </Col>
                <Col md={3}>

                    {appSettings.locationsettings.Employees_only_created_by_name === true ?
                        user.roles.length > 0 && user.roles.some(x => x === Constants.roles.AccountAdmin) ?
                            // <Form.Group className="position-relative">
                            //     <InputImoji placeholder="New colleague" value={text} onChange={v => setText(v)} />
                            //     <span className="btnPlusCircle" onClick={() => CreateEmployeeWithoutEmail()}><BsPlusCircle /></span>
                            // </Form.Group>
                            <div className='d-flex align-items-center'>
                                <form onSubmit={CreateEmployeeWithoutEmail}>
                                    <Form.Group className="position-relative flex-grow-1">
                                        <InputImoji placeholder={ept("newColleague")} value={text} onChange={v => setText(v)} />
                                    </Form.Group>
                                </form>
                                <span className="btnPlusCircle ms-2" onClick={() => CreateEmployeeWithoutEmail()}><BsPlusCircle /></span>
                            </div>
                            : null
                        : null
                    }
                </Col>
            </Row>
        </>
    );
}

export default AbsencesView;

function FilterAbsenseBtns(props) {
    const { t: apt } = useTranslation('absencePage');


    const { filterFrom, changeData } = props;
    const [selectedAbsenses, setSelectedAbsenses] = useState(["confirmed", "pending", "rejected"]);
    const Color = useSelector(state => state.app.theme.colors.active);
    const filterAbsensesData = (selected) => {
        if (!selectedAbsenses.includes(selected)) {
            setSelectedAbsenses([...selectedAbsenses, selected]);
        } else {
            setSelectedAbsenses(selectedAbsenses.filter(x => x !== selected));
        }
    }
    useEffect(() => {
        filterData(selectedAbsenses)
    }, [selectedAbsenses]);

    const filterData = (selectedArray) => {
        let newarr = filterFrom.filter(x => selectedArray.includes(x.status));
        if (newarr.length > 0) {
            changeData(newarr)
        } else {
            changeData([])
        }
    }

    return (
        <Col md={3} className="mb-3">
            {/* <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip>Confirmed absences</Tooltip>}> */}
            <a data-tooltip-id="my-tooltip" data-tooltip-content={apt("selectExportMdl.confirmedAbsences")} href="javascript:;" onClick={() => filterAbsensesData("confirmed")}> <CircleBtnWithIcon className="Btn" color={selectedAbsenses.includes("confirmed") ? Color : ""}> <FaRegCheckCircle className={`ActionToolIcon ${selectedAbsenses.includes("confirmed") && "colorGreenPrimary"}  ms-0`} /> </CircleBtnWithIcon></a>
            {/* </OverlayTrigger> */}
            {/* <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip>Open absences</Tooltip>}> */}
            <a data-tooltip-id="my-tooltip" data-tooltip-content={apt("selectExportMdl.openAbsences")} href="javascript:;" onClick={() => filterAbsensesData("pending")}> <CircleBtnWithIcon className="Btn" color={selectedAbsenses.includes("pending") ? Color : ""}><FaRegCircle className={`ActionToolIcon ${selectedAbsenses.includes("pending") && "colorGreenPrimary"}  ms-0`} /> </CircleBtnWithIcon> </a>
            {/* </OverlayTrigger> */}
            {/* <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip>Rejected absence</Tooltip>}> */}
            <a data-tooltip-id="my-tooltip" data-tooltip-content={apt("selectExportMdl.rejectedAbsences")} href="javascript:;" onClick={() => filterAbsensesData("rejected")}> <CircleBtnWithIcon className="Btn" color={selectedAbsenses.includes("rejected") ? Color : ""}> <FaRegTimesCircle className={`ActionToolIcon ${selectedAbsenses.includes("rejected") && "colorGreenPrimary"}  ms-0`} /> </CircleBtnWithIcon></a>
            {/* </OverlayTrigger> */}
        </Col>
    );
}
