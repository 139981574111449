import React from 'react';
import { ContentBox, ContentSubHeader } from '../../../Helpers/Global/StyledComponents';
import { CallAxios, CallAxiosBlob } from '../../../Helpers';
import { useState } from 'react';
import { Button, Col, Container, Form, Modal, Nav, OverlayTrigger, Row, Tab, Table, Tabs, Tooltip } from 'react-bootstrap';
import { BsGear } from 'react-icons/bs';
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import Highlighter from 'react-highlight-words';
import { FaDownload, FaUpload } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import DatePicker from 'react-datepicker';
import { useTranslation } from "react-i18next";

function EmployeeExportTimeRecords() {
    const { t: ct } = useTranslation('common');
    const { t: eit } = useTranslation('exportsImports');


    const [PeriodStartDate, setPeriodStartDate] = useState("");
    const [PeriodEndDate, setPeriodEndDate] = useState("");
    const [status, setstatus] = useState("All");
    const [Employeesstatus, setEmployeesstatus] = useState("List");
    const [FileFormat, setFileFormat] = useState("XLSX");
    const [Add_column_with_employee_staff_number, setAdd_column_with_employee_staff_number] = useState(true);
    const [Export_all_time_tracking_within_the_selected_range, setExport_all_time_tracking_within_the_selected_range] = useState(false);

    const submit = async () => {
        var ismanual = false;
        if (PeriodStartDate !== "" || PeriodEndDate !== "") {
            ismanual = true;
        }

        var data = {
            StartDate: PeriodStartDate === "" ? new Date() : PeriodStartDate,
            EndDate: PeriodEndDate === "" ? new Date() : PeriodEndDate,
            TimetrackStatus: status,
            Employeesstatus: Employeesstatus,
            format: FileFormat,
            IsManual: ismanual,
            Add_column_with_employee_staff_number: Add_column_with_employee_staff_number,
        }
        var response = await CallAxiosBlob("/api/TimeTracking/AccountTimeTrackingsExport|post", data)

        if (!!response && response.status === 200) {
            if (FileFormat === 'CSV') {
                saveAs(response.data, "Absence.csv")
            }
            else if (FileFormat === 'XLSX') {
                saveAs(response.data, "Absence.xlsx")
            }
        }
    }


    return (
        <>
            <Container fluid className='ex-imp-tab-font-fix'>
                <Row className="desginBox">
                    <Col md={6}>
                        <Form>
                            <Row className="mt-3">
                                <Col md={12}>
                                    <Form.Label className="textDesign fs-15px" > {eit('timeRecordsTab.range')}</Form.Label>
                                </Col>
                                <Col md={5}>
                                    <Form.Group controlId="ExportForm.txtWeekDateResource">
                                        <span muted className='fw-normal'>{ct('startDate')}</span>
                                        <DatePicker selected={PeriodStartDate} className="form-control" onChange={date => { setPeriodStartDate(date); setExport_all_time_tracking_within_the_selected_range(true) }} />

                                    </Form.Group>
                                </Col>

                                <Col md={5}>
                                    <Form.Group controlId="ExportForm.txtWeekDateResource">
                                        <span muted className='fw-normal'>{ct('endDate')}</span>
                                        <DatePicker selected={PeriodEndDate} className="form-control" onChange={date => { setPeriodEndDate(date); setExport_all_time_tracking_within_the_selected_range(true) }} />
                                    </Form.Group>
                                </Col>

                                <Col md={12} className="mt-3">
                                    <Form.Group onChange={(e) => setstatus(e.target.value)}>
                                        <Form.Label className="textDesign fs-15px">Status</Form.Label>
                                        <div className='d-flex align-items-center '>
                                            <Form.Check className='me-2' type="radio" value="All" checked={status === "All" ? true : false} label={ct('all')} name="SelectExportStatus" />
                                            <Form.Check className='me-2' type="radio" value="confirmed" checked={status === "confirmed" ? true : false} label={ct('confirmed')} name="SelectExportStatus" />
                                            <Form.Check className='me-2' type="radio" value="pending" checked={status === "pending" ? true : false} label={ct('unconfirmed')} name="SelectExportStatus" />
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col md={12} className="mt-3">
                                    <Form.Group onChange={(e) => setEmployeesstatus(e.target.value)}>
                                        <Form.Label className="textDesign fs-15px">{ct('employee')} Status</Form.Label>
                                        <div className='d-flex align-items-center'>
                                            <Form.Check className='me-2' type="radio" value="All" checked={Employeesstatus === "All" ? true : false} label={ct('all')} name="SelectEmployeeExportStatus" />
                                            <Form.Check className='me-2' type="radio" value="Active" checked={Employeesstatus === "Active" ? true : false} label={ct('active')} name="SelectEmployeeExportStatus" />
                                            <Form.Check className='me-2' type="radio" value="Inactive" checked={Employeesstatus === "Inactive" ? true : false} label={ct('inActive')} name="SelectEmployeeExportStatus" />
                                        </div>

                                    </Form.Group>
                                </Col>

                                <Col md={12} className="mt-3">
                                    <Form.Group onChange={(e) => setFileFormat(e.target.value)}>
                                        <Form.Label className="textDesign fs-15px"> {eit('fileFormat')} </Form.Label>
                                        <div className='d-flex align-items-center '>
                                            <Form.Check className='me-2' type="radio" value="XLSX" checked={FileFormat === "XLSX" ? true : false} label="XLSX" name="SelectFileExporttype" />
                                            <Form.Check className='me-2' type="radio" value="CSV" checked={FileFormat === "CSV" ? true : false} label="CSV" name="SelectFileExporttype" />
                                        </div>

                                    </Form.Group>
                                </Col>


                                <Col md={12} className="mt-3">
                                    <Form.Label className="textDesign fs-15px">{eit('timeRecordsTab.option')}</Form.Label>
                                    <Form.Check name="isSat" onChange={(e) => setAdd_column_with_employee_staff_number(!Add_column_with_employee_staff_number)} label={eit('timeRecordsTab.optionLabel')} checked={Add_column_with_employee_staff_number} type="checkbox" id="AnsenceAllDay" />
                                </Col>

                                <Col md={12} className="mt-5 mb-4">
                                    <Button className="btn btnPrimaryGreen fs-15px" onClick={() => submit()}> {eit('createExport')}</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col md={6}>
                        <Form>
                            <Row className="block-example border-left border-dark mt-5  p-3">
                                <Col md={12}>
                                    <span muted>
                                        <span className="textdesginHead h5 fw-bold"> {eit('hintText')}</span>
                                    </span>
                                </Col>

                                <Col md={12} className="mt-4">
                                    <span muted className='text-break'>
                                        {eit('timeRecordsTab.hints.hint1')}
                                    </span>
                                </Col>

                                <Col md={12} className="mt-4">
                                    <span muted className='text-break'>
                                        {eit('timeRecordsTab.hints.hint2')}
                                    </span>
                                </Col>

                                <Col md={12} className="mt-4">
                                    <span muted className='text-break'>
                                        {eit('timeRecordsTab.hints.hint3')}
                                    </span>
                                </Col>

                                <Col md={12} className="mt-4">
                                    <span muted className='text-break'>
                                        {eit('timeRecordsTab.hints.hint4')}
                                    </span>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>

        </>
    );
}

export default EmployeeExportTimeRecords;