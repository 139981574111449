import * as React from 'react';

/** Extracts the logic required for pagination.
 * Accepts a configuration object with two required properties:
 *
 * count (number, optional) the number of items in the paginated list
 *
 * take (number) the number of items per page
 *
 * initialPage (number, optional) the initial page to display
 *
 * Note: 'count' and 'take' get passed through to avoid having to declare them
 * in the outer scope. This is entirely for convenience.
 */

export const usePagination = ({ take, count: countt, initialPage }) => {
    const [currentPage, setCurrentPage] = React.useState(initialPage ?? 1);
    const [count, setCount] = React.useState(countt ?? 0);

    const pageCount = Math.ceil(count / take);
    const skip = take * (currentPage - 1);

    const isLastPage = currentPage >= pageCount;
    const isFirstPage = currentPage === 1;
    const isMultiPage = pageCount > 1;

    const handlePaginate = (page) => {
        if (!isMultiPage) return;
        if (!isFirstPage && page < currentPage) setCurrentPage(page);
        if (!isLastPage && page > currentPage) setCurrentPage(page);
    };

    const updateCount = (newCount) => setCount(newCount);
    const updateCurrentPage = (newPage) => setCurrentPage(newPage);

    // we may need to wait for data to load before we get the count
    // until we provide a value for 'count', or
    // until we set the count with 'updateCount'
    const isPaginated = typeof count !== 'undefined';

    return {
        currentPage,
        pageCount,
        handlePaginate,
        isLastPage,
        isFirstPage,
        isMultiPage,
        isPaginated,
        updateCount,
        updateCurrentPage,
        skip,
        take,
        count,
    };
};

export default usePagination;
