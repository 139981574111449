import moment from 'moment';
import { default as React, useEffect, useState } from 'react';
import { Button, ButtonGroup, Col, Form, Modal, Nav, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { BsExclamationCircle, BsFillGearFill } from 'react-icons/bs';
import { FaRegCalendarAlt, FaTimes, FaUsers } from 'react-icons/fa';
import { HiOutlineClock } from "react-icons/hi";
import { TiArrowUnsorted } from 'react-icons/ti';
import { useSelector } from 'react-redux';
import { AxiosLite, CallAxios } from '../../Helpers';
import { ActionItem, ActionsBox, ContentBox, ContentSubHeader, SubHeadTitle, SubHeaderTitleBox, SubHeaderWrapper, TableWrapper } from '../../Helpers/Global/StyledComponents';
import { Gear, RemoveCircle } from '../../Components/ActionBtns';
import DataGrid from '../../Components/DataGrid';
import { useTranslation } from "react-i18next";

function Recurrences() {
    const { t: rpt } = useTranslation('recurrencePage');
    const { t: ct } = useTranslation('common');
    const { t: ht } = useTranslation('header');

    const user = useSelector(x => x.app.user)
    const [RecurrenceList, setRecurrenceList] = useState([]);
    const [OccupancyRecurrencList, setOccupancyRecurrencList] = useState([]);
    const [CurrentView, setCurrentView] = useState("CalendarView");
    const [ShowEditShiftPopUp, setShowEditShiftPopUp] = useState(false);
    const [showDeleteShiftPopUp, setShowDeleteShiftPopUp] = useState(false);
    const [selectedButtonId, setSelectedButtonId] = useState(null);
    const [modalType, setModalType] = useState("");
    const [empId, setEmpId] = useState(null);
    const [StartHours, setStartHours] = useState(null);
    const [StartMin, setStartMin] = useState(null);
    const [EndHours, setEndHours] = useState(null);
    const [EndMin, setEndMin] = useState(null);
    const themeColorBtn = useSelector(state => state.app.theme.colors.buttons);
    useEffect(() => {
        GetList()
        GetOccupancyRecurrenc()
    }, []);
    const GetList = async () => {
        var response = await AxiosLite('/api/Calendar/GetRecurrencList|get')
        if (!!response && response.status === 200) {
            setRecurrenceList(response.data)
        }
    }
    const GetOccupancyRecurrenc = async () => {
        var response = await AxiosLite('/api/Calendar/GetAvalibilityRecurrencList|get')
        if (!!response && response.status === 200) {
            setOccupancyRecurrencList(response.data)
        }
    }
    const sorttableData = (e) => {

        let sortText = e.target.getAttribute("sort");
        let prevList = [...RecurrenceList];
        let result = "";
        let sortBy = (prevList, p, o = 1) => [...prevList].sort((a, b) => a[p].localeCompare(b[p]) * o);
        if (sortText === "asc") {
            result = sortBy(prevList, 'surname');
            e.target.setAttribute("sort", 'desc');
        } else {
            result = sortBy(prevList, 'surname', -1);
            e.target.setAttribute("sort", 'asc');
        }
        setRecurrenceList(result)
    }

    const DeleteShiftRecurrence = async (deletedropdownvalue) => {
        var data = {
            DeleteValue: deletedropdownvalue,
            SchedularId: selectedButtonId,
            Deletefrom: new Date()
        }
        var response = null;
        if (modalType === "availibility") {
            response = await CallAxios(
                "/api/Calendar/RemoveEmployeeAvailabilitySchedular|post", data
            );
        } else {
            response = await CallAxios(
                "/api/Calendar/DeleteSchedular|post", data
            );
        }
        if (!!response && response.data === true) {
            if (modalType === "availibility") {
                GetOccupancyRecurrenc()
            } else {
                GetList()
            }
            setShowDeleteShiftPopUp(false)
        }
    }
    const openShiftEditModel = (obj) => {
        setSelectedButtonId(obj.schedularId);
        setStartHours(obj.start.split(":")[0])
        setStartMin(obj.start.split(":")[1])
        setEndHours(obj.end.split(":")[0])
        setEndMin(obj.end.split(":")[1])
        setModalType("Shift");
        setShowEditShiftPopUp(true)
    }
    const openAvailibiltyEditModel = (obj) => {
        setEmpId(obj.employeeId);
        setSelectedButtonId(obj.schedularId);
        setStartHours(obj.start.split(":")[0])
        setStartMin(obj.start.split(":")[1])
        setEndHours(obj.end.split(":")[0])
        setEndMin(obj.end.split(":")[1])
        setModalType("availibility");
        setShowEditShiftPopUp(true)
    }

    const columns = [
        { key: 'startDate', name: rpt('tbl.startDate'), sortable: false, searchable: true, disableSearch: true },
        { key: 'endDate', name: rpt('tbl.endDate'), sortable: false, searchable: true, disableSearch: true },
        { key: 'start', name: 'Start', sortable: false, searchable: true, disableSearch: true },
        { key: 'end', name: ct('end'), sortable: false, searchable: true, disableSearch: true },
        { key: 'weekdays', name: rpt('tbl.weekdays'), sortable: false, searchable: true, disableSearch: true },
        { key: 'startDate', name: ct('summary'), sortable: false, searchable: true, disableSearch: true },
        { key: 'employee', name: ct('employee'), sortable: true, searchable: true, disableSearch: false },
        { key: 'workingArea', name: ct('workingArea'), sortable: true, searchable: true, disableSearch: false },
        { key: 'status', name: 'Status', sortable: true, searchable: true, disableSearch: true },
        { key: 'action', name: ct('action') },

    ];


    return (
        <>
            <ContentSubHeader>
                <SubHeaderWrapper>
                    <SubHeaderTitleBox>
                        <HiOutlineClock color={themeColorBtn} size={22} />
                        <SubHeadTitle>{ht('recurrences')} {user.LocationName}</SubHeadTitle>
                    </SubHeaderTitleBox>
                    <ActionsBox>
                        <ActionItem>
                            <Nav.Link data-tooltip-id="my-tooltip" data-tooltip-content={rpt('calendarTooltip')} data-tooltip-place="bottom" href="javascript:;" className={CurrentView === "CalendarView" ? 'active' : null} onClick={() => setCurrentView("CalendarView")}>
                                <FaRegCalendarAlt className="HeaderActionIcon" />
                            </Nav.Link>
                        </ActionItem>
                        <ActionItem>
                            <Nav.Link data-tooltip-id="my-tooltip" data-tooltip-content={rpt('availabilityTooltip')} data-tooltip-place="bottom" href="javascript:;" className={CurrentView === "AvailabilityView" ? 'active' : null} onClick={() => setCurrentView("AvailabilityView")}>
                                <FaUsers className="HeaderActionIcon" />
                            </Nav.Link>
                        </ActionItem>
                    </ActionsBox>
                </SubHeaderWrapper>
            </ContentSubHeader>
            <ContentBox>

                {
                    CurrentView === "CalendarView" ?
                        <Row>
                            <Col md={12}>
                                <DataGrid
                                    columns={columns}
                                    rows={RecurrenceList}
                                    itemsPerPage={10}
                                    customBody={(rows) =>
                                        rows?.map((value, i) => {
                                            return <tr>
                                                <td>
                                                    <div className="dataTextCell">
                                                        {value.startDate}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="dataTextCell">
                                                        {value.endDate}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="dataTextCell">
                                                        {value.start}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="dataTextCell">
                                                        {value.end}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="dataTextCell">
                                                        {value.weekdays}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="dataTextCell">
                                                        {value.startDate}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="dataTextCell">
                                                        {value.employee}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="dataTextCell">
                                                        {value.workingArea}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className={`dataTextCell text-center `}>
                                                        <Button variant='primary'>{value.status}</Button>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="dataTextCell">
                                                        {/* <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip>Edit</Tooltip>}> */}
                                                        <Gear data-tooltip-id="my-tooltip" data-tooltip-content={ct('edit')} className="btnTblAction bgBlue" onClick={() => { openShiftEditModel(value) }} />
                                                        {/* <a  data-tooltip-id="my-tooltip" data-tooltip-content="Edit"  className="btnTblAction bgBlue" onClick={() => { openShiftEditModel(value) }}><BsFillGearFill /></a> */}
                                                        {/* </OverlayTrigger> */}
                                                        {/* <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip>Cancel</Tooltip>}> */}
                                                        <RemoveCircle data-tooltip-id="my-tooltip" data-tooltip-content={ct('cancel')} onClick={(e) => { setSelectedButtonId(value.schedularId); setModalType("Shift"); setShowDeleteShiftPopUp(true) }} color='#f25656' />
                                                        {/* <a  data-tooltip-id="my-tooltip" data-tooltip-content="Cancel"  className="btnTblAction bgRed" onClick={(e) => { setSelectedButtonId(value.schedularId); setModalType("Shift"); setShowDeleteShiftPopUp(true) }}><FaTimes /></a> */}
                                                        {/* </OverlayTrigger> */}
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                        )
                                    } />

                            </Col>
                        </Row>
                        : null
                }
                {
                    CurrentView === "AvailabilityView" ?
                        <Row>
                            <Col md={12}>
                                <TableWrapper>
                                    <Table striped bordered hover className="DataGridTable overflowVisible text-left">
                                        <thead>
                                            <tr className='firsthead'>
                                                <th> {ct('employee')} <TiArrowUnsorted sort="asc" onClick={(e) => sorttableData(e)} /></th>
                                                <th> {rpt('tbl.startDate')} <TiArrowUnsorted sort="asc" onClick={(e) => sorttableData(e)} /></th>
                                                <th> {rpt('tbl.endDate')} <TiArrowUnsorted sort="asc" onClick={(e) => sorttableData(e)} /></th>
                                                <th>Start <TiArrowUnsorted sort="asc" onClick={(e) => sorttableData(e)} /></th>
                                                <th> {ct('end')} <TiArrowUnsorted sort="asc" onClick={(e) => sorttableData(e)} /></th>
                                                <th> {rpt('tbl.weekdays')} <TiArrowUnsorted sort="asc" onClick={(e) => sorttableData(e)} /></th>
                                                <th> {ct('summary')} <TiArrowUnsorted sort="asc" onClick={(e) => sorttableData(e)} /></th>
                                                <th>Status <TiArrowUnsorted sort="asc" onClick={(e) => sorttableData(e)} /></th>
                                                <th> {ct('actions')}</th>
                                            </tr>
                                            <tr>
                                                <th><Form.Control type="text" disabled /></th>
                                                <th><Form.Control type="text" disabled /></th>
                                                <th><Form.Control type="text" disabled /></th>
                                                <th><Form.Control type="text" disabled /></th>
                                                <th><Form.Control type="text" disabled /></th>
                                                <th><Form.Control type="text" disabled /></th>
                                                <th><Form.Control type="text" disabled /></th>
                                                <th><Form.Control type="text" disabled /></th>
                                                <th><Form.Control type="text" disabled /></th>
                                            </tr>
                                        </thead>
                                        <tbody>


                                            {
                                                !!OccupancyRecurrencList && OccupancyRecurrencList.length > 0 ?
                                                    OccupancyRecurrencList.map((value, key) => {
                                                        return <tr>
                                                            <td>
                                                                <div className="dataTextCell">
                                                                    {value.employeeName}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="dataTextCell">
                                                                    {value.startDate}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="dataTextCell">
                                                                    {value.endDate}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="dataTextCell">
                                                                    {value.start}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="dataTextCell">
                                                                    {value.end}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="dataTextCell">
                                                                    {value.weekdays}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="dataTextCell">
                                                                    {value.startDate}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className={`dataTextCell btn btn-sm ${value.status === "Active" ? "btn-success" : "btn-danger"}`}>
                                                                    {value.status}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="dataTextCell">
                                                                    {/* <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip>Edit</Tooltip>}> */}
                                                                    <a data-tooltip-id="my-tooltip" data-tooltip-content={ct('edit')} className="btnTblAction bgBlue" onClick={() => { openAvailibiltyEditModel(value) }}><BsFillGearFill /></a>
                                                                    {/* </OverlayTrigger> */}
                                                                    {/* <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip>Cancel</Tooltip>}> */}
                                                                    <a data-tooltip-id="my-tooltip" data-tooltip-content={ct('cancel')} className="btnTblAction bgRed" onClick={(e) => { setSelectedButtonId(value.schedularId); setEmpId(value.employeeId); setModalType("availibility"); setShowDeleteShiftPopUp(true) }}><FaTimes /></a>
                                                                    {/* </OverlayTrigger> */}
                                                                </div>
                                                            </td>
                                                        </tr>

                                                    })
                                                    : null
                                            }
                                        </tbody>
                                    </Table>
                                </TableWrapper>
                            </Col>
                        </Row>
                        : null
                }
            </ContentBox>
            {
                ShowEditShiftPopUp ?
                    <RepetitionModal ShowEditShiftPopUp={ShowEditShiftPopUp} StartHours={StartHours} StartMin={StartMin} EndHours={EndHours} EndMin={EndMin} empId={empId} modalType={modalType} schedularID={selectedButtonId} reloadShiftOccurence={GetList} setShowEditShiftPopUp={setShowEditShiftPopUp} />
                    : null
            }
            {
                showDeleteShiftPopUp ?
                    <Modal show={showDeleteShiftPopUp} className="" onHide={() => setShowDeleteShiftPopUp(false)}>
                        <Modal.Header closeButton className="">
                            <Modal.Title> {rpt('deleteShiftMdl.title')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h6> {rpt('deleteShiftMdl.para')}</h6>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="btn btn-success mx-2" onClick={() => DeleteShiftRecurrence("future")} > {rpt('deleteShiftMdl.futureElements')}</Button>
                            <Button className="btn btn-success mx-2" onClick={() => DeleteShiftRecurrence("all")} > {rpt('deleteShiftMdl.allElements')}</Button>
                        </Modal.Footer>
                    </Modal>
                    : null
            }
        </>
    )
}
export default Recurrences;
function RepetitionModal(props) {
    const { t } = useTranslation('myProfile');
    const { t: rpt } = useTranslation('recurrencePage');
    const { t: sdt } = useTranslation('selectDropdown');
    const { t: ct } = useTranslation('common');

    const intervalText = {
        daily: <span className="seperator">{ct('day')}(s)</span>,
        weekly: <span className="seperator">{ct('week')}(s)</span>,
        monthly: <span className="seperator">{ct('month')}(s)</span>,
        yearly: <span className="seperator">{ct('year')}(s)</span>,
    }

    const [RulesDropdown, setRulesDropdown] = useState("weekly");
    const [monthlyRhythm, setmonthlyRhythm] = useState("Weekday");
    const [startDate, setstartDate] = useState(new Date());
    const [interval, setinterval] = useState(1);
    const [activeButtons, setactiveButtons] = useState({ Monday: true, Tuesday: true, Wednesday: true, Thursday: true, Friday: true, Saturday: false, Sunday: false });
    const [summary, setsummary] = useState("Daily");
    const [isNeverEnds, setisNeverEnds] = useState(true);
    const [AfterNoofAppointments, setAfterNoofAppointments] = useState(null);
    const [AtTheDate, setAtTheDate] = useState(null);
    const [IsAfterNoofAppointmentsOrAtTheDate, setIsAfterNoofAppointmentsOrAtTheDate] = useState("");
    const [IsAtTheDate, setIsAtTheDate] = useState(false);

    const [confimationShiftModal, setConfimationShiftModal] = useState(false);
    const [deletedropdownvalue, setDeletedropdownvalue] = useState("future");
    const [summaryStart, setSummaryStart] = useState("");

    useEffect(() => {
        if (!!props.schedularID) {
            GetSchedularData()
        }
    }, [props.schedularID]);

    const GetSchedularData = async () => {
        var response = await CallAxios(
            "/api/Calendar/GetSchedularByid|post", { id: props.schedularID }
        );
        if (!!response && response.status === 200) {
            let data = response.data;
            setmonthlyRhythm(!!data.monthlyRhythm ? data.monthlyRhythm : "Weekday");
            setRulesDropdown(data.type);
            setinterval(data.interval);
            setisNeverEnds(data.isNeverEnds);
            setstartDate(data.startsOn);
            setIsAtTheDate(data.isAtTheDate);
            setAfterNoofAppointments(!!data.occurances ? data.occurances : null);
            if (!!data.endsOn) {
                setAtTheDate(new Date(data.endsOn));
            }
            setIsAfterNoofAppointmentsOrAtTheDate(data.isAfterNoofAppointments === true ? "afterNapppoinment" : data.isAtTheDate === true ? "Atthedate" : false);
            if (!!data.days && data.days.length > 0) {
                let newobj = {};
                for (let i = 0; i < Object.keys(activeButtons).length; i++) {
                    const gett = Object.keys(activeButtons)[i];
                    if (data.days.split(",").some((item) => item.includes(gett.toUpperCase().substring(0, 3)))) {
                        newobj = { ...newobj, [gett]: true }
                    } else {
                        newobj = { ...newobj, [gett]: false }
                    }
                }
                setactiveButtons(newobj)
            } else {
                setactiveButtons({ Monday: false, Tuesday: false, Wednesday: false, Thursday: false, Friday: false, Saturday: false, Sunday: false })
            }
        }
    }
    const UpdateShiftbtn = async () => {
        var startDateTime = moment(props.DayDate).startOf('day')
            .add(parseInt(props.StartHours), 'hours')
            .add(parseInt(props.StartMin), 'minutes');

        var endDateTime = moment(props.DayDate).startOf('day')
            .add(parseInt(props.EndHours), 'hours')
            .add(parseInt(props.EndMin), 'minutes');
        var data = null;
        if (props.modalType === "availibility") {
            data = {
                empId: props.empId,
                Schedularid: props.schedularID,
                StartDate: startDateTime,
                EndDate: endDateTime,
                Rule: RulesDropdown,
                interval: parseInt(interval),
                monthlyRhythm: monthlyRhythm,
                isNeverEnds: isNeverEnds,
                daysOfWeek: [],
                IsAtTheDate: IsAtTheDate,
                AtTheDate: !!AtTheDate ? AtTheDate : null,
                IsAfterNoofAppointments: IsAfterNoofAppointmentsOrAtTheDate,
                AfterNoofAppointments: !!AfterNoofAppointments ? parseInt(AfterNoofAppointments) : null,
                UpdateDDValue: deletedropdownvalue,
            }
        } else {
            data = {
                id: props.schedularID,
                ShiftDate: startDateTime,
                ShiftEndDate: endDateTime,
                Rule: RulesDropdown,
                interval: parseInt(interval),
                monthlyRhythm: monthlyRhythm,
                isNeverEnds: isNeverEnds,
                daysOfWeek: [],
                IsAtTheDate: IsAtTheDate,
                AtTheDate: !!AtTheDate ? AtTheDate : null,
                IsAfterNoofAppointments: IsAfterNoofAppointmentsOrAtTheDate,
                AfterNoofAppointments: !!AfterNoofAppointments ? parseInt(AfterNoofAppointments) : null,
                UpdateDDValue: deletedropdownvalue,
                Attachment: null,
            }
        }

        let arr = [];
        Object.entries(activeButtons).map(([key, value]) => value === true ? arr.push(key.toUpperCase().substring(0, 3)) : console.log(key, value));
        data.daysOfWeek = (arr.join(','));

        if (IsAfterNoofAppointmentsOrAtTheDate === "afterNapppoinment") {
            data.IsAfterNoofAppointments = true;
            data.IsAtTheDate = false;
        }
        if (IsAfterNoofAppointmentsOrAtTheDate === "Atthedate") {
            data.IsAfterNoofAppointments = false;
            data.IsAtTheDate = true;
        }
        if (IsAfterNoofAppointmentsOrAtTheDate === "") {
            data.IsAfterNoofAppointments = false;
            data.IsAtTheDate = false;
        }
        var response = null;
        if (props.modalType === "availibility") {
            response = await CallAxios('/api/Calendar/UpdateAvailabilitySchedular|post', data);
        } else {
            response = await CallAxios('/api/Shift/UpdateShedular|post', data);
        }
        if (!!response && response.status === 200) {
            props.reloadShiftOccurence()
            props.setShowEditShiftPopUp()
        }
    }

    useEffect(() => {
        setWeeklySummary();
    }, [activeButtons]);
    useEffect(() => {
        setSummeryOnBasisOfRuleDropdown(RulesDropdown);
    }, [RulesDropdown]);
    const changingButtonState = (e) => {
        setactiveButtons({
            ...activeButtons,
            [e.target.name]: !activeButtons[e.target.name]
        })

    }
    const getValuesofRules = (e) => {
        setRulesDropdown(e.target.value);
        switch (e.target.value) {
            case "daily":
                setsummary("")
                break;
            case "yearly":
                setsummary("")
                break;
            case "monthly":
                setMonthlySummary(startDate, monthlyRhythm);
                break;
            case "weekly":
                setWeeklySummary();
                break;
            default:
                break;
        }
    }
    const setSummeryOnBasisOfRuleDropdown = (value) => {
        switch (value) {
            case "daily":
                setsummary("")
                break;
            case "yearly":
                setsummary("")
                break;
            case "monthly":
                setMonthlySummary(startDate, monthlyRhythm);
                break;
            case "weekly":
                setWeeklySummary();
                break;
            default:
                break;
        }
    }
    useEffect(() => {
        if (interval) {
            setInterValSummary(interval)
        }
    }, [interval, RulesDropdown, monthlyRhythm]);
    const setInterValSummary = (num) => {
        if (Number(num) !== 1) {
            setSummaryStart(`Every ${getNumberWithOrdinal(interval)} ${getdayConventions(num)}`)
        } else {
            setSummaryStart(getdayConventions(num))
        }
    }
    const getdayConventions = (num) => {
        const obj = {
            "daily": "day",
            "weekly": "week",
            "monthly": "month",
            "yearly": "year"
        }
        if (num > 1) {
            return obj[RulesDropdown]
        } else {
            return capitalizeFirstLetter(RulesDropdown)
        }
    }
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const getNumberWithOrdinal = (n) => {
        var s = ["th", "st", "nd", "rd"],
            v = n % 100;
        return n + (s[(v - 20) % 10] || s[v] || s[0]);
    }
    const setWeeklySummary = () => {
        const filteredByValue = Object.fromEntries(
            Object.entries(activeButtons).filter(([key, value]) => value === true));
        if (isEmpty(filteredByValue)) {
            setsummary("")
        } else {
            setsummary(" on " + Object.keys(filteredByValue).map((valu) => " " + valu + " "))
        }
    }
    const isEmpty = (obj) => {
        return Object.keys(obj).length === 0;
    }
    const setMonthlySummary = (date, type) => {
        if (type === 'DayofMonth') {
            setmonthlyRhythm("DayofMonth");
            let day = moment(date).format("w");
            // setsummary("Monthly on the " + moment(date).format("Do [day of the month]"));
            setsummary(" on the " + moment(date).format("Do [day of the month]"));
            setstartDate(date)
        }
        else if (type === 'Weekday') {
            setWeekdaySummary(startDate)
        }
    }
    const setWeekdaySummary = (date) => {
        let dayStr = moment(date).format('YYYY-MM-DD');
        setsummary(test(moment(dayStr)))

    }
    function test(mJsDate) {
        let convection = "";
        switch (Math.ceil(mJsDate.date() / 7)) {
            case 1:
                convection = "st";
                break;
            case 2:
                convection = "nd";
                break;
            case 3:
                convection = "rd";
                break;
            default:
                convection = "th";
                break;
        }
        var str = " on the " + Math.ceil(mJsDate.date() / 7) + convection + " " + moment(mJsDate.toLocaleString()).format("dddd")
        return str;
    }
    const updateMonthlyrythm = (date, type) => {
        if (type === 'DayofMonth') {
            if (!!date) {
                date = startDate
            }
            setmonthlyRhythm("DayofMonth");
            let day = moment(date).format("w");
            setsummary(" on the " + moment(date).format("Do [day of the month]"));
            setstartDate(date)
        }
        else {
            setWeekdaySummary(startDate)
        }
        if (!!type) {
            setmonthlyRhythm(type)
        } else {
            setmonthlyRhythm(monthlyRhythm)
        }
    }
    const getNEXTSummary = () => {
        return isNeverEnds === false ? IsAfterNoofAppointmentsOrAtTheDate === "Atthedate" ? !!AtTheDate ? ", till " + moment(AtTheDate).format("DD/MM/YYYY") : "" : !!AfterNoofAppointments ? (", " + AfterNoofAppointments + " times") : "" : ""
    }

    const AlertValue = {
        single: t('availbltyTab.deleteShiftMdl.alertSingle').toString(),
        future: t('availbltyTab.deleteShiftMdl.alertFuture').toString(),
        all: t('availbltyTab.deleteShiftMdl.alertAll').toString(),
    }

    return (
        <>
            {
                confimationShiftModal === true ?
                    <Modal show={confimationShiftModal} onHide={() => setConfimationShiftModal(false)} >
                        <Modal.Header className="RelativeModalHeader" closeButton >
                            <Modal.Title className="text-break"> {t('availbltyTab.deleteShiftMdl.title')}? </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <label className='fw-bolder'> {sdt('availbltyTab.iWantUpdate.labelTitle')} </label>
                                <select className='Noarrow bgPrimaryGrey text-center form-select custom-select mb-3' value={deletedropdownvalue} onChange={(e) => setDeletedropdownvalue(e.target.value)}>
                                    <option value="future"> {sdt('availbltyTab.iWantUpdate.option1')} </option>
                                    <option value="all"> {sdt('availbltyTab.iWantUpdate.option2')} </option>
                                </select>
                                <div className="alert alert-primary px-3" role="alert">
                                    <div className='row'>
                                        <div className='col-md-1'>
                                            <BsExclamationCircle style={{ fontSize: "26px" }} />
                                        </div>
                                        <div className='col-md-11'>
                                            {AlertValue[deletedropdownvalue]}

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <a className='text-danger me-3' onClick={() => setConfimationShiftModal(false)}> {ct('cancel')} </a>
                            <Button className="btn btnPrimaryGreen" onClick={() => UpdateShiftbtn()}> {ct('update')} </Button>
                        </Modal.Footer>
                    </Modal>
                    : null
            }
            <Modal show={props.ShowEditShiftPopUp} onHide={() => { props.setShowEditShiftPopUp(false) }}>
                <Modal.Header closeButton >
                    <Modal.Title> {t('availbltyTab.editRepeatMdl.title')} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={12} >
                                <Form.Group controlId="RepetitionShiftForm.SelectRule">
                                    <Form.Label> {sdt('availbltyTab.rule.labelTitle')}</Form.Label>
                                    <Form.Control as="select" value={RulesDropdown} onChange={(e) => getValuesofRules(e)} custom>
                                        <option value="daily"> {sdt('availbltyTab.rule.option1')} </option>
                                        <option value="weekly"> {sdt('availbltyTab.rule.option2')}</option>
                                        <option value="monthly"> {sdt('availbltyTab.rule.option3')}</option>
                                        <option value="yearly"> {sdt('availbltyTab.rule.option4')}</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={12} className='mt-1'>
                                <Form.Group controlId="RepetitionShiftForm.txtInterval">
                                    <Form.Label> {rpt('interval')} </Form.Label>
                                    <div className="flexInputGroup">
                                        <Form.Control as="select" onChange={(e) => setinterval(e.target.value)} style={{ width: "80px" }} value={interval} custom>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                            <option value="13">13</option>
                                            <option value="14">14</option>
                                            <option value="15">15</option>
                                            <option value="16">16</option>
                                            <option value="17">17</option>
                                            <option value="18">18</option>
                                            <option value="19">19</option>
                                            <option value="20">20</option>
                                            <option value="21">21</option>
                                            <option value="22">22</option>
                                            <option value="23">23</option>
                                            <option value="24">24</option>
                                            <option value="25">25</option>
                                            <option value="26">26</option>
                                            <option value="27">27</option>
                                            <option value="28">28</option>
                                            <option value="29">29</option>
                                            <option value="30">30</option>
                                            <option value="31">31</option>
                                        </Form.Control>
                                        {/* <Form.Control type="number" onChange={(e) => setinterval(e.target.value)} value={interval} className="smallNumFormControl"/> */}
                                        {intervalText[RulesDropdown]}
                                    </div>

                                </Form.Group>
                            </Col>
                            {RulesDropdown === "monthly" ?
                                <Col md={12} >
                                    <label className="form-label"> {sdt('availbltyTab.monthly.labelTitle')} </label>
                                    <Form.Control as="select" value={monthlyRhythm} onChange={(e) => updateMonthlyrythm(startDate, e.target.value)} custom>
                                        <option value="Weekday"> {sdt('availbltyTab.monthly.option1')} </option>
                                        <option value="DayofMonth"> {sdt('availbltyTab.monthly.option2')} </option>
                                    </Form.Control>
                                </Col>
                                : null}
                            {RulesDropdown === "weekly" ?
                                <Col md={12}>
                                    <label className="form-label"> {sdt('availbltyTab.daysOfWeek.labelTitle')} </label>
                                    <div>
                                        <ButtonGroup onClick={(e) => changingButtonState(e)} aria-label="Basic example" size="sm" className="btnSelectGroup">
                                            <Button name='Monday' variant="secondary" className={`${activeButtons.Monday === true ? " btnPrimaryGreen" : ""} makeitselect`} > {sdt('availbltyTab.daysOfWeek.option1')} </Button>
                                            <Button name='Tuesday' variant="secondary" className={`${activeButtons.Tuesday === true ? " btnPrimaryGreen" : ""} makeitselect`}> {sdt('availbltyTab.daysOfWeek.option2')}</Button>
                                            <Button name='Wednesday' variant="secondary" className={`${activeButtons.Wednesday === true ? " btnPrimaryGreen" : ""} makeitselect`}> {sdt('availbltyTab.daysOfWeek.option3')}</Button>
                                            <Button name='Thursday' variant="secondary" className={`${activeButtons.Thursday === true ? " btnPrimaryGreen" : ""} makeitselect`}> {sdt('availbltyTab.daysOfWeek.option4')}</Button>
                                            <Button name='Friday' variant="secondary" className={`${activeButtons.Friday === true ? " btnPrimaryGreen" : ""} makeitselect`}> {sdt('availbltyTab.daysOfWeek.option5')}</Button>
                                            <Button name='Saturday' variant="secondary" className={`${activeButtons.Saturday === true ? " btnPrimaryGreen" : ""} makeitselect`}> {sdt('availbltyTab.daysOfWeek.option6')}</Button>
                                            <Button name='Sunday' variant="secondary" className={`${activeButtons.Sunday === true ? " btnPrimaryGreen" : ""} makeitselect`}> {sdt('availbltyTab.daysOfWeek.option7')}</Button>
                                        </ButtonGroup>
                                    </div>
                                </Col>
                                : null}
                            <Col md={12} className="pt-3">
                                <Form.Group controlId="formBasicCheckbox">
                                    <Form.Label>Ends</Form.Label>
                                    <Form.Check type="checkbox" label="Never" checked={isNeverEnds} onClick={() => setisNeverEnds(!isNeverEnds)} />
                                </Form.Group>
                            </Col>
                            {isNeverEnds === false ?
                                <Col md={12}>
                                    <div className="check">
                                        <div className="d-flex align-items-center mb-2">
                                            <Form.Check
                                                inline
                                                label={t('availbltyTab.editRepeatMdl.afterLabel')}
                                                type={"radio"}
                                                name="apppoinmenttime"
                                                checked={IsAfterNoofAppointmentsOrAtTheDate === "afterNapppoinment" ? true : false}
                                                id={`inline-radio-3`}
                                                value="afterNapppoinment"
                                                onChange={(e) => setIsAfterNoofAppointmentsOrAtTheDate(e.target.value)}
                                            />
                                            <Form.Control style={{ minHeight: "32px" }} type="text" onClick={() => setIsAfterNoofAppointmentsOrAtTheDate("afterNapppoinment")} onChange={(e) => setAfterNoofAppointments(e.target.value)} value={AfterNoofAppointments} className="smallNumFormControlss me-2" /> {t('availbltyTab.editRepeatMdl.appointments')}

                                        </div>
                                        <Form.Group controlId="RepetitionShiftForm">
                                            <div className="d-flex align-items-center">
                                                <Form.Check
                                                    inline
                                                    label={t('availbltyTab.editRepeatMdl.atThe')}
                                                    name="apppoinmenttime"
                                                    checked={IsAfterNoofAppointmentsOrAtTheDate === "Atthedate" ? true : false}
                                                    type={"radio"}
                                                    id={`inline-radio-2`}
                                                    value="Atthedate"
                                                    onChange={(e) => setIsAfterNoofAppointmentsOrAtTheDate(e.target.value)}
                                                />
                                                <div>
                                                    <DatePicker selected={AtTheDate} onFocus={() => setIsAfterNoofAppointmentsOrAtTheDate("Atthedate")} onChange={(selected) => { setAtTheDate(selected) }} className="form-control" /></div>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </Col>
                                : null}
                            <Col md={12} className="">
                                <div className="SummaryText">{ct('summary')}: {summaryStart} {summary} {isNeverEnds === false ? IsAfterNoofAppointmentsOrAtTheDate === "Atthedate" ? !!AtTheDate ? ", till " + moment(AtTheDate).format("DD/MM/YYYY") : null : !!AfterNoofAppointments ? (", " + AfterNoofAppointments + " times") : "" : null}</div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.setShowEditShiftPopUp(false)}> {t('availbltyTab.editRepeatMdl.toShutdown')}</Button>
                    <Button className="btn btnPrimaryGreen" onClick={() => setConfimationShiftModal(true)}> {ct('toSave')}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}