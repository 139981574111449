import { useFormik } from "formik";
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FileBase64 from 'react-file-base64';
import { isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from "yup";
import { LanguageList } from '../../../CommonJSON';
import { AxiosLite, CallAxios } from '../../../Helpers';
import { SignInManager } from '../../../Helpers/Authentication/SignInManager';
import { config } from '../../../Helpers/Config';
import { InputTypeFileWrapper, RequiredText } from '../../../Helpers/Global/StyledComponents';
import { UpdateReduxStates } from '../../../Helpers/MasterData';
import { useTranslation } from "react-i18next";



function BaseDataTab() {
    const { t: Lst } = useTranslation('locationSetting');
    const { t: ct } = useTranslation('common');


    const [timezoneList, settimezoneList] = useState([]);
    const [LogoUrl, setLogoUrl] = useState(null);
    const dispatch = useDispatch();


    const Schema = Yup.object().shape({
        Surname: Yup.string().required(Lst('baseDataTab.errorMsg.name').toString()),
        phoneNumber: Yup.string().test("phoneNumber", Lst('baseDataTab.errorMsg.phoneNumber').toString(), (value) => {
            if (!!value) {
                return isValidPhoneNumber(value)
            } else {
                return true;
            }
        })
    });


    useEffect(() => {
        getData();
    }, []);
    const getData = async () => {

        var response = await AxiosLite("/api/LocationSetting/getBaseData|get");

        if (!!response && response.data) {

            settimezoneList(response.data.timeZoneList)
            setLogoUrl(response.data.logoUrl)
            formik.setFieldValue("Surname", response.data.locationName || "");
            formik.setFieldValue("language", response.data.language || "");
            formik.setFieldValue("timeZone", response.data.timeZone || "");
            formik.setFieldValue("phoneNumber", response.data.phone || "");
            formik.setFieldValue("emailSignature", response.data.emailSignature || "");
            formik.setFieldValue("clientNumber", response.data.clientNumber || "");
        }
    };

    const formik = useFormik({
        initialValues: {
            Surname: "",
            logo: "",
            phoneNumber: "",
            timeZone: "",
            language: "",
            clientNumber: "",
            emailSignature: "",
        },
        validationSchema: Schema,
        onSubmit: (values) => {
            var data = {
                locationName: values.Surname,
                language: values.language,
                timeZone: values.timeZone,
                logo: values.logo,
                phone: values.phoneNumber,
                emailSignature: values.emailSignature,
                clientNumber: values.clientNumber,
            };

            var form_data = new FormData();
            Object.keys(data).forEach((key, index) => {
                if (typeof (data[key]) === "object" && !!data[key] && data[key].length > 0) {
                    for (var i = 0; i < data[key].length; i++) {
                        form_data.append(key + [], data[key][i]);
                    }
                } else if (data[key]) {
                    form_data.append(key, data[key]);
                }
            })
            // var form_data = new FormData();
            // for (var key in data) {
            //     if (typeof (data[key]) === "object" && !!data[key] && data[key].length > 0) {
            //         for (var i = 0; i < data[key].length; i++) {
            //             form_data.append(key + [], data[key][i]);
            //         }
            //     } else {
            //         form_data.append(key, data[key]);
            //     }
            // }
            saveBaseData(form_data)
        },
    });
    const saveBaseData = async (form_data) => {
        var response = await CallAxios("/api/LocationSetting/SetBaseData|post", form_data);
        if (!!response && response.data === true) {
            getData();
            toast.success(Lst('baseDataTab.errorMsg.settingUpdate'))
            await UpdateReduxStates(new SignInManager().ParseJwt, dispatch)

        }
    }
    const getFiles = (files) => {

        formik.setFieldValue("logo", files.file);
    }


    return (
        <>
            <Row>
                <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label> {Lst('baseDataTab.name')}</Form.Label>
                        <Form.Control
                            onChange={formik.handleChange}
                            value={formik.values.Surname}
                            name="Surname"
                            type="text"
                            maxLength={150}
                        />
                        <RequiredText>
                            {formik.touched.Surname && formik.errors.Surname
                                ? formik.errors.Surname
                                : null}
                        </RequiredText>
                    </Form.Group>
                </Col>
                {
                    !!LogoUrl ?
                        <Col md={4}>
                            <img style={{ "height": "100px" }} src={config.S3BucketURL + LogoUrl}></img>
                        </Col>
                        : null
                }
                <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label> {Lst('baseDataTab.logo')}</Form.Label>
                        <InputTypeFileWrapper>

                            <FileBase64
                                id='uploadNoteFile'
                                multiple={false}
                                onDone={getFiles}
                                className='form-control'

                            />
                        </InputTypeFileWrapper>
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label> {Lst('baseDataTab.phoneNumber')}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.phoneNumber}
                            name="phoneNumber"
                        />
                        <RequiredText>
                            {formik.touched.phoneNumber && formik.errors.phoneNumber
                                ? formik.errors.phoneNumber
                                : null}
                        </RequiredText>
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label> {Lst('baseDataTab.timeZone')}</Form.Label>
                        <Form.Control
                            as="select"
                            className="SelectbgGrey"
                            onChange={formik.handleChange}
                            value={formik.values.timeZone}
                            name="timeZone"
                            custom
                        >
                            {!!timezoneList && timezoneList.length > 0 ? timezoneList.map((val, index) => {
                                return <option key={index} value={val.value}>
                                    {val.text}
                                </option>
                            })
                                : null
                            }
                        </Form.Control>
                        {/* <Form.Select disabled>
                            {TimeZoneList?.map(x =>
                                <option value={x.value}>{x.label}</option>
                            )}
                        </Form.Select> */}
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label> {Lst('baseDataTab.language')}</Form.Label>
                        {/* <Form.Select disabled>
                            {LanguageList?.map(x =>
                                <option value={x.value}>{x.label}</option>
                            )}
                        </Form.Select> */}
                        <Form.Control
                            as="select"
                            className="SelectbgGrey"
                            onChange={formik.handleChange}
                            value={formik.values.language}
                            name="language"
                            custom
                        >
                            {LanguageList?.map(x =>
                                <option value={x.value}>{x.label}</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label> {Lst('baseDataTab.clientNumber')}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.clientNumber}
                            name="clientNumber"
                            maxLength={150}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label> {Lst('baseDataTab.emailSignature')}</Form.Label>
                        <Form.Control
                            as="textarea"
                            onChange={formik.handleChange}
                            value={formik.values.emailSignature}
                            name="emailSignature"
                            rows={4}
                            maxLength={250}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <button className='btn themeGreenBtn btn-primary' onClick={(e) => formik.handleSubmit()}>{ct('save')}</button>
                </Col>
            </Row>
        </>
    );
}

export default BaseDataTab;