import Highcharts from 'highcharts';
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { CallAxios } from "../../Helpers";
import { FiltersDiv, RefreshButton } from "./ReportsIndex";

export default function BarHighChart(props) {
    const chartdiv = useRef();
    const [getFilters, setGetFilters] = useState(undefined);
    const [refreshDate, setrefreshDate] = useState(new Date());
    const [IsFilterChange, setIsFilterChange] = useState(false);
    const [IsInitialLoadComplete, setIsInitialLoadComplete] = useState(false);
    const [ChartData, setChartData] = useState([]);
    const [tickInterval, setTickInterval] = useState(1000 * 15 * 24);


    useEffect(() => {
        if (!!ChartData) {
            Highcharts.chart(chartdiv.current, {
                chart: {
                    type: 'column'
                },
                title: {
                    text: ''
                },
                subtitle: {
                    text: ''
                },
                xAxis: {
                    type: 'datetime',
                    labels: {
                        rotation: -45,
                        style: {
                            fontSize: '13px',
                            fontFamily: 'Verdana, sans-serif'
                        }
                    },
                    tickInterval: tickInterval,

                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'hours'
                    }
                },

                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    layout: 'horizontal',

                },
                tooltip: {
                    useHTML: true,
                    shared: true,
                    borderRadius: 15,
                    backgroundColor: "#fff",
                    borderColor: "#ccc",
                    shadow: false,

                    formatter: function () {
                        return `<div class=" text-center">${moment(this.x).format("DD/MM/YYYY HH:mm")}</div> 
                          <div class=" text-center" style="color:${this?.points[0].color}"> Target: ${this?.y}</div> 
                          `
                    }
                },
                series: ChartData,
                responsive: {
                    rules: [
                        {
                            condition: {
                                maxWidth: 500
                            },
                            chartOptions: {
                                legend: {
                                    layout: 'horizontal',
                                    align: 'center',
                                    verticalAlign: 'bottom'
                                }
                            }
                        }
                    ]
                }
            });
        }
    }, [ChartData]);

    useEffect(() => {
        if (IsInitialLoadComplete) {
            setIsFilterChange(true)
            if (props.Filters.length > 0 && props.Filters?.some(x => x.ReportId === props.chartid)) {
                let prevstate = [...props.Filters];
                let findObj = prevstate.find(x => x.ReportId === props.chartid);
                findObj.title = getFilters.title;
                findObj.metricCheckBox = getFilters.metricCheckBox;
                findObj.areas = getFilters.areas;
                findObj.employees = getFilters.employees;
                findObj.tags = getFilters.tags;
                findObj.absenceType = getFilters.absenceType;
                findObj.lastPeriod = getFilters.lastPeriod;
                findObj.groupingType = getFilters.groupingType;
                findObj.graphType = getFilters.graphType;
                findObj.interval = getFilters.interval;
                findObj.budget = getFilters.budget;
                findObj.startDate = getFilters.startDate;
                findObj.endDate = getFilters.endDate;

                let index = prevstate.findIndex(x => x.ReportId === props.chartid);
                prevstate[index] = findObj;
                props.setFilters(prevstate);

            } else {
                getFilters.ReportId = props.chartid
                props.setFilters([...props.Filters, getFilters]);
            }
        }
    }, [getFilters]);
    useEffect(() => {
        if (IsInitialLoadComplete) {
            getChartData();
        }
    }, [IsInitialLoadComplete]);
    const getChartData = async () => {

        getFilters.interval === "15m" && setTickInterval(1000 * 15 * 24)
        getFilters.interval === "30m" && setTickInterval(1000 * 30 * 24)
        getFilters.interval === "Hour" && setTickInterval(1000 * 60 * 24)
        getFilters.interval === "day" && setTickInterval(1000 * 3600 * 24)
        getFilters.interval === "week" && setTickInterval(1000 * 25200 * 24)
        getFilters.interval === "month" && setTickInterval(1000 * 100000 * 24)
        let data = {};
        data = {
            ...getFilters,
            startDate: !!getFilters.startDate ? moment(parseFloat(getFilters.startDate)).startOf("day") : null,
            endDate: !!getFilters.endDate ? moment(parseFloat(getFilters.endDate)).endOf("day") : null
        }
        setIsFilterChange(false)

        var response = await CallAxios(
            "/api/Reports/GetChartData|post", data
        );
        if (!!response && response.status === 200) {

            if (response.data.targetHoursList.length > 0) {
                response.data.targetHoursList.map((v, k) => {
                    var targetlist = []
                    v.data.map((val, key) => {
                        targetlist.push({ x: new Date(val.x).getTime(), y: val.y })
                    })
                    v.data = targetlist;
                })
            }

            setChartData(response.data.targetHoursList)
            setrefreshDate(new Date)
        }
    }
    return (
        <>
            {/* <FiltersDiv onChange={selected => setGetFilters(selected)} /> */}
            <FiltersDiv chartid={props.chartid} setIsInitialLoadComplete={setIsInitialLoadComplete} onChange={selected => setGetFilters(selected)} />

            <div ref={chartdiv} style={{ height: "400px" }}></div>
            {/* {showRefreshBtn &&   <RefreshButton onclick={()=> setShowRefreshBtn(false)} /> } */}
            <RefreshButton IsFilterChange={IsFilterChange} refreshDate={refreshDate} getChartData={getChartData} />


        </>
    );
}

