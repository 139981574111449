import React, { useState } from 'react';
import { ContextMenu, ContextMenuTrigger, MenuItem } from "react-contextmenu";
import { FcFile, FcFolder, FcOpenedFolder } from 'react-icons/fc';
import { config } from '../Helpers/Config';



function collect(props) {
    return props;
}

const TreeNode = ({ obj, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        if (children.length !== 0) {
            setIsOpen(!isOpen);
        }
        if (obj.isFile) {
            window.open(config.S3BucketURL + obj.bucketKey)
        }
    };

    return (
        <li>
            <span style={{ cursor: 'pointer' }} onClick={() => handleToggle()}>
                {
                    isOpen ?
                        <>
                            {
                                (!!obj.files && obj.files.length > 0) || obj.children.length > 0 || obj.isFile === false ?
                                    <>
                                        <FcOpenedFolder />
                                    </>
                                    :
                                    null
                            }
                        </>
                        :
                        <>
                            {
                                (!!obj.files && obj.files.length > 0) || obj.children.length > 0 || obj.isFile === false ?
                                    <>
                                        <FcFolder />
                                    </>
                                    :
                                    null
                            }
                        </>
                }
                {
                    !!obj.bucketKey && <FcFile />
                }
                {

                    <ContextMenuTrigger Node={obj} collect={collect} id="same_unique_identifier">
                        <span>{obj.label}</span>
                    </ContextMenuTrigger>

                }
            </span>
            {isOpen && <ul>{children}</ul>}
        </li>
    );
};

const renderTreeNodes = (data, handleTreeActions) => {
    return data.map((node) => (
        node.isvisible &&
        <>
            <TreeNode key={node.id} obj={node}>
                {node.children && renderTreeNodes(node.children, handleTreeActions)}
                {node.files && renderTreeNodes(node.files, handleTreeActions)}
            </TreeNode>
        </>
    ));
};

function TreeViewProfile(props) {
    const [showUpload, setShowUpload] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showView, setShowView] = useState(false);
    const [showMoveToArchive, setShowMoveToArchive] = useState(false);
    const [showMoveBackFromArchive, setShowMoveBackFromArchive] = useState(false);

    const treeNodes = renderTreeNodes(props.jsonData, props.handleTreeActions);
    const onSHowf = (e) => {
        setShowView(e.detail.data.Node.canRead);
        setShowDelete(e.detail.data.Node.canDelete);
        setShowEdit(e.detail.data.Node.canModify);
        setShowUpload(e.detail.data.Node.canWrite);
        setShowMoveToArchive(e.detail.data.Node.bucketKey && e.detail.data.Node.parentFolderName !== "Archive" ? true : false);
        setShowMoveBackFromArchive(e.detail.data.Node.bucketKey && e.detail.data.Node.parentFolderName === "Archive" ? true : false);
    }
    return (
        <>
            <ul className='tree'>
                {treeNodes}
            </ul>
            <ContextMenu id="same_unique_identifier" onShow={onSHowf}>
                {
                    showMoveBackFromArchive &&
                    <MenuItem data={{ action: 'MoveBackFromArchive', list: props.jsonData }} onClick={props.handleTreeActions}>
                        Move Back
                    </MenuItem>
                }
                {
                    showMoveToArchive &&
                    <MenuItem data={{ action: 'Movetoarchive', list: props.jsonData }} onClick={props.handleTreeActions}>
                        Move to archive
                    </MenuItem>
                }
                {
                    showUpload &&
                    <MenuItem data={{ action: 'Upload' }} onClick={props.handleTreeActions}>
                        Upload
                    </MenuItem>
                }
                {
                    showView &&
                    <MenuItem data={{ action: 'View' }} onClick={props.handleTreeActions}>
                        View
                    </MenuItem>
                }
                {
                    showEdit &&
                    <MenuItem data={{ action: 'Edit' }} onClick={props.handleTreeActions}>
                        Edit
                    </MenuItem>
                }
                {
                    showDelete &&
                    <MenuItem data={{ action: 'Delete' }} onClick={props.handleTreeActions}>
                        Delete
                    </MenuItem>
                }
            </ContextMenu>
        </>
    );
}

export default TreeViewProfile;