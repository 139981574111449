import { useFormik } from "formik";
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useDispatch } from "react-redux";
import { CallAxios } from "../../../Helpers";
import { SignInManager } from "../../../Helpers/Authentication/SignInManager";
import { UpdateReduxStates } from "../../../Helpers/MasterData";
import { useTranslation } from "react-i18next";


function Display(props) {
    const { t: Lst } = useTranslation('locationSetting');
    const { t: ct } = useTranslation('common');


    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            Employees_can_see_their_colleagues: false,
            The_status_and_type_of_absence_are_not_visible_to_other_employees: false,
            Confirm_absences_created_by_administrators: false,
            Confirm_absences_created_by_employees_with_creation: false,
            public_holiday_is_created_changed_or_deleted: false,

        },
        onSubmit: values => {
            submit(values)
        },
    });

    useEffect(() => {
        if (!!props.displayData) {
            formik.setFieldValue("Employees_can_see_their_colleagues", props.displayData.absenceSetting.employees_can_see_their_colleagues)
            formik.setFieldValue("The_status_and_type_of_absence_are_not_visible_to_other_employees", props.displayData.absenceSetting.the_status_and_type_of_absence_are_not_visible_to_other_employees)
            formik.setFieldValue("Confirm_absences_created_by_administrators", props.displayData.absenceSetting.confirm_absences_created_by_administrators)
            formik.setFieldValue("Confirm_absences_created_by_employees_with_creation", props.displayData.absenceSetting.confirm_absences_created_by_employees_with_creation)
            formik.setFieldValue("public_holiday_is_created_changed_or_deleted", props.displayData.absenceSetting.public_holiday_is_created_changed_or_deleted)
        }
    }, [props.displayData]);
    const submit = async (values) => {
        var data = {
            Employees_can_see_their_colleagues: values.Employees_can_see_their_colleagues,
            The_status_and_type_of_absence_are_not_visible_to_other_employees: values.The_status_and_type_of_absence_are_not_visible_to_other_employees,
            Confirm_absences_created_by_administrators: values.Confirm_absences_created_by_administrators,
            Confirm_absences_created_by_employees_with_creation: values.Confirm_absences_created_by_employees_with_creation,
            public_holiday_is_created_changed_or_deleted: values.public_holiday_is_created_changed_or_deleted,
        };
        await CallAxios("/api/LocationSetting/editAbsenceSetting|post", data);
        await UpdateReduxStates(new SignInManager().ParseJwt, dispatch)

    };

    return (
        <>
            <Container fluid className="mb-3">
                <Row>
                    <Col md={12}>
                        <div className="TabFormContainerSection">
                            <h5 className='text-left fw-bold  mb-3'> {Lst('absenceTab.display')}</h5>
                            <div className='showCheckboxes mb-4'>
                                <Form.Group className="mb-1" controlId={"Employees_can_see_their_colleagues"}>

                                    <Form.Check className="text-left fw-bold"
                                        name={"Employees_can_see_their_colleagues"} onChange={formik.handleChange} checked={formik.values.Employees_can_see_their_colleagues} type="checkbox" label={Lst('absenceTab.employeesLabel')} />
                                    {formik.values.Employees_can_see_their_colleagues === true ?
                                        <Form.Group className="mb-1" controlId={"The_status_and_type_of_absence_are_not_visible_to_other_employees"}>
                                            <Form.Check className="text-left ms-4 mt-2 fw-bold"
                                                name={"The_status_and_type_of_absence_are_not_visible_to_other_employees"} type="checkbox" onChange={formik.handleChange} checked={formik.values.The_status_and_type_of_absence_are_not_visible_to_other_employees} label={Lst('absenceTab.otherEmployeesLabel2')} />  </Form.Group>
                                        : null}

                                </Form.Group>
                            </div>
                            <h5 className='text-left fw-bold mb-3'> {Lst('absenceTab.confirmAutomatically')}</h5>
                            <div className='showCheckboxes mb-4'>
                                <Form.Group className="mb-1" controlId={"Confirm_absences_created_by_administrators"}>

                                    <Form.Check className="text-left fw-bold"
                                        name={"Confirm_absences_created_by_administrators"} onChange={formik.handleChange} checked={formik.values.Confirm_absences_created_by_administrators} type="checkbox" label={Lst('absenceTab.confirmLabel1')} />

                                </Form.Group>

                                <Form.Group className="mb-1" controlId={"Confirm_absences_created_by_employees_with_creation"}>
                                    <Form.Check className="text-left mt-2 fw-bold"
                                        name={"Confirm_absences_created_by_employees_with_creation"} onChange={formik.handleChange} checked={formik.values.Confirm_absences_created_by_employees_with_creation} type="checkbox" label={Lst('absenceTab.confirmLabel2')} />
                                </Form.Group>

                            </div>
                            <h5 className='text-left fw-bold mb-3'> {Lst('absenceTab.publicHolidays')}</h5>
                            <div className='showCheckboxes mb-4'>
                                <Form.Group className="mb-1" controlId={"public_holiday_is_created_changed_or_deleted"}>

                                    <Form.Check className="text-left fw-bold"
                                        name={"public_holiday_is_created_changed_or_deleted"} onChange={formik.handleChange} checked={formik.values.public_holiday_is_created_changed_or_deleted} type="checkbox" label={Lst('absenceTab.publicHolidaysLabel')} />


                                </Form.Group>
                            </div>
                            <div className='text-left pt-4'>
                                <button type="button" className="btn btnPrimaryGreen btn-primary" onClick={(e) => formik.handleSubmit()} > {ct('ready')}</button>

                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Display;