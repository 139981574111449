import moment from "moment";
import { useState } from "react";
import { Button, Col, Dropdown, Modal, Row } from "react-bootstrap";
import { CallAxios } from "../../../Helpers";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";


export default function CopyTemplateModal(props) {
    const { t: cl } = useTranslation('calendarPage');
    const { t: ct } = useTranslation('common');



    const [selectedvalue, setSelectedvalue] = useState('');
    const [ConfirmationModel, setConfirmationModel] = useState(false);
    useEffect(() => {
        firstValue();
    }, []);

    const createPreviosDates = () => {
        let arr = [];
        for (let i = 0; i <= 56; i++) {
            let values = getWeeksValues(i);
            arr.push(<Dropdown.Item onClick={() => selectOnClick(`${values.weekno} ${moment(values.startOfWeek).format("MM/DD/YY")} - ${moment(values.endOfWeek).format("MM/DD/YY")}`)}>{values.weekno} ({moment(values.startOfWeek).format("MM/DD/YY")} - {moment(values.endOfWeek).format("MM/DD/YY")})</Dropdown.Item>);
        }
        return arr;
    }
    const getWeeksValues = (i) => {
        let startOfWeek = moment().subtract(i, 'weeks').startOf('isoWeek').toDate();
        let endOfWeek = moment().subtract(i, 'weeks').endOf('isoWeek').toDate();
        let weekno = moment(startOfWeek).format("[CW:] W-YYYY");
        return { startOfWeek, endOfWeek, weekno }
    }
    const firstValue = () => {
        let values = getWeeksValues(0);
        setSelectedvalue(`${values.weekno} ${moment(values.startOfWeek).format("MM/DD/YY")} - ${moment(values.endOfWeek).format("MM/DD/YY")}`)
    }
    const selectOnClick = (value) => {
        setSelectedvalue(value)
    }
    const Submit = async () => {
        var data = {
            TobeCopiedWeekNo: parseInt(selectedvalue.split(" ")[1].split("-")[0]),
            TobeCopiedYear: parseInt(selectedvalue.split(" ")[1].split("-")[1]),
            PasteOnWeekNo: parseInt(props.CalendarweekNum),
            PasteOnYear: parseInt(props.calendar.current.activeRange.start.getFullYear())
        }
        var response = await CallAxios("/api/Calendar/CopyTemplate|post", data);
        if (!!response && response.status === 200) {
            props.GetEmployeeList()
            props.UpdateShiftChildTrigger()
            setConfirmationModel(false)
            props.onHide(false)
        }
    }
    return (
        <>
            {
                ConfirmationModel === true ?
                    <Modal show={ConfirmationModel} onHide={() => setConfirmationModel(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>{cl("confirmCopying")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{cl("copyCalenderWeek")} {selectedvalue.split(" ")[1]} {cl("currentCalenderWeek")}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="btn btnPrimaryGreen" onClick={() => setConfirmationModel(false)}>{ct("no")}</Button>
                            <Button className="btn btnPrimaryGreen" onClick={() => Submit()}>{ct("finished")}</Button>
                        </Modal.Footer>
                    </Modal>
                    : null
            }
            <Modal show={props.show} onHide={() => props.onHide(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{cl("templateWeek")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={8}>
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic" className="btn-block btnPrimaryGreen">
                                    {selectedvalue}
                                </Dropdown.Toggle>
                                <Dropdown.Menu style={{ maxHeight: "350px", overflow: "auto" }}>
                                    {createPreviosDates()}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        <Col md={4}>
                            <Button className="btn btnPrimaryGreen btn-block" onClick={() => setConfirmationModel(true)}>{ct("copy")}</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
}