import { saveAs } from 'file-saver';
import moment from "moment";
import { useEffect, useState } from "react";
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import { FaTimes, FaUndo } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { CallAxios, CallAxiosBlob } from "../../Helpers";
import { Constants } from "../../Helpers/Contents";
import { TableWrapper } from "../../Helpers/Global/StyledComponents";
import { useTranslation } from "react-i18next";

function Assignments() {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');


    const { userid } = useParams();
    const user = useSelector(x => x.app.user)
    const appSettings = useSelector(x => x.app.appSettings)

    const [StartPeriod, setStartPeriod] = useState(moment().startOf('month')._d);
    const [EndPeriod, setEndPeriod] = useState(moment().endOf('month')._d);
    const [assignmentList, setAssignmentList] = useState([]);

    useEffect(() => {
        fnUpdatetable()
    }, []);

    const fnUpdatetable = async () => {
        var data = {
            Satrtdate: StartPeriod,
            Enddate: EndPeriod,
            empid: userid.toString()
        }
        var response = await CallAxios(
            "/api/Calendar/GetempAssignemnets|post", data
        );
        if (!!response && response.status === 200) {
            setAssignmentList(response.data)
        }
    }
    const handleassignment = async (id) => {
        var response = await CallAxios(
            "/api/Assignment/offerShift|post", { id: id }
        );
        if (!!response && response.data.success) {
            fnUpdatetable()
        } else {
            toast.error(response.data.message)
        }
    }
    const ExportData = async () => {
        var data = {
            Satrtdate: StartPeriod,
            Enddate: EndPeriod,
            empid: userid.toString()
        }
        var response = await CallAxiosBlob(
            "/api/Calendar/ExportAssignments|post", data
        );
        if (!!response && response.status === 200) {
            saveAs(response.data, "Assignment.pdf")
        }
    }
    const SendAssignement = async () => {
        var data = {
            starDate: StartPeriod,
            endDate: EndPeriod,
            empId: userid
        }
        var response = await CallAxios("/api/Calendar/SendShiftsToEmployee|post", data)
        if (!!response && response.status === 200) {
            if (response.data === "Noemail") {
                toast.info(t('assignmentTab.errorMsg.noemail').toString())
            }
            if (response.data === true) {
                toast.success(t('assignmentTab.errorMsg.emailSent').toString())
            }
            if (response.data === "norecord") {
                toast.info(t('assignmentTab.errorMsg.noRecord').toString())
            }
        }
    }

    return <>
        <div className="DetailInfoWrapper">
            <div className='mb-3 d-flex align-items-center justify-content-between'>
                <h4 className='h5 fw-bold mb-0'> {t('assignments')}</h4>

            </div>
            <div className='d-flex mb-3 justify-content-between align-items-center'>
                <div className='d-flex'>
                    <div className='me-2'>
                        <DatePicker className="form-control" selected={StartPeriod} onChange={(date) => setStartPeriod(date)} />
                    </div>
                    <div className='me-2'>
                        <DatePicker className="form-control" selected={EndPeriod} onChange={(date) => setEndPeriod(date)} />
                    </div>

                    <button className='btn btn-primary btn-sm me-3 btn-sm ' onClick={() => fnUpdatetable()}> {ct('show')} </button>

                </div>
                <div>
                    {
                        user.roles.length === 1 && user.roles.some(x => x === Constants.roles.Employee) ?
                            null :
                            <>
                                <button className='btn btn-primary btn-sm me-2' onClick={() => ExportData()} > {ct('export')} </button>
                                <button className='btn btn-primary btn-sm' onClick={() => SendAssignement()}> {t('assignmentTab.asEmailEmployees')}</button>
                            </>
                    }
                </div>
            </div>
            <TableWrapper>
                <Table striped bordered responsive="sm">
                    <thead>
                        <tr className='firsthead'>
                            <th> {t('assignmentTab.myassignments')}</th>
                            <th> {t('assignmentTab.workingArea')}</th>
                            <th> {ct('tags')}</th>
                            <th>{ct('note')}</th>
                            {
                                appSettings.locationsettings.ProhibitShiftSwaps == false ?
                                    <th>{ct('actions')}</th>
                                    : null
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !!assignmentList && assignmentList.length > 0 ? assignmentList.map((value, key) => {

                                return <tr>
                                    <td>{value.datetime}</td>
                                    <td>{value.workArea}</td>
                                    <td>{value.tags}</td>
                                    <td>{value.note}</td>
                                    <td>
                                        {
                                            appSettings.locationsettings.ProhibitShiftSwaps == false && value.canoffered === true ?
                                                value.isoffered === true ?
                                                    // <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={(props) => (<Tooltip {...props}>Revert shift trade</Tooltip>)}>
                                                    <a data-tooltip-id="my-tooltip" data-tooltip-content={ct('revertShiftTradeTooltip')} className="btnTblAction bgRed" onClick={(e) => handleassignment(value.id)}><FaTimes /></a>
                                                    // </OverlayTrigger>
                                                    :
                                                    // <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={(props) => (<Tooltip {...props}>Offer shift</Tooltip>)}>
                                                    <a data-tooltip-id="my-tooltip" data-tooltip-content={ct('offerShiftTooltip')} className="btnTblAction bgYellow" onClick={(e) => handleassignment(value.id)}><FaUndo /></a>
                                                // </OverlayTrigger>
                                                : null
                                        }
                                    </td>
                                </tr>
                            })
                                : null
                        }

                    </tbody>
                </Table>
            </TableWrapper>
        </div>
    </>
}

export default Assignments;

