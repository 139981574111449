import { useFormik } from "formik";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import CollapseableDiv from "../../../Components/CollapseCard";
import { CallAxios } from "../../../Helpers";
import { SignInManager } from "../../../Helpers/Authentication/SignInManager";
import { UpdateReduxStates } from "../../../Helpers/MasterData";
import { useTranslation } from "react-i18next";

function ApplicationsAndAssignments(props) {
    const { t: Lst } = useTranslation('locationSetting');
    const { t: ct } = useTranslation('common');


    const dispatch = useDispatch();
    const CHECKBOXLIST = [
        { name: "applyForFullShifts", label: Lst('planTab.appAssignAccor.labelList1').toString() },
        { name: "allowedFullShifts", label: Lst('planTab.appAssignAccor.labelList2').toString() },
        { name: "prohibitApplications", label: Lst('planTab.appAssignAccor.labelList3').toString() },
        { name: "seeApplicationsFromOtherEmployees", label: Lst('planTab.appAssignAccor.labelList4').toString() },
        { name: "hideBookingsInCalendar", label: Lst('planTab.appAssignAccor.labelList5').toString() },
        { name: "prohibitShiftSwaps", label: Lst('planTab.appAssignAccor.labelList6').toString() },
        { name: "viewApplicationsInResourceView", label: Lst('planTab.appAssignAccor.labelList7').toString() }]


    const formik = useFormik({
        initialValues: {
            allowedFullShifts: false,
            applyForFullShifts: false,
            assignAutomatically_IgnoreTargetHourLimit: false,
            hideBookingsInCalendar: false,
            prohibitApplications: false,
            prohibitShiftSwaps: false,
            alwaysConsiderApplication: false,
            statutoryRestPeriods: false,
            consider_hours_between_bookings: ""

        },
        onSubmit: values => {
            submit(values)
        },
    });
    const submit = async (values) => {
        if (values.statutoryRestPeriods && values.statutoryRestPeriodsHours === "") {
            alert(Lst('planTab.appAssignAccor.restPeriodNumberError'))
            return;
        }
        var data = {
            allowedFullShifts: values.allowedFullShifts,
            alwaysConsiderApplication: values.alwaysConsiderApplication,
            applyForFullShifts: values.applyForFullShifts,
            assignAutomatically_IgnoreTargetHourLimit: values.assignAutomatically_IgnoreTargetHourLimit,
            hideBookingsInCalendar: values.hideBookingsInCalendar,
            prohibitApplications: values.prohibitApplications,
            prohibitShiftSwaps: values.prohibitShiftSwaps,
            seeApplicationsFromOtherEmployees: values.seeApplicationsFromOtherEmployees,
            statutoryRestPeriods: values.statutoryRestPeriods,
            statutoryRestPeriodsHours: parseFloat(values.statutoryRestPeriodsHours),
            viewApplicationsInResourceView: values.viewApplicationsInResourceView,

        };
        var response = await CallAxios(
            "/api/LocationSetting/ApplicationsAssignmentsSetting|post", data
        );
        await UpdateReduxStates(new SignInManager().ParseJwt, dispatch)

    };
    useEffect(() => {
        formik.setFieldValue("allowedFullShifts", props.ApplicationsandAssignments.allowedFullShifts);
        formik.setFieldValue("alwaysConsiderApplication", props.ApplicationsandAssignments.alwaysConsiderApplication);
        formik.setFieldValue("applyForFullShifts", props.ApplicationsandAssignments.applyForFullShifts);
        formik.setFieldValue("assignAutomatically_IgnoreTargetHourLimit", props.ApplicationsandAssignments.assignAutomatically_IgnoreTargetHourLimit);
        formik.setFieldValue("hideBookingsInCalendar", props.ApplicationsandAssignments.hideBookingsInCalendar);
        formik.setFieldValue("prohibitApplications", props.ApplicationsandAssignments.prohibitApplications);
        formik.setFieldValue("prohibitShiftSwaps", props.ApplicationsandAssignments.prohibitShiftSwaps);
        formik.setFieldValue("seeApplicationsFromOtherEmployees", props.ApplicationsandAssignments.seeApplicationsFromOtherEmployees);
        formik.setFieldValue("statutoryRestPeriods", props.ApplicationsandAssignments.statutoryRestPeriods);
        formik.setFieldValue("statutoryRestPeriodsHours", props.ApplicationsandAssignments.statutoryRestPeriodsHours);
        formik.setFieldValue("viewApplicationsInResourceView", props.ApplicationsandAssignments.viewApplicationsInResourceView);
    }, [props.ApplicationsandAssignments]);
    return (
        <>
            <CollapseableDiv title={Lst('planTab.appAssignAccor.applicationsAssignments')}>
                <div className='py-2'>
                    {CHECKBOXLIST.map((val, index) =>
                        <Form.Group className="formBasicCheckbox mb-2" key={"formBasicCheckbox" + index} controlId={"formBasicCheckbox" + index}>
                            <Form.Check className="text-left" checked={formik.values[val.name]} onChange={formik.handleChange} name={val.name}
                                type="checkbox" label={val.label} />
                        </Form.Group>
                    )}
                    <div className="form-check text-left">
                        <input className="form-check-input " type="checkbox" checked={formik.values.statutoryRestPeriods} onChange={formik.handleChange} name="statutoryRestPeriods" value="" id="statutoryRestPeriods" />
                        <label className="form-check-label" htmlFor="FormWithInput">
                            {Lst('planTab.appAssignAccor.restPeriodsLabel')}
                            <input type="text" onChange={formik.handleChange} maxLength={50} name="statutoryRestPeriodsHours" value={formik.values.statutoryRestPeriodsHours} className="form-control form-control-sm d-inline-block text-center mx-2" style={{ width: "10%", minHeight: "15px" }} disabled={!formik.values.statutoryRestPeriods} />
                            {Lst('planTab.appAssignAccor.hourBookingLabel')}

                        </label>
                    </div>
                    <div className='mt-4 mb-5'>
                        <Form.Group className="formBasicCheckbox mb-2" controlId={"alwaysConsiderApplication"}>
                            <Form.Check className="text-left" onChange={formik.handleChange} name={"alwaysConsiderApplication"} checked={formik.values.alwaysConsiderApplication}
                                type="checkbox" label={Lst('planTab.appAssignAccor.alwaysAppLabel')} />
                        </Form.Group>
                        <Form.Group className="formBasicCheckbox mb-2" controlId={"assignAutomatically_IgnoreTargetHourLimit"}>
                            <Form.Check className="text-left" onChange={formik.handleChange} name={"assignAutomatically_IgnoreTargetHourLimit"}
                                type="checkbox" label={Lst('planTab.appAssignAccor.assignLimitLabel')} checked={formik.values.assignAutomatically_IgnoreTargetHourLimit} />
                        </Form.Group>
                    </div>
                    {/* {checkboxList?.map((x, i) =>
                        <Form.Check
                            type={'checkbox'}
                            id={"t_" + i}
                            label={x.label !== "Statutory rest periods from" ? x.label : <HoursApplication label={x.label} checked={x.checked} />}
                            checked={x.checked}
                        />
                    )} */}
                </div>
                <button className='btn themeGreenBtn btn-primary' onClick={(e) => formik.handleSubmit()} > {ct('ready')}</button>
            </CollapseableDiv>
        </>
    )
}

export default ApplicationsAndAssignments;
