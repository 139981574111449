import { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { BsFillGearFill } from "react-icons/bs";
import { FaRegEdit, FaTrash } from "react-icons/fa";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { useSelector } from "react-redux";
import CreatableSelect from 'react-select/creatable';
import InputImoji from "../../../Components/inputImoji";
import { CallAxios } from "../../../Helpers";
import { useFormik } from "formik";
import { Col, Row } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import { toast } from "react-toastify";
import { Edit, Gear, Remove } from "../../../Components/ActionBtns";
import { TableWrapper } from "../../../Helpers/Global/StyledComponents";
import { AddEditTargetHourPeriodModal } from "../../Profile/TargetHours";
import { useTranslation } from "react-i18next";

export default function EmployeeSettingModal(props) {
    const { t: cl } = useTranslation('calendarPage');
    const { t: ct } = useTranslation('common');
    const { t: ept } = useTranslation('employeePage');

    const [editName, setEditName] = useState(false);
    const [editWorkingAreas, setEditWorkingAreas] = useState(false);
    const [TargetHourList, setTargetHourList] = useState([]);
    const [idforEdit, setIdforEdit] = useState("");
    const [editshow, seteditshow] = useState(false);
    const [empname, setEmpname] = useState("");
    const [workspaceList, setWorkspaceList] = useState([]);
    const [tagsDD, settagsDD] = useState([]);
    const [Tags, setTags] = useState([]);
    const [defaultSelectedTags, setdefaultSelectedTags] = useState([]);
    const [EmpworkspaceList, setEmpworkspaceList] = useState([]);
    const [NewSelectedtags, setNewSelectedtags] = useState();
    const appSettings = useSelector(x => x.app.appSettings)
    const themeColor = useSelector(state => state.app.theme.colors.buttons);

    useEffect(() => {
        if (!!props.EmpIdForEmployeeSetting) {
            Promise.all([
                getworkspaces(),
                getTagsDD(),
                getEmpTargetHours(),
                getEmpWorkspaces(),
                getEmpTags(),
                setEmpname(props.EmpNameForEmployeeSetting)
            ])
        }
    }, [props.EmpIdForEmployeeSetting]);
    useEffect(() => {
        if (tagsDD.length > 0 && Tags.length > 0) {
            let arr = [];
            for (let x = 0; x < Tags.length; x++) {
                const element = Tags[x];
                let result = tagsDD.find(i => i.value === element);
                if (!!result) {
                    arr.push(result);
                }
            }
            if (arr.length > 0) {
                setdefaultSelectedTags(arr);
            }
        }
    }, [tagsDD, Tags]);
    const getTagsDD = async () => {
        settagsDD(appSettings.tags.filter(x => x.value !== "Withouttags"))

        // var responseTags = await AxiosLite(
        //     "/api/LocationSetting/getTagsDD|get"
        // );
        // if (!!responseTags && responseTags.data.length > 0) {

        //     let changedata = responseTags.data.map(x => {
        //         return {
        //             value: x.key,
        //             label: x.label
        //         }
        //     })
        //     settagsDD(changedata)
        // }
    }
    const getworkspaces = async () => {
        setWorkspaceList(appSettings.WorkSpaces);
        // setselectedEmpworkspaceList(appSettings.WorkSpaces.map(x => x.key))
        // var response = await dynamicGetApi(
        //     "/api/LocationSetting/getWorkSpaceDD"
        // );
        // if (!!response && response.data.length > 0) {
        //     setWorkspaceList(response.data);
        //     setselectedEmpworkspaceList(response.data.map(x => x.key))

        // }
    }
    const getEmpTargetHours = async () => {
        var response = await CallAxios("/api/TargetHours/EmptargetHoursList|post", { id: props.EmpIdForEmployeeSetting });
        if (!!response && response.status === 200) {
            setTargetHourList(response.data.targetHourList)
        }
    }
    const getEmpTags = async () => {
        var response = await CallAxios('/api/Employee/getEmployeeTags|post', { id: props.EmpIdForEmployeeSetting })
        if (!!response && response.data.length > 0) {
            setTags(response.data)
        }
    }
    const getEmpWorkspaces = async () => {
        var response = await CallAxios('/api/Employee/getEmployeeWorkAreas|post', { id: props.EmpIdForEmployeeSetting })
        if (!!response && response.data.length > 0) {
            setEmpworkspaceList(response.data);
        }
    }

    const editobj = async (id) => {
        setIdforEdit(id)
        seteditshow(true)
    }
    const deleteobj = async (id) => {
        var response = await CallAxios("/api/TargetHours/deletetargetHours|post", { id: id });
        if (!!response && response.status === 200) {
            getEmpTargetHours()
        }
    }
    const OnchageValue = (e) => {
        setEmpworkspaceList(e)
    }
    const savedata = async () => {
        var data = {
            EmpID: props.EmpIdForEmployeeSetting,
            name: empname,
            workingareas: EmpworkspaceList,
            Tags: NewSelectedtags
        }
        var response = await CallAxios("/api/Calendar/UpdateEmployeeSetting|post", data);
        if (!!response && response.status === 200) {
            props.GetEmployeeList()
            props.setShow(false)
        }
    }
    const DropdownIndicator = () => null;
    const getdata = () => {
        getEmpTargetHours()
        props.GetEmployeeList()
    };
    return (
        <>
            {/* {
                editshow === true ?
                    <EditTargetHourPeriodModal getEmpTargetHours={getEmpTargetHours} setTargetHourList={setTargetHourList} GetEmployeeList={props.GetEmployeeList} idforEdit={idforEdit} empid={props.EmpIdForEmployeeSetting} show={editshow} setshow={seteditshow} />
                    : null
            } */}
            {
                editshow === true ?
                    <AddEditTargetHourPeriodModal getdata={getdata} idforEdit={idforEdit} userid={props.EmpIdForEmployeeSetting} show={editshow} setshow={seteditshow} />
                    : null
            }
            <Modal size='lg' show={props.show} onHide={() => { props.setShow(false) }}>
                <Modal.Header closeButton >
                    <Modal.Title> {cl("employeeSettingsMdl.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Form.Group className="position-relative mb-3">
                            <div className='d-flex '>
                                <label className='fw-bold me-3'>Name</label>
                                {
                                    editName === false ?
                                        <>
                                            <span className='me-3'>{empname}</span>
                                            <Edit color={themeColor} onClick={() => setEditName(true)} className="" />
                                        </>
                                        : null
                                }
                            </div>
                            {
                                editName === true ?
                                    <div className="position-relative">
                                        <InputImoji placeholder={ept("newColleague")} value={empname} onChange={v => setEmpname(v)} />
                                    </div>
                                    : null
                            }
                        </Form.Group>
                    </div>
                    <div>
                        <Form.Group className="position-relative mb-3">
                            <label className='fw-bold'> {ct("workingAreas")}s</label>
                            {editWorkingAreas === false ?
                                <div>
                                    <span className='me-3'>
                                        {
                                            workspaceList.filter(x => EmpworkspaceList.some(c => x.value === c)).map(x => x.label).join(",")
                                        }
                                    </span>
                                    <Edit color={themeColor} onClick={() => setEditWorkingAreas(true)} className=" hoverCursor" />
                                </div>
                                : null}
                            {
                                editWorkingAreas === true ?
                                    <DropdownMultiselect
                                        optionKey="value"
                                        options={workspaceList}
                                        name="workspaceList"
                                        selected={EmpworkspaceList}
                                        handleOnChange={(selected) => OnchageValue(selected)}
                                    />
                                    : null
                            }
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="NewShiftForm.txtEditTags">
                            <Form.Label> {ct("tags")}</Form.Label>
                            <CreatableSelect
                                classNamePrefix="MyReactSelect"
                                isMulti={true}
                                isClearable={false}
                                components={{ DropdownIndicator }}
                                options={tagsDD}
                                value={tagsDD.filter(x => defaultSelectedTags.some(v => x.value.includes(v.value)))}
                                styles={{
                                    indicatorSeparator: base => ({
                                        ...base,
                                        display: 'none'
                                    })
                                }}
                                onChange={async (value, action) => {
                                    var newtag = value.find(x => x.__isNew__ === true);
                                    if (!!newtag) {
                                        var data = {
                                            tagname: newtag.label
                                        }
                                        var response = await CallAxios("/api/LocationSetting/addeditTagFromFilter|post", data)
                                        if (!!response && response.status === 200) {
                                            var newobj = { value: response.data, label: newtag.label }
                                            value.splice(value.findIndex(x => x.__isNew__ === true), 1)
                                            value.push(newobj)
                                        }
                                    }
                                    let stringarr = [];
                                    for (let i = 0; i < value.length; i++) {
                                        stringarr.push(value[i].value)
                                    }
                                    setNewSelectedtags(stringarr)
                                    setdefaultSelectedTags(value)
                                    setTags(stringarr)
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="position-relative mb-3">
                            <label className='fw-bold mb-2' > {cl("employeeSettingsMdl.targetHours")} </label>
                            <TableWrapper>
                                <Table hover striped responsive className="DataGridTable overflowVisible">
                                    <thead>
                                        <tr className={"firsthead"}>
                                            <th className="NumberDataCell">{ct("startDate")}	</th>
                                            <th>{ct("summary")}</th>
                                            <th>{cl("employeeSettingsMdl.exceedingTargetHours")} </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !!TargetHourList && TargetHourList.length > 0 ?
                                                TargetHourList.map((value, index) => {
                                                    return <tr>
                                                        <td>{value.date}</td>
                                                        <td>{value.summary}</td>
                                                        <td>{value.exceedTargetHours === true ? <>{ct('notAllowed')}</> : <>{ct('permitted')}</>} </td>
                                                        <td>
                                                            <Gear color='#0d7be3' onClick={() => editobj(value.id)} />
                                                            {/* <a className='btnTblAction bgBlue'><BsFillGearFill  /></a> */}
                                                            {
                                                                TargetHourList && TargetHourList.length > 1 ?
                                                                    <Remove onClick={() => deleteobj(value.id)} />
                                                                    : null
                                                            }
                                                        </td>
                                                    </tr>
                                                })
                                                : null
                                        }
                                    </tbody>
                                </Table>
                            </TableWrapper>
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btnPrimaryGreen" onClick={() => savedata()}> {ct("done")}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

// function EditTargetHourPeriodModal(props) {
//     const formik = useFormik({
//         initialValues: {
//             startDate: new Date(),
//             hours: "weekly",
//             weeklyHours: "0.0",
//             monthlyHours: "0.0",
//             targetHoursExceed: false,
//             isMon: true,
//             isTue: true,
//             isWed: true,
//             isThu: true,
//             isFri: true,
//             isSat: false,
//             isSun: false,
//             Mon: "8.0",
//             Tue: "8.0",
//             Wed: "8.0",
//             Thu: "8.0",
//             Fri: "8.0",
//             Sat: "0.0",
//             Sun: "0.0",

//         },
//         onSubmit: values => {
//             submit(values)
//         },
//     });
//     const checkmonthly = (e) => {
//         formik.setFieldValue('hours', e.target.value)
//     }
//     useEffect(() => {
//         onLoad();
//     }, []);
//     const onLoad = async () => {
//         var response = await CallAxios("/api/TargetHours/getemptargetHours|post", { id: props.idforEdit });
//         if (!!response && !!response.status === 200) {
//             formik.setFieldValue("startDate", new Date(response.data.startdate))
//             formik.setFieldValue("hours", response.data.weeklyhour !== null ? "weekly" : "monthly")
//             formik.setFieldValue("weeklyHours", response.data.weeklyhour)
//             formik.setFieldValue("monthlyHours", response.data.monthlyhour)
//             formik.setFieldValue("targetHoursExceed", response.data.target_hours_not_be_exceeded)
//             formik.setFieldValue("isMon", response.data.ismon)
//             formik.setFieldValue("isTue", response.data.istue)
//             formik.setFieldValue("isWed", response.data.iswed)
//             formik.setFieldValue("isThu", response.data.isthur)
//             formik.setFieldValue("isFri", response.data.isfri)
//             formik.setFieldValue("isSat", response.data.issat)
//             formik.setFieldValue("isSun", response.data.issun)
//             formik.setFieldValue("Mon", response.data.mon)
//             formik.setFieldValue("Tue", response.data.tue)
//             formik.setFieldValue("Wed", response.data.wed)
//             formik.setFieldValue("Thu", response.data.thur)
//             formik.setFieldValue("Fri", response.data.fri)
//             formik.setFieldValue("Sat", response.data.sat)
//             formik.setFieldValue("Sun", response.data.sun)
//         }
//     }
//     const submit = async (value) => {
//         var data = {
//             Id: props.idforEdit,
//             EmployeeId: props.empid,
//             startdate: value.startDate,
//             isweeklyorMonthlyhour: value.hours,
//             weeklyhour: value.weeklyHours,
//             monthlyhour: value.monthlyHours,
//             Target_hours_not_be_exceeded: value.targetHoursExceed,
//             ismon: value.isMon,
//             istue: value.isTue,
//             iswed: value.isWed,
//             isthur: value.isThu,
//             isfri: value.isFri,
//             issat: value.isSat,
//             issun: value.isSun,
//             mon: value.Mon.toString(),
//             tue: value.Tue.toString(),
//             wed: value.Wed.toString(),
//             thur: value.Thu.toString(),
//             fri: value.Fri.toString(),
//             sat: value.Sat.toString(),
//             sun: value.Sun.toString(),
//         }

//         var response = await CallAxios("/api/TargetHours/AddEdittargetHours|post", data);
//         if (!!response && response.status === 200) {
//             if (response.data === true) {
//                 props.getEmpTargetHours()
//                 props.GetEmployeeList()
//                 props.setshow(false)
//             } else if (response.data === false) {
//                 toast.error("Some thing went wrong.")
//             }
//             else {
//                 toast.error("Start date has to be unique.")
//             }
//         }
//     }

//     const Handleweekdays = (e) => {
//         formik.setFieldValue(e.target.name, e.target.checked)
//     }
//     const divideWeeklyHours = (e) => {
//         let value = e.target.value;
//         let arr = [
//             formik.values.isMon,
//             formik.values.isTue,
//             formik.values.isWed,
//             formik.values.isThu,
//             formik.values.isFri,
//             formik.values.isSat,
//             formik.values.isSun,
//         ]
//         let Truelength = arr.filter(x => x === true).length;
//         let divResult = parseInt(value) / parseInt(Truelength);
//         if (formik.values.isMon) {
//             formik.setFieldValue('Mon', divResult)
//         }
//         if (formik.values.isTue) {
//             formik.setFieldValue('Tue', divResult)
//         }
//         if (formik.values.isWed) {
//             formik.setFieldValue('Wed', divResult)
//         }
//         if (formik.values.isThu) {
//             formik.setFieldValue('Thu', divResult)
//         }
//         if (formik.values.isFri) {
//             formik.setFieldValue('Fri', divResult)
//         }
//         if (formik.values.isSat) {
//             formik.setFieldValue('Sat', divResult)
//         }
//         if (formik.values.isSun) {
//             formik.setFieldValue('Sun', divResult)
//         }
//         formik.setFieldValue("weeklyHours", value);
//     }
//     return (
//         <Modal size="sm" className="editTargetHour" show={props.show} onHide={() => props.setshow(false)}>
//             <Modal.Header closeButton>
//                 <Modal.Title>Edit target hour period</Modal.Title>
//             </Modal.Header>
//             <Modal.Body >
//                 <Form>
//                     <Row>
//                         <Col md={12}>
//                             <Form.Group className="mb-3">
//                                 <label htmlFor="">start date </label>
//                                 <DatePicker className="form-control" selected={formik.values.startDate} onChange={(date) => formik.setFieldValue("startDate", date)} />
//                             </Form.Group>
//                             <Form.Group className="mb-3">
//                                 <label htmlFor="">Specify how many hours the employee should work on a weekly or monthly basis.</label>
//                             </Form.Group>
//                             <Row>
//                                 <Col md={3}>
//                                     <Form.Group className="mb-3">
//                                         <Form.Check value="weekly" onChange={(e) => checkmonthly(e)} checked={formik.values.hours === "weekly" ? true : false} type="radio" name="hours" id="AnsenceAllDay" />
//                                         <label htmlFor="">weekly hours </label>
//                                         <Form.Control name="weeklyHours" onChange={(e) => divideWeeklyHours(e)} value={formik.values.weeklyHours} type="text" disabled={formik.values.hours !== "weekly" ? true : false} />
//                                     </Form.Group>
//                                 </Col>
//                                 <Col md={3}>
//                                     <Form.Group className="mb-3">
//                                         <Form.Check value="monthly" type="radio" onChange={(e) => checkmonthly(e)} checked={formik.values.hours === "monthly" ? true : false} name="hours" id="AnsenceAllDa2" />
//                                         <label htmlFor="">monthly hours </label>
//                                         <Form.Control type="text" name="monthlyHours" onChange={formik.handleChange} value={formik.values.monthlyHours} disabled={formik.values.hours !== "monthly" ? true : false} />
//                                     </Form.Group>
//                                 </Col>

//                             </Row>
//                             <div className="py-3">
//                                 <p className="fw-light">Specify whether the employee in the schedule can exceed target hours on a weekly or monthly basis:</p>
//                                 <Form.Check label="Target hours must not be exceeded" onChange={formik.handleChange} checked={formik.values.targetHoursExceed} name="targetHoursExceed" className="my-4" type="checkbox" id="AnsenceAllDay25" />
//                                 <p className="fw-light">Target hours represent the contractually regulated hours of the employee. Here you can specify the target hours per weekday. The hours entered are used to calculate vacations and other absences. Target hours, on the other hand, do not affect the availability of the employee in the duty roster. If you enter target hours per week, you can define the hours per weekday. If you set the hours per month, the monthly average value per ticked day will be used automatically.</p>
//                             </div>
//                             <div className="smallDivsSection my-3">
//                                 <Row>
//                                     <Col md={2}>
//                                         <Form.Group>
//                                             <Form.Check inline className="me-2" name="isMon" onChange={(e) => Handleweekdays(e)} checked={formik.values.isMon} value="Mon" type="checkbox" id="AnsenceAllDay5" />

//                                             <label htmlFor="">Mon</label>
//                                             <Form.Control name="Mon" onChange={formik.handleChange} value={formik.values.Mon} type="text" disabled={formik.values.hours === "monthly" ? true : !formik.values.isMon} />
//                                         </Form.Group>
//                                     </Col>
//                                     <Col md={2}>
//                                         <Form.Group>
//                                             <Form.Check inline className="me-2" name="isTue" onChange={(e) => Handleweekdays(e)} value="Tue" checked={formik.values.isTue} type="checkbox" id="AnsenceAllDay7" />

//                                             <label htmlFor="">Tue</label>
//                                             <Form.Control name="Tue" type="text" onChange={formik.handleChange} value={formik.values.Tue} disabled={formik.values.hours === "monthly" ? true : !formik.values.isTue} />
//                                         </Form.Group>
//                                     </Col>
//                                     <Col md={2}>
//                                         <Form.Group>
//                                             <Form.Check inline className="me-2" name="isWed" onChange={(e) => Handleweekdays(e)} value="Wed" checked={formik.values.isWed} type="checkbox" id="AnsenceAllDay" />

//                                             <label htmlFor="">Wed</label>
//                                             <Form.Control name="Wed" type="text" onChange={formik.handleChange} value={formik.values.Wed} disabled={formik.values.hours === "monthly" ? true : !formik.values.isWed} />
//                                         </Form.Group>
//                                     </Col>
//                                     <Col md={2}>
//                                         <Form.Group>
//                                             <Form.Check inline className="me-2" name="isThu" onChange={(e) => Handleweekdays(e)} value="Thu" checked={formik.values.isThu} type="checkbox" id="AnsenceAllDay" />

//                                             <label htmlFor="">Thu</label>
//                                             <Form.Control name="Thu" type="text" onChange={formik.handleChange} value={formik.values.Thu} disabled={formik.values.hours === "monthly" ? true : !formik.values.isThu} />
//                                         </Form.Group>
//                                     </Col>
//                                     <Col md={2}>
//                                         <Form.Group>
//                                             <Form.Check inline className="me-2" name="isFri" onChange={(e) => Handleweekdays(e)} value="Fri" checked={formik.values.isFri} type="checkbox" id="AnsenceAllDay" />

//                                             <label htmlFor="">Fri</label>
//                                             <Form.Control name="Fri" type="text" onChange={formik.handleChange} value={formik.values.Fri} disabled={formik.values.hours === "monthly" ? true : !formik.values.isFri} />
//                                         </Form.Group>
//                                     </Col>
//                                     <Col md={2}>
//                                         <Form.Group>
//                                             <Form.Check inline className="me-2" name="isSat" onChange={(e) => Handleweekdays(e)} value="Sat" checked={formik.values.isSat} type="checkbox" id="AnsenceAllDay" />

//                                             <label htmlFor="">Sat</label>
//                                             <Form.Control name="Sat" type="text" onChange={formik.handleChange} value={formik.values.Sat} disabled={formik.values.hours === "monthly" ? true : !formik.values.isSat} />
//                                         </Form.Group>
//                                     </Col>
//                                     <Col md={2}>
//                                         <Form.Group>
//                                             <Form.Check inline className="me-2" name="isSun" onChange={(e) => Handleweekdays(e)} value="Sun" checked={formik.values.isSun} type="checkbox" id="AnsenceAllDay" />

//                                             <label htmlFor="">Sun</label>
//                                             <Form.Control name="Sun" type="text" onChange={formik.handleChange} value={formik.values.Sun} disabled={formik.values.hours === "monthly" ? true : !formik.values.isSun} />
//                                         </Form.Group>
//                                     </Col>
//                                 </Row>
//                             </div>
//                         </Col>
//                     </Row>
//                 </Form>
//             </Modal.Body>
//             <Modal.Footer>
//                 <Button className="" onClick={() => props.setshow(false)}>Cancel</Button>
//                 <Button className="btn btnPrimaryGreen" onClick={formik.handleSubmit}>Ready</Button>
//             </Modal.Footer>
//         </Modal>
//     );
// }