import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Col, Container, Form, Modal, Nav, Popover, Row, Table, Tooltip } from 'react-bootstrap';
import { FaDownload, FaPaperclip, FaQuoteRight, FaRegStickyNote } from 'react-icons/fa';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import FullCustomCalendar from '../../Components/FullCustomCalendar';
import { CallAxios } from '../../Helpers';
import { config } from '../../Helpers/Config';
import CalendarNotesModal from './RosterModals/CalendarNotesModal';
import DeleteShiftModal from './RosterModals/DeleteShiftModal';
import DirectAssignPastShiftConfirmationModel from './RosterModals/DirectAssignPastShiftConfirmationModel';
import EditShiftModal from './RosterModals/EditShiftModal';
import OccupancyModal from './RosterModals/OccupancyModal';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { BsFillGearFill } from 'react-icons/bs';
import { CircleBtnWithIcon } from '../../Helpers/Global/StyledComponents';
import { Remove } from '../../Components/ActionBtns';
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';

function Roster(props) {
    const { t: cl } = useTranslation('calendarPage');
    const { t: ct } = useTranslation('common');

    var selectedTags = useSelector(x => x.app.selectedTags);
    var selectedWorkAreas = useSelector(x => x.app.selectedWorkAreas);
    const [events, setEvents] = useState([]);
    const [SandBoxDates, setSandBoxDates] = useState();
    const [badges, setBadges] = useState([]);
    const [popOversData, setPopOversData] = useState({});
    const [shiftidforedit, setShiftidforedit] = useState("");
    const [EditShiftModals, setEditShiftModals] = useState(false);
    const [HeaderHolidaysColor, setHeaderHolidaysColor] = useState({});
    const [CalanderDate, setCalanderDate] = useState(null);
    const [AddNotesModal, setAddNotesModal] = useState(false);
    const [selectedHighlight, setSelectedHighlight] = useState([]);
    const [EventOccpancyInfo, setEventOccpancyInfos] = useState(null);
    const [isShiftalreadyassigned, seShifttisalreadyassigned] = useState(false);
    const [ShiftIdForAssignment, setShiftIdForAssignment] = useState("");
    const [DirectAssignPastShiftModel, setDirectAssignPastShiftModel] = useState(false);
    const [forceShiftId, setForceShiftId] = useState("");
    const [ShowExceedLimitModel, setShowExceedLimitModel] = useState(false);
    const [OccupancyModals, setOccupancyModal] = useState(false);
    const [ResourceTDModal, setResourceTDModal] = useState(false);
    const [deleteShiftModal, setDeleteShiftModal] = useState(false);
    const [DateForFutureDelete, setDateForFutureDeletes] = useState(null);
    const [ShowAttachmentListModal, setShowAttachmentListModal] = useState(false);

    useEffect(() => {
        if (!!props.calendarComponentRef && !!props.calendarComponentRef.current && !!props.calendarComponentRef.current.activeRange.start) {
            // var start = new Date(props.calendarComponentRef.current.activeRange.start);
            Promise.all([
                getShifts(props.calendarComponentRef.current.activeRange.start, props.calendarComponentRef.current.activeRange.end),
                // GetEmployeeList(start)
            ])
        }
    }, []);
    useEffect(() => {
        if (props.ShiftTrigget > 0) {
            getShifts(props.calendarComponentRef.current.activeRange.start, props.calendarComponentRef.current.activeRange.end)
        }
    }, [props.ShiftTrigget]);

    const getShifts = async (startDate, endDate) => {
        var start = !!startDate ? new Date(startDate) : new Date(props.calendarComponentRef.current.activeRange.start);
        var d = start.setHours(new Date().getHours(), new Date().getMinutes());
        start.setTime(d);

        var end = !!endDate ? new Date(endDate) : new Date(props.calendarComponentRef.current.activeRange.end)
        var dd = end.setHours(new Date().getHours(), new Date().getMinutes());
        end.setTime(dd);
        var data = {
            starDate: start,
            endDate: end,
            view: "Admin"
        }
        var response = await CallAxios('/api/Calendar/GetShifts|post', data)

        if (!!response && response.status === 200) {
            let bagesArr = {};
            bagesArr = response.data.absenceCounterBadge.map((event) => ({ ...bagesArr, [event.date]: event.counter }));
            const obj = Object.assign({}, ...bagesArr);
            let popData = {};
            popData = response.data.notedetail.map((event) => ({ ...popData, [event.noteDate]: event }));
            const obj2 = Object.assign({}, ...popData);

            setEvents(response.data.calenderShift)
            setSandBoxDates(response.data.sandBoxDates)
            // setFilterevents(response.data.calenderShift)
            // setShowCalendar(true)
            setBadges(obj)
            setPopOversData(obj2)
            // GetEmployeeList(calendarComponentRef.current?.activeRange.start);
        }

    }
    const openshiftEditModel = (info) => {
        setShiftidforedit(info.shiftId)
        setEditShiftModals(true)
    }
    const noteTrigger = (date) => {
        setCalanderDate(date)
        setAddNotesModal(!AddNotesModal)
    }
    const renderTimeinHeader = (date) => {
        if (props.timeBelowHeader !== {}) {
            if (props.activeTime === true) {
                if (!!props.timeBelowHeader[date]) {
                    return props.timeBelowHeader[date].map(x => <span className='d-block text-center' style={{ color: "#ffffff" }}>{x}</span>)
                }
            }
        }
    }
    const handleOccupancyModal = async (info) => {
        if (props.activeindex !== null) {
            var isalreadyassigned = false;
            seShifttisalreadyassigned(false)
            for (let index = 0; index < info.empId.length; index++) {
                const element = info.empId[index];
                if (element === props.SelectedEmployeeId.toString()) {
                    isalreadyassigned = true
                    seShifttisalreadyassigned(true)
                }
            }
            if (isalreadyassigned === false) {

                var data = {
                    id: info.shiftId,
                    empid: props.SelectedEmployeeId.toString()
                }
                var Occupancyresponse = await CallAxios("/api/Calendar/CheckEmplyeeOccupancyInsamePeriod|post", data)
                if (!!Occupancyresponse && Occupancyresponse.status === 200) {
                    if (Occupancyresponse.data === true) {
                        Swal.fire({
                            icon: 'info',
                            title: cl("errorMsg.employeeAlreadyAssigned"),
                        })
                        return;
                    }
                }
            }
            var shiftEndTime = moment(new Date(info.date).toLocaleDateString()).startOf("day").add(parseInt(info.time.split(" - ")[1].split(":")[0]), 'hours').add(parseInt(info.time.split(" - ")[1].split(":")[1]), 'minutes');

            if (moment().isSameOrAfter(shiftEndTime)) {

                setShiftIdForAssignment(info.shiftId)
                setDirectAssignPastShiftModel(true)
                return
            }
            var data = {
                id: info.shiftId,
                empid: props.SelectedEmployeeId.toString(),
                checkWorkarea: true,
                assignForcefully: false
            }
            if (isalreadyassigned) {
                var response = await CallAxios("/api/Calendar/RemoveFromShiftDirectly|post", data)
                if (!!response && response.data.success === true) {
                    getShifts(props.calendarComponentRef.current.activeRange.start, props.calendarComponentRef.current.activeRange.end);
                    props.GetEmployeeList(props.calendarComponentRef.current.activeRange.start);
                } else {
                    toast.error(response.data.message)
                }
            } else {
                var response = await CallAxios("/api/Calendar/assignShiftDirectly|post", data)
                if (!!response && response.status === 200) {
                    if (response.data === true) {
                        getShifts(props.calendarComponentRef.current.activeRange.start, props.calendarComponentRef.current.activeRange.end);
                        props.GetEmployeeList(props.calendarComponentRef.current.activeRange.start);
                    }
                    else if (response.data === "reached limit") {
                        setForceShiftId(info.shiftId)
                        setShowExceedLimitModel(true);
                    } else if (response.data !== false) {
                        Swal.fire({
                            icon: 'error',
                            title: response.data,
                        })
                    }
                }
            }
        } else {
            setOccupancyModal(!OccupancyModals)
        }
    }
    const ForceassignShiftDirectly = async () => {
        var data = {
            id: forceShiftId,
            empid: props.SelectedEmployeeId.toString(),
            checkWorkarea: true,
            assignForcefully: true
        }
        var response = await CallAxios("/api/Calendar/assignShiftDirectly|post", data)
        if (!!response && response.status === 200) {
            if (response.data === true) {
                getShifts(props.calendarComponentRef.current.activeRange.start, props.calendarComponentRef.current.activeRange.end);
                props.GetEmployeeList(props.calendarComponentRef.current.activeRange.start);
                setShowExceedLimitModel(false)
            }
            else if (response.data !== false) {
                Swal.fire({
                    icon: 'error',
                    title: response.data,
                })
            }
        }
    }
    const UpdateCalendar = () => {
        Promise.all([
            getShifts(props.calendarComponentRef.current.activeRange.start, props.calendarComponentRef.current.activeRange.end),
            props.GetEmployeeList()
            // GetEmployeeList(start)
        ])
    }
    const ShiftAttachmentList = (val) => {
        setShiftidforedit(val.shiftId)
        setShowAttachmentListModal(true)
    }
    return (
        <>

            {
                props.view === "Monthlist" ?
                    <MonthlyViewCalendar getShifts={getShifts} events={events} setEventOccpancyInfos={setEventOccpancyInfos} handleOccupancyModal={handleOccupancyModal} openshiftEditModel={openshiftEditModel} />
                    :
                    <FullCustomCalendar
                        _startTime={'00:00'}
                        _endTime={'22:45'}
                        _oneRowPerSeries={props._oneRowPerSeries}
                        _showTitle={props._showTitle}
                        resourceViewData={props.resourceViewData}
                        view={props.view}
                        updateLeftEmployeeList={UpdateCalendar}
                        _fixedHeadline={props._fixedHeadline}
                        _hideAssignments={props._hideAssignments}
                        _compactCalendar={props._compactCalendar}
                        _planningIncomplete={props._planningIncomplete}
                        _tags={props._tags}
                        _showRequirements={props._showRequirements}
                        showborderClass={props.showborderClass}
                        ShiftAttachmentList={ShiftAttachmentList}
                        filterShifts={props.filterShifts}
                        SandBoxDates={SandBoxDates}
                        refCalander={(node) => {
                            // _refofCustomCalendar.current = node;
                            props.calendarComponentRef.current = node;
                        }}
                        interval={15}
                        events={events.filter(x => selectedWorkAreas.some(c => c.value === x.workspaceid) || x.tags.find(v => selectedTags.some(c => c.value === v.label)) || (selectedTags.some(c => c.value === "Withouttags") ? x.tags.length === 0 : false))}
                        handleEditShiftModal={openshiftEditModel}
                        disableOverLappingInDailyView={true}
                        headerContent={
                            (args, element) => {
                                if (HeaderHolidaysColor[moment(args.date).format("DD/MM/YYYY")] !== undefined) {
                                    return <>
                                        <a className="gotit  showNoteHover" style={{ position: "relative" }}>
                                            <span className="isholiday ">{args.text}</span>
                                            <BadgePopover args={args.date} date={badges[moment(args.date).format("DD/MM/YYYY")]} />
                                            {popOversData[moment(args.date).format("DD/MM/YYYY")] !== {} ?
                                                <>
                                                    <StickyNotePopover data={popOversData[moment(args.date).format("DD/MM/YYYY")]} noteTrigger={noteTrigger} />
                                                    <PaperClipPopover data={popOversData[moment(args.date).format("DD/MM/YYYY")]} />
                                                </>
                                                : null
                                            }
                                            <FaRegStickyNote onClick={() => noteTrigger(args.date)} />

                                        </a>{renderTimeinHeader(moment(args.date).format("DD/MM/YYYY"))}
                                    </>

                                } else {
                                    let highlight = selectedHighlight[moment(args.date).format("DD/MM/YYYY")];
                                    // return <><a className='showNoteHover' style={{position:"relative"}}>{badges[moment(args.date).format("DD/MM/YYYY")] !== undefined && badges[moment(args.date).format("DD/MM/YYYY")] !== 0 && props.activeTime === true ? <span className='text-dangerss'>{args.text}</span> : highlight !== undefined && highlight.filter(x => x === localStorage.getItem("ClickedEmpID")).length > 0 ? <span className='text-danger employeeka'>{args.text}</span> :   args.text} {popOversData[moment(args.date).format("DD/MM/YYYY")] !== {} ? <><StickyNotePopover data={popOversData[moment(args.date).format("DD/MM/YYYY")]} /> <PaperClipPopover data={popOversData[moment(args.date).format("DD/MM/YYYY")]} /> </>: null}   <i className='fa fa-file btnOpenNoteModal' onClick={() => noteTrigger(args.date)}></i> <BadgePopover args={args.date} date={badges[moment(args.date).format("DD/MM/YYYY")]} /></a>{renderTimeinHeader(moment(args.date).format("DD/MM/YYYY"))}</>
                                    return <>
                                        <a className='showNoteHover'
                                            style={{ position: "relative" }}>
                                            {
                                                highlight !== undefined && highlight.filter(x => x === props.SelectedEmployeeId?.toString()).length > 0 ?
                                                    <span className='text-danger employeeka'>{args.text}</span> : args.text} {popOversData[moment(args.date).format("DD/MM/YYYY")] !== {} ?
                                                        <>
                                                            {/* <StickyNotePopover data={[moment(args.date).format("DD/MM/YYYY")]} noteTrigger={noteTrigger} /> */}
                                                            <CustomizedTooltips
                                                                toolTipType="Note"
                                                                toolTipData={popOversData[moment(args.date).format("DD/MM/YYYY")]}
                                                                toolTipIcon={<FaRegStickyNote className={`${!!popOversData[moment(args.date).format("DD/MM/YYYY")] ? "fa fa-file-o btnNote" : "fa fa-file btnOpenNoteModal"}`} />}
                                                                openModalClick={() => noteTrigger(args.date, popOversData[moment(args.date).format("DD/MM/YYYY")])}
                                                            />
                                                            {
                                                                popOversData[moment(args.date).format("DD/MM/YYYY")]?.notefilename ?
                                                                    <CustomizedTooltips
                                                                        toolTipType="Attachment"
                                                                        toolTipData={popOversData[moment(args.date).format("DD/MM/YYYY")]}
                                                                        toolTipIcon={<FaPaperclip className={`${!!popOversData[moment(args.date).format("DD/MM/YYYY")] ? "dontShowNote" : "dontShowNote"}`} />}
                                                                    />
                                                                    :
                                                                    null
                                                            }
                                                            {/* <PaperClipPopover data={popOversData[moment(args.date).format("DD/MM/YYYY")]} /> */}
                                                        </>
                                                        : null
                                            }
                                            <BadgePopover args={args.date} date={badges[moment(args.date).format("DD/MM/YYYY")]} />
                                        </a>
                                        {renderTimeinHeader(moment(args.date).format("DD/MM/YYYY"))}
                                    </>
                                }
                            }
                        }
                        eventClick={(event, info) => {
                            setEventOccpancyInfos(info);
                            handleOccupancyModal(info);
                        }}
                        dateClick={(event, date) => {
                            var currentTime = moment();
                            var DayClickDate = moment(date).add(currentTime.hour(), 'hour').add(currentTime.minute(), 'minute')
                            props.setNewShiftModal(true)
                            props.setCreateShiftMode("dayclick")
                            props.setDayClickDate(DayClickDate)
                            // props.handleModalShowHide("NewShiftModal", "dayclick", dateobj)
                        }}
                        datesSet={
                            async (info) => {
                                var start = new Date(info.start)
                                var d = start.setHours(new Date().getHours(), new Date().getMinutes());
                                start.setTime(d);

                                var end = new Date(info.end)
                                var dd = end.setHours(new Date().getHours(), new Date().getMinutes());
                                end.setTime(dd);

                                var data = {
                                    starDate: start,
                                    endDate: end,
                                    view: "Admin"
                                }

                                // let cc = moment.utc(moment(info.start).utc()).format();
                                // let ee = moment.utc(moment(info.end).utc()).format();
                                var response = await CallAxios('/api/Calendar/GetShifts|post', data);
                                if (!!response && response.status === 200) {
                                    let bagesArr = {};
                                    bagesArr = response.data.absenceCounterBadge.map((event) => ({ ...bagesArr, [event.date]: event.counter }));
                                    const obj = Object.assign({}, ...bagesArr);
                                    let popData = {};
                                    popData = response.data.notedetail.map((event) => ({ ...popData, [event.notecreatedDate]: event }));
                                    const obj2 = Object.assign({}, ...popData);
                                    let Holiday = {};
                                    Holiday = response.data.publicHolidays.map((event) => ({ ...Holiday, [moment(event.date).format("DD/MM/YYYY")]: event }));
                                    const obj3 = Object.assign({}, ...Holiday);
                                    let newEvents = response.data.calenderShift.filter(x => x.requiredEmpCount !== x.assignedEmpCount);
                                    let selectedEmployeebadgeHighlighter = {};
                                    selectedEmployeebadgeHighlighter = response.data.absenceCounterBadge.map((event) => ({ ...selectedEmployeebadgeHighlighter, [event.date]: event.empIds }));
                                    const obj4 = Object.assign({}, ...selectedEmployeebadgeHighlighter);
                                    setEvents(response.data.calenderShift)
                                    setSandBoxDates(response.data.sandBoxDates)
                                    // setFilterevents(response.data.calenderShift)
                                    // setShowCalendar(true)
                                    setBadges(obj)
                                    setPopOversData(obj2)
                                    setHeaderHolidaysColor(obj3)
                                    setSelectedHighlight(obj4)
                                }
                            }
                        }
                        onResourceDrop={(event, empId) => {
                        }}
                        onResourceTDClick={(event, date) => {
                            var currentTime = moment();
                            var DayClickDate = moment(date).add(currentTime.hour(), 'hour').add(currentTime.minute(), 'minute')
                            props.setNewShiftModal(true)
                            props.setCreateShiftMode("dayclick")
                            props.setDayClickDate(DayClickDate)
                            setResourceTDModal(true)
                        }}
                        SelectedEmployeeId={props.SelectedEmployeeId}
                    />
            }
            {
                props.OccupanyInotherLocations.length > 0 ?
                    <>
                        <div> {cl("employeeAssignmentLocationsPeriod")}:</div>
                        {
                            props.OccupanyInotherLocations.map((val, key) => {
                                return <div>{val.datetime} hour ({val.locationName}) </div>
                            })
                        }
                    </>
                    : null
            }
            {
                ShowExceedLimitModel === true ?
                    <Modal show={ShowExceedLimitModel} onHide={() => setShowExceedLimitModel(false)} >
                        <Modal.Header closeButton >
                            <Modal.Title>Information</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <label className='fw-bolder'> {cl("employeeHourLimit")}.</label>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <a className='text-danger me-3' onClick={() => setShowExceedLimitModel(false)}> {ct("cancel")}</a>
                            <Button className="btn btnPrimaryGreen" onClick={() => ForceassignShiftDirectly()}> {ct("forceAssignment")}</Button>
                        </Modal.Footer>
                    </Modal>
                    : null
            }
            {/* {
                ResourceTDModal === true ?
                    <TabResourceTDModal show={ResourceTDModal} handleChange={setResourceTDModal} />
                    : null
            } */}
            {
                ShowAttachmentListModal &&
                <ShiftAttachment show={ShowAttachmentListModal} onHide={setShowAttachmentListModal} shiftidforedit={shiftidforedit}
                    GetEmployeeList={props.GetEmployeeList} getShifts={getShifts} calenderDate={props.calendarComponentRef.current?.activeRange?.start} />
            }
            {
                DirectAssignPastShiftModel === true ?
                    <DirectAssignPastShiftConfirmationModel
                        SelectedEmployeeId={props.SelectedEmployeeId} checkWorkarea={true} show={DirectAssignPastShiftModel} handleModal={setDirectAssignPastShiftModel}
                        isShiftalreadyassigned={isShiftalreadyassigned} getShifts={getShifts} calendarComponentRef={props.calendarComponentRef}
                        GetEmployeeList={props.GetEmployeeList} shiftid={ShiftIdForAssignment} />
                    : null
            }
            {
                EditShiftModals === true ?
                    <EditShiftModal show={EditShiftModals} handleOccupancyModal={handleOccupancyModal}
                        OccupancyModal={OccupancyModals} calenderDate={props.calendarComponentRef.current?.activeRange?.start}
                        GetEmployeeList={props.GetEmployeeList} getShifts={getShifts} setDateForFutureDelete={setDateForFutureDeletes}
                        shiftidforedit={shiftidforedit} onHide={setEditShiftModals} setDeleteShiftModal={setDeleteShiftModal} />
                    : null
            }
            {
                deleteShiftModal === true ?
                    <DeleteShiftModal show={deleteShiftModal} onHide={setDeleteShiftModal} shiftidforedit={shiftidforedit} getShifts={getShifts}
                        OccupancyModals={OccupancyModals} setOccupancyModal={setOccupancyModal} setEditShiftModal={setEditShiftModals} DateForFutureDelete={DateForFutureDelete} />
                    : null
            }
            {
                OccupancyModals === true ?
                    <OccupancyModal calendarRef={props.calendarComponentRef} updateLeftList={props.GetEmployeeList} setshiftidforedit={setShiftidforedit}
                        updateshift={getShifts} show={OccupancyModals} setEditShiftModal={setEditShiftModals} handleOccupancyModal={handleOccupancyModal} EventOccpancyInfo={EventOccpancyInfo} />
                    : null
            }
            {
                AddNotesModal === true ?
                    <CalendarNoteModel show={AddNotesModal} onHide={setAddNotesModal} objIdforNote={CalanderDate} updateshift={getShifts} />
                    : null
            }

        </>

    );
}

export default Roster;

function CalendarNoteModel(props) {
    const { t: ct } = useTranslation('common');

    return (
        <Modal show={props.show} onHide={() => props.onHide(false)}>
            <Modal.Header closeButton >
                <Modal.Title>{ct("note")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col md={12}>
                            <Container fluid>
                                <CalendarNotesModal objIdforNote={props.objIdforNote} noteEntity="calendar" updateshift={props.updateshift} />
                            </Container>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-secondary" onClick={() => props.onHide(false)}>{ct("shutdown")}</Button>
            </Modal.Footer>
        </Modal>
    )
}
function StickyNotePopover(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget.value);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    // const classes = useStyles();

    return (
        <>
            {!!props.data && props.data.notecreatedByName !== null ?
                <>
                    <FaRegStickyNote className='dontShowNote' aria-describedby={id} onClick={() => props.noteTrigger(moment(props.data.noteDate))} onMouseEnter={handleClick} onMouseLeave={handleClose} />
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <div className='minwidth150px'>
                            <div className='header bg-light p-2 px-3'>
                                <h6 className='mb-0 fw-bold' style={{ fontSize: "12px" }}>{!!props.data && props.data.notecreatedByName}</h6>
                                <span style={{ fontSize: "10px" }}>{!!props.data && props.data.notecreatedDate}</span>
                            </div>
                            <div className='popoverBody p-2 px-3'>
                                <span style={{ fontSize: "12px" }}> {!!props.data && props.data.noteText} </span>
                            </div>
                        </div>
                    </Popover> </>
                : null}
        </>
    );
}
function BadgePopover(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const [Counter, setCounter] = useState(null);
    const [AbsenceList, setAbsenceList] = useState([]);
    const [checkstate, setCheckstate] = useState(false);

    const handleClick = async (event) => {
        setAnchorEl(event.currentTarget);
        if (checkstate === false) {
            var response = await CallAxios("/api/Calendar/GetAbsenceBadgeDetail|post", { date: new Date(props.args) });
            if (!!response && response.status === 200) {
                setAbsenceList(response.data);
                setCheckstate(true)
            }
        }

    };
    const TooltipData = {
        data: AbsenceList,
        counter: Counter
    }
    useEffect(() => {
        setCounter(props.date)
    }, [props.date]);
    const open = Boolean(anchorEl);

    return (
        <>
            {!!props.date && props.data !== 0 ?
                <>
                    {Counter > 0 ?
                        <CustomizedTooltips
                            toolTipType="BadgeTooltip"
                            toolTipData={TooltipData}
                            toolTipIcon={<span
                                onClick={handleClick}
                                data-tooltip-id='my-tooltip-click'
                                data-tooltip-html={
                                    !!TooltipData.data && TooltipData.data.length > 0 ? TooltipData.data.map((value, key) => {
                                        return `<div>
                                        <div className='tooltip-header'>
                                            <p className='tooltip-title'>${value?.empName}</p>
                                            <span className='tooltip-header-date'>${value?.dateRange}</span>
                                        </div>
                                        <div className='tooltip-body'>
                                            <p className='tooltip-body-text'>${value?.absenceName}</p>
                                        </div>
                                    </div>`
                                    })

                                        : null
                                }
                                style={{ marginLeft: '3px' }} className='badge text-white bg-danger'
                            >{props.date}</span>}
                        />
                        : null
                    }
                </>
                : null
            }
        </>
    );
}
function PaperClipPopover(props) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const onSendClick = () => {
        let address = props.data.notefileAdress;
        window.open(config.S3BucketURL + address, '_blank');
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    // const classes = useStyles();

    return (
        <>{
            !!props.data && props.data.notefilecreatedByName !== null ?
                <>
                    <FaPaperclip className='dontShowNote' onClick={onSendClick} aria-describedby={id} onMouseEnter={handleClick} onMouseLeave={handleClose} />

                    <Popover
                        id={id}
                        // className={classes.popover}
                        // classes={{
                        //     paper: classes.popoverContent,
                        // }}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <div className='minwidth150px'>
                            <div className='header bg-light p-3'>
                                <h6 className='mb-0 fw-bold' style={{ fontSize: "12px" }}>{!!props.data && props.data.notefilecreatedByName}</h6>
                                <span style={{ fontSize: "10px" }}>{!!props.data && props.data.notefilecreatedDate}</span>
                            </div>
                            <div className='popoverBody p-3'>
                                <span style={{ fontSize: "12px" }}> {!!props.data && props.data.notefilename}  </span>
                            </div>
                        </div>
                    </Popover>
                </>
                : null
        }

        </>
    );
}
function CustomizedTooltips(props) {
    const data = props.toolTipData;

    return (
        <>
            {!!data ?
                <>
                    <div style={{ display: 'inline-block' }}>

                        <span>

                            {props.toolTipType === "Attachment" ?
                                <a data-tooltip-id="my-tooltip" data-tooltip-html={props.toolTipType === "Attachment" ?

                                    `<div className='tooltip-header'>
                                        <p className='tooltip-title'>${data?.notefilecreatedByName}</p>
                                        <span className='tooltip-header-date'>${data?.notefilecreatedDate}</span>
                                    </div>
                                    <div className='tooltip-body'>
                                        <p className='tooltip-body-text'>${data?.notefilename}</p>
                                    </div>`

                                    :
                                    null
                                } target='_blank' href={config.S3BucketURL + data?.notefilename} className="attachment-link"><span>{props.toolTipIcon}</span></a>
                                :
                                props.toolTipType === "Note" ?
                                    <span data-tooltip-id="my-tooltip" data-tooltip-html={
                                        props.toolTipType === "Note" ?
                                            `
                                        <div className='tooltip-header'>
                                            <p className='tooltip-title'>${data?.notecreatedByName}</p>
                                            <span className='tooltip-header-date'>${data?.notecreatedDate}</span>
                                        </div>
                                        <div className='tooltip-body'>
                                            <p className='tooltip-body-text'>${data?.noteText}</p>
                                        </div>
                                    `
                                            :
                                            null
                                    }
                                        onClick={props.openModalClick}>{props.toolTipIcon}</span>
                                    :
                                    props.toolTipType === "attachmentInside" ? <span data-tooltip-id='my-tooltip' data-tooltip-html={
                                        props.toolTipType === "attachmentInside" && data.length > 0 ?

                                            `<div className='tooltip-header'>
                                            <p className='tooltip-title text-center'><span className='attachmentNumber'>${data.length}</span>Attachments</p>
                                            <span className='tooltip-header-date'></span>
                                        </div>
                                        <div className='tooltip-body'>
                                            ${data.map((item, index) => {
                                                return ` <p className='tooltip-body-text text-center'>
                                                        <a target='_blank' href=${config.S3BucketURL + item.attachmentKey} onClick=${(e) => { e.stopPropagation() }} className="attachmentOpen">
                                                            ${item.attachmentName}
                                                        </a>
                                                    </p>`

                                            })}
                                        </div>`

                                            :
                                            null
                                    }>{props.toolTipIcon}</span>
                                        :
                                        props.toolTipIcon
                            }
                        </span>
                    </div>
                </>
                :
                <>
                    {props.toolTipType === "Attachment" ? null : null}
                    {props.toolTipType === "attachmentInside" ? null : null}
                    {props.toolTipType === "Note" ? <span onClick={props.openModalClick}>{props.toolTipIcon}</span> : null}
                </>
            }
        </>
    );
}
function MonthlyViewCalendar(props) {
    const { t: cl } = useTranslation('calendarPage');
    const { t: ct } = useTranslation('common');

    const [startDate, setstartDate] = useState(new Date(moment().startOf('month')));
    const [endDate, setEndDate] = useState(new Date(moment().endOf('month')));
    useEffect(() => {
        props.getShifts(startDate, endDate)
    }, []);
    // const getShifts = async () => {
    //     var start = new Date(startDate)
    //     var d = start.setHours(new Date().getHours(), new Date().getMinutes());
    //     start.setTime(d);

    //     var end = new Date(endDate)
    //     var dd = end.setHours(new Date().getHours(), new Date().getMinutes());
    //     end.setTime(dd);
    //     var data = {
    //         starDate: start,
    //         endDate: end,
    //         view: "Admin"
    //     }
    //     var response = await CallAxios('/api/Calendar/GetShifts|post', data)
    //     if (!!response && response.status === 200) {
    //         setEvents(response.data.calenderShift)
    //     }

    // }
    const HandelRowClick = (obj) => {
        var data = {
            "shiftId": obj.shiftId,
            "time": obj.time,
            "title": obj.title,
            "count": obj.assignedEmpCount + "/" + obj.requiredEmpCount,
            "tags": obj.tags,
            "date": obj.date,
            "note": obj.note,
            "shiftApplicationsEmployees": obj.shiftEmployeeList,
            "bgColor": obj.bgColor,
            "workspaceid": obj.workspaceid,
            "empId": obj.empId,
            "empIds": obj.empIds,
            "isPublicHoliday": obj.isPublicHoliday,
            "assignedEmpCount": obj.assignedEmpCount,
            "requiredEmpCount": obj.requiredEmpCount,
            "shiftEmployeeList": obj.shiftEmployeeList,
            "shiftAttachmentList": obj.shiftAttachmentList,
            "height": "",
            "left": 0,
            "right": 0,
            "zIndex": 1,
            "Rwidth": ""
        }
        props.setEventOccpancyInfos(data)
        props.handleOccupancyModal(data)
    }
    return (
        <>

            <Nav.Item>
                <div className='row'>
                    <div className='col-5 pe-0'>
                        <DatePicker selected={startDate} onChange={date => setstartDate(date)} PlaceholderText={ct("startTime")} />
                    </div>
                    <div className='col-5'>
                        <DatePicker selected={endDate} onChange={date => setEndDate(date)} PlaceholderText={ct("endTime")} />
                    </div>
                    <div className='col-2'>
                        <button className='btn' onClick={(e) => props.getShifts(startDate, endDate)}> {ct("show")}</button>
                    </div>
                </div>
            </Nav.Item>
            <Table hover responsive className="DataGridTable">
                <thead>
                    <tr>
                        <th> {ct("date")}</th>
                        <th> {ct("time")}</th>
                        <th> {ct("employee")}</th>
                        <th> {ct("workingArea")}	</th>
                        <th> {ct("tags")}</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>

                    {
                        props.events.length > 0 ?
                            props.events.sort((a, b) => new Date(...a.date.split('/').reverse()) - new Date(...b.date.split('/').reverse())).map((val, key) => {
                                return <tr onClick={() => HandelRowClick(val)}>
                                    <td>{moment(val.date).format("DD/MM/YYYY")}</td>
                                    <td>{val.time}</td>
                                    <td>{val.shiftEmployeeList.map(x => x.empname).join(",")}</td>
                                    <td>{val.title}</td>
                                    <td>{val.tags.map(x => x.text).join(',')}</td>
                                    <td>
                                        {/* <Badge className={"badgedanger"}> {val.assignedEmpCount} From {val.requiredEmpCount}</Badge> */}
                                        <Badge className='p-2' bg={
                                            val.requiredEmpCount > val.assignedEmpCount ? "danger" : "success"}>
                                            {val.assignedEmpCount} {ct("from")} {val.requiredEmpCount}
                                        </Badge>


                                        <a className="text-dark ms-2" style={{ fontSize: "16px" }} onClick={(e) => { e.stopPropagation(); props.openshiftEditModel(val) }}><BsFillGearFill /></a>
                                        {/* <a data-tooltip-id="my-tooltip" data-tooltip-content="To edit" className="text-dark ms-2" style={{ fontSize: "16px" }}><FaQuoteRight /></a> */}
                                    </td>
                                </tr>

                            })
                            : null
                    }
                </tbody>
            </Table>
        </>
    );
}
function ShiftAttachment(props) {
    const { t: ct } = useTranslation('common');

    const [ShiftAttchmentsList, setShiftAttchmentsList] = useState([]);
    useEffect(() => {
        getData()

    }, []);
    const getData = async () => {
        var data = {
            id: props.shiftidforedit
        }
        var response = await CallAxios('/api/Calendar/getShiftAttachmentList|post', data)
        if (!!response && response.status === 200) {
            setShiftAttchmentsList(response.data)
        }
    }
    const DeleteAttachment = async (id) => {
        var Attachmentresponse = await CallAxios("/api/Shift/DeleteAttachment|post", { id: id });
        if (!!Attachmentresponse && Attachmentresponse.data === true) {
            props.GetEmployeeList(props.calenderDate)
            props.getShifts?.()
            if (ShiftAttchmentsList.length > 1) {
                setShiftAttchmentsList(ShiftAttchmentsList.filter(x => x.id !== id))
            } else {
                props.onHide(false)
            }
        }
    }
    return (
        <Modal show={props.show} onHide={() => props.onHide(false)} >
            <Modal.Header closeButton >
                <Modal.Title> {ct("attachments")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {
                        ShiftAttchmentsList.length > 0 &&
                        ShiftAttchmentsList.map((val, key) => {
                            return <div className="d-flex align-items-center">
                                <span className="me-2">{val.attachmentName}</span>
                                <a target='_blank' href={config.S3BucketURL + val.attachmentKey}><CircleBtnWithIcon className="Btn " color=""><FaDownload /></CircleBtnWithIcon></a>
                                <Remove onClick={() => DeleteAttachment(val.id)} />
                            </div>
                        })
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btnPrimaryGreen" onClick={() => props.onHide(false)}> {ct("done")}</Button>
            </Modal.Footer>
        </Modal>
    )
}