import { createSlice } from '@reduxjs/toolkit';
import { SignInManager } from '../../Helpers/Authentication/SignInManager';
import { Constants } from '../../Helpers/Contents';

// Redux States
const initialState = {
  activeNavBar: 'Design',
  isLoading: false,
  currentView: "",
  isshowSystemMessage: true,
  theme: {
    colors: {
      bg: "#1b9176",
      buttons: "#01dba9",
      headerText: "#109a7a",
      active: "#00dba9"
    }
  },
  selectedTags: [],
  selectedWorkAreas: [],


  jwt: {
    token: new SignInManager().AccessToken
  },
  user: {
    CompanyId: 0,
    LocationId: "",
    roles: [],
    LoginUserId: "",
    EncLoginUserId: "",
    empName: "",
    // empId: 0,
    CurrentBalance: 0,
    CurrentVocationBalance: 0,
    CompanyName: "",
    LocationName: "",
    messageCounter: 0,
  },
  appSettings: {
    Language: "de",
    Modules: [],
    SubscriptionStatus: Constants.SubscriptionStatus.Expire,
    // LocationId: 0,
    // UserRoles: [],
    employeeAccess: {
      canSeeabsences: false,
      canSeeavailabilities: false,
      canSeeCalender: false,
      canSeeMessages: false,
      canSeetimetracking: false,
    },
    calendarSettings: {
      Drag_drop_list_views: false,
      highlight_events: false,
      Hourly_view_end_time: undefined,
      Hourly_view_start_time: undefined,
      Overlapping_shifts_in_day_view: false,
      Overlapping_shifts_in_week_view: false,
      Override_row_height: false,
      time_steps_length: "",
    },
    tags: [],
    locationsettings: {
      Activate_shift_related_breaks_in_duty_roster: false,
      Employees_can_see_all_workspaces: false,
      Employees_only_created_by_name: false,
      Hide_employee_hours_in_calendar: false,
      TimeZone: "",
      ProhibitShiftSwaps: false,
      applyForFullShifts: false,
      seeApplicationsFromOtherEmployees: false,
      viewApplicationsInResourceView: false,
    },
    absenceSetting: {
      CalculatePublicHolidayHoursInReports: false,
      ConfirmAbsencesCreatedAdministrators: false,
      ConfirmAbsencesCreatedEmployee: false,
      EmployeesCanSeecolleagueAbsences: false,
      status_and_type_of_absence_not_visible_to_other_employees: false,
    },
    TimetrackSettings: {
      Automatic_break_deduction_after_net_time: false,
      Copy_tags_from_roster: false,
      Employees_allowed_edit_working_area_of_time_recordings: false,//use
      Employees_allowed_to_tag_time_records: false, //use
      Employees_can_create_own_tags: false,//use
      employee_app: false,
      Resulting_pause_at_least_as_long_as_automatic_pause: false, //use
      Summarize_time_records: false,
      Time_recordings_automatically_confirmed: false,
    },
    WorkSpaces: [],
    MinimumRoleToEditLocationSetting: "",
  },

  // these slices are update itself no need to update on refresh ( define in app.jsx ProtectedRoute function)
  absenceCounterBadge: "",
  pendingTimetrackCount: "",
  runningTimetrackCount: ""
};

//Slice
export const AppSlice = createSlice({
  name: 'AppSlice',
  initialState,
  reducers: {
    setAddEditResponseWindow: (state, action) => {
      state.activeNavBar = action.payload;
    },
    setisLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setCurrentView: (state, action) => {
      state.currentView = action.payload;
      localStorage.setItem("currentView", action.payload)
    },
    setJwt: (state, action) => {
      state.jwt = { ...state.jwt, ...action.payload };
    },
    setUser: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
    setAppSettings: (state, action) => {
      state.appSettings = { ...state.appSettings, ...action.payload };
    },
    setSelectedTags: (state, action) => {
      state.selectedTags = action.payload;
      localStorage.setItem("selectedTags", action.payload.map(x => x.value).join(","))
    },
    setSelectedWorkAreas: (state, action) => {
      state.selectedWorkAreas = action.payload;
      localStorage.setItem("selectedWorkArea", action.payload.map(x => x.value).join(","))
    },
    setAbsenceCounterBadge: (state, action) => {
      state.absenceCounterBadge = action.payload;
    },
    setIsshowSystemMessage: (state, action) => {
      state.isshowSystemMessage = action.payload;
      localStorage.setItem("isshowSystemMessage", action.payload)
    },
    setPendingTimetrackCount: (state, action) => {
      state.pendingTimetrackCount = action.payload;
    },
    setRunningTimetrackCount: (state, action) => {
      state.runningTimetrackCount = action.payload;
    },
    setLocationTheme: (state, action) => {
      state.theme = { ...state.theme, ...action.payload };
    },

  },
})

// Exporter
export const {
  setAddEditResponseWindow,
  setisLoading,
  setJwt,
  setUser,
  setCurrentView,
  setAppSettings,
  setSelectedTags,
  setSelectedWorkAreas,
  setAbsenceCounterBadge,
  setIsshowSystemMessage,
  setPendingTimetrackCount,
  setRunningTimetrackCount,
  setLocationTheme
} = AppSlice.actions

export default AppSlice.reducer