import { Field, FieldArray, FormikProvider, useFormik } from "formik";
import React, { useEffect } from 'react';
import { Button, Col, Container, Modal, Row, Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { isValidPhoneNumber } from "react-phone-number-input";
import * as Yup from "yup";
import { AxiosLite, CallAxios } from "../../Helpers";
import { toast } from "react-toastify";
import { useState } from "react";
import { Gear, Remove } from "../../Components/ActionBtns";
import { Constants } from "../../Helpers/Contents";
import { TableWrapper } from "../../Helpers/Global/StyledComponents";
import HeaderButton from "../../Components/HeaderButton";
import { FaPlus } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import DeleteConfirmationModal from "../../Components/DeleteConfirmationModal";


function WorkSchedules(props) {
    const { t: ast } = useTranslation('accountSetting');
    const { t: ct } = useTranslation('common');


    const [AddEditWorkingTimeModelModal, setAddEditWorkingTimeModelModal] = useState(false);
    const [WorkingTimeModelsList, setWorkingTimeModelsList] = useState([]);
    const [WorkingTimeModelForEditID, setWorkingTimeModelForEditID] = useState("");
    const [ShowDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    useEffect(() => {
        getData()
    }, []);
    const getData = async () => {
        var response = await AxiosLite("/api/WorkingTimeModel/workingModelList|get");
        if (!!response && response.status === 200) {
            setWorkingTimeModelsList(response.data)
        }
    }
    const onDeleteSubmit = async (id) => {
        var response = await CallAxios("/api/WorkingTimeModel/workingModelDelete|post", { id: id })
        if (!!response && response.status === 200) {
            getData();
            setShowDeleteConfirmationModal(false)
        }
    }
    return (
        <>
            {
                ShowDeleteConfirmationModal &&
                <DeleteConfirmationModal show={ShowDeleteConfirmationModal} onHide={setShowDeleteConfirmationModal}
                    title={ast('workTab.deleteMdl.title')} message={ast('workTab.deleteMdl.desc')} deletefn={onDeleteSubmit} objId={WorkingTimeModelForEditID} />
            }
            {
                AddEditWorkingTimeModelModal === true ?
                    <AddEditWorkingTimeModal show={AddEditWorkingTimeModelModal} id={WorkingTimeModelForEditID} getData={getData} setshow={setAddEditWorkingTimeModelModal} />
                    : null
            }
            <Row>
                <Col md={12}>
                    <div className="TabFormContainerSection">
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <div className="Heading h5 fw-bold mb-3 d-flex align-items-center"> {ast('workTab.title')} </div>
                            <HeaderButton title={ct('new') + ' ' + ast('workTab.workingTimeModel')} onClick={() => { setWorkingTimeModelForEditID(""); setAddEditWorkingTimeModelModal(true) }} icon={<FaPlus color='#fff' size={18} />} />
                        </div>
                        <p>{ast('workTab.para')}.</p>
                        <TableWrapper className="mt-4">
                            <Table striped bordered responsive className="DataGridTable overflowVisible">
                                <thead>
                                    <tr className="firsthead">
                                        <th> {ct('title')}</th>
                                        <th> {ast('workTab.numberDays')}</th>
                                        <th className="text-right"> {ct('action')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !!WorkingTimeModelsList && WorkingTimeModelsList.length > 0 ? WorkingTimeModelsList.map((value, key) => {
                                            return <tr>
                                                <td>{value.title}</td>
                                                <td>{value.numberOfDays}</td>
                                                <td>
                                                    <Gear onClick={(e) => { setWorkingTimeModelForEditID(value.id); setAddEditWorkingTimeModelModal(true) }} />
                                                    <Remove onClick={(e) => { setWorkingTimeModelForEditID(value.id); setShowDeleteConfirmationModal(true) }} />
                                                </td>
                                            </tr>
                                        })
                                            :
                                            <tr>
                                                <td colSpan="4" className="text-center"> {ast('workTab.noRecordDesc')}.</td>
                                            </tr>
                                    }
                                </tbody>
                            </Table>
                        </TableWrapper>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default WorkSchedules;


function AddEditWorkingTimeModal(props) {
    const { t: ast } = useTranslation('accountSetting');
    const { t: ct } = useTranslation('common');


    const formik = useFormik({
        initialValues: {
            title: "",
            TimeModel: [{ beginningHour: "", beginningMin: "", EndHour: "", EndMin: "", isFree: false }],
        },
        validationSchema: Yup.object().shape({
            title: Yup.string().required(ast('workTab.newWorkMdl.errorMsg.titleError')),
        }),
        onSubmit: values => {
            Submit(values)
        },
    });
    React.useEffect(() => {
        if (!!props.id) {
            getData()
        }
    }, [props.id]);
    const getData = async () => {
        var response = await CallAxios("/api/WorkingTimeModel/getworkingModel|post", { id: props.id });
        if (!!response & response.status === 200) {
            formik.setFieldValue("title", response.data.title)
            formik.setFieldValue("TimeModel", response.data.timeModel)
        }
    }
    const Submit = async (e) => {
        var data = {
            id: props.id,
            title: e.title,
            TimeModel: e.TimeModel
        }
        var response = null;
        if (!!props.id) {
            response = await CallAxios("/api/WorkingTimeModel/EditworkingModel|post", data)
        } else {
            response = await CallAxios("/api/WorkingTimeModel/createModel|post", data)
        }
        if (!!response && response.data === true) {
            props.getData();
            props.setshow(false)
        }
    }


    return (
        <Modal show={props.show} onHide={() => props.setshow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{!!props.id ? <>{ct('edit')}</> : <>{ct('new')}</>} {ast('workTab.workingTimeModel')} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col md={12} >
                            <Form.Group>
                                <Form.Label> {ct('title')}</Form.Label>
                                <Form.Control onChange={formik.handleChange} name="title" maxLength={150} defaultValue={formik.values.title} type="text" placeholder={ast('workTab.newWorkMdl.zbFree')} />
                                {formik.touched.title && formik.errors.title ? (
                                    <span className="error text-danger text-left">{formik.errors.title}</span>
                                ) : null}
                            </Form.Group>
                        </Col>
                        <Col md={12} className="mt-3">
                            <ASTimeModel formik={formik} />
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btnPrimaryGreen" onClick={formik.handleSubmit}> {ct('finished')}</Button>
            </Modal.Footer>
        </Modal>
    );
}

function ASTimeModel(props) {
    const { t: ast } = useTranslation('accountSetting');
    const { t: ct } = useTranslation('common');


    return (
        <FormikProvider value={props.formik}>

            <FieldArray
                name="TimeModel"
                render={arrayHelpers => {
                    const TimeModel = props.formik.values.TimeModel;
                    return (
                        <div>
                            {TimeModel && TimeModel.length > 0
                                ? TimeModel.map((user, index) => (
                                    <div key={index}>
                                        <Container fluid className="py-3 border-top">
                                            {user.isFree === false ?
                                                <Row>
                                                    <Col md={1}>
                                                        <span>{index + 1}</span>
                                                    </Col>
                                                    <Col md={5}>
                                                        <Field
                                                            name={`TimeModel.${index}.id`}
                                                            className="Noarrow bgPrimaryGrey custom-select text-center d-none"
                                                            as="select"
                                                        >
                                                        </Field>
                                                        <Form.Group className="mb-0 text-left" controlId="NewWorkTimeModelForm.txtBeginningTime">
                                                            <Form.Label> {ct('beginning')}</Form.Label>
                                                            <div className="flexInputGroup d-flex align-items-center">
                                                                <Field

                                                                    name={`TimeModel.${index}.beginningHour`}
                                                                    label={ct("free")}
                                                                    className="Noarrow bgPrimaryGrey form-select custom-select text-center"
                                                                    as="select"
                                                                >
                                                                    {
                                                                        Constants.hoursDropDown.map((val, key) => {
                                                                            return <option key={"beginningHour" + key} value={val.value}>{val.text}</option>
                                                                        })
                                                                    }
                                                                </Field>
                                                                <span className="seperator mx-2">:</span>
                                                                <Field

                                                                    name={`TimeModel.${index}.beginningMin`}
                                                                    label={ct("free")}
                                                                    className="Noarrow bgPrimaryGrey form-select custom-select text-center"
                                                                    as="select"
                                                                >
                                                                    {
                                                                        Constants.minutesDropDown.map((val, key) => {
                                                                            return <option key={"beginningMin" + key} value={val.value}>{val.text}</option>
                                                                        })
                                                                    }
                                                                </Field>
                                                            </div>

                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={5}>
                                                        <Form.Group className="mb-0 text-left" controlId="NewWorkTimeModelForm.txtEndTime">
                                                            <Form.Label> {ct('end')}</Form.Label>
                                                            <div className="flexInputGroup d-flex align-items-center">
                                                                <Field

                                                                    name={`TimeModel.${index}.endHour`}
                                                                    label={ast('workTab.newWorkMdl.free')}
                                                                    className="Noarrow bgPrimaryGrey  form-select text-center custom-select"
                                                                    as="select"
                                                                >
                                                                    {
                                                                        Constants.hoursDropDown.map((val, key) => {
                                                                            return <option key={"endHour" + key} value={val.value}>{val.text}</option>
                                                                        })
                                                                    }
                                                                </Field>
                                                                <span className="seperator mx-2">:</span>
                                                                <Field

                                                                    name={`TimeModel.${index}.endMin`}
                                                                    label={ast('workTab.newWorkMdl.free')}
                                                                    className="Noarrow text-center form-select bgPrimaryGrey custom-select"
                                                                    as="select"
                                                                >
                                                                    {
                                                                        Constants.minutesDropDown.map((val, key) => {
                                                                            return <option key={"endMin" + key} value={val.value}>{val.text}</option>
                                                                        })
                                                                    }
                                                                </Field>
                                                            </div>

                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                : null}
                                            <Row className="mt-2">
                                                <Col md={1}></Col>
                                                <Col md={5}>
                                                    <Form.Group className="mb-0 text-left">
                                                        <label>
                                                            <Field
                                                                type="checkbox"
                                                                name={`TimeModel.${index}.isFree`}
                                                                label={ast('workTab.newWorkMdl.free')}
                                                                className="m-2 form-check-input"
                                                            />
                                                            {ast('workTab.newWorkMdl.free')}
                                                        </label>

                                                    </Form.Group>
                                                </Col>
                                                <Col md={5} className="text-right ">
                                                    <a className="LinkTextDanger btn btn-primary btn-sm" onClick={() => arrayHelpers.remove(index)}> {ast('workTab.newWorkMdl.clear')}</a>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                ))
                                : null}

                            <div className="border-top">
                                <div className="py-3"><Button className="LinkTextGreen" onClick={() =>
                                    arrayHelpers.push({ beginningHour: "", beginningMin: "", EndHour: "", EndMin: "", isFree: false })
                                }> {ast('workTab.newWorkMdl.addWorkingDay')} </Button></div>
                            </div>

                        </div>
                    );
                }}
            />
        </FormikProvider>

    )
}
