import { useState } from "react";
import { CallAxios } from "../../../Helpers";
import Swal from "sweetalert2";
import { Button, Modal } from "react-bootstrap";
import { BsExclamationCircle } from "react-icons/bs";
import { MdWifiProtectedSetup } from "react-icons/md";
import { useTranslation } from "react-i18next";


function DeleteShiftModal(props) {
    const { t: cl } = useTranslation('calendarPage');
    const { t: ct } = useTranslation('common');


    const AlertValue = {
        single: cl("alertValue.single"),
        future: cl("alertValue.future"),
        all: cl("alertValue.all"),
    }


    const [deletedropdownvalue, setDeletedropdownvalue] = useState("single");
    const DeleteShiftbtn = async () => {
        var data = {
            deleteValue: deletedropdownvalue,
            shiftId: props.shiftidforedit,
            deletefrom: props.DateForFutureDelete
        }
        var response = await CallAxios("/api/Calendar/DeleteShifts|post", data);
        if (!!response && response.data === true) {
            props.getShifts();
            props.onHide(false)
            props.setEditShiftModal(false)
            if (props.OccupancyModals) {
                props.setOccupancyModal(false)
            }
        }
    }

    return (
        <>
            <Modal show={props.show} onHide={() => props.onHide(false)} >
                <Modal.Header className="RelativeModalHeader" closeButton >
                    <Modal.Title className="text-break">{cl("deleteSingleEvent")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <label className='fw-bolder form-label'>{cl("iWantDelete")}</label>
                        <select className='Noarrow bgPrimaryGrey form-select text-center custom-select mb-3' value={deletedropdownvalue} onChange={(e) => setDeletedropdownvalue(e.target.value)}>
                            <option value="single">{cl("onlyThisEvent")}</option>
                            <option value="future">{cl("allFutureEvents")}</option>
                            <option value="all">{cl("allevents")}</option>
                        </select>
                        <div className="alert alert-primary px-3" role="alert">
                            <div className='row'>
                                <div className='col-md-1'>
                                    <BsExclamationCircle style={{ fontSize: "26px" }} />
                                </div>
                                <div className='col-md-11'>
                                    {AlertValue[deletedropdownvalue]}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <a className='text-danger me-3' onClick={() => props.onHide(false)}>{ct("cancel")}</a>
                    <Button className="btn btnPrimaryGreen" onClick={() => DeleteShiftbtn()}>{ct("delete")}</Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}
export default DeleteShiftModal;
