import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Row, Table } from 'react-bootstrap';
import { BsChevronDown } from 'react-icons/bs';
import { FaRegEnvelope } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import MessageModal from '../../Components/MessageModal';
import MessageToWorAreaModal from '../../Components/MessageToWorAreaModal';
import { AxiosLite, CallAxios } from '../../Helpers';
import { config } from '../../Helpers/Config';
import { ContentBox, ContentSubHeader } from '../../Helpers/Global/StyledComponents';
import { setUser } from '../../Store/slices/AppSlice';
import MessagesHeader from './MessagesHeader';
import { useTranslation } from "react-i18next";


const Navdown = styled.a`
cursor:pointer;
padding:5px;
`

function MessagesView() {
    const { t: st } = useTranslation('simpleData');
    const { t: ct } = useTranslation('common');


    var user = useSelector(x => x.app.user)
    const dispatch = useDispatch();

    const [ChatList, setChatList] = useState([]);
    const [messages, setMessages] = useState("");
    const [messageList, setMessageList] = useState([]);
    const [ActiveChatId, setActiveChatId] = useState("");
    const [MessageEmployeeModal, setMessageEmployeeModal] = useState(false);
    const [SendMessageWorAreaModal, setSendMessageWorAreaModal] = useState(false);
    const [employeeList, setEmployeeList] = useState([]);

    useEffect(() => {
        getChat();
        getEmployeeList();
    }, []);

    const getChat = async () => {
        var response = await AxiosLite("/api/Chat/GetEmployeeChat|get")
        if (!!response && response.status === 200) {
            setChatList(response.data)
        }
    }
    const getEmployeeList = async () => {
        var response = await AxiosLite("/api/Employee/GetAllEmployeeDropDown|get")
        if (!!response && response.status === 200) {
            setEmployeeList(response.data)
        }
    }
    const expandDiv = async (id) => {
        let prevstate = [...ChatList];

        if (prevstate.find(x => x.id === id).show === true) {
            prevstate.find(x => x.id === id).show = false;
            setMessages("")
            setMessageList([])
            setActiveChatId("")
            setChatList(prevstate)
        } else {
            let newList = prevstate.map(x => {
                return {
                    ...x,
                    show: false
                }
            });
            getChatMessages(id)
            setMessages("")
            setActiveChatId(id)
            newList.find(x => x.id === id).show = true;
            setChatList(newList)
        }
    }
    const getChatMessages = async (id) => {
        var response = await CallAxios("/api/Chat/GetChatMessages|post", { id: id })
        if (!!response && response.status === 200) {
            setMessageList(response.data)
        }
    }
    const replay = async () => {
        var data = {
            ChatId: ActiveChatId,
            message: messages
        }
        var response = await CallAxios("/api/Chat/SendMessages|post", data)
        if (!!response && response.status === 200) {
            setMessages("")
            getChatMessages(ActiveChatId)
        }
    }
    const UpdateChatStatus = async (id) => {
        var response = await CallAxios("/api/Chat/UpdateChatStatus|post", { id: id })
        if (!!response && response.status === 200) {
            getChat();
            var responses = await CallAxios("/api/Chat/UpdateMessageCounter|post", { id: user.LoginUserId })
            if (!!responses && responses.status === 200) {
                dispatch(
                    setUser({
                        messageCounter: responses.data,
                    })
                );
                // setCookie('messageCounter', response.data, { path: '/' });
            }
        }
    }

    return (
        <>
            <ContentSubHeader>
                <MessagesHeader setMessageEmployeeModal={setMessageEmployeeModal} setSendMessageWorAreaModal={setSendMessageWorAreaModal} />
            </ContentSubHeader>
            <ContentBox>
                <Container fluid>
                    <Row>
                        <Col md={12} className="mt-4">
                            <Table hover responsive className="DataGridTable text-left" >
                                <thead>
                                    <tr>
                                        <th> {st('messagePage.tbl.from')}</th>
                                        <th> {st('messagePage.tbl.message')}</th>
                                        <th> {st('messagePage.tbl.to')}</th>
                                        <th> {st('messagePage.tbl.on')}</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!!ChatList && ChatList.length > 0 && ChatList.map((x, i) => {
                                        return <>
                                            <tr key={i}>
                                                <td>{x.fromEmp}</td>
                                                <td>{x.message} {!!x.attachment && <a target='_blank' href={config.S3BucketURL + '/' + x.attachment}>- {st('messagePage.attachment')}</a>}</td>
                                                <td>{x.toEmp}</td>
                                                <td>{x.date}</td>
                                                <td>{x.seen === false ? <a class="h5 text-dark"><FaRegEnvelope onClick={() => UpdateChatStatus(x.id)} /></a> : ""}</td>
                                                <td class="text-right"><Navdown onClick={() => expandDiv(x.id)}><BsChevronDown /></Navdown></td>
                                            </tr>
                                            {x.show === true &&
                                                <>
                                                    {
                                                        !!messageList && messageList.length > 0 ? messageList.map((item, index) =>
                                                            <tr class="comment_section " key={index} style={{ display: "table-row" }} data-comment-section="comment-508411">
                                                                <td className='border-0'>
                                                                    <span style={{ marginLeft: "15%" }}>
                                                                        {item.fromEmp}
                                                                    </span>
                                                                </td>
                                                                <td className='border-0' colspan={6} style={{ "whiteSpace": "normal" }}>
                                                                    "{item.message}" <br />
                                                                    <span style={{ color: "#95a5a6", fontSize: "12.5px" }}>
                                                                        {item.time}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        ) : null
                                                    }
                                                    <tr>
                                                        <td className='border-0'></td>
                                                        <td className='border-0' colSpan={2}>
                                                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                                                <Form.Control as="textarea" rows={1} placeholder={st('messagePage.answer')} value={messages} onChange={(e) => setMessages(e.target.value)} className="pb24" />
                                                                <div className='text-right mt-3'>
                                                                    <button className='btn btn-secondary w-25' onClick={() => replay()}>{ct('send')}</button>
                                                                </div>
                                                            </Form.Group>

                                                        </td>
                                                        <td className='border-0' colSpan={3}></td>
                                                    </tr>
                                                </>
                                            }
                                        </>
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    {
                        MessageEmployeeModal === true ?
                            <MessageModal show={MessageEmployeeModal} onHide={setMessageEmployeeModal} getData={getChat}
                                EmpList={employeeList.filter(x => x.email !== null)} />
                            : null
                    }
                    {
                        SendMessageWorAreaModal === true ?
                            <MessageToWorAreaModal show={SendMessageWorAreaModal} setshow={setSendMessageWorAreaModal} getData={getChat} />
                            : null
                    }
                </Container>
            </ContentBox>
        </>
    );
}

export default MessagesView;
