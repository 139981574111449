import React, { useState, useRef, lazy } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { Portal } from 'react-portal';
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import OutsideClickHandler from 'react-outside-click-handler';
import { useTranslation } from "react-i18next";

const DropdownContentWrapper = styled.div`
background-color:#ffffff;
`
const Title = styled.span`
overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    float: left;
    text-align: left;
    padding-right: 6px;
    margin-right: -6px;
    `


const DropdownPortal = ({ options, handleOnChange, selected, name, placeholder, optionKey }) => {

  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const parentRef = useRef(null);
  const [selectedList, setselectedList] = useState(selected);
  const [dropOptions, DropOptions] = useState(options);
  const [allSelected, setallSelected] = useState(false);
  const getDrodownRef = useRef(null);
  useEffect(() => {
    if (dropOptions.length === selectedList.length) {
      setallSelected(true)
    }
  }, []);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const onChange = (e) => {
    if (e.target.checked === true) {
      let prevState = [...selectedList];
      let newState = [...prevState, e.target.value];
      setselectedList(newState)
      handleOnChange(newState)
    } else {
      let prevState = [...selectedList];
      let filter = prevState.filter(x => x !== e.target.value);
      let newState = filter;
      setselectedList(newState)
      handleOnChange(newState)
    }

  }

  const showTitles = () => {
    let x = [];
    if (selectedList && selectedList.length > 0) {
      for (let i = 0; i < selectedList.length; i++) {
        const element = selectedList[i];
        x.push(dropOptions.find(y => y[optionKey ? optionKey : "key"] === element)?.label);
      }
    }
    console.log(x)
    return x
  }
  const selectDeselectAll = () => {
    if (allSelected === false) {
      let prevState = [...selectedList];
      if (dropOptions && dropOptions.length > 0) {
        for (let i = 0; i < dropOptions.length; i++) {
          const element = dropOptions[i];
          if (prevState.includes(element[optionKey ? optionKey : "key"]) === false) {
            prevState.push(element[optionKey ? optionKey : "key"])
          }
        }
      }
      setselectedList(prevState)
      setallSelected(true)
      handleOnChange(prevState)
    } else {
      setselectedList([])
      setallSelected(false)
      handleOnChange([])

    }


  }



  return (
    <div className="parent" ref={parentRef}>
      <button className='btn dropdown-toggle btnDDList smWidth w-100' onClick={toggleDropdown}>
        <Title>
          {showTitles().length > 0 ? showTitles().join(",") : placeholder}
        </Title>
      </button>
      {isDropdownVisible && (
        <Portal>
          <OutsideClickHandler
            onOutsideClick={() => {
              setDropdownVisible(false);
            }}
          >
            <DropdownContent optionKey={optionKey} parentRef={parentRef} onChange={onChange} options={dropOptions} selectDeselectAll={selectDeselectAll} selected={selectedList} name={name} getDrodownRef={getDrodownRef} />

          </OutsideClickHandler>
        </Portal>
      )}
    </div>
  );
};



const DropdownContent = ({ parentRef, options, name, onChange, selected, selectDeselectAll, getDrodownRef, optionKey }) => {
  const { t: ct } = useTranslation('common');


  const dropdownRef = useRef(null);
  const [position, setPosition] = useState("");
  const dropdownRefc = useCallback((e) => { dropdownRef.current = e; getDrodownRef.current = e }, []);
  useEffect(() => {
    calculateDropdownPosition()
  }, []);

  const calculateDropdownPosition = () => {
    const parentRect = parentRef.current.getBoundingClientRect();
    const parentScrollTop = parentRef.current.scrollTop;
    if (dropdownRef.current) {
      const dropdownHeight = dropdownRef.current.offsetHeight;
      let dropdownTop = parentRect.bottom - parentScrollTop;
      if (dropdownTop + dropdownHeight > window.innerHeight) {
        dropdownTop = parentRect.top - parentScrollTop - dropdownHeight;
      }
      setPosition({
        left: parentRect.left + 'px',
        top: dropdownTop + 'px',
      });

    }

    return {};
  };

  return (
    <DropdownContentWrapper className="dropdown-content dropdown-menu position-fixed py-0  border d-block" style={{ ...position }} ref={dropdownRefc}>
      <div class="btn-group btn-group-sm btn-block"><button onClick={selectDeselectAll} class="actions-btn btn btn-light"> {ct('selectDeselectAll')} </button></div>
      {options?.map((x, i) =>
        <div className='dropdown-item'>
          <Form.Check
            value={x[optionKey ? optionKey : "key"]}
            type={'checkbox'}
            id={`default-checkbox-${i}-${x.label}`}
            label={x?.label}
            name={name + "[]"}
            onChange={onChange}
            checked={selected.includes(x[optionKey ? optionKey : "key"]) ? true : false}
          />
        </div>
      )}
    </DropdownContentWrapper>

  );
};








export default DropdownPortal;
