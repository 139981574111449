import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';


import common from './locales/en/common.json';
import myProfile from './locales/en/myProfile.json';
import selectDropdown from './locales/en/selectDropdown.json';
import header from './locales/en/header.json';
import locationSetting from './locales/en/locationSetting.json';
import accountSetting from './locales/en/accountSetting.json';
import exportsImports from './locales/en/ExportsImports.json';
import subscription from './locales/en/subscription.json';
import simpleData from './locales/en/simpleData.json';
import employeePage from './locales/en/employeePage.json';
import calendarPage from './locales/en/calendarPage.json';
import absencePage from './locales/en/absencePage.json';
import timeTrackingPage from './locales/en/timeTrackingPage.json';
import invoicePage from './locales/en/invoicePage.json';
import content from './locales/en/content.json';
import recurrencePage from './locales/en/recurrencePage.json';


import common_de from './locales/de/common.json';
import myProfile_de from './locales/de/myProfile.json';
import selectDropdown_de from './locales/de/selectDropdown.json';
import header_de from './locales/de/header.json';
import locationSetting_de from './locales/de/locationSetting.json';
import accountSetting_de from './locales/de/accountSetting.json';
import exportsImports_de from './locales/de/ExportsImports.json';
import subscription_de from './locales/de/subscription.json';
import simpleData_de from './locales/de/simpleData.json';
import employeePage_de from './locales/de/employeePage.json';
import calendarPage_de from './locales/de/calendarPage.json';
import absencePage_de from './locales/de/absencePage.json';
import timeTrackingPage_de from './locales/de/timeTrackingPage.json';
import invoicePage_de from './locales/de/invoicePage.json';
import content_de from './locales/de/content.json';
import recurrencePage_de from './locales/de/recurrencePage.json';

export const defaultNS = 'common';

const en = {
    common,
    myProfile,
    selectDropdown,
    header,
    locationSetting,
    accountSetting,
    exportsImports,
    subscription,
    simpleData,
    employeePage,
    calendarPage,
    absencePage,
    timeTrackingPage,
    invoicePage,
    content,
    recurrencePage,
};

const de = {
    common: common_de,
    myProfile: myProfile_de,
    selectDropdown: selectDropdown_de,
    header: header_de,
    locationSetting: locationSetting_de,
    accountSetting: accountSetting_de,
    exportsImports: exportsImports_de,
    subscription: subscription_de,
    simpleData: simpleData_de,
    employeePage: employeePage_de,
    calendarPage: calendarPage_de,
    absencePage: absencePage_de,
    timeTrackingPage: timeTrackingPage_de,
    invoicePage: invoicePage_de,
    content: content_de,
    recurrencePage: recurrencePage_de,
};

export const resources = { de, en };

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        defaultNS,
        ns: 'common',
        resources,
        fallbackLng: 'de',
        lng: 'de', // default language
        debug: false,
        detection: {
            lookupQuerystring: 'lang',
            lookupFromPathIndex: 0,
            lookupLocalStorage: 'i18nextLng',
            caches: ['localStorage', 'cookie'],
        },
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
