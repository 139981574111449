import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Modal, Nav, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { BsPlusSquare } from 'react-icons/bs';
import { FaRegClock, FaRegStar, FaStar } from 'react-icons/fa';
import { FiInfo, FiShield } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import accountLocation from "../../../App/accountLocation.png";
import { AxiosLite, CallAxios } from '../../../Helpers';
import { SignInManager } from '../../../Helpers/Authentication/SignInManager';
import { CircleBtnWithIcon, ContentBox, ContentSubHeader, SubHeadTitle, TableWrapper } from '../../../Helpers/Global/StyledComponents';
import { useTranslation } from "react-i18next";


function LocationList() {
    const { t: st } = useTranslation('simpleData');
    const { t: ct } = useTranslation('common');


    const user = useSelector(x => x.app.user)

    const [AddLocationModal, setAddLocationModal] = useState(false);
    const [LocationList, setLocationList] = useState([]);

    useEffect(() => {
        getdata();
    }, []);

    const getdata = async () => {
        var response = await AxiosLite('/api/Locations/locationsList|get');
        if (!!response && response.status === 200) {
            setLocationList(response.data)
        }
    }
    const addTofavourit = async (id) => {
        var response = await CallAxios('/api/Locations/FavouritLocation|post', { id: id.toString() });
        if (!!response && response.status === 200) {
            getdata()
        }
    }

    return (
        <>
            <ContentSubHeader>
                <Row>
                    <Col md={4}>
                        <div className="my-3">
                            <SubHeadTitle> {st('locationPage.locationTitle')}</SubHeadTitle>
                            <SubHeadTitle>{user.CompanyName}</SubHeadTitle>
                        </div>
                    </Col>
                    <Col md={8}>
                        <div className="my-3">
                            <Nav className="justify-content-end" activeKey="/home">
                                <Nav.Item className="text-right">
                                    {/* <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={<Tooltip>Add another location</Tooltip>}> */}
                                    <Nav.Link data-tooltip-id="my-tooltip" data-tooltip-content={st('locationPage.addLocationTooltip')} data-tooltip-place="bottom" className="px-0" href="#" onClick={() => setAddLocationModal(true)}>
                                        <CircleBtnWithIcon className='Btn' color='#000'>  <BsPlusSquare className="HeaderActionIcon" /> </CircleBtnWithIcon>
                                    </Nav.Link>
                                    {/* </OverlayTrigger> */}
                                </Nav.Item>
                            </Nav>
                        </div>
                    </Col>
                </Row>
            </ContentSubHeader>
            <ContentBox>
                <Container fluid>
                    <Row>
                        <Col md={12} className="mt-3">
                            <TableWrapper>
                                <Table bordered striped responsive className="DataGridTable leftalign  overflowVisible">
                                    <thead >
                                        <tr className="firsthead">
                                            <th>{st('locationPage.tbl.location')} </th>
                                            <th>{ct('employee')}</th>
                                            <th>{st('locationPage.tbl.owner')}</th>
                                            <th>{st('locationPage.tbl.notifications')}</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !!LocationList && LocationList.length > 0 ? LocationList.map((value, key) => {
                                                return <tr>
                                                    <td>
                                                        <div className="dataTextCell">
                                                            <Link className="LinkBlue" to={`/account/${user.CompanyId}/locationdetail/${value.locationId}`}>
                                                                <img src={accountLocation} alt="employeeIcon" width={50} className="LinkTextIcon me-2" />
                                                                {value.locationname}
                                                            </Link>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="dataTextCell">
                                                            {value.noOfEmployees}({value.noOfEmployees}) {ct('employee')}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="dataTextCell">
                                                            {value.owners}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="dataTextCell">
                                                            <a href="javascript:;" className="NotificationIcons me-2"><FiShield />{!!value.pendingAbsence && value.pendingAbsence !== '0' ? <span className="countBadge"> {value.pendingAbsence} </span> : null}</a>
                                                            <a href="javascript:;" className="NotificationIcons"><FaRegClock />{!!value.pendingTimetracker && value.pendingTimetracker !== '0' ? <span className="countBadge"> {value.pendingTimetracker} </span> : null}</a>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="dataTextCell">
                                                            {/* 151932 */}
                                                            {/* <Link className="LinkBlue" to={`/companies/${value.locationId}`}>Enter location</Link> */}
                                                            <EnterLocationLink locationId={value.locationId} locationname={value.locationname} />
                                                            {/* <Link className="LinkBlue" to={`/companies/${value.locationId}`} onClick={()=> setCookieValue("LocationName",value.locationname,3)}>Enter location</Link> */}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="dataTextCell">
                                                            <a href="javascript:;" className="LinkIconBlue" onClick={() => addTofavourit(value.locationId)}>
                                                                {value.setAsFavourits ? <FaStar className='me-2' style={{ fontSize: "26px", color: "#f1c40f" }} /> : <FaRegStar className='me-2' style={{ fontSize: "26px" }} />}

                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            })
                                                : null
                                        }


                                    </tbody>
                                </Table>
                            </TableWrapper>
                        </Col>
                    </Row>
                </Container>
                {
                    !!AddLocationModal ?
                        <AddNewLocation show={AddLocationModal} getdata={getdata} onHide={setAddLocationModal} />
                        : null
                }
            </ContentBox>
        </>
    );
}

export default LocationList;
function EnterLocationLink(props) {
    const { t: st } = useTranslation('simpleData');

    const SwitchLocation = async (id) => {
        var response = await CallAxios("/api/Account/SwitchLocation|post", { id: id + "" })
        if (!!response && response.status === 200) {
            new SignInManager().SetToken(response.data.token)
            window.location.href = `/companies/${id}/users`
        }
    }

    return (
        <Link className="LinkBlue" to="#" onClick={() => SwitchLocation(props.locationId)}> {st('locationPage.enterLocation')}</Link>
    )
}
function AddNewLocation(props) {
    const { t: st } = useTranslation('simpleData');
    const { t: ct } = useTranslation('common');


    const [locationtemplateID, setLocationtemplateID] = useState('');
    const [SystemTemplateTypeControl, setSystemTemplateTypeControl] = useState(false);

    const [TimeZone, setTimeZone] = useState("UTC");
    const [templateList, setTemplateList] = useState([]);
    const [timezoneList, settimezoneList] = useState([]);

    useEffect(() => {
        getTemplates()
        getTimeZones()
    }, []);
    useEffect(() => {
        if (SystemTemplateTypeControl) {
            setLocationtemplateID(templateList.filter(x => x.isdefault === true)[0]?.value)
        } else {
            setLocationtemplateID(templateList.filter(x => x.isdefault === false)[0]?.value)
        }
    }, [SystemTemplateTypeControl, templateList]);
    const getTemplates = async () => {
        var response = await AxiosLite('/api/Locations/locationsTemplatesList|get');
        if (!!response) {
            setTemplateList(response.data);
            // if (response.data.length > 0) {
            //     setLocationtemplateID(response.data[0].value)
            // }
        }
    }
    const getTimeZones = async () => {
        var response = await AxiosLite('/api/Locations/GetTimeZones|get');
        if (!!response && response.status === 200) {
            settimezoneList(response.data);
        }
    }
    const gsubmitTemplate = async () => {
        if (locationtemplateID === '' || TimeZone === '') {
            return;
        }
        var data = {
            locationtemplateID: locationtemplateID,
            TimeZone: TimeZone
        }
        var response = await CallAxios('/api/Locations/addLocation|post', data);
        if (!!response && response.data === true) {
            props.getdata()
            props.onHide(false)
        }
    }

    return (
        <Modal show={props.show} onHide={() => props.onHide(false)}>
            <Modal.Header closeButton >
                <Modal.Title> {st('locationPage.newLocationMdl.newLocation')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row onChange={() => setSystemTemplateTypeControl(!SystemTemplateTypeControl)}>
                    <Col md={6} className="mb-3">
                        <div>
                            <Form.Check type="radio" defaultChecked={!SystemTemplateTypeControl} label={st('locationPage.newLocationMdl.ownTemplateLabel')} name="SelectLocationTemplate" id="formAddLocationOwnTemplate" />
                        </div>
                    </Col>
                    <Col md={6} className="mb-3">
                        <div>
                            <Form.Check type="radio" defaultChecked={SystemTemplateTypeControl} label={st('locationPage.newLocationMdl.systemTemplateLabel')} name="SelectLocationTemplate" id="formAddLocationSystemTemplate" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    {SystemTemplateTypeControl ?
                        <Col md={12}>
                            <Form.Group className='mb-3' controlId="AddLocationForm.SyatemSelectCreateLocation">
                                <Form.Label> {st('locationPage.newLocationMdl.createLocation')}</Form.Label>
                                <Form.Control as="select" className="SelectbgGrey form-select" custom onChange={(e) => setLocationtemplateID(e.target.value)}>
                                    {
                                        !!templateList && templateList.length > 0 ? templateList.filter(x => x.isdefault === true).map((val, key) => {
                                            return <option value={val.value}>{val.text}</option>
                                        }) :
                                            null
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        :
                        <Col md={12} >
                            <Form.Group className='mb-3' controlId="AddLocationForm.OwnSelectCreateLocation">
                                <Form.Label> {st('locationPage.newLocationMdl.createLocation')} </Form.Label>
                                <Form.Control as="select" className="SelectbgGrey form-select" va custom onChange={(e) => setLocationtemplateID(e.target.value)}>
                                    {
                                        !!templateList && templateList.length > 0 ? templateList.filter(x => x.isdefault === false).map((val, key) => {
                                            return <option value={val.value}>{val.text}</option>
                                        }) :
                                            null
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    }
                    <Col md={12} >
                        <Form.Group className='mb-3' controlId="AddLocationForm.SelectTimeZone">
                            <Form.Label> {ct('timeZone')}</Form.Label>
                            <Form.Control as="select" className="SelectbgGrey form-select" value={TimeZone} onChange={(e) => setTimeZone(e.target.value)} custom>
                                {!!timezoneList && timezoneList.length > 0 ? timezoneList.map((val, index) => {
                                    return <option key={index} value={val.value}>
                                        {val.text}
                                    </option>
                                })
                                    : null
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={12} className="mt-3">
                        <Alert variant="primary" className="text-center">
                            <FiInfo className="me-2" /> {st('locationPage.newLocationMdl.alertSelect')}
                        </Alert>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btnPrimaryGreen" onClick={() => gsubmitTemplate()}>{ct('finished')}</Button>
            </Modal.Footer>
        </Modal>
    )
}