import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, Col, Form, Modal, Row } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import FileBase64 from 'react-file-base64';
import { BsChevronDown } from "react-icons/bs";
import { FaUpload } from "react-icons/fa";
import { useSelector } from "react-redux";
import CreatableSelect from 'react-select/creatable';
import Swal from "sweetalert2";
import { AxiosLite, CallAxios } from "../../../Helpers";
import { Constants } from "../../../Helpers/Contents";
import { IoClose } from "react-icons/io5";
import { FaRegTimesCircle } from "react-icons/fa";
import TimeTrackingBreaksRepeter from "../../../Components/TimeTrackingBreaksRepeter";
import RepetitionModal from "../../../Components/RepetitionModal";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";


function CreateShift(props) {
    const { t: cl } = useTranslation('calendarPage');
    const { t: ct } = useTranslation('common');



    const user = useSelector(x => x.app.user)
    const appSettings = useSelector(x => x.app.appSettings)
    const currentView = useSelector(x => x.app.currentView)

    const [ShowFormControl, setShowFormControl] = useState(false);
    const [workSpaceId, setWorkSpaceId] = useState("");
    const [minimumEmployee, setMinimumEmployee] = useState("1");
    const [DayDate, setDayDate] = useState(new Date());
    const [StartHours, setStartHours] = useState("9");
    const [StartMin, setStartMin] = useState("0");
    const [EndHours, setEndHours] = useState("14");
    const [EndMin, setEndMin] = useState("0");
    const [Tags, setTags] = useState([]);
    const [Note, setNote] = useState("");
    const [workspaceList, setWorkspaceList] = useState([]);
    const [tagsDD, settagsDD] = useState([]);
    const [AllowToaddBreak, setAllowToaddBreak] = useState(false);

    const [ShowRepetationModel, setShowRepetationModel] = useState(false);
    const [mode, setMode] = useState("");
    // const [days, setdays] = useState("MON,TUE,WED,THU,FRI");

    const [RulesDropdown, setRulesDropdown] = useState("weekly");
    const [monthlyRhythm, setmonthlyRhythm] = useState("DayofMonth");
    const [startDate, setstartDate] = useState(new Date());
    const [interval, setinterval] = useState(1);
    const [activeButtons, setactiveButtons] = useState("MON,TUE,WED,THU,FRI");
    const [summary, setsummary] = useState("Weekly every Monday, Tuesday, Wednesday, Thursday, Friday");
    const [isNeverEnds, setisNeverEnds] = useState(true);
    const [AfterNoofAppointments, setAfterNoofAppointments] = useState(null);
    const [AtTheDate, setAtTheDate] = useState(null);
    const [IsAfterNoofAppointmentsOrAtTheDate, setIsAfterNoofAppointmentsOrAtTheDate] = useState("");

    const [ShowPreviousModel, setShowPreviousModel] = useState(false);
    const [FileName, setFileName] = useState("");
    const [File, setFile] = useState(null);

    const [isHovered, setIsHovered] = useState(false);


    const fileInput = useRef();
    const getFiles = (files) => {
        setFileName(files.name);
        setFile(files.file)
    }
    useEffect(() => {
        setstartDate(DayDate)
    }, [DayDate]);
    const removerepetation = () => {
        setMode("dayclick")
    }
    const CheckPastAndOpenModel = () => {
        var shiftEndTime = moment(new Date(DayDate).toLocaleDateString()).startOf("day").add(parseInt(StartHours), 'hours').add(parseInt(StartMin), 'minutes');
        if (moment().isSameOrAfter(shiftEndTime) && !!props.SelectedEmployeeId) {
            setShowPreviousModel(true)
            return
        } else {
            submit(false)
        }
    }
    const formik = useFormik({
        initialValues: {
            timeTrackingBreaks: [],
        },
    });
    const submit = async (IsAssignmentWithTimetrack) => {
        if (DayDate === null) {
            Swal.fire({
                icon: 'info',
                title: cl("errorMsg.time"),
            })
            return;
        }
        if (minimumEmployee === "" || parseInt(minimumEmployee) < 0) {
            Swal.fire({
                icon: 'info',
                title: cl("errorMsg.numberOfEmployees"),
            })
            return;
        }
        var startDateTime = moment(DayDate).startOf('day')
            .add(parseInt(StartHours), 'hours')
            .add(parseInt(StartMin), 'minutes');

        var endDateTime = moment(DayDate).startOf('day')
            .add(parseInt(EndHours), 'hours')
            .add(parseInt(EndMin), 'minutes');
        if (moment(endDateTime).isSameOrBefore(moment(startDateTime))) {
            toast.error(cl("errorMsg.endTimeGreaterThenStartTime"))
            return;
        }
        var BreaksList = [];
        var TotalBreakTime = 0;
        var startTime = (parseInt(StartHours) * 60) + parseInt(StartMin);
        var endTime = (parseInt(EndHours) * 60) + parseInt(EndMin);
        var TotalWorkingTime = (endTime - startTime);
        if (!!formik.values.timeTrackingBreaks && formik.values.timeTrackingBreaks.length > 0) {
            if (formik.values.timeTrackingBreaks.find(x => x.day.setHours(0, 0, 0, 0) !== DayDate.setHours(0, 0, 0, 0)) === undefined) {
                formik.values.timeTrackingBreaks.map((val, key) => {
                    var Breakstart = (parseInt(val.startTimeHour) * 60) + parseInt(val.startTimeMin);
                    var Breakend = (parseInt(val.endTimeHour) * 60) + parseInt(val.endTimeMin);
                    TotalBreakTime = TotalBreakTime + (Breakend - Breakstart);
                    if (Breakstart < startTime || Breakstart > endTime || Breakend < startTime || Breakend > endTime) {
                        Swal.fire({
                            icon: 'error',
                            title: cl("errorMsg.pauseTime"),
                        })
                        throw ("error", cl("errorMsg.pauseTime"))
                        return
                    }
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: cl("errorMsg.pauseTime"),
                })
                return
            }
            if (TotalWorkingTime < TotalBreakTime) {
                Swal.fire({
                    icon: 'info',
                    title: cl("errorMsg.PauseTimeTrackDuration"),
                })
                return
            }

            formik.values.timeTrackingBreaks.map((val, key) => {
                var breakobj = {
                    day: moment(val.day).startOf('day'),
                    startTime: moment(val.day).startOf('day')
                        .add(parseInt(val.startTimeHour), 'hours')
                        .add(parseInt(val.startTimeMin), 'minutes'),
                    endTime: moment(val.day).startOf('day')
                        .add(parseInt(val.endTimeHour), 'hours')
                        .add(parseInt(val.endTimeMin), 'minutes')
                }
                BreaksList.push(breakobj)
            })
        }
        var data = {
            workSpaceId: workSpaceId,
            minimumEmployee: minimumEmployee,
            DayDate: startDateTime,
            DayDateEnd: endDateTime,
            Tags: Tags,
            Note: Note,
            empidforoccupancy: props.SelectedEmployeeId?.toString(),
            Attachment: [],

            Rule: RulesDropdown,
            monthlyRhythm: monthlyRhythm,
            startDate: startDate,
            interval: parseInt(interval),
            daysOfWeek: activeButtons,
            isNeverEnds: isNeverEnds,
            IsAfterNoofAppointments: IsAfterNoofAppointmentsOrAtTheDate === "afterNapppoinment" ? true : false,
            IsAtTheDate: IsAfterNoofAppointmentsOrAtTheDate === "Atthedate" ? true : false,
            AtTheDate: !!AtTheDate ? AtTheDate : null,
            AfterNoofAppointments: parseInt(AfterNoofAppointments),
            IsAssignmentWithTimetrack: IsAssignmentWithTimetrack,
            ShiftBreaks: BreaksList,
        }

        if (!!AtTheDate) {
            var d = AtTheDate.setHours(new Date().getHours(), new Date().getMinutes());
            AtTheDate.setTime(d);
            data.AtTheDate = AtTheDate;
        }

        if (!!File) {
            var obj = {
                OldAttachmentID: "",
                Attachment: File
            }
            var form_data = new FormData();
            for (var key in obj) {
                if (typeof (obj[key]) === "object" && !!obj[key] && obj[key].length > 0) {
                    for (var i = 0; i < obj[key].length; i++) {
                        form_data.append(key + [], obj[key][i]);
                    }
                } else {
                    form_data.append(key, obj[key]);
                }
            }
            var Attachmentresponse = await CallAxios("/api/Shift/SaveAttachment|post", form_data);
            if (!!Attachmentresponse && Attachmentresponse.status === 200) {
                data.Attachment = [{
                    AttachmentName: File.name,
                    AttachmentKey: Attachmentresponse.data
                }];
            }
        }
        if (mode === "normal") {
            var response = await CallAxios("/api/Shift/addeditShift|post", data);
            if (!!response && response.status === 200) {
                RefreshData()
            }
        }
        else if (mode === "dayclick") {//click from calender
            var response = await CallAxios("/api/Shift/CreateShiftsingle|post", data);
            if (!!response && response.status === 200) {
                RefreshData()

            }
        }
    }
    const DropdownIndicator = () => null;
    useEffect(() => {
        onLoad()
    }, []);
    const onLoad = async () => {
        var response = await AxiosLite("/api/Calendar/GetCalendarSetting|get");
        if (!!response && response.status === 200) {
            setAllowToaddBreak(response.data.activate_shift_related_breaks_in_duty_roster)
        }
        getworkspaces()
        getTagsDD()
        setMode(props.CreateShiftMode)
        if (props.CreateShiftMode === "normal") {
            setstartDate(new Date());
            setsummary("Weekly every Monday, Tuesday, Wednesday, Thursday, Friday");
        }
        else if (props.CreateShiftMode === "dayclick") {
            const newdateparent = new Date(props.DayClickDate);
            setDayDate(newdateparent)
            setstartDate(newdateparent);
            setsummary("Weekly every Monday, Tuesday, Wednesday, Thursday, Friday");
        }
    }
    const getTagsDD = () => {
        settagsDD(appSettings.tags.filter(x => x.value !== "Withouttags"))
    }
    const getworkspaces = async () => {
        if (currentView === Constants.layoutType.Admin) {
            setWorkspaceList(appSettings.WorkSpaces);
            setWorkSpaceId(appSettings.WorkSpaces[0].value)
        } else {
            var response = await CallAxios("/api/Employee/GetEmplyeeWorkSpaceDD|post", { id: user.LoginUserId });
            if (!!response && response.data.length > 0) {
                setWorkspaceList(response.data)
                setWorkSpaceId(response.data[0].value)
            }
        }
    }
    const RefreshData = () => {
        props.GetEmployeeList?.(props.calendar.current.getDate())
        props.UpdateShiftChildTrigger?.()
        props.setCreateShiftMode?.("normal");
        props.triggerChange?.();
        props.onHide?.(false);
    }
    return (
        <>
            {
                ShowPreviousModel === true ?
                    <Modal show={ShowPreviousModel} onHide={() => setShowPreviousModel(false)} >
                        <Modal.Header className="" closeButton >
                            <Modal.Title>{ct("warning")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{cl("assignRemoveEmployeeFromShift")}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="btn btnPrimaryGreen me-3" onClick={() => { submit(false) }}>{cl("assignmentOnly")}</Button>
                            <Button className="btn btnPrimaryGreen" onClick={() => { submit(true) }}>{cl("alsoTimeTracking")}</Button>
                        </Modal.Footer>
                    </Modal>
                    : null
            }
            {
                // props.showRepetation === true ?
                ShowRepetationModel === true ?
                    <RepetitionModal
                        show={ShowRepetationModel}
                        onHide={setShowRepetationModel}
                        // mode={mode}
                        setMode={setMode}
                        RulesDropdown={RulesDropdown}
                        setRulesDropdown={setRulesDropdown}
                        monthlyRhythm={monthlyRhythm}
                        setmonthlyRhythm={setmonthlyRhythm}
                        startDate={startDate}
                        setstartDate={setstartDate}
                        StartHours={StartHours}
                        StartMin={StartMin}
                        EndHours={EndHours}
                        EndMin={EndMin}
                        interval={interval}
                        setinterval={setinterval}
                        activeButtons={activeButtons}
                        setactiveButtons={setactiveButtons}
                        summary={summary}
                        setsummary={setsummary}
                        isNeverEnds={isNeverEnds}
                        setisNeverEnds={setisNeverEnds}
                        AfterNoofAppointments={AfterNoofAppointments}
                        setAfterNoofAppointments={setAfterNoofAppointments}
                        AtTheDate={AtTheDate}
                        setAtTheDate={setAtTheDate}
                        IsAfterNoofAppointmentsOrAtTheDate={IsAfterNoofAppointmentsOrAtTheDate}
                        setIsAfterNoofAppointmentsOrAtTheDate={setIsAfterNoofAppointmentsOrAtTheDate}
                        Schedularid=""
                        shiftidforedit={props.shiftidforedit}
                        RefreshData={RefreshData}
                    />
                    :
                    null
            }
            {/* <Modal show={props.parentThis.state.NewShiftModal} onHide={() => { props.parentThis.handleModalShowHide("NewShiftModal", "normal"); props.parentThis.handleModalFormControl(false) }}> */}
            <Modal show={props.show} onHide={() => { props.setCreateShiftMode?.("normal"); props.onHide(false); }}>
                <Modal.Header closeButton>
                    <Modal.Title>{cl("createShift")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={7} >
                                <Form.Group className="mb-3" controlId="NewShiftForm.SelectWorkspace">
                                    <Form.Label>{cl("workspace")}</Form.Label>
                                    <Form.Control as="select" className="bgPrimaryGreen form-select" defaultValue={workSpaceId} onChange={(e) => setWorkSpaceId(e.target.value)} custom>
                                        {
                                            !!workspaceList && workspaceList.length > 0 ?
                                                workspaceList.map((value, key) => {
                                                    return <option value={value.value}>{value.label}</option>
                                                }) : null
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={5}>
                                <Form.Group className="mb-3" controlId="NewShiftForm.txtMinNumEmployee">
                                    <Form.Label>{cl("minimumEmployees")}</Form.Label>
                                    <Form.Control type="number" defaultValue={minimumEmployee} onChange={(e) => setMinimumEmployee(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="NewShiftForm.txtDay">
                                    <Form.Label>{ct("day")}</Form.Label>
                                    <DatePicker className="form-control" selected={DayDate} onChange={(date) => setDayDate(date)} />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="NewShiftForm.txtStartTime">
                                    <Form.Label>{ct("startTime")}</Form.Label>
                                    <div className="flexInputGroup d-flex align-items-center">
                                        <Form.Control as="select" className="Noarrow bgPrimaryGrey" defaultValue={StartHours} onChange={(e) => setStartHours(e.target.value)} custom>
                                            {
                                                Constants.hoursDropDown.map((val, key) => {
                                                    return <option key={"StartHours" + key} value={val.value}>{val.text}</option>
                                                })
                                            }
                                        </Form.Control>
                                        <span className="seperator mx-3">:</span>
                                        <Form.Control as="select" className="Noarrow bgPrimaryGrey" defaultValue={StartMin} onChange={(e) => setStartMin(e.target.value)} custom>
                                            {
                                                Constants.minutesDropDown.map((val, key) => {
                                                    return <option key={"StartMin" + key} value={val.value}>{val.text}</option>
                                                })
                                            }
                                        </Form.Control>
                                    </div>

                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="NewShiftForm.txtEndTime">
                                    <Form.Label>{ct("endTime")}</Form.Label>
                                    <div className="flexInputGroup d-flex align-items-center">
                                        <Form.Control as="select" className="Noarrow bgPrimaryGrey" defaultValue={EndHours} onChange={(e) => setEndHours(e.target.value)} custom>
                                            {
                                                Constants.hoursDropDown.map((val, key) => {
                                                    return <option key={"EndHours" + key} value={val.value}>{val.text}</option>
                                                })
                                            }
                                        </Form.Control>
                                        <span className="seperator mx-3">:</span>
                                        <Form.Control as="select" className="Noarrow bgPrimaryGrey" defaultValue={EndMin} onChange={(e) => setEndMin(e.target.value)} custom>
                                            {
                                                Constants.minutesDropDown.map((val, key) => {
                                                    return <option key={"EndMin" + key} value={val.value}>{val.text}</option>
                                                })
                                            }
                                        </Form.Control>
                                    </div>
                                </Form.Group>
                            </Col>
                            {
                                props.showRepetation === true ?
                                    <Col md={12}>
                                        <div className="pb-4 border-bottom">
                                            <div>
                                                <label className="form-label">{cl("repetition")}</label>
                                                <div className="flexInputGroup" >
                                                    {
                                                        mode === "normal" ?
                                                            <div>
                                                                <span onMouseEnter={() => setIsHovered(true)}
                                                                    onMouseLeave={() => setIsHovered(false)}
                                                                    style={{ display: 'inline-block', color: '#1b9176', cursor: 'pointer', textDecoration: isHovered ? 'underline' : 'none' }}>
                                                                    <span style={{ paddingRight: '7px' }} className="ActionText" onClick={() => setShowRepetationModel(true)}>{summary}</span>
                                                                    <FaRegTimesCircle fontSize={20} color="#1b9176" className="CloseActionText" onClick={() => removerepetation()} />
                                                                </span>
                                                            </div>
                                                            :
                                                            <div className="ActionText custom-link-style" onClick={() => setShowRepetationModel(true)}>{cl("createRepetition")}</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    : null
                            }
                            {
                                AllowToaddBreak === true ?
                                    <Col md={12}>
                                        <div className=" border-bottom">
                                            <div>
                                                <TimeTrackingBreaksRepeter formik={formik} DayDate={DayDate} StartHours={StartHours} StartMin={StartMin} EndHours={EndHours} EndMin={EndMin} />
                                            </div>
                                        </div>
                                    </Col>
                                    : null
                            }

                            {!ShowFormControl ?
                                <Col md={12}>
                                    <div className="py-4 " onClick={() => setShowFormControl(true)}>
                                        <div>
                                            <div className="flexInputGroup">
                                                <div className="ActionGreyText">{cl("showFields")}  <BsChevronDown className="CloseActionText" /></div>
                                            </div>
                                        </div>
                                    </div>
                                </Col> : null
                            }
                            {ShowFormControl ?
                                <Col md={12}>
                                    <div className="py-4 border-bottom">
                                        <Form.Group controlId="NewShiftForm.txtTags">
                                            <Form.Label>{ct("tags")}</Form.Label>
                                            <CreatableSelect
                                                classNamePrefix="MyReactSelect"
                                                isMulti
                                                isClearable={false}
                                                components={{ DropdownIndicator }}
                                                options={tagsDD}
                                                getOptionValue={(option) => option.value}
                                                styles={{
                                                    indicatorSeparator: base => ({
                                                        ...base,
                                                        display: 'none'
                                                    })
                                                }}
                                                onChange={async (value, action) => {
                                                    var newtag = value.find(x => x.__isNew__ === true);
                                                    if (!!newtag) {
                                                        var data = {
                                                            tagname: newtag.label
                                                        }
                                                        var response = await CallAxios("/api/LocationSetting/addeditTagFromFilter|post", data)
                                                        if (!!response && response.status === 200) {
                                                            var newobj = { value: response.data, label: newtag.label }
                                                            value.splice(value.findIndex(x => x.__isNew__ === true), 1)
                                                            value.push(newobj)
                                                        }
                                                    }
                                                    let stringarr = [];
                                                    for (let i = 0; i < value.length; i++) {
                                                        stringarr.push(value[i].value)
                                                    }
                                                    setTags(stringarr)
                                                }}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="py-4">
                                        <Form.Group controlId="NewShiftForm.txtNote">
                                            <Form.Label>{ct("note")}</Form.Label>
                                            <Form.Control as="textarea" rows={2} defaultValue={Note} onChange={(e) => setNote(e.target.value)} />
                                        </Form.Group>
                                    </div>
                                    <div className="py-4">
                                        <a className="btnUpload position-relative">
                                            <FaUpload className="icon" /> <span className="mx-3">{!!FileName ? FileName : cl("addAttachment")}</span>
                                            <FileBase64
                                                hidden
                                                id='uploadNoteFile'
                                                ref={fileInput}
                                                multiple={false}
                                                onDone={getFiles}
                                            />
                                        </a>
                                    </div>
                                </Col> : null}
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btnPrimaryGreen" onClick={() => { CheckPastAndOpenModel() }} >{ct("finished")}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default CreateShift;


// function RepetitionModal(props) {
//     const intervalText = {
//         daily: <span className="seperator">Day(s)</span>,
//         weekly: <span className="seperator">Week(s)</span>,
//         monthly: <span className="seperator">Month(s)</span>,
//         yearly: <span className="seperator">Year(s)</span>,
//     }

//     const [RulesDropdown, setRulesDropdown] = useState("weekly");
//     const [monthlyRhythm, setmonthlyRhythm] = useState("Weekday");
//     const [startDate, setstartDate] = useState(props.startDate);
//     const [interval, setinterval] = useState(1);
//     const [activeButtons, setactiveButtons] = useState({ Monday: true, Tuesday: true, Wednesday: true, Thursday: true, Friday: true, Saturday: false, Sunday: false });
//     const [summary, setsummary] = useState("Daily");
//     const [isNeverEnds, setisNeverEnds] = useState(true);
//     const [AfterNoofAppointments, setAfterNoofAppointments] = useState(null);
//     const [AtTheDate, setAtTheDate] = useState(null);
//     const [IsAfterNoofAppointmentsOrAtTheDate, setIsAfterNoofAppointmentsOrAtTheDate] = useState("");
//     const [monthlyRythemflag, setMonthlyRythemflag] = useState(true);
//     const [summaryStart, setSummaryStart] = useState("");

//     useEffect(() => {
//         if (!!props.startDate && !!RulesDropdown) {

//             setstartDate(props.startDate)
//             var RulesDropdownval = RulesDropdown;
//             setRulesDropdown("daily");
//             setRulesDropdown(RulesDropdownval);

//         }
//     }, [props.startDate]);


//     const saveDataToBaseModel = () => {
//         props.setRulesDropdown(RulesDropdown);
//         props.setmonthlyRhythm(monthlyRhythm);
//         props.setstartDate(startDate);
//         props.setinterval(interval);
//         let arr = [];
//         Object.entries(activeButtons).map(([key, value]) => value === true ? arr.push(key.toUpperCase().substring(0, 3)) : console.log(key, value));
//         props.setdays(arr.join(','));
//         let summaryText = getNEXTSummary();

//         let concat = summaryStart + '' + summary + '' + summaryText;
//         props.setsummary(concat);
//         props.setisNeverEnds(isNeverEnds);
//         props.setAfterNoofAppointments(AfterNoofAppointments)
//         props.setAtTheDate(AtTheDate)

//         if (IsAfterNoofAppointmentsOrAtTheDate === "afterNapppoinment") {
//             props.setIsAfterNoofAppointments(true)
//             props.setIsAtTheDate(false)
//         }
//         if (IsAfterNoofAppointmentsOrAtTheDate === "Atthedate") {
//             props.setIsAfterNoofAppointments(false)
//             props.setIsAtTheDate(true)
//         }
//         if (IsAfterNoofAppointmentsOrAtTheDate === "") {
//             props.setIsAfterNoofAppointments(false)
//             props.setIsAtTheDate(false)
//         }
//         props.setMode("normal")
//         props.handleModalShowHide(false)
//     }

//     useEffect(() => {
//         setWeeklySummary();
//     }, [activeButtons]);
//     useEffect(() => {
//         setSummeryOnBasisOfRuleDropdown(RulesDropdown);
//     }, [RulesDropdown]);

//     const changingButtonState = (e) => {
//         props.setdays()
//         setactiveButtons({
//             ...activeButtons,
//             [e.target.name]: !activeButtons[e.target.name]
//         })
//     }
//     const getValuesofRules = (e) => {
//         setRulesDropdown(e.target.value);
//         switch (e.target.value) {
//             case "daily":
//                 setsummary(e.target.value)
//                 break;
//             case "yearly":
//                 setsummary(e.target.value)
//                 break;
//             case "monthly":
//                 setMonthlySummary(startDate, monthlyRhythm);
//                 break;
//             case "weekly":
//                 setWeeklySummary();
//                 break;
//             default:
//                 break;
//         }
//     }
//     const setSummeryOnBasisOfRuleDropdown = (value) => {
//         console.log(value)
//         switch (value) {
//             case "daily":
//                 setsummary("")
//                 break;
//             case "yearly":
//                 setsummary("")
//                 break;
//             case "monthly":
//                 setMonthlySummary(startDate, monthlyRhythm);
//                 break;
//             case "weekly":
//                 setWeeklySummary();
//                 break;
//             default:
//                 break;
//         }
//     }
//     useEffect(() => {
//         if (interval) {
//             setInterValSummary(interval)
//         }
//     }, [interval, RulesDropdown]);
//     const setInterValSummary = (num) => {

//         console.log("calling : setInterValSummary")
//         if (Number(num) !== 1) {
//             setSummaryStart(`Every ${getNumberWithOrdinal(interval)} ${getdayConventions(num)}`)
//         } else {
//             setSummaryStart(getdayConventions(num))
//         }
//     }
//     const getdayConventions = (num) => {
//         const obj = {
//             "daily": "day",
//             "weekly": "week",
//             "monthly": "month",
//             "yearly": "year"
//         }
//         if (num > 1) {
//             return obj[RulesDropdown]
//         } else {
//             return capitalizeFirstLetter(RulesDropdown)
//         }
//     }
//     function capitalizeFirstLetter(string) {
//         return string.charAt(0).toUpperCase() + string.slice(1);
//     }
//     const getNumberWithOrdinal = (n) => {
//         var s = ["th", "st", "nd", "rd"],
//             v = n % 100;
//         return n + (s[(v - 20) % 10] || s[v] || s[0]);
//     }
//     const setWeeklySummary = () => {
//         const filteredByValue = Object.fromEntries(
//             Object.entries(activeButtons).filter(([key, value]) => value === true));

//         if (isEmpty(filteredByValue)) {
//             setsummary("")

//         } else {

//             setsummary(" on " + Object.keys(filteredByValue).map((valu) => " " + valu + " "))
//         }
//     }
//     const isEmpty = (obj) => {
//         return Object.keys(obj).length === 0;
//     }
//     const setMonthlySummary = (date, type) => {

//         if (type === 'DayofMonth') {
//             setmonthlyRhythm("DayofMonth");
//             let day = moment(date).format("w");
//             setsummary("Monthly on the " + moment(date).format("Do [day of the month]"));
//             setstartDate(date)
//         }
//         else if (type === 'Weekday') {
//             setWeekdaySummary(startDate)
//         }

//     }
//     const setWeekdaySummary = (date) => {
//         let dayStr = moment(date).format('YYYY-MM-DD');
//         setsummary(test(moment(dayStr)))

//     }
//     function test(mJsDate) {

//         let convection = "";
//         switch (Math.ceil(mJsDate.date() / 7)) {
//             case 1:
//                 convection = "st";
//                 break;
//             case 2:
//                 convection = "nd";
//                 break;
//             case 3:
//                 convection = "rd";
//                 break;

//             default:
//                 convection = "th";
//                 break;
//         }

//         var str = " on the " + Math.ceil(mJsDate.date() / 7) + convection + " " + moment(mJsDate.toLocaleString()).format("dddd")

//         return str;
//     }
//     const updateMonthlyrythm = (date, type) => {

//         if (type === 'DayofMonth') {
//             if (!!date) {
//                 date = startDate
//             }
//             setmonthlyRhythm("DayofMonth");
//             let day = moment(date).format("w");
//             setsummary(" on the " + moment(date).format("Do [day of the month]"));
//             setstartDate(date)
//         }
//         else {
//             setWeekdaySummary(startDate)
//         }
//         if (!!type) {
//             setmonthlyRhythm(type)
//         } else {
//             setmonthlyRhythm(monthlyRhythm)
//         }
//     }
//     const getNEXTSummary = () => {
//         return isNeverEnds === false ? IsAfterNoofAppointmentsOrAtTheDate === "Atthedate" ? !!AtTheDate ? ", till " + moment(AtTheDate).format("DD/MM/YYYY") : "" : !!AfterNoofAppointments ? (", " + AfterNoofAppointments + " times") : "" : ""
//     }

//     return (
//         <Modal show={props.show} onHide={() => { props.handleModalShowHide(false) }}>
//             <Modal.Header closeButton onClick={() => props.handleModalShowHide(false)}>
//                 <Modal.Title>Repetition 2222</Modal.Title>
//             </Modal.Header>
//             <Modal.Body>
//                 <Form>
//                     <Row>
//                         <Col md={12} >
//                             <Form.Group controlId="RepetitionShiftForm.SelectRule">
//                                 <Form.Label>Rule</Form.Label>
//                                 <Form.Control as="select" value={RulesDropdown} onChange={(e) => getValuesofRules(e)} custom>
//                                     <option value="daily">Every day</option>
//                                     <option value="weekly">Weekly</option>
//                                     <option value="monthly">Per month</option>
//                                     <option value="yearly">Yearly</option>
//                                 </Form.Control>
//                             </Form.Group>
//                         </Col>
//                         <Col md={12} >
//                             <Form.Group controlId="RepetitionShiftForm.txtInterval">
//                                 <Form.Label>Interval</Form.Label>
//                                 <div className="flexInputGroup">
//                                     <Form.Control as="select" onChange={(e) => setinterval(e.target.value)} style={{ width: "65px" }} value={interval} custom>
//                                         <option value="1">1</option>
//                                         <option value="2">2</option>
//                                         <option value="3">3</option>
//                                         <option value="4">4</option>
//                                         <option value="5">5</option>
//                                         <option value="6">6</option>
//                                         <option value="7">7</option>
//                                         <option value="8">8</option>
//                                         <option value="9">9</option>
//                                         <option value="10">10</option>
//                                         <option value="11">11</option>
//                                         <option value="12">12</option>
//                                         <option value="13">13</option>
//                                         <option value="14">14</option>
//                                         <option value="15">15</option>
//                                         <option value="16">16</option>
//                                         <option value="17">17</option>
//                                         <option value="18">18</option>
//                                         <option value="19">19</option>
//                                         <option value="20">20</option>
//                                         <option value="21">21</option>
//                                         <option value="22">22</option>
//                                         <option value="23">23</option>
//                                         <option value="24">24</option>
//                                         <option value="25">25</option>
//                                         <option value="26">26</option>
//                                         <option value="27">27</option>
//                                         <option value="28">28</option>
//                                         <option value="29">29</option>
//                                         <option value="30">30</option>
//                                         <option value="31">31</option>
//                                     </Form.Control>
//                                     {/* <Form.Control type="number" onChange={(e) => setinterval(e.target.value)} value={interval} className="smallNumFormControl"/> */}
//                                     {intervalText[RulesDropdown]}
//                                 </div>

//                             </Form.Group>
//                         </Col>
//                         <Col md={12} >
//                             <Form.Group controlId="RepetitionShiftForm.txtStartOn">
//                                 <Form.Label>Starts on</Form.Label>
//                                 <div><DatePicker selected={props.startDate} disabled onChange={(selected) => { RulesDropdown === "monthly" ? setMonthlySummary(selected) : setstartDate(selected) }} className="w-100" /></div>
//                             </Form.Group>
//                         </Col>
//                         {RulesDropdown === "monthly" ?
//                             <Col md={12} >
//                                 <label className="form-label">Monthly rhythm</label>
//                                 <Form.Control as="select" value={monthlyRhythm} onChange={(e) => updateMonthlyrythm(startDate, e.target.value)} custom>
//                                     <option value="Weekday">Weekday</option>
//                                     <option value="DayofMonth">Day of Month</option>
//                                 </Form.Control>
//                             </Col>
//                             : null}
//                         {RulesDropdown === "weekly" ?
//                             <Col md={12}>
//                                 <label className="form-label">Days of the week</label>
//                                 <div>
//                                     <ButtonGroup onClick={(e) => changingButtonState(e)} aria-label="Basic example" size="sm" className="btnSelectGroup">
//                                         <Button name='Monday' variant="secondary" className={activeButtons.Monday === true ? "active" : ""} >Monday</Button>
//                                         <Button name='Tuesday' variant="secondary" className={activeButtons.Tuesday === true ? "active" : ""}>Tuesday</Button>
//                                         <Button name='Wednesday' variant="secondary" className={activeButtons.Wednesday === true ? "active" : ""}>Wednesday</Button>
//                                         <Button name='Thursday' variant="secondary" className={activeButtons.Thursday === true ? "active" : ""}>Thursday</Button>
//                                         <Button name='Friday' variant="secondary" className={activeButtons.Friday === true ? "active" : ""}>Friday</Button>
//                                         <Button name='Saturday' variant="secondary" className={activeButtons.Saturday === true ? "active" : ""}>Saturday</Button>
//                                         <Button name='Sunday' variant="secondary" className={activeButtons.Sunday === true ? "active" : ""}>Sunday</Button>
//                                     </ButtonGroup>
//                                 </div>
//                             </Col>
//                             : null}
//                         <Col md={12} className="pt-3">
//                             <Form.Group controlId="formBasicCheckbox">
//                                 <Form.Label>Ends</Form.Label>
//                                 <Form.Check type="checkbox" label="Never" defaultChecked={isNeverEnds} onClick={() => setisNeverEnds(!isNeverEnds)} />
//                             </Form.Group>
//                         </Col>
//                         {isNeverEnds === false ?
//                             <Col md={12}>
//                                 <div className="check">
//                                     <div className="d-flex align-items-center mb-2">
//                                         <Form.Check
//                                             inline
//                                             label="After"
//                                             type={"radio"}
//                                             name="apppoinmenttime"
//                                             checked={IsAfterNoofAppointmentsOrAtTheDate === "afterNapppoinment" ? true : false}
//                                             id={`inline-radio-3`}
//                                             value="afterNapppoinment"

//                                             onChange={(e) => setIsAfterNoofAppointmentsOrAtTheDate(e.target.value)}

//                                         />
//                                         <Form.Control style={{ minHeight: "32px" }} type="text" onClick={() => setIsAfterNoofAppointmentsOrAtTheDate("afterNapppoinment")} onChange={(e) => setAfterNoofAppointments(e.target.value)} value={AfterNoofAppointments} className="smallNumFormControlss me-2" /> appointments

//                                     </div>
//                                     <Form.Group controlId="RepetitionShiftForm">
//                                         <div className="d-flex align-items-center">
//                                             <Form.Check
//                                                 inline
//                                                 label="At the"
//                                                 name="apppoinmenttime"
//                                                 checked={IsAfterNoofAppointmentsOrAtTheDate === "Atthedate" ? true : false}
//                                                 type={"radio"}
//                                                 id={`inline-radio-2`}
//                                                 value="Atthedate"
//                                                 onChange={(e) => setIsAfterNoofAppointmentsOrAtTheDate(e.target.value)}
//                                             />
//                                             <div>
//                                                 <DatePicker selected={AtTheDate} onFocus={() => setIsAfterNoofAppointmentsOrAtTheDate("Atthedate")} onChange={(selected) => { setAtTheDate(selected) }} className="w-100 shortheight" /></div>
//                                         </div>
//                                     </Form.Group>
//                                 </div>
//                             </Col>
//                             : null}
//                         <Col md={12} className="">
//                             <div className="SummaryText">Summary: {summaryStart} {summary} {isNeverEnds === false ? IsAfterNoofAppointmentsOrAtTheDate === "Atthedate" ? !!AtTheDate ? ", till " + moment(AtTheDate).format("DD/MM/YYYY") : null : !!AfterNoofAppointments ? (", " + AfterNoofAppointments + " times") : "" : null}</div>
//                         </Col>
//                     </Row>
//                 </Form>
//             </Modal.Body>
//             <Modal.Footer>
//                 <Button variant="secondary" onClick={() => props.handleModalShowHide(false)}>To shutdown</Button>
//                 <Button className="btn btnPrimaryGreen" onClick={() => saveDataToBaseModel()}>To save</Button>
//             </Modal.Footer>
//         </Modal>
//     )
// }