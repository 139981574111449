import moment from 'moment';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import { CalendarBadge, DOT } from '../../Helpers/Global/StyledComponents';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { BsFillGearFill } from "react-icons/bs";
import "./Calendarstyle.css";
// import { ApiContext } from '../pages/Contexts/api';
import { setisLoading } from '../../Store/slices/AppSlice';
// import { dynamicPostApi } from '../api';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { CallAxios } from '../../Helpers';
import { PaperCLip } from '../ActionBtns';
import { useTranslation } from "react-i18next";

// const TIMEARRAY = [
//     "00:00", "00:15", "00:30", "00:45", "01:00", "01:15", "01:30", "01:45", "02:00", "02:15", "02:30", "02:45", "03:00", "03:15", "03:30", "03:45", "04:00", "04:15", "04:30", "04:45", "05:00", "05:15", "05:30", "05:45", "06:00", "06:15", "06:30", "06:45", "07:00", "07:15", "07:30", "07:45", "08:00", "08:15", "08:30", "08:45", "09:00", "09:15", "09:30", "09:45", "10:00", "10:15", "10:30", "10:45", "11:00", "11:15", "11:30", "11:45", "12:00", "12:15", "12:30", "12:45", "13:00", "13:15", "13:30", "13:45", "14:00", "14:15", "14:30", "14:45", "15:00", "15:15", "15:30", "15:45", "16:00", "16:15", "16:30", "16:45", "17:00", "17:15", "17:30", "17:45", "18:00", "18:15", "18:30", "18:45", "19:00", "19:15", "19:30", "19:45", "20:00", "20:15", "20:30", "20:45", "21:00", "21:15", "21:30", "21:45", "22:00", "22:15", "22:30", "22:45", "23:00", "23:15", "23:30", "23:45"
// ];

const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
            currentValue
        );
        return result;
    }, {});
};

const CalendarTable = forwardRef((props, ref) => {
    let dispatch = useDispatch();
    moment.updateLocale('en', {
        week: {
            dow: 1, // Monday is the first day of the week.
        }
    });
    const [dailyViewList, setdailyViewList] = useState("");
    const [monthlyListHeader, setmonthlyListHeader] = useState([]);
    const [weekViewHeader, setweekViewHeader] = useState([]);
    const [singleTHHeader, setsingleTHHeader] = useState("");
    const [monthlyListBody, setmonthlyListBody] = useState([]);
    const [currentDate, setCurrentDate] = useState(!!localStorage.getItem("calendar_date") ? new moment(localStorage.getItem("calendar_date")) : new moment());
    const [activeRange, setActiveRange] = useState({ start: "", end: "" });
    const [defaultHeight, setDefaultHeight] = useState(19.5);
    const [left, setLeft] = useState(0);
    const [currentTarget, setCurrentTarget] = useState({ target: null, ParentTD: null, ChildTD: null });
    const [fullWidthTrigger, setFullWidthTrigger] = useState(2);
    const themeColor = useSelector(state => state.app.theme.colors.bg);
    const appSettings = useSelector(state => state.app.appSettings);

    const [timearray, setTIMEARRAY] = useState([]);
    // CALL FUNCTIONS ON COMPONENT DID MOUNT
    useEffect(() => {
        getCurrentDay();
        getActiveDateRange();
        setTIMEARRAY(timelineLabels(props._startTime, props.interval, 'minutes', props._endTime));
    }, []);
    useEffect(() => {
        props.setheight();
    });
    useEffect(() => {
        if (!!activeRange.start && !!activeRange.end) {
            props.datesSet({ ...activeRange, view: props.view })
        }

    }, [activeRange]);


    const timelineLabels = (desiredStartTime, interval, period, DesiredEndTime) => {
        const periodsInADay = moment.duration(1, 'day').as(period);
        const todayDate = moment().format('D-M-yyyy')
        const timeLabels = [];
        const startTimeMoment = moment(desiredStartTime, 'hh:mm');
        let isLessThenEndTime = true;


        for (let i = 0; i <= periodsInADay; i += interval) {
            startTimeMoment.add(i === 0 ? 0 : interval, period);
            if (startTimeMoment.format('D-M-yyyy') === todayDate && isLessThenEndTime === true) {
                timeLabels.push(startTimeMoment.format('HH:mm'));
                if (startTimeMoment.format('HH:mm') === DesiredEndTime) {
                    isLessThenEndTime = false;

                }
            }
        }
        return timeLabels;
    };

    const SaveEventDrag = async (data) => {
        if (!!data.ShiftId) {
            var response = await CallAxios("/api/Shift/UpdateShiftDateTime|post", data);
            if (!!response && response.data === true) {
                props.updateLeftEmployeeList()
            }

        } else {
            alert("ShiftId = " + data.ShiftId)
        }
    }
    const SaveEventSwipe = async (data) => {
        var response = await CallAxios("/api/Shift/SwipeShift|post", data);
        if (!!response && response.status === 200) {
            if (response.data === true) {
                props.updateLeftEmployeeList()
            } else {
                Swal.fire({
                    icon: 'error',
                    title: response.data,
                })
            }
        }
    }
    //Detecting Current Active view date Ranges 
    const getActiveDateRange = (cDate) => {
        let date = !!cDate ? moment(cDate) : currentDate;
        switch (props.view) {
            case "weeklyViewList": case "weeklyViewhoursList": case "resourceViewWeek":
                setActiveRange({ start: new Date(date.clone().startOf('isoWeek')), end: new Date(date.clone().endOf('isoWeek')) })
                break;
            case "dailyViewList": case "dailyViewhoursList": case "resourceViewDay":
                setActiveRange({ start: new Date(date.startOf('day')), end: new Date(date.endOf('day')) })
                break;
            case "resourceViewMonth": case "monthlyList":
                setActiveRange({ start: new Date(date.clone().startOf('month')), end: new Date(date.clone().endOf('month')) })
                break;
        }
    }
    //DETECTING CHANGE IN PROPS    
    useEffect(() => {
        if (!!props.view) {
            getCurrentDay();

        }
    }, [props.view]);
    useEffect(() => {
        if (props.view == "dailyViewhoursList") {
            adjustWidth();
        }
    }, []);

    const getCurrentDay = () => {
        switch (props.view) {
            case "weeklyViewList": case "weeklyViewhoursList":
                getCurrentWeekDates(currentDate);
                break;
            case "dailyViewList": case "dailyViewhoursList":
                setsingleTHHeader(moment(currentDate).format("ddd,M/DD"))
                setdailyViewList(moment(currentDate))
                break;
            case "resourceViewDay":
                setsingleTHHeader(moment(currentDate).format("dddd MMMM DD"))
                setdailyViewList(moment(currentDate))

                break;
            case "resourceViewWeek":
                setsingleTHHeader(moment(currentDate).format("[CW] w"));
                getCurrentWeekDates(currentDate);
                break;
            case "resourceViewMonth":
                setsingleTHHeader(moment().format("MMMM"))
                // getDatesForMonthlyList();

                break;
            case "monthlyList":
                getCurrentWeekDays(currentDate);
                getDatesForMonthlyList(currentDate);
                break;
            default:
                getCurrentWeekDates();
                break;
        }
        getActiveDateRange();
    }
    const PrevOrNextDate = (date) => {
        switch (props.view) {
            case "weeklyViewList": case "weeklyViewhoursList":
                getCurrentWeekDates(date);
                break;
            case "dailyViewList": case "dailyViewhoursList":
                setsingleTHHeader(moment(date).format("ddd,M/DD"))
                setdailyViewList(moment(date))
                break;
            case "resourceViewDay":
                setsingleTHHeader(moment(date).format("dddd MMMM DD"))
                setdailyViewList(moment(date))

                break;
            case "resourceViewWeek":
                setsingleTHHeader(moment(date).format("[CW] w"))
                getCurrentWeekDates(date);
                break;
            case "resourceViewMonth":
                setsingleTHHeader(moment(date).format("MMMM"))
                // getDatesForMonthlyList();

                break;
            case "monthlyList":
                getCurrentWeekDays(date);
                getDatesForMonthlyList(date);
                break;
            default:
                getCurrentWeekDates(date);
                break;
        }
        getActiveDateRange();
    }
    const getCurrentWeekDates = (date) => {
        //weekly header
        var currentDate = !!date ? moment(date) : moment();
        setCurrentDate(currentDate)
        var weekStart = currentDate.clone().startOf('isoWeek');
        var weekEnd = currentDate.clone().endOf('isoWeek');

        var days = [];

        for (var i = 0; i <= 6; i++) {
            days.push({ text: moment(weekStart).add(i, 'days').format("ddd,M/DD"), date: moment(weekStart).add(i, 'days').format() });
        }
        setweekViewHeader(days)
    }
    const getCurrentWeekDays = (date) => {
        // monthlyheader
        var currentDate = !!date ? date : moment();
        setCurrentDate(currentDate)
        var weekStart = moment(currentDate).startOf("week");
        // var weekStart = currentDate.clone().startOf('isoWeek');
        // var weekEnd = currentDate.clone().endOf('isoWeek');

        var days = [];

        for (var i = 0; i <= 6; i++) {
            days.push(moment(weekStart).add(i, 'days').format("ddd"));
        }
        setmonthlyListHeader(days)
    }
    const getDatesForMonthlyList = (dates) => {
        //Month dates
        const calendar = [];
        const today = !!dates ? moment(dates) : moment();
        const startDay = today.clone().startOf('month').startOf('week');
        const endDay = today.clone().endOf('month').endOf('week');

        let date = startDay.clone().subtract(0, 'day');

        while (date.isBefore(endDay, 'day'))
            calendar.push({
                days: Array(7).fill(0).map(() => date.add(1, 'day').clone())
            });

        setmonthlyListBody(calendar)

    }
    const next = () => {

        if (props.view === "weeklyViewList" || props.view === "weeklyViewhoursList" || props.view === "resourceViewWeek") {
            let datehere = currentDate.endOf('week');
            let nextdate = datehere.add(1, 'weeks').endOf('week').format('YYYY-MM-DD');
            PrevOrNextDate(nextdate)
        }
        else if (props.view === "dailyViewList" || props.view === "dailyViewhoursList" || props.view === "resourceViewDay") {
            let datehere = currentDate;
            let nextdate = datehere.add(1, 'days').format('YYYY-MM-DD');
            PrevOrNextDate(nextdate)
        } else {
            let datehere = moment(currentDate);
            let nextmonth = datehere.add(1, 'month');
            PrevOrNextDate(nextmonth)
        }
    }
    const prev = () => {

        if (props.view === "weeklyViewList" || props.view === "weeklyViewhoursList" || props.view === "resourceViewWeek") {
            let datehere = currentDate.endOf('week');
            let prevhere = datehere.subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD');
            PrevOrNextDate(prevhere)
        } else if (props.view === "dailyViewList" || props.view === "dailyViewhoursList" || props.view === "resourceViewDay") {
            let datehere = currentDate;
            let prevhere = datehere.subtract(1, 'days').format('YYYY-MM-DD');
            PrevOrNextDate(prevhere)
        }
        else {
            let datehere = moment(currentDate);
            let prevmonth = datehere.subtract(1, 'month');
            PrevOrNextDate(prevmonth)
        }
    }
    const getDate = () => {
        return currentDate
    }
    const gotoDate = (date) => {
        switch (props.view) {
            case "weeklyViewList":
                getCurrentWeekDates(date);
                break;
            case "dailyViewList": case "dailyViewhoursList":
                setsingleTHHeader(moment(date).format("ddd,M/DD"))
                setdailyViewList(moment(date))
                break;
            case "resourceViewDay":
                setsingleTHHeader(moment(date).format("dddd MMMM DD"))
                break;
            case "resourceViewWeek":
                setsingleTHHeader(moment(date).format("[CW] w"))
                break;
            case "resourceViewMonth":
                setsingleTHHeader(moment(date).format("MMMM"))
                break;
            case "monthlyList":
                getCurrentWeekDays(date);
                getDatesForMonthlyList(date);
                break;
            default:
                getCurrentWeekDates(date);
                break;
        }
        getActiveDateRange(date);
    }

    useImperativeHandle(ref, () => ({
        next,
        prev,
        getDate,
        gotoDate,
        activeRange
    }));

    const checkcollisionTop = (e) => {

        let target = e.target;
        let targetTop = target.getBoundingClientRect().top;
        let targetLeft = e.target.getBoundingClientRect().left;
        let targetBottom = e.target.getBoundingClientRect().bottom;
        let targetRight = e.target.getBoundingClientRect().right;

        target.classList.add("d-none");

        var dropTargetTopLeft = document.elementFromPoint(targetLeft, targetTop);
        var dropTargetBottomLeft = document.elementFromPoint(targetLeft, targetBottom);
        var dropTargetTopRight = document.elementFromPoint(targetRight, targetTop);
        var dropTargetbottomRight = document.elementFromPoint(targetRight, targetBottom);

        target.classList.remove("d-none");

        if (!!dropTargetTopLeft && !!dropTargetBottomLeft && (dropTargetTopLeft.classList.contains("eventwrapper") || dropTargetBottomLeft.classList.contains("eventwrapper"))) {
            return true;

        } else {

            return false;
        }
    }

    useEffect(() => {
        if (left > 0 && !!currentTarget) {
            // loopthroughEachElement(left,currentTarget)
            resetzIndex();
            if (props.disableOverLappingInDailyView === true) {
                adjustWidth()

            } else {

                checkCollideEachElement(left, currentTarget)
            }
        }

    }, [left, currentTarget]);

    useEffect(() => {
        if (props.disableOverLappingInDailyView === true) {
            adjustWidth()
        }
    }, [fullWidthTrigger]);

    const elementsOverlap = (el1, el2) => {

        const domRect1 = el1.getBoundingClientRect();
        const domRect2 = el2.getBoundingClientRect();

        return !(
            domRect1.top > domRect2.bottom ||
            domRect1.right < domRect2.left ||
            domRect1.bottom < domRect2.top ||
            domRect1.left > domRect2.right
        );
    }

    const fullWidthSpecificElement = (element) => {
        let allEvents = [...props.events];

        let shiftid = element.getAttribute("wel-id");
        let index = allEvents.findIndex(x => x.shiftId === shiftid);
        if (index !== null && index !== undefined && index !== -1) {
            allEvents[index].left = 0;
            allEvents[index].right = 0;
            allEvents[index].zIndex = 1;

        }


        props.updateEvents(allEvents)
    }

    const adjustWidth = () => {

        let allEvents = [...props.events];
        let arr = Array.from(document.querySelectorAll(".eventwrapper"));
        let newLeft = 0;
        let count = 0;
        let tdWidth = 0;
        let obj = {};
        for (let i = 0; i < arr.length; i++) {
            const element = arr[i];
            tdWidth = element.parentElement.clientWidth;
            let noOfCollide = 1;
            for (let j = 0; j < arr.length; j++) {
                const element2 = arr[j];
                if (element !== element2) {

                    if (elementsOverlap(element, element2)) {
                        let elementID = element.getAttribute("wel-id");
                        let element2ID = element2.getAttribute("wel-id");
                        Object.assign(obj, { [elementID]: noOfCollide })
                        count++;
                        noOfCollide++;
                    } else {
                        fullWidthSpecificElement(element)
                    }

                }

            }


        }
        if (obj) {

            let arr = Object.keys(obj);
            let left = 0;
            let right = 0;
            for (let i = 0; i < arr.length; i++) {
                const element = arr[i];

                let index = allEvents.findIndex(x => x.shiftId === element);
                if (index !== null && index !== undefined && index !== -1) {
                    allEvents[index].left = left < tdWidth ? left : 0;
                    if (allEvents[index].left === 0) {
                        left = 0
                    }
                    // if(i === arr.length){
                    //     allEvents[index].right = tdWidth / (obj[element] + 1) * ((obj[element] + 1) - 1);

                    // } else {

                    //     allEvents[index].right = 0;
                    // }
                    // allEvents[index].zIndex = 1;
                    // // allEvents[index].right = i === (arr.length - 1) ? 0 : (obj[element] === 1 ?  tdWidth / (obj[element] + 1) : tdWidth / obj[element]);
                    // allEvents[index].right =(tdWidth / (obj[element] + 2)) * (arr.length - (i+1));
                    // // left += obj[element] === 1 ?  tdWidth / (obj[element] + 1) : tdWidth / obj[element];
                    // left += tdWidth / (obj[element] + 2) + left;
                    left += tdWidth / (obj[element] + 1);



                }
            }
            props.updateEvents(allEvents)

        }
        // if(count > 0 ){
        //     let percentage = 100/count / 2;
        //     let right = 0;
        //     // right + (tdWidth / 100) * percentage
        // }
    }

    const checkCollideEachElement = (left) => {

        let allEvents = [...props.events];
        let newLeft = 0;
        let arr = Array.from(document.querySelectorAll(".eventwrapper"));
        let newRight = 0;

        for (let i = 0; i < arr.length; i++) {
            const element = arr[i];
            let shiftid = element.getAttribute("wel-id");

            let index = allEvents.findIndex(x => x.shiftId === shiftid);
            let target = { target: element }
            if (checkcollisionTop(target)) {

                if (index !== null && index !== undefined && index !== -1) {
                    allEvents[index].left = element.clientWidth / (arr.length);
                    allEvents[index].right = newRight;
                    allEvents[index].zIndex = i + 1;
                    newLeft += left;
                    newRight += 10;

                }
            }
        }
        props.updateEvents(allEvents)

    }
    const resetzIndex = () => {

        let allEvents = [...props.events];
        let arr = Array.from(document.querySelectorAll(".eventwrapper"));

        for (let i = 0; i < arr.length; i++) {
            const element = arr[i];
            let shiftid = element.getAttribute("wel-id");
            let index = allEvents.findIndex(x => x.shiftId === shiftid);
            if (index !== null && index !== undefined && index !== -1) {

                allEvents[index].zIndex = 1;


            }
        }
        props.updateEvents(allEvents)
    }
    // const loopthroughEachElement = (left,childrenDrop) => {
    //     

    //         let allEvents = [...props.events];
    //     if(childrenDrop.ChildTD === childrenDrop.ParentTD){
    //         let newLeft = 0;

    //         let arr = Array.from(childrenDrop.ChildTD.children);
    //         for (let i = 0; i < arr.length; i++) {
    //             const element = arr[i];
    //             let shiftid = element.getAttribute("wel-id");

    //             let index = allEvents.findIndex(x => x.shiftId === shiftid);
    //             if (index !== null && index !== undefined && index !== -1) {
    //                 allEvents[index].left = newLeft;
    //                 allEvents[index].zIndex = i + 1;
    //                 newLeft += left;

    //             }
    //         }

    //     } else {
    //         let arr = Array.from(childrenDrop.ParentTD.children);
    //         let childLength = Array.from(childrenDrop.ChildTD.children);
    //         let newLeft = 0;

    //         for (let i = 0; i < childLength.length; i++) {
    //             // const element = document.querySelector(`.eventwrapper[wel-id='${childrenDrop.target.getAttribute("wel-id")}']`) ;
    //             const element = childLength[i] ;
    //             let shiftid = element.getAttribute("wel-id");

    //             let index = allEvents.findIndex(x => x.shiftId === shiftid);
    //             if (index !== null && index !== undefined && index !== -1) {
    //                 allEvents[index].left = element.clientWidth / (arr.length + childLength.length);
    //                 allEvents[index].zIndex = i + 1;

    //                 newLeft += left;

    //             }
    //         }

    //     }

    //     props.updateEvents(allEvents)


    // }
    const HideEventElements = (Ishide) => {
        let allelements = Array.from(document.querySelectorAll(".eventwrapper"));
        for (let i = 0; i < allelements.length; i++) {
            const element = allelements[i];
            if (Ishide) {
                element.classList.add("d-none")
            } else {
                element.classList.remove("d-none")
            }
        }
    }
    const calculateHours = (start, end) => {

        var startTime = moment(start, "HH:mm");
        var endTime = moment(end, "HH:mm");
        var eng = endTime.diff(startTime, 'hours');
        return eng;
    }
    //dailyViewList HTML COMPONENT 

    const RenderdailyViewList = (events, dailyViewList, _showTitle) => {
        let GroupedEvents = groupBy(events, 'title');

        return (
            <>
                {events.length > 0 ?

                    !!props._compactCalendar && props._compactCalendar === true ?

                        <tr>
                            <td className="eventContainer dailyViewList" onClick={(e) => props.dateClick(e, dailyViewList)}>
                                {!!events && events.map((value, index) =>
                                    moment(dailyViewList).format().split("T")[0] === moment(value.date).format().split("T")[0] ?
                                        props.EventsTemplate(value, index)
                                        : null
                                )}
                            </td>
                        </tr>

                        :

                        Object.keys(GroupedEvents).map(x =>
                            <>
                                {_showTitle === true ?
                                    <tr className='showTitleRow dailyViewList'>
                                        <td className=' titleTd'>{x}</td>
                                    </tr> : null
                                }
                                <tr>
                                    <td className="eventContainer dailyViewList" onClick={(e) => props.dateClick(e, dailyViewList)}>
                                        {GroupedEvents[x].map((value, index) =>
                                            moment(dailyViewList).format().split("T")[0] === moment(value.date).format().split("T")[0] ?
                                                props.EventsTemplate(value, index)
                                                : null
                                        )}
                                    </td>
                                </tr>
                            </>
                        )


                    : <tr>
                        <td className="eventContainer dailyViewList" onClick={(e) => props.dateClick(e, dailyViewList)}>
                        </td>
                    </tr>}
            </>

        )
    }
    //dailyViewhoursList HTML COMPONENT 

    const RenderdailyViewhoursList = (events, dailyViewList, TIMEARRAY) => {

        var dragSrcEl = null;
        var cOffX = 0;
        var cOffY = 0;
        let defaulttop = 0;
        var startY, startHeight, CurrentEle;
        let resizeval = 0;


        const handleDragStart = (e) => {

            dragSrcEl = e.target;
            dragSrcEl.style.top = 0

            defaulttop = dragSrcEl.offsetTop
            cOffY = e.clientY - dragSrcEl.offsetTop;
            e.target.style.zIndex = 100;



            var crt = dragSrcEl.cloneNode(true);
            crt.style.backgroundColor = "red";
            e.dataTransfer.setDragImage(crt, 0, 0);

            e.dataTransfer.effectAllowed = 'move';
            e.dataTransfer.setData('text/html', e.target.outerHTML);
        }

        const dragMove = (e) => {

            e = e || window.event;
            console.log(e.clientY);
            e.preventDefault();
            dragSrcEl.style.top = (e.clientY - cOffY).toString() + 'px';
        };
        const handleDragOver = (e) => {
            if (e.preventDefault) {
                e.preventDefault();
            }

            e.dataTransfer.dropEffect = 'move';

            return false;
        }

        const handleDrop = (e) => {

            if (e.stopPropagation) {
                e.stopPropagation();
            }

            let targetTop = e.target.getBoundingClientRect().top;
            let targetLeft = e.target.getBoundingClientRect().left;
            let targetBottom = e.target.getBoundingClientRect().bottom;
            HideEventElements(true);
            var dropTargetBelow = document.elementFromPoint(targetLeft, targetTop);
            var dropTargetbottom = document.elementFromPoint(targetLeft, targetBottom);
            HideEventElements(false);


            if (e.target.classList.contains("addDND")) {

            } else {
                if (!!dropTargetBelow && !!dropTargetbottom && dropTargetBelow.classList.contains("addDND")) {
                    if (dragSrcEl != e.currentTarget) {
                        if (!!dropTargetBelow && !!dropTargetbottom) {
                            let timeintervalTop = dropTargetBelow.parentElement.getAttribute("time-interval");
                            let timeintervalBottom = dropTargetbottom.parentElement.getAttribute("time-interval");
                            let TimeText = timeintervalTop + " - " + timeintervalBottom;
                            ReRenderEvents(TimeText, e)
                            var data = {
                                ShiftId: e.target.getAttribute("wel-id"),
                                StartdateTime: moment(e.target.attributes.date.value).startOf('day').add(TimeText.split("-")[0].split(":")[0], 'hour').add(TimeText.split("-")[0].split(":")[1], 'minute'),
                                EnddateTime: moment(e.target.attributes.date.value).startOf('day').add(TimeText.split("-")[1].split(":")[0], 'hour').add(TimeText.split("-")[1].split(":")[1], 'minute')
                            }
                            SaveEventDrag(data)
                        }

                    }
                } else {
                    e.target.style.top = defaulttop.toString() + 'px';
                }

            }

            if (props.disableOverLappingInDailyView === true) {
                fullWidthAllElement(e)

            } else {
                if (checkcollisionTop(e)) {
                    adjustWidthsOfEvents(e)
                } else {
                    fullWidthAllElement(e)
                }
            }


            return false;
        }

        const ReRenderEvents = (text, e) => {

            let shiftid = e.target.getAttribute("wel-id");
            let allEvents = [...events];
            let index = allEvents.findIndex(x => x.shiftId === shiftid);
            if (index !== null && index !== undefined && index !== -1) {
                allEvents[index].time = text;
                props.updateEvents(allEvents)
            }
        }
        const ResizeMouseDown = (e) => {
            e.preventDefault();
            e.stopPropagation();

            CurrentEle = e.target.parentElement;
            startY = e.clientY;
            startHeight = parseInt(document.defaultView.getComputedStyle(CurrentEle).height, 10);
            document.addEventListener('mousemove', ResizeMouseMove, false);
            document.addEventListener('mouseup', ResizeMouseUp, false);
        }

        const ResizeMouseMove = (e) => {

            CurrentEle.style.height = (startHeight + e.clientY - startY) + 'px';
            resizeval = startHeight + e.clientY - startY
        }

        const ResizeMouseUp = (e) => {

            let shiftid = CurrentEle.getAttribute("wel-id");
            let allEvents = [...events];
            let index = allEvents.findIndex(x => x.shiftId === shiftid);
            if (index !== null && index !== undefined && index !== -1) {
                allEvents[index].height = resizeval;
                if (getTopAndBottomtdcoords(CurrentEle)) {
                    allEvents[index].time = getTopAndBottomtdcoords(CurrentEle);
                    var time = getTopAndBottomtdcoords(CurrentEle);
                    var data = {
                        ShiftId: shiftid,
                        StartdateTime: moment(CurrentEle.getAttribute("date")).startOf('day').add(time.split("-")[0].split(":")[0].trim(), 'hour').add(time.split("-")[0].split(":")[1].trim(), 'minute'),
                        EnddateTime: moment(CurrentEle.getAttribute("date")).startOf('day').add(time.split("-")[1].split(":")[0].trim(), 'hour').add(time.split("-")[1].split(":")[1].trim(), 'minute')
                    }
                    SaveEventDrag(data)
                }
                props.updateEvents(allEvents)
            }


            document.removeEventListener('mousemove', ResizeMouseMove, false); document.removeEventListener('mouseup', ResizeMouseUp, false);
        }

        const getTopAndBottomtdcoords = (target) => {
            let targetTop = target.getBoundingClientRect().top;
            let targetLeft = target.getBoundingClientRect().left;
            let targetBottom = target.getBoundingClientRect().bottom;
            HideEventElements(true);
            var dropTargettop = document.elementFromPoint(targetLeft, targetTop);
            var dropTargetbottom = document.elementFromPoint(targetLeft, targetBottom);
            HideEventElements(false);
            if (!!dropTargettop && !!dropTargetbottom) {
                let timeintervalTop = dropTargettop.parentElement.getAttribute("time-interval");
                let timeintervalBottom = dropTargetbottom.parentElement.getAttribute("time-interval");
                let TimeText = timeintervalTop + " - " + timeintervalBottom;
                return TimeText
            } else {
                return false
            }

        }

        const adjustWidthsOfEvents = (e) => {

            let target = e.target;
            let targetTop = target.getBoundingClientRect().top;
            let targetLeft = target.getBoundingClientRect().left;
            let targetBottom = e.target.getBoundingClientRect().bottom;
            target.classList.add("d-none");
            var EventTarget = document.elementFromPoint(targetLeft, targetTop);
            var dropTargetbottom = document.elementFromPoint(targetLeft, targetBottom);

            target.classList.remove("d-none");
            if (EventTarget.classList.contains("eventwrapper") || dropTargetbottom.classList.contains("eventwrapper")) {

                HideEventElements(true);
                var onDropCheckChildElements = document.elementFromPoint(targetLeft, targetTop);
                HideEventElements(false);

                if (onDropCheckChildElements.classList.contains("addDND")) {

                    let parentChildCount = EventTarget.parentElement.childElementCount;
                    let currentChildCount = onDropCheckChildElements.childElementCount === 0 ? onDropCheckChildElements.childElementCount + 1 : onDropCheckChildElements.childElementCount;
                    let left = onDropCheckChildElements.clientWidth / (parentChildCount + currentChildCount);
                    setLeft(left);

                    let tagers = { target: target, ParentTD: EventTarget.parentElement, ChildTD: onDropCheckChildElements };
                    setCurrentTarget(tagers);

                    // loopthroughEachElement(left, target,onDropCheckChildElements,EventTarget)
                }
            }

        }
        const fullWidthAllElement = (e) => {
            let allelements = Array.from(document.querySelectorAll(".eventwrapper"));
            let allEvents = [...props.events];

            for (let i = 0; i < allelements.length; i++) {
                const element = allelements[i];
                let target = { target: element }
                if (!checkcollisionTop(target)) {
                    let shiftid = element.getAttribute("wel-id");
                    let index = allEvents.findIndex(x => x.shiftId === shiftid);
                    if (index !== null && index !== undefined && index !== -1) {
                        allEvents[index].left = 0;
                        allEvents[index].right = 0;
                        allEvents[index].zIndex = 1;

                    }
                }
            }
            props.updateEvents(allEvents)
            setFullWidthTrigger(Math.random() * 100)
        }

        return (
            <tr>
                <td className="eventContainer dailyViewhoursList">
                    <div className='fullCustomcalendar-slats'>
                        <table className='fullCustomcalendar-table'>
                            <tbody>
                                {TIMEARRAY.map((x, i) => {

                                    if (props.interval === 15) {
                                        return <>
                                            <tr time-interval={x}>
                                                <td className='fc-axis fc-time fc-widget-content' style={{ width: "25px" }}><span>{x.split(":")[1] !== "15" && x.split(":")[1] !== "30" && x.split(":")[1] !== "45" ? x.split(":")[0] : ""}</span></td>
                                                <td onDrop={handleDrop} onDrag={dragMove} onDragOver={handleDragOver} onDragStart={handleDragStart} className='fc-widget-content addDND' onClick={(e) => props.dateClick(e, dailyViewList)}>
                                                    {!!events && events.map((value, index) => {

                                                        if ((moment(dailyViewList).format().split("T")[0] === moment(value.date).format().split("T")[0]) && (x === value.time.split("-")[0].trim())) {
                                                            let heightDiff = calculateHours(value.time.split("-")[0], value.time.split("-")[1]) * defaultHeight;
                                                            let height = !!value.height ? value.height : heightDiff * (60 / props.interval);
                                                            return moment(dailyViewList).format().split("T")[0] === moment(value.date).format().split("T")[0] ?
                                                                props.EventsTemplate(value, index, height, ResizeMouseDown)
                                                                : null
                                                        }
                                                    }

                                                    )}
                                                </td>
                                            </tr>


                                        </>
                                    } else if (props.interval === 30) {
                                        return i % 2 == 0 ?
                                            <>
                                                <tr time-interval={x}>
                                                    <td className='fc-axis fc-time fc-widget-content' style={{ width: "25px" }}><span>{x.split(":")[1] !== "15" && x.split(":")[1] !== "30" && x.split(":")[1] !== "45" ? x.split(":")[0] : ""}</span></td>
                                                    <td onDrop={handleDrop} onDrag={dragMove} onDragOver={handleDragOver} onDragStart={handleDragStart} className='fc-widget-content addDND' onClick={(e) => props.dateClick(e, dailyViewList)}>
                                                        {!!events && events.map((value, index) => {

                                                            if ((moment(dailyViewList).format().split("T")[0] === moment(value.date).format().split("T")[0]) && (x === value.time.split("-")[0].trim())) {
                                                                let heightDiff = calculateHours(value.time.split("-")[0], value.time.split("-")[1]) * defaultHeight;
                                                                let height = !!value.height ? value.height : heightDiff * (60 / props.interval);
                                                                return moment(dailyViewList).format().split("T")[0] === moment(value.date).format().split("T")[0] ?
                                                                    props.EventsTemplate(value, index, height, ResizeMouseDown)
                                                                    : null
                                                            }
                                                        }

                                                        )}
                                                    </td>
                                                </tr>


                                            </>
                                            : null
                                    } else {
                                        return i % 4 == 0 ?
                                            <>
                                                <tr time-interval={x}>
                                                    <td className='fc-axis fc-time fc-widget-content' style={{ width: "25px" }}><span>{x.split(":")[1] !== "15" && x.split(":")[1] !== "30" && x.split(":")[1] !== "45" ? x.split(":")[0] : ""}</span></td>
                                                    <td onDrop={handleDrop} onDrag={dragMove} onDragOver={handleDragOver} onDragStart={handleDragStart} className='fc-widget-content addDND' onClick={(e) => props.dateClick(e, dailyViewList)}>
                                                        {!!events && events.map((value, index) => {

                                                            if ((moment(dailyViewList).format().split("T")[0] === moment(value.date).format().split("T")[0]) && (x.split(":")[0] === value.time.split("-")[0].split(":")[0].trim())) {
                                                                let heightDiff = calculateHours(value.time.split("-")[0], value.time.split("-")[1]) * defaultHeight;
                                                                let height = !!value.height ? value.height : heightDiff * (60 / props.interval);
                                                                return moment(dailyViewList).format().split("T")[0] === moment(value.date).format().split("T")[0] ?
                                                                    props.EventsTemplate(value, index, height, ResizeMouseDown)
                                                                    : null
                                                            }
                                                        }

                                                        )}
                                                    </td>
                                                </tr>


                                            </>
                                            : null

                                    }



                                })
                                }


                            </tbody>
                        </table>
                    </div>

                </td>
            </tr>
        )
    }
    //weeklyViewList HTML COMPONENT 

    const RenderweeklyViewList = (weekViewHeader, events, _showTitle, setheight) => {
        let GroupedEvents = groupBy(events, 'title');



        return (
            <>
                {
                    events.length > 0 ?

                        !!props._compactCalendar && props._compactCalendar === true ?

                            <tr className='RowRef cheking'>
                                {weekViewHeader.map((week) =>
                                    <td className="eventContainer weeklyViewList showBOx" onClick={(e) => props.dateClick(e, week.date)} weekDate={moment(week.date).format().split("T")[0]}>
                                        {!!events && events.map((value, index) =>
                                            moment(week.date).format().split("T")[0] === moment(value.date).format().split("T")[0] ?
                                                props.EventsTemplate(value, index)
                                                : null
                                            //     (index === 0 ?  <div className='HoverBoxforAddingDiv'>
                                            //        <span>+</span>
                                            //    </div> : null)
                                        )}
                                    </td>

                                )}
                            </tr>

                            :
                            !!props._oneRowPerSeries && props._oneRowPerSeries === true ?
                                Object.keys(GroupedEvents).map(x =>
                                    <>

                                        {_showTitle === true ?
                                            <tr className='showTitleRow weeklyViewList'>
                                                <td className='titleTd'>{x}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            : null}
                                        {GroupedEvents[x].map((value, index) =>
                                            <tr>
                                                {weekViewHeader.map((week) =>
                                                    <td className="eventContainer weeklyViewList" onClick={(e) => props.dateClick(e, week.date)} weekDate={moment(week.date).format().split("T")[0]}>

                                                        {moment(week.date).format().split("T")[0] === moment(value.date).format().split("T")[0] ?
                                                            props.EventsTemplate(value, index)
                                                            : null}

                                                    </td>

                                                )}
                                            </tr>
                                        )}
                                    </>
                                )
                                : Object.keys(GroupedEvents).map((x, z) =>
                                    <>

                                        {_showTitle === true ?
                                            <tr className='showTitleRow weeklyViewList'>
                                                <td className='titleTd'>{x}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            : null}
                                        <tr className='RowRef RowRefss'>
                                            {weekViewHeader.map((week) =>
                                                <td className="eventContainer weeklyViewList showBOx" onClick={(e) => props.dateClick(e, week.date)} weekDate={moment(week.date).format().split("T")[0]}>
                                                    {GroupedEvents[x].map((value, index) =>
                                                        moment(week.date).format().split("T")[0] === moment(value.date).format().split("T")[0] ?
                                                            props.EventsTemplate(value, index)
                                                            : null
                                                        //        (index === 0 ?  <div className='HoverBoxforAddingDiv'>
                                                        //        <span>+</span>
                                                        //    </div> : null)
                                                    )}
                                                </td>

                                            )}
                                        </tr>
                                    </>
                                )
                        :
                        <tr>
                            {weekViewHeader.map((week) =>
                                <td className="eventContainer weeklyViewList showBOx" onClick={(e) => props.dateClick(e, week.date)} weekDate={moment(week.date).format().split("T")[0]}>
                                    <div className='HoverBoxforAddingDiv'>
                                        <span>+</span>
                                    </div>
                                </td>

                            )}
                        </tr>

                }
            </>
        )
    }
    //weeklyViewhoursList HTML COMPONENT 

    const RenderweeklyViewhoursList = (weekViewHeader, events, timearr) => {
        var dragSrcEl = null;
        var cOffX = 0;
        var cOffY = 0;
        let defaulttop = 0;
        let defaultleft = 0;
        var startY, startHeight, CurrentEle;
        let resizeval = 0;

        const ResizeMouseDown = (e) => {
            e.preventDefault();
            e.stopPropagation();

            CurrentEle = e.target.parentElement;
            startY = e.clientY;
            startHeight = parseInt(document.defaultView.getComputedStyle(CurrentEle).height, 10);
            document.addEventListener('mousemove', ResizeMouseMove, false);
            document.addEventListener('mouseup', ResizeMouseUp, false);
        }

        const ResizeMouseMove = (e) => {
            CurrentEle.style.height = (startHeight + e.clientY - startY) + 'px';
            resizeval = startHeight + e.clientY - startY
        }

        const ResizeMouseUp = (e) => {
            let shiftid = CurrentEle.getAttribute("wel-id");
            let allEvents = [...events];
            let index = allEvents.findIndex(x => x.shiftId === shiftid);
            if (index !== null && index !== undefined && index !== -1) {
                allEvents[index].height = resizeval;
                if (getTopAndBottomtdcoords(CurrentEle)) {

                    allEvents[index].time = getTopAndBottomtdcoords(CurrentEle);
                    var time = getTopAndBottomtdcoords(CurrentEle);
                    var data = {
                        ShiftId: shiftid,
                        StartdateTime: moment(CurrentEle.getAttribute("date")).startOf('day').add(time.split("-")[0].split(":")[0].trim(), 'hour').add(time.split("-")[0].split(":")[1].trim(), 'minute'),
                        EnddateTime: moment(CurrentEle.getAttribute("date")).startOf('day').add(time.split("-")[1].split(":")[0].trim(), 'hour').add(time.split("-")[1].split(":")[1].trim(), 'minute')
                    }
                    SaveEventDrag(data)
                }
                props.updateEvents(allEvents)
            }

            document.removeEventListener('mousemove', ResizeMouseMove, false); document.removeEventListener('mouseup', ResizeMouseUp, false);
        }

        const getTopAndBottomtdcoords = (target) => {
            let targetTop = target.getBoundingClientRect().top;
            let targetLeft = target.getBoundingClientRect().left;
            let targetBottom = target.getBoundingClientRect().bottom;
            HideEventElements(true);
            var dropTargettop = document.elementFromPoint(targetLeft, targetTop);
            var dropTargetbottom = document.elementFromPoint(targetLeft, targetBottom);
            HideEventElements(false);
            if (!!dropTargettop && !!dropTargetbottom) {
                let timeintervalTop = dropTargettop.parentElement.getAttribute("time-interval");
                let timeintervalBottom = dropTargetbottom.parentElement.getAttribute("time-interval");
                let TimeText = timeintervalTop + " - " + timeintervalBottom;
                return TimeText
            } else {
                return false
            }

        }

        const handleDragStart = (e) => {

            dragSrcEl = e.target;
            dragSrcEl.style.top = 0
            // dragSrcEl.style.left = 0

            defaulttop = dragSrcEl.offsetTop;
            defaultleft = dragSrcEl.offsetLeft;
            cOffY = e.clientY - dragSrcEl.offsetTop;
            cOffX = e.clientX - dragSrcEl.offsetLeft;
            dragSrcEl.style.width = dragSrcEl.offsetLeft == 0 ? document.querySelector(".eventContainer.weeklyViewhoursList.fc-widget-content.addDND").clientWidth - 20 + "px" : dragSrcEl.offsetLeft + "px";
            e.target.style.zIndex = 100;



            var crt = dragSrcEl.cloneNode(true);
            crt.style.backgroundColor = "red";
            e.dataTransfer.setDragImage(crt, 0, 0);

            e.dataTransfer.effectAllowed = 'move';
            e.dataTransfer.setData('text/html', e.target.outerHTML);
        }

        const dragMove = (e) => {

            e = e || window.event;
            e.preventDefault();
            dragSrcEl.style.top = (e.clientY - cOffY).toString() + 'px';
            dragSrcEl.style.left = (e.clientX - cOffX).toString() + 'px';

            let val = e.clientX - cOffX;
            // dragSrcEl.style.right = val > 0 ? -Math.abs(val).toString() + 'px': val.toString() + 'px';
        };
        const handleDragOver = (e) => {
            if (e.preventDefault) {
                e.preventDefault();
            }

            e.dataTransfer.dropEffect = 'move';

            return false;
        }

        const handleDrop = async (e) => {
            if (e.stopPropagation) {
                e.stopPropagation();
            }


            let targetTop = e.target.getBoundingClientRect().top;
            let targetLeft = e.target.getBoundingClientRect().left;
            let targetBottom = e.target.getBoundingClientRect().bottom;
            HideEventElements(true);
            var dropTargetBelow = document.elementFromPoint(targetLeft, targetTop);
            var dropTargetbottom = document.elementFromPoint(targetLeft, targetBottom);
            HideEventElements(false);

            if (e.target.classList.contains("addDND")) {

            } else {
                if (!!dropTargetBelow && !!dropTargetbottom && dropTargetBelow.classList.contains("addDND")) {
                    if (dragSrcEl != e.currentTarget) {
                        if (!!dropTargetBelow && !!dropTargetbottom) {
                            let timeintervalTop = dropTargetBelow.parentElement.getAttribute("time-interval");
                            let IntervalWeekDate = dropTargetBelow.getAttribute("weekdate");
                            let timeintervalBottom = dropTargetbottom.parentElement.getAttribute("time-interval");
                            let TimeText = timeintervalTop + " - " + timeintervalBottom;
                            ReRenderEvents(TimeText, e, IntervalWeekDate)
                            var data = {
                                ShiftId: e.target.getAttribute("wel-id"),
                                StartdateTime: moment(IntervalWeekDate).startOf('day').add(timeintervalTop.split(":")[0], 'hour').add(timeintervalTop.split(":")[1], 'minute'),
                                EnddateTime: moment(IntervalWeekDate).startOf('day').add(timeintervalBottom.split(":")[0], 'hour').add(timeintervalBottom.split(":")[1], 'minute')
                            }
                            SaveEventDrag(data)
                        }

                    }
                } else {
                    e.target.style.top = defaulttop.toString() + 'px';
                }

            }

            if (props.disableOverLappingInDailyView === true) {
                fullWidthAllElement(e)

            } else {
                if (checkcollisionTop(e)) {
                    adjustWidthsOfEvents(e)
                } else {
                    fullWidthAllElement(e)
                }
            }

            dragSrcEl.style.width = "";

            return false;
        }

        const ReRenderEvents = (text, e, IntervalWeekDate) => {

            let shiftid = e.target.getAttribute("wel-id");
            let allEvents = [...events];
            let index = allEvents.findIndex(x => x.shiftId === shiftid);
            if (index !== null && index !== undefined && index !== -1) {
                allEvents[index].time = text;
                if (IntervalWeekDate) {
                    allEvents[index].date = IntervalWeekDate + "T" + allEvents[index].date.split("T")[1];
                }
                props.updateEvents(allEvents)
            }
        }
        const fullWidthAllElement = (e) => {
            let allelements = Array.from(document.querySelectorAll(".eventwrapper"));
            let allEvents = [...props.events];

            for (let i = 0; i < allelements.length; i++) {
                const element = allelements[i];
                let target = { target: element }
                if (!checkcollisionTop(target)) {
                    let shiftid = element.getAttribute("wel-id");
                    let index = allEvents.findIndex(x => x.shiftId === shiftid);
                    if (index !== null && index !== undefined && index !== -1) {
                        allEvents[index].left = 0;
                        allEvents[index].right = 0;
                        allEvents[index].zIndex = 1;

                    }
                }
            }
            props.updateEvents(allEvents)
            setFullWidthTrigger(Math.random() * 100)
        }
        const adjustWidthsOfEvents = (e) => {

            let target = e.target;
            let targetTop = target.getBoundingClientRect().top;
            let targetLeft = target.getBoundingClientRect().left;
            let targetBottom = e.target.getBoundingClientRect().bottom;
            target.classList.add("d-none");
            var EventTarget = document.elementFromPoint(targetLeft, targetTop);
            var dropTargetbottom = document.elementFromPoint(targetLeft, targetBottom);

            target.classList.remove("d-none");
            if (EventTarget.classList.contains("eventwrapper") || dropTargetbottom.classList.contains("eventwrapper")) {

                HideEventElements(true);
                var onDropCheckChildElements = document.elementFromPoint(targetLeft, targetTop);
                HideEventElements(false);

                if (onDropCheckChildElements.classList.contains("addDND")) {

                    let parentChildCount = EventTarget.parentElement.childElementCount;
                    let currentChildCount = onDropCheckChildElements.childElementCount === 0 ? onDropCheckChildElements.childElementCount + 1 : onDropCheckChildElements.childElementCount;
                    let left = onDropCheckChildElements.clientWidth / (parentChildCount + currentChildCount);
                    setLeft(left);

                    let tagers = { target: target, ParentTD: EventTarget.parentElement, ChildTD: onDropCheckChildElements };
                    setCurrentTarget(tagers);

                }
            }

        }
        return (

            timearr?.map((x, i) => {
                if (props.interval === 15) {

                    return <>

                        <tr time-interval={x} className='weeklyviewhourslisttr'>
                            <td className='fc-axis fc-time fc-widget-content' style={{ width: "25px" }}><span>{x.split(":")[1] !== "15" && x.split(":")[1] !== "30" && x.split(":")[1] !== "45" ? x.split(":")[0] : ""}</span></td>
                            {weekViewHeader.map((week) =>
                                <td onDrop={handleDrop} onDrag={dragMove} onDragOver={handleDragOver} onDragStart={handleDragStart} className="eventContainer weeklyViewhoursList fc-widget-content addDND" onClick={(e) => props.dateClick(e, week.date)} data-date={week.date} weekDate={moment(week.date).format().split("T")[0]}>
                                    {!!events && events.map((value, index) => {
                                        if ((moment(week.date).format().split("T")[0] === moment(value.date).format().split("T")[0]) && (x === value.time.split("-")[0].trim())) {
                                            let heightDiff = calculateHours(value.time.split("-")[0], value.time.split("-")[1]) * defaultHeight;
                                            let height = !!value.height ? value.height : heightDiff * (60 / props.interval);
                                            return moment(week.date).format().split("T")[0] === moment(value.date).format().split("T")[0] ?
                                                props.EventsTemplate(value, index, height, ResizeMouseDown)
                                                : null
                                        }
                                    })}
                                </td>

                            )}
                        </tr>
                        {/* <tr className='fc-minor weeklyviewhourslisttr'>
                        <td className='fc-axis fc-time fc-widget-content'></td>
                        {weekViewHeader.map((week) =>
                            <td className="eventContainer weeklyViewList notremove" onClick={(e) => props.dateClick(e, week.date)} weekDate={moment(week.date).format().split("T")[0]}> */}
                        {/* {!!events && events.map((value, index) =>
                                    moment(week.date).format().split("T")[0] === moment(value.date).format().split("T")[0] ?
                                        props.EventsTemplate(value, index)
                                        : null
                                )} */}
                        {/* </td>

                        )}
                    </tr> */}
                    </>
                }
                else if (props.interval === 30) {
                    return i % 2 == 0 ?
                        <tr time-interval={x} className='weeklyviewhourslisttr'>
                            <td className='fc-axis fc-time fc-widget-content' style={{ width: "25px" }}><span>{x.split(":")[1] !== "15" && x.split(":")[1] !== "30" && x.split(":")[1] !== "45" ? x.split(":")[0] : ""}</span></td>
                            {weekViewHeader.map((week) =>
                                <td className="eventContainer weeklyViewhoursList fc-widget-content" onClick={(e) => props.dateClick(e, week.date)} weekDate={moment(week.date).format().split("T")[0]}>
                                    {!!events && events.map((value) =>
                                        moment(week.date).format().split("T")[0] === moment(value.date).format().split("T")[0] ?
                                            props.EventsTemplate(value)
                                            : null
                                    )}
                                </td>

                            )}
                        </tr>
                        : null
                } else {
                    return i % 4 == 0 ?
                        <tr className='weeklyviewhourslisttr'>
                            <td className='fc-axis fc-time fc-widget-content' style={{ width: "25px" }}><span>{x.split(":")[1] !== "15" && x.split(":")[1] !== "30" && x.split(":")[1] !== "45" ? x.split(":")[0] : ""}</span></td>
                            {weekViewHeader.map((week) =>
                                <td className="eventContainer weeklyViewhoursList fc-widget-content" onClick={(e) => props.dateClick(e, week.date)} weekDate={moment(week.date).format().split("T")[0]}>
                                    {!!events && events.map((value) =>
                                        moment(week.date).format().split("T")[0] === moment(value.date).format().split("T")[0] ?
                                            props.EventsTemplate(value)
                                            : null
                                    )}
                                </td>

                            )}
                        </tr>
                        : null
                }
            })


        )
    }
    //monthlyList HTML COMPONENT 

    const RendermonthlyView = (monthlyListBody, events) => {

        return (
            monthlyListBody.map((month) =>
                <tr className="eventContainer RowRef">
                    {month.days.map((monthDate) =>
                        <td className='DatesTD py-0 px-1 text-right' monthDate={monthDate} onClick={(e) => props.dateClick(e, monthDate)}>
                            {/* showing Date */}
                            <div>{moment(monthDate).format("DD")}</div>


                            {/* Show EVENTS in monthlyList */}
                            <div className='MonthlyEventsContainer'>
                                {!!events && events.map((value, index) =>
                                    moment(monthDate).format().split("T")[0] === moment(value.date).format().split("T")[0] ?
                                        props.EventsTemplate(value, index)
                                        : null
                                )}
                            </div>
                        </td>
                    )
                    }

                </tr>
            )
        )
    }
    //RenderResourceViewDay HTML COMPONENT 

    const renderResourceViewDay = (weekViewHeader, events, dataLength, TIMEARRAY) => {
        let defaultWidth = 25;
        var dragSrcEl = null;
        var cOffY = 0;
        let defaulttop = 0;
        let currentEvent = null;
        var applications = [];

        if (appSettings.locationsettings.viewApplicationsInResourceView && events.length > 0) {
            events.map((val, key) => {
                if (val.shiftApplicationsEmployees.length > 0) {

                    applications = [...applications, val]
                }
            })
        }
        const handleDragStart = (e) => {

            let shiftId = e.target.getAttribute("wel-id");
            if (!!shiftId) {
                currentEvent = events.find(x => x.shiftId === shiftId);
            }

            dragSrcEl = e.target;
            dragSrcEl.style.top = 0

            defaulttop = dragSrcEl.offsetTop
            cOffY = e.clientY - dragSrcEl.offsetTop;
            e.target.style.zIndex = 100;



            var crt = dragSrcEl.cloneNode(true);
            crt.style.backgroundColor = "red";
            e.dataTransfer.setDragImage(crt, 0, 0);

            e.dataTransfer.effectAllowed = 'move';
            e.dataTransfer.setData('text/html', e.target.outerHTML);
        }
        const dragMove = (e) => {

            e = e || window.event;
            e.preventDefault();
            dragSrcEl.style.top = (e.clientY - cOffY).toString() + 'px';
        };
        const handleDragOver = (e) => {
            if (e.preventDefault) {
                e.preventDefault();
            }

            e.dataTransfer.dropEffect = 'move';

            return false;
        }
        const handleDrop = (e) => {

            if (e.stopPropagation) {
                e.stopPropagation();
            }
            let targetTop = e.target.getBoundingClientRect().top;
            let targetLeft = e.target.getBoundingClientRect().left;
            HideEventElements(true);
            var dropTarget = document.elementFromPoint(targetLeft, targetTop);
            HideEventElements(false);
            let empid = dropTarget.getAttribute("emp-id");
            if (!!props.onResourceDrop) {
                props.onResourceDrop(currentEvent, empid)

                var data = {
                    EmpIdAlreadyAssigned: currentEvent.empId[0],
                    EmpIdToAssigned: empid,
                    shiftId: currentEvent.shiftId
                }
                SaveEventSwipe(data, currentEvent, empid)
            }

            return false;
        }

        return (
            <>
                <tr>
                    <td className="eventContainer renderResourceViewDay p-0">
                        <div className='fullCustomcalendar-slats overflow-scroll'>
                            <table className='fullCustomcalendar-table'>
                                <tbody>
                                    <tr>
                                        {TIMEARRAY.map((x, i) =>

                                            <>
                                                <td className='fc-axis fc-time fc-widget-content adjustWidth' data-hour={x}><span>{i % 4 === 0 ? x.split(":")[0] : null}</span></td>

                                            </>


                                        )}
                                    </tr>
                                    <tr className='spacer'></tr>
                                    {dataLength.map((data, i) =>
                                        <tr>
                                            {TIMEARRAY.map((x, Tindex) =>

                                                <>
                                                    <td onClick={(e) => props.onResourceTDClick(e)} onDrop={handleDrop} onDrag={dragMove} onDragOver={handleDragOver} onDragStart={handleDragStart} className='fc-axis fc-time fc-widget-content adjustWidth position-relative ResourceMainTd' data-hour={x} emp-id={data.empId} >
                                                        {!!events && events.map((value, index) => {

                                                            let empiD = data.empId;
                                                            if ((moment(dailyViewList).format().split("T")[0] === moment(value.date).format().split("T")[0]) && (x === value.time.split("-")[0].trim())) {
                                                                let WidthDiff = calculateHours(value.time.split("-")[0].trim(), value.time.split("-")[1].trim()) * defaultWidth;
                                                                let width = !!value.Rwidth ? value.Rwidth : WidthDiff * (60 / props.interval);

                                                                return moment(dailyViewList).format().split("T")[0] === moment(value.date).format().split("T")[0] && value.empId.some(id => id === empiD) ?
                                                                    props.EventsTemplate(value, index, width)
                                                                    :
                                                                    !!applications && applications.map((v, index) => {

                                                                        if ((moment(dailyViewList).format().split("T")[0] === moment(v.date).format().split("T")[0]) && (x === v.time.split("-")[0].trim())) {
                                                                            let WidthDiff = calculateHours(v.time.split("-")[0].trim(), v.time.split("-")[1].trim()) * defaultWidth;
                                                                            let width = !!v.Rwidth ? v.Rwidth : WidthDiff * (60 / props.interval);

                                                                            return moment(dailyViewList).format().split("T")[0] === moment(v.date).format().split("T")[0] && v.shiftApplicationsEmployees.some(x => x.employeeid === empiD) ?
                                                                                props.EventsTemplate({ ...v, shiftApplicationsEmployees: [], bgColor: 'rgb(241, 196, 15)' }, i, width)
                                                                                : null
                                                                        }
                                                                    }
                                                                    )
                                                            }
                                                        }
                                                        )}
                                                    </td>

                                                </>


                                            )}
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>

            </>
        );
    }

    //RenderResourceViewWeek HTML COMPONENT 

    const renderResourceViewWeek = (weekViewHeader, headerContent, dataLength, events) => {

        var dragSrcEl = null;
        var cOffY = 0;
        let defaulttop = 0;
        let currentEvent = null;
        var applications = [];

        if (appSettings.locationsettings.viewApplicationsInResourceView && events.length > 0) {
            events.map((val, key) => {
                if (val.shiftApplicationsEmployees.length > 0) {

                    applications = [...applications, val]
                }
            })
        }
        const handleDragStart = (e) => {
            let shiftId = e.target.getAttribute("wel-id");
            if (!!shiftId) {
                currentEvent = events.find(x => x.shiftId === shiftId);

            }

            dragSrcEl = e.target;
            dragSrcEl.style.top = 0

            defaulttop = dragSrcEl.offsetTop
            cOffY = e.clientY - dragSrcEl.offsetTop;
            e.target.style.zIndex = 100;



            var crt = dragSrcEl.cloneNode(true);
            crt.style.backgroundColor = "red";
            e.dataTransfer.setDragImage(crt, 0, 0);

            e.dataTransfer.effectAllowed = 'move';
            e.dataTransfer.setData('text/html', e.target.outerHTML);
        }
        const dragMove = (e) => {

            e = e || window.event;
            e.preventDefault();
            dragSrcEl.style.top = (e.clientY - cOffY).toString() + 'px';
        };
        const handleDragOver = (e) => {
            if (e.preventDefault) {
                e.preventDefault();
            }

            e.dataTransfer.dropEffect = 'move';

            return false;
        }
        const handleDrop = (e) => {

            if (e.stopPropagation) {
                e.stopPropagation();
            }
            let targetTop = e.target.getBoundingClientRect().top;
            let targetLeft = e.target.getBoundingClientRect().left;
            HideEventElements(true);
            var dropTarget = document.elementFromPoint(targetLeft, targetTop);
            HideEventElements(false);
            let empid = dropTarget.getAttribute("emp-id");
            if (!!props.onResourceDrop) {

                props.onResourceDrop(currentEvent, empid)
                var data = {
                    EmpIdAlreadyAssigned: currentEvent.empId[0],
                    EmpIdToAssigned: empid,
                    shiftId: currentEvent.shiftId
                }
                SaveEventSwipe(data)
            }

            return false;
        }
        let parentcheck = null;
        const MouseDown = (e) => {
            e.preventDefault();
            e.stopPropagation();


            e.target.classList.add("selectedEvent");
            parentcheck = e.target.parentElement;
            document.addEventListener('mousemove', MouseMove, false);
            document.addEventListener('mouseup', MouseUp, false);
        }
        let lastX = 0;
        const MouseMove = (e) => {
            if (parentcheck === e.target.parentElement) {
                const leftOrRight = (
                    e.clientX > lastX ? 'right'
                        : e.clientX < lastX ? 'left'
                            : 'none'
                )

                if (leftOrRight === "right" || leftOrRight === "left") {
                    e.target.classList.add("selectedEvent")
                }
                lastX = e.clientX;
            }


        }
        const MouseUp = (e) => {


            let allselectedevents = Array.from(document.querySelectorAll(".selectedEvent"));
            allselectedevents.map(x => x.classList.remove("selectedEvent"))

            lastX = 0
            document.removeEventListener('mousemove', MouseMove, false); document.removeEventListener('mouseup', MouseUp, false);
        }
        return (
            <>
                <tr className='ResourceViewWeekList adjustWidthAccordingtoResource'>
                    {weekViewHeader.map((day) =>

                        <td className={`eventContainer adjustWidthAccordingtoResource  p-0 resourceWeek ResourceViewWeekList header fc-widget-content text-dark ${new Date(day.date).getDay() === 6 || new Date(day.date).getDay() === 0 ? "WeekEnd" : ""}`} weekDate={moment(day.date).format().split("T")[0]}>
                            {!!headerContent ? headerContent(day) : day.text}
                        </td>

                    )}
                </tr>
                <tr className='spacer'></tr>
                {dataLength.map((data, i) =>

                    <tr className='ResourceViewWeekList adjustWidthAccordingtoResource Employee'>
                        {weekViewHeader.map((day) =>
                            <td onClick={(e) => props.onResourceTDClick(e)} onDrop={handleDrop} onDrag={dragMove} onDragOver={handleDragOver} onDragStart={handleDragStart} className={`eventContainer resourceWeek p-0 ResourceViewWeekListEmployee adjustWidthAccordingtoResource position-relative ResourceMainTd fc-widget-content text-dark ${new Date(day.date).getDay() === 6 || new Date(day.date).getDay() === 0 ? "WeekEnd" : ""}`} emp-id={data.empId} weekDate={moment(day.date).format().split("T")[0]}>
                                {
                                    !!events && events.map((value, index) =>

                                        moment(day.date).format().split("T")[0] === moment(value.date).format().split("T")[0] && value.empId.some(id => id === data.empId) ?
                                            props.EventsTemplate(value, index)
                                            :
                                            !!applications && applications.length > 0 ?
                                                applications.map((v, i) =>

                                                    moment(day.date).format().split("T")[0] === moment(v.date).format().split("T")[0] && v.shiftApplicationsEmployees.some(x => x.employeeid === data.empId) ?
                                                        props.EventsTemplate({ ...v, shiftApplicationsEmployees: [], bgColor: 'rgb(241, 196, 15)' }, i)
                                                        :
                                                        null

                                                )
                                                : null

                                    )
                                }
                            </td>

                        )}
                    </tr>

                )}


            </>
        );
    }
    //RenderResourceViewMonth HTML COMPONENT 

    const renderResourceViewMonth = (currentdate, events, dataLength) => {

        var daysInMonth = [];
        var monthDate = moment(currentdate).startOf('month');
        var applications = [];

        if (appSettings.locationsettings.viewApplicationsInResourceView && events.length > 0) {
            events.map((val, key) => {
                if (val.shiftApplicationsEmployees.length > 0) {

                    applications = [...applications, val]
                }
            })
        }

        for (let i = 0; i < monthDate.daysInMonth(); i++) {
            let newDay = monthDate.clone().add(i, 'days');
            daysInMonth.push(newDay);
        }
        var dragSrcEl = null;
        var cOffY = 0;
        let defaulttop = 0;
        let currentEvent = null;

        const handleDragStart = (e) => {
            let shiftId = e.target.getAttribute("wel-id");
            if (!!shiftId) {
                currentEvent = events.find(x => x.shiftId === shiftId);

            }

            dragSrcEl = e.target;
            dragSrcEl.style.top = 0

            defaulttop = dragSrcEl.offsetTop
            cOffY = e.clientY - dragSrcEl.offsetTop;
            e.target.style.zIndex = 100;



            var crt = dragSrcEl.cloneNode(true);
            crt.style.backgroundColor = "red";
            e.dataTransfer.setDragImage(crt, 0, 0);

            e.dataTransfer.effectAllowed = 'move';
            e.dataTransfer.setData('text/html', e.target.outerHTML);
        }
        const dragMove = (e) => {

            e = e || window.event;
            e.preventDefault();
            dragSrcEl.style.top = (e.clientY - cOffY).toString() + 'px';
        };
        const handleDragOver = (e) => {
            if (e.preventDefault) {
                e.preventDefault();
            }

            e.dataTransfer.dropEffect = 'move';

            return false;
        }
        const handleDrop = (e) => {
            if (e.stopPropagation) {
                e.stopPropagation();
            }
            let targetTop = e.target.getBoundingClientRect().top;
            let targetLeft = e.target.getBoundingClientRect().left;
            HideEventElements(true);
            var dropTarget = document.elementFromPoint(targetLeft, targetTop);
            HideEventElements(false);
            let empid = dropTarget.getAttribute("emp-id");
            if (!!props.onResourceDrop) {

                props.onResourceDrop(currentEvent, empid)
                var data = {
                    EmpIdAlreadyAssigned: currentEvent.empId[0],
                    EmpIdToAssigned: empid,
                    shiftId: currentEvent.shiftId
                }
                SaveEventSwipe(data)
            }

            return false;
        }
        let parentcheck = null;
        const MouseDown = (e) => {
            e.preventDefault();
            e.stopPropagation();


            e.target.classList.add("selectedEvent");
            parentcheck = e.target.parentElement;
            document.addEventListener('mousemove', MouseMove, false);
            document.addEventListener('mouseup', MouseUp, false);
        }
        let lastX = 0;

        const MouseMove = (e) => {
            if (parentcheck === e.target.parentElement) {
                const leftOrRight = (
                    e.clientX > lastX ? 'right'
                        : e.clientX < lastX ? 'left'
                            : 'none'
                )

                if (leftOrRight === "right" || leftOrRight === "left") {
                    e.target.classList.add("selectedEvent")
                }
                lastX = e.clientX;
            }


        }

        const MouseUp = (e) => {


            let allselectedevents = Array.from(document.querySelectorAll(".selectedEvent"));
            allselectedevents.map(x => x.classList.remove("selectedEvent"))

            lastX = 0
            document.removeEventListener('mousemove', MouseMove, false); document.removeEventListener('mouseup', MouseUp, false);
        }

        return (
            <>
                <tr>
                    <td className="eventContainer adjustWidthAccordingtoResource ResourceViewMonth p-0">
                        <div className='overflow-scroll'>
                            <table>
                                <tbody>
                                    <tr>
                                        {daysInMonth.map((day) => {
                                            return <td width="120px" className={`${new Date(day).getDay() === 6 || new Date(day).getDay() === 0 ? "WeekEnd" : ""} eventContainer adjustWidthAccordingtoResource ResourceViewMonth header fc-widget-content text-dark p-0`} monthDate={day.format().split("T")[0]}>
                                                <div>{day.format("ddd,M/DD")}</div>
                                            </td>
                                        }
                                        )}
                                    </tr>
                                    <tr className='spacer'></tr>
                                    {dataLength.map((data, i) =>

                                        <tr className='ResourceViewMonth Employee RowRef'>
                                            {daysInMonth.map((day) =>
                                                <td onClick={(e) => props.onResourceTDClick(e)} onDrop={handleDrop} onDrag={dragMove} onDragOver={handleDragOver} onDragStart={handleDragStart} className={`${new Date(day).getDay() === 6 || new Date(day).getDay() === 0 ? "WeekEnd" : ""} eventContainer p-0 ResourceViewMonth ResourceViewMonthEmployee position-relative ResourceMainTd adjustWidthAccordingtoResource fc-widget-content text-dark`} monthDate={day.format().split("T")[0]} emp-id={data.empId}>
                                                    {!!events && events.map((value, index) =>
                                                        moment(day).format().split("T")[0] === moment(value.date).format().split("T")[0] && value.empId.some(id => id === data.empId) ?
                                                            props.EventsTemplate(value, index)
                                                            : !!applications && applications.length > 0 ?
                                                                applications.map((v, i) =>

                                                                    moment(day).format().split("T")[0] === moment(v.date).format().split("T")[0] && v.shiftApplicationsEmployees.some(x => x.employeeid === data.empId) ?
                                                                        props.EventsTemplate({ ...v, shiftApplicationsEmployees: [], bgColor: 'rgb(241, 196, 15)' }, i)
                                                                        :
                                                                        null

                                                                )
                                                                : null
                                                    )}
                                                </td>

                                            )}
                                        </tr>

                                    )}
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>



            </>
        );
    }

    const renderViews = () => {

        switch (props.view) {
            case "monthlyList":
                return RendermonthlyView(monthlyListBody, props.events)
            case "dailyViewList":
                return RenderdailyViewList(props.events, dailyViewList, props._showTitle)
            case "dailyViewhoursList":
                return RenderdailyViewhoursList(props.events, dailyViewList, timearray)
            case "weeklyViewList":
                return RenderweeklyViewList(weekViewHeader, props.events, props._showTitle, props.setheight)
            case "weeklyViewhoursList":
                return RenderweeklyViewhoursList(weekViewHeader, props.events, timearray)
            case "resourceViewDay":
                return renderResourceViewDay(weekViewHeader, props.events, props.dataLength, timearray)
            case "resourceViewWeek":
                return renderResourceViewWeek(weekViewHeader, props.headerContent, props.dataLength, props.events)
            case "resourceViewMonth":
                return renderResourceViewMonth(currentDate, props.events, props.dataLength)
            default:
                break;
        }
    }

    return (
        <>
            <table className='fullCustomcalendar fullCustomcalendar-table fullCustomcalendar-table-bordered' style={{ height: !!props.fixedHeadline && props.fixedHeadline === true ? "611px" : "auto" }}>
                <thead className='fullCustomcalendar-thead bg-grey' style={{ backgroundColor: themeColor }}>
                    <tr className='fullCustomcalendar-thead-tr'>
                        {props.view === "weeklyViewList" ?
                            weekViewHeader.map((day) => {
                                return <th data-currentDate={day.date} style={{ background: (props.SandBoxDates?.some(x => new Date(x).toString() === new Date(day.date).toString())) ? "#f1c40f" : "" }} className='text-white weeklyThs'>{!!props.headerContent ? props.headerContent(day) : day.text}</th>

                            })
                            : props.view === "weeklyViewhoursList" ?
                                <>
                                    <th style={{ width: "25px" }}></th>
                                    {weekViewHeader.map((day) =>
                                        <th data-currentDate={day.date} className='text-white weeklyThs'>{!!props.headerContent ? props.headerContent(day) : day.text}</th>

                                    )}
                                </>

                                : null}
                        {props.view === "dailyViewList" || props.view === "resourceViewDay" || props.view === "dailyViewhoursList" ?

                            <th className='text-white'>{singleTHHeader}</th>

                            : null}
                        {props.view === "resourceViewWeek" ?


                            <th className='text-white' colSpan={7}>{singleTHHeader}</th>

                            : null}

                        {props.view === "resourceViewMonth" ?

                            // <th className='text-white' colSpan={moment(currentDate).startOf('month').daysInMonth()}>{singleTHHeader}</th>
                            <th className='text-white'>{singleTHHeader}</th>

                            : null}

                        {props.view === "monthlyList" ?

                            monthlyListHeader.map((day) => <th className='text-white'>{day}</th>)

                            : null}
                    </tr>
                </thead>
                <tbody>
                    {
                        renderViews()
                    }
                </tbody>
            </table>
        </>
    );
});


function FullCustomCalendar(props) {
    const { t: ct } = useTranslation('common');

    const [filterEvents, setFilterEvents] = useState([]);
    const [events, setEvents] = useState([]);
    const [View, setView] = useState("weeklyViewList");
    const [showRequirements, setshowRequirements] = useState(false);
    const [hideTags, setHideTags] = useState(false);
    const [hideAssignments, setHideAssignments] = useState(false);
    const [fixedHeadline, setFixedHeadline] = useState(false);
    const [compactCalendar, setCompactCalendar] = useState(false);
    const [oneRowPerSeries, setoneRowPerSeries] = useState(false);
    const checkDiv = useRef([]);
    const [checkChange, setCheckChange] = useState(null);
    const [SandBoxDates, setSandBoxDates] = useState();

    useEffect(() => {

        if (!!props.view) {
            setView(props.view)
        }
        // if (!!props.events) {
        //     setEvents(props.events)
        // }

    }, []);
    useEffect(() => {
        if (!!props.SandBoxDates) {
            setSandBoxDates(props.SandBoxDates)
        }

    }, [props.SandBoxDates]);

    useEffect(() => {
        if (props._planningIncomplete === true) {
            let newEvents = filterEvents.filter(x => x.assignedEmpCount !== x.requiredEmpCount && !x.assignedEmpCount >= x.requiredEmpCount);
            setEvents(newEvents)
        } else {
            setEvents(filterEvents)
        }
    }, [props._planningIncomplete, filterEvents]);

    useEffect(() => {
        if (props.filterShifts === true) {
            let empId = props.SelectedEmployeeId?.toString();
            let newEvents = filterEvents.filter(x => x.empId.includes(empId));

            //Change in props
            setEvents(newEvents)

        } else {
            setEvents(filterEvents)
        }
    }, [props.filterShifts]);

    useMemo(() => {
        if (!!props.events) {
            let neweve = props.events.map(x => { return { ...x, height: "", left: 0, right: 0, zIndex: 1, Rwidth: "" } })
            let sortedList = neweve.sort((a, b) => (a.title.toUpperCase() > b.title.toUpperCase()) ? 1 : ((b.title.toUpperCase() > a.title.toUpperCase()) ? -1 : 0));
            setEvents(sortedList)
            setFilterEvents(sortedList)
        }
    }, [props.events]);

    useEffect(() => {
        if (!!props.view) {
            console.log(props.view)
            setView(props.view)
        }
    }, [props.view]);

    useEffect(() => {

        setshowRequirements(props._showRequirements)
    }, [props._showRequirements]);

    useEffect(() => {

        setHideTags(props._tags)
    }, [props._tags]);

    useEffect(() => {

        setHideAssignments(props._hideAssignments)
    }, [props._hideAssignments]);

    useEffect(() => {

        setFixedHeadline(props._fixedHeadline)
    }, [props._fixedHeadline]);

    useEffect(() => {

        setCompactCalendar(props._compactCalendar)
    }, [props._compactCalendar]);

    useEffect(() => {

        setoneRowPerSeries(props._oneRowPerSeries)
    }, [props._oneRowPerSeries]);

    useEffect(() => {
        setHeightofDivs()
    }, [compactCalendar]);

    const setHeightofDivs = () => {

        let rowRef = Array.from(document.querySelectorAll(".RowRef"));
        if (!!rowRef && rowRef.length > 0) {
            for (let index = 0; index < rowRef.length; index++) {
                let rowElementTR = rowRef[index];

                let AllElemetns = Array.from(rowElementTR.querySelectorAll('.customEventDiv'));

                if (AllElemetns.length > 0 && !!AllElemetns[0].parentElement) {
                    let currentArr = [];

                    for (let j = 0; j < AllElemetns.length; j++) {

                        const divElement = AllElemetns[j];
                        if (!!divElement) {

                            divElement.style.height = "auto"
                        }

                    }

                    let newArray = AllElemetns.map(ele => currentArr.push({ height: ele.offsetHeight, nodeIndex: Array.from(ele.parentElement.parentElement.children).indexOf(ele.parentElement) }));

                    let ObjMap = {};

                    currentArr.forEach(element => {
                        var makeKey = "node_" + element.nodeIndex;
                        if (!ObjMap[makeKey]) {
                            ObjMap[makeKey] = [];
                        }

                        ObjMap[makeKey].push(
                            element.height

                        );
                    });

                    for (let i = 0; i < Object.keys(ObjMap).length; i++) {

                        const element = Object.keys(ObjMap)[i];

                        let MaxnodeHeight = Math.max(...ObjMap[element]);
                        let nodeIndex = element.split("_")[1];

                        for (let j = 0; j < AllElemetns.length; j++) {

                            const divElement = AllElemetns[j];
                            if (!!divElement) {
                                if (Array.from(divElement.parentElement.parentElement.children).indexOf(divElement.parentElement) === parseInt(nodeIndex)) {
                                    if (props.view !== "resourceViewDay" && props.view !== "resourceViewWeek" && props.view !== "resourceViewMonth") {

                                        Array.from(Array.from(divElement.parentElement.parentElement.children)[nodeIndex].children)[1].style.height = MaxnodeHeight + "px"
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    useEffect(() => {
        if (props.events.length === checkDiv.current.length) {
            setHeightofDivs();
        }
    }, [events]);

    function pickTextColorBasedOnBgColorSimple(bgColor, darkColor, lightColor) {
        if (bgColor === "#fff") {
            return darkColor
        }
        var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
        var r = parseInt(color.substring(0, 2), 16); // hexToR
        var g = parseInt(color.substring(2, 4), 16); // hexToG
        var b = parseInt(color.substring(4, 6), 16); // hexToB
        return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
            darkColor : lightColor;
    }
    //EVENTS CUSTOM TEMPLATE YOU CAN CHANGE THIS ACCORDING TO YOUR NEED
    const eventsTemplate = (value, loopindex, height, resize) => {
        checkDiv.current = [];
        let compareDate = moment(new Date(value.date)).isSameOrAfter(moment(new Date()), 'day');
        let endTimeFromLoop = moment(value.time.split("-")[1].trim(), 'hh:mm');
        let currentStartTime = moment();
        let isFutureTime = endTimeFromLoop.isAfter(currentStartTime);
        let isTodayDate = moment(new Date(value.date)).isSame(moment(new Date()), 'day');


        let empId = props.SelectedEmployeeId?.toString();

        let newEvents = filterEvents.filter(x => x.empId.includes(empId));

        let test = newEvents.find(x => x.shiftId === value.shiftId);


        const returnColor = () => {


            if (isFutureTime === false && isTodayDate === true) {
                return "rgb(149, 165, 166)" //grey color
            }
            if (!!value.bgColor) {
                return value.bgColor // color comming from api
            }
            if (isFutureTime === true && isTodayDate === true) {
                return "rgb(231, 76, 60)"//red color
            } else {
                if (compareDate === true) {
                    return "rgb(231, 76, 60)" //red color
                } else {

                    return "rgb(149, 165, 166)" //grey color
                }
            }
        }

        if (props.view === "resourceViewDay" || props.view === "resourceViewWeek" || props.view === "resourceViewMonth") {
            return (

                !!props.eventsTemplate ? props.eventsTemplate(value, props.eventClick) :
                    <div ref={(node) => {
                        if (!!node) {

                            checkDiv.current[loopindex] = node
                        }

                    }} draggable='true' style={{ backgroundColor: returnColor(), width: !!height ? height : '', left: value.left, right: value.right, zIndex: value.zIndex, top: "0px" }} className={`eventwrapper resourceViews position-absolute   ${!!test && props.showborderClass === true ? "addBorder" : ""}`} date={value.date} wel-id={value.shiftId} onClick={(e) => { e.stopPropagation(); props.eventClick(e, value) }}>
                        {/* <div className='gearIconOnEvent' onClick={(e) => { e.stopPropagation(); props.handleEditShiftModal(value) }}></div> */}
                        <div className='event-div d-flex justify-content-between flex-column customEventDiv adjustPaddingOfEvent rounded text-center' style={{ backgroundColor: returnColor() }}>
                            <div className=''>
                                <div className='ShowTime text-white fw-bold '>{value.time}
                                    {
                                        !!value.shiftApplicationsEmployees.length > 0 ?
                                            // <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip></Tooltip>}>
                                            // <span data-tooltip-id="my-tooltip" data-tooltip-content={value.shiftApplicationsEmployees.map(e => e.empname).join(',')} className="dotPrimary"></span>
                                            <DOT color="#428bca" data-tooltip-id="my-tooltip" data-tooltip-content={value.shiftApplicationsEmployees.map(e => e.empname).join(',')} />  // </OverlayTrigger>
                                            : null
                                    }
                                </div>
                                <div className='ShowTitle text-white fw-bold  fc-title-with-tags'>{value.title} {!hideTags === true ? !!value.tags && value.tags.length > 0 ? <div className='ShowNote p-1 mt-2 rounded fw-bold text-left bg-white'>
                                    {
                                        value.tags.map((val, key) => {
                                            return <CalendarBadge style={{ "backgroundColor": !!val.value ? val.value : "#fff" }} className={!!val.value ? pickTextColorBasedOnBgColorSimple(val.value, "text-black me-2", "text-white me-2") : ""} >{val.text}</CalendarBadge>

                                        })
                                    }</div> : null : null}</div>

                            </div>

                        </div>
                    </div>
            );
        } else {
            console.log(value)
            let selectedBorder = value.count;
            let newSplit = selectedBorder.split("/");
            let hasBorder = false;
            if (newSplit[0] === newSplit[1]) {
                hasBorder = true
            } else {
                hasBorder = false
            }
            return (

                !!props.eventsTemplate ? props.eventsTemplate(value, props.eventClick) :
                    <div ref={(node) => {
                        if (!!node) {

                            checkDiv.current[loopindex] = node
                        }

                    }} draggable='true' style={{ backgroundColor: returnColor(), height: !!height ? height : '', left: value.left, right: value.right, zIndex: value.zIndex }} className={`eventwrapper ${props.view?.includes("hours") ? 'resize' : ''} mb-3 ${!!test && props.showborderClass === true ? "addBorder" : ""}`} date={value.date} wel-id={value.shiftId} onClick={(e) => { e.stopPropagation(); props.eventClick(e, value) }}>
                        <div className='gearIconOnEvent' onClick={(e) => { e.stopPropagation(); props.handleEditShiftModal(value) }}><BsFillGearFill /></div>
                        {value.shiftAttachmentList.length > 0 && <div className='h-100 text-end paperclip' style={{ borderLeft: hasBorder === true ? "5px solid green" : "", borderTop: hasBorder === true ? "5px solid green" : "", borderRight: hasBorder === true ? "5px solid green" : "", }} data-tooltip-id="my-tooltip" data-tooltip-html={
                            `<ul>
                                        ${value.shiftAttachmentList.map((val, key) => {
                                return `<li>` + val.attachmentName + `</li>`
                            })}
                                        
                                </ul>
                                <div style={{ textAlign: "center" }}>${value.shiftAttachmentList.length} ${ct("attachments")} </div>
                                `


                        } onClick={(e) => { e.stopPropagation(); props.ShiftAttachmentList(value) }}><PaperCLip fs={"16px"} className="m-0" color="white" /></div>}
                        <div className='event-div d-flex justify-content-between flex-column customEventDiv p-2  text-center' style={{ borderBottom: hasBorder === true ? "5px solid green" : "", borderLeft: hasBorder === true ? "5px solid green" : "", borderRight: hasBorder === true ? "5px solid green" : "", borderTop: hasBorder === true && value.shiftAttachmentList.length <= 0 ? "5px solid green" : "none", backgroundColor: returnColor() }}>
                            <div>
                                <div className='ShowTime text-white fw-bold'>{value.time}
                                    {
                                        !!value.shiftApplicationsEmployees.length > 0 ?
                                            // <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip></Tooltip>}>
                                            // <span data-tooltip-id="my-tooltip" data-tooltip-content={value.shiftApplicationsEmployees.map(e => e.empname).join(',')} className="dotPrimary"></span>
                                            <DOT color="#428bca" data-tooltip-id="my-tooltip" data-tooltip-content={value.shiftApplicationsEmployees.map(e => e.empname).join(',')} />  // </OverlayTrigger>
                                            : null
                                    }
                                </div>
                                <div className='ShowTitle text-white fw-bold'>{value.title}</div>
                                {showRequirements === true ?
                                    <div className='Showcount text-white fw-bold'>{value.count}</div>
                                    : null}
                                {hideAssignments === false ?
                                    <p className="EventInfo" dangerouslySetInnerHTML={{ __html: value.shiftEmployeeList.map(x => `<p class="mb-0">${x.empname}</p>`).join(", ") }}></p> : null
                                }
                                {!!value.note ? <div className='ShowTags text-white fw-bold'>"{value.note}"</div> : null}
                            </div>
                            {!hideTags === true ? !!value.tags && value.tags.length > 0 ? <div className='ShowNote p-1 mt-2 rounded fw-bold text-left bg-white'>{
                                value.tags.map((val, key) => {

                                    return <CalendarBadge style={{ "backgroundColor": !!val.value ? val.value : "#fff" }} className={!!val.value ? pickTextColorBasedOnBgColorSimple(val.value, "text-black me-2", "text-white me-2") : ""} >{val.text}</CalendarBadge>

                                })}</div> : null : null}

                            {/* {hideTags === true ? !!value.tags ? <div className='ShowTags text-white fw-bold'>"{value.tags}"</div> : null : null}
                    {!!value.note ? <div className='ShowNote p-1 mt-2 rounded fw-bold text-left bg-white'>{value.note}</div> : null} */}

                        </div>
                        {props.view?.includes("hours") === true ? <div className='ResizeEvent' onClick={(e) => e.stopPropagation()} onMouseDown={!!resize ? resize : false}></div> : null}
                    </div>
            );
        }

    }

    return (
        <div className='CustomFullCalendarWrapper table-responsive responsive-width'>
            {
                View === "resourceViewDay" || View === "resourceViewWeek" || View === "resourceViewMonth" ?
                    <div className='makeFlexColumn'>
                        <div className='width-25 padding-right-2'>
                            <ResourceViewSideBar title={props.resourceHeaderTitle} listOfdata={props.resourceViewData} template={!!props.customResourceViewSidebarTemplate ? props.customResourceViewSidebarTemplate : null} />
                        </div>
                        <div className='width-75'>
                            <CalendarTable _startTime={props._startTime} _endTime={props._endTime} SandBoxDates={SandBoxDates} updateLeftEmployeeList={props.updateLeftEmployeeList} onResourceTDClick={!!props.onResourceTDClick ? props.onResourceTDClick : false} onResourceDrop={!!props.onResourceDrop ? props.onResourceDrop : false} disableOverLappingInDailyView={!!props.disableOverLappingInDailyView ? props.disableOverLappingInDailyView : false} updateEvents={setEvents} interval={!!props.interval ? props.interval : 15} setheight={setHeightofDivs} datesSet={!!props.datesSet ? props.datesSet : null} dateClick={!!props.dateClick ? props.dateClick : null} fixedHeadline={fixedHeadline} EventsTemplate={eventsTemplate} dataLength={!!props.resourceViewData && props.resourceViewData.length > 0 ? props.resourceViewData : []} ref={!!props.refCalander ? props.refCalander : null} events={events} view={View} headerContent={(args) => { return !!props.headerContent ? props.headerContent(args) : <span className='text-white'>{args.text}</span> }} />
                        </div>
                    </div>
                    :
                    <CalendarTable _startTime={props._startTime} _endTime={props._endTime} SandBoxDates={SandBoxDates} updateLeftEmployeeList={props.updateLeftEmployeeList} onResourceTDClick={!!props.onResourceTDClick ? props.onResourceTDClick : false} onResourceDrop={!!props.onResourceDrop ? props.onResourceDrop : false} disableOverLappingInDailyView={!!props.disableOverLappingInDailyView ? props.disableOverLappingInDailyView : false} updateEvents={setEvents} interval={!!props.interval ? props.interval : 15} setheight={setHeightofDivs} datesSet={!!props.datesSet ? props.datesSet : null} dateClick={!!props.dateClick ? props.dateClick : null} _oneRowPerSeries={oneRowPerSeries} _compactCalendar={compactCalendar} fixedHeadline={fixedHeadline} EventsTemplate={eventsTemplate} _showTitle={!!props._showTitle ? props._showTitle : false} ref={!!props.refCalander ? props.refCalander : null} events={events} view={View} headerContent={(args) => { return !!props.headerContent ? props.headerContent(args) : <span className='text-white'>{args.text}</span> }} />
            }
        </div>
    );
}

export default FullCustomCalendar;


function ResourceViewSideBar(props) {
    const { t: ct } = useTranslation('common');

    return (
        <>
            {!!props.template ? props.template(props.listOfdata) :
                <table className='ResourveViewEmployeeTab fullCustomcalendar fullCustomcalendar-table '>
                    <thead>
                        <tr className='fullCustomcalendar-thead-tr '>
                            <th className='noBorder'>&nbsp;</th>
                        </tr>
                        <tr>
                            <th className='header'>
                                <div className='spaceBetween'>
                                    <span className='Title'>{!!props.title ? props.title : ct("employee")}</span>
                                    <div className="ValueText">
                                        <button variant="primary" size="sm" className="btnActionChage">
                                            {ct("week")} {moment().format('W')}
                                        </button>
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody className='ResourceBody'>
                        <tr className='spacer'></tr>
                        {!!props.listOfdata && props.listOfdata.map(x =>
                            <tr>
                                <td className='pad-8'>

                                    <div className='spaceBetween'>
                                        <span className='Title'>{x.name}</span>
                                        <div className="ValueText">
                                            <span variant="primary" size="sm" className="badge badge-success">
                                                {x.weeklyassignHours.toString() + "/" + x.weeklytargetHours.toString()}
                                            </span>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            }
        </>
    );
}



