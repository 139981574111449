import { saveAs } from 'file-saver';
import moment from 'moment';
import React, { useState } from 'react';
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import DatePicker from 'react-datepicker';
import { BiExport } from "react-icons/bi";
import { BsFillHouseDoorFill } from 'react-icons/bs';
import { HiPlus, HiUserGroup } from "react-icons/hi";
import CheckSelect from '../../Components/CheckSelect';
import HeaderButton from '../../Components/HeaderButton';
import TagsCheckSelectFilter from '../../Components/TagsCheckSelectFilter';
import WorkAreaCheckSelectFilter from '../../Components/WorkAreaCheckSelectFilter';
import { CallAxiosBlob } from '../../Helpers';
import { ActionItem, ActionsBox, CircleBtnWithIcon, HeaderRadio, SubHeadTitle, SubHeaderTitleBox, SubHeaderWrapper } from '../../Helpers/Global/StyledComponents';
import SelectPeriodDropdownFilter from '../../Components/SelectPeriodDropdownFilter';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";


function AbsencesHeader(props) {
    const { t: ht } = useTranslation('header');
    const { t: ct } = useTranslation('common');
    const { t: apt } = useTranslation('absencePage');

    const [showExportModal, setShowExportModal] = useState(false);
    const themeColorBtn = useSelector(state => state.app.theme.colors.buttons);
    return (
        <>
            <SubHeaderWrapper>
                <SubHeaderTitleBox>
                    <HiUserGroup color={themeColorBtn} size={22} />
                    <SubHeadTitle> {ht('absences')}</SubHeadTitle>
                </SubHeaderTitleBox>
                <ActionsBox>
                    <ActionItem>
                        <CircleBtnWithIcon data-tooltip-id="my-tooltip" onClick={() => props.setCheckRadio('home')} data-tooltip-content={apt('overviewTooltip')} className={`Btn ${props.checkRadio === "home" ? "ActiveRad" : ""}`}><BsFillHouseDoorFill /> </CircleBtnWithIcon>
                    </ActionItem>
                    <ActionItem>
                        <HeaderRadio onChange={() => props.setCheckRadio('open')} data-tooltip-id="my-tooltip" data-tooltip-content={apt('openRequestTooltip')} name='check' type='radio' checked={props.checkRadio === "open" ? true : false} />
                    </ActionItem>
                    <ActionItem>
                        <HeaderRadio onChange={() => props.setCheckRadio('confirm')} data-tooltip-id="my-tooltip" data-tooltip-content={apt('confirmedRequestTooltip')} name='check' type='radio' checked={props.checkRadio === "confirm" ? true : false} />
                    </ActionItem>
                    <ActionItem onClick={(e) => { props.setAbsenceModalType("add"); props.setAbsenceIdForEdit(""); props.setShowAddEditAbsenceModal(true) }}>
                        <HeaderButton title={ct('add')} icon={<HiPlus color='#fff' size={18} />} />
                    </ActionItem>
                    <ActionItem>
                        <HeaderButton title={ct('export')} onClick={() => { setShowExportModal(true) }} icon={<BiExport color='#fff' size={18} className='rotate90deg' />} />
                    </ActionItem>
                    <ActionItem>
                        <SelectPeriodDropdownFilter handelDateFilter={props.handelDateFilter} />
                    </ActionItem>
                    <ActionItem>
                        <TagsCheckSelectFilter />
                    </ActionItem>
                    <ActionItem>
                        <WorkAreaCheckSelectFilter />
                    </ActionItem>
                </ActionsBox>
            </SubHeaderWrapper>
            {
                showExportModal === true ?
                    <ExportAbsenceModal show={showExportModal} onHide={setShowExportModal} />
                    : null
            }
        </>
    );
}

export default AbsencesHeader;

function ExportAbsenceModal(props) {
    const { t: ct } = useTranslation('common');
    const { t: apt } = useTranslation('absencePage');

    const [DisabledInput, setDisabledInput] = useState(false);
    const [AbsenceStartDate, setAbsenceStartDate] = useState(new Date());
    const [AbsenceEndDate, setAbsenceEndDate] = useState(new Date());
    const [StatusType, setStatusType] = useState("All");
    const [VACATIONStartDate, setVACATIONStartDate] = useState(moment().startOf("year"));
    const [VACATIONEndDate, setVACATIONEndDate] = useState(moment().endOf("year"));


    const ExportAbsence = async (val) => {
        var data = {
            IsManual: DisabledInput,
            StartDate: AbsenceStartDate,
            EndDate: AbsenceEndDate,
            StatusType: StatusType,
            format: val
        }
        var response = await CallAxiosBlob("/api/Absence/AbsencesExport|post", data);

        if (!!response && response.status === 200) {
            if (val === 'CSV') {
                saveAs(response.data, "Absence.csv")
            }
            else if (val === 'XLSX') {
                saveAs(response.data, "Absence.xlsx")
            }
        }
    }
    const ExportVacation = async (val) => {
        var data = {
            StartDate: AbsenceStartDate,
            EndDate: AbsenceEndDate,
            format: val
        }
        var response = await CallAxiosBlob("/api/VacationQuota/VactionExport|post", data);

        if (!!response && response.status === 200) {
            if (val === 'CSV') {
                saveAs(response.data, "VacationQuota.csv")
            }
            else if (val === 'XLSX') {
                saveAs(response.data, "VacationQuota.xlsx")
            }
        }
    }
    return (
        <>
            <Modal show={props.show} onHide={() => props.onHide(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{apt('selectExportMdl.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}><h5 className='fw-bold mb-2 text-uppercase'> {apt('selectExportMdl.exportOfAbsences')}</h5></Col>
                        <Col md={12}>
                            <div className="smallInfoText pb-2 ps-3"> {apt('selectExportMdl.smallInfo1')}</div>
                            <div className="smallInfoText pb-2 ps-3"> {apt('selectExportMdl.smallInfo2')}</div>

                            <Form.Group className="mt-3">
                                <Form.Check className="smallTextControl" inline checked={DisabledInput} onChange={() => setDisabledInput(!DisabledInput)} label={apt('selectExportMdl.defineExportLabel')} type="checkbox" id="exportMAnually" />
                            </Form.Group>
                            <Row>
                                <Col md={3}>
                                    <Form.Group controlId="ExportAbsenceForm.txtStartDate">
                                        <Form.Label>&nbsp;</Form.Label>
                                        <div><DatePicker className="form-control" selected={AbsenceStartDate} onChange={date => setAbsenceStartDate(date)} PlaceholderText={ct('startDate')} disabled={!DisabledInput} /></div>
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group controlId="ExportAbsenceForm.txtEndDate">
                                        <Form.Label>&nbsp;</Form.Label>
                                        <div><DatePicker className="form-control" selected={AbsenceEndDate} onChange={date => setAbsenceEndDate(date)} PlaceholderText={ct('endDate')} disabled={!DisabledInput} /></div>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="AbsenceForm.SelectStatus">
                                        <Form.Label>Status</Form.Label>
                                        <Form.Control value={StatusType} onChange={(e) => setStatusType(e.target.value)} as="select" className="SelectbgGrey form-select" name='StatusType' disabled={!DisabledInput} custom>
                                            <option value="All"> {apt('selectExportMdl.allAbsences')} </option>
                                            <option value="confirmed">{apt('selectExportMdl.confirmedAbsences')}</option>
                                            <option value="pending">{apt('selectExportMdl.openAbsences')}</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <div className="d-flex justify-content-end">
                                        <Form.Group controlId="AbsenceForm.SelectStatus">
                                            <Form.Label>&nbsp;</Form.Label>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic" className="btnPrimaryGreen">
                                                    {ct('export')}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#" onClick={() => ExportAbsence("XLSX")}>Excel</Dropdown.Item>
                                                    <Dropdown.Item href="#" onClick={() => ExportAbsence("CSV")}>CSV</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Form.Group>
                                    </div>
                                </Col>
                            </Row>


                        </Col>
                        <Col md={12} className="pt-3"><h5 className='fw-bold mb-2 text-uppercase'> {apt('selectExportMdl.exportOfVacation')}</h5></Col>
                        <Col md={12}>
                            <div className="smallInfoText pb-2 ps-3"> {apt('selectExportMdl.exportOfVacationPara')}</div>
                        </Col>
                        <Container>
                            <Row>
                                <Col md={5}>
                                    <Form.Group controlId="ExportVACATIONForm.StartDate">
                                        <div><DatePicker className="form-control" selected={new Date(VACATIONStartDate)} onChange={date => setVACATIONStartDate(date)} PlaceholderText={ct('startDate')} /></div>
                                    </Form.Group>
                                </Col>
                                <Col md={5}>
                                    <Form.Group controlId="ExportVACATIONForm.EndDate">
                                        <div><DatePicker className="form-control" selected={new Date(VACATIONEndDate)} onChange={date => setVACATIONEndDate(date)} PlaceholderText={ct('endDate')} /></div>
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <div className="d-flex justify-content-end">
                                        <Form.Group controlId="AbsenceForm.SelectStatus">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic" className="btnPrimaryGreen">
                                                    {ct('export')}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#" onClick={() => ExportVacation("XLSX")}>Excel</Dropdown.Item>
                                                    <Dropdown.Item href="#" onClick={() => ExportVacation("CSV")}>CSV</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Form.Group>
                                    </div>
                                </Col>
                            </Row>
                        </Container>

                    </Row>

                </Modal.Body>
            </Modal>
        </>
    )
}