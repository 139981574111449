import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { TiArrowUnsorted } from "react-icons/ti";
import ReactPaginate from 'react-paginate';
import { TableWrapper } from '../Helpers/Global/StyledComponents';
import usePagination from '../Helpers/usePagination';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";


function ServerSideDataGrid(props) {
    const { t: ct } = useTranslation('common');

    const { columns, rows, customBody, customFooter, hidesecondTH, itemsPerPage } = props;
    var user = useSelector(x => x.app.user);
    const [tblRows, setTblRows] = useState([]);

    const {
        skip,
        take,
        updateCount,
        isMultiPage,
        currentPage,
        pageCount,
        handlePaginate,
    } = usePagination({
        take: itemsPerPage,
    });

    useEffect(() => {
        setTblRows(rows)
    }, [rows]);

    const SearchData = (e, key) => {
        let value = e.target.value;
        let newData = rows.filter(x => x[key]?.toString().toLowerCase().includes(value.toString().toLowerCase()));
        setTblRows(newData)
    }

    const SortData = (key) => {
        let prevData = [...tblRows];
        prevData.sort((a, b) => a[key] > b[key] ? 1 : -1);
        setTblRows(prevData)
    }

    useEffect(() => {
        if (props.loadData && !!user.EncLoginUserId) {
            props.loadData({
                skip,
                take,
                updateCount,
                isMultiPage,
                currentPage,
                pageCount,
                handlePaginate,
            })
        }
    }, [currentPage, user.EncLoginUserId, skip, take]);

    useEffect(() => {
        if (props.getPaginateInfo) {
            props.getPaginateInfo({ skip, take, updateCount })
        }
    }, [skip, take, currentPage]);

    return (
        <TableWrapper>
            <Table responsive striped bordered>
                <thead>
                    <tr className='firsthead'>
                        {columns.map(x => <th>{x.name} {x?.sortable === true && <TiArrowUnsorted onClick={(e) => SortData(x.key)} />}</th>)}
                    </tr>
                    {!hidesecondTH && <tr className='bg-white'>
                        {columns.map(x => <th>{x?.searchable === true && <input type='text' onInput={(e) => SearchData(e, x.key)} placeholder={ct('search')} disabled={x.disableSearch} className='form-control form-control-sm' />}</th>)}
                    </tr>
                    }
                </thead>
                <tbody>
                    {!!customBody ? customBody(tblRows) :

                        tblRows?.map(y =>
                            <tr>
                                {columns?.map(x =>
                                    <td>
                                        {y[x.key]}
                                    </td>)}
                            </tr>
                        )
                    }
                </tbody>
                <tfoot>
                    {customFooter ?
                        customFooter
                        : null
                    }
                </tfoot>
            </Table>
            <div className='mt-3'>
                <ReactPaginate
                    nextLabel={ct('next') + " >"}
                    // onPageChange={handlePageClick}
                    onPageChange={(e) => handlePaginate(e.selected + 1)}
                    // pageRangeDisplayed={3}
                    // marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={"< " + ct('previous')}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                />
            </div>
        </TableWrapper>
    );
}

export default ServerSideDataGrid;