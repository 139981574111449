import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Card, Col, Container, Form, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { GiPriceTag } from 'react-icons/gi';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";
import { AxiosLite, CallAxios } from '../../../Helpers';
import { ContentBox, SubHeadTitle, SubHeaderTitleBox } from '../../../Helpers/Global/StyledComponents';
import { Constants } from '../../../Helpers/Contents';
import { CheckCircle } from '../../../Components/ActionBtns';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import InputGroup from 'react-bootstrap/InputGroup';
import { BsBank, BsCreditCardFill } from 'react-icons/bs';
import styled from 'styled-components';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { withMask } from 'use-mask-input';
import { UpdateReduxStates } from '../../../Helpers/MasterData';
import { SignInManager } from '../../../Helpers/Authentication/SignInManager';
import { useTranslation } from "react-i18next";
import { config } from '../../../Helpers/Config';
const BGGREY = styled.div`
background:#eee;
`

function SubscriptionIndex() {
    const { t: ct } = useTranslation('common');
    const { t: st } = useTranslation('subscription');


    let location = useLocation();
    const themeColorBtn = useSelector(state => state.app.theme.colors.buttons);
    const [activeKey, setActiveKey] = useState('Subscriptions');
    useEffect(() => {
        console.log(location)
        if (location.state !== null) {
            setActiveKey(location.state.tab)
        }
    }, [location]);
    return (
        <>
            <ContentBox className='subs-font-fix'>
                <Container fluid>
                    <Row>
                        <Col md={4} >
                            <SubHeaderTitleBox>
                                <GiPriceTag color={themeColorBtn} size={22} />
                                <SubHeadTitle>{st('pageTitle')}</SubHeadTitle>
                            </SubHeaderTitleBox>

                        </Col>
                        <Col md={8}>
                            <div className="my-3">
                            </div>
                        </Col>
                        <Col md={12} className="mt-3 bg-white p-2 border">
                            <div className="classicTab custom-tabs-font">
                                <Tabs className='nav-underline' activeKey={activeKey} onSelect={(selected) => setActiveKey(selected)} transition={false} id="noanim-tab-example">
                                    <Tab eventKey="Subscriptions" title={st("yourSubscriptionsTab.tabTitle")}>
                                        <YourSubscriptions />
                                    </Tab>
                                    <Tab eventKey="Plans" title={st("plansTab.tabTitle")}>
                                        <Plans />
                                    </Tab>
                                    <Tab eventKey="Addons" title={st("addonsTab.tabTitle")}>
                                        <Addons />
                                    </Tab>
                                </Tabs>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </ContentBox>
        </>
    );
}

export default SubscriptionIndex;

function YourSubscriptions(props) {
    const { t: ct } = useTranslation('common');
    const { t: st } = useTranslation('subscription');


    const [PlanList, setPlanList] = useState(null);
    const [ShowOrderModal, setShowOrderModal] = useState(false);
    const [SelectedSubscription, setSelectedSubscription] = useState(null);
    useEffect(() => {
        getData();
    }, []);
    const getData = async () => {
        var res = await AxiosLite("/api/SubscriptionPlan/GetCompanySubscriptionList|get")
        if (!!res && res.status === 200) {
            setPlanList(res.data)
        }
    }
    const OrderNow = async () => {

    }

    return (
        <>
            {
                ShowOrderModal &&
                <OrderModal show={ShowOrderModal} onHide={setShowOrderModal} SelectedSubscription={SelectedSubscription} setSelectedSubscription={setSelectedSubscription} getData={getData} />
            }
            <Container fluid className='subs-tab-font-fix'>
                <div className='pt-2'>
                    <Row className='p-2'>
                        {
                            !!PlanList &&
                            <Col md={3}>
                                <div className='border rounded p-3 h-100'>
                                    <div className='d-flex flex-column h-100 justify-content-between'>
                                        <div>
                                            <p className='font12'>{st("yourSubscriptionsTab.package")}: <b> {PlanList?.subscriptionName}</b></p>

                                            <p className='font12'>{st("employees")}: <b> {PlanList?.employeeCreated}/ {PlanList?.employeeAssigned}</b></p>

                                            <p className='font12'>{st("yourSubscriptionsTab.individualPrice")}: <b> {PlanList?.individualPrice}€</b></p>

                                            <p className='font12'>{st("yourSubscriptionsTab.status")}: <b>{PlanList?.status}</b>  </p>

                                            <p className='font12'>{st("yourSubscriptionsTab.periodLeft")}: <b> {PlanList?.daysLeft} Days</b></p>
                                        </div>
                                        <div>
                                            <Button variant="primary" className='d-block w-100' onClick={(e) => { setSelectedSubscription(PlanList?.subscriptionId); setShowOrderModal(true) }}>
                                                {st("yourSubscriptionsTab.orderNow")}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        }
                    </Row>
                </div>
            </Container>
        </>
    )
}
function Plans(props) {
    const { t: ct } = useTranslation('common');
    const { t: st } = useTranslation('subscription');


    const [PlanList, setPlanList] = useState([]);
    const [ShowOrderModal, setShowOrderModal] = useState(false);
    const [SelectedSubscription, setSelectedSubscription] = useState(null);

    useEffect(() => {
        getData();
    }, []);
    const getData = async () => {
        var res = await AxiosLite("/api/SubscriptionPlan/GetSubscriptionList|get")
        if (!!res && res.status === 200) {
            setPlanList(res.data)
        }
    }
    return (
        <>
            <div className='subs-tab-font-fix'>
                {
                    ShowOrderModal &&
                    <OrderModal show={ShowOrderModal} onHide={setShowOrderModal} SelectedSubscription={SelectedSubscription} setSelectedSubscription={setSelectedSubscription} getData={getData} />
                }
                <Row className="mt-4 text-center" >
                    <Col md={12}>
                        <h5 className="text-center">{st("plansTab.allPricePara")}</h5>
                    </Col>
                </Row>
                <Row className="mt-4 justify-content-center">
                    {
                        PlanList.length > 0 &&
                        PlanList.map((val, key) => {
                            return <Col md={3} key={key}>
                                <Card className="mb-2 text-center border h-100">
                                    <Card.Header className='plan-head'>{val.planName}</Card.Header>
                                    <Card.Body className='d-flex justify-content-between flex-column'>
                                        <div>
                                            <Card.Text className='plan-description'>
                                                {val.subscriptionPlanFeature.map((v, i) =>
                                                    <p>{v.key} </p>
                                                )}
                                            </Card.Text>
                                            <ul className='list-price ps-0 m-0'>
                                                <li className='price-list'><strong className='price-font'>{val.priceYearly}€</strong><p>{st("plansTab.billedAnnually")}</p></li>
                                                <li className='price-list'><span className='short-head'>{val.priceMonthly}€</span><p>{st("plansTab.billedMonthly")}</p></li>
                                                {/* <li className='price-list'></li> */}
                                            </ul>
                                        </div>
                                        <Button className='plan-btn-color' size="lg" onClick={(e) => { setSelectedSubscription(val.id); setShowOrderModal(true) }}>{st("select")}</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        })
                    }
                </Row>
                <Row className="mt-4 ps-3" >
                    <Col md={12}>
                        <p className='text-center-001' style={{ color: '#bdc3c7' }}>{st("plansTab.allPricePerMonth")}</p>
                    </Col>
                </Row>
                <Row className="mt-1 ps-3"  >
                    <Col md={12}>
                        <h5 className='text-center-001'>{st("plansTab.moreThan100Emp")}</h5>
                    </Col>
                </Row>
                <Row className="mt-1 ps-3" >
                    <Col md={12}>
                        <h5 className='text-center-001'>{st("plansTab.via")} <b>enterprise@bastashift.com</b> or <b>+44 20 12345689.</b></h5>
                    </Col>
                </Row>
            </div>
        </>
    )
}
function Addons(props) {
    const { t: ct } = useTranslation('common');
    const { t: st } = useTranslation('subscription');


    return (
        <>
            <div className='subs-tab-font-fix'>

                <Row className="mt-4" >
                    <Col md={3} className='mb-2'>
                        <Card

                            className=" text-center border h-100"
                        >
                            <Card.Header className='plan-head'>HR</Card.Header>
                            <Card.Body>
                                <ul className='list-price-addon ps-0 m-0 '>
                                    <li className='price-list'><strong className='price-font'>4€</strong><p>{st("plansTab.billedAnnually")}</p></li>
                                    <li className='price-list'><span className='short-head'>5€</span><p>{st("plansTab.billedMonthly")}</p></li>
                                    <li className='price-list'></li>
                                </ul>
                                <div>
                                    <Button variant='primary' className='plan-btn-color' size="lg" disabled>{st("select")}</Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3} className='mb-2'>
                        <Card

                            className=" text-center border h-100"
                        >
                            <Card.Header className='plan-head'>{st("addonsTab.payroll")}</Card.Header>
                            <Card.Body>
                                <ul className='list-price-addon ps-0 m-0 '>
                                    <li className='price-list'><strong className='price-font'>3€</strong><p>{st("plansTab.billedAnnually")}</p></li>
                                    <li className='price-list'><span className='short-head'>4€</span><p>{st("plansTab.billedMonthly")}</p></li>
                                    {/* <li className='price-list price-btn'><Button className='plan-btn-color' size="lg" disabled>Select</Button></li> */}
                                </ul>
                                <div>
                                    <Button variant='primary' className='plan-btn-color' size="lg" disabled>{st("select")}</Button>
                                    <p className='text-dark pt-2'>{st("addonsTab.tryTrail30")}</p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3} className='mb-2'>
                        <Card

                            className=" text-center border h-100"
                        >
                            <Card.Header className='plan-head'>{st("addonsTab.events")}</Card.Header>
                            <Card.Body>
                                <ul className='list-price-addon ps-0 m-0 '>
                                    <li className='price-list'><strong className='price-font'>1€</strong><p>{st("plansTab.billedAnnually")}</p></li>
                                    <li className='price-list'><span className='short-head'>1.50€</span><p>{st("plansTab.billedMonthly")}</p></li>
                                    {/* <li className='price-list price-btn'></li> */}
                                </ul>
                                <div>
                                    <Button className='plan-btn-color' size="lg" disabled>{st("select")}</Button>
                                    <p className='text-dark pt-2'>{st("addonsTab.tryTrail30")}</p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3} className='mb-2'>
                        <Card

                            className=" text-center border h-100"
                        >
                            <Card.Header className='plan-head'>{st("insights")}</Card.Header>
                            <Card.Body>
                                <ul className='list-price-addon ps-0 m-0'>
                                    <li className='price-list'><strong className='price-font'>1.50€</strong><p>{st("plansTab.billedAnnually")}</p></li>
                                    <li className='price-list'><span className='short-head'>2€</span><p>{st("plansTab.billedMonthly")}</p></li>
                                    {/* <li className='price-list price-btn'></li> */}
                                </ul>
                                <div>
                                    <Button className='plan-btn-color' size="lg" disabled>{st("select")}</Button>
                                    <p className='text-dark pt-2'>{st("addonsTab.tryTrail7")}</p>

                                </div>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3} className='mb-2'>
                        <Card

                            className=" text-center border h-100"
                        >
                            <Card.Header className='plan-head'>{st("workflows")}</Card.Header>
                            <Card.Body>
                                <ul className='list-price-addon ps-0 m-0'>
                                    <li className='price-list'><strong className='price-font'>2€</strong><p>{st("plansTab.billedAnnually")}</p></li>
                                    <li className='price-list'><span className='short-head'>2.50€</span><p>{st("plansTab.billedMonthly")}</p></li>
                                    {/* <li className='price-list price-btn'></li> */}
                                </ul>
                                <div>
                                    <Button className='plan-btn-color' size="lg" disabled>{st("select")}</Button>
                                    <p className='text-dark pt-2'>{st("addonsTab.tryTrail30")}</p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3} className='mb-2'>
                        <Card

                            className=" text-center border  h-100"
                        >
                            <Card.Header className='plan-head'>{st("addonsTab.aPI")}</Card.Header>
                            <Card.Body>
                                <ul className='list-price-addon ps-0 m-0'>
                                    <li className='price-list'><strong className='price-font'>399€</strong><p>{st("plansTab.billedAnnually")}</p></li>
                                    <li className='price-list'><span className='short-head'>399€</span><p>{st("plansTab.billedMonthly")}</p></li>
                                    {/* <li className='price-list price-btn'></li> */}
                                </ul>
                                <div>
                                    <Button className='plan-btn-color' size="lg" disabled>{st("select")}</Button>
                                    <p className='text-dark pt-2'>{st("addonsTab.tryTrail30")}</p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3} className='mb-2'>
                        <Card

                            className=" text-center border  h-100"
                        >
                            <Card.Header className='plan-head'>{st("custom")}</Card.Header>
                            <Card.Body>
                                <Button className='plan-btn-color' size="lg">{st("inquire")}</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}
function OrderModal(props) {
    const { t: st } = useTranslation('subscription');
    const { t: ast } = useTranslation('accountSetting');
    const { t: invt } = useTranslation('invoicePage');
    const { t: ct } = useTranslation('common');

    const dispatch = useDispatch();
    const [Plan, setPlan] = useState(null);
    const [tab2, setTab2] = useState(true);
    const [tab3, setTab3] = useState(true);
    const [cardNumberType, setCardNumberType] = useState(Constants.PaymentType.Card);
    const [activeIndex, setActiveIndex] = useState(0);
    const [tabkey, setTabkey] = useState("BillingAddress");

    useEffect(() => {
        getbaseData()
        getPackageDetail()
    }, []);
    const getbaseData = async () => {
        var response = await AxiosLite("/api/AccountSetting/basedata|get");
        if (!!response && response.status === 200) {
            formik.setFieldValue("companyName", response.data.companyName)
            formik.setFieldValue("Adress", response.data.address || "")
            formik.setFieldValue("City", response.data.city || "")
            formik.setFieldValue("FederalState", response.data.federalState || "DE")
            formik.setFieldValue("Country", response.data.country || "")
            formik.setFieldValue("VatID", response.data.vatidNo || "")
            formik.setFieldValue("InvoiceHolder", response.data.invoiceHolder || "")
            formik.setFieldValue("invoiceEmail", response.data.invoiceEmail || "")
            formik.setFieldValue("Postcode", response.data.postcode || "")
        }
    }
    const getPackageDetail = async () => {
        var res = await AxiosLite("/api/SubscriptionPlan/GetCompanySubscriptionPlanById?planId=" + props.SelectedSubscription + "|get")
        if (!!res && res.status === 200) {
            setPlan(res.data)
            formik.setFieldValue("NumberOfEmployees", res.data.currentPlanNoOfEmployees.toString())
            if (Constants.SubscriptionPeriod.Trial !== res.data.currentPlanSubscriptionPeriod)
                formik.setFieldValue("BillingPeriod", res.data.currentPlanSubscriptionPeriod.toString())
        }
    }

    const validateArr = [
        Yup.object().shape({
            companyName: Yup.string()
                .required(ast('baseDataTab.errorMsg.company')),
            Adress: Yup.string()
                .required(ast('baseDataTab.errorMsg.address')),
            Postcode: Yup.string()
                .required(ast('baseDataTab.errorMsg.postal')),
            City: Yup.string()
                .required(ast('baseDataTab.errorMsg.city')),
            InvoiceHolder: Yup.string()
                .required(ast('baseDataTab.errorMsg.billing')),
            invoiceEmail: Yup.string()
                .email(ast('baseDataTab.errorMsg.billingEmail'))
                .required(ast('baseDataTab.errorMsg.billingEmail')),
        }),
        Yup.object().shape({
            BillingPeriod: Yup.string()
                .required(st('errorMsg.billingPeriod')),
            NumberOfEmployees: Yup.string()
                .required(st('errorMsg.numberEmployee')),
        }),
        Yup.object().shape({
            CardNumber: Yup.string().max(16, st('errorMsg.cardNumberNotValid')).required(st('errorMsg.cardNumberRequire')),
            Name: Yup.string().required(st('errorMsg.nameRequire')),
            CVC: Yup.string().max(4, st('errorMsg.cardNumberNotValid')).required(st('errorMsg.cvcRequire')),
        }),
        Yup.object().shape({

        })
    ]
    const formik = useFormik({
        initialValues: {
            companyName: "",
            Adress: "",
            City: "",
            FederalState: "",
            Country: "DE",
            VatID: "",
            InvoiceHolder: "",
            invoiceEmail: "",
            Postcode: "",

            PlanId: props.SelectedSubscription,
            NumberOfEmployees: "10",
            BillingPeriod: Constants.SubscriptionPeriod.Monthly.toString(),

            focus: "",

            // PaymentType: Constants.PaymentType.Card,
            CardNumber: "",
            Expiry: "",
            Name: "",
            CVC: "",
            IbanNumber: "",
            EnableAutoBilling: true

        },
        validationSchema: cardNumberType === Constants.PaymentType.Paypal ? validateArr[3] : validateArr[activeIndex],
        onSubmit: values => {
            if (activeIndex === 0) {
                UpdateCompanyInfo(values)
                return;
            }
            if (activeIndex === 1) {
                setTabkey("Purchase")
                setTab3(false)
                return;
            }
            if (activeIndex === 2) {
                onSubmit(values)
            }
        },
    });
    useEffect(() => {
        if (!!formik.values.NumberOfEmployees) {
            if (parseInt(formik.values.NumberOfEmployees) < Plan?.currentPlanNoOfEmployees && Plan?.isCurrentPlanTrial === false) {
                formik.setFieldValue("NumberOfEmployees", Plan.currentPlanNoOfEmployees.toString())
                toast.error(invt("errorMsg.employeesLessThen"))
            }
        }
    }, [formik.values.NumberOfEmployees]);
    useEffect(() => {
        if (!!formik.values.BillingPeriod) {
            if (formik.values.BillingPeriod < Plan?.currentPlanSubscriptionPeriod && Plan?.isCurrentPlanTrial === false) {
                formik.setFieldValue("BillingPeriod", Plan.currentPlanSubscriptionPeriod)
                toast.error(invt("errorMsg.billingPeriod"))
            }
        }
    }, [formik.values.BillingPeriod]);
    const onSubmit = async () => {
        if (cardNumberType === Constants.PaymentType.Card) {
            var month = parseInt(formik.values.Expiry.split("/")[0]);
            var year = parseInt(formik.values.Expiry.split("/")[1]);
            if (!formik.values.Expiry || month < 1 || month > 12 || year < 23) {
                toast.error(invt("errorMsg.expiryNotValid"))
                return
            }
            if (!formik.values.CardNumber) {
                toast.error(invt("errorMsg.CardNumber"))
                return
            }
            if (!formik.values.Name) {
                toast.error(invt("errorMsg.nameBlank"))
                return
            }
            if (!formik.values.CVC) {
                toast.error(invt("errorMsg.CVCblank"))
                return
            }
        }
        // else {
        //     if (!formik.values.IbanNumber) {
        //         toast.error("Paypal number is required")
        //         return
        //     }
        // }
        var data = {
            SubscriptionPlanId: props.SelectedSubscription,
            NoOfEmployee: parseInt(formik.values.NumberOfEmployees),
            Period: parseInt(formik.values.BillingPeriod),
            CardOrIbanNumber: cardNumberType === Constants.PaymentType.Card ? formik.values.CardNumber.toString() : formik.values.IbanNumber.toString(),
            PaymentType: cardNumberType,
            ExpiryMonth: month,
            ExpiryYear: year,
            Name: formik.values.Name,
            CVC: formik.values.CVC.toString(),
            State: window.location.href,
            EnableAutoBilling: formik.values.EnableAutoBilling

        }
        var res = await CallAxios("/api/SubscriptionPlan/PurchaseSubscriptionPlan|post", data)
        if (!!res && res.status === 200) {
            if (res.data.success === true) {
                if (cardNumberType === Constants.PaymentType.Paypal) {
                    window.open(config.API_URL + "/api/SubscriptionPlan/PaypalPaymentRedirection?url=" + encodeURIComponent(res.data.message))
                } else {
                    await UpdateReduxStates(new SignInManager().ParseJwt, dispatch)
                    toast.success(st('errorMsg.paymentSuccessfull'))
                    props.getData();
                    props.setSelectedSubscription(null);
                    props.onHide(false)
                }
            }
            else {
                toast.error(res.data.message)
            }
        }
    }
    const UpdateCompanyInfo = async (values) => {

        var data = {
            companyName: values.companyName,
            address: values.Adress,
            Postcode: values.Postcode,
            city: values.City,
            federalState: values.FederalState,
            country: values.Country,
            VATIDNo: values.VatID,
            InvoiceHolder: values.InvoiceHolder,
            invoiceEmail: values.invoiceEmail,
        }
        var response = await CallAxios("/api/AccountSetting/updatebasedata|post", data);
        if (!!response && response.status === 200 && response.data.success === true) {
            if (!!response.data.message) {
                toast.info(response.data.message)
            } 
            else if (response.data.message === "ComapnyNameTaken") {
                toast.error("Company name alreday exist")
            }
            else {
                setTabkey("Subscription")
                setTab2(false)
            }
        } else {
            toast.info(st('errorMsg.someWentWrong'))
        }
    }
    useEffect(() => {
        setActiveIndex(tabkey === "BillingAddress" ? 0 : tabkey === "Subscription" ? 1 : 2);
    }, [tabkey]);

    return (
        <>
            <Modal show={props.show} className="largeModalDialog" onHide={() => { props.setSelectedSubscription(null); props.onHide(false) }}>
                <Modal.Header closeButton className="">
                    <Modal.Title>{st("subscriptionsModal.modalTitle")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs
                        defaultActiveKey="BillingAddress"
                        transition={false}
                        id="SubscriptionTabs"
                        onSelect={(k) => setTabkey(k)}
                        className="mb-3 nav-underline"
                        activeKey={tabkey}
                    >
                        <Tab eventKey="BillingAddress" title={st("subscriptionsModal.billingAddressTab")}>
                            <Row>
                                <Col md={6}>
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group className="mb-3" controlId="AccountBaseData.txtCompanyName">
                                                <Form.Label>{st("subscriptionsModal.copanyName")}</Form.Label>
                                                <Form.Control name='companyName' type="text" maxLength={150} value={formik.values.companyName} onChange={formik.handleChange} />
                                                {formik.touched.companyName && formik.errors.companyName ? (
                                                    <span className="error text-danger text-left">{formik.errors.companyName}</span>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className="mb-3" controlId="AccountBaseData.txtAddress">
                                                <Form.Label>{st("subscriptionsModal.address")}</Form.Label>
                                                <Form.Control type="text" name="Adress" maxLength={250} value={formik.values.Adress} onChange={formik.handleChange} />
                                                {formik.touched.Adress && formik.errors.Adress ? (
                                                    <span className="error text-danger text-left">{formik.errors.Adress}</span>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className="mb-3" controlId="AccountBaseData.txtPostcode">
                                                <Form.Label>{st("subscriptionsModal.postal")}</Form.Label>
                                                <Form.Control type="text" name="Postcode" maxLength={50} value={formik.values.Postcode} onChange={formik.handleChange} />
                                                {formik.touched.Postcode && formik.errors.Postcode ? (
                                                    <span className="error text-danger text-left">{formik.errors.Postcode}</span>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className="mb-3" controlId="AccountBaseData.txtCity">
                                                <Form.Label>{st("subscriptionsModal.city")}</Form.Label>
                                                <Form.Control type="text" name="City" maxLength={150} value={formik.values.City} onChange={formik.handleChange} />
                                                {formik.touched.City && formik.errors.City ? (
                                                    <span className="error text-danger text-left">{formik.errors.City}</span>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className="mb-3" controlId="AccountBaseData.txtFederalState">
                                                <Form.Label>{st("subscriptionsModal.state")}</Form.Label>
                                                <Form.Control type="text" name="FederalState" value={formik.values.FederalState} onChange={formik.handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className="mb-3" controlId="AccountBaseData.SelectCountry">
                                                <Form.Label>{st("subscriptionsModal.country")}</Form.Label>
                                                <Form.Select as="select" name="Country" value={formik.values.Country} onChange={formik.handleChange} custom>
                                                    <option value="AD">AD</option>
                                                    <option value="AL">AL</option>
                                                    <option value="AT">AT</option>
                                                    <option value="AU">AU</option>
                                                    <option value="BA">BA</option>
                                                    <option value="BE">BE</option>
                                                    <option value="BF">BF</option>
                                                    <option value="BG">BG</option>
                                                    <option value="BY">BY</option>
                                                    <option value="CH">CH</option>
                                                    <option value="CN">CN</option>
                                                    <option value="CO">CO</option>
                                                    <option value="CY">CY</option>
                                                    <option value="CZ">CZ</option>
                                                    <option value="DE">DE</option>
                                                    <option value="DK">DK</option>
                                                    <option value="EC">EC</option>
                                                    <option value="EE">EE</option>
                                                    <option value="EG">EG</option>
                                                    <option value="ES">IT</option>
                                                    <option value="FI">FI</option>
                                                    <option value="FO">FO</option>
                                                    <option value="FR">FR</option>
                                                    <option value="GB">GB</option>
                                                    <option value="GE">GE</option>
                                                    <option value="GR">GR</option>
                                                    <option value="HR">MR</option>
                                                    <option value="HU">HU</option>
                                                    <option value="ID">ID</option>
                                                    <option value="IE">IE</option>
                                                    <option value="IS">IS</option>
                                                    <option value="IL">IL</option>
                                                    <option value="IT">IT</option>
                                                    <option value="LI">LI</option>
                                                    <option value="LT">LT</option>
                                                    <option value="LU">LU</option>
                                                    <option value="LV">LV</option>
                                                    <option value="MA">MA</option>
                                                    <option value="MC">MC</option>
                                                    <option value="MD">MD</option>
                                                    <option value="MK">MK</option>
                                                    <option value="MT">MT</option>
                                                    <option value="NL">NL</option>
                                                    <option value="NO">NO</option>
                                                    <option value="NP">NP</option>
                                                    <option value="PL">PL</option>
                                                    <option value="PT">PT</option>
                                                    <option value="RS">RS</option>
                                                    <option value="RU">RU</option>
                                                    <option value="SE">SE</option>
                                                    <option value="SI">SI</option>
                                                    <option value="SK">SK</option>
                                                    <option value="SM">SM</option>
                                                    <option value="SY">SY</option>
                                                    <option value="TR">TR</option>
                                                    <option value="UA">UA</option>
                                                    <option value="US">US</option>
                                                    <option value="VA">VA</option>
                                                    <option value="VN">VN</option>
                                                    <option value="YE">YE</option>
                                                    <option value="YU">YU</option>
                                                    <option value="ZA">ZA</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className="mb-3" controlId="AccountBaseData.txtVATID">
                                                <Form.Label>{st("subscriptionsModal.vatID")}</Form.Label>
                                                <Form.Control type="text" name="VatID" maxLength={50} value={formik.values.VatID} onChange={formik.handleChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className="mb-3" controlId="AccountBaseData.txtInvoiceHolder">
                                                <Form.Label>{st("subscriptionsModal.billingOwner/Contact")}</Form.Label>
                                                <Form.Control type="text" name="InvoiceHolder" maxLength={150} value={formik.values.InvoiceHolder} onChange={formik.handleChange} />
                                                {formik.touched.InvoiceHolder && formik.errors.InvoiceHolder ? (
                                                    <span className="error text-danger text-left">{formik.errors.InvoiceHolder}</span>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className="mb-3" controlId="AccountBaseData.txtInvoiceEmail">
                                                <Form.Label>{st("subscriptionsModal.billingEmail")}</Form.Label>
                                                <Form.Control type="email" name="invoiceEmail" maxLength={150} value={formik.values.invoiceEmail} onChange={formik.handleChange} />
                                            </Form.Group>
                                            {formik.touched.invoiceEmail && formik.errors.invoiceEmail ? (
                                                <span className="error text-danger text-left">{formik.errors.invoiceEmail}</span>
                                            ) : null}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row>

                                    </Row>
                                </Col>

                            </Row>
                        </Tab>
                        <Tab eventKey="Subscription" title={st("subscriptionsModal.subscriptionTab")} disabled={tab2}>
                            <Row>
                                <Col md={6}>
                                    <Row>
                                        <Col md={12}>
                                            {st("subscriptionsModal.product")}: {Plan?.planName}
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className="mb-3" controlId="AccountBaseData.txtNumOfEmp">
                                                <Form.Label>{st("subscriptionsModal.numberOfEmployees")}</Form.Label>
                                                <Form.Select as="select" className="SelectbgGrey" name='NumberOfEmployees' value={formik.values.NumberOfEmployees} onChange={formik.handleChange} custom>
                                                    {
                                                        Constants.noOfEmployeeDropDown.map((val, key) => {
                                                            return <option key={"noOfEmployee" + key} value={val.value}>{val.text}</option>
                                                        })
                                                    }
                                                </Form.Select>
                                                {formik.touched.noOfEmployeeDropDown && formik.errors.noOfEmployeeDropDown ? (
                                                    <span className="error text-danger text-left">{formik.errors.noOfEmployeeDropDown}</span>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className="mb-3" controlId="AccountBaseData.txtNumOfEmp">
                                                <Form.Label>{st("subscriptionsModal.billingPeriod")}</Form.Label>
                                                <Form.Select as="select" className="SelectbgGrey" name='BillingPeriod' value={formik.values.BillingPeriod} onChange={formik.handleChange} custom>
                                                    <option value={Constants.SubscriptionPeriod.Monthly}>{st("subscriptionsModal.month")}</option>
                                                    <option value={Constants.SubscriptionPeriod.Yearly}>{st("subscriptionsModal.yearly")}</option>
                                                </Form.Select>
                                                {formik.touched.BillingPeriod && formik.errors.BillingPeriod ? (
                                                    <span className="error text-danger text-left">{formik.errors.BillingPeriod}</span>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            {st("subscriptionsModal.summary")}: {Plan?.planName} {st("subscriptionsModal.forUpto")} {formik.values.NumberOfEmployees} {st("employees")}
                                        </Col>
                                        <Col md={12}>
                                            {st("subscriptionsModal.resultingCost")}:
                                        </Col>
                                        <Col md={12}>
                                            {formik.values.BillingPeriod === Constants.SubscriptionPeriod.Monthly.toString() ? 49 : 490} € {st("subscriptionsModal.baseFee")}
                                        </Col>
                                        <Col md={12}>
                                            + {
                                                formik.values.BillingPeriod === Constants.SubscriptionPeriod.Monthly.toString() ?
                                                    (parseFloat(formik.values.NumberOfEmployees) * Plan?.priceMonthly) + " € " + st("subscriptionsModal.licenseFee") + " (" + formik.values.NumberOfEmployees + ' ' + ct("users") + " x " + Plan?.priceMonthly + "€)"
                                                    :
                                                    (parseFloat(formik.values.NumberOfEmployees) * Plan?.priceYearly) * 12 + " € " + st("subscriptionsModal.licenseFee") + " (" + formik.values.NumberOfEmployees + ' ' + ct("users") + " x " + Plan?.priceYearly * 12 + "€)"
                                            }
                                        </Col>
                                        {
                                            (Plan?.isCurrentPlanTrial === false && Plan.debitAmountFromCurrentPlan > 0) &&
                                            <Col md={12}>
                                                -{Plan.debitAmountFromCurrentPlan} {st("subscriptionsModal.currentPlan")}
                                            </Col>
                                        }
                                        <Col md={12}>
                                            =
                                            {
                                                formik.values.BillingPeriod === Constants.SubscriptionPeriod.Monthly.toString() ?
                                                    (49 + (parseFloat(formik.values.NumberOfEmployees) * Plan?.priceMonthly)) - (Plan?.isCurrentPlanTrial === false ? Plan.debitAmountFromCurrentPlan : 0) :
                                                    (490 + (parseFloat(formik.values.NumberOfEmployees) * (Plan?.priceYearly * 12))) - (Plan?.isCurrentPlanTrial === false ? Plan.debitAmountFromCurrentPlan : 0)
                                            }
                                            € {ct("per")} {formik.values.BillingPeriod == Constants.SubscriptionPeriod.Yearly.toString() ? <>{ct("year")}</> : <>{ct("month")}</>} ({ct("net")})
                                        </Col>

                                        {
                                            formik.values.BillingPeriod === "year" &&
                                            <>
                                                <Col md={12}>
                                                    <CheckCircle color='#2ecc71' />
                                                    {

                                                        (((Plan?.priceMonthly - Plan?.priceYearly) / Plan?.priceMonthly) * 100).toFixed(2)
                                                    }
                                                    % {st("subscriptionsModal.monthlyDiscount")}
                                                </Col>
                                                <Col md={12}>
                                                    <CheckCircle color='#2ecc71' />
                                                    {st("subscriptionsModal.premiumSupport")}
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                </Col>
                                <Col md={6}>
                                </Col>
                            </Row>

                        </Tab>
                        <Tab eventKey="Purchase" title={st("subscriptionsModal.purchase")} disabled={tab3}>
                            <Row>
                                <Col md={6}>
                                    <ButtonGroup onClick={(e) => setCardNumberType(parseInt(e.target.name))} aria-label="Basic example" size="sm" className="btnSelectGroup mb-3">
                                        <Button name={Constants.PaymentType.Card} variant={cardNumberType === Constants.PaymentType.Card ? "primary" : "secondary"} className='makeitselect font-14' >{st("subscriptionsModal.creditCard")}</Button>
                                        <Button name={Constants.PaymentType.Paypal} variant={cardNumberType === Constants.PaymentType.Paypal ? "primary" : "secondary"} className='makeitselect font-14' >Paypal</Button>
                                    </ButtonGroup>
                                    {cardNumberType === Constants.PaymentType.Card &&
                                        <>
                                            <InputGroup className="mb-3">
                                                <Cards
                                                    number={formik.values.CardNumber}
                                                    expiry={formik.values.Expiry}
                                                    cvc={formik.values.CVC}
                                                    name={formik.values.Name}
                                                    focused={formik.values.focus}
                                                />
                                            </InputGroup>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text><BsCreditCardFill /></InputGroup.Text>
                                                <Form.Control aria-label="Card Number" name='CardNumber' onFocus={() => formik.setFieldValue("focus", "number")} className='w-50' pattern="[\d| ]{16,22}" type="number" value={formik.values.CardNumber} onChange={formik.handleChange} placeholder={st("subscriptionsModal.cardNumber")} />
                                                <Form.Control aria-label="MM/YY" ref={withMask('99/99')} type='text' placeholder='MM/YY' onFocus={() => formik.setFieldValue("focus", "expiry")} className='w-25' name='Expiry' value={formik.values.Expiry} onChange={formik.handleChange} />
                                                {formik.touched.CardNumber && formik.errors.CardNumber ? (
                                                    <span className="error text-danger text-left">{formik.errors.CardNumber}</span>
                                                ) : null}
                                                {formik.touched.Expiry && formik.errors.Expiry ? (
                                                    <span className="error text-danger text-left">{formik.errors.Expiry}</span>
                                                ) : null}
                                            </InputGroup>
                                            <InputGroup className="mb-3">
                                                <Form.Control aria-label="Card Number" name='Name' onFocus={() => formik.setFieldValue("focus", "name")} className='w-50' type="text" value={formik.values.Name} onChange={formik.handleChange} placeholder={st("subscriptionsModal.name")} />
                                                {formik.touched.Name && formik.errors.Name ? (
                                                    <span className="error text-danger text-left">{formik.errors.Name}</span>
                                                ) : null}
                                            </InputGroup>
                                            <InputGroup className="mb-3">
                                                <Form.Control aria-label="Card Number" name='CVC' onFocus={() => formik.setFieldValue("focus", "cvc")} className='w-50' maxLength='4' type="number" value={formik.values.CVC} onChange={formik.handleChange} placeholder='CVC' />
                                                {formik.touched.CVC && formik.errors.CVC ? (
                                                    <span className="error text-danger text-left">{formik.errors.CVC}</span>
                                                ) : null}
                                            </InputGroup>
                                        </>
                                    }
                                    {cardNumberType === Constants.PaymentType.Paypal &&
                                        <>
                                            <InputGroup className="mb-3">
                                                {/* <InputGroup.Text><BsBank /></InputGroup.Text>
                                            <Form.Control name='IbanNumber' type="text" value={formik.values.IbanNumber} onChange={formik.handleChange} placeholder='DE00 0000 0000 0000 0000 00' /> */}
                                                <Button className="btn btn-success mx-2" onClick={() => { formik.handleSubmit(); }} >Pay with Paypal</Button>
                                            </InputGroup>
                                            <InputGroup className="mb-3">
                                                <Form.Group className="mb-3">
                                                    <Form.Check className="text-left" name="EnableAutoBilling" checked={formik.values.EnableAutoBilling} onChange={formik.handleChange} type="checkbox" label="Enable auto billing" />
                                                </Form.Group>
                                            </InputGroup>
                                        </>
                                    }
                                    {formik.touched.IbanNumber && formik.errors.IbanNumber ? (
                                        <span className="error text-danger text-left">{formik.errors.IbanNumber}</span>
                                    ) : null}

                                    <p className='small'>{st("subscriptionsModal.onPurchase")} <b>{st("subscriptionsModal.terms")}</b> {st("subscriptionsModal.and")} <b>{st("subscriptionsModal.privacyPolicy")}</b></p>

                                </Col>
                                <Col md={6}>
                                    <BGGREY className=' border p-3'>
                                        <p>{st("subscriptionsModal.thankYouPara")} {Plan?.planName} {st("subscriptionsModal.plan")}.</p>
                                        <p>{st("subscriptionsModal.paymentInformationPara1")} <b>{st("subscriptionsModal.SSLEncypted")}</b> {st("subscriptionsModal.paymentInformationPara2")}</p>
                                        <p>{st("subscriptionsModal.quetionsPara")}</p>
                                        <p><b>E-Mail: support@basta-shitf.de</b></p>
                                    </BGGREY>
                                </Col>
                            </Row>

                        </Tab>
                    </Tabs>
                </Modal.Body >
                <Modal.Footer>
                    <Button className="btn btn-success mx-2" onClick={() => { formik.handleSubmit(); }} >{tabkey === "Purchase" ? <>{ct("subscribe")}</> : <>{ct("continue")}</>}</Button>
                </Modal.Footer>
            </Modal >
        </>
    )
}

