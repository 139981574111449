import { saveAs } from 'file-saver';
import moment from "moment";
import { useEffect, useState } from "react";
import { Badge, Dropdown, Nav, OverlayTrigger, Popover, Table, Tooltip } from "react-bootstrap";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import DatePicker from 'react-datepicker';
import { FaEdit, FaInfoCircle, FaStickyNote } from "react-icons/fa";
import { GoPrimitiveDot } from "react-icons/go";
import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { CheckCircle, Edit, ExclaimationCircle, Gear, Note } from "../../Components/ActionBtns";
import { AxiosLite, CallAxios, CallAxiosBlob } from "../../Helpers";
import { Constants } from "../../Helpers/Contents";
import { TableWrapper } from "../../Helpers/Global/StyledComponents";
import { UpdateTimeTrackBadge } from "../../Helpers/MasterData";
import { SignatureModel, TimeTrackNoteModel } from "../TimeTracking/TimeTrackingGrid";
import { AddEditTimeTrackingModel } from "../TimeTracking/TimeTrackingList";
import { toast } from 'react-toastify';
import ServerSideDataGrid from '../../Components/ServerSideDataGrid';
import { useTranslation } from "react-i18next";





function TimeTrackingTab() {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');

    const cols = [
        { key: '', name: '', sortable: false, searchable: false },
        { key: 'workSpaceName', name: t('timeTrackTab.areasTags').toString(), sortable: true, searchable: true },
        { key: 'date', name: ct('day').toString(), sortable: true, searchable: true },
        { key: 'time', name: ct('time').toString(), sortable: true, searchable: true },
        { key: 'duration', name: ct('duration').toString(), sortable: true, searchable: true },
        { key: 'breaks', name: ct('break').toString(), sortable: true, searchable: true },
        { key: 'net', name: ct('net').toString(), sortable: true, searchable: true },
        { key: 'regiserdBy', name: t('timeTrackTab.recordedBy').toString(), sortable: true, searchable: true },
        { key: 'status', name: ct('status').toString() },
        { key: 'action', name: ct('action').toString() },
    ];


    const { userid } = useParams();
    const user = useSelector(x => x.app.user)
    const appSettings = useSelector(x => x.app.appSettings)
    const currentView = useSelector(x => x.app.currentView)
    const dispatch = useDispatch();
    const [TimeTrackingList, setTimeTrackingList] = useState([]);
    const [LocationBreaks, setLocationBreaks] = useState([]);
    const [Breaks, setBreaks] = useState([]); //breaks assigned to employye  
    const [NetBreak, setNetBreak] = useState("");
    const [StartPeriod, setStartPeriod] = useState(moment().startOf('month')._d);
    const [EndPeriod, setEndPeriod] = useState(moment().endOf('month')._d);
    const [show, setshow] = useState(false);
    const [timeTrackIdForEdit, setTimeTrackIdForEdit] = useState("");
    const [showSignatureModal, setShowSignatureModal] = useState(false);
    const [showNoteModal, setShowNoteModal] = useState(false);
    const [Breakdiv, setBreakdiv] = useState(false);
    const [NewAssignBreeakingTypes, setAssignNewBreeakingTypes] = useState([]);
    const [createOwnTimeRecords, setCreateOwnTimeRecords] = useState(false);
    const themeColor = useSelector(state => state.app.theme.colors.bg);
    const [PaginateInfo, setPaginateInfo] = useState(null);

    useEffect(() => {
        onLoad()
        // getTimeTrackList()
        if (currentView === Constants.layoutType.Employee) {
            GetEmpRights()
        }
    }, []);
    const getPaginateInfo = (prop) => {
        setPaginateInfo(prop)
    }
    const GetEmpRights = async () => {
        var response = await CallAxios('/api/Employee/GetEmpRights|post', { id: user.EncLoginUserId })
        if (!!response && response.status === 200) {
            setCreateOwnTimeRecords(response.data.createOwnTimeRecords)
        }
    }
    const onLoad = async () => {
        var response = await CallAxios("/api/TimeTracking/getEmpBreaks|post", { id: userid });
        if (!!response && response.status === 200) {
            setBreaks(response.data)
        }

        var responses = await AxiosLite(
            "/api/TimeTracking/getLocationBreaks|get"
        );
        if (!!responses && responses.status === 200) {
            let LocationMultiselectArray = [];
            responses.data.map((val) => LocationMultiselectArray.push({ key: val.locationTimeBreakId, label: " > " + val.hour + 'h (' + val.minutes + 'm)' }))
            setLocationBreaks(LocationMultiselectArray)
        }
    }
    const getTimeTrackList = async (prop) => {
        if (StartPeriod > EndPeriod) {
            toast.error(t('timeTrackTab.errorMsg.dateError').toString())
            return
        }
        if (!prop && !!PaginateInfo) {
            prop = PaginateInfo
        }
        var data = {
            empid: parseInt(userid),
            startPeriod: StartPeriod,
            endPeriod: EndPeriod,
            Skip: prop.skip,
            Take: prop.take
        }
        var response = await CallAxios("/api/TimeTracking/EmployeeTimeTrackingList|post", data);
        if (response.data.length > 0) {
            setNetBreak(response.data.list.map(x => parseFloat(x.net.replace(":", "."))).reduce((a, v) => a = a + v, 0))
        }
        setTimeTrackingList(response.data.list)
        prop.updateCount(response.data.count)

    }
    const UpdateStatus = async (id) => {
        var response = await CallAxios("/api/TimeTracking/UpdateTimeTracking|post", { id: id });
        if (!!response && response.status === 200) {
            // UpdateTimeTrackBadge(dispatch);
            getTimeTrackList()
        }
    }
    const ConfirmInPeriod = async (id) => {
        var data = {
            empid: parseInt(userid),
            startPeriod: StartPeriod,
            endPeriod: EndPeriod,
        }
        var response = await CallAxios("/api/TimeTracking/ConfirmAllInTimeFromforEmp|post", data);
        if (!!response && response.status === 200) {
            getTimeTrackList()
            // setTimeTrackingList(response.data)

        }
    }
    const assignBreaks = async () => {
        setBreakdiv(false)
        var data = {
            empid: parseInt(userid),
            ids: NewAssignBreeakingTypes
        }
        var response1 = await CallAxios("/api/TimeTracking/AssignBreaks|post", data);
        var response = await CallAxios("/api/TimeTracking/getEmpBreaks|post", { id: userid });
        setBreaks(response.data)
    }
    const ExportTimeTracking = async (format) => {

        var data = {
            EmpId: userid,
            format: format,
            StartDate: moment(StartPeriod).startOf('day'),
            EndDate: moment(EndPeriod).startOf('day')
        }
        var response = await CallAxiosBlob("/api/TimeTracking/EmployeeTimeTrackingsExport|post", data);

        if (!!response && response.status === 200) {
            if (format === 'CSV') {
                saveAs(response.data, "TimeTracking.csv")
            }
            else if (format === 'XLSX') {
                saveAs(response.data, "TimeTracking.xlsx")
            }
        }
    }

    return <>
        {show === true ?
            <AddEditTimeTrackingModel show={show} onHide={setshow} getTimeTrackList={getTimeTrackList} timeTrackIdForEdit={timeTrackIdForEdit} />
            : null
        }
        {
            showSignatureModal === true ?
                <SignatureModel show={showSignatureModal} onHide={setShowSignatureModal} timeTrackIdForEdit={timeTrackIdForEdit} />
                : null
        }
        {
            showNoteModal === true ?
                <TimeTrackNoteModel show={showNoteModal} onHide={setShowNoteModal} timeTrackIdForEdit={timeTrackIdForEdit} />
                : null
        }
        <div className="DetailInfoWrapper">
            <div className='mb-3 d-flex justify-content-between align-items-center'>
                <h4 className='h5 fw-bold mb-0'> {t('timeTracking')}</h4>
            </div>
            <div className='d-flex mb-3 justify-content-between align-items-center'>

                <div className='d-flex'>
                    <div className='me-2'>
                        <DatePicker className="form-control " selected={!!StartPeriod ? StartPeriod : new Date()} onChange={(date) => { setStartPeriod(date) }} />
                    </div>
                    <div className='me-2'>
                        <DatePicker className="form-control" selected={!!EndPeriod ? EndPeriod : new Date()} onChange={(date) => setEndPeriod(date)} />
                    </div>
                    <button className='btn btn-primary btn-sm ' onClick={() => getTimeTrackList()}>{ct('show')}</button>
                </div>
                <div className="d-flex  justify-content-between align-items-center">
                    {
                        (user.roles.some(x => x === Constants.roles.AccountAdmin || x === Constants.roles.WorkspaceAdmin || x === Constants.roles.Admin) ||
                            (createOwnTimeRecords === true && currentView === Constants.layoutType.Employee)) ?
                            <button className='btn btn-primary me-2' onClick={(e) => { setTimeTrackIdForEdit(""); setshow(true) }}> {t('timeTrackTab.newTimeTracking')}</button>
                            : null
                    }
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic" className="btnPrimaryGreen minWidthDD">
                            {ct('export')}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="#" onClick={() => ExportTimeTracking("XLSX")}>Excel</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => ExportTimeTracking("CSV")}>CSV</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div className=' text-center well'>
                {/* <TimeTrackingDetailTable startDate={StartPeriod} endDate={EndPeriod} UpdateListTrigger={UpdateListTrigger} setNetBreak={setNetBreak} isFromEmployee={true} fnupdateTablePeriodTrigge={fnupdateTablePeriodTrigger} updateTablePeriod={updateTablePeriod} UpdatetableTrigger={UpdateTbale} fnUpdatetableTrigger={fnUpdatetableTrigger} changeDatainTableArea={changeDatainTableArea} showArea={showArea} WorkingAreaSelections={WorkingAreaSelections} /> */}
                {
                    user.roles.some(x => x === Constants.roles.AccountAdmin || x === Constants.roles.WorkspaceAdmin || x === Constants.roles.Admin) && currentView == Constants.layoutType.Admin ?
                        <Row>
                            <Col md={12}>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                        <p className='fw-bold mb-0'> {t('timeTrackTab.hoursPeriod')}: <span className='fw-light'>{!!NetBreak && parseFloat(NetBreak)?.toFixed(2)}</span></p>
                                    </div>
                                    <Nav className="justify-content-end" activeKey="/home">
                                        <Nav.Item>
                                            <Nav.Link href="#" onClick={() => ConfirmInPeriod()}>
                                                <span className="HeaderLinkText"> {t('timeTrackTab.confirmTimeTrack')} </span>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </Col>
                        </Row>
                        : null
                }

                <TableWrapper>
                    <ServerSideDataGrid
                        columns={cols}
                        rows={TimeTrackingList}
                        itemsPerPage={10}
                        customBody={(rows) =>
                            rows?.map((value, i) =>
                                <tr className='text-left'>
                                    <td>
                                        <div className="dataTextCell">
                                            {!!value.runningstatus && value.runningstatus === "Start" ? <GoPrimitiveDot style={{ color: "#2ecc71", fontSize: "25px" }} /> : !!value.runningstatus && value.runningstatus === "Stop" ? <GoPrimitiveDot style={{ color: "yellow", fontSize: "25px" }} /> : null}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dataTextCell white-space-nowrap">
                                            {value.workSpaceName} | {!!appSettings.tags && appSettings.tags.length > 0 ? appSettings.tags.filter(x => value.tagids.some(d => d === x.value)).map(x => x.text).join(",") : ""}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dataTextCell white-space-nowrap">
                                            {value.date}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dataTextCell white-space-nowrap">
                                            {value.time}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dataTextCell">
                                            {value.duration} h
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dataTextCell">
                                            {value.breaks} h
                                            {
                                                value.breaksDetail.length > 0 ?

                                                    <ExclaimationCircle color='#a4a4a4' className="ml-1" data-tooltip-id="my-tooltip" data-tooltip-html={
                                                        value.breaksDetail.map((vv, ii) => {

                                                            return `<div>${vv}</div>`
                                                        }
                                                        )
                                                    } />
                                                    : null
                                            }
                                        </div>

                                    </td>
                                    <td>
                                        <div className="dataTextCell">
                                            {value.net} h
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dataTextCell">
                                            <Badge className="badgedanger"> {value.regiserdBy}</Badge>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dataTextCell">
                                            {value.status === true ?
                                                <Badge className="badgesuccess"> {ct('confirmed')}</Badge>
                                                : <Badge className="badgedanger"> {ct('unconfirmed')}</Badge>}
                                            {
                                                value.haveNote &&
                                                <Note data-tooltip-id="my-tooltip" data-tooltip-html={
                                                    ` <div className='minwidth150px'>
                                                        <div className='header bg-light p-3'>
                                                          <h6 className='mb-0 fw-bold' style={{ fontSize: "12px" }}>${value.haveNote}</h6>
                                                          <span style={{ fontSize: "10px" }}>${value.haveNote}</span>
                                                        </div>
                                                        <div className='popoverBody p-3'>
                                                          <span style={{ fontSize: "12px" }}> ${value.haveNote}  </span>
                                                        </div>
                                                      </div>`
                                                } />
                                            }
                                        </div>
                                    </td>
                                    <td className="largSpaceIconWidth">
                                        <div className='d-flex justify-content-center'>
                                            {
                                                value.runningstatus === null || value.runningstatus === 'Complete' ?
                                                    // <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip>To edit</Tooltip>}>
                                                    <a data-tooltip-id="my-tooltip" data-tooltip-content={ct('toEdit')} className="btnTblAction bgBlue" onClick={() => { setTimeTrackIdForEdit(value.id); setshow(true) }}><Gear /></a>
                                                    // </OverlayTrigger>
                                                    : null
                                            }
                                            {/* <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip>Attached note</Tooltip>}> */}
                                            <a data-tooltip-id="my-tooltip" data-tooltip-content={ct('attachedNote')} className="btnTblAction bgYellow" onClick={() => { setTimeTrackIdForEdit(value.id); setShowNoteModal(true) }}><Note /></a>
                                            {/* </OverlayTrigger> */}
                                            {
                                                (value.runningstatus === null || value.runningstatus === 'Complete') && value.regiserdBy === 'Station-App' && value.haveSignature === true ?
                                                    <a className="btnTblAction" onClick={() => { setTimeTrackIdForEdit(value.id); setShowSignatureModal(true) }}><FaEdit /></a>
                                                    : null
                                            }
                                            {
                                                value.runningstatus === null || value.runningstatus === 'Complete' ?
                                                    value.status === false && user.roles.some(x => x === Constants.roles.AccountAdmin || x === Constants.roles.WorkspaceAdmin || x === Constants.roles.Admin) && currentView == Constants.layoutType.Admin ?
                                                        // <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip>To confirm</Tooltip>}>
                                                        <a data-tooltip-id="my-tooltip" data-tooltip-content={ct('toConfirm')} className="btnTblAction bgGreen" onClick={() => UpdateStatus(value.id)}> <CheckCircle /></a>
                                                        // </OverlayTrigger>
                                                        : null
                                                    : null
                                            }
                                        </div>
                                    </td>
                                </tr>
                            )}
                        getPaginateInfo={(p) => getPaginateInfo(p)}
                        loadData={(p) => getTimeTrackList(p)}

                        customFooter={<tr className='text-left'>
                            <th style={{ width: "30px" }} className="NumberDataCell"> {ct('total')}</th>
                            <th></th>
                            <th>{TimeTrackingList.length}</th>
                            <th></th>
                            <th>{TimeTrackingList.reduce((a, v) => a = parseFloat(a) + parseFloat(v.duration.replace(":", ".")), 0).toFixed(2)}h</th>
                            <th>{TimeTrackingList.reduce((a, v) => a = parseFloat(a) + parseFloat(v.breaks.replace(":", ".")), 0).toFixed(2)}h</th>
                            <th>{TimeTrackingList.reduce((a, v) => a = parseFloat(a) + parseFloat(v.net.replace(":", ".")), 0).toFixed(2)}h</th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>} />
                    {/* <Table striped bordered responsive className="DataGridTable">
                        <thead>
                            <tr className='firsthead'>
                                <th style={{ width: "30px" }} className="NumberDataCell"></th>
                                <th>Areas | Tags</th>
                                <th>Day</th>
                                <th>Times</th>
                                <th>Duration</th>
                                <th>Break</th>
                                <th>Net</th>
                                <th>Recorded by</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                            <tr>
                                <th style={{ width: "30px" }}><Form.Control type="text" disabled /></th>
                                <th><Form.Control type="text" /></th>
                                <th><Form.Control type="text" /></th>
                                <th><Form.Control type="text" /></th>
                                <th><Form.Control type="text" /></th>
                                <th><Form.Control type="text" /></th>
                                <th><Form.Control type="text" /></th>
                                <th><Form.Control type="text" disabled /></th>
                                <th><Form.Control type="text" disabled /></th>
                                <th><Form.Control type="text" disabled /></th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                !!TimeTrackingList && TimeTrackingList.length > 0 ? TimeTrackingList.map((value, key) => {
                                    return <tr className='text-left'>
                                        <td>
                                            <div className="dataTextCell">
                                                {!!value.runningstatus && value.runningstatus === "Start" ? <GoPrimitiveDot style={{ color: "#2ecc71", fontSize: "25px" }} /> : !!value.runningstatus && value.runningstatus === "Stop" ? <GoPrimitiveDot style={{ color: "yellow", fontSize: "25px" }} /> : null}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dataTextCell white-space-nowrap">
                                                {value.workSpaceName} | {!!appSettings.tags && appSettings.tags.length > 0 ? appSettings.tags.filter(x => value.tagids.some(d => d === x.value)).map(x => x.text).join(",") : ""}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dataTextCell white-space-nowrap">
                                                {value.date}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dataTextCell white-space-nowrap">
                                                {value.time}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dataTextCell">
                                                {value.duration} h
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dataTextCell">
                                                {value.breaks} h
                                                {
                                                    value.breaksDetail.length > 0 ?

                                                        <ExclaimationCircle color='#a4a4a4' className="ml-1" data-tooltip-id="my-tooltip" data-tooltip-html={
                                                            value.breaksDetail.map((vv, ii) => {

                                                                return `<div>${vv}</div>`
                                                            }
                                                            )
                                                        } />
                                                        : null
                                                }
                                            </div>

                                        </td>
                                        <td>
                                            <div className="dataTextCell">
                                                {value.net} h
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dataTextCell">
                                                <Badge className="badgedanger"> {value.regiserdBy}</Badge>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dataTextCell">
                                                {value.status === true ?
                                                    <Badge className="badgesuccess">Confirmed</Badge>
                                                    : <Badge className="badgedanger">Unconfirmed</Badge>}
                                                {
                                                    value.haveNote &&
                                                    <Note data-tooltip-id="my-tooltip" data-tooltip-html={
                                                        ` <div className='minwidth150px'>
                                                        <div className='header bg-light p-3'>
                                                          <h6 className='mb-0 fw-bold' style={{ fontSize: "12px" }}>${value.haveNote}</h6>
                                                          <span style={{ fontSize: "10px" }}>${value.haveNote}</span>
                                                        </div>
                                                        <div className='popoverBody p-3'>
                                                          <span style={{ fontSize: "12px" }}> ${value.haveNote}  </span>
                                                        </div>
                                                      </div>`
                                                    } />
                                                }
                                            </div>
                                        </td>
                                        <td className="largSpaceIconWidth">
                                            <div className='d-flex justify-content-center'>
                                                {
                                                    value.runningstatus === null || value.runningstatus === 'Complete' ?
                                                        <a data-tooltip-id="my-tooltip" data-tooltip-content="To edit" className="btnTblAction bgBlue" onClick={() => { setTimeTrackIdForEdit(value.id); setshow(true) }}><Gear /></a>
                                                        : null
                                                }
                                                <a data-tooltip-id="my-tooltip" data-tooltip-content="Attached note" className="btnTblAction bgYellow" onClick={() => { setTimeTrackIdForEdit(value.id); setShowNoteModal(true) }}><Note /></a>
                                                {
                                                    (value.runningstatus === null || value.runningstatus === 'Complete') && value.regiserdBy === 'Station-App' && value.haveSignature === true ?
                                                        <a className="btnTblAction" onClick={() => { setTimeTrackIdForEdit(value.id); setShowSignatureModal(true) }}><FaEdit /></a>
                                                        : null
                                                }
                                                {
                                                    value.runningstatus === null || value.runningstatus === 'Complete' ?
                                                        value.status === false && user.roles.some(x => x === Constants.roles.AccountAdmin || x === Constants.roles.WorkspaceAdmin || x === Constants.roles.Admin) && currentView == Constants.layoutType.Admin ?
                                                            <a data-tooltip-id="my-tooltip" data-tooltip-content="To confirm" className="btnTblAction bgGreen" onClick={() => UpdateStatus(value.id)}> <CheckCircle /></a>
                                                            : null
                                                        : null
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                }) : null
                            }
                        </tbody>
                        <tfoot>
                            <tr className='text-left'>
                                <th style={{ width: "30px" }} className="NumberDataCell">Total</th>
                                <th></th>
                                <th>{TimeTrackingList.length}</th>
                                <th></th>
                                <th>{TimeTrackingList.reduce((a, v) => a = parseFloat(a) + parseFloat(v.duration.replace(":", ".")), 0).toFixed(2)}h</th>
                                <th>{TimeTrackingList.reduce((a, v) => a = parseFloat(a) + parseFloat(v.breaks.replace(":", ".")), 0).toFixed(2)}h</th>
                                <th>{TimeTrackingList.reduce((a, v) => a = parseFloat(a) + parseFloat(v.net.replace(":", ".")), 0).toFixed(2)}h</th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </tfoot>
                    </Table> */}
                </TableWrapper>

            </div>
            {
                user.roles.length === 1 && user.roles.some(x => x === Constants.roles.Employee) ?
                    null :
                    <div className=' '>
                        <h5 className='h5 fw-bold mb-2'> {ct('breaks')}</h5>

                        {
                            Breakdiv === false ?
                                <div className='d-flex'>
                                    {
                                        !!Breaks && Breaks.length > 0 ?
                                            Breaks.map((val, key) => {
                                                return <p className='mb-0 me-1'>{'> ' + val.hour + 'h (' + val.minutes + 'm), '}</p>
                                            })
                                            : null
                                    }
                                    {
                                        user.roles.some(x => x === Constants.roles.AccountAdmin || x === Constants.roles.WorkspaceAdmin || x === Constants.roles.Admin) ?
                                            <Edit color={themeColor} onClick={(e) => setBreakdiv(true)} />
                                            : null
                                    }
                                </div>
                                :
                                <div className='d-flex align-items-center w-50'>
                                    <div className="TblDDList Multiselect HideSelectAll">
                                        {!!LocationBreaks && LocationBreaks.length > 0 ?

                                            <DropdownMultiselect selected={!!Breaks && Breaks.length > 0 ? Breaks.map((val) => val.locationTimeBreakId) : ""} handleOnChange={(selected) => setAssignNewBreeakingTypes(selected)} options={LocationBreaks} placeholder={ct('break')} buttonClass="btnDDList smWidth" /> : null}
                                    </div>
                                    <div>
                                        <button className='btn btn-primary  mx-3' onClick={(e) => assignBreaks()}> {ct('ready')}</button>
                                    </div>
                                </div>
                        }
                    </div>
            }
        </div >
    </>
}

export default TimeTrackingTab;
