import styled from 'styled-components';
import Menu, { AccountMenu } from './Menu';
import { useEffect, useState } from "react";

import { useSelector } from 'react-redux';
import { CollapseMenu, DisplayStart } from '../../Helpers/Global/StyledComponents';
import { Sidebar as ReactProSidebar } from 'react-pro-sidebar';
import LogoMenu from './logoMenu';
import { useLocation } from 'react-router-dom';


const Sidebarbg = styled.div`
    height:100%;
    width:100%;
`
const SideBarLogo = styled.img`
width:150px;
height:auto;
object-fit:contain;
margin-left:15px;
margin-bottom:15px;
`

const AccountSidebar = (props) => {
    let location = useLocation();
    const {isCollapse ,setIsCollapse} = props;
    const [showLogoMenu, setShowLogoMenu] = useState(false);

    const themeColor = useSelector(state => state.app.theme.colors.bg);
    
    useEffect(() => {
        setShowLogoMenu(false)
    }, [location]);
    return (
        <Sidebarbg className='sideBarMenu'>
         
        <DisplayStart>
            {!isCollapse ?
                <SideBarLogo onClick={() => setShowLogoMenu(!showLogoMenu)} src="/assets/WhiteLogo.png" />
                :
                <SideBarLogo onClick={() => setShowLogoMenu(!showLogoMenu)} style={{ height: '33px', width:'auto' }} src="/assets/WhiteFavicon.png" />
            }
        </DisplayStart>
        {showLogoMenu &&  <LogoMenu isCollapse={isCollapse} /> }
        <ReactProSidebar width="200px" backgroundColor={themeColor} collapsed={isCollapse}>
        <AccountMenu />

        </ReactProSidebar>
    </Sidebarbg>
    );
}

export default AccountSidebar;
