import { useFormik } from 'formik';
import moment from "moment";
import { useEffect, useState } from "react";
import { Dropdown, Table } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import DatePicker from 'react-datepicker';
import { BsFillGearFill, BsInfoCircle } from "react-icons/bs";
import { FaArrowRight } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import Swal from "sweetalert2";
import * as Yup from 'yup';
import { Gear, ListIcon, Remove } from "../../Components/ActionBtns";
import { CallAxios } from "../../Helpers";
import { Constants } from "../../Helpers/Contents";
import { DetailsGreyBox, RequiredText, StatusBox, TableWrapper } from "../../Helpers/Global/StyledComponents";
import { useTranslation } from "react-i18next";

function VocationTab() {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');


    const { userid } = useParams();
    const user = useSelector(x => x.app.user)

    const [Breakdiv, setBreakdiv] = useState(false);
    const [show, setshow] = useState(false);
    const [vacationIdforEdit, setvacationIdforEdit] = useState("");
    const [VocationList, setVocationList] = useState([]);
    const [OverView, setOverView] = useState({});
    const [OverViewTill, setOverViewTill] = useState({});
    const [ShowVocationSummery, setShowVocationSummery] = useState(false);
    const [ConsumedVocationForEdit, setConsumedVocationForEdit] = useState(0);
    const [ShowDeleteVacation, setShowDeleteVacation] = useState(false);
    useEffect(() => {
        getdata()
    }, []);
    const getdata = async () => {
        var response = await CallAxios(
            "/api/VacationQuota/EmptacationQuotaList|post", { id: userid }
        );
        if (!!response && response.status === 200) {
            setVocationList(response.data)
        }
        var Vocationresponse = await CallAxios(
            "/api/VacationQuota/EmpQuotaDetail|post", { id: userid }
        );
        if (!!Vocationresponse && Vocationresponse.status === 200) {
            setOverView(Vocationresponse.data.overviewAll)
            setOverViewTill(Vocationresponse.data.vacationtill)
        }
    }
    useEffect(() => {
        if (VocationList.length === 0) {
            setBreakdiv(false)
        } else {
            setBreakdiv(true)
        }
    }, [VocationList]);

    const Deletevacation = async (id) => {
        var response = await CallAxios(
            "/api/VacationQuota/DeleteEmpacationQuota|post", { id: id }
        );
        if (!!response && response.status === 200) {
            setShowDeleteVacation(false)
            getdata()
        }
    }
    const TransferVacationQuata = async (id) => {
        var response = await CallAxios(
            "/api/VacationQuota/transferVacationQuota|post", { id: id }
        );
        if (!!response && response.data === true) {
            getdata()
        }
    }

    return <>
        <div className="DetailInfoWrapper">
            <div className='mb-2 d-flex justify-content-between align-items-center'>
                <h4 className='h5 fw-bold mb-0'> {t('vacationTab.holidayEntitlement')} </h4>
                <Dropdown>
                    <Dropdown.Toggle variant="primary" className='SettingIcon' id="dropdown-basic">
                        <BsFillGearFill />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Header> {t('vacationTab.calculateLeave')}:</Dropdown.Header>
                        <Dropdown.Item className="font-13"> {t('vacationTab.days')}</Dropdown.Item>
                        <Dropdown.Item className="font-13"> {t('vacationTab.hours')}</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div>
                <div className=''>
                    {Breakdiv === true ?
                        <>
                            <Row>
                                <Col md={6}>
                                    <Row>

                                        <Col md={12}>
                                            <span className=' fw-bold '> {t('vacationTab.overviewTotal')}</span>
                                            <div className='mt-2'>
                                                <DetailsGreyBox> {t('vacationTab.schedule')}: {!!OverView ? OverView.planned : "0.0"} {t('vacationTab.days')}</DetailsGreyBox>
                                                <DetailsGreyBox> {t('vacationTab.consumed')}: {!!OverView ? OverView.spent : "0.0"} {t('vacationTab.days')}</DetailsGreyBox>
                                                <DetailsGreyBox> {t('vacationTab.transferred')}: {!!OverView ? OverView.transferred : "0.0"} {t('vacationTab.days')}</DetailsGreyBox>
                                                <DetailsGreyBox> {t('vacationTab.left')}: {!!OverView ? OverView.remaining : "0.0"} {t('vacationTab.days')}</DetailsGreyBox>
                                            </div>
                                        </Col>


                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row>
                                        <Col md={12}>
                                            <span className=' fw-bold '> {t('vacationTab.vacationTill')} </span>
                                            <div className='mt-2'>
                                                <DetailsGreyBox>{t('vacationTab.schedule')}: {!!OverViewTill ? OverViewTill.planned : "0.0"} {t('vacationTab.days')}</DetailsGreyBox>
                                                <DetailsGreyBox>{t('vacationTab.consumed')}: {!!OverViewTill ? OverViewTill.spent : "0.0"} {t('vacationTab.days')}</DetailsGreyBox>
                                                <DetailsGreyBox> {t('vacationTab.transferred')}: {!!OverViewTill ? OverViewTill.transferred : "0.0"} {t('vacationTab.days')}</DetailsGreyBox>
                                                <DetailsGreyBox> {t('vacationTab.left')}: {!!OverViewTill ? OverViewTill.remaining : "0.0"} {t('vacationTab.days')}</DetailsGreyBox>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </>
                        : null}
                </div>
            </div>
            <div className='mb-3 d-flex justify-content-between align-items-center'>
                <h4 className='h5 fw-bold mb-0'> {t('vacationTab.vacationQuotas')}</h4>
                {
                    user.roles.some(x => x === Constants.roles.Admin || x === Constants.roles.AccountAdmin || x === Constants.roles.WorkspaceAdmin) ?
                        <button className='btn btn-primary' onClick={(e) => { setvacationIdforEdit(""); setshow(true) }}> {t('vacationTab.newVacationQuota')}</button>
                        : null
                }
            </div>
            <TableWrapper>
                <Table bordered striped responsive className="DataGridTable">
                    <thead>
                        <tr className="firsthead">
                            <th> {t('vacationTab.tbl.vacationQuota')}</th>
                            <th> {t('vacationTab.tbl.begin')}</th>
                            <th> {t('vacationTab.tbl.end')}</th>
                            <th> {t('vacationTab.tbl.status')}</th>
                            <th> {t('vacationTab.tbl.plan')}</th>
                            <th> {t('vacationTab.tbl.consumed')}</th>
                            <th> {t('vacationTab.tbl.transfer')}</th>
                            <th> {t('vacationTab.tbl.Actions')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !!VocationList && VocationList.length > 0 ? VocationList.map((val, key) => {

                                return <tr>
                                    <td>{val.name}</td>
                                    <td>{val.begin}	</td>
                                    <td>{val.end}	</td>
                                    <td>
                                        {
                                            val.plan === val.consumed || val.isTransfered === true ?
                                                <StatusBox className="badgedanger"> {t('vacationTab.used')} </StatusBox>
                                                :
                                                val.status === true ?
                                                    <div className='d-flex align-items-center'> <StatusBox status={'pending'} className="badgewarning flex-grow-1"> {t('vacationTab.expires')} </StatusBox> <BsInfoCircle className="ms-2" /></div>
                                                    :
                                                    <div className='d-flex align-items-center'><StatusBox status={'confirmed'} className="badgesuccess flex-grow-1"> {t('vacationTab.doesnotExpire')} </StatusBox> <BsInfoCircle className="ms-2" /></div>
                                        }
                                    </td>
                                    <td>{val.plan}	</td>
                                    <td>{!!val.consumed ? val.consumed : '0.0'}	</td>
                                    <td>{val.transfer}	</td>
                                    <td>
                                        <ListIcon className='btnTblAction' onClick={(e) => { setvacationIdforEdit(val.id); setShowVocationSummery(true) }} />
                                        {
                                            user.roles.some(x => x === Constants.roles.Admin || x === Constants.roles.AccountAdmin || x === Constants.roles.WorkspaceAdmin)
                                                ?
                                                <>
                                                    <Gear className='btnTblAction bgBlue' onClick={(e) => { setvacationIdforEdit(val.id); setConsumedVocationForEdit(val.consumed); setshow(true) }} />
                                                    {
                                                        val.consumed > 0 || val.isTransfered === true ?
                                                            null :
                                                            <Remove className='btnTblAction bgRed' onClick={(e) => { setvacationIdforEdit(val.id); setShowDeleteVacation(true) }} />
                                                    }
                                                    {
                                                        val.isTransfer === true ?
                                                            val.isTransfered === true ?
                                                                <a className='btnTblAction bg-warning'><FaArrowRight /></a>
                                                                :
                                                                <a className='btnTblAction bg-warning' onClick={(e) => { TransferVacationQuata(val.id) }}><FaArrowRight /></a>
                                                            : null
                                                    }
                                                </>
                                                : null
                                        }

                                    </td>
                                </tr>
                            }) : null
                        }

                    </tbody>
                </Table>
            </TableWrapper>
        </div>
        {
            ShowDeleteVacation &&
            <DeleteVacation show={ShowDeleteVacation} setshow={setShowDeleteVacation} vacationIdforEdit={vacationIdforEdit} Deletevacation={Deletevacation} setvacationIdforEdit={setvacationIdforEdit} />
        }
        {ShowVocationSummery === true ?
            <VocationSummeryModel vacationIdforEdit={vacationIdforEdit} show={ShowVocationSummery} setshow={setShowVocationSummery} />
            : null
        }

        {
            show === true ?
                <NewVacationModal getdata={getdata} setvacationIdforEdit={setvacationIdforEdit} ConsumedVocationForEdit={ConsumedVocationForEdit} vacationIdforEdit={vacationIdforEdit} show={show} setshow={setshow} />
                : null
        }
    </>
}

export default VocationTab;
function DeleteVacation(props) {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');

    return (
        <Modal show={props.show} onHide={() => { props.setvacationIdforEdit(""); props.setshow(false) }}>
            <Modal.Header closeButton>
                <Modal.Title> {t('vacationTab.deleteVacationMdl.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={12} >
                        {t('vacationTab.deleteVacationMdl.para')}?
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btnPrimaryGreen" onClick={() => props.setshow(false)}> {ct('cancel')}</Button>
                <Button className="btn btnPrimaryDanger" onClick={() => props.Deletevacation(props.vacationIdforEdit)}> {ct('yesRemoveNow')}</Button>
            </Modal.Footer>
        </Modal>

    );
}
function NewVacationModal(props) {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');

    const { userid } = useParams();
    const [editmode, seteditmode] = useState(false);
    const [epireVacation, setepireVacation] = useState(true);

    const initialValues = {
        surname: "",
        beginning: moment().startOf('year')._d,
        end: moment().endOf('year')._d,
        expires_after_expiry: true,
        holiday: 0.0,
        transfer_remaining_entitlement_to_new_quota: false,
        expireDate: moment().startOf('year').endOf('month').add(2, 'months').add(1, 'years')._d
    };

    useEffect(() => {
        if (!!props.vacationIdforEdit) {
            getData()
        }
    }, []);

    const getData = async () => {
        var response = await CallAxios(
            "/api/VacationQuota/getEmpacationQuota|post", { id: props.vacationIdforEdit }
        );
        if (!!response && !!response.data) {
            formik.setFieldValue('surname', response.data.name)
            formik.setFieldValue('beginning', new Date(response.data.beginingTime))
            formik.setFieldValue('end', new Date(response.data.endTime))
            formik.setFieldValue('expires_after_expiry', response.data.isExpires)
            formik.setFieldValue('holiday', response.data.holidays)
            formik.setFieldValue('transfer_remaining_entitlement_to_new_quota', response.data.isTransfer)
            formik.setFieldValue('expireDate', !!response.data.transfer_Expiration_Date ? new Date(response.data.transfer_Expiration_Date) : moment().startOf('year').endOf('month').add(2, 'months').add(1, 'years')._d)
            if (response.data.isExpires === false) {
                seteditmode(true)
            }
        }
    }

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            surname: Yup.string()
                .required(t('vacationTab.errorMsg.titleError').toString())
        }),
        onSubmit: values => {
            SubmitEvent(values)
        },
    });
    const SubmitEvent = (values) => {
        if (!!values.holiday && parseFloat(values.holiday) > 0) {
            if (!!props.vacationIdforEdit) {
                if ((!!props.ConsumedVocationForEdit ? parseFloat(props.ConsumedVocationForEdit) : 0) <= parseFloat(values.holiday)) {
                    //for edit
                    PostData(values)
                }
                else {
                    Swal.fire({
                        icon: 'info',
                        title: t('vacationTab.errorMsg.swalPlannedDays').toString(),
                    })
                }
            } else {
                PostData(values)
            }
        } else {
            Swal.fire({
                icon: 'info',
                title: t('vacationTab.errorMsg.swalHoliday').toString(),
            })
        }
    }
    const PostData = async (values) => {
        var data = {
            Id: props.vacationIdforEdit,
            EmployeeId: userid,
            Name: values.surname,
            BeginingTime: !!values.beginning ? values.beginning : null,
            EndTime: !!values.end ? values.end : null,
            IsExpires: values.expires_after_expiry,
            Holidays: parseFloat(values.holiday),
            IsTransfer: values.transfer_remaining_entitlement_to_new_quota,
            Transfer_Expiration_Date: !!values.expireDate ? values.expireDate : null,
        }
        var response = await CallAxios(
            "/api/VacationQuota/AddEditVacationQuota|post", data
        );
        if (!!response && response.status === 200) {
            props.getdata()
            props.setshow(false)
        }
    }
    const handleExpiresafterexpiration = (val) => {
        formik.setFieldValue('expires_after_expiry', !val)
        seteditmode(val)
    };
    const handleTransferExpirationDate = (val) => {
        formik.setFieldValue('transfer_remaining_entitlement_to_new_quota', !val)
        setepireVacation(val)
    };

    return (
        <Modal show={props.show} onHide={() => { props.setvacationIdforEdit(""); props.setshow(false) }}>
            <Modal.Header closeButton>
                <Modal.Title className="text-break">{!!props.vacationIdforEdit ? <>{ct('edit')}</> : <>{ct('new')}</>} {t('vacationTab.newVacationMdl.title')}
                    {
                        !!props.vacationIdforEdit ?
                            <p style={{ 'color': 'red' }} className="text-break mb-0">
                                {t('vacationTab.newVacationMdl.vacationQuotaError')}.
                            </p>
                            : null
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={12} className='mb-2'>
                        <Form.Group controlId="NewShiftForm.txtDay">
                            <Form.Label> {t('vacationTab.newVacationMdl.titleLabel')}</Form.Label>
                            <Form.Control type="text" name="surname" maxLength={150} onChange={formik.handleChange} value={formik.values.surname} placeholder={t('vacationTab.newVacationMdl.titlePlaceholder') + " 2017"} />
                            <RequiredText>
                                {formik.touched.surname && formik.errors.surname
                                    ? formik.errors.surname
                                    : null}
                            </RequiredText>
                        </Form.Group>
                    </Col>
                    <Col md={6} className='mb-2'>
                        <Form.Group controlId="NewPeriodForm.txtBeginning">
                            <Form.Label> {t('vacationTab.newVacationMdl.beginning')}</Form.Label>
                            <DatePicker className="form-control" name="beginning" onChange={(date) => formik.setFieldValue("beginning", date)} disabled={!!props.ConsumedVocationForEdit && parseFloat(props.ConsumedVocationForEdit) > 0 ? 'disabled' : ''} selected={formik.values.beginning} />
                        </Form.Group>
                    </Col>
                    <Col md={6} className='mb-2'>
                        <Form.Group controlId="NewPeriodForm.txtend">
                            <Form.Label> {t('vacationTab.newVacationMdl.end')}</Form.Label>
                            <DatePicker className="form-control" name="end" onChange={(date) => formik.setFieldValue("end", date)} disabled={!!props.ConsumedVocationForEdit && parseFloat(props.ConsumedVocationForEdit) > 0 ? 'disabled' : ''} selected={formik.values.end} />
                        </Form.Group>
                    </Col>
                    <Col md={12} className='mb-2'>
                        <Form.Group controlId="NewVacationForm.txtExpiry">
                            <Form.Check inline name="expires_after_expiry" disabled={!!props.vacationIdforEdit ? 'disabled' : ''} onChange={() => handleExpiresafterexpiration(formik.values.expires_after_expiry)} checked={formik.values.expires_after_expiry} label={t('vacationTab.newVacationMdl.afterExpiryCheckbox')} className="boldlabeltext" type="checkbox" id="CheckExpiry" />
                        </Form.Group>
                    </Col>
                    <Col md={12} className='mb-2'>
                        <Form.Group controlId="NewShiftForm.txtDay">
                            <Form.Label> {t('vacationTab.newVacationMdl.holiday')} </Form.Label>
                            <Form.Control type="text" name="holiday" maxLength={20} onChange={formik.handleChange} value={formik.values.holiday} placeholder={t('vacationTab.newVacationMdl.titlePlaceholder') + " 2017"} />
                        </Form.Group>
                    </Col>
                    {editmode === false ?
                        <>
                            <Col md={12} className='mb-2'>
                                <Form.Group controlId="NewVacationForm.txtTransferremaining">

                                    <Form.Check name="transfer_remaining_entitlement_to_new_quota" onChange={() => handleTransferExpirationDate(formik.values.transfer_remaining_entitlement_to_new_quota)} checked={formik.values.transfer_remaining_entitlement_to_new_quota} inline label={t('vacationTab.newVacationMdl.transferQuotaCheckbox')} type="checkbox" id="CheckTransferremaining" />
                                </Form.Group>
                            </Col>
                            <Col md={6} className='mb-2'>
                                <Form.Group controlId="NewShiftForm.txtCarryoverExpirationDate">
                                    <Form.Label> {t('vacationTab.newVacationMdl.transferExpirationDateLabel')}</Form.Label>
                                    <DatePicker className="form-control" name="expireDate" onChange={(date) => formik.setFieldValue("expireDate", date)} selected={formik.values.expireDate} disabled={epireVacation} />
                                </Form.Group>
                            </Col>
                        </>
                        : null}
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btnPrimaryGreen" onClick={formik.handleSubmit}>{ct('finished')}</Button>
            </Modal.Footer>
        </Modal>

    );
}
function VocationSummeryModel(props) {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');


    const [SummeryList, setSummeryList] = useState([]);

    useEffect(() => {
        getData()
    }, []);
    const getData = async () => {
        var response = await CallAxios(
            "/api/VacationQuota/GetvacationQuotaSummery|post", { id: props.vacationIdforEdit }
        );
        if (!!response && response.status === 200) {
            setSummeryList(response.data)
        }
    }
    return (
        <>
            <Modal show={props.show} onHide={() => { props.setshow(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title> {t('vacationTab.summaryMdl.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12} >
                            <Table hover responsive className="DataGridTable">
                                <thead>
                                    <tr>
                                        <th> {t('vacationTab.summaryMdl.absence')}</th>
                                        <th> {t('vacationTab.summaryMdl.confirmedBy')}</th>
                                        <th> {t('vacationTab.summaryMdl.calculation')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !!SummeryList && SummeryList.length > 0 ? SummeryList.map((val, key) => {
                                            return <tr>
                                                <td>{val.quotaName}</td>
                                                <td>{val.confirmedBy}<br></br>{val.confirmedDate}</td>
                                                <td>{!!val.calculation && val.calculation.length > 0 ? val.calculation.map((v, i) => {
                                                    return <><span>{v.date + ': ' + v.calculation}</span><br></br></>
                                                })
                                                    : null
                                                }	</td>
                                            </tr>
                                        }) : null
                                    }

                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btnPrimaryGreen" onClick={() => props.setshow(false)}> {ct('finished')}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
