import moment from "moment";
import { createRef, forwardRef, useEffect, useState } from "react";
import { Button, Col, Container, Dropdown, Form, Modal, Nav, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import { BiCalendarExclamation } from "react-icons/bi";
import { BsChevronLeft, BsChevronRight, BsGear, BsPerson } from "react-icons/bs";
import { FaCalendar, FaEyeSlash, FaHeading, FaListAlt, FaPlus, FaRegCalendarAlt, FaSortAmountDown, FaTags, FaThumbtack, FaTimes, FaUndo, FaUsers } from "react-icons/fa";
import { useSelector } from "react-redux";
import Swal from 'sweetalert2';
import MessageModal from '../../Components/MessageModal';
import { AxiosLite, CallAxios } from "../../Helpers";
import EmployeeRoster from './EmployeeRoster';
import { CircleBtnWithIcon } from "../../Helpers/Global/StyledComponents";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const CustomToggleSettings = forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </a>
));

function EmployeeRosterView() {
    const { t: cl } = useTranslation('calendarPage');
    const { t: ct } = useTranslation('common');

    var calendarComponentRef = createRef();
    const user = useSelector(x => x.app.user)
    // const appSettings = useSelector(x => x.app.appSettings)
    const [setting, setSetting] = useState({
        _showRequirements: true,
        _hidetags: false,
        _planningIncomplete: false,
        _compactCalendar: false,
        _hideAssignments: false,
        _fixedHeadline: false,
        _showTitle: false,
        _oneRowPerSeries: false,
        _fullCalendarView: !!localStorage.getItem("calendar_view") ? localStorage.getItem("calendar_view") : "weeklyViewList",
        _SearchEmployee: false,
    });
    const [showborderClass, setshowborderClass] = useState(false);
    const [filterShifts, setFilterShifts] = useState(false);
    const [weeklyTotalhour, setWeeklyTotalhour] = useState("0");
    const [weeklyAssignedhour, setWeeklyAssignedhour] = useState("0");
    const [weekNum, setWeekNum] = useState(parseInt(!!localStorage.getItem("calendar_date") ? new moment(localStorage.getItem("calendar_date")).format('W') : new moment().format('W')));
    const [showHide, setShowHide] = useState(false);
    const [ChoseCalendarDate, setChoseCalendarDate] = useState(!!localStorage.getItem("calendar_date") ? new Date(localStorage.getItem("calendar_date")) : new Date());

    // const [calendarTable, setCalendarTable] = useState(false);
    // const [showMonth, setShowMonth] = useState(new Date());
    // const [activeEmployeeButtons, setActiveEmployeeButtons] = useState(false);
    // const [activeindex, setActiveindex] = useState(null);
    // const [showTimeBelowHeader, setShowTimeBelowHeader] = useState({});
    // const [ViewTitle, setViewTitle] = useState("");
    // const [chooseWeekDate, setChooseWeekDate] = useState("");
    const [employeelist, setEmployeelist] = useState([]);
    const [AssignmentsAndOfferedShiftTrigger, setAssignmentsAndOfferedShiftTrigger] = useState(0);
    useEffect(() => {
        // getWeekNum(parseInt(!!localStorage.getItem("calendar_date") ? new moment(localStorage.getItem("calendar_date")).format('W') : new moment().format('W')));
        // setChooseWeekDate(parseInt(!!localStorage.getItem("calendar_date") ? new moment(localStorage.getItem("calendar_date")).format('W') : new moment().format('W')))
        getEmployeeList()

        // let calendar = calendarComponentRef.current;
        // if (!!calendar) {
        //     this.setState({
        //         ViewTitle: calendar.getApi().view.getCurrentData().viewTitle
        //     })
        // }
        // this.GetEmployeeAbsenceList()
    }, []);
    // const getWeekNum = (date, e) => {
    //     let week = parseInt(moment(date).format('W'));
    //     let dates = moment().format('DD-MM-YYYY');
    //     if (!!e && (e === "dailyViewList" || e === "dailyViewhoursList")) {
    //         setWeekNum(dates)
    //     } else {
    //         setWeekNum(week)
    //     }
    // }
    const getEmployeeList = async () => {
        var response = await AxiosLite("/api/employee/GetemployeelistforMessage|get");
        if (!!response && response.status === 200) {
            setEmployeelist(response.data.filter(x => x.empID !== parseInt(user.LoginUserId)))
        }
    }
    // const ChangeViewOfCalendar = (e) => {

    //     let calendar = this.calendarComponentRef.current;
    //     if (e === "Monthlist") {
    //         // setCalendarTable(true)
    //         setSetting({ ...setting, _fullCalendarView: e })
    //     } else {
    //         // setCalendarTable(false)
    //         setSetting({ ...setting, _fullCalendarView: e })
    //         if (!!calendar && !!e) {
    //             calendar.getApi().changeView(e);
    //         }
    //     }
    //     let calendars = calendarComponentRef.current;
    //     let week = parseInt(moment(calendars?.activeRange?.start).format('W'));
    //     setWeekNum(week)
    // }
    const ChangeViewOfCalendar = (e) => {
        // let calendar = calendarComponentRef.current;
        if (e === "Monthlist") {
            setSetting({ ...setting, _fullCalendarView: e })
        } else if (e === "_planningIncomplete" || e === "_compactCalendar" || e === "_oneRowPerSeries" || e === "_fixedHeadline" || e === "_showTitle" || e === "_showRequirements" || e === "_hideAssignments" || e === "_hidetags") {
            setSetting({ ...setting, [e]: !setting[e] })
        }
        else {
            // setCalendarTable(false)
            setSetting({ ...setting, _fullCalendarView: e })
        }
        let calendars = calendarComponentRef.current;
        let week = parseInt(moment(calendars?.activeRange?.start).format('W'));
        setWeekNum(week)
    }
    const NextMonthsEmpFull = () => {
        let calendar = calendarComponentRef.current;
        if (!!calendar) {
            calendar.next();
            var date = calendar.getDate();
            setWeekNum(parseInt(moment(date).format('W')))
            localStorage.setItem("calendar_date", date)

            // setShowMonth(date)
        }
    }
    const PrevMonthsEmpFull = () => {
        let calendar = calendarComponentRef.current;
        if (!!calendar) {
            calendar.prev();
            var date = calendar.getDate();
            setWeekNum(parseInt(moment(date).format('W')))
            localStorage.setItem("calendar_date", date)

            // setShowMonth(date)
        }
    }
    const setEmpTargetAndAssignHour = (weeklyAssignedhour, weeklyTotalhour) => {
        setWeeklyAssignedhour(weeklyAssignedhour)
        setWeeklyTotalhour(weeklyTotalhour)
    }
    const getChooseDate = (date) => {
        let calendar = calendarComponentRef.current;
        if (!!calendar) {
            calendar.gotoDate(date);
        }
        let week = parseInt(moment(date).format('W'));
        setWeekNum(week)
        // setShowMonth(date)
        setShowHide(false)
        localStorage.setItem("calendar_date", date)

    }
    return (
        <>
            <Row className="mtMainContent">
                <Col md={12} className="lgcol12">
                    <Row>
                        <Col md={4} className="mb-3 text-left align-self-end">
                            <span style={{ color: "#95a5a6" }}><BsPerson onClick={() => {
                                setshowborderClass(!showborderClass);
                                setFilterShifts(!filterShifts)
                            }} className={filterShifts === true ? "redText ActionToolIcon ms-0" : "ActionToolIcon ms-0"} />{cl('employeeRoster.weekTime')}: <b>{weeklyTotalhour}h</b> | {cl('employeeRoster.currentWeek')}: <b>{weeklyAssignedhour}h</b></span>
                        </Col>
                        <Col md={8} className="mb-3">
                            <Nav className="justify-content-end" activeKey="/employee/employeecalender">
                                <Nav.Item>
                                    {/* <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip>Customize view</Tooltip>}> */}
                                    <Nav.Link data-tooltip-id="my-tooltip" data-tooltip-content={cl("roasterView.customizeViewTool")} href="#" className="active p-0">
                                        <Dropdown className="ActionDDList" onSelect={(eventkey) => { ChangeViewOfCalendar(eventkey); localStorage.setItem("calendar_view", eventkey) }}>
                                            <Dropdown.Toggle as={CustomToggleSettings} id="dropdown-basic" className="btnDDList" menuAlign="right">
                                                <CircleBtnWithIcon className='Btn' color='#000'>
                                                    <BsGear className="ActionToolIcon" />
                                                </CircleBtnWithIcon>

                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className="ScrollableListMenu showbeGreen">
                                                <Dropdown.Header className="LinkListHeader"> {ct("views")} </Dropdown.Header>
                                                <Dropdown.Item eventKey="dailyViewList" active={setting._fullCalendarView === "dailyViewList" ? true : false}>
                                                    {cl('roasterView.dayViewList')}
                                                </Dropdown.Item>
                                                <Dropdown.Item eventKey="dailyViewhoursList" active={setting._fullCalendarView === "dailyViewhoursList" ? true : false}>
                                                    {cl('roasterView.dayViewHours')}
                                                </Dropdown.Item>
                                                <Dropdown.Item eventKey="weeklyViewList" active={setting._fullCalendarView === "weeklyViewList" ? true : false}>
                                                    {cl('roasterView.weekViewList')}
                                                </Dropdown.Item>
                                                <Dropdown.Item eventKey='weeklyViewhoursList' active={setting._fullCalendarView === "weeklyViewhoursList" ? true : false}>
                                                    {cl('roasterView.weekViewHours')}
                                                </Dropdown.Item>
                                                <Dropdown.Item eventKey='resourceViewDay' active={setting._fullCalendarView === "resourceViewDay" ? true : false}>
                                                    {cl('roasterView.resourceViewDay')}
                                                </Dropdown.Item>
                                                <Dropdown.Item eventKey="resourceViewWeek" active={setting._fullCalendarView === "resourceViewWeek" ? true : false}>
                                                    {cl('roasterView.resourceViewWeek')}
                                                </Dropdown.Item>
                                                <Dropdown.Item eventKey='resourceViewMonth' active={setting._fullCalendarView === "resourceViewMonth" ? true : false}>
                                                    {cl('roasterView.resourceViewMonth')}
                                                </Dropdown.Item>
                                                <Dropdown.Item eventKey="monthlyList" active={setting._fullCalendarView === "monthlyList" ? true : false}>
                                                    {cl('roasterView.monthViewCalendar')}
                                                </Dropdown.Item>
                                                <Dropdown.Item eventKey="Monthlist" active={setting._fullCalendarView === "Monthlist" ? true : false}>
                                                    {cl('roasterView.monthViewList')}
                                                </Dropdown.Item>
                                                {
                                                    setting._fullCalendarView !== "resourceViewDay" && setting._fullCalendarView !== "resourceViewWeek" && setting._fullCalendarView !== "resourceViewMonth" && setting._fullCalendarView !== "Monthlist" ?
                                                        <>
                                                            <Dropdown.Header className="LinkListHeader">  {cl('roasterView.settings')} </Dropdown.Header>
                                                            <Dropdown.Item eventKey="_planningIncomplete" active={setting._planningIncomplete}>
                                                                <BiCalendarExclamation className="me-3" /> {cl('roasterView.planningIncomplete')}
                                                            </Dropdown.Item>
                                                            {
                                                                setting._fullCalendarView !== "monthlyList" ?
                                                                    <Dropdown.Item eventKey="_compactCalendar" active={setting._compactCalendar} >
                                                                        <FaCalendar className="me-3" /> {cl('roasterView.compactCalendar')}
                                                                        {/* <FaCalendar eventKey="Monthlist" className="me-3" />Compact calendar */}
                                                                    </Dropdown.Item>
                                                                    : null
                                                            }
                                                            <Dropdown.Item eventKey="_oneRowPerSeries" active={setting._oneRowPerSeries} >
                                                                <FaListAlt className="me-3" /> {cl('roasterView.oneRowSeries')}
                                                            </Dropdown.Item>
                                                            <Dropdown.Item eventKey="_fixedHeadline" active={setting._fixedHeadline} >
                                                                <FaThumbtack className="me-3" /> {cl('roasterView.fixedHeadline')}
                                                            </Dropdown.Item>
                                                            {
                                                                setting._fullCalendarView !== "monthlyList" ?
                                                                    <Dropdown.Item eventKey="_showTitle" active={setting._showTitle} >
                                                                        <FaHeading className="me-3" />  {cl('roasterView.showTitle')}
                                                                    </Dropdown.Item>
                                                                    : null
                                                            }
                                                            {
                                                                setting._fullCalendarView !== "dailyViewhoursList" && setting._fullCalendarView !== "weeklyViewhoursList" && setting._fullCalendarView !== "monthlyList" ?
                                                                    <Dropdown.Item>
                                                                        <FaSortAmountDown className="me-3" />  {cl('roasterView.placeEnd')}
                                                                    </Dropdown.Item> : null
                                                            }
                                                            <Dropdown.Item eventKey="_showRequirements" active={setting._showRequirements}>
                                                                <FaUsers className="me-3" /> {cl('roasterView.showRequirements')}
                                                            </Dropdown.Item>
                                                            <Dropdown.Item eventKey="_hideAssignments" active={setting._hideAssignments} >
                                                                <FaEyeSlash className="me-3" />  {cl('roasterView.hideAssignments')}
                                                            </Dropdown.Item>
                                                            <Dropdown.Item eventKey="_hidetags" active={setting._hidetags} >
                                                                <FaTags className="me-3" />  {cl('roasterView.hideTags')}
                                                            </Dropdown.Item>
                                                        </>
                                                        : null
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Nav.Link>
                                    {/* </OverlayTrigger> */}
                                </Nav.Item>
                                <Nav.Item>
                                    <div className="ToolMonthChange">
                                        <div>
                                            <a><BsChevronLeft onClick={(e) => PrevMonthsEmpFull()} className="btnPrev" /></a>
                                        </div>
                                        <div>
                                            <div className="MonthChangeContainer" onClick={() => { setShowHide(!showHide) }}>
                                                <FaRegCalendarAlt className="CalIcon" />
                                                <span className="MonthTitle"> {weekNum}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <a><BsChevronRight onClick={(e) => NextMonthsEmpFull()} className="btnNext" /></a>
                                        </div>
                                    </div>
                                </Nav.Item>
                            </Nav>
                        </Col>

                    </Row>
                    <div className="FullCalendarContainer NoToolBar">
                        <EmployeeRoster
                            _oneRowPerSeries={setting._oneRowPerSeries}
                            _showTitle={setting._showTitle}
                            resourceViewData={employeelist}
                            view={setting._fullCalendarView}
                            _fixedHeadline={setting._fixedHeadline}
                            _hideAssignments={setting._hideAssignments}
                            _compactCalendar={setting._compactCalendar}
                            _planningIncomplete={setting._planningIncomplete}
                            _tags={setting._hidetags}
                            _showRequirements={setting._showRequirements}
                            // activeTime={activeEmployeeButtons}
                            // activeindex={activeindex}
                            showborderClass={showborderClass}
                            filterShifts={filterShifts}
                            // timeBelowHeader={showTimeBelowHeader}
                            // setViewTitle={setViewTitle}
                            refCalander={calendarComponentRef}
                            setEmpTargetAndAssignHour={setEmpTargetAndAssignHour}
                            setPopUpDate={ChoseCalendarDate}
                            AssignmentsAndOfferedShiftTrigger={AssignmentsAndOfferedShiftTrigger}
                            setAssignmentsAndOfferedShiftTrigger={setAssignmentsAndOfferedShiftTrigger}
                        />
                    </div>
                </Col>
                <Modal show={showHide} onHide={() => setShowHide(false)} size="sm">
                    <Modal.Header closeButton>
                        <Modal.Title> {ct("chooseDate")} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={12} className="m-auto InlineDatePickerContainer">
                                <DatePicker ClassName="form-control" showWeekNumbers selected={calendarComponentRef?.current?.activeRange.start} onChange={date => { getChooseDate(date) }} inline />
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                <Container fluid>
                    {/* <Row className="mt-2">
                        <Col>
                            <p className="fw-light ">Assignment</p>
                        </Col>
                    </Row> */}

                    <Assignments AssignmentsAndOfferedShiftTrigger={AssignmentsAndOfferedShiftTrigger} employeelist={employeelist} />

                </Container>
            </Row>
        </>
    );
}

export default EmployeeRosterView;

function Assignments(props) {
    const { t: cl } = useTranslation('calendarPage');
    const { t: ct } = useTranslation('common');

    const user = useSelector(x => x.app.user)
    const appSettings = useSelector(x => x.app.appSettings)
    const [startdate, setStartdate] = useState(new Date());
    const [endDate, setEndDate] = useState(moment().add(1, 'M').endOf('month')._d);
    const [assignmentList, setAssignmentList] = useState([]);
    const [offerdshiftList, setOfferdshiftList] = useState([]);
    const [messagemodel, setMessagemodel] = useState(false);
    useEffect(() => {
        Promise.all([
            getdata(),
            getofferedShiftList(),
        ])

    }, []);
    useEffect(() => {
        if (props.AssignmentsAndOfferedShiftTrigger > 0) {
            Promise.all([
                getdata(),
                getofferedShiftList(),
            ])
        }
    }, [props.AssignmentsAndOfferedShiftTrigger]);

    const openmessageModal = (id) => {
        Giveorwithdrawoffershift(id)
    }
    const getofferedShiftList = async () => {
        var data = {
            empId: user.LoginUserId,
            Satrtdate: startdate,
            Enddate: endDate,
        };
        var response = await CallAxios("/api/Calendar/GetofferdShift|post", data);
        if (!!response && response.status === 200) {
            setOfferdshiftList(response.data)
        }
    }
    const Takeoffershift = async (id, shiftid) => {
        var obj = {
            id: shiftid,
            empid: user.LoginUserId
        }
        var Occupancyresponse = await CallAxios("/api/Calendar/CheckEmplyeeOccupancyInsamePeriod|post", obj)
        if (!!Occupancyresponse && Occupancyresponse.status === 200) {
            if (Occupancyresponse.data === true) {
                Swal.fire({
                    icon: 'info',
                    title: cl("errorMsg.employeeAlreadyAssigned"),
                })
                return;
            }
        }
        var data = {
            empId: parseInt(user.LoginUserId),
            occupancyid: id,
        };
        var response = await CallAxios("/api/Calendar/Takeofferedshift|post", data);
        if (!!response && response.data === true) {
            Promise.all([
                getdata(),
                getofferedShiftList()
            ])
        } else {
            Swal.fire({
                icon: 'error',
                text: cl("errorMsg.shiftUserDesc"),
            })
        }
    }
    const getdata = async () => {
        var data = {
            empId: user.LoginUserId,
            Satrtdate: startdate,
            Enddate: endDate,
        };
        var response = await CallAxios("/api/Calendar/GetempAssignemnets|post", data);
        if (!!response && response.status === 200) {
            setAssignmentList(response.data)
        }
    }
    const Giveorwithdrawoffershift = async (id) => {
        var data = {
            empId: parseInt(user.LoginUserId),
            occupancyid: id,
        };
        var response = await CallAxios("/api/Calendar/Giveorwithdrawoffershift|post", data);
        if (!!response && response.status === 200) {
            if (response.data.success) {
                getdata()
                setMessagemodel(true)

            }
            else {
                toast.error(response.data.message)
            }
        }
    }

    return (
        <>
            {
                messagemodel === true ?
                    // <MessageModel show={messagemodel} setMessagemodel={setMessagemodel} />
                    <MessageModal show={messagemodel} onHide={setMessagemodel} EmpList={props.employeelist.filter(x => x.email !== null).map((val, key) => ({ label: val.surname, value: val.empID.toString() }))} />
                    :
                    null
            }
            <Col md={12} className="d-flex justify-content-between mb-3 align-items-center">
                <p className="fw-light mb-0 "> {cl("assignment.title")} </p>
                <Row className="d-flex justify-content-end ">
                    <Col md={4}>
                        <Form.Group controlId="ExportForm.txtWeekDateResource">
                            <DatePicker selected={startdate} onChange={(date) => setStartdate(date)} className="form-control " />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="ExportForm.txtWeekDateResource">
                            <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} className="form-control " />
                        </Form.Group>
                    </Col>
                    <Col md={2}>
                        <Button onClick={() => getdata()}>{ct("show")}</Button>
                    </Col>
                </Row>
            </Col>
            <Row>
                <Col md={12}>
                    {
                        !!assignmentList && assignmentList.length > 0 ?
                            <Table hover responsive className="DataGridTable">
                                <thead>
                                    <tr>
                                        <th> {cl("assignment.myAssignments")}</th>
                                        <th> {ct("workingArea")}</th>
                                        <th> {ct("tags")}</th>
                                        <th> {ct("note")}</th>
                                        {
                                            appSettings.locationsettings.ProhibitShiftSwaps === false ?
                                                <th> {ct("actions")}</th>
                                                : null
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        assignmentList.map((val, key) => {
                                            return <tr>
                                                <td>{val.datetime}</td>
                                                <td>{val.workArea}	</td>
                                                <td>{val.tags}	</td>
                                                <td>{val.note}	</td>
                                                {
                                                    appSettings.locationsettings.ProhibitShiftSwaps === false && val.canoffered === true ?
                                                        <td>
                                                            {
                                                                val.isoffered === true ?
                                                                    // <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={(props) => (<Tooltip {...props}>Revert shift trade</Tooltip>)}>
                                                                    <a data-tooltip-id="my-tooltip" data-tooltip-content={ct("revertShiftTradeTooltip")} className="btnTblAction bgRed" onClick={(e) => Giveorwithdrawoffershift(val.id)}><FaTimes /></a>
                                                                    // </OverlayTrigger>
                                                                    :
                                                                    // <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={(props) => (<Tooltip {...props}>Offer shift</Tooltip>)}>
                                                                    <a data-tooltip-id="my-tooltip" data-tooltip-content={ct("offerShiftTooltip")} className="btnTblAction bgYellow" onClick={(e) => openmessageModal(val.id)}><FaUndo /></a>
                                                                // </OverlayTrigger>
                                                            }
                                                        </td>
                                                        : appSettings.locationsettings.ProhibitShiftSwaps === false ? <td></td> : null
                                                }
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </Table>
                            :
                            <div className="mt-5 text-center boxTimeTrack">
                                {cl("assignment.noFutureShifts")}
                            </div>
                    }
                </Col>
            </Row>
            {
                appSettings.locationsettings.ProhibitShiftSwaps === false ?
                    <Row>
                        <Col md={12}>
                            {
                                !!offerdshiftList && offerdshiftList.length > 0 ?
                                    <Table hover responsive className="DataGridTable">
                                        <thead>
                                            <tr>
                                                <th> {cl("assignment.freeShifts")}</th>
                                                <th> {cl("assignment.colleague")}</th>
                                                <th> {ct("workingArea")}</th>
                                                <th> {ct("tags")}</th>
                                                <th> {ct("note")}</th>
                                                <th> {ct("actions")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                offerdshiftList.map((val, key) => {
                                                    return <tr>
                                                        <td>{val.datetime}</td>
                                                        <td>{val.empName}</td>
                                                        <td>{val.workArea}	</td>
                                                        <td>{val.tags}	</td>
                                                        <td>{val.note}	</td>
                                                        <td>
                                                            <a className="btnTblAction bgGreen" onClick={(e) => Takeoffershift(val.id, val.shiftid)}><FaPlus /></a>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                    :
                                    <div className="mt-5 text-center boxTimeTrack">
                                        {cl("assignment.noCurrentShift")}
                                    </div>
                            }
                        </Col>
                    </Row>
                    : null
            }
        </>
    )
}