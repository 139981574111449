import React from 'react';
import { AiFillClockCircle, AiOutlineClockCircle, AiOutlineMail, AiTwotoneMail } from "react-icons/ai";
import { BsArchive, BsCalendarMinus, BsCalendarMinusFill, BsFillArchiveFill, BsInfoCircle, BsInfoCircleFill, BsRecordCircle } from "react-icons/bs";
import { FaEye, FaList, FaPaperclip, FaUndo } from 'react-icons/fa';
import { IoIosCloseCircle, IoIosCloseCircleOutline } from "react-icons/io";
import { IoCheckmarkCircle, IoCheckmarkCircleOutline, IoSettingsOutline, IoSettingsSharp, IoShare, IoShareOutline } from "react-icons/io5";
import { MdLocationOff, MdOutlineLocationOff, MdPayment } from 'react-icons/md';
import { RiDeleteBin7Fill, RiDeleteBin7Line, RiEditFill, RiEditLine, RiStickyNoteFill, RiStickyNoteLine } from "react-icons/ri";
import { CircleBtnWithIcon } from '../Helpers/Global/StyledComponents';
import { FiPaperclip } from 'react-icons/fi';
import FileBase64 from 'react-file-base64';
import { useRef } from 'react';
export function Mail(props) {
    return (<CircleBtnWithIcon {...props}><AiOutlineMail /> <AiTwotoneMail className='onHover' /> </CircleBtnWithIcon>);
}

export function Remove(props) {
    return (<CircleBtnWithIcon color='#f25656' {...props}><RiDeleteBin7Line /> <RiDeleteBin7Fill className='onHover' /> </CircleBtnWithIcon>);
}
export function Invite(props) {
    return (<CircleBtnWithIcon {...props}><IoShareOutline /> <IoShare className='onHover' /> </CircleBtnWithIcon>);
}
export function CheckCircle(props) {
    return (<CircleBtnWithIcon {...props}><IoCheckmarkCircleOutline /> <IoCheckmarkCircle className='onHover' />  </CircleBtnWithIcon>);
}
export function CalendarBtn(props) {
    return (<CircleBtnWithIcon {...props}><BsCalendarMinus /> <BsCalendarMinusFill className='onHover' />  </CircleBtnWithIcon>);
}
export function Edit(props) {
    return (<CircleBtnWithIcon {...props}><RiEditLine /> <RiEditFill className='onHover' />  </CircleBtnWithIcon>);
}
export function Note(props) {
    return (<CircleBtnWithIcon color="#e1ac1d" {...props}><RiStickyNoteLine /> <RiStickyNoteFill className='onHover' />  </CircleBtnWithIcon>);
}
export function ExclaimationCircle(props) {
    return (<CircleBtnWithIcon {...props}><BsInfoCircle /> <BsInfoCircleFill className='onHover' />  </CircleBtnWithIcon>);
}
export function Clock(props) {
    return (<CircleBtnWithIcon {...props}><AiOutlineClockCircle /> <AiFillClockCircle className='onHover' />  </CircleBtnWithIcon>);
}
export function Gear(props) {
    return (<CircleBtnWithIcon color='#0d7be3' {...props}><IoSettingsOutline /> <IoSettingsSharp className='onHover' />  </CircleBtnWithIcon>);
}
export function RemoveCircle(props) {
    return (<CircleBtnWithIcon {...props}><IoIosCloseCircleOutline /> <IoIosCloseCircle className='onHover' />  </CircleBtnWithIcon>);
}
export function LocationOff(props) {
    return (<CircleBtnWithIcon {...props}><MdOutlineLocationOff /> <MdLocationOff className='onHover' />  </CircleBtnWithIcon>);
}
export function ArchiveBox(props) {
    return (<CircleBtnWithIcon {...props}><BsArchive /> <BsFillArchiveFill className='onHover' />  </CircleBtnWithIcon>);
}
export function BsToggleBtn(props) {
    return (<CircleBtnWithIcon {...props}><BsArchive /> <BsFillArchiveFill className='onHover' />  </CircleBtnWithIcon>);
}
export function UndoBtn(props) {
    return (<CircleBtnWithIcon {...props}><FaUndo /> <FaUndo className='onHover' />  </CircleBtnWithIcon>);
}
export function ListIcon(props) {
    return (<CircleBtnWithIcon {...props}><FaList /> <FaList className='onHover' />  </CircleBtnWithIcon>);
}
export function Eye(props) {
    return (<CircleBtnWithIcon {...props}><FaEye /> <FaEye className='onHover' />  </CircleBtnWithIcon>);
}
export function DotCircle(props) {
    return (<CircleBtnWithIcon {...props}><BsRecordCircle /> <BsRecordCircle className='onHover' />  </CircleBtnWithIcon>);
}
export function PaperCLip(props) {
    return (<CircleBtnWithIcon {...props}><FiPaperclip /> <FaPaperclip className='onHover' />  </CircleBtnWithIcon>);
}
export function CardPayment(props) {
    return (<CircleBtnWithIcon {...props}><MdPayment /> <MdPayment className='onHover' />  </CircleBtnWithIcon>);
}
export function AttachmentBtn(props) {
    const { multiple, onDone, paperclipSize } = props;
    const inputRef = useRef();

    return (
        <a>
            <PaperCLip color="#000" fs={paperclipSize} className="me-0" onClick={() => { inputRef.current.children[0].click() }} />
            <span className='d-none' ref={inputRef}><FileBase64
                id='uploadNoteFile'
                multiple={multiple}
                onDone={onDone}
            />
            </span>
        </a>
    );
}
