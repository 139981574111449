import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next";



function WorkFlowTab() {
    const { t: Lst } = useTranslation('locationSetting');


    return (
        <>
            <Row>
                <Col md={12}>
                    <p> {Lst('workflowTab.para')}</p>
                </Col>

            </Row>
        </>
    );
}

export default WorkFlowTab;


