import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import CollapseableDiv from '../../../Components/CollapseCard';
import { CallAxios } from '../../../Helpers';
import { useTranslation } from "react-i18next";

function Availabilities(props) {
    const { t: Lst } = useTranslation('locationSetting');
    const { t: ct } = useTranslation('common');

    useEffect(() => {
        formik.setFieldValue("availabilities_in_the_week_displayed", props.AvailibilitySetting.automatically_availabilities_in_the_week_displayed);
    }, [props.AvailibilitySetting]);

    const formik = useFormik({
        initialValues: {
            availabilities_in_the_week_displayed: false,
        },
        onSubmit: (values) => {
            submit(values);
        },
    });

    const submit = async (values) => {
        var data = {
            automatically_availabilities_in_the_week_displayed: values.availabilities_in_the_week_displayed,
        };
        await CallAxios("/api/LocationSetting/AvailabilitySetting|post", data);
    };
    return (
        <>
            <CollapseableDiv title={Lst('planTab.availAccor.availabilities')}>
                <div className='py-2'>
                    <Form.Check
                        className="text-left"
                        type="checkbox"
                        name="availabilities_in_the_week_displayed"
                        onChange={formik.handleChange}
                        checked={formik.values.availabilities_in_the_week_displayed}
                        label={Lst('planTab.availAccor.availabilitieCheckLabel')}
                    />

                </div>

                <button className='btn themeGreenBtn btn-primary' onClick={formik.handleSubmit}> {ct('ready')}</button>

            </CollapseableDiv>
        </>
    )
}

export default Availabilities;
