import axios from 'axios';
import { toast } from 'react-toastify';
import { setJwt, setisLoading } from '../../Store/slices/AppSlice';
import { SignInManager } from '../Authentication/SignInManager';
import { config } from '../Config';
import { HooksHelper } from '../HooksHelper';
import { UpdateReduxStates } from '../MasterData';
import { Constants } from '../Contents';


axios.defaults.baseURL = config.API_URL;

export const AxiosLite =
    (url, params, navigate, dispatch, shouldAttachToken = true, loader = true) => {
        return CallAxios(url, undefined, params, undefined, navigate, dispatch, shouldAttachToken, 'json', loader);
    };

export const CallAxiosBlob =
    (url, body, loader = true) => {
        return CallAxios(url, body, undefined, undefined, undefined, undefined, true, 'blob', loader);
    };

export const CallAxiosNoloader =
    (url, body, loader = false) => {
        return CallAxios(url, body, undefined, undefined, undefined, undefined, true, 'json', loader);
    };

export const CallAxios = async (url, body, params, headers, navigate, dispatch, shouldAttachToken = true, responseType = 'json', loader = true) => {
    if (url) console.log('URL: ', url);
    if (body) console.log('Body: ', body);
    if (params) console.log('Params: ', params);
    const _dispatch = dispatch ?? HooksHelper.dispatch;
    var jwtData = new SignInManager().ParseJwt;
    if (_dispatch && loader) _dispatch(setisLoading(true))
    var retry = 0;

    while (retry <= 1) {
        try {

            const res = await axios.request({
                url: url.split('|')[0],
                method: url.split('|')[1],
                params,
                headers: shouldAttachToken ? { ...headers, Authorization: ('Bearer ' + new SignInManager().AccessToken) } : { ...headers },
                data: body,
                responseType: responseType
            });

            if (_dispatch && loader) _dispatch(setisLoading(false))
            return res;
        } catch (err) {
            if (_dispatch && loader) _dispatch(setisLoading(false))
            if (err.response) {
                const _navigate = navigate ?? HooksHelper.navigate;
                if (err.response.status === 401 && retry === 0) {
                    await refreshAccessToken(_dispatch, navigate, loader)
                } else if (err.response.status === 401) {
                    const rUri = window.location.pathname
                        .replace('login', '');
                    if (_navigate) {
                        new SignInManager().Logout(_navigate)
                        _navigate('/login?redirect_url=' + rUri);
                    }
                }
                else if (err.response.status === 403) {
                    if (_navigate)
                        _navigate('/forbidden403');
                }
                else if (err.response.status === 423) { //Subscription Expired
                    await UpdateReduxStates(new SignInManager().ParseJwt, _dispatch)
                    _navigate('/account/' + jwtData.CompanyId + '/subscriptions');
                }
                else if (err.response.status === 422) { //Module Access Denied
                    toast.error("Access Denaid.")
                    await UpdateReduxStates(new SignInManager().ParseJwt, _dispatch)
                    if (jwtData.Role.split(",").some(x => x === Constants.roles.AccountAdmin || x === Constants.roles.WorkspaceAdmin || x === Constants.roles.Admin)) {
                        _navigate('/companies/' + jwtData.CompanyId + '/users');
                    } else {
                        _navigate('/companies/' + jwtData.CompanyId + '/users/' + jwtData.EmpID + "/master_data");
                    }
                }
            }
            else {
                toast.error("Something went wrong.")
            }
            retry = retry + 1;
        }

    }
};

export const refreshAccessToken = async (_dispatch, navigate, loader) => {
    if (_dispatch && loader) _dispatch(setisLoading(true))
    var rfToken = localStorage.getItem('refresh_Token')
    var accfToken = JSON.parse(localStorage.getItem('token'))
    try {
        const resp = await axios.request({
            url: "/api/account/authRefresh",
            method: 'post',
            headers: {
                Authorization: ('Bearer ' + new SignInManager().AccessToken)
            },
            data: { RefreshToken: rfToken, AccessToken: accfToken },
            responseType: 'json'
        });
        if (!!resp && resp.status === 200) {
            const access_token = resp.data;
            _dispatch(setJwt({ token: access_token }));
            new SignInManager().SetToken(access_token)
        }
    }
    catch (err) {
        if (_dispatch && loader) _dispatch(setisLoading(false))
        if (err.response) {
            const _navigate = navigate ?? HooksHelper.navigate;
            if (err.response.status === 401) {
                const rUri = window.location.pathname
                    .replace('login', '');
                if (_navigate) {
                    new SignInManager().Logout(_navigate)
                    _navigate('/login?redirect_url=' + rUri);
                }
            }
            else if (err.response.status === 403) {
                if (_navigate)
                    _navigate('/forbidden403');
            }
        }
    }
}
// type API_URLs = keyof DataReturnTypes;


