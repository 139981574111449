import React from 'react';
import { SubHeadTitle, SubHeaderTitleBox, SubHeaderWrapper } from '../../Helpers/Global/StyledComponents';
import { useTranslation } from "react-i18next";


const SelectCheckData = [
    {
        optionText: 'Option 1',
        isCheck: false
    },
    {
        optionText: 'Option 2',
        isCheck: false
    },
    {
        optionText: 'Option 3',
        isCheck: false
    },
    {
        optionText: 'Option 4',
        isCheck: false
    }
]

function LocationSettingHeader(props) {
    const { t: ht } = useTranslation('header');


    return (
        <>
            <SubHeaderWrapper>
                <SubHeaderTitleBox>
                    <SubHeadTitle> {ht('mainHeader.locationSettings')} </SubHeadTitle>
                </SubHeaderTitleBox>

            </SubHeaderWrapper>
        </>
    );
}

export default LocationSettingHeader;

