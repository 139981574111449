import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next";


function APITab(props) {
    const { t: ast } = useTranslation('accountSetting');

    return (
        <>
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <div className="TabFormContainerSection">
                            <div className="Heading h5 mb-3 fw-bold">API</div>
                            <div> {ast('apiTab.desc')}.</div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default APITab;