import { useFormik } from "formik";
import { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import CollapseableDiv from "../../../Components/CollapseCard";
import { CallAxios } from "../../../Helpers";
import { SignInManager } from "../../../Helpers/Authentication/SignInManager";
import { Constants } from "../../../Helpers/Contents";
import { UpdateReduxStates } from "../../../Helpers/MasterData";
import { useTranslation } from "react-i18next";

function CalendarSetting(props) {
    const { t: Lst } = useTranslation('locationSetting');
    const { t: ct } = useTranslation('common');


    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            Overlapping_shifts_in_day_view: false,
            Overlapping_shifts_in_week_view: false,
            highlight_events: false,
            Drag_and_drop_in_list_views: false,
            Hourly_view_start_time_hour: "0",
            Hourly_view_start_time_min: "0",
            Hour_view_end_time_hour: "0",
            Hour_view_end_time_min: "0",
            length_of_the_time_steps: "0",
            Override_row_height: false,
            rowHeightInput: "0.0"

        },
        onSubmit: values => {
            submit(values)
        },
    });
    const submit = async (values) => {
        var data = {
            Overlapping_shifts_in_day_view: values.Overlapping_shifts_in_day_view,
            Overlapping_shifts_in_week_view: values.Overlapping_shifts_in_week_view,
            highlight_events: values.highlight_events,
            Drag_drop_list_views: values.Drag_and_drop_in_list_views,
            Hourly_view_start_time_hour: parseInt(values.Hourly_view_start_time_hour),
            Hourly_view_start_time_min: parseInt(values.Hourly_view_start_time_min),
            Hourly_view_end_time_hour: parseInt(values.Hour_view_end_time_hour),
            Hourly_view_end_time_min: parseInt(values.Hour_view_end_time_min),
            Override_row_height: values.Override_row_height,
            time_steps_length: values.length_of_the_time_steps,
            rowHeightInput: values.rowHeightInput
        };

        await CallAxios("/api/LocationSetting/CalendarSetting|post", data);
        await UpdateReduxStates(new SignInManager().ParseJwt, dispatch)

    };
    useEffect(() => {
        if (!!props.LocationCalendarSettings) {
            formik.setFieldValue("Overlapping_shifts_in_day_view", props.LocationCalendarSettings.overlapping_shifts_in_day_view);
            formik.setFieldValue("Overlapping_shifts_in_week_view", props.LocationCalendarSettings.overlapping_shifts_in_week_view);
            formik.setFieldValue("highlight_events", props.LocationCalendarSettings.highlight_events);
            formik.setFieldValue("Drag_and_drop_in_list_views", props.LocationCalendarSettings.drag_drop_list_views);
            formik.setFieldValue("Hourly_view_start_time_hour", props.LocationCalendarSettings.hourly_view_start_time_hour);
            formik.setFieldValue("Hourly_view_start_time_min", props.LocationCalendarSettings.hourly_view_start_time_min);
            formik.setFieldValue("Hour_view_end_time_hour", props.LocationCalendarSettings.hourly_view_end_time_hour);
            formik.setFieldValue("Hour_view_end_time_min", props.LocationCalendarSettings.hourly_view_end_time_min);
            formik.setFieldValue("length_of_the_time_steps", props.LocationCalendarSettings.time_steps_length);
            formik.setFieldValue("Override_row_height", props.LocationCalendarSettings.override_row_height);
            formik.setFieldValue("rowHeightInput", props.LocationCalendarSettings.rowHeightInput);
        }
    }, [props.LocationCalendarSettings]);
    return (
        <>
            <CollapseableDiv title={Lst('planTab.calendarAccor.calendarSettings')}>
                <Row>
                    <Col md={4}>
                        <div>
                            <div className='Heading  text-left mb-2 fw-bold text-uppercase'> {Lst('planTab.calendarAccor.layers')}</div>
                            <div className='checkboxlist font13'>
                                <Form.Group className="" controlId={"Overlapping_shifts_in_day_view"}>

                                    <Form.Check className="text-left fw-bold"
                                        type="checkbox" onChange={formik.handleChange} name="Overlapping_shifts_in_day_view" checked={formik.values.Overlapping_shifts_in_day_view} label={Lst('planTab.calendarAccor.shiftDayLabel')} />

                                    <p className="text-info text-left">
                                        <small> {Lst('planTab.calendarAccor.shiftDayLabelDes')}.</small>
                                    </p>
                                </Form.Group>
                                <Form.Group className="" controlId={"Overlapping_shifts_in_week_view"}>

                                    <Form.Check className="text-left fw-bold"
                                        type="checkbox" onChange={formik.handleChange} name="Overlapping_shifts_in_week_view" checked={formik.values.Overlapping_shifts_in_week_view} label={Lst('planTab.calendarAccor.shiftWeekLabel')} />

                                    <p className="text-info text-left">
                                        <small> {Lst('planTab.calendarAccor.shiftWeekLabelDes')}.</small>
                                    </p>
                                </Form.Group>
                                <Form.Group className="" controlId={"highlight_events"}>

                                    <Form.Check className="text-left fw-bold"
                                        type="checkbox" onChange={formik.handleChange} name="highlight_events" checked={formik.values.highlight_events} label={Lst('planTab.calendarAccor.highlightLabel')} />

                                    <p className="text-info text-left">
                                        <small> {Lst('planTab.calendarAccor.highlightLabelDes')}.</small>
                                    </p>
                                </Form.Group>
                                <Form.Group className="" controlId={"Drag_and_drop_in_list_views"}>

                                    <Form.Check className="text-left fw-bold"
                                        type="checkbox" onChange={formik.handleChange} name="Drag_and_drop_in_list_views" checked={formik.values.Drag_and_drop_in_list_views} label={Lst('planTab.calendarAccor.dragLabel')} />

                                    <p className="text-info text-left">
                                        <small> {Lst('planTab.calendarAccor.dragLabelDes')}.</small>
                                    </p>
                                </Form.Group>
                            </div>

                        </div>
                    </Col>
                    <Col md={4}>
                        <div>
                            <div className='Heading  text-left mb-2 fw-bold text-uppercase'> {Lst('planTab.calendarAccor.hourViewLength')}</div>
                            <div className='TimeList font13'>
                                <Row>
                                    <Col md={6}>
                                        <div className='TextArea text-left'>
                                            <label className='fw-bold text-left'> {Lst('planTab.calendarAccor.startTimeLabel')}</label>
                                            <p className="text-info text-left">
                                                <small> {Lst('planTab.calendarAccor.startTimeLabelDes')}.</small>
                                            </p>
                                        </div>
                                    </Col>
                                    <Col md={6} className="px-0">
                                        <div className='SelectTimes d-flex justify-content-between align-items-center'>
                                            <select id="Hourly_view_start_time_hour" onChange={formik.handleChange} name="Hourly_view_start_time_hour" value={formik.values.Hourly_view_start_time_hour} className="SelectbgGrey custom-select form-select form-select-sm">
                                                {
                                                    Constants.hoursDropDown.map((val, key) => {
                                                        return <option key={"startTimeHour" + key} value={val.value}>{val.text}</option>
                                                    })
                                                }
                                            </select>
                                            <span className='d-inline-block mx-1'>:</span>
                                            <select id="Hourly_view_start_time_min" onChange={formik.handleChange} name="Hourly_view_start_time_min" value={formik.values.Hourly_view_start_time_min} className="SelectbgGrey custom-select form-select-sm paddrright form-select">
                                                <option value="0">00</option>
                                                <option value="15">15</option>
                                                <option value="30">30</option>
                                                <option value="45">45</option>
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6}>
                                        <div className='TextArea text-left'>
                                            <label className='fw-bold text-left'> {Lst('planTab.calendarAccor.endtime')}</label>
                                            <p className="text-info text-left">
                                                <small> {Lst('planTab.calendarAccor.endtimeLabelDes')}.</small>
                                            </p>
                                        </div>
                                    </Col>
                                    <Col md={6} className="px-0">
                                        <div className='SelectTimes d-flex justify-content-between align-items-center'>
                                            <select id="Hour_view_end_time_hour" onChange={formik.handleChange} name="Hour_view_end_time_hour" value={formik.values.Hour_view_end_time_hour} className="SelectbgGrey custom-select form-select form-select-sm">
                                                {
                                                    Constants.hoursDropDown.map((val, key) => {
                                                        return <option key={"startTimeHour" + key} value={val.value}>{val.text}</option>
                                                    })
                                                }
                                            </select>
                                            <span className='d-inline-block mx-1'>:</span>
                                            <select id="Hour_view_end_time_min" onChange={formik.handleChange} name="Hour_view_end_time_min" value={formik.values.Hour_view_end_time_min} className="SelectbgGrey custom-select form-select paddrright form-select-sm">
                                                <option value="0">00</option>
                                                <option value="15">15</option>
                                                <option value="30">30</option>
                                                <option value="45">45</option>
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                        </div>
                    </Col>
                    <Col md={4}>
                        <div>
                            <div className='Heading  text-left mb-2 fw-bold text-uppercase'> {Lst('planTab.calendarAccor.timeSlots')} </div>
                            <div className='TimeList font13'>
                                <Row>
                                    <Col md={7}>
                                        <div className='TextArea text-left'>
                                            <label className='fw-bold text-left'> {Lst('planTab.calendarAccor.lengthTimeLabel')}</label>
                                            <p className="text-info text-left">
                                                <small> {Lst('planTab.calendarAccor.lengthTimeLabelDes')}.</small>
                                            </p>
                                        </div>
                                    </Col>
                                    <Col md={5} className="px-0 align-self-center">
                                        <div className='SelectTimes d-flex justify-content-between align-items-center'>
                                            <select id="length_of_the_time_steps" onChange={formik.handleChange} name="length_of_the_time_steps" value={formik.values.length_of_the_time_steps} className="SelectbgGrey custom-select form-select-sm form-select me-2">
                                                <option value="15">15</option>
                                                <option value="30">30</option>
                                                <option value="60">60</option>
                                                <option value="90">90</option>
                                                <option value="120">120</option>
                                            </select>
                                            <span className='d-inline-block w-75 font12'> {Lst('planTab.calendarAccor.atLeast')}</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Form.Group className="mt-3" controlId={"Override_row_height"}>

                                    <Form.Check onChange={formik.handleChange} name="Override_row_height" checked={formik.values.Override_row_height} className="text-left fw-bold"
                                        type="checkbox" label={Lst('planTab.calendarAccor.overrideLabel')} />

                                    <p className="text-info text-left">
                                        <small> {Lst('planTab.calendarAccor.overrideLabelDes')}.</small>
                                    </p>

                                    {formik.values.Override_row_height === true ? <div className='mt-4 text-left'>
                                        <label className='fw-bold'> {Lst('planTab.calendarAccor.rowHeights')}:</label>
                                        <input type="number" name='rowHeightInput' onChange={formik.handleChange} value={formik.values.rowHeightInput} className="form-control form-control-sm d-inline-block text-center mx-2" style={{ width: "30%", minHeight: "15px" }} /> mm
                                    </div>
                                        : null}
                                </Form.Group>
                            </div>
                        </div>

                    </Col>
                </Row>
                <div className='text-left mt-4'>
                    <button type="button" onClick={formik.handleSubmit} className="btn btn themeGreenBtn btn-primary" > {ct('ready')}</button>
                </div>
            </CollapseableDiv>
        </>
    )
}

export default CalendarSetting;
