import React from 'react';
import AutomaticBreaks from './AutomaticBreaks';
import Settings from './Settings';



function TimeTrackingTab() {
    return (
        <>
            <AutomaticBreaks />
            <Settings />
        </>
    );
}

export default TimeTrackingTab;


