import { useFormik } from "formik";
import React, { useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { isValidPhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { AxiosLite, CallAxios } from "../../Helpers";
import { LanguageList } from "../../CommonJSON";
import { useTranslation } from "react-i18next";


function BaseDataTab(props) {
    const { t: ast } = useTranslation('accountSetting');
    const { t: ct } = useTranslation('common');
    const { t: sdt } = useTranslation('selectDropdown');


    useEffect(() => {
        getbaseData()
    }, []);
    const getbaseData = async () => {
        var response = await AxiosLite("/api/AccountSetting/basedata|get");
        if (!!response && response.status === 200) {
            formik.setFieldValue("companyName", response.data.companyName)
            formik.setFieldValue("PhoneNo", response.data.phonNumber || "")
            formik.setFieldValue("Adress", response.data.address || "")
            formik.setFieldValue("Additive", response.data.additive || "")
            formik.setFieldValue("City", response.data.city || "")
            formik.setFieldValue("FederalState", response.data.federalState || "")
            formik.setFieldValue("Country", response.data.country || "DE")
            formik.setFieldValue("VatID", response.data.vatidNo || "")
            formik.setFieldValue("InvoiceHolder", response.data.invoiceHolder || "")
            formik.setFieldValue("invoiceEmail", response.data.invoiceEmail || "")
            formik.setFieldValue("language", response.data.language || "de")
            formik.setFieldValue("roleToEditLocations", response.data.roleToEditLocations || "Workspace admin")
            formik.setFieldValue("ChosenBranch", response.data.chosenBranch || "")
            formik.setFieldValue("NumberOfEmployees", response.data.numberOfEmployees || "6-20Mitarbeiter")
            formik.setFieldValue("Postcode", response.data.postcode || "")
        }
    }

    const formik = useFormik({
        initialValues: {
            companyName: "",
            PhoneNo: "",
            Adress: "",
            Additive: "",
            City: "",
            FederalState: "",
            Country: "DE",
            VatID: "",
            InvoiceHolder: "",
            invoiceEmail: "",
            language: "de",
            roleToEditLocations: "Workspace admin",
            ChosenBranch: "",
            NumberOfEmployees: "6-20Mitarbeiter",
            Postcode: ""
        },
        validationSchema: Yup.object().shape({
            companyName: Yup.string()
                .required(ast('baseDataTab.errorMsg.company')),
            PhoneNo: Yup.string().test('PhoneNo', ast('baseDataTab.errorMsg.phoneNo'), (value) => {
                if (!!value) {
                    return isValidPhoneNumber(value)
                } else {
                    return true;
                }
            }),
            Adress: Yup.string()
                .required(ast('baseDataTab.errorMsg.address')),
            Postcode: Yup.string()
                .required(ast('baseDataTab.errorMsg.postal')),
            City: Yup.string()
                .required(ast('baseDataTab.errorMsg.city')),
            InvoiceHolder: Yup.string()
                .required(ast('baseDataTab.errorMsg.billing')),
            invoiceEmail: Yup.string()
                .email("Billing email invalid")
                .required(ast('baseDataTab.errorMsg.billingEmail')),
        }).required(),
        onSubmit: values => {
            onSubmit(values)
        },
    });


    const onSubmit = async (values) => {

        var data = {
            companyName: values.companyName,
            phonNumber: values.PhoneNo,
            address: values.Adress,
            additive: values.Additive,
            Postcode: values.Postcode,
            city: values.City,
            federalState: values.FederalState,
            country: values.Country,
            VATIDNo: values.VatID,
            InvoiceHolder: values.InvoiceHolder,
            invoiceEmail: values.invoiceEmail,
            language: values.language,
            roleToEditLocations: values.roleToEditLocations,
            ChosenBranch: values.ChosenBranch,
            NumberOfEmployees: values.NumberOfEmployees
        }
        var response = await CallAxios("/api/AccountSetting/updatebasedata|post", data);
        if (!!response && response.status === 200 && response.data.success === true) {
            if (!!response.data.message) {
                toast.info(response.data.message)
            } else {
                toast.success(ct("errorMsg.updatedSuccessfully"))
            }
        } else if (response.data.message === "ComapnyNameTaken") {
            toast.error("Company name alreday exist")
        }
        else {
            toast.info(ct("errorMsg.somethingWrong"))
        }
    }


    return (
        <>

            <div className="TabFormContainerSection">
                <Form>
                    <Row>
                        <Col md={4}>
                            <Form.Group className="mb-3" controlId="AccountBaseData.txtCompanyName">
                                <Form.Label> {ast('baseDataTab.companyName')}</Form.Label>
                                <Form.Control name='companyName' type="text" maxLength={150} value={formik.values.companyName} onChange={formik.handleChange} />
                                {formik.touched.companyName && formik.errors.companyName ? (
                                    <span className="error text-danger text-left">{formik.errors.companyName}</span>
                                ) : null}
                            </Form.Group>
                        </Col>


                        <Col md={4}>
                            <Form.Group className="mb-3" controlId="AccountBaseData.txtPhoneNumber">
                                <Form.Label> {ast('baseDataTab.phoneNumber')}</Form.Label>
                                <Form.Control type="text" name="PhoneNo" value={formik.values.PhoneNo} onChange={formik.handleChange} />
                                {formik.touched.PhoneNo && formik.errors.PhoneNo ? (
                                    <span className="error text-danger text-left">{formik.errors.PhoneNo}</span>
                                ) : null}
                            </Form.Group>
                        </Col>


                        <Col md={4}>
                            <Form.Group className="mb-3" controlId="AccountBaseData.txtAddress">
                                <Form.Label> {ast('baseDataTab.address')}</Form.Label>
                                <Form.Control type="text" name="Adress" maxLength={250} value={formik.values.Adress} onChange={formik.handleChange} />
                                {formik.touched.Adress && formik.errors.Adress ? (
                                    <span className="error text-danger text-left">{formik.errors.Adress}</span>
                                ) : null}
                            </Form.Group>
                        </Col>


                        <Col md={4}>
                            <Form.Group className="mb-3" controlId="AccountBaseData.txtAdditive">
                                <Form.Label> {ast('baseDataTab.additive')}</Form.Label>
                                <Form.Control type="text" name="Additive" maxLength={150} value={formik.values.Additive} onChange={formik.handleChange} />
                            </Form.Group>
                        </Col>


                        <Col md={4}>
                            <Form.Group className="mb-3" controlId="AccountBaseData.txtPostcode">
                                <Form.Label> {ast('baseDataTab.postal')}</Form.Label>
                                <Form.Control type="text" name="Postcode" maxLength={150} value={formik.values.Postcode} onChange={formik.handleChange} />
                                {formik.touched.Postcode && formik.errors.Postcode ? (
                                    <span className="error text-danger text-left">{formik.errors.Postcode}</span>
                                ) : null}
                            </Form.Group>
                        </Col>


                        <Col md={4}>
                            <Form.Group className="mb-3" controlId="AccountBaseData.txtCity">
                                <Form.Label> {ast('baseDataTab.city')}</Form.Label>
                                <Form.Control type="text" name="City" maxLength={150} value={formik.values.City} onChange={formik.handleChange} />
                                {formik.touched.City && formik.errors.City ? (
                                    <span className="error text-danger text-left">{formik.errors.City}</span>
                                ) : null}
                            </Form.Group>
                        </Col>


                        <Col md={4}>
                            <Form.Group className="mb-3" controlId="AccountBaseData.txtFederalState">
                                <Form.Label> {ast('baseDataTab.state')}</Form.Label>
                                <Form.Control type="text" name="FederalState" value={formik.values.FederalState} onChange={formik.handleChange} />
                            </Form.Group>
                        </Col>


                        <Col md={4}>
                            <Form.Group className="mb-3" controlId="AccountBaseData.SelectCountry">
                                <Form.Label> {ast('baseDataTab.country')}</Form.Label>
                                <Form.Control as="select" name="Country" value={formik.values.Country} onChange={formik.handleChange} custom>
                                    <option value="AD">AD</option>
                                    <option value="AL">AL</option>
                                    <option value="AT">AT</option>
                                    <option value="AU">AU</option>
                                    <option value="BA">BA</option>
                                    <option value="BE">BE</option>
                                    <option value="BF">BF</option>
                                    <option value="BG">BG</option>
                                    <option value="BY">BY</option>
                                    <option value="CH">CH</option>
                                    <option value="CN">CN</option>
                                    <option value="CO">CO</option>
                                    <option value="CY">CY</option>
                                    <option value="CZ">CZ</option>
                                    <option value="DE">DE</option>
                                    <option value="DK">DK</option>
                                    <option value="EC">EC</option>
                                    <option value="EE">EE</option>
                                    <option value="EG">EG</option>
                                    <option value="ES">IT</option>
                                    <option value="FI">FI</option>
                                    <option value="FO">FO</option>
                                    <option value="FR">FR</option>
                                    <option value="GB">GB</option>
                                    <option value="GE">GE</option>
                                    <option value="GR">GR</option>
                                    <option value="HR">MR</option>
                                    <option value="HU">HU</option>
                                    <option value="ID">ID</option>
                                    <option value="IE">IE</option>
                                    <option value="IS">IS</option>
                                    <option value="IL">IL</option>
                                    <option value="IT">IT</option>
                                    <option value="LI">LI</option>
                                    <option value="LT">LT</option>
                                    <option value="LU">LU</option>
                                    <option value="LV">LV</option>
                                    <option value="MA">MA</option>
                                    <option value="MC">MC</option>
                                    <option value="MD">MD</option>
                                    <option value="MK">MK</option>
                                    <option value="MT">MT</option>
                                    <option value="NL">NL</option>
                                    <option value="NO">NO</option>
                                    <option value="NP">NP</option>
                                    <option value="PL">PL</option>
                                    <option value="PT">PT</option>
                                    <option value="RS">RS</option>
                                    <option value="RU">RU</option>
                                    <option value="SE">SE</option>
                                    <option value="SI">SI</option>
                                    <option value="SK">SK</option>
                                    <option value="SM">SM</option>
                                    <option value="SY">SY</option>
                                    <option value="TR">TR</option>
                                    <option value="UA">UA</option>
                                    <option value="US">US</option>
                                    <option value="VA">VA</option>
                                    <option value="VN">VN</option>
                                    <option value="YE">YE</option>
                                    <option value="YU">YU</option>
                                    <option value="ZA">ZA</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>



                        <Col md={4}>
                            <Form.Group className="mb-3" controlId="AccountBaseData.txtVATID">
                                <Form.Label> {ast('baseDataTab.vatId')}</Form.Label>
                                <Form.Control type="text" name="VatID" maxLength={50} value={formik.values.VatID} onChange={formik.handleChange} />
                            </Form.Group>
                        </Col>


                        <Col md={4}>
                            <Form.Group className="mb-3" controlId="AccountBaseData.txtInvoiceHolder">
                                <Form.Label> {ast('baseDataTab.billingOwner')}</Form.Label>
                                <Form.Control type="text" name="InvoiceHolder" maxLength={150} value={formik.values.InvoiceHolder} onChange={formik.handleChange} />
                                {formik.touched.InvoiceHolder && formik.errors.InvoiceHolder ? (
                                    <span className="error text-danger text-left">{formik.errors.InvoiceHolder}</span>
                                ) : null}
                            </Form.Group>
                        </Col>


                        <Col md={4}>
                            <Form.Group className="mb-3" controlId="AccountBaseData.txtInvoiceEmail">
                                <Form.Label> {ast('baseDataTab.billingEmail')}</Form.Label>
                                <Form.Control type="email" name="invoiceEmail" maxLength={150} value={formik.values.invoiceEmail} onChange={formik.handleChange} />
                            </Form.Group>
                            {formik.touched.invoiceEmail && formik.errors.invoiceEmail ? (
                                <span className="error text-danger text-left">{formik.errors.invoiceEmail}</span>
                            ) : null}
                        </Col>


                        <Col md={4}>
                            <Form.Group className="mb-3" controlId="AccountBaseData.txtlanguage">
                                <Form.Label> {ast('baseDataTab.language')}</Form.Label>
                                <Form.Control as="select" className="SelectbgGrey" name="language" value={formik.values.language} onChange={formik.handleChange} custom>
                                    {/* <option value="de">Deutsch</option>
                                    <option value="en">English</option> */}
                                    {LanguageList?.map(x =>
                                        <option value={x.value}>{x.label}</option>
                                    )}
                                    {/* <option value="ch">German (Switzerland)</option> */}
                                    {/* <option value="en_uk">English UK</option> */}
                                </Form.Control>
                            </Form.Group>
                        </Col>


                        <Col md={4}>
                            <Form.Group className="mb-3" controlId="AccountBaseData.txtRole">
                                <Form.Label>  {sdt('accountBaseTab.editLocation.labelTitle')}</Form.Label>
                                <Form.Control as="select" className="SelectbgGrey" name="roleToEditLocations" value={formik.values.roleToEditLocations} onChange={formik.handleChange} custom>
                                    <option value="Workspace admin"> {sdt('accountBaseTab.editLocation.option1')}</option>
                                    <option value="Account admin"> {sdt('accountBaseTab.editLocation.option2')}</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>


                        <Col md={4}>
                            <Form.Group className="mb-3" controlId="AccountBaseData.txtBranch">
                                <Form.Label> {sdt('accountBaseTab.chosenInd.labelTitle')}</Form.Label>
                                <Form.Control as="select" className="SelectbgGrey" name="ChosenBranch" value={formik.values.ChosenBranch} onChange={formik.handleChange} custom>
                                    <option value="[DE] Plain">  {sdt('accountBaseTab.chosenInd.option1')}  </option>
                                    <option value="[DE] Apotheke"> {sdt('accountBaseTab.chosenInd.option2')}</option>
                                    <option value="[DE] Arztpraxis"> {sdt('accountBaseTab.chosenInd.option3')}</option>
                                    <option value="[DE] Arztpraxis mit Bereich je Arzt"> {sdt('accountBaseTab.chosenInd.option4')}</option>
                                    <option value="[DE] Arztpraxis mit MRT"> {sdt('accountBaseTab.chosenInd.option5')}</option>
                                    <option value="[DE] Ausbildung"> {sdt('accountBaseTab.chosenInd.option6')}</option>
                                    <option value="[DE] Autohaus mit Verkauf und Werkstatt"> {sdt('accountBaseTab.chosenInd.option7')}</option>
                                    <option value="[DE] Bäckerei mit Bezirksplanung"> {sdt('accountBaseTab.chosenInd.option8')}</option>
                                    <option value="[DE] Bankfiliale"> {sdt('accountBaseTab.chosenInd.option9')}</option>
                                    <option value="[DE] Bauunternehmen"> {sdt('accountBaseTab.chosenInd.option10')}</option>
                                    <option value="[DE] Bildung"> {sdt('accountBaseTab.chosenInd.option11')}</option>
                                    <option value="[DE] E-Commerce"> {sdt('accountBaseTab.chosenInd.option12')}</option>
                                    <option value="[DE] Einzelhandel Supermarkt"> {sdt('accountBaseTab.chosenInd.option13')}</option>
                                    <option value="[DE] Eventveranstalter"> {sdt('accountBaseTab.chosenInd.option14')}</option>
                                    <option value="[DE] Fitness"> {sdt('accountBaseTab.chosenInd.option15')}</option>
                                    <option value="[DE] Freizeit"> {sdt('accountBaseTab.chosenInd.option16')}</option>
                                    <option value="[DE] Gastronomie"> {sdt('accountBaseTab.chosenInd.option17')}</option>
                                    <option value="[DE] Hotel"> {sdt('accountBaseTab.chosenInd.option18')}</option>
                                    <option value="[DE] Kino"> {sdt('accountBaseTab.chosenInd.option19')}</option>
                                    <option value="[DE] Kita"> {sdt('accountBaseTab.chosenInd.option20')}</option>
                                    <option value="[DE] Kleines Filialgeschäft"> {sdt('accountBaseTab.chosenInd.option21')}</option>
                                    <option value="[DE] Kurierdienst"> {sdt('accountBaseTab.chosenInd.option22')}</option>
                                    <option value="[DE] Leerer Standort"> {sdt('accountBaseTab.chosenInd.option23')}</option>
                                    <option value="[DE] Limousinenservice/Taxi"> {sdt('accountBaseTab.chosenInd.option24')}</option>
                                    <option value="[DE] Logistik/ Transportunternehmen"> {sdt('accountBaseTab.chosenInd.option25')}</option>
                                    <option value="[DE] Marketing"> {sdt('accountBaseTab.chosenInd.option26')}</option>
                                    <option value="[DE] Museum Tour Guides"> {sdt('accountBaseTab.chosenInd.option27')}</option>
                                    <option value="[DE] Personaldienstleistung"> {sdt('accountBaseTab.chosenInd.option28')}</option>
                                    <option value="[DE] Pflegedienst"> {sdt('accountBaseTab.chosenInd.option29')}</option>
                                    <option value="[DE] Planung Kundeneinsätze"> {sdt('accountBaseTab.chosenInd.option30')}</option>
                                    <option value="[DE] Produktion: 3 Schicht mit Schichführer"> {sdt('accountBaseTab.chosenInd.option31')}</option>
                                    <option value="[DE] Produktion: mit Arbeitsschritten"> {sdt('accountBaseTab.chosenInd.option32')}</option>
                                    <option value="[DE] Produktion: ohne Schichtbetrieb"> {sdt('accountBaseTab.chosenInd.option33')}</option>
                                    <option value="[DE] Produzierendes Gewerbe"> {sdt('accountBaseTab.chosenInd.option34')}</option>
                                    <option value="[DE] Promotion mit freien Mitarbeitern"> {sdt('accountBaseTab.chosenInd.option35')}</option>
                                    <option value="[DE] Redaktion (Zeitung/ Radio)"> {sdt('accountBaseTab.chosenInd.option36')}</option>
                                    <option value="[DE] Reisebüro"> {sdt('accountBaseTab.chosenInd.option37')}</option>
                                    <option value="[DE] Rettungsdienst"> {sdt('accountBaseTab.chosenInd.option38')}</option>
                                    <option value="[DE] Rundfunk"> {sdt('accountBaseTab.chosenInd.option39')}</option>
                                    <option value="[DE] Schwimmbad"> {sdt('accountBaseTab.chosenInd.option40')}</option>
                                    <option value="[DE] Service Center"> {sdt('accountBaseTab.chosenInd.option41')}</option>
                                    <option value="[DE] Servicecenter/ Callcenter"> {sdt('accountBaseTab.chosenInd.option42')}</option>
                                    <option value="[DE] Servicecenter (einfach)"> {sdt('accountBaseTab.chosenInd.option43')}</option>
                                    <option value="[DE] Sicherheitsdienst"> {sdt('accountBaseTab.chosenInd.option44')}</option>
                                    <option value="[DE] Skigebiet"> {sdt('accountBaseTab.chosenInd.option45')}</option>
                                    <option value="[DE] Sozialeinrichtung/ Jugendhilfe"> {sdt('accountBaseTab.chosenInd.option46')}</option>
                                    <option value="[DE] Theater"> {sdt('accountBaseTab.chosenInd.option47')}</option>
                                    <option value="[DE] Tourguides"> {sdt('accountBaseTab.chosenInd.option48')}</option>
                                    <option value="[DE] Verein"> {sdt('accountBaseTab.chosenInd.option49')}</option>
                                    <option value="[DE] Wohngruppen"> {sdt('accountBaseTab.chosenInd.option50')}</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>



                        <Col md={4}>
                            <Form.Group className="mb-3" controlId="AccountBaseData.txtNumOfEmp">
                                <Form.Label> {sdt('accountBaseTab.chosenEmp.labelTitle')}</Form.Label>
                                <Form.Control as="select" className="SelectbgGrey" name='NumberOfEmployees' value={formik.values.NumberOfEmployees} onChange={formik.handleChange} custom>
                                    <option value="AnzahlMitarbeiterwählen"> {sdt('accountBaseTab.chosenEmp.option1')}</option>
                                    <option value="1-5Mitarbeiter"> {sdt('accountBaseTab.chosenEmp.option2')}</option>
                                    <option value="6-20Mitarbeiter"> {sdt('accountBaseTab.chosenEmp.option3')}</option>
                                    <option value="21-35Mitarbeiter"> {sdt('accountBaseTab.chosenEmp.option4')}</option>
                                    <option value="36-50Mitarbeiter"> {sdt('accountBaseTab.chosenEmp.option5')}</option>
                                    <option value="51-80Mitarbeiter"> {sdt('accountBaseTab.chosenEmp.option6')}</option>
                                    <option value="81-150Mitarbeiter"> {sdt('accountBaseTab.chosenEmp.option7')}</option>
                                    <option value="151-500Mitarbeiter"> {sdt('accountBaseTab.chosenEmp.option8')}</option>
                                    <option value="mehrals500Mitarbeiter"> {sdt('accountBaseTab.chosenEmp.option9')}</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>


                        <Col md={4} className="text-right">
                            <Button className="btn btnPrimaryGreen" onClick={() => formik.handleSubmit()}> {ct('finished')}</Button>
                        </Col>
                    </Row>
                </Form>
            </div>

        </>
    );
}

export default BaseDataTab;