import Picker from "emoji-picker-react";
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { FiSmile } from 'react-icons/fi';


function InputImoji(props) {
    const [hideShowEmojipicker, sethideShowEmojipicker] = useState(false);
    const PickerDropDown = { right: "15px !important", top: "45px !important" };

    const EmojiOnChange = (e) => {
        e.preventDefault();
        if (props.onChange) {
            props.onChange(e.target.value)
        }
    };
    const handleEmojipicker = () => {
        sethideShowEmojipicker(!hideShowEmojipicker);
    };
    const EmojiHandleClick = (n, e) => {
        if (props.onChange) {
            props.onChange(props.value + n.emoji)
        }
    };
    return (
        <>
            <Form.Control
                type="text"
                placeholder={props.placeholder}
                name="name"
                maxLength={150}
                value={props.value}
                onChange={EmojiOnChange}
                className="ThickBorder my-0 pr40"
            />
            <span
                className="btnShowEmoji lgSpace"
                onClick={handleEmojipicker}
            >
                <FiSmile />
            </span>
            {hideShowEmojipicker ? (
                <div className="pickerWrapperEmj">
                    <Picker
                        disableSearchBar="true"
                        style={PickerDropDown}
                        onEmojiClick={EmojiHandleClick}
                    />
                </div>
            ) : null}
        </>
    );
}

export default InputImoji;

