import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Nav, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import accountLocation from "../../../App/accountLocation.png";
import { CalendarBtn, DotCircle, Edit, Eye, Remove } from '../../../Components/ActionBtns';
import { AxiosLite, CallAxios } from '../../../Helpers';
import { ContentBox, ContentSubHeader, TableWrapper } from '../../../Helpers/Global/StyledComponents';
import { Constants } from '../../../Helpers/Contents';
import styled, { createGlobalStyle } from "styled-components";
import DropdownPortal from '../../../Components/PortalDynamicDropdown';
import { useTranslation } from "react-i18next";
import DeleteConfirmationModal from '../../../Components/DeleteConfirmationModal';


export const GLobalcss = createGlobalStyle`
    .App , .mainWrapperOfLayout , .layoutDflex,.contentAreaGrey{
        height:100%;
    }
    .px-0.container-fluid{
         background-color: #f4f4f4; 

    }
`;


function LocationDetail() {
    const { t: st } = useTranslation('simpleData');
    const { t: ct } = useTranslation('common');
    const { t: cnt } = useTranslation('content');

    const user = useSelector(x => x.app.user);
    const [addEmpFromLocationModal, setAddEmpFromLocationModal] = useState(false);
    const [ShowDeleteModal, setShowDeleteModal] = useState(false);
    const [locationDetail, setLocationDetail] = useState({});
    const [RoleDD, setRoleDD] = useState([]);
    const [selectedEmpids, setSelectedEmpids] = useState([]);
    const [ShowDeactiveModal, setShowDeactiveModal] = useState(false);
    const [ShowDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [SelectedReportId, setSelectedReportId] = useState("");
    const { locationid } = useParams();
    const navigate = useNavigate();
    const themeColor = useSelector(state => state.app.theme.colors.bg);
    const themeColorbtn = useSelector(state => state.app.theme.colors.active);

    useEffect(() => {
        // getRole()
        setRoleDD(Constants.rolesDropDown.map(x => ({ ...x, label: x.label === "Admin" ? cnt("Area Admin") : cnt(x.label) })))
        getData()
    }, []);

    const getData = async () => {
        var response = await CallAxios('/api/Locations/locationdetail|post', { id: locationid });
        if (!!response) {
            setLocationDetail(response.data)
        }
    }
    // const getRole = async () => {
    //     var response = await AxiosLite('/api/Employee/GetRoleDD|get');
    //     if (!!response) {
    //         setRoleDD(response.data)
    //     }
    // }
    const deleteLocation = async () => {
        var response = await CallAxios("/api/Locations/DeleteLocation|post", { id: locationid })
        if (!!response) {
            navigate(`/account/${user.CompanyId}/locationoverview`, { replace: true })
        }
    }

    const addemployyefromOtherLocation = async () => {
        if (selectedEmpids.length > 0) {
            var data = {
                empIds: selectedEmpids,
                LocationId: locationid
            }
            var response = await CallAxios("/api/Employee/createEmployeeFromOtherLoction|post", data)
            if (!!response) {
                setLocationDetail(response.data)
                setAddEmpFromLocationModal(false)
            }
        }
    }
    const deleteemployee = async (id) => {
        var data = {
            empId: id,
            LocationId: locationid
        }
        var response = await CallAxios("/api/Employee/DeleteEmp|post", data)
        if (!!response) {
            setShowDeleteConfirmationModal(false)
            setLocationDetail(response.data)
        }
    }
    const ChangesRoles = async (selected, id) => {
        var data = {
            roleid: selected,
            empid: parseInt(id),
            locationId: parseInt(locationid),
        }
        await CallAxios("/api/Employee/updateEmproleFromAccount|post", data)
    }
    const ChangesAbsenceAccess = async (selected, id, ddtype) => {
        var data = {
            Accesskeys: selected,
            empid: parseInt(id),
            locationid: parseInt(locationid),
            DDType: ddtype
        }
        await CallAxios("/api/Employee/updateEmpShowAbsenceForAccess|post", data)
    }
    const parseDropdownKeys = (value, dropdowntype) => {
        if (!!value && !!value.employeeAccess) {
            let TrueKeyArray = [];
            let keysarray = Object.keys(value.employeeAccess).filter(x => x.includes(dropdowntype));

            for (let i = 0; i < keysarray.length; i++) {
                const element = keysarray[i];
                if (value.employeeAccess[element] === true) {
                    TrueKeyArray.push(element)
                }
            }
            return TrueKeyArray;
        }
    }
    const ActiveDeactiveLocation = () => {
        if (locationDetail.isActive === true) {
            setShowDeactiveModal(true)
        } else {
            ChangeLocationStatus()
        }
    }
    const ChangeLocationStatus = async (ShowModal) => {
        var response = await CallAxios("/api/Locations/disableLocation|post", { id: locationid })
        if (!!response && response.data === true) {
            getData()
            if (!!ShowModal) {
                ShowModal(false)
            }
        } else if (response.data === "lastActive") {
            Swal.fire({
                icon: 'error',
                title: st('locationPage.detail.lastActiveLocationError'),
            })
        }
    }


    const ShowAbsenceForArray = [
        { key: "showAbsenceForEmployee", label: ct('employee') },
        { key: "showAbsenceForAreaAdmin", label: ct('admin') },
        { key: "showAbsenceForWorkspaceAdmin", label: st('locationPage.detail.workspaceAdmin') },
        { key: "showAbsenceForAccountAdmin", label: st('locationPage.detail.accountAdmin') },
    ];
    const CreateAbsenceForArray = [
        { key: "createAbsenceForEmployee", label: ct('employee') },
        { key: "createAbsenceForAreaAdmin", label: ct('admin') },
        { key: "createAbsenceForWorkspaceAdmin", label: st('locationPage.detail.workspaceAdmin') },
        { key: "createAbsenceForAccountAdmin", label: st('locationPage.detail.accountAdmin') },
    ];
    const ConfirmAbsenceForArray = [
        { key: "confirmAbsenceForEmployee", label: ct('employee') },
        { key: "confirmAbsenceForAreaAdmin", label: ct('admin') },
        { key: "confirmAbsenceForWorkspaceAdmin", label: st('locationPage.detail.workspaceAdmin') },
        { key: "confirmAbsenceForAccountAdmin", label: st('locationPage.detail.accountAdmin') },
    ];


    return (
        <>
            <GLobalcss />
            {
                ShowDeleteConfirmationModal === true ?
                    <DeleteConfirmationModal title={st('locationPage.detail.deleteEmployeeMdl.title')} message={st('locationPage.detail.deleteEmployeeMdl.desc')}
                        show={ShowDeleteConfirmationModal} onHide={setShowDeleteConfirmationModal}
                        objId={SelectedReportId} deletefn={deleteemployee} />
                    : null
            }
            <ContentSubHeader>
                <Container fluid>
                    <Row>
                        <Col md={4}>
                            <div className="my-3 DetailPageTitleSection d-flex align-items-center">
                                <Link className="DetailPageTitle" to="#"><img src={accountLocation} alt="employeeIcon" className="LinkTextIcon" />
                                    <span className=' me-3'>{!!locationDetail ? locationDetail.locationName : null}</span></Link>
                                <Form.Check data-tooltip-id="my-tooltip" data-tooltip-content={st('locationPage.detail.deactivateLocationTooltip')} data-tooltip-place="bottom" type="switch" id="DeactivateLocation" checked={locationDetail.isActive} onChange={(e) => ActiveDeactiveLocation()} />
                            </div>
                        </Col>
                        <Col md={8}>
                            <div className="my-3">
                                <Nav className="justify-content-end" activeKey="/home">
                                    <Nav.Item>
                                        <Nav.Link href="#" className="LinkColorRed" onClick={() => setShowDeleteModal(true)}>
                                            {st('locationPage.detail.deleteLocation')}
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <div className='mt-3 text-end'>
                                    <button className="btnTblAction bgGreen btn btn-primary btn-sm" onClick={() => { setSelectedEmpids([]); setAddEmpFromLocationModal(true) }}><FaPlus /> {ct('add')}</button>
                                </div>
                            </div>
                        </Col>
                        <Col md={12} className="mt-4">
                            <TableWrapper className='addpositionfixed'>
                                <Table striped bordered responsive className="DataGridTable overflowVisible">
                                    <thead>
                                        <tr className='firsthead'>
                                            <th> {ct('employee')}</th>
                                            <th> {ct('role')}</th>
                                            <th> {st('locationPage.detail.tbl.access')}</th>
                                            <th> {st('locationPage.detail.tbl.showAbsences')}</th>
                                            <th> {st('locationPage.detail.tbl.createAbsences')}</th>
                                            <th> {st('locationPage.detail.tbl.confirmAbsences')}</th>
                                            <th> {st('locationPage.detail.tbl.calendarRights')}</th>
                                            <th>
                                                {ct('action')}
                                                {/* <a className="btnTblAction bgGreen" onClick={() => { setSelectedEmpids([]); setAddEmpFromLocationModal(true) }}><FaPlus /></a> */}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        {
                                            !!locationDetail.employees ? locationDetail.employees.map((value, key) => {
                                                return <tr>
                                                    <td>
                                                        <div className="dataTextCell">
                                                            <Link to={`/companies/${locationid}/users/${value.empID}/master_data`} target='_blank' className="DetailLink">{value.surname}</Link>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="TblDDList Multiselect HideSelectAll max250px" >

                                                            <DropdownPortal optionKey="value" selected={value.roleids} handleOnChange={(selected) => ChangesRoles(selected, value.empID)} options={RoleDD} placeholder={ct('employee')} buttonClass="btnDDList smWidth " name={`EmpRole${key}`} />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex align-items-center'>

                                                            {
                                                                value.isAccountAdmin === true ?
                                                                    // <OverlayTrigger placement="top" overlay={<Tooltip>Enter Location</Tooltip>}>
                                                                    <a data-tooltip-id="my-tooltip" data-tooltip-content={st('locationPage.enterLocation')} className='btnTblAction '><DotCircle className="w-20px" color="#a4a4a4" /></a>
                                                                    // </OverlayTrigger>
                                                                    :
                                                                    <EmployeeAccessToogleStateButton getData={getData} val={value.accessLocation} empid={value.empID} locationid={locationid} tooltipText={st('locationPage.enterLocation')} IconClass={<DotCircle className="w-20px" color={value.accessLocation ? "#f25656" : ""} />} RightType="AccessLocation" />
                                                            }
                                                            <EmployeeAccessToogleStateButton getData={getData} val={value.canBeAssigned} empid={value.empID} locationid={locationid} tooltipText={st('locationPage.detail.divisibleTooltip')} IconClass={<CalendarBtn className="w-20px" color={value.canBeAssigned ? '#536afb' : ""} />} RightType="CanBeAssigned" />
                                                            <EmployeeAccessToogleStateButton getData={getData} val={value.visibleInlocation} empid={value.empID} locationid={locationid} tooltipText={st('locationPage.detail.visibleLocationTooltip')} IconClass={<Eye className="w-20px" color={value.visibleInlocation ? themeColorbtn : ""} />} RightType="VisibleInlocation" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="TblDDList Multiselect HideSelectAll max250px">
                                                            <DropdownPortal selected={parseDropdownKeys(value, 'showAbsence')} handleOnChange={(selected) => ChangesAbsenceAccess(selected, value.empID, 'showAbsence')} options={ShowAbsenceForArray} placeholder="-" buttonClass="btnDDList smWidth " name={`showAbsence${key}`} />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="TblDDList Multiselect HideSelectAll max250px">
                                                            {/* <DropdownPortal placeholder="-" selected={parseDropdownKeys(value, 'createAbsence')} handleOnChange={(selected) => ChangesAbsenceAccess(selected, value.empID, 'createAbsence')} options={CreateAbsenceForArray} name={`createAbsence${key}`} /> */}
                                                            <DropdownPortal selected={parseDropdownKeys(value, 'createAbsence')} handleOnChange={(selected) => console.log(selected)} options={CreateAbsenceForArray} placeholder="-" buttonClass="btnDDList smWidth " name={`createAbsence${key}`} />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="TblDDList Multiselect HideSelectAll max250px">
                                                            <DropdownPortal selected={parseDropdownKeys(value, 'confirmAbsence')} handleOnChange={(selected) => ChangesAbsenceAccess(selected, value.empID, 'confirmAbsence')} options={ConfirmAbsenceForArray} placeholder="-" buttonClass="btnDDList smWidth " name={`confirmAbsence${key}`} />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex align-items-center'>
                                                            <EmployeeAccessToogleStateButton getData={getData} val={value.isSelfAssignShift} empid={value.empID} locationid={locationid} tooltipText={st('locationPage.detail.selfAssignmentTooltip')} IconClass={<Edit className="w-20px" color={value.isSelfAssignShift ? themeColor : ""} />} RightType="IsSelfAssignShift" />
                                                            <EmployeeAccessToogleStateButton getData={getData} val={value.stampFromAnywhere} empid={value.empID} locationid={locationid} tooltipText={st('locationPage.detail.workingTimesTooltip')} IconClass={<CalendarBtn className="w-20px" color={value.stampFromAnywhere ? '#536afb' : ""} />} RightType="createOwnTimeRecords" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="dataTextCell">
                                                            {value.isAccountAdmin !== true ?
                                                                <a className="btnTblAction bgRed"><Remove className="w-20px" onClick={() => { setSelectedReportId(value.empID); setShowDeleteConfirmationModal(true) }} /></a>
                                                                : null
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            })
                                                : null
                                        }
                                    </tbody>
                                </Table>
                            </TableWrapper>
                        </Col>
                    </Row>
                </Container>
            </ContentSubHeader>
            <ContentBox>
                {ShowDeleteModal === true ?
                    <Modal show={ShowDeleteModal} onHide={() => setShowDeleteModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title> {st('locationPage.detail.deleteLocation')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Row>
                                    <Col md={12} >
                                        {st('locationPage.detail.deleteMdlContent')}
                                    </Col>
                                </Row>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="btn btnPrimaryGreen" onClick={() => setShowDeleteModal(false)}>{ct('cancel')}</Button>
                            <Button className="btn  btn-danger " onClick={() => deleteLocation()}>{ct('delete')}</Button>
                        </Modal.Footer>
                    </Modal>
                    : null
                }
                {
                    addEmpFromLocationModal === true ?
                        <Modal show={addEmpFromLocationModal} onHide={() => setAddEmpFromLocationModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title> {st('locationPage.detail.addEmployeeMdlTitle')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Row>
                                        <Col md={12} >
                                            {
                                                !!locationDetail.otherEmployeesinSameCompany ? locationDetail.otherEmployeesinSameCompany.map((value, key) => {
                                                    return <Form.Group id="addEmpFromLoc.Emp1" onClick={() => setSelectedEmpids(state => [...state, value.value])}>
                                                        <Form.Check className="LinkCheckbox" type="checkbox" label={value.text} />
                                                    </Form.Group>
                                                }) : null
                                            }
                                        </Col>
                                    </Row>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className="btn btnPrimaryGreen" onClick={() => addemployyefromOtherLocation()}> {ct('add')}</Button>
                            </Modal.Footer>
                        </Modal>
                        : null
                }
                {
                    ShowDeactiveModal === true ?
                        <DeactiveLocationModal show={ShowDeactiveModal} onHide={setShowDeactiveModal} getData={getData} ChangeLocationStatus={ChangeLocationStatus} />
                        : null
                }
            </ContentBox>
        </>
    );
}

export default LocationDetail;

function DeactiveLocationModal(props) {
    const { t: st } = useTranslation('simpleData');
    const { t: ct } = useTranslation('common');

    return (
        <>
            <Modal show={props.show} onHide={() => props.onHide(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> {st('locationPage.detail.deactivateLocationTooltip')} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={12} >
                                {st('locationPage.detail.deactivateLocationMdlContent')}
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btnPrimaryGreen" onClick={() => props.onHide(false)}> {ct('cancel')}</Button>
                    <Button className="btn  btn-danger " onClick={() => props.ChangeLocationStatus(props.onHide)}> {st('locationPage.detail.yesDeactivateNow')}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

function EmployeeAccessToogleStateButton(props) {

    const submit = async () => {
        var data = {
            empid: parseInt(props.empid),
            locationid: parseInt(props.locationid),
            Type: props.RightType
        }
        var response = await CallAxios('/api/Employee/UpdateAccessFromaccountSetting|post', data)
        if (!!response && response.data === true) {
            props.getData()
        }
    }
    return <>
        {/* <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip></Tooltip>}> */}
        <a data-tooltip-id="my-tooltip" data-tooltip-content={props.tooltipText} className='btnTblAction  active' onClick={() => submit()}>{props.IconClass}</a>
        {/* </OverlayTrigger> */}

    </>
};
