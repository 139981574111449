import React, { useState, useEffect } from 'react';
import { AiOutlineRight } from 'react-icons/ai';
import styled from 'styled-components';
import { StatusBox } from '../../Helpers/Global/StyledComponents';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";


const LogoMenuWrapper = styled.div`
background-color:#ffffff;
border:1px solid #cccccc;
padding:25px;
position:fixed;
z-index:99;
width:255px;
top: 19px;
    left: ${props => props.iscoll === true ? "95px" : "215px"} ;

`
const Title = styled.div`
font-size:16px;
text-align:center;
margin-bottom:15px;
`
const MenuListBelow = styled.div`
border-top:1px solid #cccccc;
& .menuitem{
    display:flex;
    justify-content:space-between;
    padding:8px 5px;
   
}
& .menuitem:not(:last-child){
    border-bottom:1px solid #ccc;
}

& .menuitem .text{
    font-size:13px;
}
& .menuitem .status{
    font-size:10px;
    padding: 3px 6px;
    border-radius:0;
}
`

const RedBadge = styled.div`
border-radius: 50%;
background: red;
width: 20px;
color: white;
height: 20px;
text-align: center;
font-size: 12px;
display: flex;
align-items: center;
justify-content: center;

`

function LogoMenu(props) {
    const { t: ht } = useTranslation('header');
    const { t: ct } = useTranslation('common');

    return (
        <LogoMenuWrapper iscoll={props.isCollapse}>
            <Title>
                {ht('logoMenu.bastaShiftProducts')}
            </Title>
            <MenuListBelow>
                <Link to={"/account/1/subscriptions"} state={{ tab: "Plans" }} className='menuitem'>
                    <div className='icon d-flex align-items-center'>
                        <AiOutlineRight />
                        <div className='text ms-3'> {ht('logoMenu.premium')} </div>
                    </div>

                    <div className='action'></div>
                </Link>
                <Link to={"/account/1/subscriptions"} state={{ tab: "Plans" }} className='menuitem'>
                    <div className='icon d-flex align-items-center'>
                        <AiOutlineRight />
                        <div className='text ms-3'> {ht('logoMenu.professional')} </div>
                    </div>

                    <div className='action'><StatusBox className='status' status={'confirmed'}> {ct('active')}</StatusBox></div>
                </Link>
                <Link to={"/account/1/subscriptions"} state={{ tab: "Addons" }} className='menuitem'>
                    <div className='icon d-flex align-items-center'>
                        <AiOutlineRight />
                        <div className='text ms-3'> {ht('logoMenu.viewAddons')} </div>
                    </div>

                    <div className='action'><RedBadge>5</RedBadge></div>
                </Link>
            </MenuListBelow>
            <div className='py-2'>
                <Link to={"/account/1/subscriptions"} state={{ tab: "Subscriptions" }} className='btn btn-primary w-100'> {ct('update')}</Link>
            </div>

        </LogoMenuWrapper>
    );
}

export default LogoMenu;