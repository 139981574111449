import moment from 'moment';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, ButtonGroup, Col, Form, Modal, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { BsExclamationCircle } from 'react-icons/bs';
import { CallAxios } from '../Helpers';
import { useTranslation } from "react-i18next";



function RepetitionModal(props) {
    const { t: cl } = useTranslation('calendarPage');
    const { t: ct } = useTranslation('common');


    const AlertValue = {
        single: cl("alertValue.single"),
        future: cl("alertValue.future"),
        all: cl("alertValue.all"),
    }



    const intervalText = {
        daily: <span className="seperator"> {ct("day")}(s)</span>,
        weekly: <span className="seperator"> {ct("week")}(s)</span>,
        monthly: <span className="seperator"> {ct("month")}(s)</span>,
        yearly: <span className="seperator"> {ct("year")}(s)</span>,
    }

    const [RulesDropdown, setRulesDropdown] = useState("weekly");
    const [monthlyRhythm, setmonthlyRhythm] = useState("DayofMonth");
    const [startDate, setstartDate] = useState(new Date());
    const [interval, setinterval] = useState(1);
    const [activeButtons, setactiveButtons] = useState({ Monday: true, Tuesday: true, Wednesday: true, Thursday: true, Friday: true, Saturday: false, Sunday: false });
    const [summary, setsummary] = useState("Daily");
    const [isNeverEnds, setisNeverEnds] = useState(true);
    const [AfterNoofAppointments, setAfterNoofAppointments] = useState(null);
    const [AtTheDate, setAtTheDate] = useState(null);
    const [IsAfterNoofAppointmentsOrAtTheDate, setIsAfterNoofAppointmentsOrAtTheDate] = useState("");
    const [summaryStart, setSummaryStart] = useState("");


    const [deleteShiftModal, setdeleteShiftModal] = useState(false);
    const [deletedropdownvalue, setDeletedropdownvalue] = useState("future");


    useEffect(() => {
        setRulesDropdown(props.RulesDropdown)
        setmonthlyRhythm(props.monthlyRhythm)
        setstartDate(props.startDate)
        setinterval(props.interval)
        setactiveButtons({
            Monday: props.activeButtons.split(',').some(x => x == "MON") ? true : false,
            Tuesday: props.activeButtons.split(',').some(x => x == "TUE") ? true : false,
            Wednesday: props.activeButtons.split(',').some(x => x == "WED") ? true : false,
            Thursday: props.activeButtons.split(',').some(x => x == "THU") ? true : false,
            Friday: props.activeButtons.split(',').some(x => x == "FRI") ? true : false,
            Saturday: props.activeButtons.split(',').some(x => x == "SAT") ? true : false,
            Sunday: props.activeButtons.split(',').some(x => x == "SUN") ? true : false,
        })
        setsummary(props.summary)
        setisNeverEnds(props.isNeverEnds)
        setAfterNoofAppointments(props.AfterNoofAppointments)
        setAtTheDate(props.AtTheDate)
        setIsAfterNoofAppointmentsOrAtTheDate(props.IsAfterNoofAppointmentsOrAtTheDate)
    }, []);
    const saveDataToBaseModel = () => {
        props.setRulesDropdown(RulesDropdown);
        props.setmonthlyRhythm(monthlyRhythm);
        props.setstartDate(startDate);
        props.setinterval(interval);
        let summaryText = getNEXTSummary();
        let concat = summaryStart + '' + summary + '' + summaryText;
        props.setsummary(concat);
        props.setisNeverEnds(isNeverEnds);
        props.setAfterNoofAppointments(AfterNoofAppointments)
        props.setAtTheDate(AtTheDate)
        props.setIsAfterNoofAppointmentsOrAtTheDate(IsAfterNoofAppointmentsOrAtTheDate)
        var days = []
        activeButtons.Monday && days.push("MON")
        activeButtons.Tuesday && days.push("TUE")
        activeButtons.Wednesday && days.push("WED")
        activeButtons.Thursday && days.push("THU")
        activeButtons.Friday && days.push("FRI")
        activeButtons.Saturday && days.push("SAT")
        activeButtons.Sunday && days.push("SUN")

        props.setactiveButtons(days.toString())
        // if (IsAfterNoofAppointmentsOrAtTheDate === "afterNapppoinment") {
        //     props.setIsAfterNoofAppointments(true)
        //     props.setIsAtTheDate(false)
        // }
        // if (IsAfterNoofAppointmentsOrAtTheDate === "Atthedate") {
        //     props.setIsAfterNoofAppointments(false)
        //     props.setIsAtTheDate(true)
        // }
        // if (IsAfterNoofAppointmentsOrAtTheDate === "") {
        //     props.setIsAfterNoofAppointments(false)
        //     props.setIsAtTheDate(false)
        // }
        // let arr = [];
        // Object.entries(activeButtons).map(([key, value]) => value === true ? arr.push(key.toUpperCase().substring(0, 3)) : console.log(key, value));
        // props.setdays(arr.join(','));
        if (!!props.setMode) {
            props.setMode("normal") //To show summary or Create repetaion text in base modal
        }
        props.onHide(false)
    }
    const UpdateShiftbtn = async () => {
        var startDateTime = moment(startDate).startOf('day')
            .add(parseInt(props.StartHours), 'hours')
            .add(parseInt(props.StartMin), 'minutes');

        var endDateTime = moment(startDate).startOf('day')
            .add(parseInt(props.EndHours), 'hours')
            .add(parseInt(props.EndMin), 'minutes');
        var data = {
            id: props.Schedularid,
            shiftId: props.shiftidforedit,
            ShiftDate: startDateTime,
            ShiftEndDate: endDateTime,
            Rule: RulesDropdown,
            interval: parseInt(interval),
            monthlyRhythm: monthlyRhythm,
            isNeverEnds: isNeverEnds,
            daysOfWeek: [],
            IsAtTheDate: IsAfterNoofAppointmentsOrAtTheDate === "Atthedate" ? true : false,
            AtTheDate: !!AtTheDate ? AtTheDate : null,
            IsAfterNoofAppointments: IsAfterNoofAppointmentsOrAtTheDate === "afterNapppoinment" ? true : false,
            AfterNoofAppointments: !!AfterNoofAppointments ? parseInt(AfterNoofAppointments) : null,
            UpdateDDValue: deletedropdownvalue,
            Attachment: null,

        }

        let arr = [];
        Object.entries(activeButtons).map(([key, value]) => value === true ? arr.push(key.toUpperCase().substring(0, 3)) : console.log(key, value));
        data.daysOfWeek = (arr.join(','));
        if (!!props.fnsetsummary) {
            let summaryText = getNEXTSummary();
            let concat = summary + '' + summaryText;
            props.fnsetsummary(concat);
        }
        if (IsAfterNoofAppointmentsOrAtTheDate === "afterNapppoinment") {
            data.IsAfterNoofAppointments = true;
            data.IsAtTheDate = false;
        }
        if (IsAfterNoofAppointmentsOrAtTheDate === "Atthedate") {
            data.IsAfterNoofAppointments = false;
            data.IsAtTheDate = true;
        }
        if (IsAfterNoofAppointmentsOrAtTheDate === "") {
            data.IsAfterNoofAppointments = false;
            data.IsAtTheDate = false;
        }
        if (!!props.File) {
            var obj = {
                OldAttachmentID: "",
                Attachment: props.File
            }
            var form_data = new FormData();
            for (var key in obj) {
                if (typeof (obj[key]) === "object" && !!obj[key] && obj[key].length > 0) {
                    for (var i = 0; i < obj[key].length; i++) {
                        form_data.append(key + [], obj[key][i]);
                    }
                } else {
                    form_data.append(key, obj[key]);
                }
            }
            var Attachmentresponse = await CallAxios("/api/Shift/SaveAttachment|post", form_data);
            if (!!Attachmentresponse && Attachmentresponse.status === 200) {
                data.Attachment = {
                    AttachmentName: props.File.name,
                    AttachmentKey: Attachmentresponse.data
                };
            }
        }

        var response = await CallAxios('/api/Shift/UpdateShedular|post', data);
        if (!!response && response.status === 200) {
            props.RefreshData()
            // props.GetEmployeeList(props.calenderDate)
            // props.getShifts()
            // props.handleModalShowHide(false)
            // props.handleHideEditShiftModal();
            // if (props.OccupancyModal) {
            //     props.handleOccupancyModal();
            // }
        }
    }
    useEffect(() => {
        setWeeklySummary();
    }, [activeButtons]);
    useEffect(() => {
        setSummeryOnBasisOfRuleDropdown(RulesDropdown);
    }, [RulesDropdown]);
    const changingButtonState = (e) => {
        // props.setdays()
        // let prevState = [...WorkAreaList];
        // let newarr = prevState.map(x => {
        //     return { ...x, isEditable: false }
        // })
        // newarr[index].isEditable = true;
        // setWorkAreaList(newarr);

        var prevState = { ...activeButtons };
        var newState = { ...prevState, [e.target.name]: !prevState[e.target.name] }
        setactiveButtons(newState)
    }
    const getValuesofRules = (e) => {
        setRulesDropdown(e.target.value);
        switch (e.target.value) {
            case "daily":
                setsummary(e.target.value)
                break;
            case "yearly":
                setsummary(e.target.value)
                break;
            case "monthly":
                setMonthlySummary(startDate, monthlyRhythm);
                break;
            case "weekly":
                setWeeklySummary();
                break;
            default:
                break;
        }
    }
    const setSummeryOnBasisOfRuleDropdown = (value) => {
        switch (value) {
            case "daily":
                setsummary("")
                break;
            case "yearly":
                setsummary("")
                break;
            case "monthly":
                setMonthlySummary(startDate, monthlyRhythm);
                break;
            case "weekly":
                setWeeklySummary();
                break;
            default:
                break;
        }
    }
    useEffect(() => {
        if (interval) {
            setInterValSummary(interval)
        }
    }, [interval, RulesDropdown]);
    const setInterValSummary = (num) => {
        if (Number(num) !== 1) {
            setSummaryStart(`Every ${getNumberWithOrdinal(interval)} ${getdayConventions(num)}`)
        } else {
            setSummaryStart(getdayConventions(num))
        }
    }
    const getdayConventions = (num) => {
        const obj = {
            "daily": "day",
            "weekly": "week",
            "monthly": "month",
            "yearly": "year"
        }
        if (num > 1) {
            return obj[RulesDropdown]
        } else {
            return capitalizeFirstLetter(RulesDropdown)
        }
    }
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const getNumberWithOrdinal = (n) => {
        var s = ["th", "st", "nd", "rd"],
            v = n % 100;
        return n + (s[(v - 20) % 10] || s[v] || s[0]);
    }
    const setWeeklySummary = () => {
        const filteredByValue = Object.fromEntries(
            Object.entries(activeButtons).filter(([key, value]) => value === true));
        if (isEmpty(filteredByValue)) {
            setsummary("")
        } else {
            setsummary(" on " + Object.keys(filteredByValue).map((valu) => " " + valu + " "))
        }
    }
    const isEmpty = (obj) => {
        return Object.keys(obj).length === 0;
    }
    const setMonthlySummary = (date, type) => {
        if (type === 'DayofMonth') {
            setmonthlyRhythm("DayofMonth");
            let day = moment(date).format("w");
            setsummary(" on the " + moment(date).format("Do [day of the month]"));
            setstartDate(date)
        }
        else if (type === 'Weekday') {
            setWeekdaySummary(startDate)
        }
    }
    const setWeekdaySummary = (date) => {
        let dayStr = moment(date).format('YYYY-MM-DD');
        setsummary(test(moment(dayStr)))
    }
    function test(mJsDate) {
        let convection = "";
        switch (Math.ceil(mJsDate.date() / 7)) {
            case 1:
                convection = "st";
                break;
            case 2:
                convection = "nd";
                break;
            case 3:
                convection = "rd";
                break;

            default:
                convection = "th";
                break;
        }
        var str = " on the " + Math.ceil(mJsDate.date() / 7) + convection + " " + moment(mJsDate.toLocaleString()).format("dddd")

        return str;
    }
    const updateMonthlyrythm = (date, type) => {
        if (type === 'DayofMonth') {
            if (!!date) {
                date = startDate
            }
            setmonthlyRhythm("DayofMonth");
            let day = moment(date).format("w");
            setsummary(" on the " + moment(date).format("Do [day of the month]"));
            setstartDate(date)
        }
        else {
            setWeekdaySummary(startDate)
        }
        if (!!type) {
            setmonthlyRhythm(type)
        } else {
            setmonthlyRhythm(monthlyRhythm)
        }
    }
    const getNEXTSummary = () => {
        return isNeverEnds === false ? IsAfterNoofAppointmentsOrAtTheDate === "Atthedate" ? !!AtTheDate ? ", till " + moment(AtTheDate).format("DD/MM/YYYY") : "" : !!AfterNoofAppointments ? (", " + AfterNoofAppointments + " times") : "" : ""
    }

    return (
        <>
            <Modal show={props.show} onHide={() => { props.onHide(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>{cl("repetition")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={12} >
                                <Form.Group className='mb-3' controlId="RepetitionShiftForm.SelectRule">
                                    <Form.Label>{ct("rule")}</Form.Label>
                                    <Form.Control as="select" className='form-select' value={RulesDropdown} onChange={(e) => getValuesofRules(e)} custom>
                                        <option value="daily">{ct("daily")}</option>
                                        <option value="weekly">{ct("weekly")}</option>
                                        <option value="monthly">{ct("monthly")}</option>
                                        <option value="yearly">{ct("yearly")}</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={12} >
                                <Form.Group className='mb-3' controlId="RepetitionShiftForm.txtInterval">
                                    <Form.Label>{ct("interval")}</Form.Label>
                                    <div className="flexInputGroup d-flex align-items-center">
                                        <Form.Control as="select" className='form-select me-2' onChange={(e) => setinterval(e.target.value)} style={{ width: "80px" }} value={interval} custom>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                            <option value="13">13</option>
                                            <option value="14">14</option>
                                            <option value="15">15</option>
                                            <option value="16">16</option>
                                            <option value="17">17</option>
                                            <option value="18">18</option>
                                            <option value="19">19</option>
                                            <option value="20">20</option>
                                            <option value="21">21</option>
                                            <option value="22">22</option>
                                            <option value="23">23</option>
                                            <option value="24">24</option>
                                            <option value="25">25</option>
                                            <option value="26">26</option>
                                            <option value="27">27</option>
                                            <option value="28">28</option>
                                            <option value="29">29</option>
                                            <option value="30">30</option>
                                            <option value="31">31</option>
                                        </Form.Control>
                                        {intervalText[RulesDropdown]}
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col md={12} >
                                <Form.Group className='mb-3' controlId="RepetitionShiftForm.txtStartOn">
                                    <Form.Label>{ct("startsOn")}</Form.Label>
                                    <div><DatePicker selected={props.startDate} disabled onChange={(selected) => { RulesDropdown === "monthly" ? setMonthlySummary(selected) : setstartDate(selected) }} className="w-100 form-control" /></div>
                                </Form.Group>
                            </Col>
                            {
                                RulesDropdown === "monthly" ?
                                    <Col md={12} >
                                        <label className="form-label">{cl("monthlyRhythm")}</label>
                                        <Form.Control className='form-select' as="select" value={monthlyRhythm} onChange={(e) => updateMonthlyrythm(startDate, e.target.value)} custom>
                                            <option value="DayofMonth">{cl("dayOfmonth")}</option>
                                            <option value="Weekday">{cl("weekday")}</option>
                                        </Form.Control>
                                    </Col>
                                    : null
                            }
                            {
                                RulesDropdown === "weekly" ?
                                    <Col md={12}>
                                        <label className="form-label">{cl("daysOfTheWeek")}</label>
                                        <div>
                                            <ButtonGroup onClick={(e) => changingButtonState(e)} aria-label="Basic example" size="sm" className="btnSelectGroup">
                                                <Button name='Monday' variant="secondary" className={`${activeButtons.Monday === true ? " btnPrimaryGreen" : ""} makeitselect`} >{ct("monday")}</Button>
                                                <Button name='Tuesday' variant="secondary" className={`${activeButtons.Tuesday === true ? " btnPrimaryGreen" : ""} makeitselect`}>{ct("tuesday")}</Button>
                                                <Button name='Wednesday' variant="secondary" className={`${activeButtons.Wednesday === true ? " btnPrimaryGreen" : ""} makeitselect`}>{ct("wednesday")}</Button>
                                                <Button name='Thursday' variant="secondary" className={`${activeButtons.Thursday === true ? " btnPrimaryGreen" : ""} makeitselect`}>{ct("thursday")}</Button>
                                                <Button name='Friday' variant="secondary" className={`${activeButtons.Friday === true ? " btnPrimaryGreen" : ""} makeitselect`}>{ct("friday")}</Button>
                                                <Button name='Saturday' variant="secondary" className={`${activeButtons.Saturday === true ? " btnPrimaryGreen" : ""} makeitselect`}>{ct("saturday")}</Button>
                                                <Button name='Sunday' variant="secondary" className={`${activeButtons.Sunday === true ? " btnPrimaryGreen" : ""} makeitselect`}>{ct("sunday")}</Button>
                                            </ButtonGroup>
                                        </div>
                                    </Col>
                                    : null
                            }
                            <Col md={12} className="pt-3">
                                <Form.Group controlId="formBasicCheckbox">
                                    <Form.Label>{cl("ends")}</Form.Label>
                                    <Form.Check type="checkbox" label={ct("never")} defaultChecked={isNeverEnds} onClick={() => setisNeverEnds(!isNeverEnds)} />
                                </Form.Group>
                            </Col>
                            {
                                isNeverEnds === false ?
                                    <Col md={12}>
                                        <div className="check">
                                            <div className="d-flex align-items-center mb-2">
                                                <Form.Check
                                                    inline
                                                    label={ct("after")}
                                                    type={"radio"}
                                                    name="apppoinmenttime"
                                                    checked={IsAfterNoofAppointmentsOrAtTheDate === "afterNapppoinment" ? true : false}
                                                    id={`inline-radio-3`}
                                                    value="afterNapppoinment"
                                                    onChange={(e) => setIsAfterNoofAppointmentsOrAtTheDate(e.target.value)}
                                                />
                                                <Form.Control style={{ minHeight: "32px" }} type="text" onClick={() => setIsAfterNoofAppointmentsOrAtTheDate("afterNapppoinment")} onChange={(e) => setAfterNoofAppointments(e.target.value)} value={AfterNoofAppointments} className="smallNumFormControlss me-2" /> {ct("appointments")}
                                            </div>
                                            <Form.Group controlId="RepetitionShiftForm">
                                                <div className="d-flex align-items-center">
                                                    <Form.Check
                                                        inline
                                                        label={ct("atThe")}
                                                        name="apppoinmenttime"
                                                        checked={IsAfterNoofAppointmentsOrAtTheDate === "Atthedate" ? true : false}
                                                        type={"radio"}
                                                        id={`inline-radio-2`}
                                                        value="Atthedate"
                                                        onChange={(e) => setIsAfterNoofAppointmentsOrAtTheDate(e.target.value)}
                                                    />
                                                    <div>
                                                        <DatePicker selected={AtTheDate} onFocus={() => setIsAfterNoofAppointmentsOrAtTheDate("Atthedate")} onChange={(selected) => { setAtTheDate(selected) }} className="w-100 shortheight" /></div>
                                                </div>
                                            </Form.Group>
                                        </div>
                                    </Col>
                                    : null
                            }
                            <Col md={12} className="">
                                <div className="SummaryText">{ct("summary")}: {summaryStart} {summary} {isNeverEnds === false ? IsAfterNoofAppointmentsOrAtTheDate === "Atthedate" ? !!AtTheDate ? ", till " + moment(AtTheDate).format("DD/MM/YYYY") : null : !!AfterNoofAppointments ? (", " + AfterNoofAppointments + " times") : "" : null}</div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.onHide(false)}>{cl("toShutdown")}</Button>
                    <Button className="btn btnPrimaryGreen" onClick={() => { !!props.shiftidforedit ? setdeleteShiftModal(true) : saveDataToBaseModel() }}>{ct("toSave")}</Button>
                </Modal.Footer>
            </Modal>
            {
                deleteShiftModal === true ?
                    <Modal show={deleteShiftModal} onHide={() => setdeleteShiftModal(false)} >
                        <Modal.Header className="RelativeModalHeader" closeButton >
                            <Modal.Title>{cl("editSingleEvent")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <label className='fw-bolder'>{cl("iWantUpdate")}</label>
                                <select className='Noarrow bgPrimaryGrey text-center form-select custom-select mb-3' value={deletedropdownvalue} onChange={(e) => setDeletedropdownvalue(e.target.value)}>
                                    <option value="future">{cl("allFutureEvents")}</option>
                                    <option value="all">{cl("allevents")}</option>
                                </select>
                                <div className="alert alert-primary px-3" role="alert">
                                    <div className='row'>
                                        <div className='col-md-1'>
                                            <BsExclamationCircle style={{ fontSize: "26px" }} />
                                        </div>
                                        <div className='col-md-11'>
                                            {AlertValue[deletedropdownvalue]}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <a className='text-danger me-3' onClick={() => setdeleteShiftModal(false)}>{ct("cancel")}</a>
                            <Button className="btn btnPrimaryGreen" onClick={() => UpdateShiftbtn()}>{ct("update")}</Button>
                        </Modal.Footer>
                    </Modal>
                    : null
            }
        </>
    );
}

export default RepetitionModal;