import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { Button, Col, Dropdown, Form, Row, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { CallAxios } from "../Helpers";
import { config } from "../Helpers/Config";
import { FaDownload, FaCog, FaUpload } from "react-icons/fa";
import { BsFillTrashFill, BsPlusCircleFill } from "react-icons/bs";
import { forwardRef } from "react";
import FileBase64 from 'react-file-base64';
import TagifyComponent from "./TagifyComponent";
import { useDispatch, useSelector } from "react-redux";
import { setIsshowSystemMessage } from "../Store/slices/AppSlice";
import { GetEmployeeDD } from "../Helpers/MasterData";
import { CircleBtnWithIcon } from "../Helpers/Global/StyledComponents";
import FollowersReactSelect from "./FollowersReactSelect";
import { useTranslation } from "react-i18next";

const CustomToggle = forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}

    </a>
));


function NotesModal(props) {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');

    const fileInput = useRef();
    const [FileName, setFileName] = useState("");
    const [File, setFile] = useState("");
    const [FollowersList, setFollowersList] = useState("");
    const [ShowNoteFormControl, setShowNoteFormControl] = useState(false);
    const [NotesList, setNotesList] = useState([]);
    const [objIdforNotes, setobjIdforNotes] = useState(props.objIdforNote);
    const [Comment, setComment] = useState("");
    const isshowSystemMessage = useSelector(x => x.app.isshowSystemMessage);
    const dispatch = useDispatch();

    const handleNoteModalFormControl = () => {

        setShowNoteFormControl(!ShowNoteFormControl)
    }
    const UpdateFollowers = async (value) => {
        setFollowersList(value)
        var data = {
            ObjectId: objIdforNotes,
            noteEntity: props.noteEntity,
            FollowerList: value.map(x => x.value)
        }
        await CallAxios("/api/Notes/AddUpdateNoteFollowers|post", data)
    }
    useEffect(() => {
        getNotes()

    }, []);
    const getNotes = async () => {
        var data = {
            ObjectId: objIdforNotes,
            noteEntity: props.noteEntity,
        };
        var response = await CallAxios("/api/Notes/getnotes|post", data);
        if (!!response && response.status === 200) {
            let arr = response.data.notesList.map(v => ({ ...v, editable: false }))
            setNotesList(arr)
            setFollowersList(response.data.followers)
        }
    }
    const Submit = async () => {
        if (Comment === "") {
            toast.error(ct("errorMsg.contentNotBlank"))
        }
        else {
            var follwerlistids = [];
            if (!!FollowersList && FollowersList.length > 0) {
                FollowersList.map((value, key) => {
                    follwerlistids.push(value.id)
                })
            }

            var data = {
                ObjectId: objIdforNotes,
                noteEntity: props.noteEntity,
                noteType: "User",
                NoteText: Comment,
                Attachment: File,
                Followers: follwerlistids
            }
            var form_data = new FormData();
            Object.keys(data).forEach((key, index) => {
                if (typeof (data[key]) === "object" && !!data[key] && data[key].length > 0) {
                    for (var i = 0; i < data[key].length; i++) {
                        form_data.append(key + [], data[key][i]);
                    }
                } else if (data[key]) {
                    form_data.append(key, data[key]);
                }
            })
            // var form_data = new FormData();
            // for (var key in data) {
            //     if (typeof (data[key]) === "object" && !!data[key] && data[key].length > 0) {
            //         for (var i = 0; i < data[key].length; i++) {
            //             form_data.append(key + [], data[key][i]);
            //         }
            //     } else {
            //         form_data.append(key, data[key]);
            //     }
            // }
            var response = await CallAxios("/api/Notes/addnotes|post", form_data)
            if (!!response && response.status === 200) {
                let arr = response.data.notesList.map(v => ({ ...v, editable: false }))
                setNotesList(arr)
                setFileName("");
                setFile("")
                setFollowersList("")
                setComment("")
                setFollowersList(response.data.followers)
            }
        }
    }
    const getFiles = (files) => {
        setFileName(files.name);
        setFile(files.file)
    }
    const EditText = async (obj, e) => {
        var data = {
            ObjectId: objIdforNotes,
            noteEntity: props.noteEntity,
            noteType: "User",
            NoteText: e.target.value,
            NoteId: obj.noteId
        }
        var response = await CallAxios("/api/Notes/updatenotes|post", data)
        if (!!response && response.status === 200) {
            let arr = response.data.notesList.map(v => ({ ...v, editable: false }))
            setNotesList(arr)
            setFollowersList(response.data.followers)
        }
    }
    const DeleteNote = async (obj, e) => {
        var data = {
            ObjectId: objIdforNotes,
            noteEntity: props.noteEntity,
            noteType: "User",
            NoteId: obj.noteId
        }
        var response = await CallAxios("/api/Notes/deletenotes|post", data)
        if (!!response && response.status === 200) {
            let arr = response.data.notesList.map(v => ({ ...v, editable: false }))
            setNotesList(arr)
            setFollowersList(response.data.followers)
        }
    }
    const Deleteattachment = async (obj) => {
        var data = {
            ObjectId: objIdforNotes,
            noteEntity: props.noteEntity,
            noteType: "User",
            NoteId: obj.noteId
        }
        var response = await CallAxios("/api/Notes/Deleteattachment|post", data)
        if (!!response && response.status === 200) {
            let arr = response.data.notesList.map(v => ({ ...v, editable: false }))
            setNotesList(arr)
            setFollowersList(response.data.followers)
        }
    }
    const EditMessage = (index) => {
        let Editablearray = [...NotesList];
        let newobj = {
            ...Editablearray[index],
            editable: true
        }
        Editablearray[index] = newobj;
        setNotesList(Editablearray)
    }
    return (
        <>
            <Form.Check onChange={() => dispatch(setIsshowSystemMessage(!isshowSystemMessage))} className="checkBoxRight" defaultChecked={isshowSystemMessage} inline label={t('commonNoteMdl.showSystemMessages')} type="checkbox" id="ViewSystemMsg" />
            <Row>
                <Col md={12} className="border-bottom mb-3" >
                    <div>
                        <Table responsive>
                            <tbody>
                                {!!NotesList && NotesList.length > 0 ? NotesList.map((val, key) => {
                                    return val.noteType === "System" ?
                                        isshowSystemMessage === true ?
                                            <tr>
                                                <td>
                                                    <div className="dataTextCell">
                                                        <div className="tblTitleTextsm"> {val.noteType}</div>
                                                        <div className="tblValueTextsm">{moment(val.date).format('MM/DD/YYYY H:m a')}</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="dataTextCell">
                                                        <div className="tblTitleTextsm">{val.noteText}</div>
                                                        <div className="tblValueTextsm"> {ct('by')} {val.employeeName}</div>

                                                    </div>
                                                </td>
                                            </tr>
                                            : null
                                        :
                                        <tr>
                                            <td>
                                                <div className="dataTextCell">
                                                    <div className="tblTitleTextsm"> {val.employeeName}</div>
                                                    <div className="tblValueTextsm">{moment(val.date).format('MM/DD/YYYY H:m a')}</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="dataTextCell">
                                                    <div className='d-flex'>
                                                        <div className="tblTitleTextsm w-95">

                                                            {val.editable === false ? val.noteText : <textarea onKeyPress={(e) => e.which === 13 ? EditText(val, e) : null} className='form-control w-75 p-1' rows={1}>{val.noteText}</textarea>}
                                                            {val.attachment !== null ?
                                                                <span className='AttachmentSpace d-block text-dark mt-2 text-sm'>
                                                                    {val.attachment} <a className="mx-2 text-dark" href={config.S3BucketURL + val.attachment}><FaDownload /></a>  <BsFillTrashFill onClick={(e) => (Deleteattachment(val))} />
                                                                </span>
                                                                : null}
                                                        </div>
                                                        <Dropdown>
                                                            <Dropdown.Toggle as={CustomToggle} variant="success" id="dropdown-basic">
                                                                <FaCog className='text-dark mx-2' />
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#/action-1" onClick={(e) => EditMessage(key)}> {ct('toEdit')}</Dropdown.Item>
                                                                <Dropdown.Item href="#/action-2" onClick={(e) => DeleteNote(val)}>{ct('commonNoteMdl.extinguish')}</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>

                                                </div>

                                            </td>
                                        </tr>

                                }) : null
                                }

                            </tbody>
                        </Table>
                    </div>
                </Col>
                <Col md={12} className="position-relative mb-2">
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Control as="textarea" rows={3} onChange={(e) => setComment(e.target.value)} value={Comment} placeholder={t('commonNoteMdl.leaveComment')} className="pb24" />
                    </Form.Group>
                    <Button className="btn btnPrimaryGreen fixedRightButton text-white mt-3" onClick={(e) => Submit()}> {ct('send')}</Button>
                </Col>
                <Col md={12}>
                    <a className="btnUpload position-relative ">
                        <FaUpload className="icon" /> <span className="mx-2">{!!FileName ? FileName : <>{t('commonNoteMdl.addAttachment')}</>}</span>
                        <FileBase64
                            hidden
                            id='uploadNoteFile'
                            ref={fileInput}
                            multiple={false}
                            onDone={getFiles}
                        />
                    </a>
                </Col>

                <Col md={12} className="py-3">
                    {
                        ShowNoteFormControl === false ?
                            <>
                                <span className="btnfollowers" onClick={() => setShowNoteFormControl(true)}>
                                    <span> {t('commonNoteMdl.followers')} </span>

                                    {FollowersList.length > 0 ?
                                        FollowersList.map((p, i) =>
                                            <span className="btnFollowersNames">
                                                <span>{p.label.split(' ').map((val, index) => val.charAt(0))}</span>
                                            </span>
                                        )
                                        : null}
                                    <BsPlusCircleFill className="icon Btn ms-2" />
                                </span>
                            </>
                            :
                            <Form.Group controlId="exampleForm.ControlInput1">
                                {/* <TagifyComponent setDefault={FollowersList} getAll={getFollowersList} closediv={handleNoteModalFormControl} /> */}
                                <FollowersReactSelect setDefault={FollowersList} UpdateFollowers={UpdateFollowers} />
                            </Form.Group>
                    }

                </Col>
            </Row>
        </>
    );
}

export default NotesModal;
