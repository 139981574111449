import { saveAs } from 'file-saver';
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row, Tab, Tabs } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import { BiExport } from "react-icons/bi";
import { FaCalendarCheck, FaRegCalendarAlt, FaRegCalendarTimes } from "react-icons/fa";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { useSelector } from "react-redux";
import HeaderButton from "../../Components/HeaderButton";
import TagsCheckSelectFilter from "../../Components/TagsCheckSelectFilter";
import WorkAreaCheckSelectFilter from "../../Components/WorkAreaCheckSelectFilter";
import { CallAxiosBlob } from "../../Helpers";
import { Constants } from "../../Helpers/Contents";
import { ActionItem, ActionsBox, SubHeadTitle, SubHeaderTitleBox, SubHeaderWrapper } from "../../Helpers/Global/StyledComponents";
import { useTranslation } from "react-i18next";

function CalendarHeader(props) {
    const { t: cl } = useTranslation('calendarPage');


    const user = useSelector(x => x.app.user)
    const appSettings = useSelector(x => x.app.appSettings)
    const currentView = useSelector(x => x.app.currentView)
    const themeColorBtn = useSelector(state => state.app.theme.colors.buttons);

    const [ExportModal, setExportModal] = useState(false);

    const handleCalendarView = (CalendarView) => {
        if (CalendarView === "RosterCalView") {
            props.setAbsencesView(false)
            props.setAvaiableViewBit(false)
            props.setRosterView(true)
            localStorage.setItem("RosterActiveTab", "RosterView")
        }
        else if (CalendarView === "AbsenceCalView") {
            props.setRosterView(false)
            props.setAvaiableViewBit(false)
            props.setAbsencesView(true)
            localStorage.setItem("RosterActiveTab", "AbsencesView")
        }
        else if (CalendarView === "AvaiableCalView") {
            props.setRosterView(false)
            props.setAbsencesView(false)
            props.setAvaiableViewBit(true)
            localStorage.setItem("RosterActiveTab", "AvaiableView")
        }
    }
    return (
        <>

            <SubHeaderWrapper className="py-3">
                <SubHeaderTitleBox>
                    <FaRegCalendarAlt color={themeColorBtn} size={22} />
                    <SubHeadTitle>{cl("calendar")} : {user.LocationName}</SubHeadTitle>
                </SubHeaderTitleBox>
                <ActionsBox>
                    {
                        currentView === Constants.layoutType.Employee && appSettings.employeeAccess.canSeeavailabilities === true ?
                            <ActionItem className={props.AvaiableViewBit ? 'active' : null} onClick={() => handleCalendarView("AvaiableCalView")}>
                                <HeaderButton title={cl("availability")} icon={<FaCalendarCheck color='#fff' size={18} />} />
                            </ActionItem>
                            : null
                    }
                    {
                        (user.roles.some(x => x === Constants.roles.AccountAdmin || x === Constants.roles.WorkspaceAdmin || x === Constants.roles.Admin)) ||
                            (!!appSettings.employeeAccess && appSettings.employeeAccess.canSeeCalender === true) ?
                            <ActionItem className={props.RosterViewBit ? 'active' : null} onClick={() => handleCalendarView("RosterCalView")}>
                                <HeaderButton title={cl("roster")} icon={<FaRegCalendarAlt color='#fff' size={18} />} />
                            </ActionItem>
                            : null
                    }
                    <ActionItem className={props.AbsencesViewBit ? 'active' : null} onClick={() => handleCalendarView("AbsenceCalView")}>
                        <HeaderButton title={cl("absences")} icon={<FaRegCalendarTimes color='#fff' size={18} />} />
                    </ActionItem>

                    <ActionItem onClick={() => setExportModal(true)}>
                        <HeaderButton title={cl("export")} icon={<BiExport color='#fff' size={18} className='rotate90deg' />} />
                    </ActionItem>
                    <ActionItem>
                        <TagsCheckSelectFilter />
                    </ActionItem>
                    <ActionItem>
                        <WorkAreaCheckSelectFilter />
                    </ActionItem>

                </ActionsBox>
            </SubHeaderWrapper>



            {
                ExportModal === true ?
                    <ExportModel show={ExportModal} onHide={setExportModal} />
                    : null
            }
        </>
    );
}

export default CalendarHeader;
const DataSectionArray = [
    { key: "Absences", label: "Absences" },
    { key: "Events", label: "Events" },
];

function ExportModel(props) {
    const { t: cl } = useTranslation('calendarPage');
    const { t: ct } = useTranslation('common');


    moment.updateLocale('en', {
        week: {
            dow: 1, // Monday is the first day of the week.
        }
    });
    const [tagsDD, settagsDD] = useState([]);
    const [workspaceList, setWorkspaceList] = useState([]);
    const [SelectedWorkSpaceids, setSelectedWorkSpaceids] = useState([]);
    const [SelectedTagsids, setSelectedTagsids] = useState([]);
    const [CalendarMonthControl, setCalendarMonthControl] = useState(false);
    const [ResourceRange, setResourceRange] = useState(true);

    const [Reporttype, setReporttype] = useState("CalendarExport");
    const [periodtype, setperiodtype] = useState("Week");
    const [StartDate, setStartDate] = useState(new Date());
    const [EndDate, setEndDate] = useState(new Date());
    const [Datatype, setDatatype] = useState(["Absences", "Events"]);
    const [Format, setFormat] = useState("PDF");
    const [HideCompletelyOccupiedLayers, setHideCompletelyOccupiedLayers] = useState(false);
    const [HideRequirements, setHideRequirements] = useState(false);
    const [GroupbyWorkingAreas, setGroupbyWorkingAreas] = useState(false);
    const [PlaceShiftsbyEndDate, setPlaceShiftsbyEndDate] = useState(false);
    const [ShowNetDurationsPerShift, setShowNetDurationsPerShift] = useState(false);
    const [ShowTags, setShowTags] = useState(false);
    const [HideEmployeesWithoutAssignmentOrAbsence, setHideEmployeesWithoutAssignmentOrAbsence] = useState(false);
    const [HideWorkingAreaTitlesInCellsOfEvents, setHideWorkingAreaTitlesInCellsOfEvents] = useState(false);
    const [HideStartEndTimes, setHideStartEndTimes] = useState(false);
    const [HideCalculations, setHideCalculations] = useState(false);
    const [AddPageBreakAfterEveryWeeks, setAddPageBreakAfterEveryWeeks] = useState(false);
    const [PageBreakAfterEachWorkingArea, setPageBreakAfterEachWorkingArea] = useState(false);
    const appSettings = useSelector(x => x.app.appSettings)
    useEffect(() => {
        settagsDD(appSettings.tags.filter(x => x.value !== "Withouttags"))
        setSelectedTagsids(appSettings.tags.filter(x => x.value !== "Withouttags").map(x => x.value))

        setWorkspaceList(appSettings.WorkSpaces);
        setSelectedWorkSpaceids(appSettings.WorkSpaces.map(x => x.value))

        // getTagsDD()
        // getworkspaces()
    }, []);

    const submit = async () => {
        var data = {
            Reporttype: Reporttype,
            periodtype: periodtype,
            StartDate: moment(StartDate).startOf('day'),
            EndDate: moment(EndDate).endOf('day'),
            WorkingAreaId: SelectedWorkSpaceids,
            TagId: SelectedTagsids,
            Datatype: Datatype,
            Format: Format,
            HideCompletelyOccupiedLayers: HideCompletelyOccupiedLayers,
            HideRequirements: HideRequirements,
            GroupbyWorkingAreas: GroupbyWorkingAreas,
            PlaceShiftsbyEndDate: Format === "PDF" ? PlaceShiftsbyEndDate : false,
            ShowNetDurationsPerShift: Format === "PDF" ? ShowNetDurationsPerShift : false,
            ShowTags: ShowTags,
            HideEmployeesWithoutAssignmentOrAbsence: Format === "PDF" ? HideEmployeesWithoutAssignmentOrAbsence : false,
            HideWorkingAreaTitlesInCellsOfEvents: HideWorkingAreaTitlesInCellsOfEvents,
            HideStartEndTimes: HideStartEndTimes,
            HideCalculations: Format === "PDF" ? HideCalculations : false,
            AddPageBreakAfterEveryWeeks: AddPageBreakAfterEveryWeeks,
            PageBreakAfterEachWorkingArea: Format === "PDF" ? PageBreakAfterEachWorkingArea : false
        }
        var response = await CallAxiosBlob("/api/Calendar/CalendarExport|post", data);
        if (!!response && response.status === 200) {
            if (Format === 'PDF') {
                saveAs(response.data, "Calendar.pdf")
            }
            else if (Format === 'CSV') {
                saveAs(response.data, "Calendar.csv")
            }
            else if (Format === 'XLSX') {
                saveAs(response.data, "Calendar.xlsx")
            }
        }
    }
    const restValues = (selectedTab) => {
        setCalendarMonthControl(false);
        setStartDate(new Date(moment().startOf('week')));
        setEndDate(new Date(moment().endOf('week')));
        setFormat("PDF");
        setHideCompletelyOccupiedLayers(false);
        setHideRequirements(false);
        setSelectedWorkSpaceids(workspaceList.map(x => x.value));
        setSelectedTagsids(tagsDD.map(x => x.value));
        setCalendarMonthControl(false);
        setDatatype([]);
        setGroupbyWorkingAreas(false);
        setPlaceShiftsbyEndDate(false);
        setShowNetDurationsPerShift(false);
        setShowTags(false);
        setHideEmployeesWithoutAssignmentOrAbsence(false);
        setHideWorkingAreaTitlesInCellsOfEvents(false);
        setHideStartEndTimes(false);
        setHideCalculations(false);
        setAddPageBreakAfterEveryWeeks(false);
        setResourceRange(true)
        if (selectedTab === 'CalendarExport') {
            setperiodtype("Week");
        } else if (selectedTab === 'ListExport') {
            setperiodtype(null);
        } else if (selectedTab === 'ResourceExport') {
            setperiodtype("Range");
            setDatatype(["Absences", "Events"])
        }
    }
    return (
        <>
            <Modal show={props.show} onHide={() => props.onHide(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{cl("exportModalTitle")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="classicTab">
                        <Tabs className="nav-underline" onSelect={(key) => { setReporttype(key); restValues(key) }} defaultActiveKey="CalendarExport" transition={false} id="noanim-tab-example">
                            <Tab eventKey="CalendarExport" title={cl("calendar")}>

                                <div className="TabContentWrapper py-3">
                                    <div className="TabContentSectionTitle fw-bold h6">{cl("repPeriod")}</div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="me-2">
                                            <Form.Check type="radio" label={cl("week")} name="SelectPeriod" id="formExportPeriodWeek" value="Week" checked={periodtype === "Week" ? true : false} onChange={() => { setCalendarMonthControl(false); setperiodtype("Week") }} />
                                        </div>
                                        <div>
                                            <Form.Check type="radio" label={cl("month")} name="SelectPeriod" id="formExportPeriodMonth" value="Month" checked={periodtype === "Month" ? true : false} onChange={() => { setCalendarMonthControl(true); setperiodtype("Month") }} />
                                        </div>
                                        <Container fluid>
                                            <Row>
                                                <Col md={6} className="pt-3">
                                                    <Form.Group className="mb-3" controlId="ExportForm.txtWeekDate">
                                                        <DatePicker className="form-control" selected={StartDate} onChange={date => setStartDate(date)} PlaceholderText={ct("startTime")} />
                                                    </Form.Group>
                                                </Col>
                                                {CalendarMonthControl === true &&
                                                    <Col md={6} className="pt-3">
                                                        <Form.Group className="mb-3" controlId="ExportForm.txtMonthDate">
                                                            <DatePicker className="form-control" selected={EndDate} onChange={date => setEndDate(date)} PlaceholderText={ct("endTime")} />
                                                        </Form.Group>
                                                    </Col>
                                                }
                                            </Row>
                                        </Container>
                                    </div>

                                    <div className="mb-3">
                                        <Row>
                                            <Col md={6}>
                                                <div className="TabContentSectionTitle mb-2 fw-bold"> {ct("workingAreas")} </div>
                                                {
                                                    !!workspaceList && workspaceList.length > 0 &&
                                                    <DropdownMultiselect selected={SelectedWorkSpaceids} optionKey="value" handleOnChange={(selected) => setSelectedWorkSpaceids(selected)} selectDeselectLabel={ct("selectAll")} options={workspaceList} placeholder={ct("workingAreas")} name='workspaceDD' />
                                                }
                                            </Col>
                                            <Col md={6}>
                                                <div className="TabContentSectionTitle mb-2 fw-bold"> {ct("tags")} </div>
                                                {
                                                    !!tagsDD && tagsDD.length > 0 &&
                                                    <DropdownMultiselect selected={SelectedTagsids} optionKey="value" handleOnChange={(selected) => setSelectedTagsids(selected)} selectDeselectLabel={ct("selectAll")} options={tagsDD} placeholder={ct("tags")} name='tagsDD' />
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="TabContentSectionTitle pt-2 fw-bold">{ct("format")}</div>
                                    <div>
                                        <Form.Check type="radio" label="PDF" name="SelectFormatCalendar" id="formExportFormatPDf" defaultChecked={true} onChange={() => setFormat("PDF")} />
                                    </div>
                                    <div className="TabContentSectionTitle pt-3 fw-bold">{ct("moreOptions")}</div>
                                    <div>
                                        <Form.Check label={cl("hideShifts")} name="SelectotherOption" id="formExportHideOccupiedLayer" onChange={(e) => setHideCompletelyOccupiedLayers(!HideCompletelyOccupiedLayers)} />
                                    </div>
                                    <div>
                                        <Form.Check label={cl("hideDemand")} name="SelectotherOption" id="formExportHideRequirement" onChange={(e) => setHideRequirements(!HideRequirements)} />
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="ListExport" title={ct("list")}>
                                <div className="TabContentWrapper py-3">
                                    <div className="TabContentSectionTitle fw-bold mb-2">{ct("period")}</div>
                                    <div>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group controlId="ExportForm.txtWeekDateList">
                                                    <DatePicker className="form-control" selected={StartDate} onChange={date => setStartDate(date)} PlaceholderText={ct("startTime")} />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group controlId="ExportForm.txtMonthDateList">
                                                    <DatePicker className="form-control" selected={EndDate} onChange={date => setEndDate(date)} PlaceholderText={ct("endTime")} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="TabContentSectionTitle fw-bold pt-3 mb-2">{ct("date")}</div>
                                    <div>
                                        <Row>
                                            <Col md={6}>
                                                {
                                                    !!workspaceList && workspaceList.length > 0 &&
                                                    <DropdownMultiselect selected={SelectedWorkSpaceids} optionKey="value" handleOnChange={(selected) => setSelectedWorkSpaceids(selected)} selectDeselectLabel={ct("selectAll")} options={workspaceList} placeholder={ct("workingAreas")} name='workspaceDD' />
                                                }
                                            </Col>
                                            <Col md={6}>
                                                {
                                                    !!tagsDD && tagsDD.length > 0 &&
                                                    <DropdownMultiselect selected={SelectedTagsids} optionKey="value" handleOnChange={(selected) => setSelectedTagsids(selected)} selectDeselectLabel={ct("selectAll")} options={tagsDD} placeholder={ct("tags")} name='tagsDD' />
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="TabContentSectionTitle fw-bold pt-3">{ct("format")}</div>
                                    <div>
                                        <Form.Check type="radio" label="PDF" name="SelectFormatList" id="formExportFormatPDfList" defaultChecked={true} onChange={() => setFormat("PDF")} />
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="ResourceExport" title={ct("resource")}>
                                <div className="TabContentWrapper  py-3">
                                    <div className="TabContentSectionTitle fw-bold">{ct("period")}</div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="me-2">
                                            <Form.Check type="radio" label={ct("range")} name="SelectPeriodResource" id="formExportPeriodWeekResource" checked={periodtype === "Range" ? true : false} onChange={() => { setResourceRange(true); setperiodtype("Range") }} value="Range" />
                                        </div>
                                        <div>
                                            <Form.Check type="radio" label={ct("day")} name="SelectPeriodResource" id="formExportPeriodMonthResource" checked={periodtype === "Day" ? true : false} onChange={() => { setResourceRange(false); setperiodtype("Day") }} value="Day" />
                                        </div>
                                        <Container fluid>
                                            <Row>
                                                <Col md={6} >
                                                    <Form.Group controlId="ExportForm.txtWeekDateResource">
                                                        <DatePicker className="form-control" selected={StartDate} onChange={date => setStartDate(date)} PlaceholderText={ct("startTime")} />
                                                    </Form.Group>
                                                </Col>
                                                {
                                                    ResourceRange === true &&
                                                    <Col md={6} >
                                                        <Form.Group controlId="ExportForm.txtMonthDateResource">
                                                            <DatePicker className="form-control" selected={EndDate} onChange={date => setEndDate(date)} PlaceholderText={ct("endTime")} />
                                                        </Form.Group>
                                                    </Col>
                                                }
                                            </Row>
                                        </Container>
                                    </div>
                                    <div className="TabContentSectionTitle fw-bold pt-3 mb-2">{ct("data")}</div>
                                    <Row>
                                        <Col md={6} className="mb-2">
                                            {
                                                !!workspaceList && workspaceList.length > 0 &&
                                                <DropdownMultiselect selected={SelectedWorkSpaceids} optionKey="value" handleOnChange={(selected) => setSelectedWorkSpaceids(selected)} selectDeselectLabel={ct("selectAll")} options={workspaceList} placeholder={ct("workingAreas")} name='workspaceDD' />
                                            }
                                        </Col>
                                        <Col md={6} className="mb-2">
                                            {
                                                !!tagsDD && tagsDD.length > 0 &&
                                                <DropdownMultiselect selected={SelectedTagsids} optionKey="value" handleOnChange={(selected) => setSelectedTagsids(selected)} selectDeselectLabel={ct("selectAll")} options={tagsDD} placeholder={ct("tags")} name='tagsDD' />
                                            }
                                        </Col>
                                        <Col md={6} className="mb-2">
                                            <DropdownMultiselect selected={Datatype} handleOnChange={(selected) => setDatatype(selected)} selectDeselectLabel={ct("selectAll")} options={DataSectionArray} placeholder={ct("dataTypes")} name='Datatypes' />
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                                    <Col md={6}>
                                                        <MultiselectClassic listValues={workspaceList} defaultListText="Working area" DDName="ResourceWorkingArea" />
                                                    </Col>
                                                    <Col md={6}>
                                                        <MultiselectClassic listValues={tagsDD} defaultListText="Tags" DDName="ResourceTags" />
                                                    </Col>
                                                    <Col md={6}>
                                                        <MultiselectClassic listValues={DataSectionArray} defaultListText="Data section" DDName="ResourceDataSection" />
                                                    </Col>
                                                </Row> */}
                                    <div className="TabContentSectionTitle fw-bold pt-3 mb-2">{ct("format")}</div>
                                    <div>
                                        <Form.Check type="radio" label="PDF" name="SelectFormatPDFResource" id="formExportFormatPDfResource" checked={Format === "PDF" ? true : false} onChange={() => setFormat("PDF")} />
                                    </div>
                                    <div>
                                        <Form.Check type="radio" label="XLSX" name="SelectFormatPDFResource" id="formExportFormatXLSXResource" checked={Format === "XLSX" ? true : false} onChange={() => setFormat("XLSX")} />
                                    </div>
                                    <div>
                                        <Form.Check type="radio" label="CSV" name="SelectFormatPDFResource" id="formExportFormatCSVResource" checked={Format === "CSV" ? true : false} onChange={() => setFormat("CSV")} />
                                    </div>
                                    <div className="TabContentSectionTitle pt-3 fw-bold pt-3 mb-2">{ct("moreOptions")}</div>
                                    <div>
                                        <Form.Check label={cl("groupWorkingAreas")} name="SelectotherOption" id="Groupbyworkingareas" onChange={(e) => setGroupbyWorkingAreas(!GroupbyWorkingAreas)} />
                                    </div>
                                    {
                                        GroupbyWorkingAreas === true && Format === "PDF" ?
                                            <div style={{ "paddingLeft": "20px" }}>
                                                <Form.Check label={cl("pageBreakAfterEachWorkingArea")} name="SelectotherOption" id="PageBreakAfterEachWorkingArea" onChange={(e) => setPageBreakAfterEachWorkingArea(!PageBreakAfterEachWorkingArea)} />
                                            </div>
                                            : null
                                    }
                                    {
                                        Format === "PDF" ?
                                            <div>
                                                <Form.Check label={cl("placeShiftsEndDate")} name="SelectotherOption" id="Placeshiftsbyenddate" onChange={(e) => setPlaceShiftsbyEndDate(!PlaceShiftsbyEndDate)} />
                                            </div>
                                            : null
                                    }
                                    {
                                        Format === "PDF" ?
                                            <div>
                                                <Form.Check label={cl("showNetDurationsPerShift")} name="SelectotherOption" id="Shownetdurationspershift" onChange={(e) => setShowNetDurationsPerShift(!ShowNetDurationsPerShift)} />
                                            </div>
                                            : null
                                    }
                                    <div>
                                        <Form.Check label={cl("showTags")} name="SelectotherOption" id="ShowTags" onChange={(e) => setShowTags(!ShowTags)} />
                                    </div>
                                    {
                                        Format === "PDF" ?
                                            <div>
                                                <Form.Check label={cl("hideEmployees")} name="SelectotherOption" id="Hideemployeeswithoutassignmentorabsence" onChange={(e) => setHideEmployeesWithoutAssignmentOrAbsence(!HideEmployeesWithoutAssignmentOrAbsence)} />
                                            </div>
                                            : null
                                    }
                                    <div>
                                        <Form.Check label={cl("hideWorkingArea")} name="SelectotherOption" id="Hideworkingareatitlesincellsofevents" onChange={(e) => setHideWorkingAreaTitlesInCellsOfEvents(!HideWorkingAreaTitlesInCellsOfEvents)} />
                                    </div>
                                    <div>
                                        <Form.Check label={cl("hideTimes")} name="SelectotherOption" id="Hidestartendtimes" onChange={(e) => setHideStartEndTimes(!HideStartEndTimes)} />
                                    </div>
                                    {
                                        Format === "PDF" ?
                                            <div>
                                                <Form.Check label={cl("hideCalculations")} name="SelectotherOption" id="Hidecalculations" onChange={(e) => setHideCalculations(!HideCalculations)} />
                                            </div>
                                            : null
                                    }
                                    <div>
                                        <Form.Check label={cl("addPageBreakAfterEveryWeek")} name="SelectotherOption" id="Addpagebreakaftereveryweek" onChange={(e) => setAddPageBreakAfterEveryWeeks(!AddPageBreakAfterEveryWeeks)} />
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btnPrimaryGreen" onClick={() => submit()}>{cl("createExport")}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

