import { useEffect, useState } from "react";
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { BsSearch } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap';
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { CheckCircle, Edit, ExclaimationCircle, Gear, Remove, RemoveCircle, UndoBtn } from "../../Components/ActionBtns";
import { AxiosLite, CallAxios } from "../../Helpers";
import { Constants } from "../../Helpers/Contents";
import { StatusBox, TableWrapper } from "../../Helpers/Global/StyledComponents";
import { NoQuotaOrConsumedModal, PaperClipPopover, StickyNotePopover } from "../Absences/AbsencesGrid";
import { AddEditAbsenceModal } from "../Absences/AbsencesList";
import ServerSideDataGrid from "../../Components/ServerSideDataGrid";
import { useTranslation } from "react-i18next";


function AbsenceTab() {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');


    const { userid } = useParams();

    const [Breakdiv, setBreakdiv] = useState(false);
    const [absenceemployeeListForEmployee, setabsenceemployeeListForEmployee] = useState([]);

    const [show, setshow] = useState(false);
    const [absenceModalType, setAbsenceModalType] = useState("add");
    const [absenceIdForEdit, setAbsenceIdForEdit] = useState("");

    const [AbsenceTypes, setAbsenceTypes] = useState([]);
    const [AssignAbsenceTypes, setAssignAbsenceTypes] = useState([]);
    const [newAssignAbsenceTypes, setnewAssignAbsenceTypes] = useState([]);
    const themeColor = useSelector(state => state.app.theme.colors.buttons);
    const [PaginateInfo, setPaginateInfo] = useState(null);


    useEffect(() => {
        getInitailData()
        // getData()
    }, []);
    const getPaginateInfo = (prop) => {
        setPaginateInfo(prop)
    }

    const getData = async (prop) => {
        if (!prop && !!PaginateInfo) {
            prop = PaginateInfo
        }
        var data = {
            EmployeeId: userid,
            Skip: prop.skip,
            Take: prop.take
        }
        var response = await CallAxios("/api/Absence/EmployeeAbsenceList|post", data);
        if (!!response && response.data) {
            setabsenceemployeeListForEmployee(response.data.list)
            prop.updateCount(response.data.count)
        }
    }
    const getInitailData = async () => {
        var response = await CallAxios("/api/Absence/getemployeeAbsenceType|post", { id: userid });
        if (!!response && response.status === 200) {
            setAssignAbsenceTypes(response.data)
            setnewAssignAbsenceTypes(response.data.map(x => x.value))
        }

        var response1 = await AxiosLite(
            "/api/LocationSetting/getAbsenceTypeDD|get"
        );
        if (!!response1 && response1.status === 200) {
            setAbsenceTypes(response1.data)
        }
    }
    const assignAbsencetype = async () => {
        setBreakdiv(false)
        var data = {
            empid: parseInt(userid),
            ids: newAssignAbsenceTypes
        }
        await CallAxios("/api/Absence/AssignAbsence|post", data);
        var response = await CallAxios("/api/Absence/getemployeeAbsenceType|post", { id: userid });
        setAssignAbsenceTypes(response.data)
    }

    return <>
        <div className="DetailInfoWrapper">
            <div className='mb-3 d-flex justify-content-between align-items-center'>
                <h4 className='h5 fw-bold mb-0'>{t('absences')}</h4>
                <button className='btn btn-primary me-3' onClick={() => { setAbsenceModalType("add"); setAbsenceIdForEdit(""); setshow(true) }}> {t('absenceTab.newAbsences')}</button>
            </div>
            <div className="py-3 d-flex align-items-center">
                <h5 className='h6 fw-bold mb-0 me-2'> {t('absenceTab.absenceTypes')} </h5>
                {Breakdiv === false ?
                    <div className='d-flex'> <p className='mb-0 me-1'>
                        {!!AssignAbsenceTypes && AssignAbsenceTypes.length > 0 ? AssignAbsenceTypes.map((val, key) => {
                            return val.text + " - "
                        }) : null}
                    </p> <Edit color={themeColor} onClick={(e) => setBreakdiv(true)} />
                    </div>
                    :
                    <div className='d-flex align-items-center w-50'>
                        <div className="TblDDList Multiselect HideSelectAll">
                            {!!AbsenceTypes && AbsenceTypes.length > 0 ?

                                <DropdownMultiselect
                                    selected={!!AssignAbsenceTypes && AssignAbsenceTypes.length > 0 ? AssignAbsenceTypes.map((val) => val.value) : ""}
                                    handleOnChange={(selected) => setnewAssignAbsenceTypes(selected)}
                                    options={AbsenceTypes}
                                    placeholder="Break"
                                    buttonClass="btnDDList smWidth max250px" />
                                : null
                            }
                        </div>
                        <div>
                            <button className='btn btn-primary  mx-3' onClick={(e) => assignAbsencetype()}> {ct('ready')}</button>
                        </div>
                    </div>
                }
            </div>
            <AbsenceTable absenceemployeeListForEmployee={absenceemployeeListForEmployee} getPaginateInfo={getPaginateInfo} getData={getData} setAbsenceModalType={setAbsenceModalType} seteditabsensemodal={setshow} setAbsenceIdForEdit={setAbsenceIdForEdit} />
            {
                show === true ?
                    <AddEditAbsenceModal modalType={absenceModalType} show={show} onHide={setshow} GetAbsenceList={getData} absenceIdForEdit={absenceIdForEdit} setAbsenceIdForEdit={setAbsenceIdForEdit} />
                    : null
            }
        </div>
    </>
}

export default AbsenceTab;



function AbsenceTable(props) {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');


    const user = useSelector(x => x.app.user)
    const [absenceemployeeListForadminfilter, setabsenceemployeeListForadminfilter] = useState();
    const [showNoQuotaOrConsumeModal, setShowNoQuotaOrConsumeModal] = useState(false);
    const [tryToconfirmAbsenceDate, setTryToconfirmAbsenceDate] = useState(null);

    useEffect(() => {
        setabsenceemployeeListForadminfilter(props.absenceemployeeListForEmployee)
    }, [props.absenceemployeeListForEmployee]);

    const changestatus = async (id, status) => {
        var data = {
            id: id,
            status: status
        }
        var response = await CallAxios("/api/Absence/changeAbsenceStatusAdmin|post", data);
        if (!!response && response.status === 200) {
            if (response.data.toString() === "true") {
                props.getData()
            }
            else if (response.data === "No Quota Or Consumed") {
                setShowNoQuotaOrConsumeModal(true)
            }
            else if (response.data === "Ban") {
                toast.error(t('absenceTab.errorMsg.banError').toString())
            }
        }
    }
    const searchFilter = (e) => {

        let name = e.target.name;
        switch (name) {
            case "employee":
                let employee = props.absenceemployeeListForEmployee.filter(i => i.employeeName.toLowerCase().includes(e.target.value.toLowerCase()));
                setabsenceemployeeListForadminfilter(employee)
                break;
            case "art":
                let art = props.absenceemployeeListForEmployee.filter(i => i.absenceType.toLowerCase().includes(e.target.value.toLowerCase()));
                setabsenceemployeeListForadminfilter(art)
                break;
            case "beginning":
                let beginning = props.absenceemployeeListForEmployee.filter(i => new Date(i.startDate).toLocaleDateString().includes(e.target.value.toLowerCase()));
                setabsenceemployeeListForadminfilter(beginning)
                break;
            case "end":
                let end = props.absenceemployeeListForEmployee.filter(i => new Date(i.endDate).toLocaleDateString().includes(e.target.value.toLowerCase()));
                setabsenceemployeeListForadminfilter(end)
                break;
            case "hours":
                let hours = props.absenceemployeeListForEmployee.filter(i => i.startTime.hours.toString().includes(e.target.value.toLowerCase()) || i.startTime.minutes.toString().includes(e.target.value.toLowerCase()));
                setabsenceemployeeListForadminfilter(hours)
                break;
            case "vacation":
                let vacation = props.absenceemployeeListForEmployee.filter(i => i.endTime.hours.toString().includes(e.target.value.toLowerCase()) || i.endTime.minutes.toString().includes(e.target.value.toLowerCase()));
                setabsenceemployeeListForadminfilter(vacation)
                break;

            default:
                break;
        }
    }
    const EditAbsenceAdmin = (id, status) => {
        props.setAbsenceIdForEdit(id);
        props.setAbsenceModalType(status)
        props.seteditabsensemodal(true)
    }

    const columns = [
        { key: 'employeeName', name: t('absenceTab.tbl.employee').toString(), sortable: true, searchable: true, disableSearch: false },
        { key: 'absenceType', name: t('absenceTab.tbl.art').toString(), sortable: true, searchable: true, disableSearch: false },
        { key: 'startDate', name: t('absenceTab.tbl.beginning').toString(), sortable: true, searchable: true, disableSearch: true },
        { key: 'endDate', name: t('absenceTab.tbl.end').toString(), sortable: true, searchable: true, disableSearch: true },
        { key: 'correctedHours', name: t('absenceTab.tbl.hours').toString(), sortable: true, searchable: true, disableSearch: false },
        { key: 'holiday', name: t('absenceTab.tbl.vacation').toString(), sortable: true, searchable: true },
        { key: 'status', name: t('absenceTab.tbl.status').toString(), sortable: true },
        { key: 'action', name: t('absenceTab.tbl.action').toString() },

    ];

    return (
        <>
            {
                showNoQuotaOrConsumeModal === true ?
                    <NoQuotaOrConsumedModal show={showNoQuotaOrConsumeModal} tryToconfirmAbsenceDate={tryToconfirmAbsenceDate} setShow={setShowNoQuotaOrConsumeModal} />
                    : null
            }
            <TableWrapper>
                <ServerSideDataGrid
                    columns={columns}
                    rows={absenceemployeeListForadminfilter}
                    itemsPerPage={10}
                    getPaginateInfo={(p) => props.getPaginateInfo(p)}
                    customBody={(rows) =>
                        rows?.map((value, i) =>
                            <tr>
                                <td>
                                    <div className="dataTextCell">
                                        {value.employeeName}
                                    </div>
                                </td>
                                <td>
                                    <div className="dataTextCell">
                                        {value.absenceType}
                                    </div>
                                </td>
                                <td>
                                    <div className="dataTextCell">
                                        {new Date(value.startDate).toLocaleDateString()}
                                    </div>
                                </td>
                                <td>
                                    <div className="dataTextCell">
                                        {new Date(value.endDate).toLocaleDateString()}
                                    </div>
                                </td>
                                <td>
                                    <div className="dataTextCell">
                                        {value.correctedHours}
                                    </div>
                                </td>
                                <td>
                                    <div className="dataTextCell">
                                        {value.holiday}
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex">
                                        <span data-tooltip-id="my-tooltip" data-tooltip-content={t('absenceTab.createdAt') + `: ${new Date(value.createdOn).toLocaleDateString()} ${value.status === "confirmed" ? t('absenceTab.confirmedAt') : value.status === "pending" ? t('absenceTab.vacationAvailable') : t('absenceTab.rejectedAt')}:${value.status === "confirmed" || value.status === "rejected" ? new Date(value.updateStatus).toLocaleDateString() : (!!value.holiday ? value.holiday : '0.0') + '/' + (!!value.vocationQuota ? value.vocationQuota.totalVocation : '0.0')} `} className='d-flex'><StatusBox status={value.status} className="me-2">{value.status === "confirmed" ? value.responseByEmployee : t(`absenceTab.${value.status}`)}  </StatusBox> <ExclaimationCircle color='#a4a4a4' className="ml-1" /></span>
                                        {/* 
                                        <StatusBox data-tooltip-id="my-tooltip" data-tooltip-content={`Created at: ${new Date(value.createdOn).toLocaleDateString()} ${value.status === "confirmed" ? "Confirmed at" : value.status === "pending" ? "Vacation available" : "Rejected at"}:${value.status === "confirmed" || value.status === "rejected" ? new Date(value.updateStatus).toLocaleDateString() : (!!value.holiday ? value.holiday : '0.0') + '/' + (!!value.vocationQuota ? value.vocationQuota.totalVocation : '0.0')} `} status={value.status} className="me-2 flex-grow-1">{value.status}  </StatusBox>
                                        <ExclaimationCircle color='#a4a4a4' className="ms-1" /> */}
                                    </div>
                                    {
                                        !!value.note ?
                                            <StickyNotePopover data={value.note} />
                                            :
                                            null
                                    }
                                    {
                                        !!value.attachment ?
                                            <PaperClipPopover data={value.attachment} />
                                            :
                                            null
                                    }
                                </td>
                                <td>
                                    {
                                        value.status === "pending" ?
                                            <>
                                                {user.roles.length === 1 && user.roles.some(x => x === Constants.roles.Employee) ?
                                                    null :
                                                    <>
                                                        <CheckCircle data-tooltip-id="my-tooltip" data-tooltip-content={t('absenceTab.checkTooltip')} className="btnTblAction bgGreen" onClick={(e) => { setTryToconfirmAbsenceDate(value.startDate); changestatus(value.absenceID, "S") }} />
                                                        {/* <Remove data-tooltip-id="my-tooltip" data-tooltip-content={ct('cancel')} className="btnTblAction bgRed" onClick={(e) => changestatus(value.absenceID, "R")}></Remove> */}
                                                        <RemoveCircle data-tooltip-id="my-tooltip" data-tooltip-content={ct('cancel')} onClick={(e) => changestatus(value.absenceID, "R")} color='#f25656' />

                                                    </>
                                                }
                                            </>
                                            :
                                            (user.roles.length === 1 && user.roles.some(x => x === Constants.roles.Employee) && value.isEmployeesResetAbsences === true) || user.roles.some(x => x === Constants.roles.AccountAdmin || x === Constants.roles.WorkspaceAdmin || x === Constants.roles.Admin) ?
                                                <UndoBtn data-tooltip-id="my-tooltip" data-tooltip-content={t('absenceTab.undoTooltip')} fs={"18px"} className="btnTblAction" onClick={(e) => changestatus(value.absenceID, "P")} />
                                                : null
                                    }
                                    <Gear data-tooltip-id="my-tooltip" data-tooltip-content={ct('toEdit')} className="btnTblAction bgBlue" onClick={() => EditAbsenceAdmin(value.absenceID, value.status)} />
                                </td>
                            </tr>
                        )}
                    loadData={(p) => props.getData(p)}
                />


            </TableWrapper>
        </>
    )
}

// <Table responsive striped bordered className="DataGridTable">
//     <thead className={props.isTop ? '' : 'FixedHeader'}>
//         <tr className="firsthead">
//             <th><BsSearch className="btnHeaderSearchIcon me-2" />Employee</th>
//             <th><BsSearch className="btnHeaderSearchIcon me-2" />Art</th>
//             <th>Beginning</th>
//             <th>End</th>
//             <th>Hours</th>
//             <th>Vacation</th>
//             <th>Status</th>
//             <th>Actions</th>
//         </tr>
//         <tr>
//             <th><Form.Control type="text" name="employee" onChange={searchFilter} /></th>
//             <th><Form.Control type="text" name="art" onChange={searchFilter} /></th>
//             <th><Form.Control type="text" name="beginning" onChange={searchFilter} /></th>
//             <th><Form.Control type="text" name="end" onChange={searchFilter} /></th>
//             <th><Form.Control type="text" name="hours" onChange={searchFilter} /></th>
//             <th><Form.Control type="text" name="vacation" onChange={searchFilter} /></th>
//             <th><Form.Control type="text" disabled /></th>
//             <th><Form.Control type="text" disabled /></th>
//         </tr>
//     </thead>
//     <tbody>

//         {
//             !!absenceemployeeListForadminfilter && absenceemployeeListForadminfilter.length > 0 ?
//                 absenceemployeeListForadminfilter.map((value, key) => {
//                     return <tr>
//                         <td>
//                             <div className="dataTextCell">
//                                 {value.employeeName}
//                             </div>
//                         </td>
//                         <td>
//                             <div className="dataTextCell">
//                                 {value.absenceType}
//                             </div>
//                         </td>
//                         <td>
//                             <div className="dataTextCell">
//                                 {new Date(value.startDate).toLocaleDateString()}
//                             </div>
//                         </td>
//                         <td>
//                             <div className="dataTextCell">
//                                 {new Date(value.endDate).toLocaleDateString()}
//                             </div>
//                         </td>
//                         <td>
//                             <div className="dataTextCell">
//                                 {value.correctedHours}
//                             </div>
//                         </td>
//                         <td>
//                             <div className="dataTextCell">
//                                 {value.holiday}
//                             </div>
//                         </td>
//                         <td>
//                             <div className="d-flex">
//                                 <StatusBox data-tooltip-id="my-tooltip" data-tooltip-content={`Created at: ${new Date(value.createdOn).toLocaleDateString()} ${value.status === "confirmed" ? "Confirmed at" : value.status === "pending" ? "Vacation available" : "Rejected at"}:${value.status === "confirmed" || value.status === "rejected" ? new Date(value.updateStatus).toLocaleDateString() : (!!value.holiday ? value.holiday : '0.0') + '/' + (!!value.vocationQuota ? value.vocationQuota.totalVocation : '0.0')} `} status={value.status} className="me-2 flex-grow-1">{value.status}  </StatusBox>
//                                 <ExclaimationCircle color='#a4a4a4' className="ms-1" />
//                             </div>
//                             {
//                                 !!value.note ?
//                                     <StickyNotePopover data={value.note} />
//                                     :
//                                     null
//                             }
//                             {
//                                 !!value.attachment ?
//                                     <PaperClipPopover data={value.attachment} />
//                                     :
//                                     null
//                             }
//                         </td>
//                         <td>
//                             {
//                                 value.status === "pending" ?
//                                     <>
//                                         {user.roles.length === 1 && user.roles.some(x => x === Constants.roles.Employee) ?
//                                             null :
//                                             <>
//                                                 <CheckCircle data-tooltip-id="my-tooltip" data-tooltip-content="Note: Confirmation, deletes all assignments and applications of the employee during the period of absence!" className="btnTblAction bgGreen" onClick={(e) => { setTryToconfirmAbsenceDate(value.startDate); changestatus(value.absenceID, "S") }} />
//                                                 <Remove data-tooltip-id="my-tooltip" data-tooltip-content="Cancel" className="btnTblAction bgRed" onClick={(e) => changestatus(value.absenceID, "R")}></Remove>
//                                             </>
//                                         }
//                                     </>
//                                     :
//                                     (user.roles.length === 1 && user.roles.some(x => x === Constants.roles.Employee) && value.isEmployeesResetAbsences === true) || user.roles.some(x => x === Constants.roles.AccountAdmin || x === Constants.roles.WorkspaceAdmin || x === Constants.roles.Admin) ?
//                                         <UndoBtn data-tooltip-id="my-tooltip" data-tooltip-content="Reset to default" fs={"18px"} className="btnTblAction" onClick={(e) => changestatus(value.absenceID, "P")} />
//                                         : null
//                             }
//                             <Gear data-tooltip-id="my-tooltip" data-tooltip-content="To edit" className="btnTblAction bgBlue" onClick={() => EditAbsenceAdmin(value.absenceID, value.status)} />
//                         </td>
//                     </tr>
//                 }) : null
//         }
//     </tbody>
// </Table>