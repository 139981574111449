import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import moment from "moment";
import { useEffect, useState } from "react";
import { Badge, Button, Col, ListGroup, Modal, Row, Table, Tooltip } from "react-bootstrap";
import { BsCaretLeftFill, BsCaretRightFill, BsInfoCircle, BsSearch } from "react-icons/bs";
import { useSelector } from "react-redux";
import { AddEditAbsenceModal } from "../Absences/AbsencesList";
import { CallAxios } from '../../Helpers';
import { Constants } from '../../Helpers/Contents';
import styled from 'styled-components';
import { TableWrapper } from '../../Helpers/Global/StyledComponents';
import { useTranslation } from "react-i18next";

const HeaderAnchor = styled.a`
color:#fff;
cursor:pointer !important;
`

function AbsenceCalendar(props) {
    const { t: cl } = useTranslation('calendarPage');
    const { t: ct } = useTranslation('common');

    const currentView = useSelector(x => x.app.currentView)
    const user = useSelector(x => x.app.user)
    const appSettings = useSelector(x => x.app.appSettings)
    const [filterEmployeeList, setFilterEmployeeList] = useState([]);
    const [_SearchEmployee, set_SearchEmployee] = useState(false);
    const [showWeekNum, setShowWeekNum] = useState(false);
    const [EmployeeToBeSelected, setEmployeeToBeSelected] = useState("");
    const [DateTobESelected, setDateTobESelected] = useState(null);
    const [ShowAbsenceDetailModal, setShowAbsenceDetailModal] = useState(false);
    const [AddAbsenceModal, setAddAbsenceModal] = useState(false);
    const [AnsenceInfoObjforEdit, setAnsenceInfoObjforEdit] = useState(null);
    const [absenceModalType, setAbsenceModalType] = useState("add");
    const [AbsenceIdForEdit, setAbsenceIdForEdit] = useState("");

    const [showHeaderAbsenseModal, setShowHeaderAbsenseModal] = useState(false);
    useEffect(() => {
        if (!!props.AbsenceEmployeeListWithBadge) {
            setFilterEmployeeList(props.AbsenceEmployeeListWithBadge)
        }
    }, [props.AbsenceEmployeeListWithBadge]);
    useEffect(() => {
        var els = document.querySelectorAll('.EmployeeResourceLane');

        for (var i = 0, len = els.length; i < len; i++) {
            els[i].addEventListener('mouseover', function (e) {
                let id = e.currentTarget.getAttribute("data-resource-id")
                let element = document.querySelectorAll('.EmployeeResourceLane[data-resource-id="' + id + '"]');
                for (let i = 0; i < element.length; i++) {
                    element[i].classList.add("highlight");
                }
            });
            els[i].addEventListener('mouseout', function (e) {
                let id = e.currentTarget.getAttribute("data-resource-id")
                let element = document.querySelectorAll('.EmployeeResourceLane[data-resource-id="' + id + '"]');
                for (let i = 0; i < element.length; i++) {
                    element[i].classList.remove("highlight");
                }
            });
        }
        let calendar = props.calendarRef.current;
        if (!!calendar) {
            props.setViewTitle(calendar.getApi().view.getCurrentData().viewTitle)
        }
    }, []);
    const fnFilterEmployeeList = (e) => {
        let value = e.target.value;
        let list = props.AbsenceEmployeeListWithBadge.filter(x => x.employee.toLowerCase().includes(value));
        setFilterEmployeeList(list)
    }
    const handleAddAbsenceModalfromDetailModel = (info) => {
        if (!!info) {
            setEmployeeToBeSelected(info._def.resourceIds[0])
            setDateTobESelected(info._def.extendedProps.startDate)
            setShowAbsenceDetailModal(false)
            setAddAbsenceModal(true)
        }
    }
    return (
        <>
            <FullCalendar
                ref={node => {
                    props.calendarRef.current = node
                }}
                headerToolbar={{
                    left: '',
                    center: '',
                    right: ''
                }}
                firstDay='1'
                allDaySlot={false}
                plugins={[resourceTimelinePlugin, interactionPlugin]}
                initialView="resourceTimelineThreeMonth"
                aspectRatio={1.5}
                displayEventTime={false}
                resourceAreaWidth={_SearchEmployee == true ? "400px" : "250px"}
                resourceLaneClassNames="EmployeeResourceLane"
                resourceLabelClassNames="EmployeeResourceLane"
                slotWidth='10%'
                views={{
                    resourceTimelineThreeMonth: {
                        type: 'resourceTimelineMonth',
                        duration: { month: props.month },
                        slotDuration: { days: 1 }, //extra in emplyee
                    }
                }}
                resourceAreaColumns={[
                    {
                        field: 'employee',
                        headerClassNames: "testHeader",
                        headerContent:
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="TitleText d-flex align-items-center fw-bold">{ct("employee")}
                                    {
                                        _SearchEmployee === true ?
                                            <input type="text" className='customEmployeeFilter form-control form-control-sm ms-2' onInput={(e) => fnFilterEmployeeList(e)} placeholder={cl("filterEmployee")} />
                                            : null
                                    }
                                    <BsSearch className="ListHeadingActionIcon ms-2" onClick={() => set_SearchEmployee(!_SearchEmployee)} />
                                </div>
                                <Button variant="primary" size="sm" onClick={() => { props.SetabsenceShowWeekly(); setShowWeekNum(!showWeekNum) }} className="btnActionChage float-right">
                                    <BsCaretLeftFill />
                                    {/* <span>{`Week ${parseInt(moment(props.calendarRef?.current?.getApi()?.getDate()).format('W'))}`}</span> */}
                                    <span>{showWeekNum === true ? <span>{moment(props.calendarRef?.current?.getApi()?.getDate()).format("MMMM")}</span> : <span className="testcalss">{ct("week")} {moment(props.calendarRef?.current?.getApi()?.getDate()).week()}</span>}</span>
                                    <BsCaretRightFill />
                                </Button>
                            </div>
                    }


                ]}
                // resources ={this.props.absenceemployeelist}
                resources={filterEmployeeList}
                // resourceLabelDidMount={(info) => {
                // }}
                resourceLabelContent={(arg) => {
                    if (appSettings.locationsettings.Hide_employee_hours_in_calendar === false) {
                        if (!!arg.resource.extendedProps.weeklyassignHours && !!arg.resource.extendedProps.weeklytargetHours) {
                            var html = `<div class="d-flex align-items-center justify-content-between">
                                <div>${arg.resource.extendedProps.employee}</div>
                                <Button variant="primary"  class="btnActionChage btn btn-sm btn-primary float-right"> ${arg.resource.extendedProps.weeklyassignHours + "/" + arg.resource.extendedProps.weeklytargetHours} </Button>
                            </div>`
                            return { html: html }
                        } else {
                            var html = `<div class="d-flex align-items-center justify-content-between">
                            <div>${arg.resource.extendedProps.employee}</div>
                        </div>`
                            return { html: html }
                        }
                    } else {
                        var html = `<div class="d-flex align-items-center justify-content-between">
                        <div>${arg.resource.extendedProps.employee}</div>
                    </div>`
                        return { html: html }
                    }
                }}
                events={props.calendarAbsenceDetail}
                selectable={true}
                navLinkDayClick={currentView === Constants.layoutType.Employee ? true : undefined}
                dateClick={(info) => {
                    if (info.resource._resource.id !== "0") {
                        if (!!info) {
                            setEmployeeToBeSelected(info.resource._resource.id)
                            setDateTobESelected(new Date(info.dateStr))
                            setShowAbsenceDetailModal(false)
                            setAbsenceModalType("add")
                            setAddAbsenceModal(true)
                        }
                    }
                }}
                slotLabelFormat={
                    currentView === Constants.layoutType.Employee ? [
                        { month: 'long', }, // top level of text
                        { weekday: 'short', day: "2-digit" } // lower level of text
                    ] : undefined
                }
                slotLabelContent={
                    currentView === Constants.layoutType.Employee ?
                        (args) => {
                            if (args.level > 0) {
                                let d = new Date().setHours(0, 0, 0, 0)
                                return <HeaderAnchor className={moment(args.date).format("DD/MM/YYYY") === moment(new Date(d)).format("DD/MM/YYYY") ? "text-primary" : ""} onClick={() => { setDateTobESelected(args.date); setShowHeaderAbsenseModal(true) }}>{args.text} </HeaderAnchor>
                            } else {
                                return <span>{args.text} </span>
                            }
                        }
                        : undefined
                }
                eventClick={(date, jsEvent, view) => {
                    if (date.event._def.extendedProps.absenceID !== 0) {
                        if (currentView === Constants.layoutType.Admin) {
                            setAnsenceInfoObjforEdit(date.event)
                            setShowAbsenceDetailModal(true)
                        }
                        else {
                            if (date.event._def.resourceIds[0] === user.EncLoginUserId || appSettings.absenceSetting.status_and_type_of_absence_not_visible_to_other_employees === false) {
                                setAnsenceInfoObjforEdit(date.event)
                                setShowAbsenceDetailModal(true)
                            }
                        }
                    }


                }}

                eventDidMount={(info) => {
                    if (info.event._def.extendedProps.absenceID === 0) {
                        info.el.setAttribute("data-tooltip-id", "my-tooltip")
                        // info.el.setAttribute("data-tooltip-variant", "light")
                        info.el.setAttribute("data-tooltip-html", '<table class="table table-dark p-0 m-0 table-responses"><thead><tr><td>Title</td><td>Limit</td><td>Working Areas</td><td>Absence Types</td></tr></thead><tbody><tr><td>' + info.event._def.title + '</td><td>' + info.event._def.extendedProps.limit + '</td><td>' + info.event._def.extendedProps.workArea + '</td><td>' + info.event._def.extendedProps.absenceType + '</td></tr></tbody></table>')
                    }
                }}
            />
            {
                AddAbsenceModal === true ?
                    <AddEditAbsenceModal
                        modalType={absenceModalType} show={AddAbsenceModal} onHide={setAddAbsenceModal} GetAbsenceList={props.GetAbsenceEmployeeList}
                        absenceIdForEdit={AbsenceIdForEdit} setAbsenceIdForEdit={setAbsenceIdForEdit} SelectedEmployeeID={EmployeeToBeSelected} DateTobESelected={DateTobESelected} />
                    : null
            }
            {
                ShowAbsenceDetailModal === true ?
                    <AbsenceDetailModal show={ShowAbsenceDetailModal} onHide={setShowAbsenceDetailModal} setShowAbsenceModal={setAddAbsenceModal} setAbsenceModalType={setAbsenceModalType}
                        setAbsenceIdForEdit={setAbsenceIdForEdit} AnsenceInfoObjforEdit={AnsenceInfoObjforEdit} handleAddAbsenceModalfromDetailModel={handleAddAbsenceModalfromDetailModel} />
                    : null
            }
            {
                showHeaderAbsenseModal === true ?
                    <ShowHeaderAbsenseModal show={showHeaderAbsenseModal} HeaderClickdate={DateTobESelected} onhide={setShowHeaderAbsenseModal} absenceList={props.calendarAbsenceDetail} filterEmployeeList={filterEmployeeList} />
                    : null
            }
        </>
    );
}

export default AbsenceCalendar;

function AbsenceDetailModal(props) {
    const { t: cl } = useTranslation('calendarPage');

    const [AbsenceList, setAbsenceList] = useState([]);
    // const dispatch = useDispatch();
    useEffect(() => {
        if (!!props.AnsenceInfoObjforEdit) {
            onLaod()
        }
    }, [props.AnsenceInfoObjforEdit]);
    const onLaod = async () => {
        var data = {
            emplyeeID: props.AnsenceInfoObjforEdit._def.resourceIds[0],
            date: moment(props.AnsenceInfoObjforEdit._def.extendedProps.startDate).startOf("day"),
        }

        var response = await CallAxios("/api/Calendar/getEmployeeAbsence|post", data)
        if (!!response) {
            setAbsenceList(response.data)
        }
    }
    const handleClickRow = async (val) => {
        // await setabsenceemployeeListForadmin()
        props.setAbsenceModalType(val.status)
        props.setAbsenceIdForEdit(val.absenceId)
        props.setShowAbsenceModal(true)
        props.onHide(false)
        // if (val.status === 'confirmed' || val.status === 'rejected') {
        //     props.handleModalShowHide("AbsenceInfoModal")
        // }
        // else if (val.status === 'pending') {
        //     props.handleModalShowHide("PendingAbsenceInfoModal")
        // }
        // props.handleModalShowHide("AbsenceDetailModal")
    }

    return (
        <Modal show={props.show} onHide={() => props.onHide(false)}>
            <Modal.Header closeButton >
                <Modal.Title> {cl("absenceMdl.title")} {!!AbsenceList && AbsenceList.length > 0 ? AbsenceList[0].empName : null}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={12} >
                        <div>
                            <TableWrapper >
                                <Table responsive striped bordered>
                                    <thead>
                                        <tr className='firsthead'>
                                            <th> {cl("absenceMdl.from")} </th>
                                            <th> {cl("absenceMdl.to")}</th>
                                            <th> {cl("absenceMdl.description")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !!AbsenceList && AbsenceList.length > 0 ?
                                                AbsenceList.map((val, key) => {
                                                    return <tr onClick={(e) => handleClickRow(val)}>
                                                        <td>{val.toFrom.split(" - ")[0]}</td>
                                                        <td>{val.toFrom.split(" - ")[1]}</td>
                                                        <td>{val.type}</td>
                                                    </tr>
                                                })
                                                : null
                                        }
                                    </tbody>
                                </Table>
                            </TableWrapper>
                            {/* <ListGroup variant="flush" className="DataListGroup HalfColumn">
                                <ListGroup.Item className="BorderBottomThick">
                                    <div className="ListValueFlex">
                                        <div className="TitleText fw-bold">From ... to </div>
                                        <div className="ValueText">
                                            Description
                                        </div>
                                    </div>
                                </ListGroup.Item>
                                {
                                    !!AbsenceList && AbsenceList.length > 0 ?
                                        AbsenceList.map((val, key) => {
                                            return <ListGroup.Item>
                                                <div className="ListValueFlex" onClick={(e) => handleClickRow(val)}>
                                                    <div className="TitleText">{val.toFrom}</div>
                                                    <div className="ValueText">{val.type}</div>
                                                </div>
                                            </ListGroup.Item>
                                        })
                                        : null
                                }
                            </ListGroup> */}
                        </div>
                    </Col>
                    <Col md={12} className="text-center py-3">
                        <Button className="btn btn-sm btnPrimaryGreen" onClick={() => props.handleAddAbsenceModalfromDetailModel(props.AnsenceInfoObjforEdit)}> {cl("absenceMdl.newAbsence")}</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}
function ShowHeaderAbsenseModal(props) {
    const { t: cl } = useTranslation('calendarPage');
    const { t: ct } = useTranslation('common');

    const [AbsenceList, setAbsenceList] = useState([]);
    useEffect(() => {
        setAbsenceList(props.absenceList.filter(x => moment(props.HeaderClickdate).isSame(x.start, 'day')))
    }, []);
    return (
        <Modal show={props.show} onHide={() => props.onhide(false)}>
            <Modal.Header closeButton>
                <Modal.Title> {cl("absenceMdl.absencesAt")} {moment(props.HeaderClickdate).format("DD/MM/YYYY")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table class="table table-condensed table-hover">
                    <thead>
                        <tr>
                            <td className='border-top-0'> {cl("absenceMdl.username")} </td>
                            <td className='border-top-0'> {cl("absenceMdl.absenceType")} </td>
                            <td className='border-top-0'>Status</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            AbsenceList.length > 0 ?
                                AbsenceList.map((v, i) => {
                                    return <tr>
                                        <td>{props.filterEmployeeList.find(x => x.id === v.resourceId).employee}</td>
                                        <td>{v.title}</td>
                                        <td>
                                            <Badge className={"badgewarning"}>{v.status} <BsInfoCircle className="ml-1" /></Badge>
                                        </td>
                                    </tr>

                                })
                                : null
                        }
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => props.onhide(false)}>
                    {ct("close")}
                </Button>

            </Modal.Footer>
        </Modal>
    );
}