import React from 'react';
import Display from './Display';
import AbsenseTypes from './AbsenseTypes';
import PublicHolidays from './PublicHolidays';
import HolidayBan from './HolidayBan';
import { useState } from 'react';
import { useEffect } from 'react';
import { AxiosLite } from '../../../Helpers';


function AbsenceTab() {
    const [AbsenceTypeList, setAbsenceTypeList] = useState([]);
    const [displayData, setDisplayData] = useState(null);
    useEffect(() => {
        getAbsenceTabData();
    }, []);
    const getAbsenceTabData = async () => {
        var response = await AxiosLite("/api/LocationSetting/AbsenceTabData|get");
        if (!!response && response.data) {
            setAbsenceTypeList(response.data.absenceTypeList);
            setDisplayData(response.data)
        }
    };

    return (
        <>
            <Display displayData={displayData} />
            <AbsenseTypes AbsenceTypeList={AbsenceTypeList} setAbsenceTypeList={setAbsenceTypeList} />
            <PublicHolidays />
            <HolidayBan />
        </>
    );
}

export default AbsenceTab;