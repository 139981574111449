import { HubConnectionBuilder } from '@microsoft/signalr';
import Picker from "emoji-picker-react";
import React, { useEffect, useRef, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { FaComments, FaTimes } from "react-icons/fa";
import { FiSmile } from 'react-icons/fi';
import { MdSend } from 'react-icons/md';
import { FallingLines } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled, { createGlobalStyle } from 'styled-components';
import { CallAxios, refreshAccessToken } from '../Helpers';
import { config } from '../Helpers/Config';
import { CircleBtnWithIcon } from '../Helpers/Global/StyledComponents';
import { AttachmentBtn } from './ActionBtns';
import { useNavigate } from 'react-router-dom';


function ChatBox() {
    const [show, setShow] = useState(false);

    return (
        <>
            <Style />
            <OverlayTrigger show={show} onToggle={(isShow) => setShow(isShow)} trigger="click" placement="top" overlay={
                <Popover id="popover-basic" className='asd'>
                    <Popover.Body className='p-0 asd'>
                        <div className=" d-flex justify-content-center">
                            {
                                show &&
                                <ChatBody setShow={setShow} />
                            }
                        </div>
                    </Popover.Body>
                </Popover>
            }>
                <FloatingButton className='shadow'>
                    <FaComments />
                </FloatingButton>
            </OverlayTrigger>
        </>
    );
}

export default ChatBox;

function ChatBody(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(x => x.app.user);
    const [ActiveChatMessagesList, setActiveChatMessagesList] = useState([]);
    const [Message, setMessage] = useState("");
    const [SupportChatId, setSupportChatId] = useState(0);
    const [connection, setConnection] = useState(null);
    const [SupportChatUserId, setSupportChatUserId] = useState(null);
    const submitBtnRef = useRef();
    const [hideShowEmojipicker, sethideShowEmojipicker] = useState(false);
    const PickerDropDown = { right: "15px !important", top: "45px !important" };


    const [newreceivedmessage, setNewreceivedmessage] = useState(null);
    const [SupportChatIdTrigger, setSupportChatIdTrigger] = useState(null);
    const [SUpportUserIdTrigger, setSUpportUserIdTrigger] = useState(null);
    const [CloseChatTrigger, setCloseChatTrigger] = useState(null);
    const [IsReady, setIsReady] = useState(false);
    useEffect(() => {
        UpdateAccessToken()
        onLoad()
    }, []);
    const UpdateAccessToken = async () => {
        await refreshAccessToken(dispatch, navigate)
    }
    // useEffect(() => {
    //     if (!!SupportChatId) {
    //         if (connection.state === "Connected") {
    //             connection.invoke('ActiveChat', SupportChatId)
    //         } else {
    //             onLoad()
    //             connection.invoke('ActiveChat', SupportChatId)
    //         }
    //     }
    // }, [SupportChatId]);
    const onLoad = async () => {
        const connection = new HubConnectionBuilder()
            .withUrl(config.API_URL + '/supportChatHub', { accessTokenFactory: () => localStorage.getItem('token') })
            .withAutomaticReconnect()
            .build();
        await connection.start()
            .then(result => {
                connection.on("receive_messgae", function (data) {
                    setSupportChatIdTrigger(data.supportChatId)
                    setSUpportUserIdTrigger(data.supportChatUserId)
                    setNewreceivedmessage(data.message)
                });
                connection.on("close_chat_trigger", function (data) {
                    setCloseChatTrigger(data)
                });
                connection.on("get_chat_list", function (data) {
                    setSupportChatIdTrigger(data.supportChatId || 0)
                    setSUpportUserIdTrigger(data.supportChatUserId || null)
                    setActiveChatMessagesList(data.messages || [])
                    setIsReady(true)
                });
                connection.invoke('GetChat', { id: user.EncLoginUserId })
            })
            .catch(async e => {
                if (e.message?.includes("401")) {
                    await refreshAccessToken(dispatch, navigate)
                    // await connection.start();
                }
            });
        connection.onclose(async (error) => {
            // await connection.start();
        });
        connection.onreconnecting(async (error) => {
            // await connection.start();
        });

        setConnection(connection)
    }

    useEffect(() => {
        if (!!newreceivedmessage) {
            var chatList = ActiveChatMessagesList;
            chatList = [...chatList, newreceivedmessage]
            setActiveChatMessagesList(chatList)
            setSupportChatId(SupportChatIdTrigger)
            setSupportChatUserId(SUpportUserIdTrigger)
        }
    }, [newreceivedmessage]);
    useEffect(() => {
        setSupportChatId(SupportChatIdTrigger)
    }, [SupportChatIdTrigger]);
    useEffect(() => {
        setSupportChatUserId(SUpportUserIdTrigger)
    }, [SUpportUserIdTrigger]);

    useEffect(() => {
        if (SupportChatId === CloseChatTrigger) {
            setActiveChatMessagesList([])
            setSupportChatIdTrigger(0)
            setSUpportUserIdTrigger(null)
        }
    }, [CloseChatTrigger]);
    const SendMessage = async (e) => {
        e.preventDefault();
        if (!!Message) {
            var data = {
                SupportChatId: SupportChatId,
                SenderId: parseInt(user.LoginUserId),
                Message: Message,
                SupportChatUserId: SupportChatUserId,
                IsFromSupportTeam: false,
                IsAttachment: false,
            }
            if (connection.state === "Connected") {
                connection.invoke('SendMessage', data)
                setMessage("");
            } else {
                await onLoad()
                connection.invoke('SendMessage', data)
                setMessage("");
            }
        }
    }
    useEffect(() => {
        if (!!ActiveChatMessagesList && ActiveChatMessagesList.length > 0) {
            scrollMsg()
        }
    }, [ActiveChatMessagesList]);

    const EndChat = async () => {
        if (connection.state === "Connected") {
            connection.invoke('CloseChat', { id: SupportChatId.toString() })
        }
        else {
            await onLoad()
            connection.invoke('CloseChat', { id: SupportChatId.toString() })
        }
    }
    const scrollMsg = () => {
        const chatItems = document.querySelector('.msg-box').children;
        if (chatItems.length > 0) {
            chatItems[chatItems.length - 1].scrollIntoView(true);
        }
    }
    const EmojiHandleClick = (n, e) => {
        setMessage(Message + n.emoji)
    };
    const UploadFile = async (files) => {
        if (files.length > 0) {
            if (!files.some(x => x.type !== "image/jpeg" && x.type !== "image/png" && x.type !== "application/pdf" && x.type !== "application/vnd.ms-excel" &&
                x.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" && x.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                && x.type !== "text/csv") && !files.some(x => x.file.size / (1024 ** 2) > 2)) {
                var data = {
                    Attachments: files.map(x => x.file)
                }
                var form_data = new FormData();
                Object.keys(data).forEach((key, index) => {
                    if (typeof (data[key]) === "object" && !!data[key] && data[key].length > 0) {
                        for (var i = 0; i < data[key].length; i++) {
                            form_data.append(key + [], data[key][i]);
                        }
                    } else if (data[key]) {
                        form_data.append(key, data[key]);
                    }
                })

                var res = await CallAxios("api/Documents/UploadFiles|post", form_data)
                if (!!res && res.data.length > 0) {
                    var obj = {
                        SupportChatId: SupportChatId,
                        SenderId: parseInt(user.LoginUserId),
                        SupportChatUserId: SupportChatUserId,
                        IsFromSupportTeam: false,
                        IsAttachment: true,
                    }
                    res.data.map(async (val, key) => {
                        obj.Message = val.name;
                        obj.AttachmentType = val.type;
                        if (connection.state === "Connected") {
                            connection.invoke('SendMessage', obj)
                        } else {
                            await onLoad()
                            connection.invoke('SendMessage', obj)
                        }
                    })
                }
            } else {
                toast.error("files must be png, jpeg, Pdf, excel, or csv and max size is 2 MB.")
            }
        }
    }
    return (
        <>
            <div className="cardss">
                <div className="d-flex flex-row justify-content-between p-3 adiv text-white">
                    {/* <FaChevronLeft /> */}
                    <span className="pb-3">Live chat</span>
                    <FaTimes className='iconPointer' onClick={() => props.setShow(false)} />
                </div>
                {
                    IsReady ?
                        !!ActiveChatMessagesList && ActiveChatMessagesList.length > 0 ?
                            <>
                                <div className='chatHeight msg-box'>
                                    {
                                        ActiveChatMessagesList.map((val, key) => {
                                            return <>
                                                {
                                                    val.senderId === parseInt(user.LoginUserId) ?
                                                        <div className="d-flex flex-row p-3 justify-content-end">
                                                            <img src="https://img.icons8.com/color/48/000000/circled-user-male-skin-type-7.png" width="35" height="35" />
                                                            <div className=" chat ms-2 p-2">
                                                                {
                                                                    val.isAttachment ?
                                                                        <a target='_blank' href={config.S3BucketURL + val.message}>
                                                                            {
                                                                                val.attachmentType === "image/png" || val.attachmentType === "image/jpeg" ?
                                                                                    <img width={100} height={100} src={config.S3BucketURL + val.message} />
                                                                                    :
                                                                                    val.attachmentType === "application/pdf" ?
                                                                                        <img width={100} height={50} src='/pdf-icon.png' />
                                                                                        :
                                                                                        val.attachmentType === "application/vnd.ms-excel" || val.attachmentType === "text/csv" || val.attachmentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                            <img width={100} height={50} src='/excellogo.png' />
                                                                                            : <img width={100} height={50} src='/msdoc.png' />
                                                                            }
                                                                        </a>
                                                                        :
                                                                        val.message
                                                                }
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="d-flex flex-row p-3 justify-content-start">
                                                            <div className="bg-white  me-2 p-2">
                                                                <span className="text-muted">
                                                                    {
                                                                        val.isAttachment ?
                                                                            <a target='_blank' href={config.S3BucketURL + val.message}>
                                                                                {
                                                                                    val.attachmentType === "image/png" || val.attachmentType === "image/jpeg" ?
                                                                                        <img width={100} height={100} src={config.S3BucketURL + val.message} />
                                                                                        :
                                                                                        val.attachmentType === "application/pdf" ?
                                                                                            <img width={100} height={50} src='/pdf-icon.png' />
                                                                                            :
                                                                                            val.attachmentType === "application/vnd.ms-excel" || val.attachmentType === "text/csv" || val.attachmentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                <img width={100} height={50} src='/excellogo.png' />
                                                                                                : <img width={100} height={50} src='/msdoc.png' />
                                                                                }                                                                            </a>
                                                                            :
                                                                            val.message
                                                                    }
                                                                </span></div>
                                                            <img src="https://img.icons8.com/color/48/000000/circled-user-male-skin-type-7.png" width="35" height="35" />
                                                        </div>
                                                }
                                            </>
                                        })
                                    }
                                </div>
                                <div className="form-group px-3">
                                    <form onSubmit={SendMessage}>
                                        <textarea className="form-control typeYourMessage" rows="3" placeholder="Type your message" value={Message}
                                            onChange={(e) => { (e.keyCode === 13 && e.shiftKey === false) ? SendMessage() : setMessage(e.target.value) }}>
                                        </textarea>
                                        <div className="d-flex align-items-center justify-content-between mt-1">
                                            <div>
                                                <div className='position-relative d-inline-block'>
                                                    <CircleBtnWithIcon fs={"18px"} className="Btn lgSpace me-0" onClick={() => sethideShowEmojipicker(!hideShowEmojipicker)}><FiSmile /></CircleBtnWithIcon>

                                                    {
                                                        hideShowEmojipicker && (
                                                            <div className="pickerWrapperEmj rightOne">
                                                                <Picker
                                                                    disableSearchBar="true"
                                                                    style={PickerDropDown}
                                                                    onEmojiClick={EmojiHandleClick}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                </div>

                                                <button type='submit' className='d-none' ref={submitBtnRef}></button>

                                                <AttachmentBtn paperclipSize={"18px"} multiple={true} onDone={UploadFile} />
                                            </div>
                                            <CircleBtnWithIcon fs={"18px"} className='Btn me-0 ms-2' onClick={() => submitBtnRef.current.click()}><MdSend /></CircleBtnWithIcon>
                                        </div>
                                        <div className='text-end'>
                                            {
                                                !!SupportChatId &&
                                                <button className='btn btn-sm btn-primary mt-2' onClick={() => EndChat()}>End Chat</button>
                                            }
                                            {/* <button className='btn btn-sm btn-primary mt-2' type='submit'>Send</button> */}
                                        </div>
                                    </form>
                                </div>
                            </>
                            :
                            <>
                                <form onSubmit={SendMessage}>
                                    <div className=' msg-box px-2 py-3'>
                                        <label className='fw-bold mb-2'>Describe Your Problem.</label>
                                        <textarea className="form-control typeYourMessage" rows="3" placeholder="Type your message" value={Message}
                                            onChange={(e) => { (e.keyCode === 13 && e.shiftKey === false) ? SendMessage() : setMessage(e.target.value) }}>
                                        </textarea>
                                        <div className='text-end'>

                                            <button className='btn btn-sm btn-primary mt-2' type='submit'>Send</button>
                                        </div>
                                    </div>
                                </form>
                            </>
                        : <div className="fullLoader">
                            <FallingLines
                                color="#4fa94d"
                                width="100"
                                visible={true}
                                ariaLabel='falling-lines-loading'
                            />
                        </div>
                }

            </div>
        </>
    )
}






const Style = createGlobalStyle`
.cardss{
	width: 300px;
	border: none;
	border-radius: 15px;
    border-color:#fff;
    position: relative;
display: flex;
flex-direction: column;
min-width: 0;
height: var(--bs-card-height);
color: var(--bs-body-color);
word-wrap: break-word;
background-color: var(--bs-card-bg);
background-clip: border-box;
}
.asd{border-radius: 15px;}
.adiv{
	background: ${props => props.theme.colors.buttons};
	border-radius: 15px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	font-size: 12px;
	height: 46px;
}
.chat{
	border: none;
	background: #E2FFE8;
	font-size: 13px;
	border-radius: 20px;
}
.bg-white{
	border: 1px solid #E7E7E9;
	font-size: 10px;
	border-radius: 20px;
}
.myvideo img{
	border-radius: 20px
}
.dot{
	font-weight: bold;
}
.form-control.typeYourMessage{
	border-radius: 12px;
	border: 1px solid #F0F0F0;
    resize:none;
    font-size: 13px;
}
.form-control.typeYourMessage:focus{
	box-shadow: none;
	}
.form-control.typeYourMessage::placeholder{
	font-size: 13px;
	color: #C4C4C4;
}
.iconPointer {
    cursor:pointer;
}
.chatHeight{
    max-height: 250px;
overflow-y: auto;
min-height: 250px;
}
`

const FloatingButton = styled.div`
position:fixed;
bottom:15px;
right:15px;
background-color: ${props => props.theme.colors.buttons};
color:#fff;
width:50px;
height:50px;
border-radius:50%;
display:flex;
align-items:center;
justify-content:center;
font-size:19px;
z-index:99999;
cursor:pointer;
`
