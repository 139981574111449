import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Col, Container, Form, InputGroup, Modal, Nav, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosLite, CallAxios } from '../../../Helpers';
import { CircleBtnWithIcon, ContentBox, ContentSubHeader, SubHeadTitle, TableWrapper } from '../../../Helpers/Global/StyledComponents';
import DataGrid from '../../../Components/DataGrid';
import moment from 'moment';
import { Constants } from '../../../Helpers/Contents';
import { CardPayment, CheckCircle, Remove } from '../../../Components/ActionBtns';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { withMask } from 'use-mask-input';
import * as Yup from "yup";
import { BsBank, BsCreditCardFill } from 'react-icons/bs';
import styled from 'styled-components';
import { TbListDetails } from 'react-icons/tb';
import DeleteConfirmationModal from '../../../Components/DeleteConfirmationModal';
import { useTranslation } from "react-i18next";
import { config } from '../../../Helpers/Config';
import { UpdateReduxStates } from '../../../Helpers/MasterData';
import { SignInManager } from '../../../Helpers/Authentication/SignInManager';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
const BGGREY = styled.div`
background:#eee;
`



function InvoiceList() {
    const { t: invt } = useTranslation('invoicePage');
    const { t: ct } = useTranslation('common');

    const columns = [
        { key: 'invoiceNumber', name: invt("invoiceNumber"), sortable: true, searchable: true, disableSearch: false },
        { key: 'createdDate', name: ct("date"), sortable: true, searchable: true, disableSearch: false },
        { key: 'subscriptionName', name: ct("plan"), sortable: true, searchable: true, disableSearch: false },
        { key: 'numberOfEmployee', name: ct("employee"), sortable: true, searchable: true, disableSearch: false },
        { key: 'subscriptionPeriod', name: ct("period"), sortable: true, searchable: true, disableSearch: false },
        { key: 'subscriptionAmount', name: ct("amount"), sortable: true, searchable: true, disableSearch: false },
        { key: 'status', name: ct('status'), sortable: true, searchable: true, disableSearch: false },
        { key: 'action', name: ct('actions') },
    ];

    const user = useSelector(x => x.app.user)

    const [CompanyInvoiceList, setCompanyInvoiceList] = useState([]);
    const [ShowOrderModal, setShowOrderModal] = useState(false);
    const [InvoiceIdForPayment, setInvoiceIdForPayment] = useState("");
    const [ShowDetailInvoiceModal, setShowDetailInvoiceModal] = useState(false);
    const [ShowDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

    useEffect(() => {
        getdata();
    }, []);

    const getdata = async () => {
        var response = await CallAxios('/api/SubscriptionPlan/InvoicesList|post', { id: user.CompanyId });
        if (!!response && response.status === 200) {
            setCompanyInvoiceList(response.data.data)
        }
    }
    const DeleteInvoice = async (id) => {
        var response = await CallAxios('/api/SubscriptionPlan/DeleteInvoice|post', { id: id.toString() });
        if (!!response && response.status === 200) {
            if (response.data.success) {
                setShowDeleteConfirmationModal(false)
                getdata()
            } else {
                toast.error(response.data.message)
            }
        }
    }


    return (
        <>
            {
                ShowDeleteConfirmationModal === true ?
                    <DeleteConfirmationModal title={invt("deleteInvoice")} message={invt("deleteThisInvoice")}
                        show={ShowDeleteConfirmationModal} onHide={setShowDeleteConfirmationModal}
                        objId={InvoiceIdForPayment} deletefn={DeleteInvoice} />
                    : null
            }
            {
                ShowDetailInvoiceModal &&
                <InvoiceDetailModal show={ShowDetailInvoiceModal} onHide={setShowDetailInvoiceModal} InvoiceId={InvoiceIdForPayment} />
            }
            {
                ShowOrderModal &&
                <OrderModal show={ShowOrderModal} onHide={setShowOrderModal} InvoiceIdForPayment={InvoiceIdForPayment} getData={getdata} />
            }
            <ContentSubHeader>
                <Row>
                    <Col md={4}>
                        <div className="my-3">
                            <SubHeadTitle>{invt("invoices")} {user.CompanyName}</SubHeadTitle>
                        </div>
                    </Col>
                    <Col md={8}>
                        <div className="my-3">
                            <Nav className="justify-content-end" activeKey="/home">
                                <Nav.Item className="text-right">

                                </Nav.Item>
                            </Nav>
                        </div>
                    </Col>
                </Row>
            </ContentSubHeader>
            <ContentBox>
                <Container fluid>
                    <Row>
                        <Col md={12} className="mt-3">
                            <DataGrid
                                columns={columns}
                                rows={CompanyInvoiceList}
                                itemsPerPage={10}
                                customBody={(rows) =>
                                    rows?.map((value, i) => {
                                        return <tr key={i}>
                                            <td>{value.invoiceNumber}</td>
                                            <td>{moment(value.createdDate).format('YYYY-MM-DD')}</td>
                                            <td>{value.subscriptionName}</td>
                                            <td>{value.numberOfEmployee}</td>
                                            <td>{value.subscriptionPeriod}</td>
                                            <td>{value.subscriptionAmount}</td>
                                            <td>{value.status}</td>
                                            <td>
                                                {
                                                    value.status === "Paid" &&
                                                    <CircleBtnWithIcon color='#F266AB' data-tooltip-id="my-tooltip" data-tooltip-content="Detail" onClick={() => { setInvoiceIdForPayment(value.id); setShowDetailInvoiceModal(true) }} className='Btn'><TbListDetails /></CircleBtnWithIcon>
                                                }
                                                {
                                                    value.status === "Pending" &&
                                                    <>
                                                        <CardPayment data-tooltip-id="my-tooltip" data-tooltip-content={ct("pay")} fs={"22px"} color='#f25656' onClick={() => { setInvoiceIdForPayment(value.id); setShowOrderModal(true) }} />
                                                        <Remove data-tooltip-id="my-tooltip" data-tooltip-content={ct("delete")} fs={"22px"} color='#f25656' onClick={() => { setInvoiceIdForPayment(value.id); setShowDeleteConfirmationModal(true) }} />
                                                    </>
                                                }
                                            </td>

                                        </tr>
                                    }
                                    )
                                } />
                        </Col>
                    </Row>
                </Container>
            </ContentBox>
        </>
    );
}

export default InvoiceList;

function InvoiceDetailModal(props) {
    const { t: invt } = useTranslation('invoicePage');
    const { t: ct } = useTranslation('common');

    const [InvoiceDetail, setInvoiceDetail] = useState({});
    useEffect(() => {
        getIvoiceDetail()
    }, []);
    const getIvoiceDetail = async () => {
        var res = await CallAxios("/api/SubscriptionPlan/getInvoiceDetail|post", { id: props.InvoiceId.toString() })
        if (!!res && res.data.success === true) {
            setInvoiceDetail(res.data.data)
        }
    }

    return (
        <>
            <Modal show={props.show} className="largeModalDialog" onHide={() => { props.onHide(false) }}>
                <Modal.Header closeButton className="">
                    <Modal.Title>{invt("invoiceDetail")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        !!InvoiceDetail ?
                            <>
                                <Row>
                                    <Col md={12}>
                                        <b>{invt("invoiceNumber")}:</b> {InvoiceDetail.invoiceNumber}
                                    </Col>
                                    <Col md={12}>
                                        <b>{invt("subscriptionName")}:</b> {InvoiceDetail.subscriptionName}
                                    </Col>
                                    <Col md={12}>
                                        <b>{invt("numberEmployees")}:</b> {InvoiceDetail.numberOfEmployee}
                                    </Col>
                                    <Col md={12}>
                                        <b>{ct("period")}:</b> {InvoiceDetail.subscriptionPeriod}
                                    </Col>
                                    <Col md={12}>
                                        <b>{invt("subscriptionAmount")}:</b> {InvoiceDetail.subscriptionAmount}
                                    </Col>
                                    <Col md={12}>
                                        <b>{invt("cardNumber")}:</b> {InvoiceDetail.cardNumber}
                                    </Col>
                                    <Col md={12}>
                                        <b>{invt("transactionNumber")}:</b> {InvoiceDetail.trxRefNumber}
                                    </Col>
                                    <Col md={12}>
                                        <b>{invt("paymentDate")}:</b> {moment(InvoiceDetail.paymentDate).format('MM/DD/YYYY')}
                                    </Col>
                                    <Col md={12}>
                                        <b>{invt("subscriptionStartDate")}:</b> {moment(InvoiceDetail.subscriptionStartDate).format('MM/DD/YYYY')}
                                    </Col>
                                    <Col md={12}>
                                        <b>{invt("subscriptionEndDate")}:</b> {moment(InvoiceDetail.subscriptionEndDate).format('MM/DD/YYYY')}
                                    </Col>
                                    <Col md={12}>
                                        <b>{ct("status")}:</b> {InvoiceDetail.status}
                                    </Col>
                                </Row>
                            </>
                            : null
                    }

                </Modal.Body >
                <Modal.Footer>
                    <Button className="btn btn-success mx-2" onClick={() => { props.onHide(false) }} >{ct("cancel")}</Button>
                </Modal.Footer>
            </Modal >
        </>
    )
}
function OrderModal(props) {
    const { t: invt } = useTranslation('invoicePage');
    const { t: st } = useTranslation('subscription');
    const { t: ct } = useTranslation('common');



    const dispatch = useDispatch();
    const [Plan, setPlan] = useState(null);
    const [cardNumberType, setCardNumberType] = useState(Constants.PaymentType.Card);
    const [activeIndex, setActiveIndex] = useState(0);
    const [tabkey, setTabkey] = useState("Subscription");
    const [SelectedSubscription, setSelectedSubscription] = useState("");
    const [InvoiceNumber, setInvoiceNumber] = useState("");
    const validateArr = [
        Yup.object().shape({
            BillingPeriod: Yup.string()
                .required(invt("billingPeriodRequired")),
            NumberOfEmployees: Yup.string()
                .required(invt("numberOfemployeesRequired")),
        })
    ]
    const formik = useFormik({
        initialValues: {
            PlanId: props.SelectedSubscription,
            NumberOfEmployees: "10",
            BillingPeriod: Constants.SubscriptionPeriod.Monthly.toString(),

            focus: "",

            // PaymentType: Constants.PaymentType.Card,
            CardNumber: "",
            Expiry: "",
            Name: "",
            CVC: "",
            IbanNumber: "",
            EnableAutoBilling: true
        },
        validationSchema: validateArr[activeIndex],
        onSubmit: values => {
            if (activeIndex === 0) {
                setTabkey("Purchase")
                return;
            }
            if (activeIndex === 1) {
                onSubmit(values)
            }
        },
    });
    useEffect(() => {
        getPackageDetail()
    }, []);
    const getPackageDetail = async () => {
        var res = await CallAxios("/api/SubscriptionPlan/getInvoice|post", { id: props.InvoiceIdForPayment.toString() })
        if (!!res && res.status === 200) {
            setPlan(res.data.data.subscriptionPlan)
            setSelectedSubscription(res.data.data.subscriptionId)
            setInvoiceNumber(res.data.data.invoiceNumber)
            setCardNumberType(res.data.data.paymentType)
            formik.setFieldValue("NumberOfEmployees", res.data.data.numberOfEmployee)
            // formik.setFieldValue("BillingPeriod", res.data.data.subscriptionPeriod)
            formik.setFieldValue("CardNumber", res.data.data.paymentType === Constants.PaymentType.Card ? res.data.data.cardNumber : "")
            formik.setFieldValue("IbanNumber", res.data.data.paymentType === Constants.PaymentType.Paypal ? res.data.data.cardNumber : "")
            if (Constants.SubscriptionPeriod.Trial !== res.data.data.subscriptionPlan.currentPlanSubscriptionPeriod)
                formik.setFieldValue("BillingPeriod", res.data.data.subscriptionPlan.currentPlanSubscriptionPeriod.toString())
        }
    }
    useEffect(() => {
        if (!!formik.values.NumberOfEmployees) {
            if (parseInt(formik.values.NumberOfEmployees) < Plan?.currentPlanNoOfEmployees && Plan?.isCurrentPlanTrial === false) {
                formik.setFieldValue("NumberOfEmployees", Plan.currentPlanNoOfEmployees.toString())
                toast.error(invt("errorMsg.employeesLessThen"))
            }
        }
    }, [formik.values.NumberOfEmployees]);
    useEffect(() => {
        if (!!formik.values.BillingPeriod) {
            if (formik.values.BillingPeriod < Plan?.currentPlanSubscriptionPeriod && Plan?.isCurrentPlanTrial === false) {
                formik.setFieldValue("BillingPeriod", Plan.currentPlanSubscriptionPeriod)
                toast.error(invt("errorMsg.billingPeriod"))
            }
        }
    }, [formik.values.BillingPeriod]);
    const onSubmit = async () => {
        if (cardNumberType === Constants.PaymentType.Card) {
            var month = parseInt(formik.values.Expiry.split("/")[0]);
            var year = parseInt(formik.values.Expiry.split("/")[1]);
            if (!formik.values.Expiry || month < 1 || month > 12 || year < 23) {
                toast.error(invt("errorMsg.expiryNotValid"))
                return
            }
            if (!formik.values.CardNumber) {
                toast.error(invt("errorMsg.CardNumber"))
                return
            }
            if (!formik.values.Name) {
                toast.error(invt("errorMsg.nameBlank"))
                return
            }
            if (!formik.values.CVC) {
                toast.error(invt("errorMsg.CVCblank"))
                return
            }
        }
        var data = {
            InvoiceId: parseInt(props.InvoiceIdForPayment),
            SubscriptionPlanId: SelectedSubscription,
            NoOfEmployee: parseInt(formik.values.NumberOfEmployees),
            Period: parseInt(formik.values.BillingPeriod),
            CardOrIbanNumber: cardNumberType === Constants.PaymentType.Card ? formik.values.CardNumber.toString() : formik.values.IbanNumber.toString(),
            PaymentType: cardNumberType,
            ExpiryMonth: month,
            ExpiryYear: year,
            Name: formik.values.Name,
            CVC: formik.values.CVC.toString(),
            State: window.location.href,
            EnableAutoBilling: formik.values.EnableAutoBilling
        }
        var res = await CallAxios("/api/SubscriptionPlan/PurchaseSubscriptionPlan|post", data)
        if (!!res && res.status === 200) {
            if (res.data.success === true) {
                if (cardNumberType === Constants.PaymentType.Paypal) {
                    window.open(config.API_URL + "/api/SubscriptionPlan/PaypalPaymentRedirection?url=" + encodeURIComponent(res.data.message))
                } else {
                    await UpdateReduxStates(new SignInManager().ParseJwt, dispatch)
                    toast.success(st('errorMsg.paymentSuccessfull'))
                    props.getData();
                    props.onHide(false)
                }
            }
            else {
                toast.error(res.data.message)
            }
        }
    }
    useEffect(() => {
        setActiveIndex(tabkey === "Subscription" ? 0 : 1);
    }, [tabkey]);
    // const paypalSubscribe = (data, actions) => {
    //     return actions.subscription.create({
    //         'plan_id': "P-15V72498EL135724PMUEDBVA",
    //     });
    // };
    // const paypalOnError = (err) => {
    //     console.log("Error")
    // }
    // const paypalOnApprove = (data, detail) => {
    //     // call the backend api to store transaction details
    //     console.log("Payapl approved")
    //     console.log(data.subscriptionID)
    // };
    return (
        <>
            <Modal show={props.show} className="largeModalDialog" onHide={() => { props.onHide(false) }}>
                <Modal.Header closeButton className="">
                    <Modal.Title>{invt("payInvoice")} ({InvoiceNumber})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs
                        defaultActiveKey="Subscription"
                        transition={false}
                        id="SubscriptionTabs"
                        onSelect={(k) => setTabkey(k)}
                        className="mb-3 nav-underline"
                        activeKey={tabkey}
                    >
                        <Tab eventKey="Subscription" title={invt("subscription")}>
                            <Row>
                                <Col md={6}>
                                    <Row>
                                        <Col md={12}>
                                            {ct("product")}: {Plan?.planName}
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className="mb-3" controlId="AccountBaseData.txtNumOfEmp">
                                                <Form.Label>{invt("numberEmployees")}</Form.Label>
                                                <Form.Select as="select" className="SelectbgGrey" name='NumberOfEmployees' value={formik.values.NumberOfEmployees} onChange={formik.handleChange} custom>
                                                    {
                                                        Constants.noOfEmployeeDropDown.map((val, key) => {
                                                            return <option key={"noOfEmployee" + key} value={val.value}>{val.text}</option>
                                                        })
                                                    }
                                                </Form.Select>
                                                {formik.touched.noOfEmployeeDropDown && formik.errors.noOfEmployeeDropDown ? (
                                                    <span className="error text-danger text-left">{formik.errors.noOfEmployeeDropDown}</span>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className="mb-3" controlId="AccountBaseData.txtNumOfEmp">
                                                <Form.Label>{invt("billingPeriod")}</Form.Label>
                                                <Form.Select as="select" className="SelectbgGrey" name='BillingPeriod' value={formik.values.BillingPeriod} onChange={formik.handleChange} custom>
                                                    <option value={Constants.SubscriptionPeriod.Monthly}>{ct("monthly")}</option>
                                                    <option value={Constants.SubscriptionPeriod.Yearly}>{ct("yearly")}</option>
                                                </Form.Select>
                                                {formik.touched.BillingPeriod && formik.errors.BillingPeriod ? (
                                                    <span className="error text-danger text-left">{formik.errors.BillingPeriod}</span>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            {ct("summary")}: {Plan?.planName} {ct("forUpTo")} {formik.values.NumberOfEmployees} {ct("employee")}
                                        </Col>
                                        <Col md={12}>
                                            {ct("resultingCost")}:
                                        </Col>
                                        <Col md={12}>
                                            {formik.values.BillingPeriod === Constants.SubscriptionPeriod.Monthly.toString() ? 49 : 490} € {invt("baseFee")}
                                        </Col>
                                        <Col md={12}>
                                            + {
                                                formik.values.BillingPeriod === Constants.SubscriptionPeriod.Monthly.toString() ?
                                                    (parseFloat(formik.values.NumberOfEmployees) * Plan?.priceMonthly) + ` €  ${invt("licenseFee")} (` + formik.values.NumberOfEmployees + ` ${ct("users")} x ` + Plan?.priceMonthly + "€)"
                                                    :
                                                    (parseFloat(formik.values.NumberOfEmployees) * Plan?.priceYearly) * 12 + `€  ${invt("licenseFee")} (` + formik.values.NumberOfEmployees + ` ${ct("users")} x ` + Plan?.priceYearly * 12 + "€)"
                                            }
                                        </Col>
                                        {
                                            (Plan?.isCurrentPlanTrial === false && Plan.debitAmountFromCurrentPlan > 0) &&
                                            <Col md={12}>
                                                -{Plan.debitAmountFromCurrentPlan} {invt("planUpgrade")}
                                            </Col>
                                        }
                                        <Col md={12}>
                                            =
                                            {
                                                formik.values.BillingPeriod === Constants.SubscriptionPeriod.Monthly.toString() ?
                                                    (49 + (parseFloat(formik.values.NumberOfEmployees) * Plan?.priceMonthly)) - (Plan?.isCurrentPlanTrial === false ? Plan.debitAmountFromCurrentPlan : 0) :
                                                    (490 + (parseFloat(formik.values.NumberOfEmployees) * (Plan?.priceYearly * 12))) - (Plan?.isCurrentPlanTrial === false ? Plan.debitAmountFromCurrentPlan : 0)
                                            }
                                            € {ct("per")} {formik.values.BillingPeriod == Constants.SubscriptionPeriod.Yearly.toString() ? ct("year") : ct("month")} (net)
                                        </Col>

                                        {
                                            formik.values.BillingPeriod === "year" &&
                                            <>
                                                <Col md={12}>
                                                    <CheckCircle color='#2ecc71' />
                                                    {

                                                        (((Plan?.priceMonthly - Plan?.priceYearly) / Plan?.priceMonthly) * 100).toFixed(2)
                                                    }
                                                    % {invt("discountToMonthly")}
                                                </Col>
                                                <Col md={12}>
                                                    <CheckCircle color='#2ecc71' />
                                                    {invt("premiumSupport")}
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                </Col>
                                <Col md={6}>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="Purchase" title={ct("purchase")}>
                            <Row>
                                <Col md={6}>
                                    <ButtonGroup onClick={(e) => setCardNumberType(parseInt(e.target.name))} aria-label="Basic example" size="sm" className="btnSelectGroup mb-3">
                                        <Button name={Constants.PaymentType.Card} variant={cardNumberType === Constants.PaymentType.Card ? "primary" : "secondary"} className='makeitselect font-14' >{st("creditCard")}</Button>
                                        <Button name={Constants.PaymentType.Paypal} variant={cardNumberType === Constants.PaymentType.Paypal ? "primary" : "secondary"} className='makeitselect font-14' >Paypal</Button>
                                    </ButtonGroup>
                                    {cardNumberType === Constants.PaymentType.Card &&
                                        <>
                                            <InputGroup className="mb-3">
                                                <Cards
                                                    number={formik.values.CardNumber}
                                                    expiry={formik.values.Expiry}
                                                    cvc={formik.values.CVC}
                                                    name={formik.values.Name}
                                                    focused={formik.values.focus}
                                                />
                                            </InputGroup>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text><BsCreditCardFill /></InputGroup.Text>
                                                <Form.Control aria-label="Card Number" name='CardNumber' onFocus={() => formik.setFieldValue("focus", "number")} className='w-50' pattern="[\d| ]{16,22}" type="number" value={formik.values.CardNumber} onChange={formik.handleChange} placeholder={invt("cardNumber")} />
                                                <Form.Control aria-label="MM/YY" ref={withMask('99/99')} type='text' placeholder='MM/YY' onFocus={() => formik.setFieldValue("focus", "expiry")} className='w-25' name='Expiry' value={formik.values.Expiry} onChange={formik.handleChange} />
                                            </InputGroup>
                                            <InputGroup className="mb-3">
                                                <Form.Control aria-label="Card Number" name='Name' onFocus={() => formik.setFieldValue("focus", "name")} className='w-50' type="text" value={formik.values.Name} onChange={formik.handleChange} placeholder={ct("name")} />
                                            </InputGroup>
                                            <InputGroup className="mb-3">
                                                <Form.Control aria-label="Card Number" name='CVC' onFocus={() => formik.setFieldValue("focus", "cvc")} className='w-50' type="text" value={formik.values.CVC} onChange={formik.handleChange} placeholder='CVC' />
                                            </InputGroup>
                                        </>
                                    }
                                    {cardNumberType === Constants.PaymentType.Paypal &&
                                        <>
                                            <InputGroup className="mb-3">
                                                {/* <InputGroup.Text><BsBank /></InputGroup.Text>
                                            <Form.Control name='IbanNumber' type="number" value={formik.values.IbanNumber} onChange={formik.handleChange} placeholder='DE00 0000 0000 0000 0000 00' /> */}
                                                <Button className="btn btn-success mx-2" onClick={() => { formik.handleSubmit(); }} >Pay with Paypal</Button>
                                                <PayPalScriptProvider options={{ clientId: "AQwyflKJXq9UPntvivVW8sRw7uRlAaaVPYUAkLSdh4p1c5zulsaEeqAirPkaR03R1f4SiDFNDx0DVZA4", currency: "EUR", intent: "subscription" }}>
                                                    <PayPalButtons style={{ layout: "horizontal" }} />
                                                </PayPalScriptProvider>
                                            </InputGroup>
                                            <InputGroup className="mb-3">
                                                <Form.Group className="mb-3">
                                                    <Form.Check className="text-left" name="EnableAutoBilling" checked={formik.values.EnableAutoBilling} onChange={formik.handleChange} type="checkbox" label="Enable auto billing" />
                                                </Form.Group>
                                            </InputGroup>
                                        </>
                                    }
                                    {formik.touched.IbanNumber && formik.errors.IbanNumber ? (
                                        <span className="error text-danger text-left">{formik.errors.IbanNumber}</span>
                                    ) : null}

                                    <p className='small'>{invt("OnPurchase")} <b>{ct("terms")}</b> {ct("and")} <b>{ct("privacyPolicy")}</b></p>

                                </Col>
                                <Col md={6}>
                                    <BGGREY className=' border p-3'>
                                        <p>{invt("thankYouForPurchasing")} {Plan?.planName} {ct("plan")}.</p>
                                        <p>{invt("allPaymentInformation")} <b>{invt("SSLEncypted")}</b> {invt("notStored")}</p>
                                        <p>{invt("anyQuestion")}</p>
                                        <p><b>E-Mail: support@basta-shitf.de</b></p>
                                    </BGGREY>
                                </Col>
                            </Row>
                        </Tab>
                    </Tabs>
                </Modal.Body >
                <Modal.Footer>
                    <Button className="btn btn-success mx-2" onClick={() => { formik.handleSubmit(); }} >{tabkey === "Purchase" ? ct("subscribe") : ct("continue")}</Button>
                </Modal.Footer>
            </Modal >
        </>
    )
}