import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import CollapseableDiv from "../../../Components/CollapseCard";
import { CallAxios } from "../../../Helpers";
import { SignInManager } from "../../../Helpers/Authentication/SignInManager";
import { UpdateReduxStates } from "../../../Helpers/MasterData";
import { useTranslation } from "react-i18next";

function MoreSettings(props) {
    const { t: Lst } = useTranslation('locationSetting');
    const { t: ct } = useTranslation('common');


    const [isDisabled, setIsDisabled] = useState(false);
    const dispatch = useDispatch();

    const CHECKBOXLIST = [
        { name: "Employees_only_created_by_name", label: Lst('planTab.moreSetAccor.labelList1').toString() },
        { name: "Hide_employee_hours_in_calendar", label: Lst('planTab.moreSetAccor.labelList2').toString() },
        { name: "Employees_can_see_all_workspaces", label: Lst('planTab.moreSetAccor.labelList3').toString() },
        { name: "Allow_employees_to_see_shifts_of_their_colleagues", label: Lst('planTab.moreSetAccor.labelList4').toString() },
        { name: "Activate_shift_related_breaks_in_duty_roster", label: Lst('planTab.moreSetAccor.labelList5').toString() }];

    const formik = useFormik({
        initialValues: {
            Employees_only_created_by_name: false,
            Hide_employee_hours_in_calendar: false,
            Employees_can_see_all_workspaces: false,
            Activate_shift_related_breaks_in_duty_roster: false,
            Allow_employees_to_see_shifts_of_their_colleagues: false
        },
        onSubmit: values => {
            submit(values)
        },
    });
    const submit = async (values) => {
        var data = {
            Employees_only_created_by_name: values.Employees_only_created_by_name,
            Hide_employee_hours_in_calendar: values.Hide_employee_hours_in_calendar,
            Employees_can_see_all_workspaces: values.Employees_can_see_all_workspaces,
            Activate_shift_related_breaks_in_duty_roster: values.Activate_shift_related_breaks_in_duty_roster,
            Employees_can_see_colleagues_shifts: values.Allow_employees_to_see_shifts_of_their_colleagues,
        };
        await CallAxios("/api/LocationSetting/MoreSetting|post", data);
        await UpdateReduxStates(new SignInManager().ParseJwt, dispatch)

    };
    useEffect(() => {
        if (!!props.MoreSetting) {
            formik.setFieldValue("Employees_only_created_by_name", props.MoreSetting.employees_only_created_by_name);
            formik.setFieldValue("Hide_employee_hours_in_calendar", props.MoreSetting.hide_employee_hours_in_calendar);
            formik.setFieldValue("Employees_can_see_all_workspaces", props.MoreSetting.employees_can_see_all_workspaces);
            formik.setFieldValue("Allow_employees_to_see_shifts_of_their_colleagues", props.MoreSetting.employees_can_see_colleagues_shifts);
            formik.setFieldValue("Activate_shift_related_breaks_in_duty_roster", props.MoreSetting.activate_shift_related_breaks_in_duty_roster);
        }
    }, [props.MoreSetting]);

    useEffect(() => {
        if (formik.values.Employees_can_see_all_workspaces) {
            formik.setFieldValue("Allow_employees_to_see_shifts_of_their_colleagues", formik.values.Employees_can_see_all_workspaces)
            setIsDisabled(true)
        } else {
            setIsDisabled(false)
        }
    }, [formik.values.Employees_can_see_all_workspaces]);



    return (
        <>
            <CollapseableDiv title={Lst('planTab.moreSetAccor.moreSettings')}>
                <Row>
                    <Col md={12}>
                        <div className="TabFormContainerSection">
                            {CHECKBOXLIST.map((val, index) =>
                                <Form.Group className="mb-2" key={index} controlId={val.name}>
                                    <Form.Check className={`text-left ${val.name === "Allow_employees_to_see_shifts_of_their_colleagues" && 'ml-3'}`} name={val.name}
                                        type="checkbox" onChange={formik.handleChange} disabled={val.name === "Allow_employees_to_see_shifts_of_their_colleagues" && isDisabled} checked={formik.values[val.name]} label={val.label} />
                                </Form.Group>
                            )}
                        </div>
                    </Col>
                </Row>
                <div className='text-left mt-4'>
                    <button type="button" onClick={formik.handleSubmit} className="btn btn themeGreenBtn btn-primary" > {ct('ready')}</button>
                </div>
            </CollapseableDiv>
        </>
    )
}

export default MoreSettings;
