import { setAbsenceCounterBadge, setAppSettings, setCurrentView, setIsshowSystemMessage, setLocationTheme, setPendingTimetrackCount, setRunningTimetrackCount, setSelectedTags, setSelectedWorkAreas, setUser } from "../Store/slices/AppSlice";
import { AxiosLite, CallAxios } from "./API/useAxios";
import { SignInManager } from "./Authentication/SignInManager";
import { Constants } from "./Contents";



export async function GetEmployeeDDForAbsence(setterFunction) {
    var response = await AxiosLite("/api/Employee/GetEmployeeDDForAbsence|get")
    if (!!response && response.status === 200) {
        setterFunction(response.data)
        return response.data?.[0]?.value;
    }
}

export async function GetEmployeeDD(setterFunction) {
    var response = await AxiosLite("/api/Employee/EmployeeDropDown|get")
    if (!!response && response.status === 200) {
        setterFunction(response.data)
        return response.data;
    }
}

export async function GetAbsenceTypeByEmpid(setterFunction, data) {
    var response = await CallAxios("/api/Absence/getAbsenceTypebyempid|post", data);
    if (!!response && response.status === 200) {
        setterFunction(response.data)
        return response.data?.[0]?.value;
    }
}

export async function UpdateTimeTrackBadge(dispatch) {
    var response = await AxiosLite("/api/timetracking/GetTimeTrackingBadge|get")
    if (!!response && response.status === 200) {
        dispatch(
            setPendingTimetrackCount(response.data.pending)
        )
        dispatch(
            setRunningTimetrackCount(response.data.running)
        )
    }
}

export async function UpdateWorkarea(dispatch) {
    var response = await AxiosLite("/api/BaseLoginData/getLocationWorkAreas|get")
    if (!!response && response.status === 200) {
        dispatch(
            setSelectedWorkAreas(response.data)
        )
    }
}
export const SwitchLocation = async (id, appSettings) => {
    var response = await CallAxios("/api/Account/SwitchLocation|post", { id: id + "" })
    if (!!response && response.status === 200) {
        localStorage.setItem("InitialLogin", true)
        new SignInManager().SetToken(response.data.token)
        var tokenData = new SignInManager().ParseJwt

        if (tokenData.Role.split(",").some(x => x === Constants.roles.AccountAdmin || x === Constants.roles.WorkspaceAdmin)) {
            window.location.href = `/companies/${id}/calendar`
        } else {
            if (appSettings.employeeAccess.canSeeCalender === true) {
                window.location.href = `/companies/${id}/calendar`
            }
            else if (appSettings.employeeAccess.canSeetimetracking === true) {
                window.location.href = `/companies/${id}/timetracking`
            }
            else if (appSettings.employeeAccess.canSeeabsences === true) {
                window.location.href = `/companies/${id}/absences`
            }
            else {
                window.location.href = `/companies/${id}/users/${tokenData.EmpID}/master_data`
            }
        }
    }
}

export async function UpdateReduxStates(tokenData, dispatch) {
    var res = await AxiosLite("/api/BaseLoginData/basedatalogin|get")
    if (!!res && res.status === 200) {
        var tags = [{ value: "Withouttags", text: "Without tags", label: "Without tags" }, ...res.data.tags];
        localStorage.setItem("LoginUserId", tokenData.EmpID)

        dispatch(
            setUser({
                CompanyId: tokenData.CompanyId,
                LocationId: tokenData.LocationId,
                roles: tokenData.Role.split(","),
                LoginUserId: tokenData.EmpID,
                EncLoginUserId: tokenData.EncLoginUserId,
                CompanyName: tokenData.CompnayName,
                empName: tokenData.EmpName,

                // empId: 0,
                CurrentBalance: res.data.currentBalance,
                CurrentVocationBalance: res.data.currentVocationBalance,
                LocationName: res.data.locationName,
                messageCounter: res.data.messageCounter,
            })
        );
        dispatch(
            setPendingTimetrackCount(res.data.pendingTimeTrackingCounter)
        )
        dispatch(
            setRunningTimetrackCount(res.data.runningTimeTrackingCounter)
        )
        dispatch(
            setAbsenceCounterBadge(res.data.pendingAbsenceCounter)
        )
        dispatch(
            setAppSettings({
                Language: res.data.language,
                Modules: res.data.modules,
                SubscriptionStatus: res.data.subscriptionStatus,
                employeeAccess: {
                    canSeeabsences: res.data.employeeAccess.canSeeabsences,
                    canSeeavailabilities: res.data.employeeAccess.canSeeavailabilities,
                    canSeeCalender: res.data.employeeAccess.canSeeCalender,
                    canSeeMessages: res.data.employeeAccess.canSeeMessages,
                    canSeetimetracking: res.data.employeeAccess.canSeetimetracking,
                },
                calendarSettings: {
                    Drag_drop_list_views: res.data.calendarSettings.drag_drop_list_views,
                    highlight_events: res.data.calendarSettings.highlight_events,
                    Hourly_view_end_time: res.data.calendarSettings.hourly_view_end_time,
                    Hourly_view_start_time: res.data.calendarSettings.hourly_view_start_time,
                    Overlapping_shifts_in_day_view: res.data.calendarSettings.overlapping_shifts_in_day_view,
                    Overlapping_shifts_in_week_view: res.data.calendarSettings.overlapping_shifts_in_week_view,
                    Override_row_height: res.data.calendarSettings.override_row_height,
                    time_steps_length: res.data.calendarSettings.time_steps_length
                },
                tags: tags,
                locationsettings: {
                    Activate_shift_related_breaks_in_duty_roster: res.data.locationsettings.activate_shift_related_breaks_in_duty_roster,
                    Employees_can_see_all_workspaces: res.data.locationsettings.employees_can_see_all_workspaces,
                    Employees_only_created_by_name: res.data.locationsettings.employees_only_created_by_name,
                    Hide_employee_hours_in_calendar: res.data.locationsettings.hide_employee_hours_in_calendar,
                    TimeZone: res.data.locationsettings.timeZone,
                    ProhibitShiftSwaps: res.data.locationsettings.prohibitShiftSwaps,
                    applyForFullShifts: res.data.locationsettings.applyForFullShifts,
                    seeApplicationsFromOtherEmployees: res.data.locationsettings.seeApplicationsFromOtherEmployees,
                    viewApplicationsInResourceView: res.data.locationsettings.viewApplicationsInResourceView,
                },
                absenceSetting: {
                    CalculatePublicHolidayHoursInReports: res.data.absenceSetting.calculatePublicHolidayHoursInReports,
                    ConfirmAbsencesCreatedAdministrators: res.data.absenceSetting.confirmAbsencesCreatedAdministrators,
                    ConfirmAbsencesCreatedEmployee: res.data.absenceSetting.confirmAbsencesCreatedEmployee,
                    EmployeesCanSeecolleagueAbsences: res.data.absenceSetting.employeesCanSeecolleagueAbsences,
                    status_and_type_of_absence_not_visible_to_other_employees: res.data.absenceSetting.status_and_type_of_absence_not_visible_to_other_employees,
                },
                TimetrackSettings: {
                    Automatic_break_deduction_after_net_time: res.data.timetrackSettings.automatic_break_deduction_after_net_time,
                    Copy_tags_from_roster: res.data.timetrackSettings.copy_tags_from_roster,
                    Employees_allowed_edit_working_area_of_time_recordings: res.data.timetrackSettings.employees_allowed_edit_working_area_of_time_recordings,
                    Employees_allowed_to_tag_time_records: res.data.timetrackSettings.employees_allowed_to_tag_time_records,
                    Employees_can_create_own_tags: res.data.timetrackSettings.employees_can_create_own_tags,
                    employee_app: res.data.timetrackSettings.employee_app,
                    Resulting_pause_at_least_as_long_as_automatic_pause: res.data.timetrackSettings.resulting_pause_at_least_as_long_as_automatic_pause,
                    Summarize_time_records: res.data.timetrackSettings.summarize_time_records,
                    Time_recordings_automatically_confirmed: res.data.timetrackSettings.time_recordings_automatically_confirmed,
                },
                WorkSpaces: res.data.workSpaces,
                MinimumRoleToEditLocationSetting: res.data.minimumRoleToEditLocationSetting,
            })
        )
        dispatch(
            setLocationTheme(JSON.parse(res.data.theme))
        )
        dispatch(setIsshowSystemMessage(JSON.parse(localStorage.getItem("isshowSystemMessage")) === null ? true : JSON.parse(localStorage.getItem("isshowSystemMessage"))));
        if (localStorage.getItem("InitialLogin") === "true") {
            dispatch(
                setSelectedWorkAreas(res.data.workSpaces)
            )
        } else if (!!localStorage.getItem("selectedWorkArea")) {
            dispatch(
                setSelectedWorkAreas(res.data.workSpaces.filter(x => localStorage.getItem("selectedWorkArea").split(",").includes(x.value)))
            )
        }
        if (!localStorage.getItem("selectedWorkArea")) {
            dispatch(
                setSelectedWorkAreas(res.data.workSpaces.length > 0 && Array.of(res.data.workSpaces[0]))
            )
        }
        //  else {
        //     dispatch(
        //         setSelectedWorkAreas(res.data.workSpaces.filter(x => localStorage.getItem("selectedWorkArea").includes(x.value)))
        //     )
        // }
        if (localStorage.getItem("InitialLogin") === "true") {
            dispatch(
                setSelectedTags(tags)
            )
        } else if (!!localStorage.getItem("selectedTags")) {
            dispatch(
                setSelectedTags(tags.filter(x => localStorage.getItem("selectedTags").split(",").includes(x.value)))
            )
        }
        localStorage.setItem("InitialLogin", false)
        if (tokenData.Role.split(",").some(x => x === Constants.roles.AccountAdmin || x === Constants.roles.WorkspaceAdmin)) {
            const currentView = localStorage.getItem("currentView");
            if (currentView === Constants.layoutType.Employee) {
                dispatch(setCurrentView(Constants.layoutType.Employee));
            } else {
                dispatch(setCurrentView(Constants.layoutType.Admin));
            }

        } else {
            dispatch(setCurrentView(Constants.layoutType.Employee));
        }

    }
}