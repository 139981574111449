import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { BsChevronDown } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import TimeTrackingBreaksRepeter from '../../Components/TimeTrackingBreaksRepeter';
import { AxiosLite, CallAxios } from '../../Helpers';
import { Constants } from '../../Helpers/Contents';
import { ContentBox, ContentSubHeader } from '../../Helpers/Global/StyledComponents';
import { GetEmployeeDD, UpdateTimeTrackBadge } from '../../Helpers/MasterData';
import TimeTrackingGrid from './TimeTrackingGrid';
import TimeTrackingHeader from './TimeTrackingHeader';
import { useTranslation } from "react-i18next";

// Main Absences Page
function TimeTrackingList() {
    const currentView = useSelector(x => x.app.currentView)
    const user = useSelector(x => x.app.user)
    const [timeTrackingList, setTimeTrackingList] = useState([]);
    const [TrackViaBroserStamp, setTrackViaBroserStamp] = useState(false);
    // const [startDate, setstartDate] = useState(null);
    // const [endDate, setEndDate] = useState(null);
    const [showAddTimeTrackingModal, setShowAddTimeTrackingModal] = useState(false);
    const [timeTrackIdForEdit, setTimeTrackIdForEdit] = useState("");

    const [FilterStartDate, setFilterStartDate] = useState();
    const [FilterEndDate, setFilterEndDate] = useState();
    const [checkRadio, setCheckRadio] = useState("home");
    const [PaginateInfo, setPaginateInfo] = useState(null);

    useEffect(() => {
        Promise.all([
            getTrackingViaDetail(),
            // getTimeTrackList()
        ])
    }, []);
    const getPaginateInfo = (prop) => {
        setPaginateInfo(prop)
    }
    const getTrackingViaDetail = async () => {
        var response = await AxiosLite("/api/TimeTracking/GetEmployeeTimeTrackingMode|get")
        if (!!response && response.status === 200) {
            if (response.data === 'Browser-Stamp') {
                setTrackViaBroserStamp(true)
            }
        }
    }
    const getTimeTrackList = async (prop, startDate, endDate) => {
        if (!prop && !!PaginateInfo) {
            prop = PaginateInfo
        }
        if (currentView === Constants.layoutType.Employee) {
            var response = await CallAxios("/api/TimeTracking/AllEmployeeTimeTrackingList|post", {
                EmployeeId: user.LoginUserId,
                Skip: prop.skip,
                Take: prop.take,
                StartDate: !!startDate ? moment(startDate).startOf('day') : null,
                EndDate: !!endDate ? moment(endDate).endOf('day') : null,
            });
            setTimeTrackingList(response.data.list)
            prop.updateCount(response.data.count)
        } else {// only for admin main view
            var response = await CallAxios("/api/TimeTracking/TimeTrackingList|post", {
                Skip: prop.skip,
                Take: prop.take,
                StartDate: !!startDate ? moment(startDate).startOf('day') : null,
                EndDate: !!endDate ? moment(endDate).endOf('day') : null,
            });
            if (!!response && response.status === 200) {
                setTimeTrackingList(response.data.list)
                prop.updateCount(response.data.count)
                // setFilterTimeTrackingList(response.data)
            }
        }
    }
    const UpdateStatusAllTimeTracks = async () => {
        var response = await AxiosLite("/api/TimeTracking/UpdateAllTimeTracking|get");
        if (!!response && response.status === 200) {
            // UpdateTimeTrackBadge(dispatch);
            getTimeTrackList(PaginateInfo)
            // setTimeTrackingList(response.data);
            // setFilterTimeTrackingList(response.data)
        }
    }
    const handelDateFilter = (btnType, startDate, endDate) => {
        if (btnType === "reset") {
            setFilterStartDate()
            setFilterEndDate()
            getTimeTrackList(null, startDate, endDate)
        }
        if (btnType === "filter") {
            setFilterStartDate(startDate)
            setFilterEndDate(endDate)
            getTimeTrackList(null, startDate, endDate)
        }
    }
    return (
        <>
            <ContentSubHeader>
                <TimeTrackingHeader checkRadio={checkRadio} setCheckRadio={setCheckRadio} handelDateFilter={handelDateFilter} UpdateStatusAllTimeTracks={UpdateStatusAllTimeTracks} setTimeTrackIdForEdit={setTimeTrackIdForEdit} setShowAddTimeTrackingModal={setShowAddTimeTrackingModal} />
            </ContentSubHeader>
            <ContentBox>
                {
                    TrackViaBroserStamp === true ?
                        <TimeTrackingTimer getTrackingViaDetail={getTrackingViaDetail} getTimeTrackList={getTimeTrackList} PaginateInfo={PaginateInfo} />
                        : null
                }
                <TimeTrackingGrid getPaginateInfo={getPaginateInfo} checkRadio={checkRadio} RenderFor="Main" getTimeTrackList={getTimeTrackList} timeTrackingList={timeTrackingList} setTimeTrackingList={setTimeTrackingList} setShowAddTimeTrackingModal={setShowAddTimeTrackingModal}
                    setTimeTrackIdForEdit={setTimeTrackIdForEdit} FilterStartDate={FilterStartDate} FilterEndDate={FilterEndDate} />
            </ContentBox>
            {
                showAddTimeTrackingModal === true ?
                    <AddEditTimeTrackingModel show={showAddTimeTrackingModal} onHide={setShowAddTimeTrackingModal} getTimeTrackList={getTimeTrackList} timeTrackIdForEdit={timeTrackIdForEdit} />
                    : null
            }
        </>
    );
}

export default TimeTrackingList;

export function AddEditTimeTrackingModel(props) {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');
    const { t: tpt } = useTranslation('timeTrackingPage');

    const DropdownIndicator = () => null;
    const appSettings = useSelector(x => x.app.appSettings);
    const user = useSelector(x => x.app.user);
    const currentView = useSelector(x => x.app.currentView);
    const [createOwnTimeRecords, setCreateOwnTimeRecords] = useState(false);
    const { userid } = useParams();

    const [EmployeeDDList, setEmployeeDDList] = useState([]);
    const [workspaceDD, setWorkspaceDD] = useState([]);
    const [tagsDD, settagsDD] = useState([]);
    const [showTagsField, setShowTagsField] = useState(false);

    //edit states
    const [employeeName, setEmployeeName] = useState("");
    const [totalbreackMinutes, setTotalbreackMinutes] = useState("");
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showBreakDetail, setShowBreakDetail] = useState(false);
    useEffect(() => {
        onLoad();
        if (!!props.timeTrackIdForEdit) {
            getData();
        }
        if (currentView === Constants.layoutType.Employee) {
            GetEmpRights()
        }
    }, []);
    const GetEmpRights = async () => {
        var response = await CallAxios('/api/Employee/GetEmpRights|post', { id: user.EncLoginUserId })
        if (!!response && response.status === 200) {
            setCreateOwnTimeRecords(response.data.createOwnTimeRecords)
        }
    }
    const onLoad = async () => {
        if (!props.timeTrackIdForEdit && !userid && currentView === Constants.layoutType.Admin) {
            var res = await GetEmployeeDD(setEmployeeDDList)
            formik.setFieldValue("employeeID", res[0].value)
        }
        if (!!userid) {
            formik.setFieldValue("employeeID", userid)
        }
        if (currentView === Constants.layoutType.Employee) {
            formik.setFieldValue("employeeID", user.LoginUserId)
            getEmployeeworkspacesDD()
            formik.setFieldValue("workSpaceID", appSettings.WorkSpaces[0].value)
        } else {
            setWorkspaceDD(appSettings.WorkSpaces)
            formik.setFieldValue("workSpaceID", appSettings.WorkSpaces[0].value)
        }
        settagsDD(appSettings.tags.filter(x => x.value !== "Withouttags"))
    }
    const getData = async () => {
        var response = await CallAxios("/api/TimeTracking/getTimeTracking|post", { id: props.timeTrackIdForEdit });
        if (!!response && !!response.data) {

            setEmployeeName(response.data.employeeName)
            setTotalbreackMinutes(response.data.totalbreackMinutes)
            formik.setFieldValue("employeeID", response.data.employeeID);
            formik.setFieldValue("workSpaceID", response.data.workSpaceID);
            formik.setFieldValue("day", new Date(response.data.day));
            formik.setFieldValue("startTimeHour", response.data.startTimeHour);
            formik.setFieldValue("startTimeMin", response.data.startTimeMin);
            formik.setFieldValue("endTimeHour", response.data.endTimeHour);
            formik.setFieldValue("endTimeMin", response.data.endTimeMin);
            formik.setFieldValue("forceExactBreaks", response.data.forceExactBreaks);
            formik.setFieldValue("tags", response.data.tags);
            var breaks = [];
            response.data.timeTrackingBreaks.map((v, i) => {
                v.day = new Date(v.day)
                breaks.push(v);
            })
            formik.setFieldValue("timeTrackingBreaks", breaks)

        }
    }
    const getEmployeeworkspacesDD = async () => {
        var response = await CallAxios("/api/Employee/GetEmplyeeWorkSpaceDD|post", { id: user.LoginUserId });
        if (!!response && response.data.length > 0) {
            setWorkspaceDD(response.data)
            formik.setFieldValue("workSpaceID", response.data[0].value)
        }
    }
    const formik = useFormik({
        initialValues: {
            employeeID: "",
            workSpaceID: "",
            day: new Date(),
            startTimeHour: "8",
            startTimeMin: "00",
            endTimeHour: "16",
            endTimeMin: "00",
            forceExactBreaks: false,
            tags: [],
            timeTrackingBreaks: []
        },
        validationSchema: Yup.object().shape({
            day: Yup.date().typeError("Invalid Date").required(tpt('errorMsg.invalidDate')),
            // absenceTypeId: Yup.string().typeError("Please select absence type").required("Please select absence type"),
            // startDate: Yup.date().typeError("Invalid Date").required("For absences longer than one year we can not generate a suggestion."),
            // endDate: Yup.date().typeError("Invalid Date").required("For absences longer than one year we can not generate a suggestion."),
            // correctedhours: Yup.number().typeError("Corrected hours must be a number").required("Please enter corrected hours"),
            // holidays: Yup.number().typeError("Vacation days must be a number").required("Please enter vacation days")
        }),
        onSubmit: values => {
            var startTime = moment(values.day).startOf('day')
                .add(parseInt(values.startTimeHour), 'hours')
                .add(parseInt(values.startTimeMin), 'minutes');

            var endTime = moment(values.day).startOf('day')
                .add(parseInt(values.endTimeHour), 'hours')
                .add(parseInt(values.endTimeMin), 'minutes');
            if (startTime <= new Date() && endTime <= new Date()) {
                var data = {
                    employeeID: values.employeeID,
                    workSpaceID: values.workSpaceID,
                    day: values.day,
                    startTimeHour: parseInt(values.startTimeHour),
                    startTimeMin: parseInt(values.startTimeMin),
                    endTimeHour: parseInt(values.endTimeHour),
                    endTimeMin: parseInt(values.endTimeMin),
                    timeTrackingBreaks: values.timeTrackingBreaks,
                    forceExactBreaks: values.forceExactBreaks,
                    tags: values.tags,
                    startTime: startTime,
                    endTime: endTime,
                    iD: props.timeTrackIdForEdit,
                };
                if (data.timeTrackingBreaks.length > 0 && data.timeTrackingBreaks.some(x => x.day === null)) {
                    Swal.fire({
                        icon: 'info',
                        title: tpt('errorMsg.startEndNotValidDate'),
                    })
                    return;
                }
                if (!!data.timeTrackingBreaks && data.timeTrackingBreaks.length > 0) {
                    data.timeTrackingBreaks.map((val, key) => {
                        data.timeTrackingBreaks[key].endTimeHour = data.timeTrackingBreaks[key].endTimeHour.toString();
                        data.timeTrackingBreaks[key].endTimeMin = data.timeTrackingBreaks[key].endTimeMin.toString();
                        data.timeTrackingBreaks[key].startTimeHour = data.timeTrackingBreaks[key].startTimeHour.toString();
                        data.timeTrackingBreaks[key].startTimeMin = data.timeTrackingBreaks[key].startTimeMin.toString();
                        data.timeTrackingBreaks[key].startTime = moment(data.timeTrackingBreaks[key].day).startOf('day')
                            .add(data.timeTrackingBreaks[key].startTimeHour, 'hours')
                            .add(data.timeTrackingBreaks[key].startTimeMin, 'minutes');
                        data.timeTrackingBreaks[key].endTime = moment(data.timeTrackingBreaks[key].day).startOf('day')
                            .add(data.timeTrackingBreaks[key].endTimeHour, 'hours')
                            .add(data.timeTrackingBreaks[key].endTimeMin, 'minutes');
                    })
                }
                submit(data);
            }
            else {
                Swal.fire({
                    icon: 'info',
                    title: tpt('errorMsg.startEndFutherDate'),
                })
            }
        },
    });
    const submit = async (data) => {
        var startdaydate = moment(data.day).startOf('day')
            .add(parseInt(formik.values.startTimeHour), 'hours')
            .add(parseInt(formik.values.startTimeMin), 'minutes');
        var enddaydate = moment(data.day).startOf('day')
            .add(parseInt(formik.values.endTimeHour), 'hours')
            .add(parseInt(formik.values.endTimeMin), 'minutes');
        if (startdaydate <= new Date() && enddaydate <= new Date()) {

            if (data.timeTrackingBreaks.length > 0 && data.timeTrackingBreaks.some(x => x.day === null)) {
                Swal.fire({
                    icon: 'info',
                    title: tpt('errorMsg.startEndNotValidDate'),
                })
                return;
            }
            var startTime = (parseInt(formik.values.startTimeHour) * 60) + parseInt(formik.values.startTimeMin);
            var endTime = (parseInt(formik.values.endTimeHour) * 60) + parseInt(formik.values.endTimeMin);
            var TotalWorkingTime = (endTime - startTime);
            var TotalBreakTime = 0;
            // var isAllBreaksWithinRange = true;

            if (data.timeTrackingBreaks.length > 0) {
                if (data.timeTrackingBreaks.find(x => x.day.toDateString() !== data.day.toDateString()) === undefined) {
                    data.timeTrackingBreaks.map((val, key) => {
                        var Breakstart = (parseInt(val.startTimeHour) * 60) + parseInt(val.startTimeMin);
                        var Breakend = (parseInt(val.endTimeHour) * 60) + parseInt(val.endTimeMin);
                        TotalBreakTime = TotalBreakTime + (Breakend - Breakstart);
                        if (Breakstart < startTime || Breakstart > endTime || Breakend < startTime || Breakend > endTime) {
                            // isAllBreaksWithinRange = false;
                            Swal.fire({
                                icon: 'error',
                                title: tpt('errorMsg.pauseStartEndTimeNotDuration'),
                            });
                            throw { message: 'Pause start time is not within time tracking duration. Pause end time is not within time tracking duration. Starts may not be in future. Ends may not be in future.' }

                        }
                    })
                } else {
                    // isAllBreaksWithinRange = false;
                    Swal.fire({
                        icon: 'error',
                        title: tpt('errorMsg.pauseStartEndTimeNotDuration'),
                    });
                    throw { message: 'Pause start time is not within time tracking duration. Pause end time is not within time tracking duration. Starts may not be in future. Ends may not be in future.' }

                }
            }
            // var filterbreks = [];

            var breaksList = [];
            data.timeTrackingBreaks.map((val, key) => {
                var obj = {
                    start: moment(val.day).startOf("day").add('hours', val.startTimeHour).add('minutes', val.startTimeMin),
                    end: moment(val.day).startOf("day").add('hours', val.endTimeHour).add('minutes', val.endTimeMin),
                }
                breaksList.push(obj)
            })
            breaksList.sort((x, y) => {
                if (x.start > y.start) {
                    return 1
                } else if (x.start < y.start) {
                    return -1
                } else {
                    return 0
                }
            })

            breaksList.map((val, key) => {
                if (key < breaksList.length - 1) {
                    if ((val.start > breaksList[key + 1].start || val.end > breaksList[key + 1].start)) {
                        Swal.fire({
                            icon: 'error',
                            title: tpt('errorMsg.breaksOverlapingShift'),
                        })
                        // IsbreakOverlapping = true;
                        throw { message: 'Breaks overlaping with another break in this shift.' }
                    }
                }
            })

            // if (isAllBreaksWithinRange === true) {
            if (TotalWorkingTime >= TotalBreakTime) {

                var Ovrlapresponse = await CallAxios("/api/TimeTracking/CheckTimeTrackingOverLaping|post", data);
                if ((!!Ovrlapresponse && !!Ovrlapresponse.data) || Ovrlapresponse === undefined) {
                    Swal.fire({
                        icon: 'info',
                        title: tpt('errorMsg.timeNotOverlap') + Ovrlapresponse.data.satrtDateTime + ' - ' + Ovrlapresponse.data.endDateTime,
                    })
                    return
                } else {
                    var response = await CallAxios("/api/TimeTracking/AddEditTimeTracking|post", data);
                    if (!!response && response.status === 200) {
                        // UpdateTimeTrackBadge(dispatch);
                        props.getTimeTrackList()
                        props.onHide(false)
                    }
                }
            } else {
                Swal.fire({
                    icon: 'info',
                    title: tpt('errorMsg.pauseTimeDuration'),
                })
            }
            // }
            // else {
            //     Swal.fire({
            //         icon: 'error',
            //         title: 'Pause start time is not within time tracking duration. Pause end time is not within time tracking duration.',
            //     })
            // }
        }
        else {
            Swal.fire({
                icon: 'info',
                title: tpt('errorMsg.startEndFutherDate'),
            })
        }
    }

    return (
        <>
            {
                showDeleteModal === true ?
                    <DeleteTimeTrackingModal show={showDeleteModal} onHide={setShowDeleteModal} getTimeTrackList={props.getTimeTrackList} TimeTrackObj={formik.values} timeTrackIdForEdit={props.timeTrackIdForEdit} parentModal={props.onHide} />
                    : null
            }
            <Modal size='xl' show={props.show} onHide={() => props.onHide(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> {t('timeTracking')} {!!props.timeTrackIdForEdit ? <span className="fw-normal">({employeeName})</span> : null}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>

                        <div>
                            <Row>
                                <Col md={!!props.timeTrackIdForEdit ? 12 : 6} className=" ">
                                    {
                                        ((currentView === Constants.layoutType.Employee && (appSettings.TimetrackSettings.Employees_allowed_edit_working_area_of_time_recordings === true || !props.timeTrackIdForEdit))
                                            || currentView === Constants.layoutType.Admin) ?
                                            <Form.Group controlId="AddTimeTrackingForm.SelectWorkspace">
                                                <Form.Label> {ct('workspace')}</Form.Label>

                                                <Form.Control as="select" className="SelectbgGrey form-select" name="workSpaceID" value={formik.values.workSpaceID} onChange={formik.handleChange} custom>
                                                    {
                                                        !!workspaceDD && workspaceDD.length > 0 ?
                                                            workspaceDD.map((value, key) => {
                                                                return <option value={value.value}>{value.label}</option>
                                                            }) : null
                                                    }
                                                </Form.Control>
                                            </Form.Group>
                                            : null
                                    }
                                </Col>
                                {
                                    !props.timeTrackIdForEdit && !userid && currentView === Constants.layoutType.Admin ?
                                        <Col md={6} className=" ps-0">
                                            <Form.Group controlId="AddTimeTrackingForm.SelectEmployee">
                                                <Form.Label> {ct('employee')}</Form.Label>
                                                <Form.Control as="select" className="SelectbgGrey form-select" name="employeeID" value={formik.values.employeeID} onChange={formik.handleChange} custom>
                                                    {
                                                        !!EmployeeDDList && EmployeeDDList.length > 0 ?
                                                            EmployeeDDList.map((value, key) => {
                                                                return <option value={value.value}>{value.label}</option>
                                                            }) : null
                                                    }
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        : null
                                }
                            </Row>
                            {
                                (currentView === Constants.layoutType.Admin && user.roles.some(x => x === Constants.roles.Admin || x === Constants.roles.WorkspaceAdmin || x === Constants.roles.AccountAdmin))
                                    || (createOwnTimeRecords === true && currentView === Constants.layoutType.Employee) ?
                                    <>
                                        <Row>
                                            <Col md={12}>  <div className="normalHeading h5 fw-bold my-3"> {ct('workingHours')}</div></Col>
                                            <Col md={4} >
                                                <Form.Group controlId="AddTimeTrackingForm.SelectDay">
                                                    <Form.Label> {ct('day')}</Form.Label>
                                                    <DatePicker className="form-control" selected={formik.values.day} name="day" onChange={(date) => formik.setFieldValue('day', date)} />

                                                    {/* <AppDatePicker PlaceholderText=""  />  */}
                                                </Form.Group>
                                            </Col>
                                            <Col md={4} >
                                                <Form.Group controlId="AddTimeTrackingForm.txtBegin">
                                                    <Form.Label> {ct('begin')}</Form.Label>
                                                    <div className="flexInputGroup d-flex align-items-center ">
                                                        <Form.Control as="select" className="Noarrow bgPrimaryGrey" value={formik.values.startTimeHour} name="startTimeHour" onChange={formik.handleChange} custom>
                                                            {
                                                                Constants.hoursDropDown.map((val, key) => {
                                                                    return <option key={"startTimeHour" + key} value={val.value}>{val.text}</option>
                                                                })
                                                            }
                                                        </Form.Control>
                                                        <span className="seperator mx-2">:</span>
                                                        <Form.Control as="select" className="Noarrow bgPrimaryGrey" value={formik.values.startTimeMin} name="startTimeMin" onChange={formik.handleChange} custom>
                                                            {
                                                                Constants.minutesDropDown.map((val, key) => {
                                                                    return <option key={"startTimeMin" + key} value={val.value}>{val.text}</option>
                                                                })
                                                            }
                                                        </Form.Control>
                                                    </div>

                                                </Form.Group>
                                            </Col>
                                            <Col md={4} >
                                                <Form.Group controlId="AddTimeTrackingForm.txtEndTime">
                                                    <Form.Label> {ct('end')}</Form.Label>
                                                    <div className="flexInputGroup d-flex align-items-center">
                                                        <Form.Control as="select" className="Noarrow bgPrimaryGrey" name="endTimeHour" value={formik.values.endTimeHour} onChange={formik.handleChange} custom>
                                                            {
                                                                Constants.hoursDropDown.map((val, key) => {
                                                                    return <option key={"endTimeHour" + key} value={val.value}>{val.text}</option>
                                                                })
                                                            }
                                                        </Form.Control>
                                                        <span className="seperator mx-2">:</span>
                                                        <Form.Control as="select" className="Noarrow bgPrimaryGrey" name="endTimeMin" value={formik.values.endTimeMin} onChange={formik.handleChange} custom>
                                                            {
                                                                Constants.minutesDropDown.map((val, key) => {
                                                                    return <option key={"endTimeMin" + key} value={val.value}>{val.text}</option>
                                                                })
                                                            }
                                                        </Form.Control>
                                                    </div>

                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12} className="border-bottom">
                                                {
                                                    !!props.timeTrackIdForEdit && showBreakDetail === false ?
                                                        <Col md={12} className="border-bottom py-3">
                                                            <div> {t('timeTrackTab.timeTrackMdl.pauseTimeRecording')}<span className="fw-bold"> {totalbreackMinutes} </span>{ct('minutes')}.</div>
                                                            <div><a className="highlitedLink AddComponentLink" onClick={() => setShowBreakDetail(true)}> {ct('showDetail')}</a></div>
                                                        </Col>
                                                        : null
                                                }
                                                {appSettings.TimetrackSettings.Resulting_pause_at_least_as_long_as_automatic_pause === true && currentView === Constants.layoutType.Admin &&
                                                    ((!props.timeTrackIdForEdit && showBreakDetail === false) || (!!props.timeTrackIdForEdit && showBreakDetail === true)) ?
                                                    <Form.Group className="mb-0 mt-3 d-flex align-items-center">
                                                        <Form.Label className='me-2'> {t('timeTrackTab.timeTrackMdl.forceExactBreaks')}</Form.Label>
                                                        <Form.Check className="smallTextControl text-danger" name="forceExactBreaks" onChange={(e) => formik.setFieldValue("forceExactBreaks", !formik.values.forceExactBreaks)} inline label={t('timeTrackTab.timeTrackMdl.forceExactBreaksLabel')} type="checkbox" id="AnsenceAllDay" />
                                                    </Form.Group>
                                                    : null
                                                }
                                                {
                                                    (!!props.timeTrackIdForEdit && showBreakDetail === true) || (!props.timeTrackIdForEdit && showBreakDetail === false) ?
                                                        <TimeTrackingBreaksRepeter formik={formik} DayDate={formik.values.day} StartHours={formik.values.startTimeHour} StartMin={formik.values.startTimeMin} EndHours={formik.values.endTimeHour} EndMin={formik.values.endTimeMin} />
                                                        : null
                                                }
                                            </Col>
                                        </Row>
                                    </>
                                    : null
                            }
                            <Row>
                                {
                                    currentView === Constants.layoutType.Employee &&
                                        appSettings.TimetrackSettings.Employees_allowed_to_tag_time_records === false ? null :
                                        <>
                                            {
                                                showTagsField === false ?
                                                    <Col md={12}>
                                                        <div className="py-4 " onClick={() => setShowTagsField(true)}>
                                                            <div>
                                                                <div className="flexInputGroup">
                                                                    <div className="ActionGreyText"> {t('timeTrackTab.timeTrackMdl.showFieldsTags')} <BsChevronDown className="CloseActionText" /></div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    : null
                                            }
                                            {
                                                showTagsField === true ?
                                                    <Col md={12}>
                                                        <div className="py-4">
                                                            <Form.Group controlId="AddTimeTrackingForm.txtEditTags">
                                                                <Form.Label>{ct('tags')}</Form.Label>
                                                                <CreatableSelect
                                                                    classNamePrefix="MyReactSelect"
                                                                    isMulti
                                                                    isClearable={false}
                                                                    components={{ DropdownIndicator }}
                                                                    options={tagsDD}
                                                                    getOptionValue={(option) => option.value}
                                                                    value={tagsDD.filter(x => formik.values.tags.some(c => c === x.value))}
                                                                    styles={{
                                                                        indicatorSeparator: (base) => ({
                                                                            ...base,
                                                                            display: "none",
                                                                        }),
                                                                    }}

                                                                    onChange={async (value, action) => {
                                                                        if ((user.roles.length === 1 && user.roles.some(x => x === Constants.roles.Employee) && appSettings.TimetrackSettings.Employees_can_create_own_tags === true
                                                                            || user.roles.some(x => x === Constants.roles.Admin || x === Constants.roles.WorkspaceAdmin || x === Constants.roles.AccountAdmin))) {

                                                                            var newtag = value.find(x => x.__isNew__ === true);
                                                                            if (!!newtag) {
                                                                                var data = {
                                                                                    tagname: newtag.label
                                                                                }
                                                                                var response = await CallAxios("/api/LocationSetting/addeditTagFromFilter|post", data)
                                                                                if (!!response && response.status === 200) {
                                                                                    var newobj = { value: response.data, label: newtag.label }
                                                                                    value.splice(value.findIndex(x => x.__isNew__ === true), 1)
                                                                                    value.push(newobj)
                                                                                    tagsDD.push(newobj)
                                                                                }
                                                                            }
                                                                        }
                                                                        let TagIdsList = [];
                                                                        for (let i = 0; i < value.length; i++) {
                                                                            TagIdsList.push(value[i].value)
                                                                        }
                                                                        formik.setFieldValue("tags", TagIdsList)
                                                                    }
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </div>

                                                    </Col>
                                                    : null
                                            }
                                        </>
                                }

                            </Row>
                        </div>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {
                        currentView === Constants.layoutType.Admin && user.roles.some(x => x === Constants.roles.Admin || x === Constants.roles.WorkspaceAdmin || x === Constants.roles.AccountAdmin) &&
                            !!props.timeTrackIdForEdit ?
                            <div><a className="LinkColorRed" onClick={() => setShowDeleteModal(true)}> {t('timeTrackTab.timeTrackMdl.deleteTimeRecording')}</a></div>
                            : null
                    }

                    <Button className="btn btnPrimaryGreen" onClick={formik.handleSubmit}> {ct('finished')}</Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}

function DeleteTimeTrackingModal(props) {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');

    const user = useSelector(x => x.app.user);

    const deletetimetrack = async (id) => {
        var data = {
            id: props.timeTrackIdForEdit
        }
        var response = await CallAxios("/api/TimeTracking/deleteTimeTracking|post", data);
        if (!!response && response.status === 200) {
            // UpdateTimeTrackBadge(dispatch)
            props.getTimeTrackList()
            props.parentModal(false)
        }
    }
    return (
        <Modal show={props.show} onHide={() => props.onHide(false)}>
            <Modal.Header closeButton>
                <Modal.Title> {t('timeTrackTab.confirmDeleteMdl.confirmDeletionTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col md={12}>
                            <Container fluid>
                                <Row>
                                    <Col md={12} >
                                        {t('timeTrackTab.confirmDeleteMdl.confirmDeletionPara')} {moment(props.TimeTrackObj.day).format("DD/MM/YYYY")} {props.TimeTrackObj.startTimeHour}:{props.TimeTrackObj.startTimeMin} - {props.TimeTrackObj.endTimeHour}:{props.TimeTrackObj.endTimeMin} {ct('of')} {user.LocationName}?
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between align-items-center">
                <Button className="btn btn-secondary" onClick={() => props.onHide(false)}> {ct('no')}</Button>
                <Button className="btn btn-danger" onClick={() => deletetimetrack()}> {t('timeTrackTab.confirmDeleteMdl.yesRemoveNow')} </Button>
            </Modal.Footer>
        </Modal>
    )
}

function TimeTrackingTimer(props) {
    const { t: tpt } = useTranslation('timeTrackingPage');
    const { t: ct } = useTranslation('common');

    const [WorkingTime, setWorkingTime] = useState(null);
    const [BreakTime, setBreakTime] = useState(null);
    const [TimeTrackId, setTimeTrackId] = useState(0);
    const [RunningStatus, setRunningStatus] = useState("");
    const user = useSelector(x => x.app.user);
    useEffect(() => {
        if (!!user.EncLoginUserId) {
            GetTimeTrackDetail()
        }
    }, [user.EncLoginUserId]);
    const GetTimeTrackDetail = async () => {
        var response = await CallAxios('/api/TimeTracking/EmployeeDetail|post', { id: user.EncLoginUserId })
        if (!!response && response.data === "") {
            setRunningStatus("")
            setWorkingTime(null)
            setBreakTime(null)
            setTimeTrackId(0)
        }
        else {
            setWorkingTime(response.data.workingTimeDuration)
            setBreakTime(response.data.breakDuration)
            setTimeTrackId(response.data.timetrackId)
            setRunningStatus(response.data.runningStatus)
        }
    }
    const StartTracking = async () => {
        var data = {
            id: user.EncLoginUserId,
            TrackingViea: 'Browser-Stamp',
            CurrentDatetime: moment().format('YYYY/MM/DD HH:mm:ss')
        }
        var form_data = new FormData();
        Object.keys(data).forEach((key, index) => {
            if (typeof (data[key]) === "object" && !!data[key] && data[key].length > 0) {
                for (var i = 0; i < data[key].length; i++) {
                    form_data.append(key + [], data[key][i]);
                }
            } else if (data[key]) {
                form_data.append(key, data[key]);
            }
        })
        var response = await CallAxios('/api/TimeTracking/StartTimeTrcking|post', form_data)
        if (!!response && response.data === true) {
            props.getTrackingViaDetail()
            props.getTimeTrackList(props.PaginateInfo)
            GetTimeTrackDetail()
        } else {
            toast.error(tpt('errorMsg.trackingRoster'))
        }

    }
    const StartPauseTracking = async (timetrackid, status) => {
        var data = {
            id: timetrackid.toString(),
            CurrentDatetime: moment().format('YYYY/MM/DD HH:mm:ss')
        }
        var form_data = new FormData();
        Object.keys(data).forEach((key, index) => {
            if (typeof (data[key]) === "object" && !!data[key] && data[key].length > 0) {
                for (var i = 0; i < data[key].length; i++) {
                    form_data.append(key + [], data[key][i]);
                }
            } else if (data[key]) {
                form_data.append(key, data[key]);
            }
        })
        var response = await CallAxios('/api/TimeTracking/StopStartTimeTrcking|post', form_data)
        if (!!response && !!response.data) {
            props.getTrackingViaDetail()
            props.getTimeTrackList(props.PaginateInfo)
            GetTimeTrackDetail()
        }
        else {
            toast.error(tpt('errorMsg.trackingRoster'))
        }
    }
    const EndTracking = async (timetrackid, status) => {
        var data = {
            id: timetrackid.toString(),
            CurrentDatetime: moment().format('YYYY/MM/DD HH:mm:ss')
        }
        var form_data = new FormData();
        Object.keys(data).forEach((key, index) => {
            if (typeof (data[key]) === "object" && !!data[key] && data[key].length > 0) {
                for (var i = 0; i < data[key].length; i++) {
                    form_data.append(key + [], data[key][i]);
                }
            } else if (data[key]) {
                form_data.append(key, data[key]);
            }
        })
        var response = await CallAxios('/api/TimeTracking/FinishTimeTrcking|post', form_data)
        if (!!response && response.data === true) {
            props.getTrackingViaDetail()
            props.getTimeTrackList(props.PaginateInfo)
            GetTimeTrackDetail()
        }
        else {
            toast.error(tpt('errorMsg.trackingRoster'))
        }
    }
    return (
        <Row className='text-left'>
            <Col md={4} className='p-3' style={{ backgroundColor: 'lightgray' }}>
                <Col md={12}>
                    <h4> Stempeluhar</h4>
                </Col>
                {
                    RunningStatus === "Start" ?
                        <>
                            <Col md={12}>
                                <Button className="btn btn-secondary" onClick={() => StartPauseTracking(TimeTrackId, "startPause")}>Pause</Button>
                            </Col>
                            <Col md={12} className='pt-2'>
                                <Button className="btn btn-danger" onClick={() => EndTracking(TimeTrackId, "endtracking")}> {tpt('timeTrackingTimer.endTimeTracking')} </Button>
                            </Col>
                            <Col md={12} className='text-center pt-2'>
                                {
                                    !!WorkingTime ?
                                        RunningStatus === "Start" ?
                                            <LiveClock WorkingTime={WorkingTime} />
                                            :
                                            (moment(WorkingTime, 'HH:mm:ss').format('H') > 0 ? moment(WorkingTime, 'HH:mm:ss').format('H') + " hours, " : "") + (moment(WorkingTime, 'HH:mm:ss').format('m') > 0 ? moment(WorkingTime, 'HH:mm:ss').format('m') + " minutes, " : "") + moment(WorkingTime, 'HH:mm:ss').format('s') + " seconds"
                                        : null
                                }
                            </Col>
                            <Col md={12} className='text-center pt-2'>
                                Pause:
                                {
                                    !!BreakTime ?
                                        RunningStatus === "Stop" ?
                                            <LiveClock WorkingTime={BreakTime} />
                                            :
                                            (moment(BreakTime, 'HH:mm:ss').format('H') > 0 ? moment(BreakTime, 'HH:mm:ss').format('H') + " hours, " : "") + (moment(BreakTime, 'HH:mm:ss').format('m') > 0 ? moment(BreakTime, 'HH:mm:ss').format('m') + " minutes, " : "") + moment(BreakTime, 'HH:mm:ss').format('s') + " seconds,"
                                        : null
                                }
                            </Col>
                        </>
                        :
                        RunningStatus === "Stop" ?
                            <>
                                <Col md={12}>
                                    <Button className="btn btn-secondary" onClick={() => StartPauseTracking(TimeTrackId, "endPause")}> {ct('continue')}</Button>
                                </Col>
                                <Col md={12} className='text-center pt-2'>
                                    {
                                        !!WorkingTime ?
                                            RunningStatus === "Start" ?
                                                <LiveClock WorkingTime={WorkingTime} />
                                                :
                                                (moment(WorkingTime, 'HH:mm:ss').format('H') > 0 ? moment(WorkingTime, 'HH:mm:ss').format('H') + " hours, " : "") + (moment(WorkingTime, 'HH:mm:ss').format('m') > 0 ? moment(WorkingTime, 'HH:mm:ss').format('m') + " minutes, " : "") + moment(WorkingTime, 'HH:mm:ss').format('s') + " seconds,"
                                            : null
                                    }
                                </Col>
                                <Col md={12} className='text-center pt-2'>
                                    Pause:
                                    {
                                        !!BreakTime ?
                                            RunningStatus === "Stop" ?
                                                <LiveClock WorkingTime={BreakTime} />
                                                :
                                                (moment(BreakTime, 'HH:mm:ss').format('H') > 0 ? moment(BreakTime, 'HH:mm:ss').format('H') + " hours, " : "") + (moment(BreakTime, 'HH:mm:ss').format('m') > 0 ? moment(BreakTime, 'HH:mm:ss').format('m') + " minutes, " : "") + moment(BreakTime, 'HH:mm:ss').format('s') + " seconds,"
                                            : null
                                    }
                                </Col>
                            </>
                            :
                            <Col md={12}>
                                <Button className="btn btn-success" onClick={() => StartTracking()}> {tpt('timeTrackingTimer.startTimeTracking')}</Button>
                            </Col>
                }

            </Col>
            <Col md={8}>
            </Col>
        </Row>
    )
}

export function LiveClock(props) {
    const spanRef = React.useRef();

    const timerupdate = (date) => {
        if (!!spanRef.current) {

            let newDate = date.add(1, "seconds");
            spanRef.current.innerHTML = `${newDate.format("HH")} hours, ${newDate.format("mm")} minutes, ${newDate.format("ss")} seconds`;
        }
    }

    useEffect(() => {
        if (!!props.WorkingTime) {
            let date = moment().set({ "hour": moment(props.WorkingTime, 'HH:mm:ss.t').format('H'), "minute": moment(props.WorkingTime, 'HH:mm:ss.t').format('m'), "seconds": moment(props.WorkingTime, 'HH:mm:ss.t').format('s') });
            // let date = moment().set({"hour":1,"minute":10,"seconds":20});
            setInterval(() => {
                timerupdate(date)
            }, 1000);
        }
    }, [props.WorkingTime]);

    return (
        <span ref={spanRef}></span>
    );
}