import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { FaPlus } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { Gear, Remove } from '../../Components/ActionBtns';
import HeaderButton from '../../Components/HeaderButton';
import { AxiosLite, CallAxios } from "../../Helpers";
import { TableWrapper } from '../../Helpers/Global/StyledComponents';
import { useTranslation } from "react-i18next";
import DeleteConfirmationModal from '../../Components/DeleteConfirmationModal';


function AvailibilityValidations(props) {
    const { t: ast } = useTranslation('accountSetting');
    const { t: ct } = useTranslation('common');


    const [AddEditConditionForAvailabilityModal, setAddEditConditionForAvailabilityModal] = useState(false);
    const [ConditionsAvailability, setConditionsAvailability] = useState([]);
    const [ConditionsAvailabilityForditID, setConditionsAvailabilityForditID] = useState("");
    const [ShowDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);


    useEffect(() => {
        ConditionsAvailabilityList()
    }, []);

    const ConditionsAvailabilityList = async () => {
        var response = await AxiosLite("/api/AccountSetting/ConditionsAvailabilityList|get");
        if (!!response) {
            setConditionsAvailability(response.data)
        }
    }

    const onDeleteSubmit = async (id) => {
        var response = await CallAxios("/api/AccountSetting/ConditionsAvailabilityDelete|post", { id: id })
        if (!!response && response.data === true) {
            ConditionsAvailabilityList();
            setShowDeleteConfirmationModal(false)
        }
    }

    return (
        <>
            {
                ShowDeleteConfirmationModal &&
                <DeleteConfirmationModal show={ShowDeleteConfirmationModal} onHide={setShowDeleteConfirmationModal}
                    title={ast('availabilityTab.deleteMdl.title')} message={ast('availabilityTab.deleteMdl.desc')} deletefn={onDeleteSubmit} objId={ConditionsAvailabilityForditID} />
            }
            <Row>
                <Col md={12}>
                    <div className="TabFormContainerSection">
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <div className="Heading h5 fw-bold mb-3 d-flex align-items-center"> {ast('availabilityTab.conditionsAvailability')}</div>
                            <HeaderButton title={ct('new') + ' ' + ast('availabilityTab.conditionsAvailability')} onClick={() => { setConditionsAvailabilityForditID(""); setAddEditConditionForAvailabilityModal(true) }} icon={<FaPlus color='#fff' size={18} />} />
                        </div>
                        <p>{ast('availabilityTab.para')}.</p>
                        <TableWrapper className="mt-4">
                            <Table striped bordered responsive className="DataGridTable overflowVisible">
                                <thead>
                                    <tr className="firsthead">
                                        <th>{ast('availabilityTab.rule')}</th>
                                        <th className="text-right"> {ct('action')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !!ConditionsAvailability && ConditionsAvailability.length > 0 ? ConditionsAvailability.map((value, key) => {
                                            return <tr>
                                                <td>
                                                    {value.rule === "AvailabilitiesValidationRules::MinimumDuration" ? <>{ast('availabilityTab.leastDuration') + ' ' + `${value.worth}` + ' ' + ast('availabilityTab.hours')} </> : <>{ast('availabilityTab.longDuration') + ' ' + `${value.worth}` + ' ' + ast('availabilityTab.hours')}</>}
                                                </td>
                                                <td>
                                                    <Gear onClick={(e) => { setConditionsAvailabilityForditID(value.id); setAddEditConditionForAvailabilityModal(true) }} />
                                                    <Remove onClick={(e) => { setConditionsAvailabilityForditID(value.id); setShowDeleteConfirmationModal(true) }} />
                                                </td>
                                            </tr>


                                        }) :
                                            <tr>
                                                <td colSpan="2" className="text-center"> {ast('availabilityTab.noRecordDesc')}.</td>
                                            </tr>
                                    }

                                </tbody>
                            </Table>
                        </TableWrapper>
                    </div>
                </Col>
            </Row>

            {AddEditConditionForAvailabilityModal === true ?
                <ConditionForAvailabilityModal show={AddEditConditionForAvailabilityModal} getData={ConditionsAvailabilityList} ConditionsAvailabilityForditID={ConditionsAvailabilityForditID} onHide={setAddEditConditionForAvailabilityModal} />
                : null
            }
        </>
    );
}

export default AvailibilityValidations;

function ConditionForAvailabilityModal(props) {
    const { t: ast } = useTranslation('accountSetting');
    const { t: ct } = useTranslation('common');


    const [worth, setWorth] = useState("");
    const [rule, setRule] = useState("");
    useEffect(() => {
        if (!!props.ConditionsAvailabilityForditID) {
            getdata()
        }
    }, [props.ConditionsAvailabilityForditID]);
    const getdata = async () => {
        var response = await CallAxios("/api/AccountSetting/getConditionsAvailability|post", { id: props.ConditionsAvailabilityForditID })
        if (!!response && response.data) {
            setRule(response.data.rule)
            setWorth(response.data.worth)

        }
    }
    const Submit = async (e) => {
        if (!rule) {
            return;
        }
        if (!worth) {
            alert("Duration not valid")
            return;
        }
        var data = {
            Id: props.ConditionsAvailabilityForditID,
            rule: rule,
            worth: worth
        }

        if (data.rule === "" || data.worth === "") {
            return
        }
        var response = null;
        if (!!props.ConditionsAvailabilityForditID) {
            response = await CallAxios("/api/AccountSetting/updateConditionsAvailability|post", data)
        } else {
            response = await CallAxios("/api/AccountSetting/createConditionsAvailability|post", data)
        }


        if (!!response && response.data.toString() === "true") {
            props.getData()
            props.onHide(false)
        } else if (!!response && response.data === "Exist") {
            Swal.fire(
                ast('availabilityTab.newAvalbltyMdl.errorCondition'),
            )
        }
    }
    return (
        <>
            <Modal show={props.show} onHide={() => props.onHide(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{!!props.ConditionsAvailabilityForditID ? <>{ct('edit')}</> : <>{ct('new')}</>} {ast('availabilityTab.conditionsAvailability')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={10}>
                                <Form.Group controlId="ConditionForAvailabilityForm.txtRule">
                                    <Form.Label> {ast('availabilityTab.rule')}</Form.Label>
                                    <Form.Control as="select" className="SelectbgGrey" name="rule" value={rule} onChange={(e) => setRule(e.target.value)} custom>
                                        <option value=""></option>
                                        <option value="AvailabilitiesValidationRules::MinimumDuration"> {ast('availabilityTab.newAvalbltyMdl.ruleOption1')}.</option>
                                        <option value="AvailabilitiesValidationRules::DurationMustNotExceed"> {ast('availabilityTab.newAvalbltyMdl.ruleOption2')}.</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={2} >
                                <Form.Group controlId="ConditionForAvailabilityForm.txtValue">
                                    <Form.Label>{ast('availabilityTab.newAvalbltyMdl.value')}</Form.Label>
                                    <Form.Control name="worth" onChange={(e) => setWorth(e.target.value)} value={worth} type="text" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btnPrimaryGreen" onClick={() => Submit()} > {ct('finished')} </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}