import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { Col, Modal, Row, Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';
import { FaDownload } from "react-icons/fa";
import * as Yup from "yup";
import { Gear, Remove } from "../../../Components/ActionBtns";
import CollapseableDiv from "../../../Components/CollapseCard";
import { AxiosLite, CallAxios } from "../../../Helpers";
import { TableWrapper } from "../../../Helpers/Global/StyledComponents";
import { useTranslation } from "react-i18next";
import DeleteConfirmationModal from "../../../Components/DeleteConfirmationModal";


function PublicHolidays() {
    const { t: Lst } = useTranslation('locationSetting');
    const { t: ct } = useTranslation('common');


    const [showmodal, setshowmodal] = useState(false);
    const [publicholidaayperiodModal, setpublicholidaayperiodModal] = useState(false);
    const [PublicHolidayList, setPublicHolidayList] = useState([]);
    const [PublicHolidayEditId, setPublicHolidayEditId] = useState("");
    const [ShowDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

    useEffect(() => {
        gteList()
    }, []);

    const gteList = async () => {
        var response = await AxiosLite("/api/LocationSetting/GetPublicHolidayList|get")
        if (!!response && response.status === 200) {
            setPublicHolidayList(response.data)
        }
    }
    const onDeleteSubmit = async (id) => {
        var response = await CallAxios("/api/LocationSetting/DeletePublicHoliday|post", { id: id })
        if (!!response && response.status === 200) {
            setPublicHolidayList(response.data)
            setShowDeleteConfirmationModal(false)
        }
    }
    const EditModel = (id) => {
        setPublicHolidayEditId(id)
        setshowmodal(true)
    }


    return (
        <>
            {
                ShowDeleteConfirmationModal &&
                <DeleteConfirmationModal show={ShowDeleteConfirmationModal} onHide={setShowDeleteConfirmationModal}
                    title={Lst('absenceTab.publicAccor.deleteMdl.title')} message={Lst('absenceTab.publicAccor.deleteMdl.desc')} deletefn={onDeleteSubmit} objId={PublicHolidayEditId} />
            }
            <CollapseableDiv title={Lst('absenceTab.publicHolidays')}>
                {
                    showmodal === true ?
                        <AddEditPublicHolidayModal show={showmodal} setshow={setshowmodal} setPublicHolidayList={setPublicHolidayList} PublicHolidayEditId={PublicHolidayEditId} setPublicHolidayEditId={setPublicHolidayEditId} />
                        : null
                }
                {
                    publicholidaayperiodModal === true ?
                        <PublicHolidayPeriod gteList={gteList} show={publicholidaayperiodModal} setshow={setpublicholidaayperiodModal} />
                        : null
                }

                <Row>
                    <Col md={12}>
                        <div className="TabFormContainerSection">
                            <div className='d-flex mb-4 justify-content-between align-items-center'>
                                <p className="text-left w-75">
                                    {Lst('absenceTab.publicAccor.desc')}.
                                </p>
                                <div>
                                    <button type="button" className="btn  btn-icon me-2 bgBlue" onClick={(e) => setpublicholidaayperiodModal(true)}><FaDownload /></button>
                                    <button type="button" className="btn btnPrimaryGreen btn-primary" onClick={(e) => { setPublicHolidayEditId(""); setshowmodal(true) }}> {Lst('absenceTab.publicAccor.createPublicHoliday')} </button>
                                </div>

                            </div>
                            <TableWrapper>


                                <Table striped bordered responsive className=" table table-striped table-bordered DataGridTable text-left overflowVisible">
                                    <thead>
                                        <tr className='firsthead'>
                                            <th> {ct('name')}</th>
                                            <th> {ct('date')}</th>
                                            <th> {Lst('absenceTab.publicAccor.calculation')}</th>
                                            <th> {ct('actions')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !!PublicHolidayList && PublicHolidayList.length > 0 ? PublicHolidayList.map((val, index) => {
                                                return <tr key={"tableList_" + index}>

                                                    <td>
                                                        <div className="dataTextCell">{val.name}</div>
                                                    </td>
                                                    <td>
                                                        <div className="dataTextCell">{moment(val.holidayDate).format('MM/DD/YYYY')}</div>
                                                    </td>
                                                    <td>
                                                        <div className="dataTextCell">{val.biling === true ? <>{Lst('absenceTab.publicAccor.halfDay')}</> : <>{Lst('absenceTab.publicAccor.allDay')}</>}</div>
                                                    </td>
                                                    <td>
                                                        <Gear onClick={(e) => EditModel(val.id)} />
                                                        <Remove onClick={(e) => { setPublicHolidayEditId(val.id); setShowDeleteConfirmationModal(true) }} />
                                                    </td>
                                                </tr>
                                            }) : null
                                        }


                                    </tbody>
                                </Table>
                            </TableWrapper>
                        </div>
                    </Col>
                </Row>
            </CollapseableDiv>

        </>
    );
}

export default PublicHolidays;

function AddEditPublicHolidayModal(props) {
    const { t: Lst } = useTranslation('locationSetting');
    const { t: ct } = useTranslation('common');


    useEffect(() => {
        if (!!props.PublicHolidayEditId) {
            getData()
        }
    }, []);
    const getData = async () => {
        var data = {
            id: props.PublicHolidayEditId
        };
        var response = await CallAxios(
            "/api/LocationSetting/getPublicHoliday|post", data
        );
        if (!!response && response.status === 200) {
            formik.setFieldValue("Surname", response.data.name)
            formik.setFieldValue("Day", new Date(response.data.holidayDate))
            formik.setFieldValue("half_day", response.data.biling)
        }
    }
    const formik = useFormik({
        initialValues: {
            Surname: "",
            Day: new Date(),
            half_day: false
        },
        validationSchema: Yup.object().shape({
            Surname: Yup.string().required(ct('titleRequire')),
            Day: Yup.date().required(Lst('absenceTab.publicAccor.dayRequire'))
        }),
        onSubmit: values => {
            submit(values)
        },
    });
    const submit = async (values) => {
        var data = {
            Id: props.PublicHolidayEditId,
            name: values.Surname,
            HolidayDate: values.Day,
            biling: values.half_day,
        };

        var response = await CallAxios(
            "/api/LocationSetting/AddEditPublicHoliday|post", data
        );

        if (!!response && response.status === 200) {
            props.setPublicHolidayList(response.data)
            props.setshow(false)
        }
    };
    return (
        <Modal show={props.show} className="" onHide={() => props.setshow(false)}>
            <Modal.Header closeButton onClick={() => props.setshow(false)} className="">
                <Modal.Title>{!!props.PublicHolidayEditId ? <>{ct('edit')}</> : <>{ct('create')}</>} {Lst('absenceTab.publicHolidays')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <label className="fw-bold mb-2"> {ct('title')} </label>
                            <Form.Control type="text" onChange={formik.handleChange} maxLength={150} value={formik.values.Surname} name="Surname" className="ThickBorder my-0 pr40" />
                            {formik.touched.Surname && formik.errors.Surname ? (
                                <span className="error text-danger">{formik.errors.Surname}</span>
                            ) : null}
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <label className="fw-bold mb-2"> {ct('day')}</label>
                            <DatePicker className="ThickBorder form-control my-0 pr40 w-100" selected={formik.values.Day} name="Day" onChange={(date) => formik.setFieldValue("Day", date)} />
                            {formik.touched.Day && formik.errors.Day ? (
                                <span className="error text-danger">{formik.errors.Day}</span>
                            ) : null}
                        </Form.Group>
                    </Col>
                </Row>
                <Form.Group className="mb-1" controlId={"half_day"}>
                    <Form.Check className="text-left"
                        name={"half_day"} onChange={formik.handleChange} checked={formik.values.half_day} type="checkbox" label={Lst('absenceTab.publicAccor.halfDay')} />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-primary btnPrimaryGreen" onClick={formik.handleSubmit}>{ct('ready')}</button>
            </Modal.Footer>
        </Modal>
    );
}
function PublicHolidayPeriod(props) {
    const { t: Lst } = useTranslation('locationSetting');
    const { t: ct } = useTranslation('common');


    const newdate = new Date();
    const formik = useFormik({
        initialValues: {
            country: "de",
            region: "",
            begin: newdate,
            end: newdate
        },

        onSubmit: values => {
            Submit(values)
        },
    });
    const Submit = async (values) => {

        var data = {
            CountryCode: values.country,
            StartDate: moment(values.begin).startOf('day'),
            EndDate: moment(values.end).startOf('day')
        }
        var response = await CallAxios(
            "/api/LocationSetting/ImportHolidays|post", data
        );
        if (!!response && response.status === 200) {
            props.gteList()
        }
    }

    return (
        <Modal show={props.show} className="" onHide={() => props.setshow(false)}>
            <Modal.Header closeButton onClick={() => props.setshow(false)} className="">
                <Modal.Title> {Lst('absenceTab.publicAccor.addHolidayMdl.title')}:</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Row>
                    <Col md={12}>
                        <Form.Group className="mb-2">
                            <label> {Lst('absenceTab.publicAccor.addHolidayMdl.country')} </label>
                            <select name="country" onChange={formik.handleChange} value={formik.values.country} id="country" className="SelectbgGrey custom-select ms-2">
                                <option value="de">Germany</option>
                                <option value="at">Austria</option>
                                <option value="ch">Switzerland</option>
                                <option value="fr">France</option>
                                <option value="gb_eng">England</option>
                                <option value="es">Spain</option>
                                <option value="it">Italy</option>
                                <option value="nl">Netherlands</option>
                                <option value="gb_sct">Scotland</option>
                                <option value="gb_wls">Wales</option>
                                <option value="ie">Ireland</option>
                                <option value="lu">Luxembourg</option>
                                <option value="mt">Malta</option>
                                <option value="gb_nir">Northern Ireland</option></select>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group>
                            <label> {Lst('absenceTab.publicAccor.addHolidayMdl.starts')}</label>
                            <DatePicker className="form-control" selected={formik.values.begin} name="begin" onChange={(date) => formik.setFieldValue("begin", date)} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group>
                            <label> {ct('end')}</label>
                            <DatePicker className="form-control" selected={formik.values.end} name="end" onChange={(date) => formik.setFieldValue("end", date)} />

                        </Form.Group>
                    </Col>
                </Row>


            </Modal.Body>
            <Modal.Footer>
                <button className="btn btnPrimaryGreen" onClick={formik.handleSubmit}> {ct('import')}</button>
            </Modal.Footer>
        </Modal>
    );
}