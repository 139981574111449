import { useState } from "react";
import { CallAxios } from "../../../Helpers";
import Swal from "sweetalert2";
import { Badge, Button, Col, Container, Modal, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { BsExclamationCircle } from "react-icons/bs";
import { MdWifiProtectedSetup } from "react-icons/md";
import moment from "moment";
import { useEffect } from "react";
import { IoReload } from "react-icons/io5";
import { GiNightSleep } from "react-icons/gi";
import { DOT } from "../../../Helpers/Global/StyledComponents";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

// const assigntofutureshift = (props) => (
//     <Tooltip id="button-tooltip" {...props}>
//         Activate recurring assignment (Adds an assignment to all future shifts)
//     </Tooltip>
// );
// const removefutureshift = (props) => (
//     <Tooltip id="button-tooltip" {...props}>
//         Disable repeat (clear this and all future assignment)
//     </Tooltip>
// );
function OccupancyModal(props) {
    const { t: cl } = useTranslation('calendarPage');
    const { t: ct } = useTranslation('common');

    const [Load_unavailable_employees, setLoad_unavailable_employees] = useState(false);
    const [Load_Employees_of_other_areas, setLoad_Employees_of_other_areas] = useState(false);
    const [unOccupiedmployeeList, setunOccupiedeemployeeList] = useState([]);
    const [OccupiedemployeeList, setOccupiedemployeeList] = useState([]);
    const [AvailibaleEmployeeList, setAvailibaleEmployeeList] = useState([]);
    const [otherAreasEmployeeList, setOtherAreasEmployeeList] = useState([]);
    const [DatetimeDetail, setDatetimeDetail] = useState("");
    const [requiredemployee, setRequiredemployee] = useState("0");
    const [Occupiedemployee, setOccupiedemployee] = useState(0);
    const [month, setMonth] = useState("");
    const [isRecursiveShift, setRecursiveShifts] = useState(false);
    const [ShiftDate, setShiftDate] = useState(null);
    const [DirectAssignPastShiftModel, setDirectAssignPastShiftModel] = useState(false);
    const [isShiftalreadyassigned, setIsShiftalreadyassigned] = useState(false);
    const [RecursivePastShiftModal, setRecursivePastShiftModal] = useState(false);
    const [IsShiftAlreadyRecursive, setIsShiftAlreadyRecursive] = useState(false);
    const [ShowExceedLimitModel, setShowExceedLimitModel] = useState(false);
    const [workarea, setWorkarea] = useState("");
    const [SelectedEmpId, setSelectedEmpId] = useState("");
    useEffect(() => {
        if (props.EventOccpancyInfo !== null) {
            getunoccupiedList()
        }
    }, [props.EventOccpancyInfo]);
    const getunoccupiedList = async () => {
        var response = await CallAxios("/api/Calendar/GetEmployeeListForoccupancy|post", { id: props.EventOccpancyInfo.shiftId })
        if (!!response && response.status === 200) {
            setunOccupiedeemployeeList(response.data.unOccupiedEmployeeList)
            setOccupiedemployeeList(response.data.occupiedEmployeeList)
            setAvailibaleEmployeeList(response.data.availibaleEmployeeList)
            setOtherAreasEmployeeList(response.data.otherAreasEmployeeList)
            setDatetimeDetail(response.data.shiftDateTimeDetail)
            setRequiredemployee(response.data.noOfEmployeerequired)
            setOccupiedemployee(response.data.noOfEmployeeOccoupaid)
            setMonth(response.data.month)
            setRecursiveShifts(response.data.isRecursiveShifts)
            setShiftDate(response.data.shiftDate)
            setWorkarea(response.data.workarea)
        }
    }
    const assignshift = async (empid) => {
        var data = {
            id: props.EventOccpancyInfo.shiftId,
            empid: empid.toString()
        }
        var Occupancyresponse = await CallAxios("/api/Calendar/CheckEmplyeeOccupancyInsamePeriod|post", data)
        if (!!Occupancyresponse && Occupancyresponse.status === 200) {
            if (Occupancyresponse.data === true) {
                Swal.fire({
                    icon: 'info',
                    title: cl("employeeAlreadyAssigned"),
                })
                return;
            }
        }

        var shiftEndTime = moment(new Date(ShiftDate).toLocaleDateString()).startOf("day").add(parseInt(DatetimeDetail.split(" - ")[1].split(":")[0]), 'hours').add(parseInt(DatetimeDetail.split(" - ")[1].split(":")[1]), 'minutes');
        if (moment().isSameOrAfter(shiftEndTime)) {
            setSelectedEmpId(empid)
            // localStorage.setItem("ClickedEmpID", empid)
            setIsShiftalreadyassigned(false)
            setDirectAssignPastShiftModel(true)
            return
        }
        var data = {
            id: props.EventOccpancyInfo.shiftId,
            empid: empid
        }
        var response = await CallAxios("/api/Calendar/assignShift|post", data)
        if (!!response && response.status === 200) {
            if (response.data === true) {
                getunoccupiedList()
                props.updateshift();
                props.updateLeftList(props.calendarRef.current.activeRange.start);

            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: response.data,
                })
            }
        }
    }
    const removefutureshiftClick = async (e, empid) => {
        var shiftEndTime = moment(new Date(ShiftDate).toLocaleDateString()).startOf("day").add(parseInt(DatetimeDetail.split(" - ")[1].split(":")[0]), 'hours').add(parseInt(DatetimeDetail.split(" - ")[1].split(":")[1]), 'minutes');
        if (moment().isSameOrAfter(shiftEndTime)) {
            setSelectedEmpId(empid)

            // localStorage.setItem("ClickedEmpID", empid)
            setRecursivePastShiftModal(true)
            setIsShiftAlreadyRecursive(true)
            return
        }
        var data = {
            id: props.EventOccpancyInfo.shiftId,
            empid: empid
        }
        var response = await CallAxios("/api/Calendar/RemoveFromAllFutureShift|post", data)
        if (!!response && response.status === 200) {
            getunoccupiedList()
            props.updateshift();
            props.updateLeftList(props.calendarRef.current.activeRange.start);

        }
    }
    const RemoveFromshift = async (empid) => {
        var shiftEndTime = moment(new Date(ShiftDate).toLocaleDateString()).startOf("day").add(parseInt(DatetimeDetail.split(" - ")[1].split(":")[0]), 'hours').add(parseInt(DatetimeDetail.split(" - ")[1].split(":")[1]), 'minutes');
        if (moment().isSameOrAfter(shiftEndTime)) {
            setSelectedEmpId(empid)

            // localStorage.setItem("ClickedEmpID", empid)
            setIsShiftalreadyassigned(true)
            setDirectAssignPastShiftModel(true)
            return
        }
        var data = {
            id: props.EventOccpancyInfo.shiftId,
            empid: empid
        }
        var response = await CallAxios("/api/Calendar/RemoveFromShift|post", data)
        if (!!response && response.status === 200) {
            getunoccupiedList()
            props.updateshift();
            props.updateLeftList(props.calendarRef.current.activeRange.start);

        }
    }
    const reloadIconClick = async (e, empid) => {
        var shiftEndTime = moment(new Date(ShiftDate).toLocaleDateString()).startOf("day").add(parseInt(DatetimeDetail.split(" - ")[1].split(":")[0]), 'hours').add(parseInt(DatetimeDetail.split(" - ")[1].split(":")[1]), 'minutes');
        if (moment().isSameOrAfter(shiftEndTime)) {
            setSelectedEmpId(empid)

            // localStorage.setItem("ClickedEmpID", empid)
            setRecursivePastShiftModal(true)
            setIsShiftAlreadyRecursive(false)
            return
        }

        var data = {
            id: props.EventOccpancyInfo.shiftId,
            empid: empid
        }

        e.setAttribute("clickedhere", "yes")
        var response = await CallAxios("/api/Calendar/assignToAllfutureShift|post", data)
        e.setAttribute("clickedhere", "no")
        if (!!response && response.status === 200) {
            getunoccupiedList()
            props.updateshift();
            props.updateLeftList(props.calendarRef.current.activeRange.start);

        }
    }
    const SendMails = async (sendmailto) => {
        var EmpID = [];
        if (sendmailto === 'Done and notify' && OccupiedemployeeList.length > 0) {
            OccupiedemployeeList.map((val, key) => {
                EmpID.push(val.employeeId)
            })
            if (EmpID.length > 0) {
                var data = {
                    EmpID: EmpID,
                    notifyto: sendmailto,
                    ShiftDetail: DatetimeDetail
                }
                var response = await CallAxios("/api/Calendar/OccupancyNotifyMails|post", data)
                if (!!response && response.status === 200) {
                    props.handleOccupancyModal()
                }
            }
        } else if (sendmailto !== 'Done and notify' && AvailibaleEmployeeList.length > 0) {
            AvailibaleEmployeeList.map((val, key) => {
                EmpID.push(val.employeeId)
            })
            if (EmpID.length > 0) {
                var data = {
                    EmpID: EmpID,
                    notifyto: sendmailto,
                    ShiftDetail: DatetimeDetail
                }
                var response = await CallAxios("/api/Calendar/OccupancyNotifyMails|post", data)
                if (!!response && response.status === 200) {
                    props.handleOccupancyModal()
                }
            }
        }
    }
    const DeleteApplication = async (empId) => {
        var data = {
            id: props.EventOccpancyInfo.shiftId,
            empid: empId.toString()
        }
        var response = await CallAxios('/api/Shift/ApplayForShift|post', data) //delete application
        if (!!response && response.status === 200) {
            if (response.data === true) {
                getunoccupiedList()
                props.updateshift();
                props.updateLeftList(props.calendarRef.current.activeRange.start);

            } else if (response.data === "reached limit") {
                setShowExceedLimitModel(true);
            }

        }
    }
    return (
        <>
            {
                ShowExceedLimitModel === true ?
                    <Modal show={ShowExceedLimitModel} onHide={() => setShowExceedLimitModel(false)} >
                        <Modal.Header closeButton >
                            <Modal.Title>{cl("hourLimit")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <label className='fw-bolder'>{cl("youCan'tAssignYourself")}</label>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <a className='text-danger me-3' onClick={() => setShowExceedLimitModel(false)}>{cl("cancel")}</a>
                        </Modal.Footer>
                    </Modal>
                    : null
            }
            {
                RecursivePastShiftModal === true ?
                    <AssignPastShiftRecursiveConfirmationModel SelectedEmpId={SelectedEmpId} show={RecursivePastShiftModal} IsShiftAlreadyRecursive={IsShiftAlreadyRecursive} getunoccupiedList={getunoccupiedList} getShifts={props.updateshift} calendarComponentRef={props.calendarRef} GetEmployeeList={props.updateLeftList} handleModal={setRecursivePastShiftModal} shiftid={props.EventOccpancyInfo.shiftId} />
                    : null
            }
            {
                DirectAssignPastShiftModel === true ?
                    <DirectAssignPastShiftConfirmationModel SelectedEmpId={SelectedEmpId} checkWorkarea={false} show={DirectAssignPastShiftModel} getunoccupiedList={getunoccupiedList} isShiftalreadyassigned={isShiftalreadyassigned} getShifts={props.updateshift} calendarComponentRef={props.calendarRef} GetEmployeeList={props.updateLeftList} handleModal={setDirectAssignPastShiftModel} shiftid={props.EventOccpancyInfo.shiftId} />
                    : null
            }
            <Modal show={props.show} onHide={() => props.handleOccupancyModal()} >
                <Modal.Header className="RelativeModalHeader" closeButton>
                    <Modal.Title>{ct("assignment")} {workarea}</Modal.Title>
                    <Container fluid className="HeaderAbsoluteData">
                        <Row>
                            <Col md={6}>
                                <div>{DatetimeDetail}</div>
                            </Col>
                            <Col md={6} className="text-right">
                                <div>{cl("occupancy")}: <span className={parseInt(Occupiedemployee) >= parseInt(requiredemployee) ? "badgesuccess ms-2" : "badgedanger ms-2"}>{Occupiedemployee} of {requiredemployee}</span> </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Header>

                <Modal.Body>

                    <Row>
                        <Col md={6}>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th className='border-top-0'>{ct("assigned")}</th>
                                        <th className='border-top-0 text-right'> h ({month})</th>
                                        <th className='border-top-0'></th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        !!OccupiedemployeeList && OccupiedemployeeList.length > 0 ? OccupiedemployeeList.map((value, key) => {

                                            // return <OverlayTrigger
                                            //     placement="top"
                                            //     // delay={{ show: 250, hide: 400 }}
                                            //     overlay={<Tooltip id="button-tooltip">
                                            //         {value.employeeName}
                                            //     </Tooltip>}
                                            // >
                                            return <tr data-tooltip-id="my-tooltip" data-tooltip-content={value.employeeName}>
                                                <td onClick={() => RemoveFromshift(value.employeeId)}> {value.employeeName}</td>
                                                <td className='text-right'>  <Badge className="p-2" bg="success">{value.wokingHours} h</Badge> </td>
                                                <td className='text-center'>
                                                    <span>
                                                        {
                                                            isRecursiveShift === true ?


                                                                value.isRecursiveShifts ?
                                                                    <IoReload data-tooltip-id="my-tooltip" data-tooltip-content={value.isRecursiveShifts ? cl("disableRepeat") : cl("ActivateRecurringAssignment")} onClick={(e) => removefutureshiftClick(e.currentTarget, value.employeeId)} className='rotateIcon text-success' clickedhere="no" style={{ fontSize: "16px" }} />
                                                                    :
                                                                    <IoReload data-tooltip-id="my-tooltip" data-tooltip-content={value.isRecursiveShifts ? cl("disableRepeat") : cl("ActivateRecurringAssignment")} onClick={(e) => reloadIconClick(e.currentTarget, value.employeeId)} className='rotateIcon' clickedhere="no" style={{ fontSize: "16px" }} />

                                                                : null
                                                        }
                                                    </span>
                                                </td>
                                            </tr>
                                            // </OverlayTrigger>
                                        })
                                            : null
                                    }
                                </tbody>
                            </Table>

                        </Col>
                        <Col md={6}>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th className='border-top-0'>{ct("available")}</th>
                                        <th className='border-top-0 text-right'> h ({month})</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !!AvailibaleEmployeeList && AvailibaleEmployeeList.length > 0 ? AvailibaleEmployeeList.map((value, key) => {
                                            return <tr>
                                                <td onClick={() => DeleteApplication(value.employeeId)}>
                                                    {
                                                        value.isapplyforshift === true ?
                                                            <DOT color="#428bca" />
                                                            : null
                                                    }
                                                    {
                                                        value.belowrestPeriod === true ?
                                                            // <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip>below the rest period</Tooltip>}>
                                                            <GiNightSleep data-tooltip-id="my-tooltip" data-tooltip-content={cl("belowRestTooltip")} />
                                                            // </OverlayTrigger>
                                                            : null
                                                    }
                                                </td>
                                                <td onClick={() => assignshift(value.employeeId)}>
                                                    {value.employeeName}
                                                </td>

                                                <td className='text-right'>  <Badge className="p-2" bg="success">{value.wokingHours} h</Badge> </td>
                                            </tr>
                                        })
                                            : null
                                    }
                                </tbody>
                            </Table>

                            <div className='mt-3'>
                                {Load_unavailable_employees === true ?
                                    <Table responsive="md">
                                        <thead>
                                            <tr>
                                                <th className='border-top-0'>{ct("notAvailable")}</th>
                                                <th className='border-top-0 text-right'>  h ({month})</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {!!unOccupiedmployeeList && unOccupiedmployeeList.length > 0 ? unOccupiedmployeeList.map((value, key) => {
                                                return <tr style={{ 'backgroundColor': 'lightgray' }}>
                                                    {/* <td onClick={()=>assignshift(value.employeeId)}>  */}
                                                    <td onClick={() => DeleteApplication(value.employeeId)}>
                                                        {
                                                            value.isapplyforshift === true ?
                                                                <DOT color="#428bca" />
                                                                : null
                                                        }
                                                        {
                                                            value.belowrestPeriod === true ?
                                                                // <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip>below the rest period</Tooltip>}>
                                                                <GiNightSleep data-tooltip-id="my-tooltip" data-tooltip-content={cl("belowRestTooltip")} />
                                                                // </OverlayTrigger>
                                                                : null
                                                        }
                                                    </td>
                                                    <td onClick={() => assignshift(value.employeeId)}>
                                                        {value.employeeName}
                                                    </td>
                                                    <td className='text-right'>  <Badge className="p-2" bg="success">{value.wokingHours} h</Badge></td>
                                                </tr>
                                            })
                                                : null}
                                        </tbody>
                                    </Table>
                                    : <a href="javascript:;" onClick={() => setLoad_unavailable_employees(true)}>{cl("loadUnavailable")}</a>
                                }
                            </div>
                            <div className='my-3'>
                                {Load_Employees_of_other_areas === true ?
                                    <Table responsive="md">
                                        <thead>
                                            <tr>
                                                <th className='border-top-0'>{cl("employeesOtherAreas")}</th>
                                                <th className='border-top-0 text-right'>  h ({month})</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!!otherAreasEmployeeList && otherAreasEmployeeList.length > 0 ? otherAreasEmployeeList.map((value, key) => {
                                                return <tr>
                                                    <td onClick={() => DeleteApplication(value.employeeId)}>
                                                        {
                                                            value.isapplyforshift === true ?
                                                                <DOT color="#428bca" />
                                                                : null
                                                        }
                                                        {
                                                            value.belowrestPeriod === true ?
                                                                // <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip>below the rest period</Tooltip>}>
                                                                <GiNightSleep data-tooltip-id="my-tooltip" data-tooltip-content={cl("belowRestTooltip")} />
                                                                // </OverlayTrigger>
                                                                : null
                                                        }
                                                    </td>
                                                    <td onClick={() => assignshift(value.employeeId)}>
                                                        {value.employeeName}
                                                    </td>
                                                    <td className='text-right'>  <Badge className="p-2" bg="success">{value.wokingHours} h</Badge></td>
                                                </tr>
                                            })
                                                : null}
                                        </tbody>
                                    </Table>
                                    : <a href="javascript:;" onClick={() => setLoad_Employees_of_other_areas(true)}>{ct("load")} {cl("employeesOtherAreas")}</a>}
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Row className="w-100">
                        <Col md={12} className="px-0">
                            <div className="infotext mb-2">{cl("clickOnEmployee")}</div>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="">
                                    <Button href="javascript:;" variant="secondary" className="ModalFooterLink" onClick={() => { props.setshiftidforedit(props.EventOccpancyInfo.shiftId); props.setEditShiftModal(true) }}>{cl("editShift")}</Button>
                                </div>
                                <div className="">
                                    <Button className="btn btnPrimaryGreen me-1" onClick={() => props.handleOccupancyModal()}>{ct("finished")}</Button>
                                    <Button href="javascript:;" onClick={() => SendMails("Done and notify")} className="ModalFooterLink me-1">{cl("doneAndNotify")}</Button>
                                    <Button href="javascript:;" onClick={() => SendMails("Send link to allocation")} className="ModalFooterLink me-1">{cl("sendLink")}</Button>
                                    <Button href="javascript:;" onClick={() => SendMails("Send link to application")} className="ModalFooterLink ">{cl("sendLinkApplication")}</Button>
                                </div>
                            </div>
                        </Col>

                    </Row>

                </Modal.Footer>
            </Modal>
        </>

    )
}
export default OccupancyModal;

function AssignPastShiftRecursiveConfirmationModel(props) {
    const { t: cl } = useTranslation('calendarPage');
    const { t: ct } = useTranslation('common');



    const Assignmentonly = async () => {
        var data = {
            id: props.shiftid,
            empid: parseInt(props.SelectedEmpId),
            IsAlsoTimeTrack: false

        }
        if (props.IsShiftAlreadyRecursive) {
            var response = await CallAxios("/api/Calendar/RemoveFromAllFutureShift|post", data)
        }
        else {
            var response = await CallAxios("/api/Calendar/assignToAllfutureShift|post", data)
        }
        if (!!response && response.status === 200) {
            if (response.data === true) {
                props.getShifts();
                props.GetEmployeeList(props.calendarComponentRef.current.activeRange.start);
                props.handleModal()
                if (!!props.getunoccupiedList) {
                    props.getunoccupiedList();
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: response.data,
                })
            }
        }
    }
    const AlsoTimeTracking = async () => {
        var data = {
            id: props.shiftid,
            empid: parseInt(props.SelectedEmpId),
            IsAlsoTimeTrack: true
        }
        if (props.IsShiftAlreadyRecursive) {
            var response = await CallAxios("/api/Calendar/RemoveFromAllFutureShift|post", data)
        }
        else {
            var response = await CallAxios("/api/Calendar/assignToAllfutureShift|post", data)
        }
        if (!!response && response.status === 200) {
            if (response.data === true) {
                props.getShifts();
                props.GetEmployeeList(props.calendarComponentRef.current.activeRange.start);
                props.handleModal()
                if (!!props.getunoccupiedList) {
                    props.getunoccupiedList();
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: response.data,
                })
                if (response.data === cl("errorMsg.employeeAlreadyAssigned")) {
                    props.getShifts();
                }
            }
        }
    }
    return (
        <>
            <Modal show={props.show} onHide={() => props.handleModal()} >
                <Modal.Header className="" closeButton >
                    <Modal.Title>{ct("warning")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{cl("carefulPara")}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btnPrimaryGreen me-3" onClick={() => Assignmentonly()}>{cl("assignmentOnly")}</Button>
                    <Button className="btn btnPrimaryGreen" onClick={() => AlsoTimeTracking()}>{cl("alsoTimeTracking")}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
function DirectAssignPastShiftConfirmationModel(props) {
    const { t: cl } = useTranslation('calendarPage');
    const { t: ct } = useTranslation('common');




    const [ShowExceedLimitModel, setShowExceedLimitModel] = useState(false);
    const [forceShiftId, setForceShiftId] = useState("");
    const [CosenType, setCosenType] = useState("");
    const Assignmentonly = async () => {
        var data = {
            id: props.shiftid,
            empid: props.SelectedEmpId.toString(),
            checkWorkarea: props.checkWorkarea,
            assignForcefully: false
        }
        if (props.isShiftalreadyassigned) {
            var response = await CallAxios("/api/Calendar/RemoveFromShiftDirectly|post", data)
        }
        else {
            var response = await CallAxios("/api/Calendar/assignShiftDirectly|post", data)
            if (response.data === "reached limit") {
                setForceShiftId(props.shiftid)
                setShowExceedLimitModel(true);
                return;
            }
        }
        if (!!response && response.data.success === true) {
            if (response.data === true) {
                props.getShifts();
                props.GetEmployeeList(props.calendarComponentRef.current.activeRange.start);
                props.handleModal()
                if (!!props.getunoccupiedList) {
                    props.getunoccupiedList();
                }
            } else if (response.data !== false) {
                Swal.fire({
                    icon: 'error',
                    title: response.data,
                })
            }
        } else {
            toast.error(response.data.message)
        }
    }
    const AlsoTimeTracking = async () => {
        var data = {
            id: props.shiftid,
            empid: props.SelectedEmpId.toString(),
            checkWorkarea: props.checkWorkarea
        }
        if (props.isShiftalreadyassigned) {
            var response = await CallAxios("/api/Calendar/RemoveFromShiftAndTimetracking|post", data)
        }
        else {
            var response = await CallAxios("/api/Calendar/AssignShiftAndTimetracking|post", data)
            if (response.data === "reached limit") {
                setForceShiftId(props.shiftid)
                setShowExceedLimitModel(true);
                return;
            }
        }
        if (!!response && response.status === 200) {
            if (response.data === true) {
                props.getShifts();
                props.GetEmployeeList(props.calendarComponentRef.current.activeRange.start);
                if (!!props.getunoccupiedList) {
                    props.getunoccupiedList();
                }
                props.handleModal()
            } else if (response.data !== false) {
                Swal.fire({
                    icon: 'error',
                    title: response.data,
                })
                if (response.data === cl("errorMsg.employeeAlreadyAssigned")) {
                    props.getShifts();
                }
            }
        }
    }
    const ForceassignShiftDirectly = async () => {
        var data = {
            id: forceShiftId,
            empid: props.SelectedEmpId.toString(),
            checkWorkarea: props.checkWorkarea,
            assignForcefully: true

        }
        var URL = "";
        if (CosenType === "Also time tracking") {
            URL = "/api/Calendar/AssignShiftAndTimetracking|post";
        } else {
            URL = "/api/Calendar/assignShiftDirectly|post";
        }
        var response = await CallAxios(URL, data);
        if (!!response && response.status === 200) {
            if (response.data === true) {
                props.getShifts();
                props.GetEmployeeList(props.calendarComponentRef.current.activeRange.start);
                if (!!props.getunoccupiedList) {
                    props.getunoccupiedList();
                }
                props.handleModal()
            }
            else if (response.data !== false) {
                Swal.fire({
                    icon: 'error',
                    title: response.data,
                })
            }
        }
    }
    return (
        <>
            {
                ShowExceedLimitModel === true ?
                    <Modal show={ShowExceedLimitModel} onHide={() => setShowExceedLimitModel(false)} >
                        <Modal.Header closeButton >
                            <Modal.Title>{ct("information")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <label className='fw-bolder'>{cl("employeeHourLimit")}</label>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <a className='text-danger me-3' onClick={() => setShowExceedLimitModel(false)}>{ct("cancel")}</a>
                            <Button className="btn btnPrimaryGreen" onClick={() => ForceassignShiftDirectly()}>{ct("forceAssignment")}</Button>
                        </Modal.Footer>
                    </Modal>
                    : null
            }
            <Modal show={props.show} onHide={() => props.handleModal()} >
                <Modal.Header className="" closeButton >
                    <Modal.Title>{ct("warning")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{cl("assignRemoveEmployeeFromShift")}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btnPrimaryGreen me-3" onClick={() => { setCosenType("Assignment only"); Assignmentonly() }}>{cl("assignmentOnly")}</Button>
                    <Button className="btn btnPrimaryGreen" onClick={() => { setCosenType("Also time tracking"); AlsoTimeTracking() }}>{cl("alsoTimeTracking")}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}