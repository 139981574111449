// import { LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import { NylasProvider } from '@nylas/nylas-react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { App } from './App/App';
import { AppStore } from './Store';
import './index.css';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import './i18n/index';
const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);

  root.render(
    // <NylasProvider serverBaseUrl="https://localhost:7013">
    <I18nextProvider i18n={i18n}>
      <Provider store={AppStore}>
        <BrowserRouter>
          {/* <LocalizationProvider dateAdapter={AdapterMoment}> */}
          <App />
          {/* </LocalizationProvider> */}
        </BrowserRouter>
      </Provider>
    </I18nextProvider>
    // </NylasProvider>
  );
}

