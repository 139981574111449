import { useFormik } from "formik";
import React, { useEffect } from 'react';
import { Button, Col, Container, Modal, Row, Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { isValidPhoneNumber } from "react-phone-number-input";
import * as Yup from "yup";
import { AxiosLite, CallAxios } from "../../Helpers";
import { toast } from "react-toastify";
import { useState } from "react";
import { Gear, Remove } from "../../Components/ActionBtns";
import { TableWrapper } from "../../Helpers/Global/StyledComponents";
import HeaderButton from "../../Components/HeaderButton";
import { FaPlus } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import DeleteConfirmationModal from "../../Components/DeleteConfirmationModal";


function LocationTemplateTab(props) {
    const { t: ast } = useTranslation('accountSetting');
    const { t: ct } = useTranslation('common');


    const [AddEditLocationTemplatetModal, setAddEditLocationTemplatetModal] = useState(false);
    const [EditLocationTemplatetModal, setEditLocationTemplatetModal] = useState(false);
    const [LocationTemplateList, setLocationTemplateList] = useState([]);
    const [locationTemplateIdtoEdit, setLocationTemplateIdtoEdit] = useState("");
    const [ShowDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    useEffect(() => {
        getData()
    }, []);

    const getData = async () => {
        var response = await AxiosLite("/api/AccountSetting/locationTemplatelist|get")
        if (!!response && response.status === 200) {
            setLocationTemplateList(response.data)
        }
    }
    const onDeleteSubmit = async (id) => {
        var response = await CallAxios("/api/AccountSetting/deletelocationtemplate|post", { id: id })
        if (!!response && response.status === 200) {
            getData()
            setShowDeleteConfirmationModal(false)
        }
    }

    return (
        <>
            {
                ShowDeleteConfirmationModal &&
                <DeleteConfirmationModal show={ShowDeleteConfirmationModal} onHide={setShowDeleteConfirmationModal}
                    title={ast('templateTab.deleteMdl.title')} message={ast('templateTab.deleteMdl.desc')} deletefn={onDeleteSubmit} objId={locationTemplateIdtoEdit} />
            }
            <Row>
                <Col md={12}>
                    <div className="TabFormContainerSection">
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <div className="Heading  h5 fw-bold  d-flex align-items-center"> {ast('templateTab.locationTemplates')} </div>
                            <HeaderButton title={ct('new') + ' ' + ast('locationTemplate')} onClick={() => { setLocationTemplateIdtoEdit(""); setAddEditLocationTemplatetModal(true) }} icon={<FaPlus color='#fff' size={18} />} />
                        </div>
                        <TableWrapper>
                            <Table striped bordered responsive className="DataGridTable overflowVisible">
                                <thead>
                                    <tr className="firsthead">
                                        <th> {ct('title')}</th>
                                        <th> {ast('templateTab.locationTemplates')}</th>
                                        <th> {ast('templateTab.attributes')}</th>
                                        <th> {ct('action')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !!LocationTemplateList && LocationTemplateList.length > 0 ? LocationTemplateList.map((value, key) => {
                                            return <tr>
                                                <td>{value.templateName}</td>
                                                <td>{value.locationName}</td>
                                                <td>{value.stringattributes}</td>
                                                <td>
                                                    <Gear onClick={(e) => { setLocationTemplateIdtoEdit(value.id); setEditLocationTemplatetModal(true) }} />
                                                    <Remove onClick={(e) => { setLocationTemplateIdtoEdit(value.id); setShowDeleteConfirmationModal(true) }} />
                                                </td>

                                            </tr>
                                        }) :
                                            <tr>
                                                <td colSpan="4" className="text-center"> {ast('templateTab.noRecordDesc')}.</td>
                                            </tr>
                                    }
                                </tbody>
                            </Table>
                        </TableWrapper>
                    </div>
                </Col>
            </Row>


            {/*Add location template modal popups start*/}
            {AddEditLocationTemplatetModal == true ?
                <LocationTemplate show={AddEditLocationTemplatetModal} locationTemplateIdtoEdit={locationTemplateIdtoEdit} updatelist={getData} onHide={setAddEditLocationTemplatetModal} />
                : null}

            {EditLocationTemplatetModal == true ?
                <EditLocationTemplate show={EditLocationTemplatetModal} locationTemplateIdtoEdit={locationTemplateIdtoEdit} updatelist={getData} onHide={setEditLocationTemplatetModal} />
                : null}
        </>
    );
}

export default LocationTemplateTab;

function EditLocationTemplate(props) {
    const { t: ast } = useTranslation('accountSetting');
    const { t: ct } = useTranslation('common');

    const [locationtempObj, setLocationtempObj] = useState({
        Title: "",
        LocationId: "",
        Types: [],
        // Types:["CheckCopyWorkspaces","CheckCopyBreaks"]
    });
    const [templateList, settemplateList] = useState([]);
    useEffect(() => {
        if (!!props.locationTemplateIdtoEdit) {
            getData()
        }
    }, [props.locationTemplateIdtoEdit]);

    const getData = async () => {
        var Locationsss = await AxiosLite("/api/AccountSetting/locationlist|get");
        var response = await CallAxios("/api/AccountSetting/getlocationTemplate|post", { id: props.locationTemplateIdtoEdit });

        if (!!response && response.data) {
            var receivedata = {
                Title: response.data.templateName,
                LocationId: response.data.locationId,
                Types: response.data.attributes,
                templateList: []
            };
            setLocationtempObj(receivedata)
        }
        if (!!Locationsss) {
            settemplateList(Locationsss.data)
        }
    }
    const updatedata = (e) => {
        setLocationtempObj({
            ...locationtempObj,
            [e.target.name]: e.target.value
        })
    }
    const addRemoveChecklist = (e) => {

        if (e.target.checked === true) {
            setLocationtempObj({
                ...locationtempObj,
                Types: [...locationtempObj.Types, e.target.name]
            })
        } else {
            setLocationtempObj({
                ...locationtempObj,
                Types: [...locationtempObj.Types.filter(i => i !== e.target.name)]
            })
        }
    }
    const submit = async () => {
        if (!locationtempObj.Title) {
            alert(ct('titleRequire'))
            return;
        }
        var data = {
            Id: props.locationTemplateIdtoEdit,
            TemplateName: locationtempObj.Title,
            LocationId: locationtempObj.LocationId,
            attributes: locationtempObj.Types
        }
        var res = await CallAxios("/api/AccountSetting/addorUpdatelocationTemplate|post", data)
        if (!!res && res.status === 200) {
            props.onHide(false)
            props.updatelist()
        }
    }
    return (
        <Modal show={props.show} onHide={() => props.onHide(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{ct('edit')} {ast('locationTemplate')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col md={12} >
                            <Form.Group controlId="AddlocationtemplateForm.txtTitle">
                                <Form.Label>{ct('title')}</Form.Label>
                                <Form.Control type="text" maxLength={150} placeholder={ast('templateTab.newLocationMdl.egLocationTemplate')} name="Title" value={locationtempObj.Title} onChange={(e) => updatedata(e)} />
                            </Form.Group>
                        </Col>
                        <Col md={12} className="mt-2">
                            <Form.Group controlId="AddlocationtemplateForm.txtTitle">
                                <Form.Label>{ast('templateTab.location')}</Form.Label>
                                <Form.Control as="select" className="SelectbgGrey" name="LocationId" value={locationtempObj.LocationId} onChange={(e) => updatedata(e)} custom>
                                    {
                                        !!templateList && templateList.length > 0 ? templateList.map((v, key) => {
                                            return <option value={v.value}>{v.text}</option>
                                        })
                                            :
                                            null
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <div className="modalNoteText mb-3">
                                {ast('templateTab.newLocationMdl.locationDesc')}:
                            </div>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-0">
                                <Form.Check className="fw-bold" checked={locationtempObj.Types.find(i => i === "Workspaces") !== undefined ? true : false} onChange={(e) => addRemoveChecklist(e)} name="Workspaces" label={ast('templateTab.newLocationMdl.copyLabelList1')} type="checkbox" id="CheckCopyWorkspaces" />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-0">
                                <Form.Check className="fw-bold" checked={locationtempObj.Types.find(i => i === "Tags") !== undefined ? true : false} onChange={(e) => addRemoveChecklist(e)} name="Tags" label={ast('templateTab.newLocationMdl.copyLabelList2')} type="checkbox" id="CheckCopyTags" />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-0">
                                <Form.Check className="fw-bold" checked={locationtempObj.Types.find(i => i === "AbsenceType") !== undefined ? true : false} onChange={(e) => addRemoveChecklist(e)} name="AbsenceType" label={ast('templateTab.newLocationMdl.copyLabelList3')} type="checkbox" id="CheckCopyAbsenceTypes" />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-0">
                                <Form.Check className="fw-bold" checked={locationtempObj.Types.find(i => i === "Holidays") !== undefined ? true : false} onChange={(e) => addRemoveChecklist(e)} name="Holidays" label={ast('templateTab.newLocationMdl.copyLabelList4')} type="checkbox" id="CheckCopyHolidays" />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-0">
                                <Form.Check className="fw-bold" checked={locationtempObj.Types.find(i => i === "Breaks") !== undefined ? true : false} onChange={(e) => addRemoveChecklist(e)} name="Breaks" label={ast('templateTab.newLocationMdl.copyLabelList5')} type="checkbox" id="CheckCopyBreaks" />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-0">
                                <Form.Check className="fw-bold" checked={locationtempObj.Types.find(i => i === "WageTypes") !== undefined ? true : false} onChange={(e) => addRemoveChecklist(e)} name="WageTypes" label={ast('templateTab.newLocationMdl.copyLabelList6')} type="checkbox" id="CheckCopyWageTypes" />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-0">
                                <Form.Check className="fw-bold" checked={locationtempObj.Types.find(i => i === "Triggers") !== undefined ? true : false} onChange={(e) => addRemoveChecklist(e)} name="Triggers" label={ast('templateTab.newLocationMdl.copyLabelList7')} type="checkbox" id="CheckCopyTriggers" />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-0">
                                <Form.Check className="fw-bold" checked={locationtempObj.Types.find(i => i === "shifts") !== undefined ? true : false} onChange={(e) => addRemoveChecklist(e)} name="shifts" label={ast('templateTab.newLocationMdl.copyLabelList8')} type="checkbox" id="CheckCopyLayers" />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btnPrimaryGreen" onClick={() => submit()}> {ct('finished')}</Button>
            </Modal.Footer>
        </Modal>
    )
}

function LocationTemplate(props) {
    const { t: ast } = useTranslation('accountSetting');
    const { t: ct } = useTranslation('common');

    const [locationtempObj, setLocationtempObj] = useState({
        Title: "",
        LocationId: "",
        Types: [],
        templateList: []
        // Types:["CheckCopyWorkspaces","CheckCopyBreaks"]
    });
    useEffect(() => {
        getlocatioList()
    }, []);

    const getlocatioList = async () => {
        var response = await AxiosLite("/api/AccountSetting/locationlist|get")
        if (!!response) {
            setLocationtempObj({
                ...locationtempObj,
                templateList: response.data,
                LocationId: response.data[0].value
            })
        }
    }
    const updatedata = (e) => {
        setLocationtempObj({
            ...locationtempObj,
            [e.target.name]: e.target.value
        })

    }
    const addRemoveChecklist = (e) => {
        if (e.target.checked === true) {
            setLocationtempObj({
                ...locationtempObj,
                Types: [...locationtempObj.Types, e.target.name]
            })
        } else {
            setLocationtempObj({
                ...locationtempObj,
                Types: [...locationtempObj.Types.filter(i => i !== e.target.name)]
            })
        }
    }
    const submit = async () => {
        if (!locationtempObj.Title) {
            alert(ct('titleRequire'))
            return;
        }
        var data = {
            TemplateName: locationtempObj.Title,
            LocationId: locationtempObj.LocationId,
            attributes: locationtempObj.Types
        }
        await CallAxios("/api/AccountSetting/addorUpdatelocationTemplate|post", data)
        props.onHide(false)
        props.updatelist()
    }
    return (
        <Modal show={props.show} onHide={() => props.onHide(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{ct('new')} {ast('locationTemplate')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col md={12} >
                            <Form.Group controlId="AddlocationtemplateForm.txtTitle">
                                <Form.Label>{ct('title')}</Form.Label>
                                <Form.Control type="text" placeholder={ast('templateTab.newLocationMdl.egLocationTemplate')} name="Title" defaultValue={locationtempObj.Title} onChange={(e) => updatedata(e)} />
                            </Form.Group>
                        </Col>
                        <Col md={12} className="mt-2">
                            <Form.Group controlId="AddlocationtemplateForm.txtTitle">
                                <Form.Label>{ast('templateTab.location')}</Form.Label>
                                <Form.Control as="select" className="SelectbgGrey" name="LocationId" defaultValue={locationtempObj.LocationId} onChange={(e) => updatedata(e)} custom>
                                    {
                                        !!locationtempObj.templateList && locationtempObj.templateList.length > 0 ? locationtempObj.templateList.map((v, key) => {
                                            return <option value={v.value}>{v.text}</option>

                                        })
                                            :
                                            null
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <div className="modalNoteText mb-3">
                                {ast('templateTab.newLocationMdl.locationDesc')}:
                            </div>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-0">
                                <Form.Check className="fw-bold" checked={locationtempObj.Types.find(i => i === "Workspaces") !== undefined ? true : false} onChange={(e) => addRemoveChecklist(e)} name="Workspaces" label={ast('templateTab.newLocationMdl.copyLabelList1')} type="checkbox" id="CheckCopyWorkspaces" />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-0">
                                <Form.Check className="fw-bold" checked={locationtempObj.Types.find(i => i === "Tags") !== undefined ? true : false} onChange={(e) => addRemoveChecklist(e)} name="Tags" label={ast('templateTab.newLocationMdl.copyLabelList2')} type="checkbox" id="CheckCopyTags" />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-0">
                                <Form.Check className="fw-bold" checked={locationtempObj.Types.find(i => i === "AbsenceType") !== undefined ? true : false} onChange={(e) => addRemoveChecklist(e)} name="AbsenceType" label={ast('templateTab.newLocationMdl.copyLabelList3')} type="checkbox" id="CheckCopyAbsenceTypes" />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-0">
                                <Form.Check className="fw-bold" checked={locationtempObj.Types.find(i => i === "Holidays") !== undefined ? true : false} onChange={(e) => addRemoveChecklist(e)} name="Holidays" label={ast('templateTab.newLocationMdl.copyLabelList4')} type="checkbox" id="CheckCopyHolidays" />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-0">
                                <Form.Check className="fw-bold" checked={locationtempObj.Types.find(i => i === "Breaks") !== undefined ? true : false} onChange={(e) => addRemoveChecklist(e)} name="Breaks" label={ast('templateTab.newLocationMdl.copyLabelList5')} type="checkbox" id="CheckCopyBreaks" />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-0">
                                <Form.Check className="fw-bold" checked={locationtempObj.Types.find(i => i === "WageTypes") !== undefined ? true : false} onChange={(e) => addRemoveChecklist(e)} name="WageTypes" label={ast('templateTab.newLocationMdl.copyLabelList6')} type="checkbox" id="CheckCopyWageTypes" />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-0">
                                <Form.Check className="fw-bold" checked={locationtempObj.Types.find(i => i === "Triggers") !== undefined ? true : false} onChange={(e) => addRemoveChecklist(e)} name="Triggers" label={ast('templateTab.newLocationMdl.copyLabelList7')} type="checkbox" id="CheckCopyTriggers" />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-0">
                                <Form.Check className="fw-bold" checked={locationtempObj.Types.find(i => i === "shifts") !== undefined ? true : false} onChange={(e) => addRemoveChecklist(e)} name="shifts" label={ast('templateTab.newLocationMdl.copyLabelList8')} type="checkbox" id="CheckCopyLayers" />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btnPrimaryGreen" onClick={() => submit()}> {ct('finished')}</Button>
            </Modal.Footer>
        </Modal>
    )
} 