import React, { useState, useEffect } from 'react';
import FullCustomCalendar from '../../Components/FullCustomCalendar';
import { createRef } from 'react';
import { CallAxios, CallAxiosBlob } from '../../Helpers';
import moment from 'moment';
import { FaPaperclip, FaRegStickyNote } from 'react-icons/fa';
import { Container, Popover, Tooltip } from 'react-bootstrap';
import { config } from '../../Helpers/Config';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import CalendarHeader from './CalendarHeader';
import RosterView from './RosterView';
import AbsencesView from './AbsencesView';
import { useSelector } from 'react-redux';
import { Constants } from '../../Helpers/Contents';
import AvailibilityView from './AvailibilityView';
import EmployeeRosterView from './EmployeeRosterView';

function CalendarPage() {

    const currentView = useSelector(x => x.app.currentView)
    const [RosterViewBit, setRosterView] = useState(false);
    const [AbsencesViewBit, setAbsencesView] = useState(false);
    const [AvaiableViewBit, setAvaiableViewBit] = useState(false);
    useEffect(() => {
        var RosterActiveTab = localStorage.getItem("RosterActiveTab");
        if (RosterActiveTab === "AbsencesView") {
            setAbsencesView(true)
        }
        else if (RosterActiveTab === "AvaiableView") {
            setAvaiableViewBit(true)
        } else {
            setRosterView(true)
        }
    }, []);

    return (
        <>
            <Container fluid>
                <CalendarHeader RosterView={RosterViewBit} setRosterView={setRosterView} AbsencesView={AbsencesViewBit} setAbsencesView={setAbsencesView} AvaiableViewBit={AvaiableViewBit} setAvaiableViewBit={setAvaiableViewBit} />
                {
                    RosterViewBit === true ?
                        currentView === Constants.layoutType.Employee ?
                            <EmployeeRosterView />
                            :
                            <RosterView />
                        :
                        null
                }
                {
                    AbsencesViewBit === true ?
                        <AbsencesView />
                        :
                        null
                }
                {
                    currentView === Constants.layoutType.Employee && AvaiableViewBit === true ?
                        <AvailibilityView />
                        :
                        null
                }

            </Container>
        </>
    );
}

export default CalendarPage;