import React from 'react';
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";

function MultiSelect(props) {
  return (
    <DropdownMultiselect
      options={props.options}
      name={props.name}
      selected={props.selected}
      handleOnChange={props.handleOnChange}
      optionKey={props.optionKey}
      optionLabel="label"
      buttonClass={props.buttonClass}
      selectDeselectLabel={!!props.selectDeselectLabel ? props.selectDeselectLabel : ""}
    />
  );
}

export default MultiSelect;