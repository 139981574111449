import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Row, Table } from 'react-bootstrap';
import * as Yup from "yup";
import { Gear, Remove } from '../../../Components/ActionBtns';
import { AxiosLite, CallAxios } from '../../../Helpers';
import { TableWrapper } from '../../../Helpers/Global/StyledComponents';
import { useTranslation } from "react-i18next";
import DeleteConfirmationModal from '../../../Components/DeleteConfirmationModal';


function AutomaticBreaks() {
    const { t: Lst } = useTranslation('locationSetting');
    const { t: ct } = useTranslation('common');


    const [newBreakModal, setnewBreakModal] = useState(false);
    const [BreakIdForEdit, setBreakIdForEdit] = useState("");
    const [BreakList, setBreakList] = useState([]);
    const [ShowDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

    useEffect(() => {
        getList()
    }, []);
    const getList = async () => {
        var response = await AxiosLite(
            "/api/LocationSetting/getAutomaticBreaksList|get"
        );
        if (!!response && response.status === 200) {
            setBreakList(response.data)
        }
    }
    const Deletebreak = async (id) => {
        var response = await CallAxios(
            "/api/LocationSetting/deleteAutomaticBreaks|post", { id: id }
        );
        setBreakList(response.data)
        setShowDeleteConfirmationModal(false)
    };

    return (
        <>
            {
                ShowDeleteConfirmationModal &&
                <DeleteConfirmationModal show={ShowDeleteConfirmationModal} onHide={setShowDeleteConfirmationModal}
                    title={Lst('timeTab.deleteMdl.title')} message={Lst('timeTab.deleteMdl.desc')} deletefn={Deletebreak} objId={BreakIdForEdit} />
            }
            {
                newBreakModal === true ?
                    <AddEditBreakModal show={newBreakModal} setshow={setnewBreakModal} setBreakList={setBreakList} BreakIdForEdit={BreakIdForEdit} />
                    : null
            }
            <Row>
                <Col md={12}>
                    <div className="TabFormContainerSection">
                        <h5 className='text-left fw-bold mb-0'> {Lst('timeTab.automaticBreaks')}</h5>
                        <div className='d-flex justify-content-between mb-3 align-items-center'>
                            <p className='text-left w-75'>
                                {Lst('timeTab.desc')}.
                            </p>
                            <button type="button" className="btn btnPrimaryGreen btn-primary" onClick={(e) => { setBreakIdForEdit(""); setnewBreakModal(true) }}> {Lst('timeTab.newBreak')}</button>

                        </div>
                        <TableWrapper>


                            <Table striped bordered responsive className="DataGridTable table table-striped table-bordered text-left overflowVisible">
                                <thead>
                                    <tr>
                                        <th> {Lst('timeTab.tbl.breakHour')}</th>
                                        <th> {Lst('timeTab.tbl.breakTime')}</th>
                                        <th> {Lst('timeTab.tbl.newUsers')}</th>
                                        <th> {ct('actions')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!!BreakList && BreakList.length > 0 ? BreakList.map((val, index) =>
                                        <tr key={"tableList_" + index}>

                                            <td>
                                                <div className="dataTextCell">
                                                    {val.breakFromXHours}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="dataTextCell">
                                                    {val.duration_in_minutes}

                                                </div>
                                            </td>
                                            <td>
                                                <div className="dataTextCell">
                                                    {val.automatically_assign_this_break_to_new_employees === true ? <>{Lst('timeTab.tbl.toAssign')}</> : <>{Lst('timeTab.tbl.notAssign')}</>}

                                                </div>
                                            </td>
                                            <td>
                                                <Gear onClick={(e) => { setBreakIdForEdit(val.id); setnewBreakModal(true) }} />
                                                <Remove onClick={(e) => { setBreakIdForEdit(val.id); setShowDeleteConfirmationModal(true) }} />
                                            </td>
                                        </tr>
                                    ) : null}


                                </tbody>
                            </Table>
                        </TableWrapper>
                    </div>
                </Col>

            </Row>
        </>
    );
}

export default AutomaticBreaks;


function AddEditBreakModal(props) {
    const { t: Lst } = useTranslation('locationSetting');
    const { t: ct } = useTranslation('common');

    const formik = useFormik({
        initialValues: {
            BreakFromXHours: "",
            Duration_in_minutes: "",
            Automatically_assign_this_break_to_new_employees: false,
            Assign_this_break_to_all_employees_from_this_location: false,
        },
        validationSchema: Yup.object().shape({
            BreakFromXHours: Yup.number("Pause from x Hours is not a number").required(Lst('timeTab.newBreakMdl.errorMsg.breakHour')),
            Duration_in_minutes: Yup.number("Duration in Minutes is not a number. ").required(Lst('timeTab.newBreakMdl.errorMsg.durationMinute')),
        }),
        onSubmit: values => {
            submit(values)
        },
    });
    const getData = async () => {

        var response = await CallAxios(
            "/api/LocationSetting/getAutomaticBreaks|post", { id: props.BreakIdForEdit }
        );
        if (!!response) {
            formik.setFieldValue("BreakFromXHours", response.data.breakFromXHours)
            formik.setFieldValue("Duration_in_minutes", response.data.duration_in_minutes)
            formik.setFieldValue("Automatically_assign_this_break_to_new_employees", response.data.automatically_assign_this_break_to_new_employees)
            formik.setFieldValue("Assign_this_break_to_all_employees_from_this_location", response.data.assign_this_break_to_all_employees_from_this_location)
        }
    };
    useEffect(() => {
        if (!!props.BreakIdForEdit) {
            getData()
        }
    }, []);
    const submit = async (values) => {
        var data = {
            Id: props.BreakIdForEdit,
            BreakFromXHours: values.BreakFromXHours,
            Duration_in_minutes: values.Duration_in_minutes,
            Automatically_assign_this_break_to_new_employees: values.Automatically_assign_this_break_to_new_employees,
            Assign_this_break_to_all_employees_from_this_location: values.Assign_this_break_to_all_employees_from_this_location,

        };
        var response = await CallAxios(
            "/api/LocationSetting/addAutomaticBreaks|post", data
        );
        if (!!response && response.status === 200) {
            props.setBreakList(response.data)
            props.setshow(false)
        }
    };

    return (
        <Modal show={props.show} className="" onHide={() => props.setshow(false)}>
            <Modal.Header closeButton className="">
                <Modal.Title className='text-nowrap'>{!!props.BreakIdForEdit ? <>{ct('edit')}</> : <>{ct('new')}</>} {ct('break')}</Modal.Title>
                <p className='text-danger w-75 mx-3'> {Lst('timeTab.newBreakMdl.subTitle')}!</p>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <label className='fw-bold mb-2'> {Lst('timeTab.tbl.breakHour')} </label>
                            <Form.Control type="text" maxLength={10} onChange={formik.handleChange} value={formik.values.BreakFromXHours} name="BreakFromXHours" className="ThickBorder my-0 pr40" />
                            {formik.touched.BreakFromXHours && formik.errors.BreakFromXHours ? (
                                <span className="error text-danger">{formik.errors.BreakFromXHours}</span>
                            ) : null}
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <label className='fw-bold mb-2'> {Lst('timeTab.newBreakMdl.durationMinutes')}</label>
                            <Form.Control type="text" maxLength={10} onChange={formik.handleChange} value={formik.values.Duration_in_minutes} name="Duration_in_minutes" className="ThickBorder my-0 pr40" />
                            {formik.touched.Duration_in_minutes && formik.errors.Duration_in_minutes ? (
                                <span className="error text-danger">{formik.errors.Duration_in_minutes}</span>
                            ) : null}
                        </Form.Group>
                    </Col>
                    <Col md={8}>
                        <Form.Group className="mb-3" controlId={"Automatically_assign_this_break_to_new_employees"}>
                            <Form.Check className="text-left fw-bold"
                                onChange={formik.handleChange} checked={formik.values.Automatically_assign_this_break_to_new_employees}
                                name="Automatically_assign_this_break_to_new_employees" type="checkbox" label={Lst('timeTab.newBreakMdl.newEmployeeLabel')} />
                        </Form.Group>
                    </Col>
                    {
                        !props.BreakIdForEdit &&
                        <Col md={12} className="border-top pt-3 mt-4">
                            <Form.Group className="mb-3" controlId={"Assign_this_break_to_all_employees_from_this_location"}>
                                <Form.Check className="text-left fw-bold"
                                    onChange={formik.handleChange} checked={formik.values.Assign_this_break_to_all_employees_from_this_location} name="Assign_this_break_to_all_employees_from_this_location"
                                    type="checkbox" label={Lst('timeTab.newBreakMdl.assignEmployeeLabel')} />
                            </Form.Group>
                        </Col>
                    }
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btnPrimaryGreen text-white" onClick={formik.handleSubmit}> {ct('import')}</button>
            </Modal.Footer>
        </Modal>
    );
}