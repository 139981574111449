import { saveAs } from 'file-saver';
import moment from 'moment';
import React, { useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import DatePicker from 'react-datepicker';
import { BiExport } from "react-icons/bi";
import { BsFillHouseDoorFill } from 'react-icons/bs';
import { HiOutlineClock, HiPlus } from "react-icons/hi";
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import HeaderButton from '../../Components/HeaderButton';
import { default as CheckSelectFilter, default as TagsCheckSelectFilter } from '../../Components/TagsCheckSelectFilter';
import WorkAreaCheckSelectFilter from '../../Components/WorkAreaCheckSelectFilter';
import { CallAxios, CallAxiosBlob } from '../../Helpers';
import { Constants } from '../../Helpers/Contents';
import { ActionItem, ActionsBox, CircleBtnWithIcon, HeaderRadio, SubHeadTitle, SubHeaderTitleBox, SubHeaderWrapper } from '../../Helpers/Global/StyledComponents';
import { useEffect } from 'react';
import SelectPeriodDropdownFilter from '../../Components/SelectPeriodDropdownFilter';
import { useTranslation } from "react-i18next";


const SelectCheckData = [
    {
        optionText: 'Option 1',
        isCheck: false
    },
    {
        optionText: 'Option 2',
        isCheck: false
    },
    {
        optionText: 'Option 3',
        isCheck: false
    },
    {
        optionText: 'Option 4',
        isCheck: false
    }
]

function TimeTrackingHeader(props) {
    const { t: ct } = useTranslation('common');
    const { t: tpt } = useTranslation('timeTrackingPage');

    const user = useSelector(x => x.app.user);
    const currentView = useSelector(x => x.app.currentView)
    const [showExportModal, setShowExportModal] = useState(false);
    const [createOwnTimeRecords, setCreateOwnTimeRecords] = useState(false);
    const themeColorBtn = useSelector(state => state.app.theme.colors.buttons);
    useEffect(() => {
        if (currentView === Constants.layoutType.Employee) {
            GetEmpRights()
        }
    }, []);
    const GetEmpRights = async () => {
        var response = await CallAxios('/api/Employee/GetEmpRights|post', { id: user.EncLoginUserId })
        if (!!response && response.status === 200) {
            setCreateOwnTimeRecords(response.data.createOwnTimeRecords)
        }
    }
    return (
        <>
            <SubHeaderWrapper>
                <SubHeaderTitleBox>
                    <HiOutlineClock color={themeColorBtn} size={22} />
                    <SubHeadTitle> {tpt('timeTracking')} </SubHeadTitle>
                </SubHeaderTitleBox>
                <ActionsBox>
                    {
                        (currentView === Constants.layoutType.Admin || (createOwnTimeRecords === true && currentView === Constants.layoutType.Employee)) &&
                        <>
                            <ActionItem>
                                <HeaderButton title={ct('add')} onClick={() => { props.setTimeTrackIdForEdit(""); props.setShowAddTimeTrackingModal(true) }} icon={<HiPlus color='#fff' size={18} />} />
                            </ActionItem>
                        </>
                    }
                    <ActionItem>
                        <CircleBtnWithIcon data-tooltip-id="my-tooltip" onClick={() => props.setCheckRadio('home')} data-tooltip-content={tpt('overviewTooltip')} className={`Btn ${props.checkRadio === "home" ? "ActiveRad" : ""}`}><BsFillHouseDoorFill /> </CircleBtnWithIcon>
                    </ActionItem>
                    <ActionItem>
                        <HeaderRadio onChange={() => props.setCheckRadio('open')} data-tooltip-id="my-tooltip" data-tooltip-content={tpt('unconfirmedTimesTooltip')} name='check' type='radio' checked={props.checkRadio === "open" ? true : false} />
                    </ActionItem>
                    <ActionItem>
                        <HeaderRadio onChange={() => props.setCheckRadio('confirm')} data-tooltip-id="my-tooltip" data-tooltip-content={tpt('confirmedTimesTooltip')} name='check' type='radio' checked={props.checkRadio === "confirm" ? true : false} />
                    </ActionItem>
                    {
                        currentView === Constants.layoutType.Admin &&
                        <>
                            <ActionItem>
                                <HeaderButton title={ct('export')} onClick={() => { setShowExportModal(true) }} icon={<BiExport color='#fff' size={18} className='rotate90deg' />} />
                            </ActionItem>
                        </>
                    }
                    <ActionItem>
                        {/* <CheckSelectFilter listoptions={SelectCheckData} placeholder="Select Period" /> */}
                        <SelectPeriodDropdownFilter handelDateFilter={props.handelDateFilter} />
                    </ActionItem>
                    <ActionItem>
                        <WorkAreaCheckSelectFilter />
                    </ActionItem>
                    {
                        currentView === Constants.layoutType.Admin && user.roles.some(x => x === Constants.roles.AccountAdmin || x === Constants.roles.WorkspaceAdmin || x === Constants.roles.Admin) ?
                            <ActionItem onClick={() => props.UpdateStatusAllTimeTracks()}>
                                <HeaderButton btnLight={true} title={tpt('confirmTimeTracking')} />
                            </ActionItem>

                            //   <Row>
                            //         <Col md={12}>
                            //             <Nav className="justify-content-end" activeKey="/home">
                            //                 <Nav.Item>
                            //                     <Nav.Link href="#" onClick={() => props.UpdateStatusAllTimeTracks()}>
                            //                         <span className="HeaderLinkText">Confirm all time tracking </span>
                            //                     </Nav.Link>
                            //                 </Nav.Item>
                            //             </Nav>
                            //         </Col>
                            //     </Row>
                            : null
                    }


                </ActionsBox>
            </SubHeaderWrapper>
            {
                showExportModal === true ?
                    <ExportTimeTracking show={showExportModal} onHide={setShowExportModal} />
                    : null
            }
        </>
    );
}

export default TimeTrackingHeader;

function ExportTimeTracking(props) {
    const { t: ct } = useTranslation('common');
    const { t: tpt } = useTranslation('timeTrackingPage');


    const [Add_column_with_employee_staff_number, setAdd_column_with_employee_staff_number] = useState(true);
    const [Include_time_trackings_related_to_archived_working_areas, setInclude_time_trackings_related_to_archived_working_areas] = useState(true);
    const [Export_all_time_tracking_within_the_selected_range, setExport_all_time_tracking_within_the_selected_range] = useState(false);
    const [StartDate, setStartDate] = useState(new Date());
    const [EndDate, setEndDate] = useState(new Date());
    const [DisabledInput, setDisabledInput] = useState(false);

    const ExportTimeTracking = async (format, type) => {
        var data = {
            Export_all_time_tracking_within_the_selected_range: Export_all_time_tracking_within_the_selected_range,
            Include_time_trackings_related_to_archived_working_areas: Include_time_trackings_related_to_archived_working_areas,
            Add_column_with_employee_staff_number: Add_column_with_employee_staff_number,
            reportType: type,
            format: format,
            StartDate: moment(StartDate).startOf('day'),
            EndDate: moment(EndDate).startOf('day')
        }
        if (type === "ZIP") {
            var response = await CallAxios("/api/TimeTracking/TimeTrackingsExport|post", data);
            toast.success(tpt('errorMsg.requestFileZip'))
            props.onHide(false)
        } else {
            var response = await CallAxiosBlob("/api/TimeTracking/TimeTrackingsExport|post", data);
            if (!!response && response.status === 200) {
                if (format === 'CSV') {
                    saveAs(response.data, "TimeTracking.csv")
                }
                else if (format === 'XLSX') {
                    saveAs(response.data, "TimeTracking.xlsx")
                }
            }
        }
    }
    return (
        <>
            <Modal show={props.show} onHide={() => props.onHide(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> {tpt('selectExportMdl.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="pb-3">
                        <Col md={12}>
                            <Form.Group className="mb-2">
                                <Form.Check className="fw-bold" checked={Export_all_time_tracking_within_the_selected_range} onChange={() => { setDisabledInput(!DisabledInput); setExport_all_time_tracking_within_the_selected_range(!Export_all_time_tracking_within_the_selected_range) }} label={tpt('selectExportMdl.exportAllList1')} type="checkbox" id="CheckExportAll" />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="ExportAbsenceForm.txtStartDate">
                                <div><DatePicker className="form-control" selected={StartDate} onChange={date => setStartDate(date)} PlaceholderText={ct('startDate')} disabled={!DisabledInput} /></div>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="ExportAbsenceForm.txtEndDate">
                                <div><DatePicker className="form-control" selected={EndDate} onChange={date => setEndDate(date)} PlaceholderText={ct('endDate')} disabled={!DisabledInput} /></div>
                            </Form.Group>
                        </Col>
                        <Col md={12} className='mt-2'>
                            <Form.Group className="mb-0">
                                <Form.Check className="fw-bold" checked={Include_time_trackings_related_to_archived_working_areas} onChange={() => setInclude_time_trackings_related_to_archived_working_areas(!Include_time_trackings_related_to_archived_working_areas)} label={tpt('selectExportMdl.exportAllList2')} type="checkbox" id="CheckdeactivatedWorkspace" />
                            </Form.Group>
                            <Form.Group className="mb-0">
                                <Form.Check className="fw-bold" checked={Add_column_with_employee_staff_number} onChange={() => setAdd_column_with_employee_staff_number(!Add_column_with_employee_staff_number)} label={tpt('selectExportMdl.exportAllList3')} type="checkbox" id="CheckStaffNumber" />
                            </Form.Group>
                        </Col>

                    </Row>
                    <Row className="ExportDDContainer mb-2">
                        <Col md={3}>
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic" className="btnPrimaryGreen minWidthDD">
                                    {tpt('selectExportMdl.minimalExport')}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#" onClick={() => ExportTimeTracking("XLSX", "Minimal")}>Excel</Dropdown.Item>
                                    <Dropdown.Item href="#" onClick={() => ExportTimeTracking("CSV", "Minimal")}>CSV</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        <Col md={6}>
                            <div className="InfoTextDD text-break"> {tpt('selectExportMdl.minimalExportPara')}</div>
                        </Col>
                    </Row>
                    <Row className="ExportDDContainer mb-2">
                        <Col md={3}>
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic" className="btnPrimaryGreen minWidthDD">
                                    {tpt('selectExportMdl.simpleExport')}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#" onClick={() => ExportTimeTracking("XLSX", "Simple")}>Excel</Dropdown.Item>
                                    <Dropdown.Item href="#" onClick={() => ExportTimeTracking("CSV", "Simple")}>CSV</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        <Col md={6}>
                            <div className="InfoTextDD text-break"> {tpt('selectExportMdl.simpleExportPara')}</div>
                        </Col>
                    </Row>
                    <Row className="ExportDDContainer mb-2">
                        <Col md={3}>
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic" className="btnPrimaryGreen minWidthDD">
                                    {tpt('selectExportMdl.detailExport')}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#" onClick={() => ExportTimeTracking("XLSX", "Detail")}>Excel</Dropdown.Item>
                                    <Dropdown.Item href="#" onClick={() => ExportTimeTracking("CSV", "Detail")}>CSV</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        <Col md={6}>
                            <div className="InfoTextDD text-break">  {tpt('selectExportMdl.detailExportPara')}</div>
                        </Col>
                    </Row>
                    <Row className="ExportDDContainer mb-2">
                        <Col md={3}>
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic" className="btnPrimaryGreen minWidthDD">
                                    {tpt('selectExportMdl.zipExport')}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#" onClick={() => ExportTimeTracking("XLSX", "ZIP")}>Excel</Dropdown.Item>
                                    <Dropdown.Item href="#" onClick={() => ExportTimeTracking("CSV", "ZIP")}>CSV</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        <Col md={6}>
                            <div className="InfoTextDD text-break">  {tpt('selectExportMdl.zipExportPara')}</div>
                        </Col>

                    </Row>

                </Modal.Body>
            </Modal>
        </>
    )
}