import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import {
  Navigate,
  Route, Routes, useLocation, useNavigate
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { Forbidden401 } from '../Components/Forbidden401';
import { Forbidden403 } from '../Components/Forbidden403';
import PageNotFound from '../Components/PageNotFound';
import { SignInManager } from '../Helpers/Authentication/SignInManager';
import { Mainlayout } from '../Layout/Mainlayout';
import { ChangePassword } from '../Pages/Accounts/ChangePassword';
import { Login } from '../Pages/Accounts/Login';
import { Register } from '../Pages/Accounts/Register';

import { HubConnectionBuilder } from '@microsoft/signalr';
import Alert from 'react-bootstrap/Alert';
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { ThemeProvider } from 'styled-components';
import ChatBox from '../Components/ChatBot';
import Loader from '../Components/Loader';
import { AxiosLite, CallAxios, CallAxiosNoloader } from '../Helpers';
import { config } from '../Helpers/Config';
import { Constants } from '../Helpers/Contents';
import { HooksHelper } from '../Helpers/HooksHelper';
import { SwitchLocation, UpdateReduxStates } from '../Helpers/MasterData';
import { SignalrHelper } from '../Helpers/SignalrHelper';
import { Accountlayout } from '../Layout/AccountLayout';
import AbsencesList from '../Pages/Absences/AbsencesList';
import AccountSettings from '../Pages/AccountSettings';
import APITab from '../Pages/AccountSettings/APITab';
import AvailibilityValidations from '../Pages/AccountSettings/AvailibilityValidations';
import AccBaseDataTab from "../Pages/AccountSettings/BaseDataTab";
import DataProfileTab from '../Pages/AccountSettings/DataProfileTab';
import DisplayTab from '../Pages/AccountSettings/DisplayTab';
import ExpotImportIndex from '../Pages/AccountSettings/ExpotImport/ExpotImportIndex';
import LocationDetail from '../Pages/AccountSettings/Location/LocationDetail';
import LocationList from '../Pages/AccountSettings/Location/LocationList';
import LocationTemplateTab from '../Pages/AccountSettings/LocationTemplateTab';
import MoreSettingTab from '../Pages/AccountSettings/MoreSettingTab';
import SearchIndex from '../Pages/AccountSettings/Search/SearchIndex';
import SubscriptionIndex from '../Pages/AccountSettings/Subscriptions/SubscriptionIndex';
import WorkSchedules from '../Pages/AccountSettings/WorkSchedules';
import AdminLogin from '../Pages/Accounts/AdminLogin';
import Invites from '../Pages/Accounts/Invites';
import { ResetPassword } from '../Pages/Accounts/ResetPassword';
import Verifyemail from '../Pages/Accounts/Verifyemail';
import CalendarPage from '../Pages/Calendar/Index';
import EmployeeList from '../Pages/Employee/EmployeeList';
import LocationSettings from '../Pages/LocationSettings';
import AbsenceTabss from '../Pages/LocationSettings/Absence/AbsenceTab';
import BaseDataTab from '../Pages/LocationSettings/BaseData/BaseDataTab';
import EmployeeTab from '../Pages/LocationSettings/Employee/EmployeeTab';
import PlanningTab from '../Pages/LocationSettings/Planning/PlanningTab';
import ThemeSetting from '../Pages/LocationSettings/Theme/ThemeSetting';
import TimeTrackingTabsss from '../Pages/LocationSettings/TimeTracking/TimeTrackingTab';
import WorkFlowTab from '../Pages/LocationSettings/WorkFlows/WorkFlowTab';
import MessagesView from '../Pages/Messages/Messages';
import Profile from '../Pages/Profile';
import AbsenceTab from '../Pages/Profile/AbsenceTab';
import Assignments from '../Pages/Profile/Assignments';
import AvailabilitiesTab from '../Pages/Profile/AvailabilitiesTab';
import CalendarSynchronization from '../Pages/Profile/CalendarSynchronization';
import Documents from '../Pages/Profile/Documents';
import GeneralTab from '../Pages/Profile/GeneralTab';
import NotificationTab from '../Pages/Profile/NotificationTab';
import RecordsTab from '../Pages/Profile/RecordsTab';
import TargetHours from '../Pages/Profile/TargetHours';
import TimeTrackingTab from '../Pages/Profile/TimeTrackingTab';
import VocationTab from '../Pages/Profile/VocationTab';
import Recurrences from '../Pages/Recurrences/RecurrencesIndex';
import Reports from '../Pages/Reports/ReportsIndex';
import TimeTrackingList from '../Pages/TimeTracking/TimeTrackingList';
import { setAbsenceCounterBadge, setPendingTimetrackCount, setRunningTimetrackCount, setUser } from '../Store/slices/AppSlice';
import './App.css';
import { useTranslation } from 'react-i18next';
import InvoiceList from '../Pages/AccountSettings/Invoice/InvoiceList';

export const App = () => {

  const titlePrefix = ' | Basta-Shift';
  const jwt = useSelector(s => s.app.jwt);
  const ParseJwt = new SignInManager().ParseJwt;
  const appSettings = useSelector(s => s.app.appSettings);
  const baseURL = config.API_URL;
  const themeColor = useSelector(state => state.app.theme);
  var user = useSelector(x => x.app.user);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const [CommpnHubConnection, setCommpnHubConnection] = useState(null);
  HooksHelper.navigate = useNavigate();
  HooksHelper.dispatch = dispatch;
  let navigate = useNavigate();
  useEffect(() => {
    if (!!jwt.token && jwt.token !== ".") {
      onLoadToken()
      // if (ParseJwt.modules.split(',').some(x => x === Constants.SubscriptionModulesConstants.SupportForEmployees)) {

      // }
      const newConnection = new HubConnectionBuilder()
        .withUrl(baseURL + '/commonHub', { accessTokenFactory: () => jwt.token })
        .withAutomaticReconnect()
        .build();
      setCommpnHubConnection(newConnection);
      SignalrHelper.RoleHubConnection = newConnection;
    }
  }, [jwt.token]);

  const onLoadToken = async () => {
    await UpdateReduxStates(new SignInManager().ParseJwt, dispatch)
    var tokenData = new SignInManager().ParseJwt;
    if (parseInt(tokenData.subscriptionStatus) === Constants.SubscriptionStatus.Expire) {
      const isAuthenticated = new SignInManager().IsAuthenticated;
      if (isAuthenticated) {
        navigate('/account/' + tokenData.CompanyId + '/subscriptions');
      }
    }
  }
  useEffect(() => {
    i18n.changeLanguage(appSettings.Language)
  }, [appSettings.Language]);

  const [RoleSignalRTrigger, setRoleSignalRTrigger] = useState(0);
  useEffect(() => {
    if (CommpnHubConnection) {
      CommpnHubConnection.start()
        .then(result => {
          CommpnHubConnection.on('absence_badge_counter_Changed', resp => {
            GetPendingAbsenceBadgeCounter()
          });
          CommpnHubConnection.on('role_changed', resp => {
            setRoleSignalRTrigger(RoleSignalRTrigger + 1)
          });
          CommpnHubConnection.on('deactivate_employee', resp => {
            UpdateLocationAccess()
          });
          CommpnHubConnection.on('vocationQuotta_record_update', resp => {
            UpdateCurrentHoliday()
          });
          CommpnHubConnection.on('workinghour_record_update', resp => {
            UpdateCurrentRecord()
          });
          CommpnHubConnection.on('new_chat_message', resp => {
            updateMessageCounter()
          });
          CommpnHubConnection.on('change_access', resp => {
            UpdateLocationAccess()
          });
          CommpnHubConnection.on('timetracking_badge_update', resp => {
            UpdateTimeTrackBadge()
          });
        })
        .catch(e => { console.log('Connection failed: ', e) });
    }
  }, [CommpnHubConnection]);

  useEffect(() => {
    if (RoleSignalRTrigger > 0) {
      updateRole()
    }
  }, [RoleSignalRTrigger]);
  const GetPendingAbsenceBadgeCounter = async () => {
    var response = await CallAxiosNoloader("/api/Absence/pendingAbsenceCounter|post", { id: localStorage.getItem("LoginUserId") })
    if (!!response && response.status === 200) {
      dispatch(
        setAbsenceCounterBadge(response.data)
      )
    }
  }
  const updateRole = async () => {
    SwitchLocation(user.LocationId, appSettings)
  }
  const UpdateLocationAccess = async () => {
    new SignInManager().Logout(navigate);
  }
  const UpdateCurrentRecord = async () => {
    var data = {
      id: localStorage.getItem("LoginUserId")
    }
    var response = await CallAxiosNoloader("/api/Employee/GetCurrentRecord|post", data)
    if (!!response && response.status === 200) {
      dispatch(
        setUser({
          CurrentBalance: response.data,
        })
      );
    }
  }
  const UpdateCurrentHoliday = async () => {
    var data = {
      id: localStorage.getItem("LoginUserId")
    }
    var response = await CallAxiosNoloader("/api/Employee/GetRemainingVocationQuota|post", data)
    if (!!response && response.status === 200) {
      dispatch(
        setUser({
          CurrentVocationBalance: response.data,
        })
      );
    }
  }
  const updateMessageCounter = async () => {
    var data = {
      id: localStorage.getItem("LoginUserId")
    }
    // var response = await dynamicPostApi(data, "/api/Chat/UpdateMessageCounter")
    var response = await CallAxiosNoloader("/api/Chat/UpdateMessageCounter|post", data)
    if (!!response && response.status === 200) {
      dispatch(
        setUser({
          messageCounter: response.data,
        })
      );
    }
  }
  const UpdateTimeTrackBadge = async () => {
    var response = await CallAxiosNoloader("/api/timetracking/GetTimeTrackingBadge|get")
    if (!!response && response.status === 200) {
      dispatch(
        setPendingTimetrackCount(response.data.pending)
      )
      dispatch(
        setRunningTimetrackCount(response.data.running)
      )
    }
  }



  const titleMap = [
    { path: '/', title: 'Site' },
    { path: '/login', title: 'Login' },
    { path: '/absences', title: 'Absences' },
    { path: '/calendar', title: 'Calendar' },
  ];
  const curLoc = useLocation();
  useEffect(() => {
    const curTitle = titleMap.find(item => item.path.startsWith(curLoc.pathname.toLowerCase()))
    if (curTitle && curTitle.title) {
      document.title = curTitle.title + titlePrefix
    } else {
      document.title = 'Default' + titlePrefix
    }
  }, [curLoc, titleMap])

  return (
    <div className="App">
      <ThemeProvider theme={themeColor}>
        <Tooltip id="my-tooltip" />
        <Tooltip id="my-tooltip-click" openOnClick={true} />
        {
          (new SignInManager().IsAuthenticated && !!appSettings && !!appSettings.locationsettings.TimeZone && appSettings.locationsettings.TimeZone !== new Date().toString().split("(")[1].split(")")[0]) &&
          <Alert variant="danger" style={{ textAlign: "center" }}>
            The set time zone is different from the time zone of your device. <a href='javascript:;' onClick={() => navigate('/companies/' + user.CompanyId + '/locationsettings/master_data')}>Click here</a> to change the time zone.
          </Alert>

        }
        <Loader />
        {
          (new SignInManager().IsAuthenticated && ParseJwt.modules && ParseJwt.modules.split(',').some(x => x === Constants.SubscriptionModulesConstants.SupportForEmployees)) &&
          <ChatBox />
        }
        <ToastContainer limit={2} newestOnTop theme='light' />
        <Routes>

          <Route path="/companies/:companyid/" element={<Mainlayout />}>
            <Route index path='users' element={<ProtectedRoute role={[Constants.roles.AccountAdmin, Constants.roles.WorkspaceAdmin, Constants.roles.Admin]}><EmployeeList /></ProtectedRoute>} />
            <Route path='absences' element={<ProtectedRoute module={[Constants.SubscriptionModulesConstants.Absences]}><AbsencesList /></ProtectedRoute>} />
            <Route path='timetracking' element={<ProtectedRoute module={[Constants.SubscriptionModulesConstants.TimeTracking]}><TimeTrackingList /></ProtectedRoute>} />
            <Route path='calendar' element={<ProtectedRoute module={[Constants.SubscriptionModulesConstants.ShiftPlanner]} ><CalendarPage /></ProtectedRoute>} />
            <Route path='locationsettings' element={<ProtectedRoute><LocationSettings /></ProtectedRoute>}>
              <Route index path='master_data' element={<BaseDataTab />} />
              <Route path='planning' element={<PlanningTab />} />
              <Route path='absence' element={<AbsenceTabss />} />
              <Route path='time_trackings' element={<TimeTrackingTabsss />} />
              <Route path='workflows' element={<WorkFlowTab />} />
              <Route path='views' element={<EmployeeTab />} />
            </Route>
            <Route path='messages' element={<ProtectedRoute><MessagesView /></ProtectedRoute>} />
            <Route path='recurrences' element={<ProtectedRoute><Recurrences /></ProtectedRoute>} />
            <Route path='reports' element={<ProtectedRoute><Reports /></ProtectedRoute>} />
            {/* <Route path='users/:userid/tickets' element={<ProtectedRoute><TicketsList /></ProtectedRoute>} /> */}
            <Route path='users/:userid/' element={<ProtectedRoute><Profile /></ProtectedRoute>}>
              <Route index path='master_data' element={<GeneralTab />} />
              <Route path='master_data' element={<GeneralTab />} />
              <Route path='target_hours' element={<TargetHours />} />
              <Route path='events' element={<Assignments />} />
              <Route path='time_trackings' element={<TimeTrackingTab />} />
              <Route path='evaluations' element={<RecordsTab />} />
              <Route path='availabilities' element={<AvailabilitiesTab />} />
              <Route path='absences' element={<AbsenceTab />} />
              <Route path='vacations' element={<VocationTab />} />
              <Route path='notifications' element={<NotificationTab />} />
              <Route path='documents' element={<Documents />} />
              <Route path='authentications' element={<CalendarSynchronization />} />
            </Route>
            <Route path='changepassword' element={<ProtectedRoute><ChangePassword /></ProtectedRoute>} />
          </Route>

          <Route element={<Accountlayout />}>
            <Route path='account/:companyid/accountsettings' element={<ProtectedRoute role={[Constants.roles.AccountAdmin]}><AccountSettings /></ProtectedRoute>}>
              <Route index path='master_data' element={<AccBaseDataTab />} />
              <Route path='further' element={<MoreSettingTab />} />
              <Route path='display' element={<DisplayTab />} />
              <Route path='api' element={<APITab />} />
              <Route path='data_profiles' element={<DataProfileTab />} />
              <Route path='themes' element={<ThemeSetting />} />
              <Route path='location_templates' element={<LocationTemplateTab />} />
              <Route path='work_schedules' element={<WorkSchedules />} />
              <Route path='availabilities_rules' element={<AvailibilityValidations />} />
            </Route>
            <Route path='account/:companyid/LocationDetail/:locationid' element={<ProtectedRoute role={[Constants.roles.AccountAdmin]}><LocationDetail /></ProtectedRoute>} />
            <Route path='account/:companyid/locationoverview' element={<ProtectedRoute role={[Constants.roles.AccountAdmin]}><LocationList /></ProtectedRoute>} />
            <Route path='account/:companyid/invoices' element={<ProtectedRoute role={[Constants.roles.AccountAdmin]}><InvoiceList /></ProtectedRoute>} />
            <Route path='account/:companyid/search' element={<ProtectedRoute role={[Constants.roles.AccountAdmin]}><SearchIndex /></ProtectedRoute>} />
            <Route path='account/:companyid/exchange' element={<ProtectedRoute role={[Constants.roles.AccountAdmin]}><ExpotImportIndex /></ProtectedRoute>} />
            <Route path='account/:companyid/subscriptions' element={<ProtectedRoute name="Subscription"><SubscriptionIndex /></ProtectedRoute>} />
          </Route>



          {/* <Route path='/:formId/viewer' element={<PreviewForm />} /> */}

          {/* <Route path="/editForm/:formId" element={<Outlet />}>
          <Route index element={<ProtectedRoute><EditFormLayout /></ProtectedRoute>} />
          <Route path=":id" element={<ProtectedRoute><EditFormLayout /></ProtectedRoute>} />
          <Route path="preview" element={<ProtectedRoute><PreviewForm /></ProtectedRoute>} />
        </Route> */}
          {/* <Route path="/subusers" element={<ProtectedRoute role={['Admin', 'Client']}><Subusers /></ProtectedRoute>} /> */}
          <Route path='/' element={<CheckAuthentication><Login /></CheckAuthentication>} />
          <Route path='/login' element={<CheckAuthentication><Login /></CheckAuthentication>} />
          <Route path='/register' element={<Register />} />
          <Route path="/verifyemail" element={<Verifyemail />} />
          <Route path='/Passwordreset' element={<ResetPassword />} />
          <Route path='/Invites' element={<Invites />} />
          <Route path="/adminlogin" element={<AdminLogin />} />

          <Route path="/forbidden401" element={<Forbidden401 />} />
          <Route path="/forbidden403" element={<Forbidden403 />} />
          <Route path="*" element={<PageNotFound />} />

        </Routes>

      </ThemeProvider>
    </div >
  );
}

function CheckAuthentication(props) {
  const { children } = props;
  var navigate = useNavigate();
  const isAuthenticated = new SignInManager().IsAuthenticated;
  const ParseJwt = new SignInManager().ParseJwt;

  if (isAuthenticated) {
    // UpdateReduxStates(ParseJwt, dispatch)
    navigate(`/companies/${ParseJwt.CompanyId}/users/${ParseJwt.EmpID}/master_data`)
    // navigate(`/companies/${ParseJwt.CompanyId}/calendar`);
  } else {
    return children;
  }
}

export function ProtectedRoute(props) {
  const { children } = props;
  const location = useLocation();
  const isAuthenticated = new SignInManager().IsAuthenticated;
  const role = new SignInManager().UserRole;
  const jwt = new SignInManager().ParseJwt;

  // const GetPendingAbsenceBadgeCounter = async () => {
  //   var response = await CallAxios("/api/Absence/pendingAbsenceCounter|post", { id: jwt.EmpID })
  //   if (!!response && response.status === 200) {
  //     dispatch(
  //       setAbsenceCounterBadge(response.data)
  //     )
  //   }
  // }
  // useEffect(() => {
  //   if (appSettings.SubscriptionStatus === Constants.SubscriptionStatus.Paid) {
  //     if (isAuthenticated) {
  //       GetPendingAbsenceBadgeCounter();
  //     }
  //   }
  // }, [])
  if (!isAuthenticated || (props.role && role && !props.role.some(x => role.split(",").includes(x)))) {
    const rUri = location.pathname;
    return <Navigate to={'/login?redirect_url=' + rUri} replace />;
  }
  //  else {
  //   GetPendingAbsenceBadgeCounter();

  // }
  if ((props.module && !props.module.some(x => jwt.modules.split(",").includes(x)))) {
    return <Navigate to={'/companies/' + jwt.CompanyId + '/users/' + jwt.EmpID + '/master_data'} replace />;
  }



  return children;
};
