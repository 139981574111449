import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row, Tab, Table, Tabs } from "react-bootstrap";
import { FaPlus, FaRegTrashAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import SelectInput from 'react-select';
import { toast } from "react-toastify";
import * as Yup from "yup";
import { EditWorkspaceColor } from "../../../CommonJSON";
import { Gear, Note, Remove } from "../../../Components/ActionBtns";
import CollapseableDiv from "../../../Components/CollapseCard";
import HeaderButton from "../../../Components/HeaderButton";
import NotesModal from "../../../Components/NoteModal";
import TblSwitchToogleButton from "../../../Components/TblSwitchToogleButton";
import { CallAxios } from "../../../Helpers";
import { SignInManager } from "../../../Helpers/Authentication/SignInManager";
import { ActiveInactiveStatusBox, DOT, TableWrapper } from "../../../Helpers/Global/StyledComponents";
import { GetEmployeeDD, UpdateReduxStates } from "../../../Helpers/MasterData";
import { useTranslation } from "react-i18next";
import DeleteConfirmationModal from "../../../Components/DeleteConfirmationModal";

function Tags(props) {
    const { t: ct } = useTranslation('common');
    const { t: Lst } = useTranslation('locationSetting');


    const dispatch = useDispatch();
    const [newTagsModal, setnewTagsModal] = useState(false);
    const [NoteModal, setNotesModal] = useState(false);
    const [TagIdForedit, setTagIdForedit] = useState("");
    const [ShowDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

    const onchangeStatusSubmit = async (e, status) => {
        var data = {
            id: e.id,
            status: status
        }
        var response = await CallAxios("/api/LocationSetting/ChangeTagsStatus|post", data)
        if (!!response && response.data.length > 0) {
            props.setTagsList(response.data)
            await UpdateReduxStates(new SignInManager().ParseJwt, dispatch)

        }
    }
    const onDeleteSubmit = async (id) => {
        var response = await CallAxios("/api/LocationSetting/DeleteTags|post", { id: id })
        if (!!response) {
            props.setTagsList(response.data)
            await UpdateReduxStates(new SignInManager().ParseJwt, dispatch)
            setShowDeleteConfirmationModal(false)
        }
    }

    return (
        <>
            {
                ShowDeleteConfirmationModal &&
                <DeleteConfirmationModal show={ShowDeleteConfirmationModal} onHide={setShowDeleteConfirmationModal}
                    title={Lst('planTab.tagAccor.deleteMdl.title')} message={Lst('planTab.tagAccor.deleteMdl.desc')} deletefn={onDeleteSubmit} objId={TagIdForedit} />
            }
            <CollapseableDiv title={Lst('planTab.tagAccor.tags')}>
                <Row>

                    <Col md={12}>
                        <div className="TabFormContainerSection">
                            <div className='d-flex justify-content-between align-items-center my-3'>
                                <p className="mb-0 w-75">
                                    {Lst('planTab.tagAccor.Desc')}.
                                </p>
                                <HeaderButton title={Lst('planTab.tagAccor.newTag')} onClick={(e) => { setTagIdForedit(""); setnewTagsModal(true) }} />
                            </div>
                            <TableWrapper>


                                <Table striped bordered className="table table-striped table-bordered">
                                    <thead>
                                        <tr className='firsthead'>
                                            <th> {Lst('planTab.tagAccor.name')}</th>
                                            <th> {Lst('planTab.tagAccor.status')}</th>
                                            <th> {Lst('planTab.tagAccor.actions')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!!props.TagsList && props.TagsList.length > 0 && props.TagsList.map((val, index) => {

                                            return <tr key={"tableList_" + index}>
                                                <td>
                                                    <div className="dataTextCell">{!!val.color ? <DOT color={val.color} /> : null}{val.name}</div>
                                                </td>
                                                <td>
                                                    <div className="dataTextCell">
                                                        <Button className={val.status === true ? "badgesuccess w-100" : "badgewarning w-100"} status={val.status}>{val.status === true ? <>{ct('active')}</> : <>{ct('inActive')}</>}</Button>
                                                    </div>
                                                </td>
                                                <td>
                                                    <Gear onClick={(e) => { setTagIdForedit(val.id); setnewTagsModal(true) }} />
                                                    <Note onClick={(e) => { setTagIdForedit(val.id); setNotesModal(true) }} />
                                                    <TblSwitchToogleButton status={val.status} val={val} onClicksubmit={onchangeStatusSubmit} />
                                                    <Remove onClick={(e) => { setTagIdForedit(val.id); setShowDeleteConfirmationModal(true) }} />

                                                </td>
                                            </tr>
                                        }
                                        )}
                                    </tbody>
                                </Table>
                            </TableWrapper>
                        </div>
                    </Col>
                </Row>
                {
                    newTagsModal === true ?
                        <AddEditNewTags show={newTagsModal} setshow={setnewTagsModal} setTagsList={props.setTagsList} TagIdForedit={TagIdForedit} />
                        : null
                }
                {
                    NoteModal === true ?
                        <TagsNoteModel show={NoteModal} onHide={setNotesModal} objIdforNote={TagIdForedit} noteEntity="Tags" />
                        : null
                }
            </CollapseableDiv>
        </>
    )
}

export default Tags;

function AddEditNewTags(props) {
    const { t: ct } = useTranslation('common');
    const { t: Lst } = useTranslation('locationSetting');


    const dispatch = useDispatch();
    const DropdownIndicator = () => null;
    const [SelectValue, setSelectValue] = useState("");
    const [getEmployeeIds, setEmployeeIds] = useState([]);
    const [options, setoptions] = useState([
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]);

    const formik = useFormik({
        initialValues: {
            name: '',
            color: '',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(ct('required').toString())
        }),
        onSubmit: values => {
            submit(values)
        },
    });
    useEffect(() => {
        GetEmployeeDD(setoptions)
        if (!!props.TagIdForedit) {
            getData()
        }
    }, []);
    const getData = async () => {
        var response = await CallAxios(
            "/api/LocationSetting/getTags|post", { id: props.TagIdForedit }
        );
        if (!!response && response.data) {
            formik.setFieldValue("name", response.data.name);
            formik.setFieldValue("color", response.data.colour);

            let selectdEmpids = [];
            if (response.data.empObj.length > 0) {
                response.data.empObj.map((val, key) => {
                    selectdEmpids.push(val.value)
                })
                setEmployeeIds(selectdEmpids);
                setSelectValue(response.data.empObj);
            }
        }
    }

    const submit = async (values) => {
        var data = {
            id: props.TagIdForedit,
            name: values.name,
            colour: values.color,
            empIds: getEmployeeIds
        };
        var response = await CallAxios(
            "/api/LocationSetting/AddEditTags|post", data
        );
        if (!!response && response.data !== "SameTag") {
            props.setTagsList(response.data)
            await UpdateReduxStates(new SignInManager().ParseJwt, dispatch)

            props.setshow(false)
        } else {
            toast.info(Lst("planTab.tagAccor.sameTagError"))
        }
    };

    const GetColor = (e) => {
        let siblings = e.target.parentElement.children;
        for (let sib of siblings) {
            sib.classList.remove('selected')
        }
        e.target.classList.add("selected");
        formik.setFieldValue("color", e.target.getAttribute("data-color"))
    }

    return (
        <Modal show={props.show} className="" onHide={() => props.setshow(false)}>
            <Modal.Header closeButton className="">
                <Modal.Title>{!!props.TagIdForedit ? <>{ct('edit')}</> : <>{ct('createNew')}</>}  <>{ct('tag')}</></Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Tabs
                    defaultActiveKey="Ideas"
                    transition={false}
                    id="WorkSpaceTabs"
                    className="mb-3 nav-underline"
                >
                    <Tab eventKey="Ideas" title={Lst('planTab.newWorkMdl.ideas')}>
                        <Form.Group className="position-relative">
                            <label className='fw-bold' > {Lst('planTab.newWorkMdl.titleLabel')} </label>
                            <Form.Control type="text" name="name" maxLength={150} value={formik.values.name} onChange={formik.handleChange} className="ThickBorder my-0 pr40" />
                            {formik.touched.name && formik.errors.name ? (
                                <span className="error text-danger">{formik.errors.name}</span>
                            ) : null}
                        </Form.Group>
                        {
                            !!props.TagIdForedit &&
                            <Form.Group className="position-relative mt-2">
                                <label className='fw-bold' > {Lst('planTab.newWorkMdl.color')}</label>
                                <div className="position-relative">
                                    <p><span className='text-danger'> {Lst('planTab.newWorkMdl.caution')}</span>: {Lst('planTab.newWorkMdl.cautionPara')}!</p>
                                    <span className='SimpleColorPicker'>
                                        {EditWorkspaceColor.map((value, index) => {
                                            return <span onClick={GetColor} className={`${value.class} ${formik.values.color === value.dataColor ? "selected" : null}`} title={value.title} data-color={value.dataColor} style={{ backgroundColor: value.dataColor }}></span>
                                        }
                                        )}
                                    </span>
                                </div>
                            </Form.Group>
                        }


                    </Tab>
                    <Tab eventKey="Employees" title={ct('employee')}>
                        <div className='d-flex mb-2 justify-content-between align-items-center'>
                            <a className='btn btn-primary transparentBtnGreen' onClick={(e) => { setSelectValue(options); setEmployeeIds(options.map((x) => x.value)) }}><FaPlus className="me-1" />  {ct('addAll')}</a>
                            <a className='btn btn-primary transparentBtnred' onClick={(e) => { setSelectValue(""); setEmployeeIds([]) }}><FaRegTrashAlt className="me-1" />  {ct('removeAll')}</a>
                        </div>
                        <SelectInput
                            classNamePrefix="MyReactSelect"
                            isMulti
                            isClearable={false}
                            components={{ DropdownIndicator }}
                            options={options}
                            value={SelectValue}
                            styles={{
                                indicatorSeparator: base => ({
                                    ...base,
                                    display: 'none'
                                })
                            }}
                            onChange={(value, action) => {

                                let stringarr = [];
                                for (let i = 0; i < value.length; i++) {
                                    stringarr.push(value[i].value)
                                }
                                setEmployeeIds(stringarr);
                                setSelectValue(value)
                            }}
                        />

                    </Tab>

                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btnPrimaryGreen text-white" onClick={formik.handleSubmit} > {ct('ready')}</button>

            </Modal.Footer>
        </Modal>
    );
}
function TagsNoteModel(props) {
    const { t: ct } = useTranslation('common');

    return (
        <Modal show={props.show} onHide={() => props.onHide(false)}>
            <Modal.Header closeButton >
                <Modal.Title> {ct('note')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col md={12}>
                            <Container fluid>
                                <NotesModal objIdforNote={props.objIdforNote} noteEntity="Tags" />
                            </Container>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-secondary" onClick={() => props.onHide(false)}> {ct('shutdown')}</Button>
            </Modal.Footer>
        </Modal>
    )
}
