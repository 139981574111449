import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import FullCustomCalendarForEmployee from '../../Components/FullCustomCalendar/CalendarForEmployee';
import { CallAxios } from '../../Helpers';
import CreateShift from './RosterModals/CreateShift';
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';


function EmployeeRoster(props) {
    const { t: cl } = useTranslation('calendarPage');
    const { t: ct } = useTranslation('common');

    var selectedTags = useSelector(x => x.app.selectedTags);
    var selectedWorkAreas = useSelector(x => x.app.selectedWorkAreas);
    const user = useSelector(x => x.app.user)
    const appSettings = useSelector(x => x.app.appSettings)
    const [ShowExceedLimitModel, setShowExceedLimitModel] = useState(false);
    const [AddNotesModal, setAddNotesModal] = useState(false);
    const [assignmentList, setAssignmentList] = useState([]);
    const [events, setEvents] = useState([]);
    const [AllassignmentList, setAllassignmentList] = useState([]);
    const [createOwnTimeRecords, setCreateOwnTimeRecords] = useState(false);
    const [filterevents, setFilterevents] = useState([]);
    const [badges, setBadges] = useState([]);
    const [showCalendar, setShowCalendar] = useState(false);
    const [DayClickDate, setDayClickDate] = useState(new Date());
    const [NewShiftModal, setNewShiftModal] = useState(false);
    const [SandBoxDates, setSandBoxDates] = useState();
    useEffect(() => {
        let result = events.sort(dynamicSort("title"));;
        for (let i = 0; i < result.length; i++) {
            result[i].dataIndex = i;
        }
        // setShowCalendar(true)
        setEvents(result)
        setFilterevents(result)
        GetEmpRights()
    }, []);
    useEffect(() => {
        if (!!props.setPopUpDate) {
            gotoPast(props.setPopUpDate)
        }
    }, [props.setPopUpDate]);
    const getShifts = async () => {
        var start = props.refCalander.current.activeRange.start;
        var end = props.refCalander.current.activeRange.end;
        if (!!user.EncLoginUserId && !!start) {
            Promise.all([
                await CallAxios('/api/Calendar/GetEmpTargetAndAssignHourInfo|post', {
                    date: start,
                    emplyeeID: user.EncLoginUserId,
                }),
                await CallAxios('/api/Calendar/GetShifts|post', {
                    starDate: start,
                    endDate: end,
                    view: "Employee"
                }),
                await CallAxios("/api/Calendar/GetempShitApplicaions|post", {
                    empId: user.EncLoginUserId,
                    Satrtdate: start,
                    Enddate: end,
                })
            ]).then((response) => {
                if (!!response[0] && response[0].status === 200) {
                    props.setEmpTargetAndAssignHour(response[0].data.weeklyassignHours, response[0].data.weeklytargetHours)
                }
                setAllassignmentList(response[2].data)
                if (!!response[1] && response[1].status === 200) {
                    if (!!response[2] && response[2].data.length > 0) {
                        response[2].data.map((answer, i) => {
                            var d = response[1].data.calenderShift.filter(x => x.shiftId === response[2].data[i].id)
                            d[0].bgColor = "rgb(241 196 15)"
                        })
                    }
                    let bagesArr = {};
                    bagesArr = response[1].data.absenceCounterBadge.map((event) => ({ ...bagesArr, [event.date]: event.counter }));
                    const obj = Object.assign({}, ...bagesArr);
                    let newEvents = response[1].data.calenderShift.filter(x => x.requiredEmpCount !== x.assignedEmpCount);
                    // let popData = {};
                    // popData = response.data.notedetail.map((event) => ({ ...popData, [event.notecreatedDate]: event }));
                    // const obj2 = Object.assign({}, ...popData);
                    setEvents(props._planningIncomplete === true ? newEvents : response[1].data.calenderShift)
                    setFilterevents(response[1].data.calenderShift)
                    setShowCalendar(true)
                    setBadges(obj)
                    setSandBoxDates(response[1].data.sandBoxDates)
                    props.setAssignmentsAndOfferedShiftTrigger(props.AssignmentsAndOfferedShiftTrigger + 1);

                }
            });
        }
    }
    const GetEmpRights = async () => {
        var response = await CallAxios('/api/Employee/GetEmpRights|post', { id: user.EncLoginUserId })
        if (!!response && response.status === 200) {
            setCreateOwnTimeRecords(response.data.createOwnTimeRecords)
        }
    }
    const dynamicSort = (property) => {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            if (sortOrder === -1) {
                return b[property].localeCompare(a[property]);
            } else {
                return a[property].localeCompare(b[property]);
            }
        }
    }
    const handleOccupancyModal = async (shiftId) => {
        var listdata = events.filter(x => x.shiftId === shiftId)
        var ShiftEndDateTime = moment(listdata[0].date).startOf('day')
            .add(listdata[0].time.split("-")[1].split(":")[0], 'hours')
            .add(listdata[0].time.split("-")[1].split(":")[1], 'minutes');

        var isPreviusDate = moment(ShiftEndDateTime).isBefore(new Date());
        var assignedEmpCount = listdata[0].assignedEmpCount;
        if (listdata[0].empId.some(x => x === user.EncLoginUserId)) {
            assignedEmpCount = assignedEmpCount - 1;
        }
        if (appSettings.locationsettings.applyForFullShifts === false && assignedEmpCount >= listdata[0].requiredEmpCount ? true : false) {
            Swal.fire({
                icon: 'info',
                title: cl("errorMsg.informationTitle"),
                text: cl("errorMsg.informationDesc"),
            })
            return
        }
        if (isPreviusDate === false &&
            (assignedEmpCount < listdata[0].requiredEmpCount ? true : false || AllassignmentList.filter(x => x.id === shiftId).length > 0 ? true : false || appSettings.locationsettings.applyForFullShifts === true)) {

            if (listdata[0].empId.some(x => x === user.EncLoginUserId)) {
                var RemoveShiftdata = {
                    id: shiftId,
                    empid: user.EncLoginUserId
                }
                var RemoveShiftresponse = await CallAxios("/api/Calendar/RemoveFromShiftDirectly|post", RemoveShiftdata)
                if (!!RemoveShiftresponse && RemoveShiftresponse.data.success === true) {
                    getShifts();
                } else {
                    toast.error(RemoveShiftresponse.data.message)
                }
            } else {
                var data = {
                    id: shiftId,
                    empid: user.EncLoginUserId
                }
                var response = await CallAxios('/api/Shift/ApplayForShift|post', data)
                if (!!response && response.status === 200) {
                    if (response.data === true) {
                        getShifts()
                    }
                    else if (response.data === "reached limit") {
                        setShowExceedLimitModel(true)
                    }
                    else if (response.data === "alreadyAssign") {
                        Swal.fire({
                            icon: 'info',
                            title: cl("errorMsg.employeeAlreadyAssigned"),
                        })
                    }
                    else if (response.data === "full_shifts") {
                        Swal.fire({
                            icon: 'info',
                            title: cl("errorMsg.informationDesc"),
                        })
                    }
                    else if (response.data === "Applications_not_allowed") {
                        Swal.fire({
                            icon: 'info',
                            title: cl("errorMsg.applicationsNotAllowedDesc"),
                        })
                    }
                    else if (response.data === "not_part_working_area") {
                        Swal.fire({
                            icon: 'info',
                            title: cl("errorMsg.notPartWorkingAreaDesc"),
                        })
                    }
                    else if (response.data === "confirmed_absence") {
                        Swal.fire({
                            icon: 'info',
                            title: cl("errorMsg.confirmedAbsenceDesc"),
                        })
                    }
                }
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: cl("errorMsg.shiftNotAvailableTitle"),
                text: cl("errorMsg.shiftNotAvailableDesc"),
            })
        }
    }
    const gotoPast = (date) => {
        if (!!props.refCalander.current) {
            let calendarApi = props.refCalander.current;
            calendarApi.gotoDate(!!date ? new Date(date) : new Date()); // call a method on the Calendar object
        }
    };
    const OpenAssignmentModal = (data) => {
        var listdata = events.filter(x => moment(x.date).isSame(data.date, 'day'))
        setAssignmentList(listdata)
        setAddNotesModal(true)
    }
    return (
        <>
            {/* {this.state.NewShiftModal === true ?
                <CreateShift parentThis={this} triggerChang={this.getShifts} />
                : null
            } */}
            {
                ShowExceedLimitModel === true ?
                    <Modal show={ShowExceedLimitModel} onHide={() => setShowExceedLimitModel(false)}>
                        <Modal.Header closeButton >
                            <Modal.Title> {cl("exceedLimitMdl.title")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <label className='fw-bolder'> {cl("exceedLimitMdl.descLabel")} </label>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <a className='text-danger me-3' onClick={() => setShowExceedLimitModel(false)}>{ct("cancel")}</a>
                        </Modal.Footer>
                    </Modal>
                    : null
            }
            <FullCustomCalendarForEmployee
                refCalander={(node) => {
                    props.refCalander.current = node;
                }}
                events={events.filter(x => selectedWorkAreas.some(c => c.value === x.workspaceid) || x.tags.find(v => selectedTags.some(c => c.value === v.label)) || (selectedTags.some(c => c.value === "Withouttags") ? x.tags.length === 0 : false))}
                view={props.view}
                _oneRowPerSeries={props._oneRowPerSeries}
                _showTitle={props._showTitle}
                resourceViewData={props.resourceViewData}
                _fixedHeadline={props._fixedHeadline}
                _hideAssignments={props._hideAssignments}
                _compactCalendar={props._compactCalendar}
                _planningIncomplete={props._planningIncomplete}
                _tags={props._tags}
                _showRequirements={props._showRequirements}
                showborderClass={props.showborderClass}
                filterShifts={props.filterShifts}
                disableOverLappingInDailyView={true}
                interval={30}
                SandBoxDates={SandBoxDates}
                encEmpId={user.EncLoginUserId}
                headerContent={
                    (args, element) => {
                        return <>
                            <a style={{ position: "relative" }} >
                                <span onClick={() => OpenAssignmentModal(args)}>{args.text}</span>
                                <BadgePopover args={args.date} date={badges[moment(args.date).format("DD/MM/YYYY")]} />
                            </a>
                        </>
                    }}
                eventClick={(event, info) => {
                    handleOccupancyModal(info.shiftId)
                }}
                dateClick={(e, date) => {
                    if (createOwnTimeRecords) {
                        setDayClickDate(date)
                        setNewShiftModal(true)
                    }
                }}
                datesSet={
                    async (info) => {
                        getShifts()
                    }
                }
            />
            {NewShiftModal === true ?
                // <CreateShift triggerChang={getShifts} />
                <CreateShift
                    show={NewShiftModal} onHide={setNewShiftModal}
                    SelectedEmployeeId={user.EncLoginUserId}
                    DayClickDate={DayClickDate}
                    triggerChange={getShifts}
                    CreateShiftMode="dayclick"
                    showRepetation={false}
                />
                : null
            }
            {/*Allocation modal popups start*/}
            <Modal show={AddNotesModal} onHide={() => setAddNotesModal(false)}>
                <Modal.Header closeButton className="position-relative">
                    <Modal.Title> {cl("allocationMdl.title")} {user.LocationName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <div className="classicTab">
                                <Tabs className='nav-underline' defaultActiveKey="PossibleAssignments" transition={false} id="noanim-tab-example">
                                    <Tab eventKey="PossibleAssignments" title={cl("allocationMdl.possibleAssignments")} />
                                </Tabs>
                            </div>
                        </Col>
                        {
                            !!assignmentList && assignmentList.length > 0 ?
                                <Col>
                                    <Table hover>
                                        <thead>
                                            <tr>
                                                <th>{ct("time")}</th>
                                                <th>{ct("workSpace")}</th>
                                                <th>{cl("allocationMdl.occupancy")}</th>
                                                <th>{ct("actions")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                assignmentList.map((value, key) => {
                                                    return <tr>
                                                        <td> <span>{moment(value.date).format('ddd, DD/MM')} {value.time}</span> </td>
                                                        <td> <span>{value.title}</span> </td>
                                                        <td> <span className="desginText"> {value.count} </span> </td>
                                                        <td> <Button variant="secondary" onClick={(e) => handleOccupancyModal(value.shiftId)}>{ct("apply")}</Button></td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>

                                    </Table>
                                </Col>
                                :
                                <Col md={10} className="text-center customMessage">
                                    <div> {cl("allocationMdl.noLayerAllocation")}</div>
                                </Col>
                        }

                    </Row>
                </Modal.Body>

            </Modal>
        </>

    );
}

export default EmployeeRoster;

function BadgePopover(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [Counter, setCounter] = useState(null);
    const [AbsenceList, setAbsenceList] = useState([]);

    const handleClick = async (event) => {
        setAnchorEl(event.currentTarget);
        var data = {
            date: new Date(props.args),
        }
        var response = await CallAxios(
            "/api/Calendar/GetAbsenceBadgeDetail|post", data
        );
        if (!!response && response.data.length > 0) {
            setAbsenceList(response.data)
        }
    };
    useEffect(() => {
        setCounter(props.date)
    }, [props.date]);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            {!!props.date && props.data !== 0 ?
                <>
                    <span

                        data-tooltip-id='my-tooltip-click'
                        data-tooltip-html={
                            !!AbsenceList && AbsenceList.length > 0 ? AbsenceList.map((value, key) => {
                                return `<div>
                                        <div className='tooltip-header'>
                                            <p className='tooltip-title'>${value?.empName}</p>
                                            <span className='tooltip-header-date'>${value?.dateRange}</span>
                                        </div>
                                        <div className='tooltip-body'>
                                            <p className='tooltip-body-text'>${value?.absenceName}</p>
                                        </div>
                                    </div>`
                            })

                                : null
                        }

                        style={{ position: "absolute", top: "-5px", marginLeft: '3px' }} onClick={handleClick} className='badge text-white bg-danger'>{props.date}</span>
                    {/* <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <div className='minwidth150px'>
                            <div className='header bg-light p-2 px-3'>
                                <h6 className='mb-0 fw-bold'>{Counter} Absense</h6>
                            </div>
                            {
                                !!AbsenceList && AbsenceList.length > 0 ? AbsenceList.map((value, key) => {
                                    return <div className='popoverBody p-2 px-3'>
                                        <div>
                                            <span >{value.empName}</span>
                                        </div>
                                        <span> {value.dateRange} {value.absenceName} </span>
                                    </div>
                                })
                                    : null
                            }
                        </div>
                    </Popover> </> */}
                </>
                : null}
        </>
    );
}

// function CreateShift(props) {

//     const [workSpaceId, setWorkSpaceId] = useState("");
//     const [minimumEmployee, setMinimumEmployee] = useState("1");
//     const [DayDate, setDayDate] = useState(new Date());
//     const [StartHousr, setStartHousr] = useState("9");
//     const [StartMin, setStartMin] = useState("0");
//     const [EndHousr, setEndHousr] = useState("14");
//     const [EndMin, setEndMin] = useState("0");
//     const [Note, setNote] = useState("");
//     const [workspaceList, setWorkspaceList] = React.useState([]);
//     const { setIsLoading, getEveryThing } = React.useContext(ApiContext);
//     const [AllowToaddBreak, setAllowToaddBreak] = useState(false);
//     const [ShowExceedLimitModel, setShowExceedLimitModel] = useState(false);
//     const [ShowPreviousModel, setShowPreviousModel] = useState(false);
//     useEffect(() => {
//         setTimeTrackObj((prevState) => {
//             const newState = { ...prevState }
//             return Object.assign(newState, {
//                 day: DayDate,
//             })
//         })
//     }, [DayDate]);
//     const CheckPastAndOpenModel = () => {
//         var shiftEndTime = moment(new Date(DayDate).toLocaleDateString()).startOf("day").add(parseInt(StartHousr), 'hours').add(parseInt(StartMin), 'minutes');
//         if (moment().isSameOrAfter(shiftEndTime) && !!localStorage.getItem("ClickedEmpID")) {
//             setShowPreviousModel(true)
//             return
//         } else {
//             submit(false)
//         }
//     }
//     const formik = useFormik({
//         initialValues: {
//             TimeModel: []
//         },
//     });

//     const [TimeTrackObj, setTimeTrackObj] = useState({
//         day: DayDate,
//         startTimeHour: StartHousr,
//         startTimeMin: StartMin,
//         endTimeHour: EndHousr,
//         endTimrMin: EndMin,
//     });
//     const submit = async (IsAssignmentWithTimetrack) => {
//         setIsLoading(true);
//         var startDateTime = moment(DayDate).startOf('day')
//             .add(parseInt(StartHousr), 'hours')
//             .add(parseInt(StartMin), 'minutes');

//         var endDateTime = moment(DayDate).startOf('day')
//             .add(parseInt(EndHousr), 'hours')
//             .add(parseInt(EndMin), 'minutes');
//         var BreaksList = [];
//         var TotalBreakTime = 0;
//         var startTime = (parseInt(StartHousr) * 60) + parseInt(StartMin);
//         var endTime = (parseInt(EndHousr) * 60) + parseInt(EndMin);
//         var TotalWorkingTime = (endTime - startTime);

//         if (!!formik.values.TimeModel && formik.values.TimeModel.length > 0) {
//             if (formik.values.TimeModel.find(x => x.day.setHours(0, 0, 0, 0) !== DayDate.setHours(0, 0, 0, 0)) === undefined) {
//                 formik.values.TimeModel.map((val, key) => {
//                     var Breakstart = (parseInt(val.startTimeHour) * 60) + parseInt(val.startTimeMin);
//                     var Breakend = (parseInt(val.endTimeHour) * 60) + parseInt(val.endTimrMin);
//                     TotalBreakTime = TotalBreakTime + (Breakend - Breakstart);
//                     if (Breakstart < startTime || Breakstart > endTime || Breakend < startTime || Breakend > endTime) {
//                         Swal.fire({
//                             icon: 'error',
//                             title: 'Pause start time is not within time tracking duration. Pause end time is not within time tracking duration. Starts may not be in future. Ends may not be in future.',
//                         })
//                         return
//                     }
//                 })
//             } else {
//                 Swal.fire({
//                     icon: 'error',
//                     title: 'Pause start time is not within time tracking duration. Pause end time is not within time tracking duration. Starts may not be in future. Ends may not be in future.',
//                 })
//                 return
//             }
//             if (TotalWorkingTime < TotalBreakTime) {
//                 Swal.fire({
//                     icon: 'info',
//                     title: 'Pause time is more then time track duration.',
//                 })
//                 return
//             }

//             formik.values.TimeModel.map((val, key) => {
//                 var breakobj = {
//                     day: moment(val.day).startOf('day'),
//                     startTime: moment(val.day).startOf('day')
//                         .add(parseInt(val.startTimeHour), 'hours')
//                         .add(parseInt(val.startTimeMin), 'minutes'),
//                     endTime: moment(val.day).startOf('day')
//                         .add(parseInt(val.endTimeHour), 'hours')
//                         .add(parseInt(val.endTimrMin), 'minutes')
//                 }
//                 BreaksList.push(breakobj)
//             })
//         }
//         var shiftEndTime = moment(new Date(DayDate).toLocaleDateString()).startOf("day").add(parseInt(EndHousr), 'hours').add(parseInt(EndMin), 'minutes');
//         // var shiftEndTime=moment(new Date(info.date).toLocaleDateString()).startOf("day").add(parseInt(info.time.split(" - ")[1].split(":")[0]), 'hours').add(parseInt(info.time.split(" - ")[1].split(":")[1]), 'minutes');
//         var IsAssignmentWithTimetrack = false
//         if (moment().isSameOrAfter(shiftEndTime)) {
//             IsAssignmentWithTimetrack = true
//         }
//         var data = {
//             workSpaceId: workSpaceId,
//             minimumEmployee: minimumEmployee,
//             DayDate: startDateTime,
//             DayDateEnd: endDateTime,
//             Note: Note,
//             Tags: [],
//             empidforoccupancy: getEveryThing.encEmpId,
//             IsAssignmentWithTimetrack: IsAssignmentWithTimetrack,
//             ShiftBreaks: BreaksList,

//         }

//         var response = await dynamicPostApi(data, "/api/Shift/CreateShiftsingle");
//         setIsLoading(false);
//         if (!!response && response.status === 200) {

//             if (response.data === "reached limit") {
//                 setShowExceedLimitModel(true)
//                 props.triggerChang();
//             } else {
//                 props.triggerChang();
//                 props.parentThis.setState({
//                     NewShiftModal: false
//                 })
//             }
//         }
//     }

//     useEffect(async () => {

//         var response = await dynamicGetApi(
//             "/api/Calendar/GetCalendarSetting"
//         );

//         if (!!response && response.status === 200) {

//             setAllowToaddBreak(response.data.activate_shift_related_breaks_in_duty_roster)
//         }
//         getworkspaces()
//         setDayDate(new Date(props.parentThis.state.DayClickDate))


//     }, []);
//     const getworkspaces = async () => {
//         var data = {
//             id: getEveryThing.encEmpId
//         }
//         var response = await dynamicPostApi(data,
//             "/api/Employee/GetEmplyeeWorkSpaceDD"
//         );
//         if (!!response && response.data.length > 0) {
//             setWorkspaceList(response.data);
//             setWorkSpaceId(response.data[0].key)
//         }
//     }
//     return (
//         <>
//             {
//                 ShowExceedLimitModel === true ?
//                     <Modal show={ShowExceedLimitModel} onHide={() => props.parentThis.setState({
//                         NewShiftModal: false
//                     })} >
//                         <Modal.Header closeButton >
//                             <Modal.Title>Hour limit reached</Modal.Title>
//                         </Modal.Header>
//                         <Modal.Body>
//                             <div>
//                                 <label className='fw-bolder'>You can't assign yourself to this shift, because you have reached your weekly/monthly hour limit.</label>
//                             </div>
//                         </Modal.Body>
//                         <Modal.Footer>
//                             <a className='text-danger me-3' onClick={() => props.parentThis.setState({
//                                 NewShiftModal: false
//                             })}>Cancel</a>
//                         </Modal.Footer>
//                     </Modal>
//                     : null
//             }
//             {
//                 ShowPreviousModel === true ?
//                     <Modal show={ShowPreviousModel} onHide={() => setShowPreviousModel(false)} >
//                         <Modal.Header className="" closeButton >
//                             <Modal.Title>Warning</Modal.Title>
//                         </Modal.Header>
//                         <Modal.Body>
//                             <p>This shift is in the past. Do you want to just assign/remove the employee from the shift, or also delete all related time trackings?</p>
//                         </Modal.Body>
//                         <Modal.Footer>
//                             <Button className="btn btnPrimaryGreen me-3" onClick={() => { submit(false) }}>Assignment only</Button>
//                             <Button className="btn btnPrimaryGreen" onClick={() => { submit(true) }}>Also time tracking</Button>
//                         </Modal.Footer>
//                     </Modal>
//                     : null
//             }
//             {/* : null} */}
//             <Modal show={props.parentThis.state.NewShiftModal} onHide={() => {
//                 props.parentThis.setState({
//                     NewShiftModal: false
//                 })
//             }}>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Create shift adasd</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <Form>
//                         <Row>
//                             <Col md={7} >
//                                 <Form.Group controlId="NewShiftForm.SelectWorkspace">
//                                     <Form.Label>Workspace</Form.Label>
//                                     <Form.Control as="select" className="bgPrimaryGreen" defaultValue={workSpaceId} onChange={(e) => setWorkSpaceId(e.target.value)} custom>
//                                         {
//                                             !!workspaceList && workspaceList.length > 0 ?
//                                                 workspaceList.map((value, key) => {
//                                                     return <option value={value.key}>{value.label}</option>
//                                                 }) : null
//                                         }
//                                     </Form.Control>
//                                 </Form.Group>
//                             </Col>
//                             <Col md={5}>
//                                 <Form.Group controlId="NewShiftForm.txtMinNumEmployee">
//                                     <Form.Label>Minimum number of employees</Form.Label>
//                                     <Form.Control type="number" defaultValue={minimumEmployee} onChange={(e) => setMinimumEmployee(e.target.value)} />
//                                 </Form.Group>
//                             </Col>
//                             <Col md={4}>
//                                 <Form.Group controlId="NewShiftForm.txtDay">
//                                     <Form.Label>Day</Form.Label>
//                                     <DatePicker className="form-control" selected={DayDate} onChange={(date) => setDayDate(date)} />
//                                 </Form.Group>
//                             </Col>
//                             <Col md={4}>
//                                 <Form.Group controlId="NewShiftForm.txtStartTime">
//                                     <Form.Label>Start time</Form.Label>
//                                     <div className="flexInputGroup">
//                                         <Form.Control as="select" className="Noarrow bgPrimaryGrey" defaultValue={StartHousr} onChange={(e) => setStartHousr(e.target.value)} custom>
//                                             {
//                                                 Constants.hoursDropDown.map((val, key) => {
//                                                     return <option key={"StartHousr" + key} value={val.value}>{val.text}</option>
//                                                 })
//                                             }
//                                         </Form.Control>
//                                         <span className="seperator">:</span>
//                                         <Form.Control as="select" className="Noarrow bgPrimaryGrey" defaultValue={StartMin} onChange={(e) => setStartMin(e.target.value)} custom>
//                                             {
//                                                 Constants.minutesDropDown.map((val, key) => {
//                                                     return <option key={"StartMin" + key} value={val.value}>{val.text}</option>
//                                                 })
//                                             }
//                                         </Form.Control>
//                                     </div>
//                                 </Form.Group>
//                             </Col>
//                             <Col md={4}>
//                                 <Form.Group controlId="NewShiftForm.txtEndTime">
//                                     <Form.Label>End time</Form.Label>
//                                     <div className="flexInputGroup">
//                                         <Form.Control as="select" className="Noarrow bgPrimaryGrey" defaultValue={EndHousr} onChange={(e) => setEndHousr(e.target.value)} custom>
//                                             {
//                                                 Constants.hoursDropDown.map((val, key) => {
//                                                     return <option key={"EndHours" + key} value={val.value}>{val.text}</option>
//                                                 })
//                                             }
//                                         </Form.Control>
//                                         <span className="seperator">:</span>
//                                         <Form.Control as="select" className="Noarrow bgPrimaryGrey" defaultValue={EndMin} onChange={(e) => setEndMin(e.target.value)} custom>
//                                             {
//                                                 Constants.minutesDropDown.map((val, key) => {
//                                                     return <option key={"EndMin" + key} value={val.value}>{val.text}</option>
//                                                 })
//                                             }
//                                         </Form.Control>
//                                     </div>
//                                 </Form.Group>
//                             </Col>
//                             {
//                                 AllowToaddBreak === true ?
//                                     <Col md={12}>
//                                         <div className=" border-bottom">
//                                             <div>
//                                                 <TTAddBreak formik={formik} obj={TimeTrackObj} Day={DayDate} />
//                                             </div>
//                                         </div>
//                                     </Col>
//                                     : null
//                             }

//                             <Col md={12}>

//                                 <div className="py-4">
//                                     <Form.Group controlId="NewShiftForm.txtNote">
//                                         <Form.Label>Note</Form.Label>
//                                         <Form.Control as="textarea" rows={2} defaultValue={Note} onChange={(e) => setNote(e.target.value)} />
//                                     </Form.Group>
//                                 </div>
//                             </Col>
//                         </Row>
//                     </Form>
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button className="btn btnPrimaryGreen" onClick={() => { CheckPastAndOpenModel() }} >Finished</Button>
//                 </Modal.Footer>
//             </Modal>
//         </>
//     )
// }