import { useFormik } from "formik";
import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, OverlayTrigger, Row, Tab, Table, Tabs, Tooltip } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { FaPlus, FaRegTrashAlt } from "react-icons/fa";
import SelectInput from 'react-select';
import * as Yup from "yup";
import { EditWorkspaceColor } from "../../../CommonJSON";
import { Gear } from "../../../Components/ActionBtns";
import CollapseableDiv from "../../../Components/CollapseCard";
import TblSwitchToogleButton from "../../../Components/TblSwitchToogleButton";
import { CallAxios } from "../../../Helpers";
import { DOT, TableWrapper } from "../../../Helpers/Global/StyledComponents";
import { GetEmployeeDD } from "../../../Helpers/MasterData";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";


function AbsenseTypes(props) {
    const { t: Lst } = useTranslation('locationSetting');
    const { t: ct } = useTranslation('common');


    const [NewAbsence, setNewAbsence] = useState(false);
    const [AbsenceTypeIdForEdit, setAbsenceTypeIdForEdit] = useState("");

    const onchangeStatusSubmit = async (e, status) => {
        var response = await CallAxios("/api/LocationSetting/ChanegAbsenceTypeStatus|post", { id: e.id })
        if (!!response && response.data) {
            props.setAbsenceTypeList(response.data);
        }
    }

    return (
        <>
            <CollapseableDiv title={Lst('absenceTab.absenAccor.absenceTypes')}>
                {NewAbsence === true ?
                    <AddEditAbsenceType show={NewAbsence} setshow={setNewAbsence} setAbsenceTypeList={props.setAbsenceTypeList} AbsenceTypeIdForEdit={AbsenceTypeIdForEdit} />
                    : null}
                <Row>
                    <Col md={12}>
                        <div className="TabFormContainerSection">
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className="text-left w-75">
                                    {Lst('absenceTab.absenAccor.Desc')}.
                                </p>
                                <button type="button" className="btn btnPrimaryGreen btn-primary" onClick={(e) => { setAbsenceTypeIdForEdit(""); setNewAbsence(true) }}> {Lst('absenceTab.absenAccor.newTypeAbsence')}</button>

                            </div>
                            <TableWrapper>


                                <Table striped bordered responsive className="table table-striped table-bordered DataGridTable text-left overflowVisible">
                                    <thead>
                                        <tr className='firsthead'>
                                            <th> {Lst('absenceTab.absenAccor.tbl.title')}</th>
                                            <th> {Lst('absenceTab.absenAccor.tbl.holidayEntitlements')}</th>
                                            <th> {Lst('absenceTab.absenAccor.tbl.records')}</th>
                                            <th> {Lst('absenceTab.absenAccor.tbl.applicableFor')}</th>
                                            <th> {Lst('absenceTab.absenAccor.tbl.newUsers')}</th>
                                            <th> {ct('employee')}</th>
                                            <th> {ct('actions')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!!props.AbsenceTypeList && props.AbsenceTypeList.length > 0 ? props.AbsenceTypeList.map((val, index) => {
                                            return <tr key={"tableList_" + index}>

                                                <td>
                                                    <div className="dataTextCell">{!!val.color ? <DOT color={val.color} /> : null}{val.absencename}</div>
                                                </td>
                                                <td>
                                                    <div className="dataTextCell">{val.deduct_from_remaining_vacation_days === false ? <>{Lst('absenceTab.absenAccor.dontUse')}</> : <>{ct('use')}</>}</div>
                                                </td>
                                                <td>
                                                    <div className="dataTextCell">{val.evaluationsCalculateAbsenceHoursInReports === false ? <>{Lst('absenceTab.absenAccor.dontUse')}</> : <>{Lst('absenceTab.absenAccor.targetHours')}</>}</div>
                                                </td>
                                                <td>
                                                    <div className="dataTextCell">{val.isSelectableByEmployees === true ? <>{ct('employee')}</> : null} {val.isSelectableByadmins === true ? <>, {ct('admins')}</> : null} {val.isSelectableByWorkspaceAdmins === true ? <>, {ct('owners')}</> : null}</div>
                                                </td>
                                                <td>
                                                    <div className="dataTextCell">{val.autoAssignAbsenceTypeToNewEmployees === true ? <>{ct('assign')}</> : <>{Lst('absenceTab.absenAccor.doNotAssign')}</>}</div>
                                                </td>
                                                <td>
                                                    <div className="dataTextCell">
                                                        {/* <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip></Tooltip>}> */}
                                                        <a data-tooltip-id="my-tooltip" data-tooltip-content={val.empnames.length > 0 ? val.empnames.map(item => item).join(', ') : null} className="text-dark ms-2" style={{ fontSize: "16px" }}>
                                                            {!!val.empnames && val.empnames.length > 0 ? val.empnames.length + " " + ct('employee') : null}
                                                        </a>
                                                        {/* </OverlayTrigger> */}
                                                    </div>
                                                </td>
                                                <td>
                                                    {
                                                        val.isactive === true ?
                                                            <Gear onClick={(e) => { setAbsenceTypeIdForEdit(val.id); setNewAbsence(true) }} />
                                                            : null
                                                    }
                                                    <TblSwitchToogleButton status={val.isactive} val={val} onClicksubmit={onchangeStatusSubmit} />
                                                </td>
                                            </tr>
                                        }) : null
                                        }
                                    </tbody>
                                </Table>
                            </TableWrapper>
                        </div>
                    </Col>
                </Row>
            </CollapseableDiv>
        </>
    );
}

export default AbsenseTypes;

function AddEditAbsenceType(props) {
    const { t: Lst } = useTranslation('locationSetting');
    const { t: ct } = useTranslation('common');

    const MoreSettings = [
        { name: "IsSelectableByEmployees", label: Lst('absenceTab.absenAccor.newAbscMdl.furtherLabelList1') },
        { name: "IsSelectableByadmins", label: Lst('absenceTab.absenAccor.newAbscMdl.furtherLabelList2') },
        { name: "IsSelectableByWorkspaceAdmins", label: Lst('absenceTab.absenAccor.newAbscMdl.furtherLabelList3') },
        { name: "IsEmployeesResetAbsences", label: Lst('absenceTab.absenAccor.newAbscMdl.furtherLabelList4') },
        { name: "AutoAssignAbsenceTypeToNewEmployees", label: Lst('absenceTab.absenAccor.newAbscMdl.furtherLabelList5') },
        { name: "ConfirmAbsenceRemoveAllOccupanciesInPeriod", label: Lst('absenceTab.absenAccor.newAbscMdl.furtherLabelList6') },
        { name: "ConfirmAbsenceRemoveAllTimeRecordsInPeriod", label: Lst('absenceTab.absenAccor.newAbscMdl.furtherLabelList7') },
        { name: "ConfirmAbsenceRemoveAllAvailabilitiesInPeriod", label: Lst('absenceTab.absenAccor.newAbscMdl.furtherLabelList8') }]

    const DropdownIndicator = () => null;
    // const [text, setText] = React.useState("");
    // const [calcEval, setcalcEval] = useState(false);
    // const [calcEvalradio, setcalcEvalradio] = useState(false);
    const [SelectValue, setSelectValue] = useState("");
    const [options, setoptions] = useState([]);
    const [getEmployeeIds, setEmployeeIds] = useState([]);

    const formik = useFormik({
        initialValues: {
            Surname: "",
            color: "#fff",
            Deduct_from_remaining_vacation_days: false,
            Calculate_Hours_Absence_in_records: true,
            IsSelectableByadmins: true,
            IsSelectableByEmployees: true,
            IsSelectableByWorkspaceAdmins: true,
            IsEmployeesResetAbsences: false,
            AutoAssignAbsenceTypeToNewEmployees: true,
            ConfirmAbsenceRemoveAllAvailabilitiesInPeriod: false,
            ConfirmAbsenceRemoveAllOccupanciesInPeriod: true,
            ConfirmAbsenceRemoveAllTimeRecordsInPeriod: true,
            calchoursradio: "Set absence hours to target hours",
            calculated_fixed_value: "",
            working_days_time: "",
            working_days_timeInput: "",
            calcweeks: false,
            Takepublicholidaysintoaccount: false,
        },
        validationSchema: Yup.object().shape({
            Surname: Yup.string().required(ct('required').toString())
        }),
        onSubmit: values => {
            submit(values)
        },
    });
    const submit = async (values) => {
        if (values.calchoursradio === "Calculate fixed value" && parseFloat(values.calculated_fixed_value) < 0) {
            toast.error("Fixed value must be greater than or equal to 0")
            return;
        }

        var data = {
            id: props.AbsenceTypeIdForEdit,
            Absencename: values.Surname,
            Color: values.color,
            Deduct_from_remaining_vacation_days: values.Deduct_from_remaining_vacation_days,
            Calculate_Hours_Absence_in_records: values.Calculate_Hours_Absence_in_records,
            evaluationsCalculateAbsenceHoursInReportsValue: values.calchoursradio,
            CalculateFixedValue: values.calculated_fixed_value,
            CalculateN_weekAverage: values.calcweeks,
            CalculateN_weekAverage_value: values.working_days_time,
            CalculateN_weekAverage_N_WeekValue: values.working_days_timeInput,
            Takepublicholidaysintoaccount: values.Takepublicholidaysintoaccount,
            IsSelectableByEmployees: values.IsSelectableByEmployees,
            IsSelectableByadmins: values.IsSelectableByadmins,
            IsSelectableByWorkspaceAdmins: values.IsSelectableByWorkspaceAdmins,
            IsEmployeesResetAbsences: values.IsEmployeesResetAbsences,
            AutoAssignAbsenceTypeToNewEmployees: values.AutoAssignAbsenceTypeToNewEmployees,
            ConfirmAbsenceRemoveAllOccupanciesInPeriod: values.ConfirmAbsenceRemoveAllOccupanciesInPeriod,
            ConfirmAbsenceRemoveAllTimeRecordsInPeriod: values.ConfirmAbsenceRemoveAllTimeRecordsInPeriod,
            ConfirmAbsenceRemoveAllAvailabilitiesInPeriod: values.ConfirmAbsenceRemoveAllAvailabilitiesInPeriod,
            EmpIds: getEmployeeIds,
        };
        var response = await CallAxios(
            "/api/LocationSetting/AddEditAbsenceType|post", data
        );
        if (!!response && response.status === 200) {
            props.setAbsenceTypeList(response.data)
            props.setshow(false)
        }
    };
    useEffect(() => {
        GetEmployeeDD(setoptions)
        if (!!props.AbsenceTypeIdForEdit) {
            getData()
        }
    }, []);
    const getData = async () => {
        var response = await CallAxios(
            "/api/LocationSetting/getAbsenceType|post", { id: props.AbsenceTypeIdForEdit }
        );

        if (!!response && response.data) {
            formik.setFieldValue("Surname", response.data.absencename);
            formik.setFieldValue("color", response.data.color);
            formik.setFieldValue("Deduct_from_remaining_vacation_days", response.data.deduct_from_remaining_vacation_days);
            formik.setFieldValue("Calculate_Hours_Absence_in_records", response.data.calculate_Hours_Absence_in_records);
            formik.setFieldValue("calcweeks", response.data.calculateN_weekAverage);
            formik.setFieldValue("working_days_time", response.data.calculateN_weekAverage_value);
            formik.setFieldValue("working_days_timeInput", response.data.calculateN_weekAverage_N_WeekValue);
            formik.setFieldValue("calchoursradio", response.data.evaluationsCalculateAbsenceHoursInReportsValue);
            formik.setFieldValue("calculated_fixed_value", response.data.calculateFixedValue);

            formik.setFieldValue("IsSelectableByEmployees", response.data.isSelectableByEmployees);
            formik.setFieldValue("IsSelectableByadmins", response.data.isSelectableByadmins);
            formik.setFieldValue("IsSelectableByWorkspaceAdmins", response.data.isSelectableByWorkspaceAdmins);
            formik.setFieldValue("IsEmployeesResetAbsences", response.data.isEmployeesResetAbsences);
            formik.setFieldValue("AutoAssignAbsenceTypeToNewEmployees", response.data.autoAssignAbsenceTypeToNewEmployees);
            formik.setFieldValue("ConfirmAbsenceRemoveAllOccupanciesInPeriod", response.data.confirmAbsenceRemoveAllOccupanciesInPeriod);
            formik.setFieldValue("ConfirmAbsenceRemoveAllTimeRecordsInPeriod", response.data.confirmAbsenceRemoveAllTimeRecordsInPeriod);
            formik.setFieldValue("ConfirmAbsenceRemoveAllAvailabilitiesInPeriod", response.data.confirmAbsenceRemoveAllAvailabilitiesInPeriod);
            formik.setFieldValue("Takepublicholidaysintoaccount", response.data.takepublicholidaysintoaccount);

            let selectdEmpids = [];
            if (response.data.empObj.length > 0) {
                response.data.empObj.map((val, key) => {
                    selectdEmpids.push(val.value)
                })
                setEmployeeIds(selectdEmpids);
                setSelectValue(response.data.empObj);
            }
        }
    };
    const GetColor = (e) => {
        let siblings = e.target.parentElement.children;
        for (let sib of siblings) {
            sib.classList.remove('selected')
        }
        e.target.classList.add("selected");
        formik.setFieldValue("color", e.target.getAttribute("data-color"))
    }
    return (
        <Modal show={props.show} className="" onHide={() => props.setshow(false)}>
            <Modal.Header closeButton className="">
                <Modal.Title>{!!props.AbsenceTypeIdForEdit ? <>{ct('edit')}</> : <>{ct('new')}</>} {Lst('absenceTab.absenAccor.absenceTypes')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs
                    defaultActiveKey="Ideas"
                    transition={false}
                    id="WorkSpaceTabs"
                    className="mb-3 nav-underline"
                >
                    <Tab eventKey="Ideas" title={Lst('planTab.newWorkMdl.ideas')}>
                        <div className='pb-4 border-bottom'>
                            <h5 className='fw-light'> {Lst('absenceTab.absenAccor.newAbscMdl.generalSettings')}</h5>
                            <Form.Group className="position-relative">
                                <label className='fw-bold' > {Lst('planTab.newWorkMdl.titleLabel')}</label>
                                <Form.Control type="text" name="Surname" maxLength={150} onChange={formik.handleChange} value={formik.values.Surname} className="ThickBorder my-0 pr40" />
                                {formik.touched.Surname && formik.errors.Surname ? (
                                    <span className="error text-danger">{formik.errors.Surname}</span>
                                ) : null}
                            </Form.Group>
                            <Form.Group className="position-relative">
                                <label className='fw-bold' > {Lst('planTab.newWorkMdl.color')}</label>
                                <div className="position-relative">
                                    <span className='SimpleColorPicker'>
                                        {EditWorkspaceColor.map((value, index) =>
                                            <span onClick={GetColor} className={`${value.class} ${formik.values.color === value.dataColor ? "selected" : null}`} title={value.title} data-color={value.dataColor} style={{ backgroundColor: value.dataColor }}></span>
                                        )}
                                    </span>
                                </div>
                            </Form.Group>
                        </div>
                        <div className='border-bottom py-4'>
                            <h5 className='fw-light'> {Lst('absenceTab.absenAccor.tbl.holidayEntitlements')}</h5>
                            <Form.Group className="mb-1" controlId={"Vacation_with_vacation_days"}>
                                <Form.Check className="text-left"
                                    name={"Deduct_from_remaining_vacation_days"} checked={formik.values.Deduct_from_remaining_vacation_days} onChange={formik.handleChange} value={formik.values.Deduct_from_remaining_vacation_days} type="checkbox" label={Lst('absenceTab.absenAccor.newAbscMdl.deductLabel')} />
                            </Form.Group>
                        </div>
                        <div className='border-bottom py-4'>
                            <h5 className='fw-light'> {Lst('absenceTab.absenAccor.newAbscMdl.evaluations')}</h5>
                            <Form.Group className="mb-1" controlId={"Calculate_Hours_Absence_in_records"}>
                                <Form.Check className="text-left"
                                    name={"Calculate_Hours_Absence_in_records"} checked={formik.values.Calculate_Hours_Absence_in_records} onChange={formik.handleChange} value={formik.values.Calculate_Hours_Absence_in_records} type="checkbox" label={Lst('absenceTab.absenAccor.newAbscMdl.evaluationLabelList1')} />
                            </Form.Group>
                            {formik.values.Calculate_Hours_Absence_in_records ?
                                <>
                                    {[Lst('absenceTab.absenAccor.newAbscMdl.evaluationSubLabel1'), Lst('absenceTab.absenAccor.newAbscMdl.evaluationSubLabel2'), Lst('absenceTab.absenAccor.newAbscMdl.evaluationSubLabel3'), Lst('absenceTab.absenAccor.newAbscMdl.evaluationSubLabel4'), Lst('absenceTab.absenAccor.newAbscMdl.evaluationSubLabel5')].map((val, index) =>
                                        <Form.Group className="mb-2 ms-4" controlId={"calchoursradio" + index}>
                                            <Form.Check className="text-left"
                                                name={"calchoursradio"} value={val} checked={formik.values.calchoursradio === val ? true : false} onChange={(e) => { formik.setFieldValue("calchoursradio", e.target.value); formik.setFieldValue("calculated_fixed_value", "") }} type="radio" label={val} />
                                            {formik.values.calchoursradio === "Calculate fixed value" && val === "Calculate fixed value" ?
                                                <Form.Control type="text" maxLength={50} name="calculated_fixed_value" onChange={formik.handleChange} value={formik.values.calculated_fixed_value} className="ThickBorder mt-2 my-0 pr40" />
                                                : null}
                                        </Form.Group>
                                    )}
                                    <Form.Group className="mb-1 m-2" controlId={"calcweeks"}>
                                        <Form.Check className="text-left"
                                            name={"calcweeks"} value={formik.values.calcweeks} checked={formik.values.calcweeks} onChange={formik.handleChange} type="checkbox" label={Lst('absenceTab.absenAccor.newAbscMdl.evaluationLabelList2')} />
                                        {formik.values.calcweeks === true ?
                                            <div className="pl-3">
                                                <Form.Group className="mb-1" >
                                                    <Form.Check className="text-left my-2" id="sss"
                                                        name={"working_days_time"} checked={formik.values.working_days_time === "Workdays by time tracking" ? true : false} onChange={formik.handleChange} value={"Workdays by time tracking"} type="radio" label={Lst('absenceTab.absenAccor.newAbscMdl.evaluationLabelList3')} />
                                                    <Form.Check className="text-left my-2" id="ssss"
                                                        name={"working_days_time"} checked={formik.values.working_days_time === "Workdays by target hours" ? true : false} onChange={formik.handleChange} value={"Workdays by target hours"} type="radio" label={Lst('absenceTab.absenAccor.newAbscMdl.evaluationLabelList4')} />
                                                    <Form.Control type="text" maxLength={150} name="working_days_timeInput" onChange={formik.handleChange} value={formik.values.working_days_timeInput} className="ThickBorder mt-3 my-0 pr40" />
                                                </Form.Group>
                                            </div>
                                            : null}

                                    </Form.Group>
                                </>
                                : null}

                        </div>
                        <div className=' py-4'>
                            <h5 className='fw-light mb-4'> {Lst('absenceTab.absenAccor.newAbscMdl.furtherSettings')} </h5>
                            {
                                formik.values.Deduct_from_remaining_vacation_days ?
                                    <>
                                        <Form.Group className="mb-1" >
                                            <Form.Check className="text-left my-2" id="sssd"
                                                name={"Takepublicholidaysintoaccount"} onChange={formik.handleChange} checked={formik.values.Takepublicholidaysintoaccount} value={"Working days according to time recordings"} type="checkbox"
                                                label={Lst('absenceTab.absenAccor.newAbscMdl.furtherSettingsLabel')} />
                                        </Form.Group>
                                    </>
                                    : null
                            }
                            {MoreSettings.map((val, index) => {
                                return <Form.Group className="mb-3" controlId={"Moresettings" + index}>
                                    <Form.Check className="text-left"
                                        name={val.name} checked={formik.values[val.name]} onChange={formik.handleChange} type="checkbox" label={val.label} />
                                </Form.Group>
                            }
                            )}
                        </div>
                    </Tab>
                    <Tab eventKey="Employees" title={ct('employee') + ` ${getEmployeeIds.length}`}>
                        <div className='d-flex justify-content-between align-items-center mb-2'>
                            <a className=' transparentBtnGreen' onClick={(e) => { setSelectValue(options); setEmployeeIds(options.map((x) => x.value)) }}><FaPlus className="me-1" /> {ct('addAll')}</a>
                            <a className=' transparentBtnred' onClick={(e) => { setSelectValue(""); setEmployeeIds([]) }}><FaRegTrashAlt className="me-1" /> {ct('removeAll')}</a>
                        </div>
                        <SelectInput
                            classNamePrefix="MyReactSelect"
                            isMulti
                            isClearable={false}
                            components={{ DropdownIndicator }}
                            options={options}
                            value={SelectValue}
                            styles={{
                                indicatorSeparator: base => ({
                                    ...base,
                                    display: 'none'
                                })
                            }}
                            onChange={(value, action) => {
                                let stringarr = [];
                                for (let i = 0; i < value.length; i++) {
                                    stringarr.push(value[i].value)
                                }
                                setEmployeeIds(stringarr);
                                setSelectValue(value)
                            }}
                        />
                    </Tab>

                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" className="btn btnPrimaryGreen " onClick={formik.handleSubmit}> {ct('ready')}</Button>

            </Modal.Footer>
        </Modal>
    );
}