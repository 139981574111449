import { saveAs } from 'file-saver';
import moment from "moment";
import React from 'react';
import { createRef, useEffect, useState } from "react";
import { Badge, Button, Col, Dropdown, Form, ListGroup, Modal, Nav, OverlayTrigger, Popover, Row, Table, Tooltip } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import { BiCalendarExclamation } from "react-icons/bi";
import { BsCaretLeftFill, BsCaretRightFill, BsChevronLeft, BsChevronRight, BsFillGearFill, BsGear, BsPlusCircle, BsPlusSquare, BsSearch, BsThreeDots } from "react-icons/bs";
import { FaCalendar, FaDownload, FaEnvelope, FaEyeSlash, FaHeading, FaListAlt, FaMagic, FaQuoteRight, FaRegCalendarAlt, FaRegEnvelope, FaRegUser, FaSortAmountDown, FaTags, FaThumbtack, FaUserSecret, FaUsers } from "react-icons/fa";
import { FiInfo } from "react-icons/fi";
import { IoDocumentsSharp } from "react-icons/io5";
import { useSelector } from "react-redux";
import InputImoji from "../../Components/inputImoji";
import { CallAxios, CallAxiosBlob, CallAxiosNoloader } from "../../Helpers";
import { Constants } from "../../Helpers/Contents";
import Roster from "./Roster";
import CopyTemplateModal from "./RosterModals/CopyTemplateModal";
import CreateShift from "./RosterModals/CreateShift";
import EmployeeSettingModal from "./RosterModals/EmployeeSettingModal";
import OccupyAutomaticallySettingModal from "./RosterModals/OccupyAutomaticallySettingModal";
import SandBox from "./RosterModals/SandBox";
import SendRosterPlanModal from "./RosterModals/SendRosterPlanModal";
import { CircleBtnWithIcon } from '../../Helpers/Global/StyledComponents';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";




const CustomToggleSettings = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </a>
));

function RosterView() {
    const { t: cl } = useTranslation('calendarPage');
    const { t: ct } = useTranslation('common');
    const { t: ept } = useTranslation('employeePage');

    var calendarComponentRef = createRef();
    const user = useSelector(x => x.app.user)
    const appSettings = useSelector(x => x.app.appSettings)
    const [setting, setSetting] = useState({
        _showRequirements: true,
        _hidetags: false,
        _planningIncomplete: false,
        _compactCalendar: false,
        _hideAssignments: false,
        _fixedHeadline: false,
        _showTitle: false,
        _oneRowPerSeries: false,
        _fullCalendarView: !!localStorage.getItem("calendar_view") ? localStorage.getItem("calendar_view") : "weeklyViewList",
        _SearchEmployee: false,
    });
    const [popoverEmployeeInfoobj, setPopoverEmployeeInfoobj] = useState({
        popEmpName: "",
        popWorkingArea: "",
        popTags: "",
        popCurrentbalanceEnd: "",
        popCurrentBalanceStart: "",
        popWorkingTimeAcLastpayrool: ""
    });
    const [SelectedEmployeeId, setSelectedEmployeeId] = useState(null);
    const [employeeSettingModal, setemployeeSettingModal] = useState(false);
    const [EmployeeList, setEmployeeList] = useState([]);
    const [filterEmployeeList, setFilterEmployeeList] = useState([]);
    const [showWeek, setShowWeek] = useState(false);
    const [showMonth, setShowMonth] = useState(new Date());
    const [weekNum, setWeekNum] = useState(parseInt(!!localStorage.getItem("calendar_date") ? new moment(localStorage.getItem("calendar_date")).format('W') : new moment().format('W')));
    const [activeindex, setActiveindex] = useState(null);
    const [activeEmployeeButtons, setActiveEmployeeButtons] = useState(false);
    const [activeEmployeeName, setActiveEmployeeName] = useState("");
    const [filterShifts, setFilterShifts] = useState(false);
    const [showborderClass, setShowborderClass] = useState(false);
    const [OccupanyInotherLocations, setOccupanyInotherLocations] = useState([]);
    const [showTimeBelowHeader, setShowTimeBelowHeader] = useState({});
    const [text, setText] = useState("");
    const [NewShiftModal, setNewShiftModal] = useState(false);
    const [OccupyAutomaticallyModal, setOccupyAutomaticallyModal] = useState(false);
    const [SelectTemplateModal, setSelectTemplateModal] = useState(false);
    const [SendPlanModal, setSendPlanModal] = useState(false);
    const [SandBoxModal, setSandBoxModal] = useState(false);
    const [calendarTable, setCalendarTable] = useState(false);
    const [showHide, setShowHide] = useState(false);
    const [emailModal, setEmailModal] = useState(false);
    const [CreateShiftMode, setCreateShiftMode] = useState("normal");
    const [ShiftTrigget, setShiftTrigget] = useState(0);
    const [DayClickDate, setDayClickDate] = useState(new Date());
    const [EmppopoverInfoendDate, setEmppopoverInfoendDate] = useState("");
    // const [ChoseCalendarDate, setChoseCalendarDate] = useState(!!localStorage.getItem("calendar_date") ? new Date(localStorage.getItem("calendar_date")) : new Date());
    useEffect(() => {
        // var initialDate = ChoseCalendarDate;
        // if (!!localStorage.getItem("calendar_date")) {
        //     initialDate = new Date(localStorage.getItem("calendar_date"))
        // }
        // setChoseCalendarDate(initialDate)
        Promise.all([
            GetEmployeeList(!!localStorage.getItem("calendar_date") ? new Date(localStorage.getItem("calendar_date")) : new Date()),
        ])
    }, []);


    // useEffect(() => {
    //     if (!!calendarComponentRef.current.activeRange.start) {
    //         setChoseCalendarDate(new Date(calendarComponentRef.current.activeRange.start))
    //     }
    // }, [calendarComponentRef.current.activeRange.start]);

    const UpdateShiftChildTrigger = () => {
        setShiftTrigget(ShiftTrigget + 1)
    }
    const GetEmployeeList = async (date) => {
        if (!!date) {
            var dateobj = new Date(date)
            var d = dateobj.setHours(new Date().getHours(), new Date().getMinutes());
            dateobj.setTime(d);
            date = dateobj;
        }
        if (date === undefined) {
            let calendar = calendarComponentRef.current;
            if (!!calendar) {
                date = calendar.getDate();
            }
        }
        let week = parseInt(moment(date).format('W'));
        var data = {
            date: date,
            weekno: week
        }
        var response = await CallAxios('/api/Calendar/getEmployeeList|post', data)
        if (!!response && response.status === 200) {
            setEmployeeList(response.data)
            setFilterEmployeeList(response.data)
        }
    }
    const dateAction = () => {
        let calendar = calendarComponentRef.current;
        if (!!calendar) {
            var date = calendar.getDate();
            setShowMonth(date)
        }
    }

    useEffect(() => {
    }, [activeindex]);

    const updateEmployeeState = async (index, name, empId) => {

        if (activeindex === index) {
            setActiveindex(null)
            setActiveEmployeeButtons(false)
            setActiveEmployeeName("")
            setFilterShifts(false)
            setShowborderClass(false)
            setSelectedEmployeeId("")
            setOccupanyInotherLocations([])
        } else {
            setSelectedEmployeeId(empId)
            let calendar = calendarComponentRef.current;
            setActiveindex(index)
            setActiveEmployeeButtons(true)
            setActiveEmployeeName(name)
            setFilterShifts(false)
            setShowborderClass(false)
            var data = {
                empId: empId.toString(),
                Satrtdate: calendar.getDate(),
                Enddate: calendar.activeRange.end,
            };
            var Occupany = await CallAxios("/api/Calendar/GetempAssignemnets|post", data);
            if (!!Occupany && Occupany.status === 200) {
                setOccupanyInotherLocations(Occupany.data.filter(x => x.locationId.toString() !== user.LocationId))
            }

            if (!!calendar) {
                var date = calendar.getDate();
                var data = {
                    date: date,
                    emplyeeID: empId
                }
                var response = await CallAxios('/api/Calendar/getEmployeeAvailibilitiesList|post', data);

                if (!!response && response.data.length > 0) {
                    var datas = {};
                    datas = response.data.map((event) => ({ ...datas, [moment(event.date).format("DD/MM/YYYY")]: event.time }));
                    const obj = Object.assign({}, ...datas);
                    setShowTimeBelowHeader(obj)
                } else {
                    setShowTimeBelowHeader({})
                }
            }
        }
    }
    const popoverEmpinfo = async (empId) => {
        let calendar = calendarComponentRef.current;
        if (!!calendar) {
            // var date = calendar.getDate();
            setEmppopoverInfoendDate(calendar.activeRange.end)
            var data = {
                date: calendar.activeRange.end,
                emplyeeID: empId
            }
            var response = await CallAxiosNoloader('/api/Calendar/getpopoverEmpinfo|post', data)
            if (!!response && response.status === 200) {
                setPopoverEmployeeInfoobj({
                    popEmpName: response.data.popEmpName,
                    popWorkingArea: response.data.popWorkingArea,
                    popTags: response.data.popTags,
                    popCurrentbalanceEnd: response.data.popCurrentbalanceEnd,
                    popCurrentBalanceStart: response.data.popCurrentBalanceStart,
                    popWorkingTimeAcLastpayrool: response.data.popWorkingTimeAcLastpayrool
                })
            }
        }
    }
    const DownloadEmployeeShifts = async () => {
        var data = {
            Satrtdate: calendarComponentRef.current.activeRange.start,
            Enddate: calendarComponentRef.current.activeRange.end,
            empId: SelectedEmployeeId
        }
        var response = await CallAxiosBlob('/api/Calendar/ExportEmployeeAssignment|post', data)
        if (!!response && response.status === 200) {
            saveAs(response.data, "Calendar.pdf")
        }
    }
    const filterCalendarTableFromDatePicker = () => {

    }
    const fnfilterEmployeeList = (e) => {
        let value = e.target.value;
        let list = EmployeeList.filter(x => x.name.toLowerCase().includes(value.toLowerCase()));
        setFilterEmployeeList(list)
    }
    const NextMonthsEmpFull = () => {
        let calendar = calendarComponentRef.current;
        if (!!calendar) {
            calendar.next();
            var date = calendar.getDate();
            setWeekNum(parseInt(moment(date).format('W')))
            setShowMonth(date)
            GetEmployeeList(date)
            localStorage.setItem("calendar_date", date)
        }
    }
    const PrevMonthsEmpFull = () => {
        let calendar = calendarComponentRef.current;
        if (!!calendar) {
            calendar.prev();
            var date = calendar.getDate();
            setWeekNum(parseInt(moment(date).format('W')))
            setShowMonth(date)
            GetEmployeeList(date)
            localStorage.setItem("calendar_date", date)
        }
    }
    const getChooseDate = (date) => {
        let calendar = calendarComponentRef.current;
        if (!!calendar) {
            calendar.gotoDate(date);
        }
        let week = parseInt(moment(date).format('W'));
        setWeekNum(week)
        setShowMonth(date)
        setShowHide(false)
        GetEmployeeList(date)
        localStorage.setItem("calendar_date", date)

    }
    const ChangeViewOfCalendar = (e) => {
        // let calendar = calendarComponentRef.current;
        if (e === "Monthlist") {
            setActiveindex(null)
            setCalendarTable(true)
            setSetting({ ...setting, _fullCalendarView: e })
        } else if (e === "_planningIncomplete" || e === "_compactCalendar" || e === "_oneRowPerSeries" || e === "_fixedHeadline" || e === "_showTitle" || e === "_showRequirements" || e === "_hideAssignments" || e === "_hidetags") {
            setSetting({ ...setting, [e]: !setting[e] })
        }
        else {
            setCalendarTable(false)
            setSetting({ ...setting, _fullCalendarView: e })
            // if (!!calendar && !!e) {
            //     calendar.getApi().changeView(e);
            // }
        }
        let calendars = calendarComponentRef.current;
        let week = parseInt(moment(calendars?.activeRange?.start).format('W'));
        setWeekNum(week)
    }
    const CreateEmployeeWithoutEmail = async (e) => {
        e.preventDefault();
        if (!!text) {
            var response = await CallAxios('/api/Employee/CreateEmp|post', { name: text })
            if (!!response && response.status === 200) {
                if (response.data === true) {
                    setText("")
                    // self.GetAbsenceEmployeeList()
                    GetEmployeeList(showMonth)
                } else if (response.data === "LimitReach") {
                    toast.error(cl("errorMsg.employeeLimit"))
                }
            }
        } else {
            Swal.fire({
                icon: "info",
                text: cl("errorMsg.employeeName")
            })
        }
    }
    return (
        <>
            <Row className="mtMainContent">
                {
                    setting._fullCalendarView !== "resourceViewDay" && setting._fullCalendarView !== "resourceViewWeek" && setting._fullCalendarView !== "resourceViewMonth" ?
                        <Col lg={3} className="lgcol3">
                            <div className="mt40">
                                <ListGroup className="DataListGroup customListHere">
                                    <ListGroup.Item className="BorderBottomThick nohover headerColor">
                                        <div className="ListValueFlex d-flex align-items-center justify-content-between">
                                            <div className="TitleText fw-bold position-relative">
                                                <BsSearch className="ListHeadingActionIcon text-white" onClick={() => setSetting({ ...setting, _SearchEmployee: !setting._SearchEmployee })} />
                                                <span className='text-white mx-2 font12'>{ct("employee")}</span>
                                                <FiInfo className='text-white' data-tooltip-id="my-tooltip" data-tooltip-content={cl("toolTip.assignEmployee")} />
                                                {
                                                    setting._SearchEmployee === true &&
                                                    <input type="text" className='customEmployeeFilter form-control' onInput={(e) => fnfilterEmployeeList(e)} placeholder={cl("filterEmployee")} />
                                                }

                                            </div>
                                            <div className="ValueText">
                                                <Button variant="primary" size="sm" onClick={(e) => { setShowWeek(!showWeek); dateAction() }} className="btnActionChage darkGreen">
                                                    <BsCaretLeftFill />{showWeek === true ? <span>{moment(showMonth).format("MMMM")}</span> : <span className="testcalss"> {ct("week")} {weekNum}</span>}<BsCaretRightFill />
                                                </Button>
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                    {/* need email in the below list */}
                                    {filterEmployeeList.map((value, index) => {
                                        return <ListGroup.Item className='addGreyInOdd py-3' key={index} >
                                            <div className="ListValueFlex d-flex align-items-center justify-content-between cursor-pointer">
                                                <div className={`TitleText ${activeindex === index ? "text-danger" : null}`} onClick={() => updateEmployeeState(index, value.name, value.empId)}>
                                                    <span className='me-5'>{value.name}</span>

                                                </div>

                                                <div className="ValueText d-flex align-items-center">
                                                    <div className='GearIconShowOnHover me-3'>
                                                        <OverlayTrigger delay={500} onEntered={() => popoverEmpinfo(value?.empId)} placement="right" overlay={
                                                            <Popover id="popover-basic">
                                                                <Popover.Header as="h3">{value?.name}</Popover.Header>
                                                                <Popover.Body>
                                                                    <div>
                                                                        <span className="fw-bold me-2">{ct("workingAreas")}:</span>
                                                                        <span>{popoverEmployeeInfoobj?.popWorkingArea}</span>
                                                                    </div>
                                                                    <div>
                                                                        <span className="fw-bold me-2"> {ct("tags")}:</span>
                                                                        <span>{popoverEmployeeInfoobj?.popTags}</span>
                                                                    </div>
                                                                    <div>
                                                                        <span className="fw-bold me-2"> {cl('roasterView.currentBalanceOf')} {moment().add('day', -1).format("D/MM/YYYY")} : </span>
                                                                        {/* <span className="fw-bold me-2">Current balance as of {moment(calendarComponentRef?.current?.activeRange?.start).add('day', -1).format("D/MM/YYYY")} : </span> */}
                                                                        <span>{popoverEmployeeInfoobj?.popCurrentBalanceStart}</span>
                                                                    </div>
                                                                    <div>
                                                                        <span className="fw-bold me-2"> {cl('roasterView.currentBalanceOf')} {moment(EmppopoverInfoendDate).format("D/MM/YYYY")} : </span>
                                                                        <span>{popoverEmployeeInfoobj?.popCurrentbalanceEnd}</span>
                                                                    </div>
                                                                    <div>
                                                                        <span className="fw-bold me-2"> {cl('roasterView.hourlyAccountBilling')}:</span>
                                                                        <span>{popoverEmployeeInfoobj?.hourlybilling}</span>
                                                                    </div>
                                                                </Popover.Body>
                                                            </Popover>
                                                        }
                                                        >
                                                            <span>

                                                                <BsFillGearFill color='#ccc' onClick={() => { setSelectedEmployeeId(value.empId); setActiveEmployeeName(value.name); setemployeeSettingModal(true) }} className="ListActionIcon" />
                                                            </span>

                                                        </OverlayTrigger>
                                                    </div>
                                                    {
                                                        appSettings.locationsettings.Hide_employee_hours_in_calendar === false ?
                                                            <Badge className='p-2' bg={
                                                                value.weeklyassignHours > value.weeklytargetHours ? "danger" :
                                                                    value.weeklyassignHours >= (value.weeklytargetHours - 5) ? "warning" : "success"}>
                                                                {
                                                                    showWeek === false ?
                                                                        (value.weeklyassignHours.toString() + "/" + value.weeklytargetHours.toString())
                                                                        :
                                                                        (value.monthlyassignHours.toString() + "/" + value.monthlytargetHours.toString())
                                                                } h
                                                            </Badge>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                    }
                                    )}
                                </ListGroup>
                                <div className="position-relative mt-3">
                                    {
                                        appSettings.locationsettings.Employees_only_created_by_name === true ?
                                            user.roles.length > 0 && user.roles.some(x => x === Constants.roles.AccountAdmin || x === Constants.roles.WorkspaceAdmin) ?
                                                <div className='d-flex align-items-center'>
                                                    <form onSubmit={CreateEmployeeWithoutEmail}>
                                                        <Form.Group className="position-relative flex-grow-1">
                                                            <InputImoji placeholder={ept("newColleague")} value={text} onChange={v => setText(v)} />
                                                        </Form.Group>
                                                    </form>

                                                    <span className="btnPlusCircle ms-2" onClick={() => CreateEmployeeWithoutEmail()}><BsPlusCircle /></span>
                                                </div>
                                                : null
                                            : null
                                    }

                                </div>
                            </div>
                        </Col>
                        :
                        null
                }
                <Col lg={setting._fullCalendarView !== "resourceViewDay" && setting._fullCalendarView !== "resourceViewWeek" && setting._fullCalendarView !== "resourceViewMonth" ? 9 : 12} className="lgcol9">
                    <Row>
                        <Col md={setting._fullCalendarView !== "resourceViewDay" && setting._fullCalendarView !== "resourceViewWeek" && setting._fullCalendarView !== "resourceViewMonth" ? 3 : 7} className="mb-3">
                            {activeEmployeeButtons === false ?
                                // <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip>Create a new shift</Tooltip>}>
                                <a data-tooltip-id="my-tooltip" data-tooltip-content={cl('roasterView.createNewShiftTooltip')} href="javascript:;" onClick={() => { setCreateShiftMode("normal"); setNewShiftModal(true) }}>   <CircleBtnWithIcon className='Btn' color='#000'> <BsPlusSquare className="ActionToolIcon colorGreenPrimary  ms-0" />  </CircleBtnWithIcon></a>
                                // </OverlayTrigger>
                                :
                                <div>
                                    {/* <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip>Only show shifts of the activated employee</Tooltip>}> */}
                                    <a data-tooltip-id="my-tooltip" data-tooltip-content={cl('roasterView.employessTooltip')} href="javascript:;" > <CircleBtnWithIcon className='Btn' color={filterShifts ? "red" : '#000'}>   <FaRegUser onClick={() => setFilterShifts(!filterShifts)} className="ActionToolIcon redText colorGreenPrimary  ms-0" /> </CircleBtnWithIcon></a>
                                    {/* </OverlayTrigger> */}
                                    {/* <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip>Only download shifts of the activated employee</Tooltip>}> */}
                                    <a data-tooltip-id="my-tooltip" data-tooltip-content={cl('roasterView.downloadTooltip')} href="javascript:;" className='mx-2' >  <CircleBtnWithIcon className='Btn' color='#000'>  <FaDownload onClick={() => DownloadEmployeeShifts()} className="ActionToolIcon redText colorGreenPrimary  " /> </CircleBtnWithIcon></a>
                                    {/* </OverlayTrigger> */}
                                    {/* <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip>Send email to activated employee with shifts from displayed period</Tooltip>}> */}
                                    <a data-tooltip-id="my-tooltip" data-tooltip-content={cl('roasterView.mailTooltip')} href="javascript:;" >  <CircleBtnWithIcon className='Btn' color='#000'> <FaRegEnvelope onClick={(e) => setEmailModal(true)} className="ActionToolIcon redText colorGreenPrimary  ms-0" /> </CircleBtnWithIcon> </a>
                                    {/* </OverlayTrigger> */}
                                </div>
                            }
                        </Col>
                        <Col md={setting._fullCalendarView !== "resourceViewDay" && setting._fullCalendarView !== "resourceViewWeek" && setting._fullCalendarView !== "resourceViewMonth" ? 9 : 5} className="mb-3">
                            <Nav className="justify-content-end" activeKey="/home">
                                <Nav.Item>
                                    {/* <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip>Customize view</Tooltip>}> */}
                                    <Nav.Link data-tooltip-id="my-tooltip" data-tooltip-place="left" data-tooltip-content={cl('roasterView.customizeViewTool')} href="javascript:;" className="active p-0">

                                        <Dropdown className="ActionDDList" onSelect={(eventkey) => { ChangeViewOfCalendar(eventkey); localStorage.setItem("calendar_view", eventkey) }}>
                                            <Dropdown.Toggle as={CustomToggleSettings} id="dropdown-basic" className="btnDDList" menuAlign="right">
                                                <CircleBtnWithIcon className='Btn' color='#000'>
                                                    <BsGear className="ActionToolIcon" />
                                                </CircleBtnWithIcon>

                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className="ScrollableListMenu showbeGreen">
                                                <Dropdown.Header className="LinkListHeader"> {ct("views")}</Dropdown.Header>
                                                <Dropdown.Item eventKey="dailyViewList" active={setting._fullCalendarView === "dailyViewList" ? true : false}>
                                                    {cl('roasterView.dayViewList')}
                                                </Dropdown.Item>
                                                <Dropdown.Item eventKey="dailyViewhoursList" active={setting._fullCalendarView === "dailyViewhoursList" ? true : false}>
                                                    {cl('roasterView.dayViewHours')}
                                                </Dropdown.Item>
                                                <Dropdown.Item eventKey="weeklyViewList" active={setting._fullCalendarView === "weeklyViewList" ? true : false}>
                                                    {cl('roasterView.weekViewList')}
                                                </Dropdown.Item>
                                                <Dropdown.Item eventKey='weeklyViewhoursList' active={setting._fullCalendarView === "weeklyViewhoursList" ? true : false}>
                                                    {cl('roasterView.weekViewHours')}
                                                </Dropdown.Item>
                                                <Dropdown.Item eventKey='resourceViewDay' active={setting._fullCalendarView === "resourceViewDay" ? true : false}>
                                                    {cl('roasterView.resourceViewDay')}
                                                </Dropdown.Item>
                                                <Dropdown.Item eventKey="resourceViewWeek" active={setting._fullCalendarView === "resourceViewWeek" ? true : false}>
                                                    {cl('roasterView.resourceViewWeek')}
                                                </Dropdown.Item>
                                                <Dropdown.Item eventKey='resourceViewMonth' active={setting._fullCalendarView === "resourceViewMonth" ? true : false}>
                                                    {cl('roasterView.resourceViewMonth')}
                                                </Dropdown.Item>
                                                <Dropdown.Item eventKey="monthlyList" active={setting._fullCalendarView === "monthlyList" ? true : false}>
                                                    {cl('roasterView.monthViewCalendar')}
                                                </Dropdown.Item>
                                                <Dropdown.Item eventKey="Monthlist" active={setting._fullCalendarView === "Monthlist" ? true : false}>
                                                    {cl('roasterView.monthViewList')}
                                                </Dropdown.Item>
                                                {
                                                    setting._fullCalendarView !== "resourceViewDay" && setting._fullCalendarView !== "resourceViewWeek" && setting._fullCalendarView !== "resourceViewMonth" && setting._fullCalendarView !== "Monthlist" ?
                                                        <>
                                                            <Dropdown.Header className="LinkListHeader"> {cl('roasterView.settings')}</Dropdown.Header>
                                                            <Dropdown.Item eventKey="_planningIncomplete" active={setting._planningIncomplete}>
                                                                <BiCalendarExclamation className="me-3" /> {cl('roasterView.planningIncomplete')}
                                                            </Dropdown.Item>
                                                            {
                                                                setting._fullCalendarView !== "monthlyList" ?
                                                                    <Dropdown.Item eventKey="_compactCalendar" active={setting._compactCalendar} >
                                                                        <FaCalendar className="me-3" /> {cl('roasterView.compactCalendar')}
                                                                        {/* <FaCalendar eventKey="Monthlist" className="me-3" />Compact calendar */}
                                                                    </Dropdown.Item>
                                                                    : null
                                                            }
                                                            <Dropdown.Item eventKey="_oneRowPerSeries" active={setting._oneRowPerSeries} >
                                                                <FaListAlt className="me-3" /> {cl('roasterView.oneRowSeries')}
                                                            </Dropdown.Item>
                                                            <Dropdown.Item eventKey="_fixedHeadline" active={setting._fixedHeadline} >
                                                                <FaThumbtack className="me-3" /> {cl('roasterView.fixedHeadline')}
                                                            </Dropdown.Item>
                                                            {
                                                                setting._fullCalendarView !== "monthlyList" ?
                                                                    <Dropdown.Item eventKey="_showTitle" active={setting._showTitle} >
                                                                        <FaHeading className="me-3" />  {cl('roasterView.showTitle')}
                                                                    </Dropdown.Item>
                                                                    : null
                                                            }
                                                            {
                                                                setting._fullCalendarView !== "dailyViewhoursList" && setting._fullCalendarView !== "weeklyViewhoursList" && setting._fullCalendarView !== "monthlyList" ?
                                                                    <Dropdown.Item>
                                                                        <FaSortAmountDown className="me-3" />  {cl('roasterView.placeEnd')}
                                                                    </Dropdown.Item> : null
                                                            }
                                                            <Dropdown.Item eventKey="_showRequirements" active={setting._showRequirements}>
                                                                <FaUsers className="me-3" /> {cl('roasterView.showRequirements')}
                                                            </Dropdown.Item>
                                                            <Dropdown.Item eventKey="_hideAssignments" active={setting._hideAssignments} >
                                                                <FaEyeSlash className="me-3" /> {cl('roasterView.hideAssignments')}
                                                            </Dropdown.Item>
                                                            <Dropdown.Item eventKey="_hidetags" active={setting._hidetags} >
                                                                <FaTags className="me-3" /> {cl('roasterView.hideTags')}
                                                            </Dropdown.Item>
                                                        </>
                                                        : null
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Nav.Link>
                                    {/* </OverlayTrigger> */}
                                </Nav.Item>
                                {
                                    !(setting._fullCalendarView === "resourceViewDay" || setting._fullCalendarView === "resourceViewWeek" || setting._fullCalendarView === "resourceViewMonth") &&
                                    <Nav.Item className='mx-3'>
                                        <Nav.Link data-tooltip-place="left" data-tooltip-id="my-tooltip" data-tooltip-content={ct("actions")} href="javascript:;" className="p-0">
                                            <Dropdown className="ActionDDList">
                                                <Dropdown.Toggle as={CustomToggleSettings} id="dropdown-basic" className="btnDDList" menuAlign="right">
                                                    <CircleBtnWithIcon className='Btn' color='#000'>
                                                        <BsThreeDots className="ActionToolIcon" />

                                                    </CircleBtnWithIcon>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {
                                                        setting._fullCalendarView === "Monthlist" ?
                                                            <>
                                                                <Dropdown.Item onClick={() => setSendPlanModal(true)}>
                                                                    <FaEnvelope className="me-3" /> {cl('roasterView.sendPlan')}
                                                                </Dropdown.Item>
                                                            </>
                                                            :
                                                            <>
                                                                <Dropdown.Item onClick={() => setOccupyAutomaticallyModal(true)}>
                                                                    <FaMagic className="me-3" /> {cl('roasterView.occupyAutomatically')}
                                                                </Dropdown.Item>
                                                                <Dropdown.Item onClick={() => setSelectTemplateModal(true)}>
                                                                    <IoDocumentsSharp className="me-3" />{cl('roasterView.useTemplate')}
                                                                </Dropdown.Item>
                                                                <Dropdown.Item onClick={() => setSendPlanModal(true)}>
                                                                    <FaEnvelope className="me-3" /> {cl('roasterView.sendPlan')}
                                                                </Dropdown.Item>
                                                                <Dropdown.Item onClick={() => setSandBoxModal(true)}>
                                                                    <FaUserSecret className="me-3" /> {cl('roasterView.sandboxMode')}
                                                                </Dropdown.Item>
                                                            </>
                                                    }

                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Nav.Link>
                                    </Nav.Item>
                                }
                                {calendarTable === false &&
                                    <Nav.Item>
                                        <div className="ToolMonthChange">
                                            <div>
                                                <a><BsChevronLeft onClick={(e) => PrevMonthsEmpFull()} className="btnPrev" /></a>
                                            </div>
                                            <div className='mx-3'>
                                                <div className="MonthChangeContainer" onClick={() => { setShowHide(!showHide) }}>
                                                    <FaRegCalendarAlt className="CalIcon" />

                                                    <span className="MonthTitle">{weekNum}</span>
                                                </div>
                                            </div>
                                            <div>
                                                <a><BsChevronRight onClick={(e) => NextMonthsEmpFull()} className="btnNext" /></a>
                                            </div>
                                        </div>
                                    </Nav.Item>
                                }
                            </Nav>
                        </Col>

                    </Row>

                    <Roster
                        OccupanyInotherLocations={OccupanyInotherLocations}
                        _oneRowPerSeries={setting._oneRowPerSeries}
                        resourceHeaderTitle="TEst"
                        _showTitle={setting._showTitle}
                        resourceViewData={EmployeeList}
                        view={setting._fullCalendarView}
                        _fixedHeadline={setting._fixedHeadline}
                        _hideAssignments={setting._hideAssignments}
                        _compactCalendar={setting._compactCalendar}
                        _planningIncomplete={setting._planningIncomplete}
                        _tags={setting._hidetags}
                        _showRequirements={setting._showRequirements}
                        activeTime={activeEmployeeButtons}
                        activeindex={activeindex}
                        showborderClass={showborderClass}
                        filterShifts={filterShifts}
                        timeBelowHeader={showTimeBelowHeader}
                        GetEmployeeList={GetEmployeeList}
                        // handleModalShowHide={this.handleModalShowHide}
                        // newshiftTrigger={this.state.newshiftTrigger}
                        calendarComponentRef={calendarComponentRef}
                        SelectedEmployeeId={SelectedEmployeeId}
                        ShiftTrigget={ShiftTrigget}
                        setDayClickDate={setDayClickDate}
                        setNewShiftModal={setNewShiftModal}
                        setCreateShiftMode={setCreateShiftMode}
                    />
                </Col>
            </Row>
            <Modal show={showHide} onHide={() => setShowHide(false)} size="sm">
                <Modal.Header closeButton>
                    <Modal.Title> {ct("chooseDate")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12} className="m-auto InlineDatePickerContainer">
                            <DatePicker ClassName="form-control" showWeekNumbers selected={calendarComponentRef?.current?.activeRange.start} onChange={date => { getChooseDate(date) }} inline />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            {
                NewShiftModal === true ?
                    <CreateShift
                        show={NewShiftModal} onHide={setNewShiftModal}
                        SelectedEmployeeId={SelectedEmployeeId} calendar={calendarComponentRef}
                        GetEmployeeList={GetEmployeeList} UpdateShiftChildTrigger={UpdateShiftChildTrigger}
                        setCreateShiftMode={setCreateShiftMode}
                        CreateShiftMode={CreateShiftMode}
                        DayClickDate={DayClickDate}
                        showRepetation={true}
                    />
                    : null
            }
            {
                SelectTemplateModal === true ?
                    <CopyTemplateModal show={SelectTemplateModal} onHide={setSelectTemplateModal}
                        GetEmployeeList={GetEmployeeList} UpdateShiftChildTrigger={UpdateShiftChildTrigger}
                        CalendarweekNum={weekNum}
                        calendar={calendarComponentRef} />
                    : null
            }
            {
                SendPlanModal === true ?
                    <SendRosterPlanModal show={SendPlanModal} onHide={setSendPlanModal} calendar={calendarComponentRef} />
                    : null
            }
            {
                OccupyAutomaticallyModal === true ?
                    <OccupyAutomaticallySettingModal show={OccupyAutomaticallyModal} GetEmployeeList={GetEmployeeList} UpdateShiftChildTrigger={UpdateShiftChildTrigger}
                        calendar={calendarComponentRef} onHide={setOccupyAutomaticallyModal} />
                    : null
            }
            {
                employeeSettingModal === true ?
                    <EmployeeSettingModal
                        show={employeeSettingModal}
                        setShow={setemployeeSettingModal}
                        GetEmployeeList={GetEmployeeList}
                        EmpNameForEmployeeSetting={activeEmployeeName}
                        EmpIdForEmployeeSetting={SelectedEmployeeId}

                    />
                    : null
            }
            {
                SandBoxModal === true ?
                    <SandBox show={SandBoxModal} onHide={setSandBoxModal} GetEmployeeList={GetEmployeeList} UpdateShiftChildTrigger={UpdateShiftChildTrigger} calendar={calendarComponentRef} />
                    : null
            }
            {
                emailModal === true ?
                    <OpenEmailModal calendar={calendarComponentRef} name={activeEmployeeName} show={emailModal} setShow={setEmailModal} SelectedEmployeeId={SelectedEmployeeId} />
                    : null
            }
        </>
    );
}

export default RosterView;

function OpenEmailModal(props) {
    const { t: cl } = useTranslation('calendarPage');


    const [message, setmessage] = useState("");
    useEffect(() => {
        onLoad()
    }, []);
    const onLoad = async () => {
        var data = {
            starDate: props.calendar.current.activeRange.start,
            endDate: props.calendar.current.activeRange.end,
            empId: props.SelectedEmployeeId.toString()
        }
        var response = await CallAxios("/api/Calendar/SendShiftsToEmployee|post", data)
        if (!!response && response.status === 200) {
            if (response.data === "Noemail") {
                setmessage(props.name + ' ' + cl("informationMdl.noEmailMsg"))
            }
            if (response.data === false) {
                props.setShow(false)
            }
            if (response.data === true) {
                setmessage('Assignments for ' + props.name + ' ' + cl("informationMdl.sentMsg"))
            }
            if (response.data === "norecord") {
                setmessage(props.name + ' ' + cl("informationMdl.recordRangeMsg"))
            }
        }

    }
    return (
        <Modal show={props.show} onHide={() => { props.setShow(false) }}>
            <Modal.Header closeButton >
                <Modal.Title>Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message}
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btnPrimaryGreen" onClick={() => props.setShow(false)}>OK</Button>
            </Modal.Footer>
        </Modal>
    )
}
