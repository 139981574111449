import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { FaPlus, FaRegTrashAlt } from 'react-icons/fa';
import { FcDownRight } from "react-icons/fc";
import { HiPlus } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import SelectInput from "react-select";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { EditWorkspaceColor } from '../../../CommonJSON';
import { ArchiveBox, Gear, Note, Remove } from '../../../Components/ActionBtns';
import DataGrid from '../../../Components/DataGrid';
import HeaderButton from '../../../Components/HeaderButton';
import NotesModal from '../../../Components/NoteModal';
import InputImoji from '../../../Components/inputImoji';
import { CallAxios } from '../../../Helpers';
import { SignInManager } from '../../../Helpers/Authentication/SignInManager';
import { ActiveInactiveStatusBox, Badge, DOT } from '../../../Helpers/Global/StyledComponents';
import { GetEmployeeDD, UpdateReduxStates } from '../../../Helpers/MasterData';
import { useTranslation } from "react-i18next";


function WorkingAreaGrid(props) {
    const { t: Lst } = useTranslation('locationSetting');
    const { t: ct } = useTranslation('common');

    const dispatch = useDispatch();
    const [WorkSpaceIdForEdit, setWorkSpaceIdForEdit] = useState("");
    const [DeactiveConfirmationModal, setDeactiveConfirmationModal] = useState(false);
    const [ShowDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [showAddEditWorkArea, setShowAddEditWorkArea] = useState(false);
    const [showNotesModal, setshowNotesModal] = useState(false);
    const [thirdChildListIds, setthirdChildListIds] = useState([]);
    const [disableworkspaceModal, setdisableworkspaceModal] = useState(false);
    const [deleteworkspaceModal, setdeleteworkspaceModal] = useState(false);

    const changeStatus = async (id) => {
        await CallAxios("/api/LocationSetting/changeWorkSpaceStatus|post", { id: id });
        setDeactiveConfirmationModal(false)
        props.getplanningData();
        await UpdateReduxStates(new SignInManager().ParseJwt, dispatch)

    };
    const DeleteWorkspace = async (id) => {
        var response = await CallAxios("/api/LocationSetting/DeleteSpaceStatus|post", { id: id });
        if (!!response && response.data === true) {
            setShowDeleteConfirmationModal(false)
        }
        await UpdateReduxStates(new SignInManager().ParseJwt, dispatch)
        props.getplanningData();
    };
    const setThirdIds = () => {
        if (props.WorkAreaList.length > 0) {
            let newARr = [];
            props.WorkAreaList.filter(i => i.parentId === null).map((val) =>
                props.WorkAreaList.filter(i => i.parentId === val.id).map((child1,) =>
                    props.WorkAreaList.filter(i => i.parentId === child1.id).map((child2) => {
                        newARr.push(child2);
                    })
                )
            )
            setthirdChildListIds(newARr)
        }
    }
    useEffect(() => {
        setThirdIds();
    }, [props.WorkAreaList]);

    const columns = [
        { key: '#', name: '#' },
        { key: 'working_area', name: Lst('planTab.workArea.workingAreas').toString() },
        { key: 'status', name: Lst('planTab.workArea.status').toString() },
        { key: 'action', name: Lst('planTab.workArea.action').toString() },

    ];

    return (
        <>
            <div className='d-flex align-items-center justify-content-between mb-3'>
                <h5 className='fw-bold mb-0'> {Lst('planTab.workArea.workingAreas')}</h5>
                <HeaderButton title={Lst('planTab.workArea.addWork')} onClick={(e) => { setWorkSpaceIdForEdit(""); setShowAddEditWorkArea(true) }} icon={<HiPlus color='#fff' size={18} />} />
            </div>
            <DataGrid
                columns={columns}
                rows={props.WorkAreaList}
                hidesecondTH
                customBody={(rows) =>
                    !!props.WorkAreaList && props.WorkAreaList.length > 0
                        ? props.WorkAreaList.filter(i => i.parentId === null).map((val, index) => {

                            return (
                                <>
                                    <tr key={"tableList_" + index}>
                                        <td>{index + 1}</td>
                                        <td>
                                            <div className="align-items-center d-flex dataTextCell">{!!val.color ? <DOT color={val.color} /> : null}  {val.title}</div>
                                        </td>
                                        <td>
                                            <div className="dataTextCell">
                                                <Button className='me-2 w-100' status={val.status}>{val.status === true ? <>{ct('active')}</> : <>{ct('inActive')}</>}</Button>
                                            </div>
                                        </td>
                                        <td>
                                            {val.status === true ? (
                                                <>
                                                    <Gear data-tooltip-id="my-tooltip" data-tooltip-content={ct('edit')} color='#0d7be3' onClick={(e) => { setWorkSpaceIdForEdit(val.id); setShowAddEditWorkArea(true) }} />
                                                    <Note data-tooltip-id="my-tooltip" data-tooltip-content={ct('notes')} color='#e1ac1d' onClick={(e) => { setWorkSpaceIdForEdit(val.id); setshowNotesModal(true) }} />
                                                    {
                                                        props.WorkAreaList.filter(i => i.status === true).length > 1 &&
                                                        <ArchiveBox data-tooltip-id="my-tooltip" data-tooltip-content={Lst('planTab.workArea.deactiveWorkTooltip')} color='#9602c1' onClick={(e) => { setWorkSpaceIdForEdit(val.id); setDeactiveConfirmationModal(true) }} />
                                                    }
                                                </>
                                            ) : null}
                                            {
                                                (props.WorkAreaList.filter(i => i.status === true).length > 1 || val.status === false) &&
                                                <Remove data-tooltip-id="my-tooltip" data-tooltip-content={ct('remove')} color='#f25656' onClick={(e) => { setWorkSpaceIdForEdit(val.id); setShowDeleteConfirmationModal(true) }} />
                                            }

                                        </td>
                                    </tr>
                                    {
                                        props.WorkAreaList.filter(i => i.parentId === val.id).map((vChild1, indexChild1) =>
                                            <>
                                                <tr key={"tableListChild_" + indexChild1}>
                                                    <td>
                                                        <div className="pl-4">
                                                            <FcDownRight style={{ fontSize: "20px" }} />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="dataTextCell">{vChild1.title}</div>
                                                    </td>
                                                    <td>
                                                        <div className="dataTextCell">
                                                            <Button className='me-2 w-100' status={vChild1.status}>{vChild1.status === true ? <>{ct('active')}</> : <>{ct('inActive')}</>}</Button>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {vChild1.status === true ? (
                                                            <>
                                                                <Gear color='#0d7be3' onClick={(e) => { setWorkSpaceIdForEdit(vChild1.id); setShowAddEditWorkArea(true) }} />
                                                                <Note color='#e1ac1d' onClick={(e) => { setWorkSpaceIdForEdit(val.id); setshowNotesModal(true) }} />
                                                                <ArchiveBox color='#9602c1' onClick={(e) => { changeStatus(vChild1.id) }} />

                                                            </>
                                                        ) : null}
                                                        <Remove color='#f25656' onClick={(e) => { setWorkSpaceIdForEdit(vChild1.id); setShowDeleteConfirmationModal(true) }} />

                                                    </td>
                                                </tr>
                                                {
                                                    props.WorkAreaList.filter(i => i.parentId === vChild1.id).map((vChild2, indexChild2) =>
                                                        <tr key={"tableListChild2_" + indexChild2}>
                                                            <td>
                                                                <div className="pl-5">
                                                                    <FcDownRight style={{ fontSize: "20px" }} />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="dataTextCell">{vChild2.title}</div>
                                                            </td>
                                                            <td>
                                                                <div className="dataTextCell">
                                                                    {vChild2.status === true ? (
                                                                        <Badge className="badgesuccess"> {ct('active')} </Badge>
                                                                    ) : (
                                                                        <Badge className="badgewarning">
                                                                            {ct('inActive')}
                                                                        </Badge>
                                                                    )}
                                                                    <ActiveInactiveStatusBox className='me-2' status={vChild2.status}>{vChild2.status === true ? <>{ct('active')}</> : <>{ct('inActive')}</>}</ActiveInactiveStatusBox>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {vChild2.status === true ? (
                                                                    <>
                                                                        <Gear color='#0d7be3' onClick={(e) => { setWorkSpaceIdForEdit(vChild2.id); setShowAddEditWorkArea(true) }} />
                                                                        <Note color='#e1ac1d' onClick={(e) => { setWorkSpaceIdForEdit(val.id); setshowNotesModal(true) }} />
                                                                        <ArchiveBox color='#9602c1' onClick={(e) => { changeStatus(vChild2.id) }} />
                                                                    </>
                                                                ) : null}
                                                                <Remove color='#f25656' onClick={(e) => { setWorkSpaceIdForEdit(vChild2.id); setShowDeleteConfirmationModal(true) }} />
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </>
                                        )}
                                </>
                            );
                        })
                        : null
                } />

            {
                showAddEditWorkArea === true ? (
                    <AddWorkspace
                        show={showAddEditWorkArea}
                        onHide={setShowAddEditWorkArea}
                        WorkAreaList={props.WorkAreaList}
                        getWorkSpaces={props.getplanningData}
                        thirdChildListIds={thirdChildListIds}
                        WorkSpaceIdForEdit={WorkSpaceIdForEdit}
                    />
                )
                    : null
            }
            {
                showNotesModal === true ? (
                    <WorkingAreaNoteModel show={showNotesModal} onHide={setshowNotesModal} objIdforNote={WorkSpaceIdForEdit} />
                )
                    : null
            }
            {/* {
                disableworkspaceModal === true ? (
                    <DisableWorkspace
                        show={disableworkspaceModal}
                        setshow={setdisableworkspaceModal}
                    />
                )
                    : null
            } */}
            {
                DeactiveConfirmationModal === true ?
                    <DeactiveConfirmationMessageModal show={DeactiveConfirmationModal} setShow={setDeactiveConfirmationModal} WorkSpaceIdForDelete={WorkSpaceIdForEdit} fnDeactiveWorkspace={changeStatus} />
                    : null
            }
            {
                deleteworkspaceModal === true ? (
                    <DeleteWorkspace
                        show={deleteworkspaceModal}
                        setshow={setdeleteworkspaceModal}
                    />
                )
                    : null
            }
            {
                ShowDeleteConfirmationModal === true ?
                    <DeleteConfirmationModal show={ShowDeleteConfirmationModal} setShow={setShowDeleteConfirmationModal} WorkSpaceIdForDelete={WorkSpaceIdForEdit} fnDeleteWorkspace={DeleteWorkspace} />
                    : null
            }
        </>

    );
}
export default WorkingAreaGrid;

function AddWorkspace(props) {
    const { t: ct } = useTranslation('common');
    const { t: Lst } = useTranslation('locationSetting');

    const DropdownIndicator = () => null;
    // const PickerDropDown = { right: "0px !important", top: "45px !important" };
    // const [hideShowEmojipicker, sethideShowEmojipicker] = useState(false);
    const dispatch = useDispatch();
    const [parentArea, setparentArea] = useState(false);
    const [SelectValue, setSelectValue] = useState("");
    const [getEmployeeIds, setEmployeeIds] = useState([]);
    const [options, setoptions] = useState([]);
    const [optionsparent, setoptionsparent] = useState([]);

    useEffect(() => {
        if (props.WorkAreaList.length > 0) {
            setoptionsparent(props.WorkAreaList);
        }
    }, [props.WorkAreaList]);
    useEffect(() => {
        GetEmployeeDD(setoptions)
        if (!!props.WorkSpaceIdForEdit) {
            getData()
        }
    }, []);
    const getData = async () => {
        var data = {
            id: props.WorkSpaceIdForEdit,
        };
        var response = await CallAxios(
            "/api/LocationSetting/getWorkSpace|post", data
        );

        if (!!response && response.data) {
            formik.setFieldValue("name", response.data.title);
            formik.setFieldValue("parentId", response.data.parentId);
            formik.setFieldValue("color", response.data.colour);

            if (!!response.data.parentId) {
                setparentArea(true)
            }

            let selectdEmpids = [];
            if (response.data.selectedEmployeeobjList.length > 0) {
                response.data.selectedEmployeeobjList.map((val, key) => {
                    selectdEmpids.push(val.value)
                })
                setEmployeeIds(selectdEmpids);
                setSelectValue(response.data.selectedEmployeeobjList);
            }
        }
    }
    // const EmojiOnChange = (e) => {
    //     e.preventDefault();
    //     //   setText(e.target.value);
    //     formik.setFieldValue("name", e.target.value);
    // };
    // const EmojiHandleClick = (n, e) => {
    //     //   setText(text +  e.emoji);

    //     formik.setFieldValue("name", formik.values.name + n.emoji);
    // };
    // const handleEmojipicker = () => {
    //     sethideShowEmojipicker(!hideShowEmojipicker);
    // };
    const setparentSelectValue = (e) => {
        if (props.thirdChildListIds.filter(x => x.id === e.target.value).length > 0) {
            Swal.fire(Lst('planTab.workArea.workSpaceLinkedError'))

        } else {

            formik.setFieldValue("parentId", e.target.value);
        }
    };
    const formik = useFormik({
        initialValues: {
            name: "",
            parentId: "",
            color: "",
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(ct('required').toString()),
        }),
        onSubmit: (values) => {
            submit(values);
        },
    });
    const submit = async (values) => {
        var data = {
            Id: props.WorkSpaceIdForEdit,
            title: values.name,
            parentId: values.parentId,
            colour: values.color,
            employeeIds: getEmployeeIds
        };

        var response = await CallAxios(
            "/api/LocationSetting/addeditWorkSpace|post", data
        );
        if (!!response && response.data === true) {
            props.getWorkSpaces();
            await UpdateReduxStates(new SignInManager().ParseJwt, dispatch)
            props.onHide(false);
        } else {
            toast.info(response.data)
        }
    };
    const GetColor = (e) => {
        let siblings = e.target.parentElement.children;
        for (let sib of siblings) {
            sib.classList.remove('selected')
        }

        e.target.classList.add("selected");

        formik.setFieldValue("color", e.target.getAttribute("data-color"))

    };
    return (
        <Modal size='xl' show={props.show} className="" onHide={() => props.onHide(false)}>
            <Modal.Header
                closeButton
                // className=""
                className=""
            >
                <Modal.Title>{!!props.WorkSpaceIdForEdit ? <>{ct('edit')}</> : <>{ct('new')}</>} {Lst('planTab.workArea.workingAreas')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs
                    defaultActiveKey="Ideas"
                    transition={false}
                    id="WorkSpaceTabs"
                    className="mb-3 nav-underline"
                >
                    <Tab eventKey="Ideas" title={Lst('planTab.newWorkMdl.ideas')}>
                        <Form.Group className="position-relative mb-3">
                            <label className="fw-bold mb-2"> {Lst('planTab.newWorkMdl.titleLabel')}</label>
                            <div className="position-relative">
                                <InputImoji placeholder={Lst('planTab.newWorkMdl.titlePlace')} value={formik.values.name} onChange={v => formik.setFieldValue("name", v)} />
                            </div>
                            {formik.touched.name && formik.errors.name ? (
                                <span className="error text-danger">{formik.errors.name}</span>
                            ) : null}
                        </Form.Group>
                        <Form.Group className="position-relative mb-3">
                            <label className="fw-bold mb-2"> {Lst('planTab.newWorkMdl.color')}</label>
                            <div className="position-relative">
                                <p>
                                    <span className="text-danger">{Lst('planTab.newWorkMdl.caution')}</span>:
                                    {Lst('planTab.newWorkMdl.cautionPara')}!
                                </p>
                                <span className="SimpleColorPicker">
                                    {EditWorkspaceColor.map((value, index) => {
                                        return <span
                                            onClick={GetColor}
                                            className={`${value.class} ${formik.values.color === value.dataColor ? "selected" : null}`}
                                            title={value.title}
                                            data-color={value.dataColor}
                                            style={{ backgroundColor: value.dataColor }}

                                        ></span>
                                    })}
                                </span>
                            </div>
                        </Form.Group>

                        <Button
                            className="btn btnPrimaryGreen text-white"
                            onClick={(e) => setparentArea(true)}
                        >
                            {Lst('planTab.newWorkMdl.turnSubarea')}
                        </Button>
                        {parentArea ? (
                            <Form.Group className="mt-3">
                                <label className="fw-bold"> {Lst('planTab.newWorkMdl.parentArea')}</label>
                                <select
                                    name="parentId"
                                    className="SelectbgGrey custom-select form-select"
                                    value={formik.values.parentId}
                                    onChange={(e) => setparentSelectValue(e)}
                                    id=""
                                >
                                    <option> {ct('none')}</option>
                                    {!!optionsparent && optionsparent.length > 0
                                        ? optionsparent.map((val, key) => {
                                            return <option value={val.id}>{val.title}</option>;
                                        })
                                        : null}
                                </select>
                            </Form.Group>
                        ) : null}
                    </Tab>
                    <Tab eventKey="Employees" title={ct('employee') + ` ${getEmployeeIds.length}`}>
                        <div className="d-flex mb-3 justify-content-between align-items-center">
                            <a
                                className=" transparentBtnGreen btn btn-primary btn-sm"
                                onClick={(e) => { setSelectValue(options); setEmployeeIds(options.map((x) => x.value)) }}
                            >
                                <FaPlus className="me-1" /> {ct('addAll')}
                            </a>
                            <a
                                className=" transparentBtnred btn btn-danger btn-sm"
                                onClick={(e) => { setSelectValue(""); setEmployeeIds([]) }}
                            >
                                <FaRegTrashAlt className="me-1" /> {ct('removeAll')}
                            </a>
                        </div>
                        <SelectInput
                            classNamePrefix="MyReactSelect"
                            isMulti
                            isClearable={false}
                            components={{ DropdownIndicator }}
                            options={options}
                            value={SelectValue}
                            styles={{
                                indicatorSeparator: (base) => ({
                                    ...base,
                                    display: "none",
                                }),
                            }}
                            onChange={(value, action) => {
                                let stringarr = [];
                                for (let i = 0; i < value.length; i++) {
                                    stringarr.push(value[i].value)
                                }
                                setEmployeeIds(stringarr);
                                setSelectValue(value);
                            }}
                        />
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    type="button"
                    className="btn btnPrimaryGreen text-white"
                    onClick={(e) => formik.handleSubmit()}
                >
                    {ct('ready')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function WorkingAreaNoteModel(props) {
    const { t: ct } = useTranslation('common');

    return (
        <Modal show={props.show} onHide={() => props.onHide(false)}>
            <Modal.Header closeButton >
                <Modal.Title> {ct('note')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col md={12}>
                            <Container fluid>
                                <NotesModal objIdforNote={props.objIdforNote} noteEntity="Workspace" />
                            </Container>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-secondary" onClick={() => props.onHide(false)}> {ct('shutdown')}</Button>
            </Modal.Footer>
        </Modal>
    )
}
function DeactiveConfirmationMessageModal(props) {
    const { t: ct } = useTranslation('common');
    const { t: Lst } = useTranslation('locationSetting');


    return (
        <>
            <Modal show={props.show} onHide={() => props.setShow(false)} size="md">
                <Modal.Header closeButton>
                    <Modal.Title> {Lst('planTab.deActiveWorkMdl.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body lead'>
                    <Row>
                        <Col md={12}>
                            <p>
                                {Lst('planTab.deActiveWorkMdl.para')}. </p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='mb-2'>
                    <Button variant='btn btn-primary ' className="" onClick={() => props.setShow(false)}> {ct('cancel')}</Button>
                    <Button variant='btn btn-danger ' className="" onClick={() => props.fnDeactiveWorkspace(props.WorkSpaceIdForDelete)}> {Lst('planTab.deActiveWorkMdl.yesDeactiveNow')}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
function DeleteConfirmationModal(props) {
    const { t: ct } = useTranslation('common');
    const { t: Lst } = useTranslation('locationSetting');

    return (
        <>
            <Modal show={props.show} onHide={() => props.setShow(false)} size="md">
                <Modal.Header closeButton>
                    <Modal.Title> {Lst('planTab.deleteWorkMdl.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body lead'>
                    <Row>
                        <Col md={12}>
                            <p>
                                {Lst('planTab.deleteWorkMdl.deletePara')}.
                            </p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='mb-2'>
                    <Button variant='btn btn-primary ' className="" onClick={() => props.setShow(false)}> {ct('cancel')}</Button>
                    <Button variant='btn btn-danger ' className="" onClick={() => props.fnDeleteWorkspace(props.WorkSpaceIdForDelete)}> {ct('yesDeleteNow')}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
// function DisableWorkspace(props) {


//     return (
//         <></>
//         // <Modal show={props.show} className="" onHide={() => props.setshow(false)}>
//         //     <Modal.Header closeButton onClick={() => props.setshow(false)} className="">
//         //         <Modal.Title>Disable workspace</Modal.Title>
//         //     </Modal.Header>
//         //     <Modal.Body>
//         //         <p>Are you sure you want to disable this workspace? The work area, as well as the associated shifts and appointments from the past, no longer appear in the calendar. However, the time recordings are retained. Future shifts are completely deleted from the database.</p>
//         //     </Modal.Body>
//         //     <Modal.Footer>
//         //         <button className="btn btn-secondary text-white" onClick={(e) => props.setshow(false)}>Interrupt</button>
//         //         <button className="btn btn-danger text-white" onClick={(e) => props.setshow(false)}>Yes, deactivate now</button>

//         //     </Modal.Footer>
//         // </Modal>
//     );
// }
