import React, { useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { useSelector } from 'react-redux';
import styled from 'styled-components';
const CollapseWrapper = styled.div`
border-radius:5px;
border: 1px solid #ccc;
padding:20px;
& .pointer{
    cursor:pointer;
}
`

function CollapseableDiv(props) {
  const [open, setOpen] = useState(false);
  const themeColor = useSelector(state => state.app.theme.colors.bg);
  return (
    <CollapseWrapper className='mb-3'>
      <div className='d-flex align-items-center justify-content-between'>
        <h5 className='fw-bold mb-0'>{props.title}</h5>
        {open && <AiOutlineMinus className='pointer' onClick={() => setOpen(!open)} color={themeColor} fontSize={35} />}
        {!open && <AiOutlinePlus className='pointer' onClick={() => setOpen(!open)} color={themeColor} fontSize={35} />}
      </div>
      <Collapse in={open}>
        <div className='CollapseDivWrapper'>
          {props?.children}
        </div>
      </Collapse>
    </CollapseWrapper>
  );
}

export default CollapseableDiv;