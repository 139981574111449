import React, { useEffect, useState } from 'react';
import { Nav } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AxiosLite } from '../../Helpers';
import { ContentBox, ContentSubHeader } from '../../Helpers/Global/StyledComponents';
import ThemeSetting from '../LocationSettings/Theme/ThemeSetting';
import APITab from './APITab';
import AccountSettingHeader from './AccountSettingHeader';
import AvailibilityValidations from './AvailibilityValidations';
import BaseDataTab from './BaseDataTab';
import DataProfileTab from './DataProfileTab';
import DisplayTab from './DisplayTab';
import LocationTemplateTab from './LocationTemplateTab';
import MoreSettingTab from './MoreSettingTab';
import WorkSchedules from './WorkSchedules';
import { useTranslation } from "react-i18next";

const TabsWrapper = styled.div`
border:1px solid #ccc;
background-color:#fff;
border-radius:5px;
& .nav.nav-tabs {
    background: ${props => props.theme.colors.bg};

}
& .nav.nav-tabs .nav-link{
    border-radius:0px;
    font-weight:600;
    color:#fff;
}
& .nav.nav-tabs .nav-link.active{
    color:${props => props.theme.colors.active};
}
`



function AccountSettings() {
    const { t: ast } = useTranslation('accountSetting');


    const location = useLocation();
    const [key, setKey] = useState('');
    const [BaseData, setBaseData] = useState({});
    useEffect(() => {
        getbaseData()
    }, []);
    const getbaseData = async () => {
        var response = await AxiosLite("/api/AccountSetting/basedata|get");
        if (!!response && response.status === 200) {
            setBaseData(response.data)
        }
    }
    const TabsList = [
        {
            title: "baseData",
            eventKey: "baseData",
            component: <BaseDataTab BaseData={BaseData} />,
            url: "master_data"
        },
        {
            title: "moreSettings",
            eventKey: "moreSettings",
            component: <MoreSettingTab BaseData={BaseData} />,
            url: "further"
        },
        {
            title: "display",
            eventKey: "display",
            component: <DisplayTab />,
            url: "display"
        },
        {
            title: "API",
            eventKey: "api",
            component: <APITab />,
            url: "api"
        },
        {
            title: "dataProfile",
            eventKey: "dataProfile",
            component: <DataProfileTab />,
            url: "data_profiles"
        },
        {
            title: "themes",
            eventKey: "themes",
            component: <ThemeSetting />,
            url: "themes"
        },
        {
            title: "locationTemplate",
            eventKey: "locationTemplate",
            component: <LocationTemplateTab />,
            url: "location_templates"
        },
        {
            title: "workSchedules",
            eventKey: "workingTimeModels",
            component: <WorkSchedules />,
            url: "work_schedules"
        },
        {
            title: "conditionsAvailability",
            eventKey: "conditionsForAvailability",
            component: <AvailibilityValidations />,
            url: "availabilities_rules"
        },
    ]

    return (
        <>
            <ContentSubHeader>
                <AccountSettingHeader />
            </ContentSubHeader>
            <ContentBox>
                <TabsWrapper>
                    <Tab.Container
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className=' mb-3'
                    >
                        <Nav variant="tabs" className="">
                            {TabsList?.map(x => <Nav.Item>
                                <Nav.Link as={NavLink} to={location.pathname.substring(0, location.pathname.lastIndexOf("/")) + "/" + x.url} className='px-3 pb-3' eventKey={x.eventKey}>{ast(x.title)}</Nav.Link>
                            </Nav.Item>
                            )}
                        </Nav>

                        <Tab.Content>
                            <Tab.Pane eventKey={key} className='active show px-3 py-4' ><Outlet /> </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>

                </TabsWrapper>
            </ContentBox>

        </>
    );
}

export default AccountSettings;