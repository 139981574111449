import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { FaPlus } from "react-icons/fa";
import { Gear, Remove } from "../../Components/ActionBtns";
import HeaderButton from "../../Components/HeaderButton";
import TblSwitchToogleButton from "../../Components/TblSwitchToogleButton";
import { AxiosLite, CallAxios } from "../../Helpers";
import { ActiveInactiveStatusBox, TableWrapper } from "../../Helpers/Global/StyledComponents";
import { useTranslation } from "react-i18next";
import DeleteConfirmationModal from '../../Components/DeleteConfirmationModal';


function DisplayTab(props) {
    const { t: ast } = useTranslation('accountSetting');
    const { t: ct } = useTranslation('common');

    const [NewMsgModal, setNewMsgModal] = useState(false);
    const [NewsList, setNewsList] = useState([]);
    const [NewsForEditID, setNewsForEditID] = useState("");
    const [ShowDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

    useEffect(() => {
        getNewsList()
    }, []);

    const onchangeStatusSubmit = async (e, status) => {
        var data = {
            Id: e.id,
            Content: e.content,
            Status: status
        }

        var response = await CallAxios("/api/AccountSetting/UpdateNews|post", data)
        if (!!response && response.data === true) {
            await getNewsList();
        }
    }
    const onDeleteSubmit = async (id) => {
        var response = await CallAxios("/api/AccountSetting/DeleteNews|post", { id: id })
        if (!!response && response.data === true) {
            await getNewsList();
            setShowDeleteConfirmationModal(false)
        }
    }
    const getNewsList = async () => {
        var response = await AxiosLite("/api/AccountSetting/NewList|get");
        if (!!response && response.status === 200) {
            setNewsList(response.data)
        }
    }

    return (
        <>
            {
                ShowDeleteConfirmationModal &&
                <DeleteConfirmationModal show={ShowDeleteConfirmationModal} onHide={setShowDeleteConfirmationModal}
                    title={ast('displayTab.deleteMdl.title')} message={ast('displayTab.deleteMdl.desc')} deletefn={onDeleteSubmit} objId={NewsForEditID} />
            }
            <Row>
                <Col md={12}>
                    <div className="TabFormContainerSection">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="Heading h5 fw-bold mb-3"> {ast('displayTab.news')}</div>
                            <HeaderButton title={ast('displayTab.addNew')} onClick={() => { setNewsForEditID(""); setNewMsgModal(true) }} icon={<FaPlus color='#fff' size={18} />} />

                        </div>
                        <p> {ast('displayTab.newsPara')}.</p>
                        <TableWrapper>
                            <Table striped bordered responsive className="DataGridTable  overflowVisible">
                                <thead>
                                    <tr className="firsthead">
                                        <th> {ast('displayTab.content')}</th>
                                        <th> {ct('status')}</th>
                                        <th> {ct('action')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !!NewsList && NewsList.length > 0 ? NewsList.map((value, key) => {
                                            return <tr>
                                                <td>
                                                    <div className="dataTextCell">{value.content}</div>
                                                </td>
                                                <td>
                                                    <div className="dataTextCell">
                                                        <Button className={value.status === true ? "badgesuccess d-block w-100" : "badgewarning d-block w-100"} status={value.status}>{value.status === true ? <>{ct('active')}</> : <>{ct('inActive')}</>}</Button>
                                                    </div>
                                                </td>
                                                <td>
                                                    <Gear onClick={(e) => { setNewsForEditID(value.id); setNewMsgModal(true) }} />
                                                    <TblSwitchToogleButton status={value.status} val={value} onClicksubmit={onchangeStatusSubmit} />
                                                    <Remove onClick={(e) => { setNewsForEditID(value.id); setShowDeleteConfirmationModal(true) }} />
                                                </td>
                                            </tr>
                                        }) : null
                                    }
                                </tbody>
                            </Table>
                        </TableWrapper>
                    </div>
                </Col>
            </Row >

            {
                NewMsgModal === true ?
                    <AddEditMessageModal show={NewMsgModal} onHide={setNewMsgModal} getNewsList={getNewsList} NewsForEditID={NewsForEditID} />
                    : null
            }

        </>
    );
}

export default DisplayTab;

function AddEditMessageModal(props) {
    const { t: ast } = useTranslation('accountSetting');
    const { t: ct } = useTranslation('common');

    const [MessageObj, setMessageObj] = useState({
        Content: "",
        Status: true
    });
    useEffect(() => {
        if (!!props.NewsForEditID) {
            getdata()
        }
    }, [props.NewsForEditID]);
    const getdata = async () => {
        var response = await CallAxios("/api/AccountSetting/getNews|post", { id: props.NewsForEditID })
        if (!!response && response.status === 200) {
            setMessageObj({
                Content: response.data.content,
                Status: response.data.status
            })
        }
    }
    const ontextChange = (e) => {
        setMessageObj((prevState) => {
            const newState = { ...prevState }
            return Object.assign(newState, {
                [e.target.name]: e.target.value,
            })
        })
    }
    const onSubmit = async () => {
        if (!MessageObj.Content) {
            alert(ast('displayTab.messageError'))
            return;
        }
        var data = {
            Id: props.NewsForEditID,
            Content: MessageObj.Content,
            Status: MessageObj.Status
        }
        var response = null;
        if (!!props.NewsForEditID) {
            response = await CallAxios("/api/AccountSetting/UpdateNews|post", data)
        } else {
            response = await CallAxios("/api/AccountSetting/CreateNews|post", data)
        }

        if (!!response && response.data === true) {
            await props.getNewsList();
            props.onHide(false)
        }
    }
    return (
        <Modal show={props.show} onHide={() => props.onHide(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{!!props.NewsForEditID ? <>{ct('edit')}</> : <>{ct('new')}</>} {ast('displayTab.message')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col md={12} >
                            <Form.Group controlId="AddNewMsgForm.txtNewMsg">
                                <Form.Label> {ast('displayTab.message')}</Form.Label>
                                <Form.Control as="textarea" name="Content" maxLength={100} value={MessageObj.Content} onChange={(e) => ontextChange(e)} rows={2} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btnPrimaryGreen" onClick={() => onSubmit()}> {ct('finished')}</Button>
            </Modal.Footer>
        </Modal>

    )
}