import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { CheckBox, ChooseAll, OptionBox, OptionText, ShowItem } from '../Helpers/Global/StyledComponents';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from "react-i18next";



function SelectPeriodDropdownFilter(props) {
    const { t: ct } = useTranslation('common');

    const [Start, setStart] = useState();
    const [End, setEnd] = useState();

    return (
        <>
            <DropdownButton autoClose="outside" className={props.autoWidth ? 'check-select autoWidth' : 'check-select'} title={ct('selectPeriod')}>
                <Dropdown.Item className="choose-all">
                    <ReactDatePicker selected={Start} onChange={(selected) => setStart(selected)} placeholderText={ct('startingTime')} className="w-100 form-control" />
                </Dropdown.Item>
                <Dropdown.Item className="choose-all">
                    <ReactDatePicker selected={End} onChange={(selected) => setEnd(selected)} placeholderText={ct('endingTime')} className="w-100 form-control" />
                </Dropdown.Item>

                <Dropdown.Item className='show-item d-flex justify-content-between'>
                    <button className='btn btn-primary btn-sm' onClick={() => props.handelDateFilter("filter", Start, End)}>
                        Filter
                    </button>
                    <button className='btn btn-secondary btn-sm ms-2' onClick={() => { setStart(); setEnd(); props.handelDateFilter("reset") }}>
                        {ct('reset')}
                    </button>
                </Dropdown.Item>
            </DropdownButton>
        </>
    );
}

export default SelectPeriodDropdownFilter;

