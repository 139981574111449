import React from 'react';
import { ContentBox, ContentSubHeader } from '../../../Helpers/Global/StyledComponents';
import { CallAxios, CallAxiosBlob } from '../../../Helpers';
import { useState } from 'react';
import { Button, Col, Container, Form, Modal, Nav, OverlayTrigger, Row, Tab, Table, Tabs, Tooltip } from 'react-bootstrap';
import { BsGear } from 'react-icons/bs';
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import Highlighter from 'react-highlight-words';
import { FaDownload, FaUpload } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import DatePicker from 'react-datepicker';
import { useTranslation } from "react-i18next";

function EmployeeExportAbsences() {
    const { t: ct } = useTranslation('common');
    const { t: eit } = useTranslation('exportsImports');


    const [PeriodStartDate, setPeriodStartDate] = useState("");
    const [PeriodEndDate, setPeriodEndDate] = useState("");
    const [status, setstatus] = useState("All");
    const [ExportType, setExportType] = useState("List");
    const [FileFormat, setFileFormat] = useState("XLSX");

    const submit = async () => {
        var ismanual = false;
        if (PeriodStartDate !== "" || PeriodEndDate !== "") {
            ismanual = true;
        }

        var data = {
            format: FileFormat,
            StartDate: PeriodStartDate === "" ? new Date() : PeriodStartDate,
            EndDate: PeriodEndDate === "" ? new Date() : PeriodEndDate,
            StatusType: status,
            Exporttype: ExportType,
            IsManual: ismanual
        }
        var response = await CallAxiosBlob("/api/Absence/AccountAbsencesExport|post", data)

        if (!!response && response.status === 200) {
            if (FileFormat === 'CSV') {
                saveAs(response.data, "Absence.csv")
            }
            else if (FileFormat === 'XLSX') {
                saveAs(response.data, "Absence.xlsx")
            }
        }
    }


    return (
        <>
            <Container fluid className='ex-imp-tab-font-fix'>
                <Row className="desginBox">
                    <Col md={6}>
                        <Form>
                            <Row className="mt-3">
                                <Col md={12}>
                                    <Form.Label className="textDesign fs-16px" >{eit('peroid')}</Form.Label>
                                </Col>
                                <Col md={5}>
                                    <Form.Group controlId="ExportForm.txtWeekDateResource">
                                        <span muted className='fw-normal'>{ct('startDate')}</span>
                                        <DatePicker selected={PeriodStartDate} className="form-control" onChange={date => setPeriodStartDate(date)} />
                                    </Form.Group>
                                </Col>

                                <Col md={5}>
                                    <Form.Group controlId="ExportForm.txtWeekDateResource">
                                        <span muted className='fw-normal'>{ct('endDate')}</span>
                                        <DatePicker selected={PeriodEndDate} className="form-control" onChange={date => setPeriodEndDate(date)} />
                                    </Form.Group>
                                </Col>

                                <Col md={12} className='mt-3'>
                                    <Form.Group onChange={(e) => setstatus(e.target.value)}>
                                        <Form.Label className="textDesign me-2 d-block fs-16px">{ct('status')}</Form.Label>
                                        <div className='d-flex align-items-center'>
                                            <Form.Check className='me-2' type="radio" value="All" checked={status === "All" ? true : false} label={ct('all')} name="SelectExportStatus" />
                                            <Form.Check className='me-2' type="radio" value="confirmed" checked={status === "confirmed" ? true : false} label={ct('confirmed')} name="SelectExportStatus" />
                                            <Form.Check className='me-2' type="radio" value="rejected" checked={status === "rejected" ? true : false} label={ct('rejected')} name="SelectExportStatus" />
                                            <Form.Check className='me-2' type="radio" value="pending" checked={status === "pending" ? true : false} label={ct('pending')} name="SelectExportStatus" />
                                        </div>
                                    </Form.Group>
                                </Col>

                                <Col md={12} className='mt-3'>
                                    <Form.Group onChange={(e) => setExportType(e.target.value)}>
                                        <Form.Label className="textDesign fs-16px">{eit('absencesTab.exportType')}</Form.Label>
                                        <div className='d-flex align-items-center'>
                                            <Form.Check className='me-2' type="radio" value="List" checked={ExportType === "List" ? true : false} label={ct('list')} name="SelectExporttype" />
                                            <Form.Check className='me-2' type="radio" value="Datev" checked={ExportType === "Datev" ? true : false} label="Datev" name="SelectExporttype" />
                                        </div>
                                    </Form.Group>
                                </Col>

                                <Col md={12} className='mt-3'>
                                    <Form.Group onChange={(e) => setFileFormat(e.target.value)}>
                                        <Form.Label className="textDesign fs-16px">{eit('fileFormat')}</Form.Label>
                                        <div className='d-flex align-items-center'>
                                            <Form.Check className='me-2' type="radio" value="XLSX" checked={FileFormat === "XLSX" ? true : false} label="XLSX" name="SelectFileExporttype" />
                                            <Form.Check className='me-2' type="radio" value="CSV" checked={FileFormat === "CSV" ? true : false} label="CSV" name="SelectFileExporttype" />
                                        </div>
                                    </Form.Group>
                                </Col>

                                <Col md={12} className="mt-5 mb-4">
                                    <Button className="btn btnPrimaryGreen fs-16px" onClick={() => submit()}>{eit('createExport')}</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col md={6}>
                        <Form>
                            <Row className="block-example border-left  border-dark mt-5 p-3">
                                <Col md={12}>
                                    <span>
                                        <span className="textdesginHead h5 fw-bold">{eit('hintText')}</span>
                                    </span>
                                </Col>

                                <Col md={12} className="mt-3">
                                    <span muted className='text-break'>
                                        {eit('absencesTab.hints.hint1')}
                                    </span>
                                </Col>

                                <Col md={12} className="mt-3">
                                    <span muted className='text-break'>
                                        {eit('absencesTab.hints.hint2')}
                                    </span>
                                </Col>

                                <Col md={12} className="mt-3">
                                    <span muted className='text-break'>
                                        {eit('absencesTab.hints.hint3')}
                                    </span>
                                </Col>

                                <Col md={12} className="mt-3">
                                    <span muted className='text-break'>
                                        {eit('absencesTab.hints.hint4')}
                                        {/* <span>{eit('absencesTab.hints.hint4').split('`/n`').map(line => <span>{line}</span>)}</span> */}
                                    </span>
                                </Col>

                                <Col md={12} className="mt-3">
                                    <span muted className='text-break'>
                                        {eit('absencesTab.hints.hint5')}
                                    </span>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>

        </>
    );
}

export default EmployeeExportAbsences;