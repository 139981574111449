import { useFormik } from 'formik';
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import DatePicker from 'react-datepicker';
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { Gear, Remove } from "../../Components/ActionBtns";
import { CallAxios } from "../../Helpers";
import { Constants } from "../../Helpers/Contents";
import { TableWrapper } from "../../Helpers/Global/StyledComponents";
import { useTranslation } from "react-i18next";

function TargetHours() {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');

    const { userid } = useParams();
    const user = useSelector(x => x.app.user)
    const [TargetHourList, setTargetHourList] = useState([]);
    const [show, setshow] = useState(false);
    const [idforEdit, setIdforEdit] = useState("");
    const [lastChageDate, setLastChageDate] = useState("");
    const [DeleteModel, setDeleteModel] = useState(false);
    const [deleteobjId, setDeleteobjId] = useState(null);
    useEffect(() => {
        getdata()
    }, []);
    const getdata = async () => {
        var response = await CallAxios("/api/TargetHours/EmptargetHoursList|post", { id: userid });
        if (!!response && response.status === 200) {
            setTargetHourList(response.data.targetHourList)
            setLastChageDate(response.data.lastChangedate)
        }
    }
    const deleteobj = async () => {
        var response = await CallAxios("/api/TargetHours/deletetargetHours|post", { id: deleteobjId });
        if (!!response && response.status === 200) {
            getdata()
        }
    }
    return <>
        {
            DeleteModel === true ?
                <DeleteTargetHourModal show={DeleteModel} setshow={setDeleteModel} Submitdelet={deleteobj} />
                : null
        }
        {
            show === true ?
                <AddEditTargetHourPeriodModal getdata={getdata} setTargetHourList={setTargetHourList} setLastChageDate={setLastChageDate} show={show} setshow={setshow} idforEdit={idforEdit} />
                : null
        }

        <div className="DetailInfoWrapper">
            <div className='d-flex justify-content-between align-items-center mb-2'>
                <h4 className='h5 fw-bold mb-0'> {t('targetHours')}</h4>
                <div className='text-right'>
                    {
                        user.roles.some(x => x === Constants.roles.AccountAdmin || x === Constants.roles.WorkspaceAdmin || x === Constants.roles.Admin) ?
                            <a className='btn btn-primary ' onClick={(e) => { setIdforEdit(""); setshow(true) }}>
                                {t('targetHour.newTargetPeriod')}
                            </a>
                            : null

                    }
                </div>

            </div>
            <TableWrapper>
                <Table striped bordered responsive="sm">
                    <thead>
                        <tr className='firsthead'>
                            <th> {t('targetHour.beginning')}</th>
                            <th> {t('targetHour.summary')}</th>
                            <th> {t('targetHour.exceedTargetHours')}</th>
                            <th> {ct('actions')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !!TargetHourList && TargetHourList.length > 0 ? TargetHourList.map((value, index) => {
                                return <tr>
                                    <td>{value.date}</td>
                                    <td>{value.summary}</td>
                                    <td>{value.exceedTargetHours === true ? <>{ct('notAllowed')}</> : <>{ct('permitted')}</>} </td>
                                    <td>
                                        {
                                            user.roles.length === 1 && user.roles.some(x => x === Constants.roles.Employee) ?
                                                null :
                                                <>
                                                    <Gear onClick={(e) => { setIdforEdit(value.id); setshow(true) }} />
                                                    {
                                                        TargetHourList && TargetHourList.length > 1 ?
                                                            <Remove onClick={() => { setDeleteobjId(value.id); setDeleteModel(true) }} />
                                                            : null
                                                    }
                                                </>
                                        }
                                    </td>
                                </tr>
                            }) : null
                        }
                    </tbody>
                </Table>
            </TableWrapper>
            <h6 className='fw-light mb-0'>{ct('lastChange')}: {lastChageDate}</h6>

        </div>
    </>
}

export default TargetHours;

function DeleteTargetHourModal(props) {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');

    const submit = async (value) => {
        props.Submitdelet()
        props.setshow(false)
    }

    return (
        <Modal show={props.show} onHide={() => props.setshow(false)}>
            <Modal.Header closeButton>
                <Modal.Title> {t('targetHour.targetRemoveMdl.removeTargetHour')}</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Row>
                    <Col md={12}>
                        {t('targetHour.targetRemoveMdl.areRemoveTargetHour')}?
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btnBgGrayList btn m-0" onClick={() => props.setshow(false)}> {ct('back')}</Button>
                <Button className="btn btnPrimaryGreen" onClick={() => submit()}>{ct('yesRemove')}</Button>
            </Modal.Footer>
        </Modal>
    );
}
export function AddEditTargetHourPeriodModal(props) {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');

    const [StartDate, setStartDate] = useState(new Date());
    const [ShowCheckAbsenceModel, setShowCheckAbsenceModel] = useState(false);
    const { userid } = useParams();

    const formik = useFormik({
        initialValues: {
            startDate: new Date(),
            hours: "weekly",
            weeklyHours: "40",
            monthlyHours: "0.0",
            targetHoursExceed: false,
            isMon: true,
            isTue: true,
            isWed: true,
            isThu: true,
            isFri: true,
            isSat: false,
            isSun: false,
            Mon: "8.0",
            Tue: "8.0",
            Wed: "8.0",
            Thu: "8.0",
            Fri: "8.0",
            Sat: "0.0",
            Sun: "0.0",
        },
        onSubmit: values => {
            submit(values)
        },
    });
    const CheckAbsenceInfuture = async () => {
        if (StartDate === null) {
            alert(t('targetHour.errorMsg.alertBlankDate').toString())
            return
        }
        var postdata = {
            StartDate: StartDate,
            empid: !!userid ? userid.toString() : props.userid
        }
        var postresponse = await CallAxios("/api/Absence/CheckAllAbsenceByDate|post", postdata);
        if (!!postresponse && postresponse.status === 200) {
            if (postresponse.data === false) {
                formik.handleSubmit();
            } else {
                setShowCheckAbsenceModel(true)
            }
        }
    }

    const submit = async (value) => {
        if (StartDate === null) {
            alert(t('targetHour.errorMsg.alertBlankDate').toString())
            return
        }
        var data = {
            Id: props.idforEdit,
            EmployeeId: !!userid ? userid.toString() : props.userid,
            startdate: value.startDate,
            isweeklyorMonthlyhour: value.hours,
            weeklyhour: value.weeklyHours,
            monthlyhour: value.monthlyHours,
            Target_hours_not_be_exceeded: value.targetHoursExceed,
            ismon: value.isMon,
            istue: value.isTue,
            iswed: value.isWed,
            isthur: value.isThu,
            isfri: value.isFri,
            issat: value.isSat,
            issun: value.isSun,
            mon: value.Mon.toString(),
            tue: value.Tue.toString(),
            wed: value.Wed.toString(),
            thur: value.Thu.toString(),
            fri: value.Fri.toString(),
            sat: value.Sat.toString(),
            sun: value.Sun.toString(),
            IsUnconfirmAbsence: value.IsUnconfirmAbsence
        }
        var response = await CallAxios("/api/TargetHours/AddEdittargetHours|post", data);
        if (!!response && response.status === 200) {
            if (response.data === true) {
                props.getdata()
                props.setshow(false)
            } else if (response.data === false) {
                toast.error(t('targetHour.errorMsg.simpleError').toString())
            }
            else {
                toast.error(t('targetHour.errorMsg.startDate').toString())
            }
        }
    }
    useEffect(() => {
        if (!!props.idforEdit) {
            getData()
        }
    }, []);
    const getData = async (e) => {
        var response = await CallAxios("/api/TargetHours/getemptargetHours|post", { id: props.idforEdit });

        if (!!response && !!response.data) {
            formik.setFieldValue("startDate", new Date(response.data.startdate))
            formik.setFieldValue("hours", response.data.weeklyhour !== null ? "weekly" : "monthly")
            formik.setFieldValue("weeklyHours", response.data.weeklyhour)
            formik.setFieldValue("monthlyHours", response.data.monthlyhour)
            formik.setFieldValue("targetHoursExceed", response.data.target_hours_not_be_exceeded)
            formik.setFieldValue("isMon", response.data.ismon)
            formik.setFieldValue("isTue", response.data.istue)
            formik.setFieldValue("isWed", response.data.iswed)
            formik.setFieldValue("isThu", response.data.isthur)
            formik.setFieldValue("isFri", response.data.isfri)
            formik.setFieldValue("isSat", response.data.issat)
            formik.setFieldValue("isSun", response.data.issun)
            formik.setFieldValue("Mon", response.data.mon)
            formik.setFieldValue("Tue", response.data.tue)
            formik.setFieldValue("Wed", response.data.wed)
            formik.setFieldValue("Thu", response.data.thur)
            formik.setFieldValue("Fri", response.data.fri)
            formik.setFieldValue("Sat", response.data.sat)
            formik.setFieldValue("Sun", response.data.sun)
        }
    }
    const checkmonthly = (e) => {
        formik.setFieldValue('hours', e.target.value)
    }
    const Handleweekdays = (e) => {
        formik.setFieldValue(e.target.name, e.target.checked)
    }
    const divideWeeklyHours = (e) => {
        let value = e.target.value;
        let arr = [
            formik.values.isMon,
            formik.values.isTue,
            formik.values.isWed,
            formik.values.isThu,
            formik.values.isFri,
            formik.values.isSat,
            formik.values.isSun,
        ]
        let Truelength = arr.filter(x => x === true).length;
        let divResult = parseInt(value) / parseInt(Truelength);
        if (formik.values.isMon) {
            formik.setFieldValue('Mon', divResult)
        }
        if (formik.values.isTue) {
            formik.setFieldValue('Tue', divResult)
        }
        if (formik.values.isWed) {
            formik.setFieldValue('Wed', divResult)
        }
        if (formik.values.isThu) {
            formik.setFieldValue('Thu', divResult)
        }
        if (formik.values.isFri) {
            formik.setFieldValue('Fri', divResult)
        }
        if (formik.values.isSat) {
            formik.setFieldValue('Sat', divResult)
        }
        if (formik.values.isSun) {
            formik.setFieldValue('Sun', divResult)
        }
        formik.setFieldValue("weeklyHours", value);
    }
    return (
        <>
            {
                ShowCheckAbsenceModel === true ?
                    <CheckAbsenceModel show={ShowCheckAbsenceModel} setShow={setShowCheckAbsenceModel} formik={formik} />
                    : null
            }
            <Modal show={props.show} onHide={() => props.setshow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{!!props.idforEdit ? <>{ct('edit')}</> : <>{ct('new')}</>} {t('targetHour.targetHourMdl.targetPeriod')}</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Form>
                        <Row>
                            <Col md={12}>
                                <Form.Group className='mb-3'>
                                    <label htmlFor="" className='mb-2'> {ct('startDate')} </label>
                                    <DatePicker className="form-control" selected={formik.values.startDate} onChange={(date) => { formik.setFieldValue("startDate", date); setStartDate(date) }} />
                                </Form.Group>
                                <Form.Group >
                                    <label htmlFor=""> {t('targetHour.targetHourMdl.hoursEmployeeWeeklyLabel')}.</label>
                                </Form.Group>
                                <Row>
                                    <Col md={3}>
                                        <Form.Group className='mb-3'>
                                            <Form.Check value="weekly" onChange={(e) => checkmonthly(e)} checked={formik.values.hours === "weekly" ? true : false} type="radio" name="hours" id="AnsenceAllDay" />
                                            <label htmlFor=""> {ct('weeklyHours')} </label>
                                            <Form.Control name="weeklyHours" onChange={(e) => divideWeeklyHours(e)} value={formik.values.weeklyHours} type="text" disabled={formik.values.hours !== "weekly" ? true : false} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group className='mb-3'>
                                            <Form.Check value="monthly" type="radio" onChange={(e) => checkmonthly(e)} checked={formik.values.hours === "monthly" ? true : false} name="hours" id="AnsenceAllDa2" />
                                            <label htmlFor=""> {ct('monthlyHours')}</label>
                                            <Form.Control type="text" name="monthlyHours" onChange={formik.handleChange} value={formik.values.monthlyHours} disabled={formik.values.hours !== "monthly" ? true : false} />
                                        </Form.Group>
                                    </Col>

                                </Row>
                                <div className="py-3">
                                    <p className="fw-light"> {t('targetHour.targetHourMdl.specifyEmployeeMonthlyLabel')}:</p>
                                    <Form.Check label={t('targetHour.targetHourMdl.targetHoursNotExceededLabel')} onChange={formik.handleChange} checked={formik.values.targetHoursExceed} value={formik.values.targetHoursExceed} name="targetHoursExceed" className="my-4" type="checkbox" id="AnsenceAllDay25" />
                                    <p className="fw-light font12"> {t('targetHour.targetHourMdl.targetHoursNotExceededLabelDes')}.</p>
                                </div>
                                <div className="smallDivsSection my-3">
                                    <Row>
                                        <Col md={2}>
                                            <Form.Group>
                                                <Form.Check inline className="me-0" name="isMon" onChange={(e) => Handleweekdays(e)} checked={formik.values.isMon} value="Mon" type="checkbox" id="AnsenceAllDay5" />

                                                <label htmlFor="" className='ms-2'> {ct('days.mon')}</label>
                                                <Form.Control name="Mon" onChange={formik.handleChange} value={formik.values.Mon} type="text" disabled={formik.values.hours === "monthly" ? true : !formik.values.isMon} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group>
                                                <Form.Check inline className="me-0" name="isTue" onChange={(e) => Handleweekdays(e)} value="Tue" checked={formik.values.isTue} type="checkbox" id="AnsenceAllDay7" />

                                                <label htmlFor="" className='ms-2'> {ct('days.tue')}</label>
                                                <Form.Control name="Tue" type="text" onChange={formik.handleChange} value={formik.values.Tue} disabled={formik.values.hours === "monthly" ? true : !formik.values.isTue} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group>
                                                <Form.Check inline className="me-0" name="isWed" onChange={(e) => Handleweekdays(e)} value="Wed" checked={formik.values.isWed} type="checkbox" id="AnsenceAllDay" />

                                                <label htmlFor="" className='ms-2'> {ct('days.wed')}</label>
                                                <Form.Control name="Wed" type="text" onChange={formik.handleChange} value={formik.values.Wed} disabled={formik.values.hours === "monthly" ? true : !formik.values.isWed} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group>
                                                <Form.Check inline className="me-0" name="isThu" onChange={(e) => Handleweekdays(e)} value="Thu" checked={formik.values.isThu} type="checkbox" id="AnsenceAllDay" />

                                                <label htmlFor="" className='ms-2'> {ct('days.thu')}</label>
                                                <Form.Control name="Thu" type="text" onChange={formik.handleChange} value={formik.values.Thu} disabled={formik.values.hours === "monthly" ? true : !formik.values.isThu} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group>
                                                <Form.Check inline className="me-0" name="isFri" onChange={(e) => Handleweekdays(e)} value="Fri" checked={formik.values.isFri} type="checkbox" id="AnsenceAllDay" />

                                                <label htmlFor="" className='ms-2'> {ct('days.fri')}</label>
                                                <Form.Control name="Fri" type="text" onChange={formik.handleChange} value={formik.values.Fri} disabled={formik.values.hours === "monthly" ? true : !formik.values.isFri} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group>
                                                <Form.Check inline className="me-0" name="isSat" onChange={(e) => Handleweekdays(e)} value="Sat" checked={formik.values.isSat} type="checkbox" id="AnsenceAllDay" />

                                                <label htmlFor="" className='ms-2'> {ct('days.sat')}</label>
                                                <Form.Control name="Sat" type="text" onChange={formik.handleChange} value={formik.values.Sat} disabled={formik.values.hours === "monthly" ? true : !formik.values.isSat} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group>
                                                <Form.Check inline className="me-0" name="isSun" onChange={(e) => Handleweekdays(e)} value="Sun" checked={formik.values.isSun} type="checkbox" id="AnsenceAllDay" />

                                                <label htmlFor="" className='ms-2'> {ct('days.sun')}</label>
                                                <Form.Control name="Sun" type="text" onChange={formik.handleChange} value={formik.values.Sun} disabled={formik.values.hours === "monthly" ? true : !formik.values.isSun} />
                                            </Form.Group>
                                        </Col>


                                    </Row>
                                </div>

                            </Col>

                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btnBgGrayList btn m-0" onClick={() => props.setshow(false)}> {ct('cancel')}</Button>
                    <Button className="btn btnPrimaryGreen" onClick={() => CheckAbsenceInfuture()}> {ct('ready')}</Button>
                </Modal.Footer>
            </Modal>
        </>

    );
}
function CheckAbsenceModel(props) {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');


    return (
        <>
            <Modal show={props.show} onHide={() => props.setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> {t('targetHour.absenceTimePeriodMdl.absenceTimePeriod')}</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <p>{t('targetHour.absenceTimePeriodMdl.absenceTimePeriodPara')}</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btnBgGrayList btn m-0" onClick={() => props.setShow(false)}> {ct('back')}</button>
                    <Button className="btn btnPrimaryDanger" onClick={() => { props.formik.setFieldValue('IsUnconfirmAbsence', false); props.formik.handleSubmit() }}> {ct('no')} </Button>
                    <Button className="btn btnPrimaryGreen" onClick={() => { props.formik.setFieldValue('IsUnconfirmAbsence', true); props.formik.handleSubmit() }}>{ct('yes')} ({ct('recommended')})</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
