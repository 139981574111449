import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import styled from 'styled-components';
import { ContentBox, ContentSubHeader } from '../../Helpers/Global/StyledComponents';
import AbsenceTab from './Absence/AbsenceTab';
import BaseDataTab from './BaseData/BaseDataTab';
import EmployeeTab from './Employee/EmployeeTab';
import LocationSettingHeader from './LocationSettingHeader';
import PlanningTab from './Planning/PlanningTab';
import TimeTrackingTab from './TimeTracking/TimeTrackingTab';
import WorkFlowTab from './WorkFlows/WorkFlowTab';
import { Outlet, useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import ThemeSetting from './Theme/ThemeSetting';
import { useTranslation } from "react-i18next";

const TabsWrapper = styled.div`
border:1px solid #ccc;
background-color:#fff;
border-radius:5px;
& .nav.nav-tabs {
    background: ${props => props.theme.colors.bg};

}
& .nav.nav-tabs .nav-link{
    border-radius:0px;
    font-weight:600;
    color:#fff;
}
& .nav.nav-tabs .nav-link.active{
    color:${props => props.theme.colors.active};
}
`



function LocationSettings() {
    const { t: Lst } = useTranslation('locationSetting');

    const location = useLocation();
    const [key, setKey] = useState('');
    const TabsList = [
        {
            title: "baseData",
            eventKey: "baseData",
            component: <BaseDataTab />,
            url: "master_data"
        },
        {
            title: "planning",
            eventKey: "planning",
            component: <PlanningTab />,
            url: "planning"
        },
        {
            title: "absences",
            eventKey: "absences",
            component: <AbsenceTab />,
            url: "absence"
        },
        {
            title: "timeTracking",
            eventKey: "timeTracking",
            component: <TimeTrackingTab />,
            url: "time_trackings"
        },
        {
            title: "workFlows",
            eventKey: "workFlows",
            component: <WorkFlowTab />,
            url: "workflows"
        },
        {
            title: "employees",
            eventKey: "employees",
            component: <EmployeeTab />,
            url: "views"
        },
        // {
        //     title: "Theme",
        //     eventKey: "theme",
        //     component: <ThemeSetting />,
        //     url: "themes"
        // },
    ]

    return (
        <>
            <ContentSubHeader>
                <LocationSettingHeader />
            </ContentSubHeader>
            <ContentBox>
                <TabsWrapper>
                    <Tab.Container
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className=' mb-3'
                    >
                        <Nav variant="tabs" className="">
                            {TabsList?.map(x => <Nav.Item>
                                <Nav.Link as={NavLink} to={location.pathname.substring(0, location.pathname.lastIndexOf("/")) + "/" + x.url} className='px-3 pb-3' eventKey={x.eventKey}>{Lst(x.title)}</Nav.Link>
                            </Nav.Item>
                            )}
                        </Nav>
                        {/* {TabsList?.map(x =>
                            <Tab as={NavLink} to={location.pathname.substring(0, location.pathname.lastIndexOf("/")) + "/" + x.url} className='px-3 pb-3' eventKey={x.eventKey} title={x.title}>
                                {key === x.eventKey && x.component}
                                <Outlet /> 
                            </Tab>
                        )} */}
                        <Tab.Content>
                            <Tab.Pane eventKey={key} className='active show px-3 py-4' ><Outlet /> </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </TabsWrapper>
            </ContentBox>

        </>
    );
}

export default LocationSettings;