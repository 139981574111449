import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { GetEmployeeDD } from "../Helpers/MasterData";
import Tags from "@yaireo/tagify/dist/react.tagify"

function TagifyComponent(props) {
    const [employeeListForadmin, setEmployeeListForadmin] = useState([]);
    const [tagifyProps, setTagifyProps] = useState({})

    const baseTagifySettings = {
        //backspace: "edit",
        placeholder: "type something",
        userInput: false,
        dropdown: {
            //   searchKeys:["label", "searchBy"],
            enabled: 0, // a;ways show suggestions dropdown
            mapValueTo: "label"
        }
    }

    useEffect(() => {
        onLoad()
    }, [])
    const onLoad = async () => {
        await GetEmployeeDD(setEmployeeListForadmin)
    }
    useEffect(() => {
        setTagifyProps({ loading: true })
        setTagifyProps((lastProps) => ({
            ...lastProps,
            whitelist: employeeListForadmin,
            // showFilteredDropdown: "",
            userInput: false,
            loading: false
        }))
    }, [employeeListForadmin]);
    const settings = {
        ...baseTagifySettings,
        tagTextProp: "label",
        duplicates: false
    }
    const onChange = useCallback(e => {
        {
            props.getAll(e.detail.value === "" ? [] : JSON.parse(e.detail.value))
            props.closediv()
        }
    }, [])
    return (
        <Tags
            settings={settings}
            defaultValue={props.setDefault}
            autoFocus={true}
            {...tagifyProps}
            onChange={onChange}
        />
    )
}
export default TagifyComponent;