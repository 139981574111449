import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import 'react-data-grid/lib/styles.css';
import { BsCheck2 } from "react-icons/bs";
import { FaUndo } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ExclaimationCircle, Gear, Note, PaperCLip, RemoveCircle } from '../../Components/ActionBtns';
import ServerSideDataGrid from '../../Components/ServerSideDataGrid';
import { CallAxios } from '../../Helpers';
import { config } from '../../Helpers/Config';
import { Constants } from '../../Helpers/Contents';
import { CircleBtnWithIcon, StatusBox } from '../../Helpers/Global/StyledComponents';
import { useTranslation } from "react-i18next";



function AbsencesGrid(props) {
  const { t: ct } = useTranslation('common');
  const { t: apt } = useTranslation('absencePage');
  const { t } = useTranslation('myProfile');

  var selectedTags = useSelector(x => x.app.selectedTags);
  var user = useSelector(x => x.app.user);

  const [showNoQuotaOrConsumeModal, setShowNoQuotaOrConsumeModal] = useState(false);
  const [tryToconfirmAbsenceDate, setTryToconfirmAbsenceDate] = useState(null);
  const [FilterAbsenceList, setFilterAbsenceList] = useState([]);
  const [TotalVacation, setTotalVacation] = useState(0);
  const [TotalHours, setTotalHours] = useState(0);
  const [SelectedEmployeeId, setSelectedEmployeeId] = useState("");
  useEffect(() => {
    ApplyDateFilter()
  }, [props.absenceList]);

  useEffect(() => {
    ApplyDateFilter()
  }, [selectedTags]);
  const ApplyDateFilter = () => {
    // if (!!props.FilterStartDate && !!props.FilterEndDate) {
    //   setFilterAbsenceList(props.absenceList.filter(c => moment(c.startDate).isSameOrAfter(props.FilterStartDate) && moment(c.endDate).isSameOrBefore(props.FilterEndDate)).filter(x => selectedTags.some(v => v.value === "Withouttags") && x.tagids.length === 0 || x.tagids.find(v => selectedTags.some(c => c.value === v))))
    // }
    // else if (!!props.FilterStartDate) {
    //   setFilterAbsenceList(props.absenceList.filter(c => moment(c.startDate).isSameOrAfter(props.FilterStartDate)).filter(x => selectedTags.some(v => v.value === "Withouttags") && x.tagids.length === 0 || x.tagids.find(v => selectedTags.some(c => c.value === v))))
    // }
    // else if (!!props.FilterEndDate) {
    //   setFilterAbsenceList(props.absenceList.filter(c => moment(c.endDate).isSameOrBefore(props.FilterEndDate)).filter(x => selectedTags.some(v => v.value === "Withouttags") && x.tagids.length === 0 || x.tagids.find(v => selectedTags.some(c => c.value === v))))
    // }
    // else {
    //   setFilterAbsenceList(props.absenceList.filter(x => (selectedTags.some(v => v.value === "Withouttags") && x.tagids.length === 0) || x.tagids.find(v => selectedTags.some(c => c.value === v))))
    // }
    setFilterAbsenceList(props.absenceList.filter(x => (selectedTags.some(v => v.value === "Withouttags") && x.tagids.length === 0) || x.tagids.find(v => selectedTags.some(c => c.value === v))))
  }

  useEffect(() => {
    if (props.checkRadio === "open") {
      setFilterAbsenceList(props.absenceList.filter(x => x.status === 'pending'))
    } else if (props.checkRadio === "confirm") {
      setFilterAbsenceList(props.absenceList.filter(x => x.status === 'confirmed'))
    }
    else {
      setFilterAbsenceList(props.absenceList)
    }
  }, [props.checkRadio]);

  const changestatus = async (id, status, empId) => {
    setSelectedEmployeeId(empId)
    var data = {
      id: id,
      status: status
    }
    var response = await CallAxios("/api/Absence/changeAbsenceStatusAdmin|post", data)
    if (!!response && response.status === 200) {
      if (response.data.toString() === "true") {
        props.GetAbsenceList()
      }
      else if (response.data === "No Quota Or Consumed") {
        setShowNoQuotaOrConsumeModal(true)
      }
      else if (response.data === "Ban") {
        toast.error(apt('errorMsg.changeAbsenceBan'))
      }
    }
  }
  const EditAbsenceAdmin = (id, status) => {
    props.setAbsenceModalType(status)
    props.setAbsenceIdForEdit(id)
    props.setShowAddEditAbsenceModal(true)
  }
  useEffect(() => {
    calculateTotalhoursandVacations(FilterAbsenceList)
  }, [FilterAbsenceList]);

  const calculateTotalhoursandVacations = (list) => {
    let hours = 0;
    let vacation = 0;
    if (!!list && list.length > 0) {
      list.map((value) => {
        hours += parseFloat(value.correctedHours)
        vacation += parseFloat(value.holiday)
      })
    }
    setTotalHours(hours.toFixed(2));
    setTotalVacation(vacation);
  }

  const columns = [
    { key: 'employeeName', name: apt('tbl.employees'), sortable: true, searchable: true, disableSearch: false },
    { key: 'absenceType', name: apt('tbl.art'), sortable: true, searchable: true, disableSearch: false },
    { key: 'startDate', name: ct('beginning'), sortable: true, searchable: true, disableSearch: true },
    { key: 'endDate', name: ct('end'), sortable: true, searchable: true, disableSearch: true },
    { key: 'correctedHours', name: apt('tbl.hours'), sortable: true, searchable: true, disableSearch: false },
    { key: 'holiday', name: apt('tbl.vacation'), sortable: true, searchable: true },
    { key: 'status', name: ct('status'), sortable: true },
    { key: 'action', name: ct('action') },

  ];



  return (
    <>
      {
        showNoQuotaOrConsumeModal === true ?
          <NoQuotaOrConsumedModal show={showNoQuotaOrConsumeModal} tryToconfirmAbsenceDate={tryToconfirmAbsenceDate} setShow={setShowNoQuotaOrConsumeModal} SelectedEmployeeId={SelectedEmployeeId} />
          : null
      }
      <ServerSideDataGrid
        columns={columns}
        rows={FilterAbsenceList}
        itemsPerPage={10}
        customBody={(rows) =>
          rows?.map((value, i) =>
            <tr>
              {
                user.roles.length === 1 && user.roles[0].label === Constants.roles.Employee ?
                  <td>{value.employee}</td>
                  :
                  <td>
                    <Link className="DetailLink" onClick={() => localStorage.setItem("selectedEmpId", value.empId)} state={{ currentView: 'Admin' }} to={`/companies/${user.LocationId}/users/${value.empId}/master_data`}>{value.employeeName}</Link>
                  </td>
              }
              <td> {value.absenceType}</td>
              <td>{new Date(value.startDate).toLocaleDateString()}</td>
              <td> {new Date(value.endDate).toLocaleDateString()}</td>
              <td>{!!value.correctedHours ? value.correctedHours : '0.0'}</td>
              <td><div className='d-flex align-items-center justify-content-between'>{!!value.holiday ? value.holiday : '0.0'}</div></td>
              <td>
                <div className='d-flex align-items-center justify-content-between' >
                  {/* <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip {...props}></Tooltip>}> */}
                  <span data-tooltip-id="my-tooltip" data-tooltip-content={t('absenceTab.createdAt') + `: ${new Date(value.createdOn).toLocaleDateString()} ${value.status === "confirmed" ? t('absenceTab.confirmedAt') : value.status === "pending" ? t('absenceTab.vacationAvailable') : t('absenceTab.rejectedAt')}:${value.status === "confirmed" || value.status === "rejected" ? new Date(value.updateStatus).toLocaleDateString() : (!!value.holiday ? value.holiday : '0.0') + '/' + (!!value.vocationQuota ? value.vocationQuota.totalVocation : '0.0')} `} className='d-flex'><StatusBox status={value.status} className="me-2"> {value.status === "confirmed" ? value.responseByEmployee : t(`absenceTab.${value.status}`)} </StatusBox> <ExclaimationCircle color='#a4a4a4' className="ml-1" /></span>
                  {/* </OverlayTrigger> */}
                  {
                    !!value.note ?
                      <StickyNotePopover data={value.note} />
                      :
                      null
                  }
                  {
                    !!value.attachment ?
                      <PaperClipPopover data={value.attachment} />
                      :
                      null
                  }
                </div>
              </td>
              <td>
                <div className='d-flex'>
                  {
                    <>
                      {value.status === "pending" ?
                        <>
                          {
                            value.isHaveAccessToconfirm === true ?
                              <>
                                <a data-tooltip-id="my-tooltip" data-tooltip-content={t('absenceTab.checkTooltip')} className="btnTblAction bgGreen" onClick={(e) => { setTryToconfirmAbsenceDate(value.startDate); changestatus(value.absenceID, "S", value.empId); }}><CircleBtnWithIcon className='Btn' fs={"20px"} color='#1b9175'><BsCheck2 /></CircleBtnWithIcon></a>
                                <RemoveCircle data-tooltip-id="my-tooltip" data-tooltip-content={ct('cancel')} onClick={(e) => changestatus(value.absenceID, "R", value.empId)} color='#f25656' />
                              </>
                              : null
                          }
                        </>
                        :
                        value.isEmployeesResetAbsences === true || user.roles.some(x => x === Constants.roles.AccountAdmin || x === Constants.roles.WorkspaceAdmin || x === Constants.roles.Admin) ?
                          <a data-tooltip-id="my-tooltip" data-tooltip-content={t('absenceTab.undoTooltip')} className="btnTblAction" onClick={(e) => changestatus(value.absenceID, "P", value.empId)}><CircleBtnWithIcon fs={"16px"} className='Btn' color='#0d7be3'><FaUndo /></CircleBtnWithIcon></a>
                          : null
                      }
                      {
                        value.isHaveAccessToCreate === true ?
                          <a data-tooltip-id="my-tooltip" data-tooltip-content={ct('toEdit')} className="btnTblAction bgBlue" onClick={() => EditAbsenceAdmin(value.absenceID, value.status)}> <Gear color='#0d7be3' /> </a>
                          : null
                      }
                    </>
                  }
                </div>
              </td>
            </tr>
          )}
        loadData={(p) => props.GetAbsenceList(p)}
        getPaginateInfo={(p) => props.getPaginateInfo(p)}

        customFooter={<tr className='text-left'>
          <td colSpan={4} className="text-left"><span className='fw-bolder'>{ct('total')}</span></td>
          <td><span className='fw-bolder'>{TotalHours}</span></td>
          <td><span className='fw-bolder'>{TotalVacation}</span></td>
          <td colSpan={2}></td>
        </tr>} />
    </>
  );
}

export default AbsencesGrid;

export function StickyNotePopover(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Note

        data-tooltip-id='my-tooltip'
        data-tooltip-html={
          `   <div className='minwidth150px'>
          <div className='header bg-light p-2 px-3'>
            <h6 className='mb-0 fw-bold' style={{ fontSize: "12px" }}>${!!props.data && props.data.name}</h6>
            <span style={{ fontSize: "10px" }}>${!!props.data && props.data.date}</span>
          </div>
          <div className='popoverBody p-2 px-3'>
            <span style={{ fontSize: "12px" }}> ${!!props.data && props.data.attachmentOrNote} </span>
          </div>
        </div>`}

        className='dontShowNote' aria-describedby={id} onMouseEnter={handleClick} />

      {/* <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className='minwidth150px'>
          <div className='header bg-light p-2 px-3'>
            <h6 className='mb-0 fw-bold' style={{ fontSize: "12px" }}>{!!props.data && props.data.name}</h6>
            <span style={{ fontSize: "10px" }}>{!!props.data && props.data.date}</span>
          </div>
          <div className='popoverBody p-2 px-3'>
            <span style={{ fontSize: "12px" }}> {!!props.data && props.data.attachmentOrNote} </span>
          </div>
        </div>
      </Popover> */}
    </>
  );
}
export function PaperClipPopover(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <a target='_blank'

        data-tooltip-id='my-tooltip'
        data-tooltip-html={
          ` <div className='minwidth150px'>
          <div className='header bg-light p-3'>
            <h6 className='mb-0 fw-bold' style={{ fontSize: "12px" }}>${!!props.data && props.data.name}</h6>
            <span style={{ fontSize: "10px" }}>${!!props.data && props.data.date}</span>
          </div>
          <div className='popoverBody p-3'>
            <span style={{ fontSize: "12px" }}> ${!!props.data && props.data.attachmentOrNote}  </span>
          </div>
        </div>`

        }

        href={config.S3BucketURL + props.data.attachmentOrNote}><PaperCLip color='grey' aria-describedby={id} onMouseEnter={handleClick} /></a>

      {/* <Popover
        id={id}

        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className='minwidth150px'>
          <div className='header bg-light p-3'>
            <h6 className='mb-0 fw-bold' style={{ fontSize: "12px" }}>{!!props.data && props.data.name}</h6>
            <span style={{ fontSize: "10px" }}>{!!props.data && props.data.date}</span>
          </div>
          <div className='popoverBody p-3'>
            <span style={{ fontSize: "12px" }}> {!!props.data && props.data.attachmentOrNote}  </span>
          </div>
        </div>
      </Popover> */}

    </>
  );
}
export function NoQuotaOrConsumedModal(props) {
  const { t: apt } = useTranslation('absencePage');

  var user = useSelector(x => x.app.user);

  const navigate = useNavigate();
  const navigateToprofile = () => {
    navigate('/companies/' + user.CompanyId + '/users/' + props.SelectedEmployeeId + '/vacations')
  }
  return (
    <>
      <Modal show={props.show} className="largeModalDialog" onHide={() => props.setShow(false)}>
        <Modal.Header closeButton className="">
          <Modal.Title> {apt('noQuotaMdl.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6> {apt('noQuotaMdl.desc')}:</h6>
          <p>
            {
              new Date(props.tryToconfirmAbsenceDate).toLocaleDateString()
            }
          </p>
          <p>
            <a href="#" onClick={() => navigateToprofile()}> {apt('noQuotaMdl.setupEntitlementLink')}</a>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-success mx-2" onClick={() => props.setShow(false)} >Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}