import React, { useRef, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { Edit } from '../../Components/ActionBtns';
import { CallAxiosBlob } from '../../Helpers';
import { config } from '../../Helpers/Config';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";


const CircleImage = styled.div`
text-align:center;
position:relative;
& img{
    width:110px;
    height:110px;
}
& .adjustICON{
    position:absolute;
    bottom:0;
    right:0;
}
`

function ProfileImage(props) {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');


    const inputref = useRef();
    const [showUploadImageModal, setShowUploadImageModal] = useState(false);
    const themeColor = useSelector(state => state.app.theme.colors.buttons);

    const editAvatar = () => {
        if (inputref.current) {
            inputref.current.click()
        }
    }
    return (
        <>
            <CircleImage>
                <img src={!!props.ProfileData?.profileImageURL ? (config.S3BucketURL + props.ProfileData?.profileImageURL) : "https://creazilla-store.fra1.digitaloceanspaces.com/icons/7911322/avatar-icon-sm.png"} className='img-fluid' />
                <div className='text-end adjustICON'>
                    <Edit color={themeColor} onClick={() => setShowUploadImageModal(true)} />
                </div>
            </CircleImage>
            {
                showUploadImageModal === true ?
                    <ProfileImageModal show={showUploadImageModal} onHide={setShowUploadImageModal} getProfileData={props.getProfileData} />
                    : null
            }
        </>
    );
}

export default ProfileImage;

function ProfileImageModal(props) {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');


    const { userid } = useParams();
    const [image, setImage] = useState("");

    const onsave = async () => {
        var data = {
            empId: userid,
            image: image,
        };
        // var form_data = new FormData();
        // for (var key in data) {
        //     if (typeof (data[key]) === "object" && !!data[key] && data[key].length > 0) {
        //         for (var i = 0; i < data[key].length; i++) {
        //             form_data.append(key + [], data[key][i]);
        //         }
        //     } else {
        //         form_data.append(key, data[key]);
        //     }
        // }
        var form_data = new FormData();
        Object.keys(data).forEach((key, index) => {
            if (typeof (data[key]) === "object" && !!data[key] && data[key].length > 0) {
                for (var i = 0; i < data[key].length; i++) {
                    form_data.append(key + [], data[key][i]);
                }
            } else if (data[key]) {
                form_data.append(key, data[key]);
            }
        })

        var res = await CallAxiosBlob("/api/Employee/UploadProfileImage|post", form_data)
        if (!!res) {
            props.getProfileData()
            props.onHide(false)
        }
    }
    return (
        <Modal show={props.show} onHide={() => props.onHide(false)}>
            <Modal.Header closeButton>
                <Modal.Title> {t('profilePictureTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Form>
                    <Row>
                        <Col md={12}>
                            <Form.Group>
                                <input type="file" id="UploadProfileImg" onChange={(e) => setImage(e.target.files[0])} label={t('profilePictureTitle')} />

                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btnPrimaryGreen" onClick={(e) => onsave()}> {ct('finished')}</Button>
            </Modal.Footer>
        </Modal>
    )
}