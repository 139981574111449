import { useFormik } from 'formik';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { AiFillEye, AiFillEyeInvisible, AiTwotoneMail } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import { HiBuildingOffice2 } from "react-icons/hi2";
import PhoneInput from 'react-phone-input-2';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from "yup";
import { AxiosLite, CallAxios } from '../../Helpers';
import { SignInManager } from '../../Helpers/Authentication/SignInManager';
import { Constants } from '../../Helpers/Contents';
import {
    AnchorLink, ButtonWrapper, DisplayFlexBetween, FormGroup, InputField, InputIcon, LoginContent,
    LoginLogo, LoginWrapper, PrimaryButton, SubTitle, Title, TitleWrapper
} from '../../Helpers/Global/StyledComponents';
import { setCurrentView, setJwt, setUser } from '../../Store/slices/AppSlice';

export const Register = () => {

    const [Step, setStep] = useState(1);
    const [CompanyId, setCompanyId] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    return (
        <>
            <LoginWrapper>
                <LoginContent>
                    <LoginLogo src="/assets/logoPlaceholder.png" />
                    <TitleWrapper>
                        <SubTitle>Register to</SubTitle>
                        <Title>Basta Shift</Title>
                        <SubTitle>Time Tracking System</SubTitle>
                    </TitleWrapper>
                    {Step === 1 ?
                        <Step1 setStep={setStep} setCompanyId={setCompanyId} setEmail={setEmail} setPassword={setPassword} />
                        :
                        <Step2 CompanyId={CompanyId} email={email} password={password} />
                    }
                </LoginContent>
            </LoginWrapper>
        </>
    )
}

function Step1(props) {
    const [showPassword, setShowPassword] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            password: '',
            company_name: "",
            timezone: "",
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Required").min(2).max(50),
            email: Yup.string()
                .email("Invalid email address")
                .required("Please enter email").max(100),
            password: Yup.string().required("Required").max(50),
        }),
        onSubmit: values => {
            register(values)
        },
    })
    useEffect(() => {
        props.setEmail(formik.values.email)
        props.setPassword(formik.values.password)
    }, [formik.values.email, formik.values.password]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const register = async (values) => {
        // var res = await AxiosLite("/api/Account/verifyEmail|get", { email: values.email })

        // if (res.status === 200) {
        // if (res.data === true) {
        var data = {
            Surname: values.name,
            Email: values.email,
            Password: values.password,
            timezone: moment.tz.guess(),
        }
        var response = await CallAxios("/api/Account/createAccount|post", data)
        if (response.data.success) {
            if (response.data.data !== false) {
                props.setCompanyId(response.data.data)
                props.setStep(2)
            }
        } else if (response.data.message === "EmailExist") {
            toast.info("Email already exist...")
        }
        else {
            toast.error("Something went wrong...")
        }
        // } 
        // else {
        //     toast.info("Email already exist...")
        // }
        // }
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <FormGroup>
                    <InputField type="text" name='name' value={formik.values.name} placeholder="Name" autoFocus="autofocus" onChange={formik.handleChange} />
                    <InputIcon className='input-icon'>
                        <FaUser size={20} color={"#cbcbcb"} />
                    </InputIcon>
                    {formik.touched.name && formik.errors.name ? (
                        <span className="error text-danger text-left">{formik.errors.name}</span>
                    ) : null}
                </FormGroup>
                <FormGroup>
                    <InputField type="text" name='email' value={formik.values.email} placeholder="Company email address" onChange={formik.handleChange} />
                    <InputIcon className='input-icon'>
                        <AiTwotoneMail size={20} color={"#cbcbcb"} />
                    </InputIcon>
                    {formik.touched.email && formik.errors.email ? (
                        <span className="error text-danger text-left">{formik.errors.email}</span>
                    ) : null}
                </FormGroup>
                <FormGroup>
                    <InputField type={showPassword === true ? "text" : "password"} Password={true} name='password' value={formik.values.password} placeholder="Password" onChange={formik.handleChange} />
                    <InputIcon className='input-icon'>
                        {showPassword === false ? <AiFillEye size={20} onClick={() => setShowPassword(true)} color={"#cbcbcb"} /> : <AiFillEyeInvisible onClick={() => setShowPassword(false)} size={20} color={"#cbcbcb"} />}

                    </InputIcon>
                    {formik.touched.password && formik.errors.password ? (
                        <span className="error text-danger text-left">{formik.errors.password}</span>
                    ) : null}
                </FormGroup>
                <DisplayFlexBetween>
                    <span style={{ fontSize: '12px' }}>Does your company already have an account? Go to</span>
                    <AnchorLink onClick={() => navigate("/login")}>login</AnchorLink>
                </DisplayFlexBetween>
                <ButtonWrapper>
                    <PrimaryButton onClick={formik.handleSubmit}>Register</PrimaryButton>
                </ButtonWrapper>
            </form>
        </>
    )
}
function Step2(props) {

    const formik = useFormik({
        initialValues: {
            company_name: "",
            industry: "",
            noOfEmployees: "1-5 employees",
            phone: "",
            timezone: "",
        },
        validationSchema: Yup.object().shape({
            company_name: Yup.string().required("Company name is required").min(2).max(100),
            phone: Yup.string().test("phone", "Phone number is invalid, please check if the phone number has the right format: +44 123 4567890.", (value) => {
                if (!!value) {
                    return isValidPhoneNumber("+" + value)
                } else {
                    return true;
                }
            }),
        }),
        onSubmit: values => {
            submit(values)
        },
    })

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const submit = async (values) => {

        var data = {
            BranchName: values.company_name,
            phone: values.phone,
            industrytype: values.industry,
            NpOfEmployees: values.noOfEmployees,
            timezone: moment.tz.guess(),
            CompanyId: props.CompanyId
        }
        var response = await CallAxios("/api/Account/UpdateRigesterAccountDetal|post", data)
        if (response.data.success) {
            var res = await new SignInManager().Login(props.email, props.password)
            if (res.status === 200) {

                if (!!res.data.message) {
                    toast.error(res.data.message)
                }
                else {
                    new SignInManager().SetToken(res.data.token)
                    dispatch(setJwt({ token: res.data.token }));
                    dispatch(
                        setUser({
                            CompanyId: res.data.compnayId,
                            LocationId: res.data.locationId,
                            roles: res.data.userRoles.split(","),
                            LoginUserId: res.data.employeeId,
                        })
                    );

                    dispatch(setCurrentView("admin"));
                    navigate('/')
                }
            }
        } else {
            toast.error(response.data.message)
        }
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit}>

                <FormGroup>
                    <InputField type="text" name='company_name' value={formik.values.company_name} placeholder="Company name" autoFocus="autofocus" onChange={formik.handleChange} />
                    <InputIcon className='input-icon'>
                        <HiBuildingOffice2 size={20} color={"#cbcbcb"} />
                    </InputIcon>
                    {formik.touched.company_name && formik.errors.company_name ? (
                        <span className="error text-danger text-left">{formik.errors.company_name}</span>
                    ) : null}
                </FormGroup>
                <FormGroup>
                    <PhoneInput
                        country={'us'}
                        value={formik.values.phone}
                        onChange={value => { formik.setFieldValue('phone', value) }}
                        className="PhoneNumberHere"
                        name="phone"
                    />
                    {/* <InputIcon className='input-icon'>
                    <AiFillPhone size={20} color={"#cbcbcb"} />
                </InputIcon> */}
                    {formik.touched.phone && formik.errors.phone ? (
                        <span className="error text-danger text-left">{formik.errors.phone}</span>
                    ) : null}
                </FormGroup>
                <FormGroup>
                    <select className="btn btn-lg form-control" style={{ height: "60px", fontSize: "18px" }} onChange={e => { formik.setFieldValue('industry', e.target.value) }} name="industry" id="Industry_business">
                        <option value="">Choose Industry</option>
                        {
                            Constants.industryOptions.map((val, index) =>
                                <option value={val.value} key={index}>{val.text}</option>
                            )
                        }
                    </select>
                </FormGroup>
                <FormGroup>
                    <select className="btn btn-lg form-control" style={{ height: "60px", fontSize: "18px" }} onChange={e => { formik.setFieldValue('noOfEmployees', e.target.value) }} name="noOfEmployees" id="noOfEmployees">
                        {
                            Constants.companyNumberOfEmployeesDropDown.map((val, index) =>
                                <option value={val.value} key={index}>{val.text}</option>
                            )
                        }
                    </select>
                </FormGroup>
                <ButtonWrapper>
                    <PrimaryButton onClick={formik.handleSubmit}>Complete</PrimaryButton>
                </ButtonWrapper>
            </form>
        </>
    )
}